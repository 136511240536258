import React, { Component } from 'react';
import './App.css';
import connect from "react-redux/es/connect/connect";

class About extends Component{
    render() {

        let src= this.props.currentLang && this.props.currentLang === 'nl' ?
            ('https://www.deltaproto.com/public/deltaproto.nl.sub.mp4')
            : 'https://www.deltaproto.com/public/deltaproto.en.mp4'

        return (
            <section className="pageContainer">
                    <video id="mainVideoDP" controls autoPlay={true} loop={true}>
                        <source src={src} type={"video/mp4"}/>
                    </video>
            </section>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        currentLang: state.main.currentLang
    }
}

export default connect(mapStateToProps)(About)