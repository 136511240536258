import React, {Component} from 'react';
import connect from "react-redux/es/connect/connect";
import {Popup} from "semantic-ui-react";
import {fetchPcbPrice} from "./actions";
import {isEmployee} from "./actions/functions";

class ProjectCardPcbPrice extends React.Component {
    render() {
        let admin= isEmployee(this.props.currentUser)

        if( !admin ) {
            return <div>{this.props.price}</div>
        }

        const pcbPrice = this.props.priceListPcb.find(p => p.orderId === this.props.projectId)

        return <Popup
            content={<div>{pcbPrice ? 'PCB prijs: € ' + pcbPrice.value + ',-' : '-'}</div>}
            trigger={<div>{this.props.price}</div>}
            onOpen={() => this.props.dispatch(fetchPcbPrice(this.props.projectId))}
        />
    }
}

const mapStateToProps = (state) => {
    return {
        currentUser: state.main.currentUser,
        priceListPcb: state.project.priceListPcb
    }
}

export default connect(mapStateToProps)(ProjectCardPcbPrice)