import React, { Component } from 'react';
import './App.css';
import connect from "react-redux/es/connect/connect";
import {getProjectList, toggleProjectShow} from "./actions";
import ProjectCard from './ProjectCard';
import {Button, Checkbox, Dropdown, Icon, Loader, Search} from "semantic-ui-react";
import Translate, {getText} from "./Translate";
import {getPaymentStatusColor, isEmployee} from "./actions/functions";
import {withRouter} from "react-router-dom";
import {ProjectCardOverviewInfo} from "./ProjectCardOverviewInfo";
import OnVisible from "react-on-visible";

class ProjectList extends Component{

    state = {}

    componentDidMount() {

        this.setState({
            searchValue: this.props.projectPageFilter.searchValue
        })

        if( !(this.props.projectPage && this.props.projectPage.content.length) ) {
            this.fetchProjects();
        }
    }

    handleKeyPress = (event, value) => {
        if( event.charCode === 13 ) {
            this.fetchProjects(false, 'searchValue', this.state.searchValue);
        }
    }

    fetchProjects(nextPage, name, value, name2, value2, name3, value3) {

        //let all= this.state.showAll;
        //let searchValue= this.state.searchValue;
        //let fieldName= this.state.sort.value;
        //let dsc= this.state.sort.desc;
        //let paymentStatusFilter= this.state.paymentStatusFilter;
        //let hasOrderDate= this.state.hasOrderDate;

        let projectPageFilter= this.props.projectPageFilter;
        projectPageFilter.shippedNoInvoice = null

        if( name ) {
            projectPageFilter= Object.assign({}, projectPageFilter, {
                [name]: value
            })
        }

        if( name2 ) {
            projectPageFilter= Object.assign({}, projectPageFilter, {
                [name2]: value2
            })
        }

        if( name3 ) {
            projectPageFilter= Object.assign({}, projectPageFilter, {
                [name3]: value3
            })
        }

        if( nextPage ) {
            projectPageFilter= Object.assign({}, projectPageFilter, {
                pageNumber: this.props.projectPageFilter.pageNumber + 1
            })
        } else {
            projectPageFilter= Object.assign({}, projectPageFilter, {
                pageNumber: 0
            })
        }

        let {all, direction, sort, searchValue, pageNumber, paymentStatusFilter, hasOrderDate, series, foto, shippedNoInvoice}= projectPageFilter;

        if( sort == null || sort === "" ) {
            if( isEmployee(this.props.currentuser) ) {
                sort = "deliveryDate";
                direction = "ASC";
            } else {
                sort = "aanvraagDate";
                direction = "DESC";
            }
        }

        this.props.dispatch(getProjectList(all, direction, sort, searchValue, pageNumber, paymentStatusFilter, hasOrderDate, series, foto, shippedNoInvoice))
    }

    toggleShowHide = (project) => {

        this.props.dispatch(toggleProjectShow(project.id, !project.filterState))

    }

    setSortValue( value, type ) {

        let projectPageFilter= this.props.projectPageFilter;

        let newDirection= '';
        if( true ) {

            if( projectPageFilter.direction && projectPageFilter.sort === value ) {

                newDirection= projectPageFilter.direction === 'DESC' ? 'ASC' : 'DESC';

            } else {

                newDirection= 'DESC';

            }

        }

        //this.setState({sort: sortValue}, () => this.fetchProjects());
        this.fetchProjects(false, 'sort', value, 'direction', newDirection);

        //localStorage.setItem('deltaprotosort', JSON.stringify(sortValue));
    }

    setPaymentStatusFilter( value ) {
        this.fetchProjects(false, 'paymentStatusFilter', value)
    }

    setHasOrderDateFilter( value ) {
        this.fetchProjects(false, 'hasOrderDate', value)
    }

    setShowAllFilter( value ) {
        this.fetchProjects(false, 'all', value)
    }

    renderSortableHeader( title, value, type ) {

        let projectPageFilter= this.props.projectPageFilter;

        return <div style={{display: 'inline-block'}}>
            <button className="link-button" onClick={() => this.setSortValue(value, type)}><Translate value={title}/>
                {projectPageFilter.sort === value ? projectPageFilter.direction === 'DESC' ? ' ▼' : ' ▲' : ''}
            </button>
        </div>
    }

    render() {
        let list= this.props.projectPage ? this.props.projectPage.content : [];

        const {fetchingProjects}= this.props;
        const admin= isEmployee(this.props.currentUser);

        let projectPageFilter= this.props.projectPageFilter;

        return(
            <main id={'projects'} className={'pageContainer'}>

                <div style={{textAlign: 'right'}}>
                {!admin ? <Button onClick={() => this.props.history.push('/calculator')} primary>{getText(this.props, 'project_list_new')}</Button> : ''}
                </div>

                {admin &&
                    <div style={{textAlign: 'center', width: '100%', paddingBottom: '1em'}}>
                        <Search value={this.state.searchValue} onSearchChange={(e, { value }) => this.setState({searchValue: value})}
                               onKeyPress={this.handleKeyPress} placeholder={'Search...'} results={[]} showNoResults={false}
                               icon={{ name: 'search', circular: true, link: true, onClick: () => this.handleKeyPress({charCode: 13}) }}
                        />
                    </div>}

                {admin && <section className="projectCard">

                    <div style={{height: 0, textAlign: 'right', marginRight: -88}}>
                        <div style={{paddingTop: 14}}>
                            <Checkbox label='Show all' checked={projectPageFilter.all} onChange={() => {
                                this.setShowAllFilter(!projectPageFilter.all)
                            }}/>
                        </div>
                    </div>

                    <div style={{height: '3em', textAlign: 'left', marginLeft: 16, marginTop: 16, position: 'absolute'}}>
                        <Dropdown
                            style={{color: (projectPageFilter.hasOrderDate !== null || projectPageFilter.shippedNoInvoice) ? 'blue' : ''}}
                            icon='bars'
                            >
                            <Dropdown.Menu>
                                <Dropdown.Item text='Geen filter' style={{color: (projectPageFilter.hasOrderDate === null && projectPageFilter.series === null) ? 'blue' : ''}} onClick={() => {
                                    this.fetchProjects(false, 'series', null, 'foto', null, 'hasOrderDate', null)
                                }} />
                                <Dropdown.Item text='Alleen aanvragen' style={{color: projectPageFilter.hasOrderDate === false ? 'blue' : ''}} onClick={() => {
                                    this.setHasOrderDateFilter(false)
                                }} />
                                <Dropdown.Item text='Alleen orders' style={{color: projectPageFilter.hasOrderDate === true ? 'blue' : ''}} onClick={() => {
                                    this.setHasOrderDateFilter(true)
                                }} />
                                <Dropdown.Item text='Alleen produceren' style={{color: (projectPageFilter.series === 'active' && projectPageFilter.foto === 'active') ? 'blue' : ''}} onClick={() => {
                                    this.fetchProjects(false, 'series', 'active', 'foto', 'active')
                                }} />
                                <Dropdown.Item text='Factureren Harm!' style={{color: projectPageFilter.shippedNoInvoice === true ? 'blue' : ''}} onClick={() => {
                                    this.fetchProjects(false, 'shippedNoInvoice', true)
                                }} />
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>

                        <section className="projectOverview">
                            <ProjectCardOverviewInfo
                                listFilterView={true}
                                firstItemText={<Translate value='sort_bedrijf'/>}
                                secondItemText={this.renderSortableHeader('project_owner', 'owner.firstName', 'string')}
                            />
                            <ProjectCardOverviewInfo
                                listFilterView={true}
                                firstItemText={this.renderSortableHeader('sort_aanvraag', 'aanvraagDate')}
                                secondItemText={this.renderSortableHeader('project_name', 'name', 'string')}
                            />
                            <ProjectCardOverviewInfo
                                listFilterView={true}
                                firstItemText={this.renderSortableHeader('calc_amount', 'calculatorDetails.numPcbs')}
                                secondItemText={<div style={{display: 'inline'}}>
                                    {this.renderSortableHeader('Levertijd', 'calculatorDetails.selectedDays')}
                                    {" ("}
                                    {this.renderSortableHeader('project_deliverydate', 'deliveryDate')}
                                    {")"}
                                </div>}
                            />
                            <ProjectCardOverviewInfo
                                listFilterView={true}
                                firstItemText={this.renderSortableHeader('project_internal_name', 'internalName', 'string')}
                                secondItemText={
                                    [<Translate value='sort_bedrag'/>,
                                    <Icon name='money' style={{marginLeft: 8, cursor: 'pointer', textDecoration: projectPageFilter.paymentStatusFilter ? 'underline' : ''}} color={getPaymentStatusColor(projectPageFilter.paymentStatusFilter)} onClick={() => {
                                        if( projectPageFilter.paymentStatusFilter === 'payed' ) {
                                            this.setPaymentStatusFilter('sent')
                                        } else if( projectPageFilter.paymentStatusFilter === 'sent' ) {
                                            this.setPaymentStatusFilter('na')
                                        } else if( projectPageFilter.paymentStatusFilter === 'na' ) {
                                            this.setPaymentStatusFilter('')
                                        } else {
                                            this.setPaymentStatusFilter('payed')
                                        }
                                    }}/>]
                                }
                            />
                        </section>
                    </section>}

                <pre>
                    {fetchingProjects ? <Loader active inline/> : ' '}
                </pre>

                <section className={'projectList'}>
                    {list.map((p) => {
                        return(
                            <div style={{paddingBottom: '1em'}} key={p.id}>
                                {admin && <div style={{height: 0, textAlign: 'right', marginRight: -32}}>
                                    <div style={{paddingTop: 14}}>
                                        {projectPageFilter.all ?
                                            <Icon style={{cursor: 'pointer'}} name={p.filterState ? 'hide' : 'eye'} onClick={() => this.toggleShowHide(p)}/>:
                                            <Icon style={{cursor: 'pointer'}} name={p.filterState ? 'hide' : 'eye'} onClick={() => this.toggleShowHide(p)} />
                                        }
                                    </div>
                                </div>}



                                <ProjectCard project={p} listView={true} showAll={projectPageFilter.showAll}/>



                            </div>
                        )
                    })}
                </section>

                {/*{(this.props.projectPage && !this.props.projectPage.last) ? <button onClick={() => this.fetchProjects(true)}><Translate value={'more'}/></button> : ''}*/}

                {(this.props.projectPage && this.props.projectPage.content.length > 0 && !this.props.projectPage.last && !this.props.fetchingProjects) ? <OnVisible bounce={true} onChange={(e) => {
                    console.log('fetch next project ' + (this.props.projectPage.number + 1) )
                    this.fetchProjects(true)
                }}/> : ''}

                {this.props.projectPage && this.props.fetchingProjects ? <Loader active inline/> : ''}
            </main>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        lang: state.main.lang,
        projectPage: state.project.projectPage,
        projectPageFilter: state.project.projectPageFilter,
        fetchingProjects: state.project.fetchingProjects,
        currentUser: state.main.currentUser
    }
}

export default connect(mapStateToProps)(withRouter(ProjectList))
