import React, {Component} from 'react';
import './App.css';
import HomeTilesLine from "./HomeTilesLine";
import connect from "react-redux/es/connect/connect";
import {getText} from "./Translate";

class HomeTiles extends Component{
    render() {
        return(
            <section id={'homeTiles'}>
                <HomeTilesLine itemHeading={'prices'} linkTo={'/calculator'}/>
                <HomeTilesLine itemHeading={'microplacer'} linkTo={'/microplacer'}/>
                <div className={'homeTile'} style={{lineHeight: '2em'}}>
                    <div className={'homeTile2lines'}>
                        <a className='linkWhite' href="tel:+31207173868"><div style={{fontSize: '1em'}}>020 717 3 868</div></a>
                        <a className='linkWhite' href="tel:+31646601111"><div style={{fontSize: '.7em'}} title={getText(this.props, 'bel_harm')}>06 46 60 11 11</div></a>
                    </div>
                </div>
                <HomeTilesLine itemHeading={'protopcbas'}/>
                <HomeTilesLine itemHeading={'video'} linkTo={'/video'} video={'images/assembly.mp4'}/>
                <HomeTilesLine itemHeading={'sampledelivery'}/>
                <HomeTilesLine itemHeading={'hardwarescrum'}/>
                <HomeTilesLine itemHeading={'protofoto'} className={'pcbFloat'}/>
                <HomeTilesLine itemHeading={'bestprice'}/>
                <HomeTilesLine itemHeading={'odb'}/>
                <HomeTilesLine itemHeading={'modification'}/>
                <HomeTilesLine itemHeading={'testimonials'} scrollTo={'reviewsContainer'}/>
            </section>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        lang: state.main.lang,
        currentLang: state.main.currentLang,
    }
}

export default connect(mapStateToProps)(HomeTiles)
