import {getColSize} from "../actions/functions";

const initialState= {colType: [], importHeaders: [], decimalSeparator: ',', unitOfMeasurement: 'mm', importHeadersBom: [], importHeadersCentroid: []}

const fileimport = (state = initialState, action) => {
    switch( action.type ) {
        case 'IMPORT_CANCEL':
            return Object.assign({}, state, {
                table: null
            })
        case 'IMPORT_SET_COL_TYPE':
            return Object.assign({}, state, {
                colType: state.colType.map((c,i) => {
                    if( i === action.column ) {
                        return action.value;
                    } else {
                        return c;
                    }
                })
            })

        case 'IMPORT_SET_HEADER_ROW':
            return Object.assign({}, state, {
                selectedRow: action.row
            })

        case 'IMPORT_SELECT_SHEET':

            let colSize1= getColSize( state.table[action.sheetName] );

            return Object.assign({}, state, {
                sheetName: action.sheetName,
                colSize: colSize1,
                colType: new Array(colSize1).fill("NA"),
            })

        case 'IMPORT_SET_EXCEL_DATA':

            let colSize2= getColSize( action.table[action.sheetName] );

            return Object.assign({}, state, {
                colSize: colSize2,
                colType: new Array(colSize2).fill("NA"),
                table: action.table,
                sheetName: action.sheetName,
                selectedRow: 0
            })

        case 'START_IMPORT_BOM':
        case 'START_IMPORT_BOM_V2':
            return Object.assign({}, state, {importing: true, importError: null, print: null})
        case 'RECEIVE_IMPORT_BOM':
        case 'RECEIVE_IMPORT_BOM_V2':
            return Object.assign({}, state, {importing: false, importError: null, print: action.value})
        case 'ERROR_IMPORT_BOM':
        case 'ERROR_IMPORT_BOM_V2':
            return Object.assign({}, state, {importing: false, importError: 'Something went wrong'})
        case 'RECEIVE_IMPORT_HEADERS':
            return {
                ...state,
                importHeaders: action.value
            }
        case 'RECEIVE_IMPORT_HEADERS_BOM':
            return {
                ...state,
                importHeadersBom: action.value
            }
        case 'RECEIVE_IMPORT_HEADERS_CENTROID':
            return {
                ...state,
                importHeadersCentroid: action.value
            }
        case 'SET_DECIMAL_SEPARATOR':
            return {
                ...state,
                decimalSeparator: action.value
            }
        case 'SET_UNIT_OF_MEASUREMENT':
            return {
                ...state,
                unitOfMeasurement: action.value
            }
        case 'START_IMPORT_CENTROID_V2':
            return {
                ...state,
                importing: true,
                importError: null
            }
        case 'RECEIVE_IMPORT_CENTROID_V2':
            return {
                ...state,
                importData: action.value,
                importing: false,
                importError: null
            }
        default:
            return state
    }
}
export default fileimport
