import React, {Component} from "react";
import Truncate from "react-truncate";
import PropTypes from "prop-types";

export class ProjectCardOverviewInfo extends Component {
    render() {

        let showDivider = this.props.firstItemText && this.props.secondItemText;

        let {firstItemText, secondItemText} = this.props;

        if( firstItemText instanceof String ) {
            firstItemText = <Truncate title={firstItemText} width={150}>{firstItemText}</Truncate>
        }
        if( secondItemText instanceof String ) {
            secondItemText = <Truncate title={secondItemText} width={150}>{secondItemText}</Truncate>
        }

        if (this.props.onFirstItemClick) {
            firstItemText =
                <button className="link-button" onClick={() => this.props.onFirstItemClick()}>{firstItemText}</button>
        }

        if (this.props.onSecondItemClick) {
            secondItemText =
                <button className="link-button" onClick={() => this.props.onSecondItemClick()}>{secondItemText}</button>
        }

        return (
            <section className={this.props.listFilterView ? 'projectOverviewListFilterInfo' : 'projectOverviewInfo'}>
                <div className={'projectOverviewInfoItem body2'}>{firstItemText}</div>
                {showDivider ? ' | ' : ''}
                <div className={'projectOverviewInfoItem body2'}>{secondItemText}</div>
            </section>
        )
    }
}

ProjectCardOverviewInfo.propTypes = {
    firstItemText: PropTypes.string,
    secondItemText: PropTypes.string,
    onFirstItemClick: PropTypes.func,
    onSecondItemClick: PropTypes.func,
};

ProjectCardOverviewInfo.defaultProps = {
    firstItemText: 'Default text',
    secondItemText: 'Default text',
    onFirstItemClick: null,
    onSecondItemClick: null,
};