import React from 'react';
import PropTypes from 'prop-types';
import NormalizedComponentSpecification from "./NormalizedComponentSpecification";

class BomValidationMessage extends React.Component {

    render() {

        const {value} = this.props;

        return <div>

            {value.orderparts.map(op => <div>
                <NormalizedComponentSpecification
                    value={value.specification}
                    compareTo={op.specification}
                    onlyError={true}
                />
            </div>)}



        </div>
    }
}

BomValidationMessage.propTypes = {
    value: PropTypes.object,
    asIcon: PropTypes.bool,
}

BomValidationMessage.defaultProps = {
    asIcon: false
}

export default BomValidationMessage;