let initialState = {
    hours: [],
    startDate: new Date()
};

const employee = (state = initialState, action) => {
    switch (action.type) {
        case 'RECEIVE_GET_EMPLOYEE_HOURS':
            return {...state, hours: action.value}
        case 'RECEIVE_SAVE_EMPLOYEE_HOURS':
            if( state.hours.some(h => h.id === action.value.id) ) {
                return {
                    ...state, hours: state.hours.map(h => {
                        if( h.id === action.value.id ) {
                            return action.value
                        }
                        return h;
                    })
                }
            } else {
                return {...state, hours: [...state.hours, action.value]}
            }
        case 'SET_EMPLOYEE_HOURS_START_DATE':
            return {...state, startDate: action.value}
        default:
            return state
    }
}
export default employee
