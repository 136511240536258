import React, {Component} from 'react';
import Dropzone from "react-dropzone";
import connect from "react-redux/es/connect/connect";
import PropTypes from "prop-types";
import {Button, Icon, Popup, Progress} from "semantic-ui-react";
var request = require('superagent');

class UploadFile extends Component {

    state= {
        dropzoneActive: false
    }

    onDrop = (acceptedFiles) => {
        this.handleFileHoverEnd();

        this.setState({
            message: '',
            dropzoneActive: false,
            uploadError: null,
            errorFile: null
        });

        console.log('Accepted files: ', acceptedFiles);

        acceptedFiles.forEach((file)=> {
            var req = request.post(this.props.url);

            this.setState({progress: {perc: 0, name: file.name}})

            //this.props.dispatch(startReceivePrint())
            this.props.onStart && this.props.onStart()

            req.attach("file", file, file.name);

            req.on('progress', (e) => {

                if( e.percent != null ) {
                    console.log(e)
                    this.setState({progress: {perc: e.percent, name: file.name}});
                }


            })

            req.end((err,res) => {
                if( !err ) {

                    /*let current= this.state.data.files;
                    if( current == null ) {
                        current= [];
                    }

                    if( res.body ) {
                        res.body.forEach(o => current.push(o));
                    }

                    let newData= Object.assign({}, this.state.data, {
                        files: current
                    })*/

                    //this.setState({data: res.body})

                    //this.props.dispatch(receivePrint(res.body))

                    console.log(JSON.stringify(res))

                    this.props.onResult && this.props.onResult(res.body)

                    //this.props.change(field, current)
                    //this.props.dispatch( fileUploaded(res.body) )
                } else {
                    this.setState({uploadError: err.message, progress: {perc: 100, name: ''}})

                    this.props.onError && this.props.onError(JSON.parse(res.text))
                }
            });
        });


    }

    handleFileHover = () => {
        this.setState({dropzoneActive: true});
        if( this.props.onFileHover ) {
            this.props.onFileHover()
        }
    }

    handleFileHoverEnd = () => {
        this.setState({dropzoneActive: false});
        if( this.props.onFileHoverEnd ) {
            this.props.onFileHoverEnd()
        }
    }

    render() {

        return (
                <Dropzone onDrop={this.onDrop}
                          ref={(node) => {
                              this.dropzoneRef = node;
                          }}
                          onFileDialogCancel={() => {
                              if (this.props.onCancel) {
                                  this.props.onCancel()
                              }
                              this.handleFileHoverEnd()
                          }}
                          multiple={this.props.multiple}
                          disableClick={!this.props.children}
                          disablePreview={true}
                          style={this.props.style}
                          onDragEnter={() => this.handleFileHover()}
                          onDragLeave={() => this.handleFileHoverEnd()}
                >
                    {this.props.children ? this.props.children : this.props.popupContent ? <Popup trigger={<span>{this.renderButton()}</span>} content={this.props.popupContent}/> : this.renderButton()}
                </Dropzone>
        );
    }

    renderButton() {
        return <>
            {(this.state.progress && this.state.progress.perc < 100) ?
                <Progress color='blue' indicating percent={this.state.progress.perc}/> :
                this.props.button ?
                    <Button size={this.props.size} color={this.state.dropzoneActive ? 'blue' : this.props.color}
                            icon={this.props.icon} labelPosition={this.props.icon ? 'left' : null}
                            primary={this.props.primary} type="file" onClick={(e) => {
                        this.dropzoneRef.open();
                        if (this.props.onClick) {
                            this.props.onClick(e)
                        }
                        e.preventDefault();
                    }}>
                        {this.props.icon && <Icon name='file'/>}
                        {this.props.text}
                    </Button> : <button className='link-button' onClick={(e) => {
                        this.dropzoneRef.open();
                        if (this.props.onClick) {
                            this.props.onClick(e)
                        }
                        e.preventDefault();
                    }} style={{backgroundColor: this.state.dropzoneActive ? 'blue' : ''}}>
                        {this.props.icon && <Icon name='file'/>}
                        {this.props.text}
                    </button>}
        </>;
    }
}

UploadFile.propTypes = {
    style: PropTypes.object,
    text: PropTypes.string,
    button: PropTypes.bool,
    size: PropTypes.string,
    inputText: PropTypes.string,
    url: PropTypes.string,
    onFileHover: PropTypes.func,
    onFileHoverEnd: PropTypes.func,
    onResult: PropTypes.func,
    onStart: PropTypes.func,
    onClick: PropTypes.func,
    onError: PropTypes.func,
    icon: PropTypes.bool,
    onCancel: PropTypes.func,
    primary: PropTypes.bool,
    popupContent: PropTypes.string,
    multiple: PropTypes.bool
};

UploadFile.defaultProps = {
    style: {},
    text: '',
    button: true,
    size: '',
    inputText: '',
    url: '',
    onFileHover: null,
    onFileHoverEnd: null,
    onResult: null,
    onStart: null,
    onClick: null,
    onError: null,
    icon: true,
    onCancel: null,
    primary: false,
    popupContent: null,
    multiple: false
};

const mapStateToProps = (state) => {
    return {

    }
}

export default connect(mapStateToProps)(UploadFile)