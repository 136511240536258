import React from 'react';
import {
    changeApplicationSettings,
    fetchApplicationSettings,
    getDropboxStatus,
    getKvkApiStatus, saveApplicationSettings,
    updateKvkKey, zoekKvkHandelsnaam, zoekKvkNummer
} from "./actions";
import {Button, Divider, Form, Icon, Input, Label, Modal, Popup} from "semantic-ui-react";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import {formatDateTime} from "./actions/functions";
import Translate from "./Translate";

class ApplicationSettingItem extends React.Component {

    state = {
    }

    componentDidMount() {

    }

    handleSettingsChange = (e, { name, value, index, checked }, additional) => {
        console.log(e)
        console.log(name)
        console.log(value)
        console.log(checked)

        this.props.dispatch(changeApplicationSettings(name, value))
    }

    render() {

        const s = this.props.setting

        return <div>
            <Input labelPosition='right' action fluid name={s.key} value={s.value} onChange={this.handleSettingsChange}>
                <Label basic>{s.key}</Label>
                <input/>
                <Button primary={s.dirty} content={s.saving ? <Translate value='een_moment_aub'/> : <Translate value='save'/>} onClick={() => {
                    this.props.dispatch(saveApplicationSettings(s))
                }}/>
            </Input>
        </div>
    }
}

ApplicationSettingItem.propTypes = {
    setting: PropTypes.object
}

const mapStateToProps = (state) => {
    return {
        //applicationsettings: state.main.applicationsettings,
        //applicationsettingsSaving: state.main.applicationsettingsSaving,
    }
}

export default connect(mapStateToProps)(ApplicationSettingItem)