import React from 'react'
import PropTypes from "prop-types";
import {truncate} from "../tools/functions";

export const mmToPix = 37.79527559;
export const workspaceHeight = 345 * mmToPix;
export const workspaceWidth = 616 * mmToPix;

class CrossHair extends React.Component {

    handleMouseOver = () => {
        //console.log('CrossHair ' + this.props.value)
    }

    render() {
        const fill = 'rgba(154,0,0,0.39)'

        let {x, y, stroke, size, value, highlight, shadow} = this.props

        if( this.props.tomm ) {
            x = x * mmToPix;
            y = y * mmToPix;
        } else {
            //x = x * 10;
            //y = y * 10;
        }
        let w = size * 500;
        let w2 = (this.props.rotate != null) ? w * 0.4 : w;

        let r = this.props.rotate ? this.props.rotate : 0;

        let fontSize = this.props.fontSize;

        const bomLine = this.props.bomLine;

        const strokeWidth = 60 / this.props.scale;
        w = w / this.props.scale;
        w2 = w2 / this.props.scale;
        fontSize = (fontSize * 15) / this.props.scale;

        const s = 42; //shadow offset

        return <g filter="url(#dropShadow)">
            <g transform={`rotate(${r},${x},${y})`}>
                <line x1={`${x - w + s}`} y1={`${y - 0 + s}`} x2={`${x + w + s}`} y2={`${y + 0 + s}`} strokeWidth={strokeWidth}
                      stroke={shadow} onMouseEnter={this.handleMouseOver} stroke-opacity="0.7"/>
                <line x1={`${x - 0 + s}`} y1={`${y - w2 + s}`} x2={`${x + 0 + s}`} y2={`${y + w2 + s}`}
                      strokeWidth={strokeWidth}
                      stroke={shadow} onMouseEnter={this.handleMouseOver} stroke-opacity="0.7"/>

                <line x1={`${x - w}`} y1={`${y - 0}`} x2={`${x + w}`} y2={`${y + 0}`} strokeWidth={strokeWidth}
                      stroke={stroke} onMouseEnter={this.handleMouseOver}/>
                <line x1={`${x - 0}`} y1={`${y - w2}`} x2={`${x + 0}`} y2={`${y + w2}`} strokeWidth={strokeWidth}
                      stroke={stroke} onMouseEnter={this.handleMouseOver}/>
            </g>

            <circle onMouseDown={event => {
                if (this.props.onClick) {
                    this.props.onClick()
                }
            }} onDoubleClick={event => {
                if (this.props.onDoubleClick) {
                    this.props.onDoubleClick()
                }
            }
            } className={"svghover"} cx={x} cy={y} r={w} stroke={''} fill={'red'} opacity={highlight ? .3 : 0}>
                <title>{this.props.value}</title>
            </circle>

            <text onMouseDown={event => {
                if( this.props.onClick ) {
                    this.props.onClick()
                }
            }} onDoubleClick={event => {
                if( this.props.onDoubleClick ) {
                    this.props.onDoubleClick()
                }
            }} style={{fill: stroke, userSelect: 'none'}} x={x + (fontSize * .75)} y={y + (fontSize * 1.5)} fontSize={fontSize+'px'}>
                {this.props.value}
            </text>

            {this.props.values ? this.props.values.map((v,i) => <text x={x + (fontSize * .75)} y={y + (fontSize * 1.5 * (i+2))} fontSize={fontSize+'px'}>
                {truncate(v, 48)}
            </text>) : null}

        </g>
    }
}

CrossHair.propTypes = {
    stroke: PropTypes.string,
    fontSize: PropTypes.number,
    rotate: PropTypes.number,
    scale: PropTypes.number
}

CrossHair.defaultProps = {
    stroke: 'black',
    rotate: null,
    scale: 1
}

export default class CentroidSvg extends React.Component{

    state = {
    }

    componentDidMount() {

    }

    handleClick = (c) => {
        if( this.props.onClick ) {
            this.props.onClick(c)
        }
    }

    handleDoubleClick = (c) => {
        if( this.props.onDoubleClick ) {
            this.props.onDoubleClick(c)
        }
    }

    render() {

        const crosshair = !!this.props.crosshairPosition;

        const scale = this.props.scale;

        let items = this.props.items.filter(i => i.x && i.y)

        items = items.map(i => {

            const bottom = i.topBottom === "BOTTOM"

            if( this.props.pcbPosition ) {
                return {
                    ...i,
                    y: -i.y - this.props.pcbPosition.y,
                    x: (bottom ? -i.x : i.x) + this.props.pcbPosition.x,
                    stroke: 'black',
                    size: 1.5
                }
            }

            return {
                ...i,
                x: (bottom ? -i.x : i.x),
                y: -i.y,
                stroke: 'black',
                size: 4
            }
        });

        let minX = Number.MAX_SAFE_INTEGER;
        let minY = Number.MAX_SAFE_INTEGER;
        let maxX = 0;
        let maxY = 0;

        if( !crosshair ) {
            items.forEach(i => {
                if (i.x < minX) {
                    minX = i.x;
                }
                if (i.y < minY) {
                    minY = i.y;
                }
                if (i.x > maxX) {
                    maxX = i.x;
                }
                if (i.y > maxY) {
                    maxY = i.y;
                }
            })
        }

        let crosshair_x = 0;
        let crosshair_y = 0;

        if( this.props.mohicanPosition ) {
            crosshair_x = this.props.mohicanPosition.x;
            crosshair_y = -this.props.mohicanPosition.y/* - 2.2*/;
        }

        if( this.props.crosshairPosition ) {
            crosshair_x = crosshair_x - this.props.crosshairPosition.x
            crosshair_y = crosshair_y - this.props.crosshairPosition.y
        }

        let viewBox = `${minX - 11} ${minY - 11} ${maxX - minX + 22} ${maxY - minY + 22}`

        if( this.props.viewWidth && this.props.viewHeight ) {
            let x = (crosshair_x * 1000);
            let y = (crosshair_y * 1000);
            viewBox = `${x} ${y} ${this.props.viewWidth * 1000} ${this.props.viewHeight * 1000}`;
        }

        items.map(c => {
            if( c.d && c.d.r ) {
                c.isCapacitor = c.d.r.isCapacitor;
                c.isResistor = c.d.r.isResistor;
            }
        })

        let mult = crosshair ? 1000 : 1;

        return <svg xmlns="http://www.w3.org/2000/svg" className="" viewBox={viewBox} style={this.props.style}
                    onMouseDown={evt => {
                        var e = evt.target;
                        var dim = e.getBoundingClientRect();
                        var x = evt.clientX - dim.left;
                        var y = evt.clientY - dim.top;

                        if( this.props.onStart ) {
                            this.props.onStart({x: x, y: y})
                        }
                    }} onMouseUp={evt => {
                        var e = evt.target;
                        var dim = e.getBoundingClientRect();
                        var x = evt.clientX - dim.left;
                        var y = evt.clientY - dim.top;

                        if( this.props.onEnd ) {
                            this.props.onEnd({x: x, y: y})
                        }
                    }}
        >
            {/*<defs>*/}
            {/*    <filter id="dropShadow">*/}
            {/*        <feGaussianBlur in="SourceAlpha" stdDeviation={20 / scale} result="blur1" />*/}
            {/*        <feFlood flood-color="white" result="color"/>*/}
            {/*        <feComposite in="color" in2="blur1" operator="in" result="sombra" />*/}
            {/*        <feOffset dx={25 / scale} dy={25 / scale} />*/}
            {/*        <feMerge>*/}
            {/*            <feMergeNode />*/}
            {/*            <feMergeNode in="SourceGraphic" />*/}
            {/*        </feMerge>*/}
            {/*    </filter>*/}
            {/*</defs>*/}

            {items.map(c => {

                let rotate = null;
                if( c.isResistor || c.isResistor ) {
                    rotate = c.topBottom === "BOTTOM" ? -c.rotate : c.rotate;
                }

                return <CrossHair {...{...c, x: c.x * mult, y: c.y * mult}} onClick={() => this.handleClick(c)} onDoubleClick={() => this.handleDoubleClick(c)}
                                  stroke={c.color ? c.color : 'black'} shadow={c.shadow ? c.shadow : 'white'} tomm={false}
                                  fontSize={this.props.fontSize} rotate={rotate} bomLine={c.bomLine} scale={scale}/>

                //return <CrossHair {...{...c, x: c.x * 1000, y: c.y * 1000}} onClick={() => this.handleClick(c)} onDoubleClick={() => this.handleDoubleClick(c)} stroke={c.color ? c.color : 'black'} tomm={false}
                //                  fontSize={this.props.fontSize} rotate={rotate} bomLine={c.bomLine} scale={scale}/>
            })}

            {!crosshair ? <rect x={(minX - 10) * mult} y={(minY -10) * mult} width={((maxX - minX) + 20) * mult} height={((maxY - minY) + 20) * mult}
                   className="svgrectfill"
            /> : ''}
        </svg>

    }
}

CentroidSvg.propTypes = {
    width: PropTypes.number,
    height: PropTypes.number,
    print: PropTypes.object,
    pcbPosition: PropTypes.object,
    topBottom: PropTypes.string,
    mohicanPosition: PropTypes.object,
    crosshairPosition: PropTypes.object,
    style: PropTypes.object,
    onStart: PropTypes.func,
    onEnd: PropTypes.func,
    fontSize: PropTypes.number,
    onClick: PropTypes.func,
    scale: PropTypes.number
}

CentroidSvg.defaultProps = {
    topBottom: 'ALL',
    style: {},
    fontSize: 16,
    scale: 1
}