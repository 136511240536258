import React, {Component} from 'react';
import PropTypes from "prop-types";
import {
    Button,
    Checkbox,
    Input, Modal,
    Table, Form, Icon, Confirm, List
} from "semantic-ui-react";

import {
    formatDate
} from "./../actions/functions";

import 'moment/locale/nl.js'
import connect from "react-redux/es/connect/connect";
import {
    createInvoice,
    createPackingSlip, deletePackingSlip,
} from "./../actions";
import {openConfirmDeletePackingSlip} from "../actions";
import AccountAddress from "./AccountAddress";
import BasicSheet from "../basic/BasicSheet";
import Translate from "../Translate";

var moment = require('moment');
moment.locale('nl');

class ProjectEventView extends Component {

    constructor(props) {
        super(props);

        this.state= {
            quantity: 0,
            date: formatDate( new Date() ),
            lines: [],
            confirmCreateName: ''
        }
    }

    renderAddInvoice(project, cPackingSlip) {
        return <>

            <Button primary floated='right'
                    onClick={() => this.setState({openCreate: true, confirmCreateName: ''})}
                    loading={this.props.creatingPackingSlip}>Invoice aanmaken</Button>

            {project.invoiceAddress && <div>
                <AccountAddress address={project.invoiceAddress} displayOnly={true} color={'red'}/>
                <br/>
            </div>}

            <Modal open={this.state.openCreate} onClose={() => this.setState({openCreate: false})}>
                <Modal.Header>Invoice aanmaken</Modal.Header>
                <Modal.Content>

                    <label>Datum:</label><br/>
                    <Input type={"text"} name={"date"} placeholder={"Datum"} value={this.state.date}
                           onChange={(event) => this.setState({date: event.target.value})}/>

                    <Table compact basic>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell style={{width: 100}}>Regel</Table.HeaderCell>
                                <Table.HeaderCell>Omschrijving</Table.HeaderCell>
                                <Table.HeaderCell style={{width: 100}}>Aantal</Table.HeaderCell>
                                <Table.HeaderCell style={{width: 150}}>Prijs</Table.HeaderCell>
                                <Table.HeaderCell style={{width: 150}}></Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>

                        <Table.Body>

                            <Table.Row>
                                <Table.Cell><Input fluid value={'1'}/></Table.Cell>
                                <Table.Cell><Input fluid value={project.name}/></Table.Cell>
                                <Table.Cell><Input fluid type={"number"} name={"quantity"} placeholder={"Aantal PCBA's"}
                                                   value={this.state.quantity}
                                                   onChange={(event) => this.setState({quantity: event.target.value})}/></Table.Cell>
                                <Table.Cell><Input fluid type={"number"} name={"cost"} placeholder={"Factuur bedrag"}
                                                   value={this.state.cost}
                                                   onChange={(event) => this.setState({cost: event.target.value})}/></Table.Cell>
                                <Table.Cell></Table.Cell>
                            </Table.Row>

                            {this.state.lines.map((l, i) => <Table.Row>

                                <Table.Cell>
                                    <Input fluid type={"number"} name={"number"} placeholder={"Regel"} value={l.number}
                                           onChange={(event) => {
                                               let newObject = Object.assign({}, l, {number: event.target.value})
                                               let newList = this.state.lines.map((item, j) => i === j ? newObject : item);
                                               this.setState({lines: newList})
                                           }}/>
                                </Table.Cell>

                                <Table.Cell>
                                    <Input fluid type={"text"} name={"text"} placeholder={"Omschrijving"} value={l.description}
                                           onChange={(event) => {
                                               let newObject = Object.assign({}, l, {description: event.target.value})
                                               let newList = this.state.lines.map((item, j) => i === j ? newObject : item);
                                               this.setState({lines: newList})
                                           }}/>
                                </Table.Cell>

                                <Table.Cell>
                                    <Input fluid type={"number"} name={"quantity"} placeholder={"Aantal"} value={l.quantity}
                                           onChange={(event) => {
                                               let newObject = Object.assign({}, l, {quantity: event.target.value})
                                               let newList = this.state.lines.map((item, j) => i === j ? newObject : item);
                                               this.setState({lines: newList})
                                           }}/>
                                </Table.Cell>

                                <Table.Cell>
                                    {/*<Input fluid type={"number"} name={"price"} placeholder={"Prijs"} value={l.quantity} onChange={(event) => {
                                                let newObject= Object.assign({}, l, {quantity: event.target.value})
                                                let newList= this.state.lines.map((item, j) => i === j ? newObject : item);
                                                this.setState({lines: newList})
                                            }}/>*/}
                                </Table.Cell>

                                <Table.Cell style={{textAlign: 'right'}}>
                                    <Button
                                        onClick={() => {
                                            this.setState({lines: [...this.state.lines.slice(0, i), ...this.state.lines.slice(i + 1)]})
                                        }}
                                    >Verwijderen</Button>
                                </Table.Cell>

                            </Table.Row>)}

                        </Table.Body>
                    </Table>


                    <div style={{textAlign: 'right'}}>
                        <Button onClick={() => this.setState({
                            lines: [...this.state.lines, {
                                number: this.state.lines.length + 2,
                                description: '',
                                quantity: ''
                            }]
                        })}>Regel toevoegen</Button>
                    </div>

                </Modal.Content>
                <Modal.Actions>
                    <Button onClick={() => this.setState({openCreate: false})}>Annuleren</Button>
                    <Button
                        primary
                        disabled={!this.state.cost || this.props.creatingPackingSlip}
                        loading={this.props.creatingPackingSlip}
                        onClick={() => this.props.dispatch(createInvoice(this.props.projectId, {
                            ...cPackingSlip, createdBy: this.props.currentUser.firstName
                        }, () => {
                            this.setState({openCreate: false})
                        }))}
                    >
                        Aanmaken
                    </Button>
                </Modal.Actions>
            </Modal>
        </>;
    }

    renderPcbLevering(pcbNummer, packingSlips) {

        let onPackingSlip = false;

        packingSlips.forEach(p => {
            p.lines.forEach(l => {
                //console.log(pcbNummer + ' line pcbNumber ' + l.pcbNumber)
                if( parseInt(l.pcbNumber) === parseInt(pcbNummer) ) {
                    onPackingSlip= true;
                }
            })
        })

        if( onPackingSlip ) {
            return ' ( geleverd )'
        }

        return '';
    }

    renderAddPackingslip(project, cPackingSlip) {

        const pcbType = this.state.lines.filter(l => l.pcbType).map(l => l.pcbType).find(t => t.startsWith('SAMPLE'))

        return <>

            <Button primary floated='right'
                    onClick={() => this.setState({
                        openCreate: true,
                        confirmCreateName: '',
                        isSampleDelivery: this.props.eventList.length === 0
                    })}
                    loading={this.props.creatingPackingSlip}>
                Packing Slip aanmaken
            </Button>

            {project.deliveryAddress && <div>
                <AccountAddress address={project.deliveryAddress} displayOnly={true} color={'blue'}/>
                <br/>
            </div>}

            <Modal open={this.state.openCreate} onClose={() => this.setState({openCreate: false})}>
                <Modal.Header>Packing Slip aanmaken</Modal.Header>
                <Modal.Content>

                    <label>Datum:</label><br/>
                    <Input type={"text"} name={"date"} placeholder={"Datum"} value={this.state.date}
                           onChange={(event) => this.setState({date: event.target.value})}/>

                    <Checkbox style={{marginLeft: 16}} name={"isSampleDelivery"} label={'Is Sample Levering'}
                              checked={this.state.isSampleDelivery}
                              onClick={(event) => this.setState({isSampleDelivery: !this.state.isSampleDelivery})}/>

                    <Table compact basic fixed>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell style={{width: 100}}>Regel</Table.HeaderCell>
                                <Table.HeaderCell>Omschrijving</Table.HeaderCell>
                                <Table.HeaderCell style={{width: 100}}>Aantal</Table.HeaderCell>
                                <Table.HeaderCell style={{width: 150}}></Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>

                        <Table.Body>

                            <Table.Row>
                                <Table.Cell><Input fluid value={'1'}/></Table.Cell>
                                <Table.Cell><Input fluid value={project.name}/></Table.Cell>
                                <Table.Cell><Input fluid type={"number"} name={"quantity"} placeholder={"Aantal PCBA's"}
                                                   value={this.state.quantity}
                                                   onChange={(event) => this.setState({quantity: event.target.value})}/></Table.Cell>
                                <Table.Cell></Table.Cell>
                            </Table.Row>

                            {this.state.lines.map((l, i) => <Table.Row>

                                <Table.Cell>
                                    <Input fluid type={"number"} name={"number"} placeholder={"Regel"} value={l.number}
                                           disabled={l.pcbNumber != null}
                                           onChange={(event) => {
                                               console.log('number ' + event.target.value)
                                               let newObject = Object.assign({}, l, {number: event.target.value})
                                               let newList = this.state.lines.map((item, j) => i === j ? newObject : item);
                                               this.setState({lines: newList})
                                           }}/>
                                </Table.Cell>

                                <Table.Cell>
                                    <Input fluid type={"text"} name={"text"} placeholder={"Omschrijving"} value={l.description}
                                           disabled={l.pcbNumber != null}
                                           onChange={(event) => {
                                               let newObject = Object.assign({}, l, {description: event.target.value})
                                               let newList = this.state.lines.map((item, j) => i === j ? newObject : item);
                                               this.setState({lines: newList})
                                           }}/>
                                </Table.Cell>

                                <Table.Cell>
                                    <Input fluid type={"number"} name={"quantity"} placeholder={"Aantal"} value={l.quantity}
                                           disabled={l.pcbNumber != null}
                                           onChange={(event) => {
                                               let newObject = Object.assign({}, l, {quantity: event.target.value})
                                               let newList = this.state.lines.map((item, j) => i === j ? newObject : item);
                                               this.setState({lines: newList})
                                           }}/>
                                </Table.Cell>

                                <Table.Cell style={{textAlign: 'right'}}>
                                    <Button
                                        onClick={() => {
                                            this.setState({
                                                lines: [...this.state.lines.slice(0, i), ...this.state.lines.slice(i + 1)],
                                                quantity: (l.pcbNumber != null ? (this.state.quantity - 1) : this.state.quantity)
                                            })
                                        }}
                                    >Verwijderen</Button>
                                </Table.Cell>

                            </Table.Row>)}

                        </Table.Body>
                    </Table>
                    <div style={{textAlign: 'right'}}>
                        <Button onClick={() => this.setState({
                            lines: [...this.state.lines, {
                                number: this.state.lines.length + 2,
                                description: '',
                                quantity: ''
                            }]
                        })}>Regel toevoegen</Button>
                    </div>

                    <>
                        De klant heeft {this.props.project ? this.props.project.calculatorDetails.numPcbs : '?'} pcb's besteld.
                    </>

                    {this.props.pcbs && <div>

                        Er zijn {this.props.pcbs.length} pcb's bekend in dit project, met de volgende nummers:
                        <br/>

                        <List bulleted>
                            {this.props.pcbs.map(pcb => {
                                const inList = this.state.lines.find(l => l.pcbNumber === pcb.number)

                                return <List.Item>
                                    {pcb.number}

                                    <Icon color={inList ? 'grey' : 'blue'} disabled={inList} link name='plus' style={{marginLeft: 4}}
                                          onClick={() => this.setState({
                                              lines: [...this.state.lines, {
                                                  number: '',
                                                  description: 'PCB # ' + pcb.number,
                                                  quantity: '',
                                                  pcbNumber: pcb.number,
                                                  pcbType: pcb.type
                                              }],
                                              quantity: this.state.quantity + 1,
                                              isSampleDelivery: pcb.type ? pcb.type.startsWith('SAMPLE') : false
                                          })}/>

                                    ({pcb.type})

                                    {this.renderPcbLevering(pcb.number, this.props.eventList)}
                                </List.Item>
                            })}
                        </List>
                        <br/>

                        Druk op de + om de PCB's die opgestuurd worden toe te voegen aan de packingslip.
                    </div>}

                </Modal.Content>
                <Modal.Actions>
                    <Button onClick={() => this.setState({openCreate: false})}>Annuleren</Button>
                    <Button
                        primary
                        disabled={!this.state.quantity || this.props.creatingPackingSlip}
                        loading={this.props.creatingPackingSlip}
                        onClick={() => {
                            if( pcbType != null && pcbType.startsWith('SAMPLE') ) {
                                this.setState({openConfirmStateChange: true})
                            } else {
                                this.createPackingSlip(cPackingSlip)
                            }
                        }}
                    >
                        Aanmaken
                    </Button>
                </Modal.Actions>
            </Modal>

            <Modal open={this.state.openConfirmStateChange} onClose={() => this.setState({openConfirmStateChange: false})}>
                <Modal.Header>Sample state</Modal.Header>
                <Modal.Content>
                    <p>Deze packingslip bevat een sample. Wil je de status van het project veranderen naar 'Sample is shipped'?</p>
                    <p>{pcbType}</p>
                </Modal.Content>
                <Modal.Actions>
                    <Button onClick={() => this.setState({openConfirmStateChange: false})}>Annuleren</Button>
                    <Button onClick={() => {
                        this.createPackingSlip(cPackingSlip)
                    }}>Aanmaken zonder state te veranderen</Button>
                    <Button primary onClick={() => {
                        this.createPackingSlip(cPackingSlip, pcbType)
                    }}>Ja, aanmaken</Button>
                </Modal.Actions>
            </Modal>
        </>;
    }

    createPackingSlip(cPackingSlip, pcbType = 'NA') {
        this.setState({openConfirmStateChange: false})
        this.props.dispatch(createPackingSlip(this.props.projectId, {
            ...cPackingSlip, createdBy: this.props.currentUser.firstName
        }, pcbType, () => {
            this.setState({openCreate: false})
            if( pcbType !== 'NA') {
                window.location.reload();
            }
        }))
    }

    render() {

        let project= this.props.project;

        let cPackingSlip= {
            amount : this.state.quantity,
            cost: this.state.cost,
            lines: this.state.lines,
            isSampleDelivery: this.state.isSampleDelivery
        }

        return (
            <div style={{textAlign: 'left'}}>
                <BasicSheet
                    header={this.props.header}
                >
                    <div style={{minHeight: 32}}>
                        {this.props.invoice ? this.renderAddInvoice(project, cPackingSlip) : ''}
                        {this.props.packingslip ? this.renderAddPackingslip(project, cPackingSlip) : ''}

                        {this.props.fetchingEventList && <div>
                            Aangemaakte documenten ophalen...
                        </div>}

                        {(this.props.eventList && this.props.eventList.length === 0 && !this.props.fetchingEventList) && <div>
                            Er zijn nog geen documenten
                        </div>}

                        {(this.props.eventList && this.props.eventList.length > 0 && !this.props.fetchingEventList) && <div>
                            <p>Reeds aangemaakte documenten:</p>

                            <Table compact basic>
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell><Translate value="dashboard_type"/></Table.HeaderCell>
                                        <Table.HeaderCell><Translate value="dashboard_aangemaakt_door"/></Table.HeaderCell>
                                        <Table.HeaderCell><Translate value="dashboard_invoice_nummer"/></Table.HeaderCell>
                                        <Table.HeaderCell>PCB #</Table.HeaderCell>
                                        <Table.HeaderCell><Translate value='dashboard_datum'/></Table.HeaderCell>
                                        <Table.HeaderCell><Translate value='dashboard_aantal'/></Table.HeaderCell>
                                        <Table.HeaderCell><Translate value="dashboard_bedrag"/></Table.HeaderCell>
                                        <Table.HeaderCell collapsing></Table.HeaderCell>
                                        <Table.HeaderCell collapsing></Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>

                                <Table.Body>
                                    {this.props.eventList.filter(e => {
                                        if( this.props.packingslip ) {
                                            return !e.cost;
                                        } else if( this.props.invoice ) {
                                            return e.cost;
                                        }
                                        return false;
                                    }).map(e => (
                                        <Table.Row>
                                            <Table.Cell>
                                                <div>{e.cost ? "Invoice" : "Packing Slip"}</div>
                                                {e.isSampleDelivery ? <div>SAMPLE</div> : ''}
                                            </Table.Cell>
                                            <Table.Cell>{e.createdBy}</Table.Cell>
                                            <Table.Cell>{e.invoiceNumber}</Table.Cell>
                                            <Table.Cell>
                                                {e.lines ? <List bulleted items={e.lines.filter(p => p.pcbNumber).map(p => p.pcbNumber + ' (' + (p.pcbInfo ? p.pcbInfo.type : '?') + ')')}/> : ''}
                                            </Table.Cell>
                                            <Table.Cell>{formatDate( e.date )}</Table.Cell>
                                            <Table.Cell>{e.amount}</Table.Cell>
                                            <Table.Cell>{e.cost}</Table.Cell>
                                            <Table.Cell collapsing>
                                                {!e.cost ?
                                                    <Button download href={'/api/project/getPackingSlipPdf?id=' + e.id} icon='download'/>
                                                    :
                                                    <Button download href={'/api/project/getInvoicePdf?id=' + e.id} icon='download'/>
                                                }
                                            </Table.Cell>
                                            <Table.Cell collapsing>
                                                <Button
                                                    color='red'
                                                    icon labelPosition='left'
                                                    onClick={() => this.props.dispatch(openConfirmDeletePackingSlip(e.id))}
                                                >
                                                    <Icon name='trash' />
                                                    Verwijderen
                                                </Button>

                                                <Confirm
                                                    dimmer={'blurring'}
                                                    open={e.id === this.props.deletePackingSlipModal}
                                                    onCancel={() => this.props.dispatch(openConfirmDeletePackingSlip(null))}
                                                    onConfirm={() => {
                                                        this.props.dispatch(deletePackingSlip(e.id, this.props.projectId))
                                                    }}
                                                    content={'Weet je zeker dat je deze regel wilt verwijderen?'}
                                                    cancelButton='Nee'
                                                    confirmButton="Ja"
                                                />
                                            </Table.Cell>
                                        </Table.Row>
                                    ))}
                                </Table.Body>

                            </Table>
                        </div>}
                    </div>

                </BasicSheet>
            </div>
        );
    }


}

ProjectEventView.propTypes = {
    projectId: PropTypes.number,
    project: PropTypes.object,
    packingslip: PropTypes.bool,
    invoice: PropTypes.bool
};

ProjectEventView.defaultProps = {
    projectId: null,
    project: null,
    packingslip: false,
    invoice: false
};

const mapStateToProps = (state) => {
    return {
        lang: state.main.lang,
        eventViewOpen: state.project.eventViewOpen,
        creatingPackingSlip: state.project.creatingPackingSlip,
        fetchingEventList: state.project.fetchingEventList,
        eventList: state.project.eventList,
        priceList: state.project.priceList,
        deletePackingSlipModal: state.project.deletePackingSlipModal,
        pcbs: state.project.pcbs,
        currentUser: state.main.currentUser,
    }
}

export default connect(mapStateToProps)(ProjectEventView)
