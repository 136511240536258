import React from "react";
import {connect} from "react-redux";
import {getAllBomOrdersNeedCheckin} from "../actions";
import {Popup, Table} from "semantic-ui-react";
import {Link} from "react-router-dom";
import {formatDateTime} from "../actions/functions";
import {renderSupplier} from "./functions";

function itemNeedsCheckin(i) {
    return (i.boardLocation == null || i.boardLocation === '') && !i.expired
}

class BomOverviewCheckin2 extends React.Component {

    componentDidMount() {
        this.props.dispatch(getAllBomOrdersNeedCheckin())
    }

    getClassName(o) {
        return ''
    }

    render() {
        let list = this.props.bomOrderCheckinList;

        return <div>
            {this.props.bomOrderCheckinListBusy ? 'Een moment...' : ''}

            {list && <Table size='large'>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Project</Table.HeaderCell>
                        <Table.HeaderCell>Supplier</Table.HeaderCell>
                        <Table.HeaderCell>Order code</Table.HeaderCell>
                        <Table.HeaderCell>Parts</Table.HeaderCell>
                        <Table.HeaderCell>Parts not checked in</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>

                <Table.Body>

                    {list.map(o => (
                        <Table.Row key={o.id} className={'bst-prod-row ' + this.getClassName(o)}>
                            <Table.Cell>
                                <div>{o.print.projectOrder ? <Link  to={'/project/'+o.print.projectOrder.id+'/analysis'}>
                                    {o.print.projectOrder.internalName ? o.print.projectOrder.internalName : o.print.projectOrder.name}
                                </Link> : o.print.name}</div>

                                <div style={{opacity: .5}} >{(o.print && o.print.created) ? formatDateTime(o.print.created) : ''}</div>

                            </Table.Cell>
                            <Table.Cell>{renderSupplier({supplier: o.supplier})}</Table.Cell>
                            <Table.Cell>
                                {o.supplierOrderCode ? o.supplierOrderCode : o.supplierOrderNumber}
                            </Table.Cell>
                            <Table.Cell>

                                {o.items.length}

                            </Table.Cell>
                            <Table.Cell>

                                {o.items.filter(i => itemNeedsCheckin(i)).length}

                            </Table.Cell>

                        </Table.Row>
                    ))}



                </Table.Body>

                <Table.Footer>
                    <Table.Row>
                        <Table.HeaderCell/>
                        <Table.HeaderCell/>
                        <Table.HeaderCell/>
                        <Table.HeaderCell>{list.reduce((a, b) => a + b.items.length, 0)}</Table.HeaderCell>
                        <Table.HeaderCell>{list.reduce((a, b) => a + b.items.filter(i => itemNeedsCheckin(i)).length, 0)}</Table.HeaderCell>
                    </Table.Row>
                </Table.Footer>
            </Table>}
        </div>
    }
}

const mapStateToProps = (state) => {
    return {
        bomOrderCheckinList: state.bom.bomOrderCheckinList,
        bomOrderCheckinListBusy: state.bom.bomOrderCheckinListBusy,
    }
}

export default connect(mapStateToProps)(BomOverviewCheckin2)