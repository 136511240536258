import React from 'react';
import PropTypes from "prop-types";
import Highlighter from "react-highlight-words";

export const MAX_MOQ = 200;

export default class BomMoqDisplay extends React.Component {
    render() {
        const moq = this.props.moq;

        if( moq == null || moq === '' ) {
            return <div>??</div>
        }

        return <div>
            <Highlighter highlightClassName="Highlight" searchWords={[moq > MAX_MOQ ? moq.toString() : '']}
                         textToHighlight={moq.toString()}
            />
        </div>
    }
}

BomMoqDisplay.propTypes = {
    moq: PropTypes.number,
}

BomMoqDisplay.defaultProps = {
    moq: null
}