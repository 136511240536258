import React from 'react'
import {getNeed} from "./BomOrderparts";
import {CHECKIN_COLOR_GREEN, CHECKIN_COLOR_RED, getOrderItemsByRow} from "./functions";
import Pill from "../basic/Pill";
import {bomCheckin} from "../actions";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {isEmployee} from "../actions/functions";

class BomStock extends React.Component {

    state = {}

    render() {
        const {r} = this.props;

        if( r.pcbOrder || r.stencilOrder ) {
            return <div></div>
        }

        const need = getNeed(r.amount, this.props.print.amount, 0)
        let orderItems = getOrderItemsByRow(r, this.props.bomOrderList)

        let stock = 0
        if( r.selectedBuddyPart != null ) {
            stock = need;
        } else {
            stock = orderItems.reduce((s, o) => {
                return s + (o.stock != null ? o.stock : 0);
            }, 0)
        }

        const orderpartId= r.selectedSupplierPart ? r.selectedSupplierPart.orderpart.id : null
        const klantLevertAanId= r.klantLevertAan ? r.klantLevertAan.id : null
        const hasWarehousePart= getOrderItemsByRow(r, this.props.bomOrderList).some(o => o.supplier === 'WAREHOUSE')
        const hasAddWarehouse = isEmployee(this.props.currentUser) && (orderpartId || klantLevertAanId) && !hasWarehousePart

        return <div>
            <div className="cornertopright">
                <Pill color={(stock >= need) ? CHECKIN_COLOR_GREEN : CHECKIN_COLOR_RED} onClick={hasAddWarehouse ? () => {
                    let printId= this.props.print.id
                    this.setState({loading: true})
                    this.props.dispatch(bomCheckin({
                        receivedStock: 0,
                        boardLocation: '',
                        width: null,
                        pitch: null
                    }, printId, orderpartId, klantLevertAanId, () => {
                        this.setState({loading: false})
                    }))
                } : null} loading={this.state.loading}>
                    {stock}
                </Pill>
            </div>
            <div style={{marginLeft: -16}}>
                <Pill>{need}</Pill>
            </div>
        </div>
    }
}


BomStock.propTypes = {
    r: PropTypes.object
}

const mapStateToProps = (state) => {
    return {
        currentUser: state.main.currentUser,
        print: state.bom.print,
        bomOrderList: state.bom.bomOrderList,
    }
}

export default connect(mapStateToProps)(BomStock)