import React, { Component } from 'react';
import './App.css';

class Tips extends Component{
    render() {
        return (
            <section className='pageContainer'>
                <a href='https://www.linkedin.com/in/harmslijkhuis/detail/recent-activity/posts/'>De tips</a>
            </section>
        );
    }
}

export default Tips;