import React from 'react'
import connect from "react-redux/es/connect/connect";
import {withRouter} from "react-router-dom";
import ProjectChanges from "./ProjectChanges";
import {isEmployee} from "./actions/functions";
import {
    addLineProductionRelease, cancelProductionRelease, fetchProject,
    removeLineProductionRelease,
    setValueProductionRelease,
    submitProductionRelease
} from "./actions";
import {Divider} from "semantic-ui-react";
import ProtoFotoView from "./ProtoFotoView";

class ProjectListOfChange extends React.Component {

    state = {}

    render() {
        const {dispatch, project} = this.props;

        let isSerie = this.props.isSerie

        if( !isSerie && this.props.status === 'inactive' ) {
            return <div style={{marginTop: 8, marginBottom: 50}}>
                <section className={'projectTabs paper'} id={'checkInTab'} style={{marginTop: 0}}>
                    Deze fase is nog niet actief
                </section>
            </div>
        }

        const isReleased = this.props.releaseProduction.some(releaseProduction => releaseProduction.released)

        return <div style={{marginTop: 8, marginBottom: 50}}>

            {this.props.releaseProduction.map(releaseProduction => {

                let id = releaseProduction.id;
                
                let sampleNr = ''

                if( project.sample === 'checked' ) {
                    sampleNr = '2'
                }
                if( project.sample2 === 'checked' ) {
                    sampleNr = '3'
                }
                if( project.sample3 === 'checked' ) {
                    sampleNr = '3'
                    isSerie = true;
                }

                const enabledstates = ['ready', 'shipped', 'received']
                const hiddenstates = ['checked']

                return <div style={{marginBottom: 32}} key={releaseProduction.id}>

                    <ProjectChanges
                        action={isSerie ? null : 'RELEASE'}
                        action2={isSerie ? null : sampleNr === '3' ? null : 'SAMPLE'}
                        action3={isSerie ? null : sampleNr === '3' ? null : 'REPAIR'}
                        disabled={isReleased || !enabledstates.includes(project['sample'+sampleNr])}
                        showInputNew={!isReleased}
                        allowChange={isEmployee(this.props.currentUser)}
                        sampleCheckedDate={releaseProduction.checkedDate}
                        saving={this.props.savingProductionRelease === releaseProduction.id}
                        saved={this.state.saved === releaseProduction.id}
                        value={releaseProduction}
                        error={this.props.errorProductionRelease === releaseProduction.id}
                        onChangeValue={(i, name, value) => {
                            dispatch(setValueProductionRelease(id, i, name, value))
                        }}
                        onRemoveLine={(i) => dispatch(removeLineProductionRelease(id, i))}
                        onAddLine={(line, file) => dispatch(addLineProductionRelease(id, line, file))}
                        onSave={(action) => {

                            let m_action = action + sampleNr;

                            let lines = releaseProduction.lines.map((l,i) => {
                                return {...l, line: ( l.line == null ) ? i + 1 : l.line}
                            })

                            dispatch(submitProductionRelease({...releaseProduction, lines}, releaseProduction.id, project.id, m_action, () => {
                                //this.setState({saved: releaseProduction.id});

                                if( action === '' ) {
                                    //setTimeout(function (t) {
                                    //    t.setState({saved: false})
                                    //}, 1000, this);
                                } else {
                                    setTimeout(function (t) {
                                        t.props.dispatch(fetchProject(t.props.project.id))
                                    }, 100, this);
                                }

                                //if (action === 'PROCESSED') {
                                    //this.props.dispatch(fetchProject(this.props.project.id))
                                //}
                            }))
                        }}
                        onCancel={(released) => {
                            this.props.dispatch(cancelProductionRelease(releaseProduction.id, released, () => {
                                this.props.dispatch(fetchProject(this.props.project.id))
                            }))
                        }}
                    />

                    <>
                        <Divider/>
                        <ProtoFotoView pageName={'sample' + (sampleNr ? sampleNr : '')}/>
                    </>
                </div>
            })}



        </div>
    }
}

const mapStateToProps = (state) => {
    return {
        currentUser: state.main.currentUser,
        lang: state.main.lang,
        currentLang: state.main.currentLang,
        project: state.project.project,
        savingProject: state.project.savingProject,
        pcbs: state.project.pcbs,
        releaseProduction: state.project.releaseProduction,
        errorProductionRelease: state.project.errorProductionRelease,
        savingProductionRelease: state.project.savingProductionRelease,
        projectModification: state.project.projectModification,
        errorProjectModification: state.project.errorProjectModification,
        savingProjectModification: state.project.savingProjectModification,
        pcbWapperMode: state.project.pcbWapperMode,
        showTop: state.project.showTop
    }
}

export default connect(mapStateToProps)(withRouter(ProjectListOfChange))