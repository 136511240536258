import React from 'react';
import {connect} from "react-redux";
import {Button, Dropdown, Icon, Loader, Modal} from "semantic-ui-react";
import { Document, Page } from 'react-pdf';

import { pdfjs } from 'react-pdf';
import PropTypes from "prop-types";
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

class PdfViewer extends React.Component {

    state = {}

    renderPdf(url) {
        return <div style={{height: '607px', overflow: 'auto', maxWidth: '100%'}}>
            <Document
                noData={
                    <div>
                        <Icon style={{marginTop: '30%'}} name='file pdf outline'/><br/>
                        Hier komt een preview
                    </div>
                }
                loading={
                    <div>
                        <Icon style={{marginTop: '30%'}} name='file pdf outline'/><br/>
                        Een moment aub...
                    </div>
                }
                file={url}
                onLoadSuccess={({numPages}) => {
                    this.setState({numPages});
                    if( this.props.onLoadSuccess ) {
                        this.props.onLoadSuccess(numPages);
                    }
                }}
            >
                {
                    Array.from(
                        new Array(this.state.numPages),
                        (el, index) => (
                            <Page
                                className={'pdf_canvas'}
                                key={`page_${index + 1}`}
                                pageNumber={index + 1}
                            />
                        ),
                    )
                }
            </Document>
        </div>
    }

    render() {
        return this.renderPdf(this.props.url);
    }
}

PdfViewer.propTypes = {
    url: PropTypes.string.isRequired,
    onLoadSuccess: PropTypes.func,
};

PdfViewer.defaultProps = {
    id: null,
    onLoadSuccess: null,
};

const mapStateToProps = (state) => {
    return {
    }
}

export default connect(mapStateToProps)(PdfViewer);