import React, {Component} from 'react';
import PropTypes from "prop-types";
import {
    Button,
    Card, Checkbox, Confirm,
    Dropdown,
    Form, Grid,
    Header,
    Icon,
    Image,
    Input, Loader,
    Menu,
    Message, Modal, Segment,
    Step, Tab,
    Table, TextArea
} from "semantic-ui-react";

import {
    bestelFase,
    encodeQueryData, encodeUriObject, fases,
    fetchData, formatDate, formatDateTime, getFase, getDate,
    handleErrors,
    isEmployee, leveringFase,
    voorbereidingFase, bestelBronnen, getMissingSuppliers, projectStatus
} from "../actions/functions";
import Calculator from "./Calculator";

import 'moment/locale/nl.js'
import MyInfo from "./MyInfo";
import {getText} from "../Translate";
import connect from "react-redux/es/connect/connect";
import Translate from "../Translate";
import {
    addProjectAttachment,
    fetchProject,
    fetchProjectEvents,
    getProjectAttachments,
    getProjectCentroid,
    getProjectOrderList,
    getProjectOrderPcbInfo,
    saveProjectOrderPcbInfo,
    sendProtoFotoMail,
} from "../actions";
import ProjectEventView from "./ProjectEventView";
import UploadFile from "./UploadFile";
import ProjectViewPcb from "./ProjectViewPcb";
import LoginSignup from "../LoginSignup";

var moment = require('moment');
moment.locale('nl');

class ProjectView extends Component {

    state= {
        project: null,
        user: null,
        error: null,
        busy: false,
        projectid: null,
        thanks: false,
        allUsers: [],
        changeOwner: null,
        changeField: null,
        bestellingen: false,
        newProject: null,
        dirty: false,
        invoiceNum: null,
        quantity: null,
        activeIndex: 0
    }

    constructor(props) {
        super(props);

        this.handleChange = this.handleChange.bind(this)
        this.renderAction = this.renderAction.bind(this)
    }

    componentDidMount() {


        this.props.dispatch(fetchProject(this.props.projectId))

    }

    changeOwner(value) {

        this.setState({saved: false, busy: true})

        fetchData( 'api/project/changeOwner' + encodeUriObject(value) )
            .then(response => handleErrors(response).json())
            .then(json => {

                this.setState({project: json, saved: true, busy: false, cancelConfirm: false, changeField: null})

                setTimeout(() => {
                    this.setState({saved: false})
                }, 5000);
            })
            .catch(error => {
                this.setState({error, busy: false, cancelConfirm: false});
                console.log(error)
            })

    }

    saveProject( value, args ) {

        this.setState({saved: false, busy: true})

        fetchData( '/api/project/save' + encodeQueryData(args), value )
            .then(response => handleErrors(response).json())
            .then(json => {
                this.setState({project: json, saved: true, busy: false, cancelConfirm: false, changeField: null, bestellingen: false})

                if( args && args.action === "ORDER" ) {
                    this.setState({thanks: true})
                }

                setTimeout(() => {
                    this.setState({saved: false, dirty: false})
                }, 5000);
            })
            .catch(error => {
                this.setState({error, busy: false, cancelConfirm: false});
                console.log(error);
            })
    }

    getAref(user, name, value) {
        if(user) {
            return <a href={'/api/project/getProjectFile' + encodeQueryData(value)} download>{name}</a>
        } else {
            return name;
        }
    }

    changeProjectValue = (name, value) => {
        let obj= {};
        obj[name]= value;
        let newProject= Object.assign({}, this.props.project, obj);
        this.setState({project: newProject, dirty: true})
    }

    renderStep(project, name, secondary, options, onChange) {

        let mFase= getFase(project[name], fases[name]);
        let secStyle= Object.assign({}, mFase.style, {fontSize: '14px'});

        let mStyle= mFase.style;

        if( project.projectStatus != null && project.projectStatus !== 'NA') {

            let mFase= getFase(project.projectStatus, fases['projectStatus']);

            mStyle= Object.assign({}, mStyle, mFase.style);
            secStyle= Object.assign({}, secStyle, Object.assign({}, mFase.style, {fontSize: '14px'}));

            if( !secondary ) {
                secondary = mFase.text;
            }

        }

        return <Step style={mStyle}>
            <Icon style={{size: '1.7em'}} name={mFase.icon} />
            <Step.Content>
                <Step.Title>
                    {options ?
                        <Dropdown options={options} trigger={mFase.text} onChange={onChange} /> :
                        mFase.text}
                </Step.Title>
                <Step.Description>
                    <div style={secStyle}>{secondary}</div>
                </Step.Description>
            </Step.Content>
        </Step>
    }

    traslateTable= {
        name: getText(this.props, 'project_name'),
        reference: getText(this.props, 'project_user_reference'),
        orderDate: getText(this.props, 'project_orderdate'),
        owner: getText(this.props, 'project_owner'),
        internalName: getText(this.props, 'project_internal_name'),
        bomId: 'BOM referentie',
        projectStatus: getText(this.props, 'project_status')
    }

    openChangeItem(fieldName) {

        switch (fieldName) {
            case 'orderDate':
            case 'deliveryDate':
                this.setState({changeValue: formatDate(this.props.project[fieldName]), changeField: fieldName})
                break;
            default:
                this.setState({changeValue: this.props.project[fieldName], changeField: fieldName})
                break;
        }

    }

    renderChangeItem(name) {
        switch (name) {
            case 'orderDate':
            case 'deliveryDate':
                return <Input fluid value={this.state.changeValue} onChange={(e, {value}) => this.setState({changeValue:value})} />
            case 'owner':
                return <Dropdown placeholder={getText(this.props, "project_owner")} onChange={(e, { value, text }) => {
                    //console.log(JSON.stringify(value))
                    this.setState({changeValue: value})
                }} value={this.state.changeValue} fluid search selection options={this.state.allUsers} />
            case 'projectOwner':
                return <Dropdown placeholder={getText(this.props, "project_owner")} onChange={(e, { value, text }) => {
                    //console.log(JSON.stringify(value))
                    this.setState({changeValue: value})
                }} value={this.state.changeValue} fluid search selection options={this.state.allUsers.filter(u => {
                    let show= false;

                    u.value.roles && u.value.roles.forEach(r => {
                        if( r.name === "ROLE_EMPLOYEE" ) {
                            show= true;
                        } else if( r.name === "ROLE_ADMIN" ) {
                            show= true;
                        }
                    })

                    return show
                })} />
            case 'projectStatus':
                return <Dropdown pointing placeholder={getText(this.props, "project_status")} options={projectStatus} value={this.state.changeValue} onChange={(e, {value}) => this.setState({changeValue:value})}/>
            default:
                return <Input fluid value={this.state.changeValue && this.state.changeValue} onChange={(e, {value}) => this.setState({changeValue: value})} />
        }
    }


    onChangeBestellingen(index, name, value) {

        console.log('onChangeBestellingen' + index + name + value)

        let newProject= Object.assign({}, this.state.newProject, {
            bestellingen: this.state.newProject.bestellingen.map((b, i) => {
                if( i === index ) {
                    let obj= {};
                    obj[name]= value;
                    return Object.assign({}, b, obj)
                } else {
                    return b;
                }
            })
        })

        this.setState({newProject: newProject, dirty: true})
    }

    onAddBestellingen() {
        let newProject= Object.assign({}, this.state.newProject, {
            bestellingen: [...this.state.newProject.bestellingen, {}]
        })

        this.setState({newProject: newProject, dirty: true})
    }

    onRemoveBestellingen(index) {
        let newProject= Object.assign({}, this.state.newProject, {
            bestellingen: this.state.newProject.bestellingen.slice(0,index).concat(this.state.newProject.bestellingen.slice(index+1))
        })

        this.setState({newProject: newProject, dirty: true})
    }

    onOpenBestellingen(project) {
        let addSuppliers= [];

        getMissingSuppliers(project).forEach(m => {
            let add= true;

            this.props.project.bestellingen.forEach(e => {

                if( m === 'PCB' ) {
                    if( e.supplier && e.supplier.endsWith('_PCB') ) {
                        add= false;
                    }
                } else  if( m === 'STENCIL' ) {
                    if( e.supplier && e.supplier.endsWith('_STENCIL') ) {
                        add= false;
                    }
                } else {
                    if( e.supplier && (m === e.supplier) ) {
                        add= false;
                    }
                }

            })

            if( add ) {
                addSuppliers.push({
                    supplier: (m === 'STENCIL') ? 'ALPHAASSEMBLY_STENCIL' : (m === 'PCB') ? 'PCBWAY_PCB' : m,
                    supplierOrderNumber: ''
                })
            }
        })

        let newProject= Object.assign({}, this.props.project, {
            bestellingen: (this.props.project.bestellingen ? this.props.project.bestellingen : []).concat( addSuppliers )
        })

        this.setState({newProject: newProject, bestellingen: true, error: null})
    }

    stylePerProjectFirst = {
        minWidth: '500px',
        display: 'inline-block',
        boxShadow: 'none',
        height: 420,
        verticalAlign: 'top',
        marginTop: 0
    }

    stylePerProject = {
        marginleft: 16,
        minWidth: '500px',
        display: 'inline-block',
        boxShadow: 'none',
        height: 420,
        verticalAlign: 'top',
        marginTop: 0
    }

    renderBestellingen(project) {

        return <Table celled>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell>Leverancier</Table.HeaderCell>
                    <Table.HeaderCell>Order code van de leverancier</Table.HeaderCell>
                    <Table.HeaderCell>Status</Table.HeaderCell>
                </Table.Row>
            </Table.Header>

            <Table.Body>
                {project.bestellingen && project.bestellingen.sort( (a,b) => {
                    let objA= bestelBronnen.find((e) => e.value === a.supplier);
                    let objB= bestelBronnen.find((e) => e.value === b.supplier);

                    return ( objA ? objA.order : 99 ) - ( objB ? objB.order : 99 );
                } ).map((b, i) =>
                    <Table.Row>
                        <Table.Cell>
                            <Dropdown pointing className='link item' placeholder='Bestelling' selection options={bestelBronnen} value={b.supplier} onChange={(e,d) => this.onChangeBestellingen(i, 'supplier', d.value)} />
                        </Table.Cell>
                        <Table.Cell>
                            <Input value={b.supplierOrderNumber} onChange={(e, { name, value }) => this.onChangeBestellingen(i, 'supplierOrderNumber', value)}/>
                        </Table.Cell>
                        <Table.Cell>
                            <div>{b.status ? b.status : "Nog niet bekend"} <Button basic floated='right' icon='remove' title='Verwijderen' onClick={() => this.onRemoveBestellingen(i)} /></div>

                        </Table.Cell>
                    </Table.Row>
                )}

            </Table.Body>

            <Table.Footer>
                <Table.Row>
                    <Table.HeaderCell colSpan='3'>
                        <Button floated='right' title='Leverancier toevoegen' onClick={() => this.onAddBestellingen()}><Translate value="add"/></Button>
                    </Table.HeaderCell>
                </Table.Row>
            </Table.Footer>
        </Table>
    }

    onSaveItem(fieldName, value) {


        if( fieldName === 'orderDate' || fieldName === 'deliveryDate' ) {

            let obj= {};
            obj[fieldName]= getDate(value);
            let newProject= Object.assign({}, this.props.project, obj);

            this.saveProject(newProject, null);

        }
        else if( fieldName === 'owner' ) {
            this.changeOwner({
                projectId: this.props.project.id,
                userId: value.id
            })
        } else {
            let obj= {};
            obj[fieldName]= value;
            let newProject= Object.assign({}, this.props.project, obj);

            this.saveProject(newProject, null);
        }


    }

    renderAdmin(project) {

        return <div style={{paddingTop: 0, paddingBottom: 0}}>
            <Modal dimmer={false} closeIcon={true} open={this.state.changeField != null} onClose={() => this.setState({changeField: null})}>
                <Modal.Header>{this.state.changeField && this.traslateTable[this.state.changeField]}</Modal.Header>
                <Modal.Content>
                    <Modal.Description>
                        {this.state.error && <Message negative>
                            <Message.Header><Translate value="wrong"/></Message.Header>
                            <p>{JSON.stringify(this.state.error)}</p>
                        </Message>}
                        <p>{this.renderChangeItem(this.state.changeField)}</p>
                    </Modal.Description>
                </Modal.Content>
                <Modal.Actions>
                    <Button onClick={() => this.setState({changeField: null, error: null})}>
                        <Icon name='remove' /> <Translate value="cancel"/>
                    </Button>
                    <Button primary disabled={this.state.busy} onClick={() => {
                        this.onSaveItem(this.state.changeField, this.state.changeValue);
                    }}>
                        <Icon name='checkmark' /> {this.state.busy ? <Translate value="moment"/> : this.state.saved ? <Translate value="saved"/> : <Translate value="save"/>}
                    </Button>
                </Modal.Actions>
            </Modal>


            {this.state.changeOwner != null && <Confirm
                dimmer={'blurring'}
                open={true}
                onCancel={() => this.setState({changeOwner: null})}
                onConfirm={() => this.changeOwner(this.state.changeOwner)}
                content={'Wilt u de eigenaar van het project '+this.state.changeOwner.projectName+' veranderen?'}
                cancelButton='Nee'
                confirmButton="Ja"
            />}

            {this.state.error && <Message negative>
                <Message.Header><Translate value="wrong"/></Message.Header>
                <p>{JSON.stringify(this.state.error)}</p>
            </Message>}

            <Table fixed celled columns={3}>
                <Table.Body>
                    <Table.Row>
                        <Table.Cell>
                            <div><button className="link-button" style={{color: 'black'}} onClick={() => this.openChangeItem('owner')} title="aanpassen">{project.owner.firstName + " " + (project.owner.tussenvoegsel ? project.owner.tussenvoegsel + ' ' : '') + project.owner.lastName}</button> <Icon name='share square' link onClick={() => window.open('/account.html?id='+project.owner.id+'&lang='+this.props.lang.lang)}/></div>
                            <div><button className="link-button" onClick={() => this.openChangeItem('name')} title="aanpassen">{project.name}</button> | <button className="link-button" onClick={() => this.openChangeItem('reference')} title="aanpassen">{project.reference ? '( ' + project.reference + ' )' : '( ' +getText(this.props, 'project_user_reference')+ ' ) '}</button></div>
                        </Table.Cell>
                        <Table.Cell>
                            <div>{project.owner.email}</div>
                            <div style={{whiteSpace: 'pre-wrap'}}>{project.owner.telefoon ? project.owner.telefoon : ''}</div>
                        </Table.Cell>
                        <Table.Cell>
                            <div>{project.calculatorDetails.numPcbs} PCBA's ( <button className="link-button" onClick={() => this.openChangeItem('internalName')} title="aanpassen">{project.internalName ? project.internalName : 'nog geen interne naam'}</button> )</div>
                            <div>
                                {project.calculatorDetails.selectedDays + ' '+getText(this.props, "calc_workdays")+' ('}
                                {project.orderDate && <button className="link-button" onClick={() => this.openChangeItem('orderDate')} title="aanpassen">{formatDate( project.orderDate )}</button>}
                                {project.orderDate && ' => '}
                                <button className="link-button" onClick={() => this.openChangeItem('deliveryDate')} title="aanpassen"><strong>{formatDate( project.deliveryDate )}</strong></button>
                                {project.deliveryDate && <MyInfo message={getText(this.props, "project_we_expect_delivery")}/>}
                                {' )'}
                                </div>
                        </Table.Cell>
                    </Table.Row>
                </Table.Body>
            </Table>

            <Form>
                <TextArea autoHeight placeholder='Opmerkingen' value={project.keep} onChange={(e) => this.changeProjectValue('keep', e.target.value)} />
            </Form>

            <Step.Group widths={3} style={{width: '100%', marginLeft: 1, marginRight: 1}}>

                {this.renderStep(project, 'bestelFase', formatDateTime( project.orderDate ), bestelFase, (e,d) => this.changeProjectValue('bestelFase', d.value))}
                {this.renderStep(project, 'voorbereidingFase', null, voorbereidingFase, (e,d) => this.changeProjectValue('voorbereidingFase', d.value))}
                {this.renderStep(project, 'leveringFase', null, leveringFase, (e,d) => this.changeProjectValue('leveringFase', d.value))}

            </Step.Group>

            <div style={{textAlign: 'right', paddingBottom: 16}}>
                <Button primary={this.state.dirty} disabled={this.state.busy} positive={this.state.saved} onClick={() => {
                    this.saveProject(project)
                }}>{this.state.busy ? 'Een moment...' : this.state.saved ? 'Opgeslagen' : 'Opslaan'}</Button>
            </div>

            {/*this.renderComment(project)*/}

            <Tab menu={{ secondary: true, pointing: true }} activeIndex={this.state.activeIndex} onTabChange={(e,data) => {

                this.handleTabChange(this.props.project, true, data.activeIndex)

            }} panes={[
                {menuItem: 'Opdracht', render: () => <Calculator asForm={false} remark={project.calculatorDetails.remark} data={project.calculatorDetails} orderid={this.props.project.id} isAdmin={true} onChange={this.handleChange} locked={false}/>},
                {menuItem: 'Inkoop', render: () => <div>
                        <Grid columns={5}>
                            <Grid.Column>
                                <Button fluid type='button' content='Bestellingen'  onClick={() => this.onOpenBestellingen(project)}/>
                            </Grid.Column>
                            <Grid.Column>
                                BomId: <button className="link-button" style={project.bomId ? {} : {color: 'red'}} onClick={() => this.openChangeItem('bomId')} title="aanpassen">{project.bomId ? project.bomId : 'Geen BOM referentie'}</button>
                            </Grid.Column>
                            <Grid.Column>
                                Project Status: <button className="link-button" onClick={() => this.openChangeItem('projectStatus')} title="aanpassen">{project.projectStatus ? project.projectStatus : 'Geen'}</button>
                            </Grid.Column>
                            <Grid.Column>

                            </Grid.Column>
                            <Grid.Column>

                            </Grid.Column>
                        </Grid>

                        <Segment style={{boxShadow: 'none'}}>

                            <div style={this.state.bestellingen ? {height: 32, cursor: 'pointer'} : {cursor: 'pointer'}} onClick={() => this.state.bestellingen ? this.setState({bestellingen: false}) : this.onOpenBestellingen(project)}>
                                <Icon name={this.state.bestellingen ? 'chevron up' : 'chevron down'} />
                                Project status informatie
                            </div>

                            {this.state.bestellingen && <div>
                                <p>{this.renderBestellingen(this.state.newProject)}</p>

                                <Button primary floated='right' disabled={this.state.busy} onClick={() => {
                                    this.saveProject(this.state.newProject)
                                }}>
                                    <Icon name='checkmark' /> {this.state.busy ? 'Een moment...' : this.state.saved ? 'Opgeslagen' : 'Opslaan'}
                                </Button>
                            </div>}

                        </Segment>
                    </div>},
                {menuItem: 'Productie', render: () => <div>

                        <div>
                            <p>Project eigenaar:</p>
                            <button className="link-button" onClick={() => this.openChangeItem('projectOwner')} title="aanpassen">
                                {project.projectOwner ? (project.projectOwner.firstName + " " + (project.projectOwner.tussenvoegsel ? project.projectOwner.tussenvoegsel + ' ' : '') + project.projectOwner.lastName) : "Geen eigenaar"}
                            </button>
                        </div>

                        <br/>
                        <br/>

                        <div>
                            <h1>Project bestanden</h1>
                            <UploadFile text={"Bestand toevoegen"} url={'api/project/addAttachment?projectOrderId='+project.id} onResult={(body) => {
                                this.props.dispatch(addProjectAttachment(body))
                            }}/>

                            {this.props.attachmentList.map(a => <div><a download href={"api/project/getAttachment?id"+a.id}>{a.name}</a></div> )}
                        </div>

                        {/*<div>
                            <ProjectViewGerber project={project} projectId={project.id}/>
                        </div>*/}

                    </div>},
                {menuItem: <Menu.Item name="ProtoFoto" onClick={() => this.props.dispatch(getProjectOrderPcbInfo(project.id))}>ProtoFoto</Menu.Item>, render: () => <div>

                        <div id="popupwapper" hidden={!this.state.showWapper} className="overlay">
                            <div className="popupmax">
                                <div style={{textAlign: 'right'}}>
                                    <Button onClick={() => this.setState({showWapper: !this.state.showWapper})}>Sluiten</Button>
                                </div>
                            </div>

                        </div>

                        {this.props.pcbs.map(p => <ProjectViewPcb key={p.id} id={p.id} project={project}/>)}

                        <div style={{textAlign: 'right', marginTop: 16}}>
                            <Button onClick={() => this.setState({showWapper: true})}>Wapper test</Button>
                            <Button primary onClick={() => this.props.dispatch(saveProjectOrderPcbInfo({number: "", remarks: ""}, project.id))}>Add PCB</Button>
                        </div>

                        <div style={{textAlign: 'right', marginTop: 16}}>
                            <Button primary icon='send' labelPosition='left' loading={this.props.sendProtoFotoBusy} disabled={this.props.sendProtoFotoBusy} content='Bericht naar klant versturen' onClick={() => this.props.dispatch(sendProtoFotoMail(project.id))}/>
                            {this.props.sendProtoFotoResult}
                        </div>

                </div>},
                {menuItem: <Menu.Item name='Levering' onClick={() => this.props.dispatch(fetchProjectEvents(project.id))}/>, render: () => <div>

                        <div style={{textAlign: 'right', marginTop: 16}}>

                            {isEmployee(this.props.currentUser) && project && this.props.eventViewOpen && <ProjectEventView projectId={project.id} project={project} />}

                        </div>

                    </div>}
            ]} />


        </div>
    }

    handleChange(value) {
        this.setState({
            project: Object.assign({}, this.props.project, {calculatorDetails: value})
        })
    }

    isLocked(status) {
        if( status === "AANVRAAG" || status === "GEREED" ) {
            return false;
        } else {
            return true;
        }
    }

    handleTabChange(project, isAdmin, activeIndex) {
        this.setState({ activeIndex })

        if( isAdmin ) {
            if (activeIndex === 1) {
                this.onOpenBestellingen(project)
            } else if (activeIndex === 2) {
                this.props.dispatch(getProjectAttachments(project.id))
            } else if (activeIndex === 3) {
                this.props.dispatch(getProjectCentroid(project.id))
                this.props.dispatch(getProjectOrderList(project.id))
                this.props.dispatch(getProjectOrderPcbInfo(project.id))
            }
        }
        else {
            if (parseInt(activeIndex) === 1) {
                this.props.dispatch(getProjectCentroid(project.id))
                this.props.dispatch(getProjectOrderList(project.id))
                this.props.dispatch(getProjectOrderPcbInfo(project.id))
            }
        }
    }

    renderAction() {

        let project= this.props.project;
        let hasAddress= project.owner.deliveryAddress != null && project.owner.deliveryAddress.length > 0 && project.owner.deliveryAddress[0] !== "";

        return <div>

            {hasAddress ? <div style={{marginBottom: 14, whiteSpace: 'pre'}}>{project.owner.deliveryAddress[0].address}</div> : <div style={{marginBottom: 14}}><Translate value="project_order_need_details_1"/> <a href='/account'><Translate value="project_order_need_details_2"/></a> <Translate value="project_order_need_details_3"/></div>}

            {(project.bestelFase === "AANVRAAG" || project.bestelFase === "GEREED") && <Button type="button" size='large' title='Project aanvraag annuleren' disabled={this.state.busy || (project.projectStatus !== 'NA')} positive={this.state.saved} onClick={() => {
                this.setState({cancelConfirm: true})
            }}>{this.state.busy ? getText(this.props, "moment_please") : this.state.saved ? getText(this.props, "canceled") : getText(this.props, "cancel")}</Button>}

            {project.bestelFase === "GEREED" && <Button type="button" size='large' color='red'  data-tip data-for='global' disabled={this.state.busy || (project.projectStatus !== 'NA')} positive={this.state.saved} style={{marginLeft: 16, cursor: hasAddress ? '' : 'not-allowed'}} onClick={() => {
                if( hasAddress ) {
                    this.saveProject(project, {action: "ORDER"})
                }
            }}>{this.state.busy ? getText(this.props, "moment_please") : this.state.saved ? getText(this.props, "ordered") : getText(this.props, "order")}</Button>}
        </div>
    }

    renderUser(project) {

        return <div style={{width: '100%', paddingTop: 0, paddingBottom: 0}}>

            <Modal size='tiny' open={this.state.thanks} onClose={() => this.setState({thanks: false})}>
                <Modal.Header style={{textAlign: 'center', fontSize: '3em'}}><Translate value="project_thanks_order"/></Modal.Header>
                <Modal.Content style={{textAlign: 'center'}} >
                    <Header><Translate value="project_thanks_we_start_immediatly"/></Header>
                    <Image centered style={{aligned: 'center'}} wrapped size='medium' src='/images/undraw_To_the_stars_qhyy.svg' />

                </Modal.Content>
            </Modal>

            <Modal dimmer={false} closeIcon={true} open={this.state.changeField != null} onClose={() => this.setState({changeField: null})}>
                <Modal.Header>{this.state.changeField && this.traslateTable[this.state.changeField]}</Modal.Header>
                <Modal.Content>
                    <Modal.Description>
                        {this.state.error && <Message negative>
                            <Message.Header><Translate value="er_isiets_mis_gegaan"/></Message.Header>
                            <p>{JSON.stringify(this.state.error)}</p>
                        </Message>}
                        <p>{this.renderChangeItem(this.state.changeField)}</p>
                    </Modal.Description>
                </Modal.Content>
                <Modal.Actions>
                    <Button onClick={() => this.setState({changeField: null, error: null})}>
                        <Icon name='remove' /> Annuleren
                    </Button>
                    <Button primary disabled={this.state.busy} onClick={() => {
                        this.onSaveItem(this.state.changeField, this.state.changeValue);
                    }}>
                        <Icon name='checkmark' /> {this.state.busy ? <Translate value="moment_please"/> : this.state.saved ? <Translate value="saved"/> : <Translate value="save"/>}
                    </Button>
                </Modal.Actions>
            </Modal>

            {this.state.error && <Message negative>
                <Message.Header><Translate value="wrong"/></Message.Header>
                <p>{JSON.stringify(this.state.error)}</p>
            </Message>}

            <Confirm
                open={this.state.cancelConfirm}
                onCancel={() => this.setState({cancelConfirm: false})}
                onConfirm={() => this.saveProject(project, {action: "CANCEL"})}
                content={getText(this.props, "project_cancel_order")}
                cancelButton={getText(this.props, "no")}
                confirmButton={getText(this.props, "yes")}
            />

            <Table fixed celled columns={3}>
                <Table.Body>
                    <Table.Row>
                        <Table.Cell>
                            <div>{project.owner.firstName + " " + (project.owner.tussenvoegsel ? project.owner.tussenvoegsel + ' ' : '') + project.owner.lastName}</div>
                            <div><button className="link-button" onClick={() => this.openChangeItem('name')} title={getText(this.props, "change")}>{project.name}</button> | <button className="link-button" onClick={() => this.openChangeItem('reference')} title={getText(this.props, "change")}>{project.reference ? '( ' + project.reference + ' )' : '( '+getText(this.props, "project_user_reference")+' ) '}</button></div>
                        </Table.Cell>
                        <Table.Cell>
                            <div>{project.owner.email}</div>
                            <div style={{whiteSpace: 'pre-wrap'}}>{project.owner.telefoon ? project.owner.telefoon : ' '}</div>
                        </Table.Cell>
                        <Table.Cell>
                            <div>{project.calculatorDetails.numPcbs} PCBA's</div>
                            {project.deliveryDate != null ? <div><strong>{formatDate( project.deliveryDate )}</strong> <MyInfo message={getText(this.props, "project_we_expect_delivery")}/> ({project.calculatorDetails.selectedDays + ' ' + getText(this.props, "calc_workdays")})</div> : <div>{project.calculatorDetails.selectedDays + ' ' + getText(this.props, "calc_workdays")}</div>}
                        </Table.Cell>
                    </Table.Row>
                </Table.Body>
            </Table>

            <Step.Group widths={3} style={{width: '100%', marginLeft: 1, marginRight: 1}}>

                {this.renderStep(project, 'bestelFase', formatDateTime( project.orderDate ))}
                {this.renderStep(project, 'voorbereidingFase')}
                {this.renderStep(project, 'leveringFase')}

            </Step.Group>

            {this.state.error && <Message negative>
                <Message.Header><Translate value="wrong"/></Message.Header>
                <p><Translate value="wrong_sorry"/></p>
            </Message>}

            <Tab menu={{ secondary: true, pointing: true }} style={{marginTop: 14}} activeIndex={this.state.activeIndex} onTabChange={(e,data) => {

                this.handleTabChange( this.props.project, false, data.activeIndex )

            }} panes={[
                {menuItem: 'Opdracht', render: () => <Calculator asForm={false} data={project.calculatorDetails} orderid={this.props.project.id} onChange={this.handleChange} locked={true} dateAmountLocked={this.isLocked(project.bestelFase)} renderAction={this.renderAction}/>},
                {menuItem: <Menu.Item name={"ProtoFoto"} onClick={() => this.props.dispatch(getProjectOrderPcbInfo(project.id))}>ProtoFoto</Menu.Item>, render: () => <div>

                        {this.props.pcbs.map(p => <ProjectViewPcb key={p.id} id={p.id} project={project} isAdmin={false}/>)}

                        {this.props.pcbs && this.props.pcbs.length === 0 && <div style={{textAlign: 'center', marginTop: 64, marginBottom: 24}}>Er zijn nog geen ProtoFoto's</div>}

                    </div>}
            ]} />



        </div>
    }

    render() {

        let project= this.props.project;
        let fetchingProject= this.props.fetchingProject;

        return (
            <div style={{textAlign: 'left', paddingLeft: '', width: '100%'}}>
                <Card fluid>
                    <Card.Content style={{padding: 40}}>

                        {isEmployee(this.props.currentUser) && <div style={{marginTop: -47, marginLeft: -50, position: 'fixed'}} title="Gebruiker pagina bekijken">
                            <Checkbox float={'right'} slider onChange={() => this.setState({renderAsUser: !this.state.renderAsUser})} />
                        </div>}

                        {!project && this.props.currentUser && fetchingProject && <Translate value="moment_please"/>}

                        {!project && this.props.currentUser && !fetchingProject && <Translate value="project_notfound"/>}

                        {!project && !this.props.currentUser && !fetchingProject && <div>
                            <Translate value="project_need_to_login"/>
                            <br/>
                            <br/>
                            <LoginSignup inline={true} onLoginSuccess={() => this.props.dispatch(fetchProject(this.props.projectId))}/>
                        </div>}

                        {project && (
                            (isEmployee(this.props.currentUser) && !this.state.renderAsUser) ? this.renderAdmin(project) : this.renderUser(project)
                        )}

                    </Card.Content>
                </Card>
            </div>
        );
    }
}

ProjectView.propTypes = {
    projectId: PropTypes.string,
};

ProjectView.defaultProps = {
    projectId: null,
};

const mapStateToProps = (state) => {
    return {
        lang: state.main.lang,
        currentLang: state.main.currentLang,
        currentUser: state.main.currentUser,
        project: state.project.project,
        fetchingProject: state.project.fetchingProject,
        eventViewOpen: state.project.eventViewOpen,
        attachmentList: state.project.attachmentList,
        centroid: state.project.centroid,
        orderList: state.project.orderList,
        pcbs: state.project.pcbs,
        sendProtoFotoBusy: state.project.sendProtoFotoBusy,
        sendProtoFotoResult: state.project.sendProtoFotoResult,
    }
}

export default connect(mapStateToProps)(ProjectView)
