import React, {Component} from 'react';
import {
    Button,
    Card, Checkbox,
    Form, Grid,
    Header, Icon, Select,
} from "semantic-ui-react";

import {
    encodeQueryData,
    fetchData,
    getURLParameter,
    handleErrors,
    isEmployee,
} from "../actions/functions";
import AccountAddress from "./AccountAddress";
import connect from "react-redux/es/connect/connect";
import {getText} from "../Translate";
import Translate from "../Translate";
import PropTypes from "prop-types";
import {mohicanSendMessage, zoekKvkHandelsnaam, zoekKvkNummer} from "../actions";
import _ from "lodash";

var moment = require('moment');

const optionsCountry = [
    { key: 'NL', text: 'Nederland', value: 'NL' },
    { key: 'DE', text: 'Deutschland', value: 'DE' },
    { key: 'BE', text: 'België', value: 'BE' },
    { key: 'FR', text: 'France', value: 'FR' },
];

class AccountView extends Component {

    state= {
        project: null,
        user: null,
        editUser: null,
        error: null,
        busy: false,
        projectid: null,
        thanks: false,
        touched: false
    }

    constructor(props) {
        super(props);

        this.handleChange = this.handleChange.bind(this)
    }

    componentDidMount() {

        moment.locale('nl');

        this.validateLogin();
        let id= getURLParameter("id");

        if( this.props.id ) {
            this.fetchUser(this.props.id)
        }
        else if( id ) {
            this.fetchUser(id)
        } else {
            this.fetchUser(null)
        }

    }

    fetchUser(id) {

        this.setState({busy: true, error: false})

        fetchData( '/api/project/getUser' + ( id ? encodeQueryData({id}) : '' ) )
            .then(response => handleErrors(response).json())
            .then(json => {
                console.log(json)
                this.setState({editUser: json, busy: false});
            })
            .catch(error => {
                console.log(error)
                this.setState({error: true, busy: false});
            })

    }

    validateLogin() {

        this.setState({busy: true, error: false})

        fetchData( '/user/validate' )
            .then(response => handleErrors(response).json())
            .then(json => {
                console.log(json)
                this.setState({user: json, busy: false});
            })
            .catch(error => {
                console.log(error)
                this.setState({error: true, busy: false});
            })

    }

    handleChange = (e, { name, value }) => {
        let usr= this.state.editUser;
        usr[name]= value;
        console.log(usr)
        this.setState({ editUser: usr, touched: true })

        if( e !== null && name === 'companyName' ) {
            this.deb(value);
        }
    }

    handleChangeCheckbox = (e, { name, value }) => {
        let usr= this.state.editUser;
        usr[name]= !usr[name];
        this.setState({ editUser: usr, touched: true }, () => this.handleSubmitUser())
    }

    handleChangeSelect = (e, { name, value }) => {
        let usr= this.state.editUser;
        usr[name]= value;
        this.setState({ editUser: usr, touched: true })
    }

    handleSubmitUser = () => {

        this.setState({
            saveSuccess: false,
            saveFailed: false,
            loading: true,
        });

        fetchData( '/api/user/saveUser', this.state.editUser )
            .then(response => handleErrors(response).json())
            .then(json => {
                this.setState({editUser: json, saveSuccess: true, loading: false, touched: false})

                setTimeout((savedUser) => {
                    this.setState({saveSuccess: false})
                    if( this.props.onSaved ) {
                        this.props.onSaved(savedUser)
                    }
                }, 1000, json);
            })
            .catch(error => {
                console.log(error)
                this.setState({error: '', saveFailed: true, loading: false})

                setTimeout(() => {
                    this.setState({saveFailed: false})
                }, 5000);
            })
    }

    handleChangeDeliveryAddress = (value) => {

        let usr= this.state.editUser;
        usr.deliveryAddress= usr.deliveryAddress.map(a => {
            if( a.id === value.id ) {
                return value;
            }
            return a;
        });
        this.setState({ editUser: usr })

    }

    handleDeleteDeliveryAddress = (id) => {
        let usr= this.state.editUser;
        usr.deliveryAddress= usr.deliveryAddress.filter(a => {
           return a.id !== id
        });
        this.setState({ editUser: usr }, () => this.handleSubmitUser())
    }

    handleSetDefaultDeliveryAddress = (id) => {
        let usr= this.state.editUser;
        usr.deliveryAddress= usr.deliveryAddress.map(a => {
           if( a.id === id ) {
               return {...a, isDefault: true}
           }
           return {...a, isDefault: false}
        });
        this.setState({ editUser: usr }, () => this.handleSubmitUser())
    }

    handleChangeInvoiceAddress = (value) => {

        let usr= this.state.editUser;
        usr.invoiceAddress= [value];
        this.setState({ editUser: usr })

    }

    handleAddDeliveryAddress = () => {
        let usr= this.state.editUser;
        usr.deliveryAddress= [...usr.deliveryAddress, {open: true}];
        this.setState({ editUser: usr })
    }

    sortDeliveryAddress = (a,b) => {
        return b.isDefault - a.isDefault;
    }

    renderField = (label, user, name, disabled, onBlur ) => {
        return (
            <Form.Input label={getText(this.props, label)} name={name} placeholder={getText(this.props, label)} value={user[name]} disabled={disabled} onChange={this.handleChange} onBlur={onBlur}/>
        )
    }

    searchCompany = (name) => {
        this.props.dispatch(zoekKvkHandelsnaam(name, () => {
            this.setState({openOptions: true})
        }))
    }

    deb= _.debounce(this.searchCompany, 300);

    getCompanyOptions = () => {
        return this.props.kvkSearchResult.map((item) => {
            return {key: item.kvkNummer+'-'+item.type, value: item, text: item.handelsnaam, description: item.kvkNummer}
        })
    }

    renderUser = (user) => {
        return <Form>
            <Form.Field>
                {this.renderField('login_mail_address', user, 'email', true)}
            </Form.Field>

            <Form.Field>
                {this.renderField('user_firstname', user, 'firstName', this.state.loading)}
            </Form.Field>
            <Form.Field>
                {this.renderField('user_tussenvoegsel', user, 'tussenvoegsel', this.state.loading)}
            </Form.Field>
            <Form.Field>
                {this.renderField('user_surname', user, 'lastName', this.state.loading)}
            </Form.Field>
            <Form.Field>
                {this.renderField('user_email_invoice', user, 'emailInvoice', this.state.loading)}
            </Form.Field>
            <Form.Field>
                {this.renderField('user_phone', user, 'telefoon', this.state.loading)}
            </Form.Field>
            <Form.Field>
                {this.renderField('user_mobiel', user, 'mobiel', this.state.loading)}
            </Form.Field>


            <Form.Field>
                <Form.Field control={Select} label={getText(this.props, "user_vat_country")} options={optionsCountry} placeholder={getText(this.props, "user_vat_country")} onChange={this.handleChangeSelect} disabled={this.state.loading}/>
            </Form.Field>
            <Form.Field>
                {this.renderField('user_vat_code', user, 'btwNumber', this.state.loading)}
            </Form.Field>
            <Form.Field>
                {this.renderField('user_kvk_code', user, 'kvkNumber', this.state.loading, () => {
                    //this.props.dispatch(zoekKvkNummer(user.kvkNumber))
                })}
            </Form.Field>
            <Form.Field>
                {this.renderField('user_company_name', user, 'companyName', this.state.loading)}

                <Form.Dropdown open={this.state.openOptions} openOnFocus={false} trigger={''} text={<></>}
                               options={this.getCompanyOptions()} icon={null} fluid direction='down'
                               onChange={(event, data) => {
                                   if (data.value) {
                                       this.handleChange(null, {
                                           name: 'companyName',
                                           value: data.value.handelsnaam
                                       });
                                       this.handleChange(null, {
                                           name: 'kvkNumber',
                                           value: data.value.kvkNummer
                                       });
                                       setTimeout(() => {
                                           this.setState({openOptions: false});
                                       }, 100);
                                   }
                               }}/>
            </Form.Field>
            {isEmployee(this.props.currentUser) ? <Form.Field>
                {this.renderField('user_internal_company_name', user, 'internalCompanyName', this.state.loading)}
            </Form.Field> : null}

            <Form.Field>
                <div>{user.companyAddress}</div>
            </Form.Field>


            <Form.Field>
                <Checkbox label={getText(this.props, "user_want_newsletter")} name='newsletter' checked={user.newsletter} onChange={this.handleChangeCheckbox} disabled={this.state.loading}/>
            </Form.Field>

            { isEmployee(this.props.currentUser) && <Form.Field>
                <Checkbox label='Domain admin' name='domainAdmin' checked={user.domainAdmin} onChange={this.handleChangeCheckbox} disabled={this.state.loading}/>
            </Form.Field> }
        </Form>
    }

    render() {

        let user= this.state.editUser;
        let deliveryAddress= this.state.editUser && this.state.editUser.deliveryAddress && this.state.editUser.deliveryAddress[0] ? this.state.editUser.deliveryAddress[0] : {country: 'Nederland'};
        let invoiceAddress= this.state.editUser && this.state.editUser.invoiceAddress && this.state.editUser.invoiceAddress[0] ? this.state.editUser.invoiceAddress[0] : {country: 'Nederland'};

        return (
            <section className={this.props.asModal ? '' : "pageContainer"} style={{textAlign: 'left', paddingLeft: '', width: '100%'}}>
                <Card fluid>
                    <Card.Content style={{padding: 40}}>

                        <div style={{position: 'absolute', right: 8, top: 12, zIndex: 2}}>
                            {this.renderSaveButton()}
                        </div>

                        {this.state.busy && getText(this.props, "moment_please")}

                        {!this.state.busy && user != null && user.email == null && getText(this.props, "user_need_login")}

                        {user != null && user.email != null && <Grid stackable columns={3} divided>
                            <Grid.Row>
                                <Grid.Column>
                                    <Header><Translate value="user_details"/></Header>
                                    {this.renderUser(user)}
                                </Grid.Column>
                                <Grid.Column>
                                    <Header><Translate value="user_delivery_address"/></Header>

                                    {this.state.editUser.deliveryAddress.sort(this.sortDeliveryAddress).map((a) => {

                                        return <AccountAddress address={a} loading={this.state.loading}
                                                               canHasDefault={true}
                                                               onChange={this.handleChangeDeliveryAddress} onSave={this.handleSubmitUser}
                                                               onDelete={this.handleDeleteDeliveryAddress} onSetDefault={this.handleSetDefaultDeliveryAddress}
                                        />
                                    })}

                                    <div style={{float: 'right'}}>
                                        <Button primary icon labelPosition='left' onClick={this.handleAddDeliveryAddress}>
                                            <Icon name='add'/>
                                            <Translate value='add'/>
                                        </Button>
                                    </div>
                                </Grid.Column>
                                <Grid.Column>
                                    <div style={{overflow: 'auto', paddingBottom: 14}}>
                                        <div style={{float: 'left'}}>
                                            <Header>
                                                <Translate value="user_invoice_address"/>
                                            </Header>
                                        </div>
                                        <div style={{marginTop: 36}}>
                                            <Checkbox toggle label={getText(this.props, "user_address_same_delivery")} name='factuurAddressIsTheSame' checked={user.factuurAddressIsTheSame} onChange={this.handleChangeCheckbox} disabled={this.state.loading}/>
                                        </div>
                                    </div>
                                    <div>
                                        {invoiceAddress && (user.factuurAddressIsTheSame == null || !user.factuurAddressIsTheSame) &&
                                        <AccountAddress canHasDefault={false} address={invoiceAddress} loading={this.state.loading} onChange={this.handleChangeInvoiceAddress}
                                                        onSave={this.handleSubmitUser} color={'red'}
                                        /> }
                                    </div>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>}

                        <div style={{position: 'absolute', right: 8, bottom: 12, zIndex: 2}}>
                            {this.renderSaveButton()}
                        </div>
                    </Card.Content>
                </Card>
            </section>
        );
    }

    renderSaveButton = () => {

        let user= this.state.editUser;

        if(user != null && user.email != null) {
            return <div className='accountSaveButton'>
                <Button primary={this.state.touched} positive={this.state.saveSuccess}
                        negative={this.state.saveFailed} loading={this.state.loading}
                        disabled={this.state.loading}
                        onClick={this.handleSubmitUser}>{this.state.saveSuccess ? getText(this.props, "saved") : this.state.saveFailed ? getText(this.props, "login_failed") : getText(this.props, "save")}</Button>
            </div>
        }

        return <div></div>
    }
}

AccountView.propTypes = {
    id: PropTypes.number,
    onSaved: PropTypes.func,
    asModal: PropTypes.bool
};

AccountView.defaultProps = {
    id: null,
    onSaved: null,
    asModal: false
};

const mapStateToProps = (state) => {
    return {
        lang: state.main.lang,
        currentUser: state.main.currentUser,
        kvkSearchResult: state.main.kvkSearchResult,
        kvkSearching: state.main.kvkSearching,
    }
}

export default connect(mapStateToProps)(AccountView)