import React from 'react'
import TableResize from "../basic/TableResize";
import {connect} from "react-redux";
import {
    changeOrderPartValue,
    fetchAllOrderparts,
    revertOrderPartValue,
    saveOrderpart,
    updateOrderPart
} from "../actions";
import {renderImageForPart} from "./BomOrderparts";
import {renderSupplier, renderSupplierUrl} from "./functions";
import {formatDateTime} from "../actions/functions";
import {Button, Dropdown, Icon, Input, TextArea} from "semantic-ui-react";
import Translate from "../Translate";

export const typeOptions = [
    "UNKNOWN",
    "RESISTOR",
    "CAPACITOR",
    "MICROCONTROLLER",
    "DIODE",
    "INDUCTOR",
    "CONNECTOR"
].map(v => ({key: v, text: v, value: v}));

export const statusOptions = [
    "NA",
    "CHECKED",
    "CHANGED"
].map(v => ({key: v, text: v, value: v}));

class BomOrderpartList extends React.Component {

    componentDidMount() {
        this.props.dispatch(fetchAllOrderparts())
    }

    render() {
        return (
            <div>

                {this.props.orderpartsPage? <div>
                    Showing {this.props.orderparts.length} out of total {this.props.orderpartsPage.totalElements} parts
                </div> : ''}

                <TableResize
                    defaultWidth={200}
                    headers={[
                        {name: 'mpn', text: 'mpn'},
                        {name: 'octopartUrl', text: 'octopartUrl', width: 100},
                        {name: 'image', text: 'image', width: 50},
                        {name: 'shortDescription', text: 'shortDescription'},
                        {name: 'dpDescription', text: 'dpDescription'},
                        {name: 'dpChoice', text: 'dpChoice', width: 100},
                        {name: 'type', text: 'type', width: 100},
                        {name: 'specification', text: 'specification', width: 250},
                        {name: 'lastUpdate', text: 'lastUpdate', width: 150},
                        {name: 'suppliers', text: 'suppliers', width: 350},
                        {name: 'pitch', text: 'pit', width: 40},
                        {name: 'width', text: 'wit', width: 40},
                        {name: 'status', text: 'Status', width: 100},
                        {name: 'update', text: '', width: 111}
                    ]}
                    values={this.props.orderparts}
                    fetching={this.props.orderpartsPage.fetching}
                    last={this.props.orderpartsPage.last}
                    fetchNextPage={() => {
                        this.props.dispatch(fetchAllOrderparts(this.props.orderpartsPage.number + 1))
                    }}
                    render={(name, value) => {

                        switch (name) {
                            case 'lastUpdate':
                                let lastUpdate = null;
                                value.suppliers.forEach((s) => {
                                    if( lastUpdate == null || s.lastUpdate < lastUpdate ) {
                                        lastUpdate = s.lastUpdate
                                    }
                                })

                                return <div>
                                    <div>{lastUpdate ? formatDateTime(lastUpdate) : ''}</div>

                                </div>;
                            case 'suppliers':
                                return <div>
                                    {value.suppliers.map(s => (<div>
                                        {renderSupplier(s)}
                                        {renderSupplierUrl(s)}
                                    </div>))}
                                </div>
                            case 'octopartUrl':
                                return <div>
                                    <a href={value[name]}>link</a>
                                </div>;
                            case 'image':
                                return <div>
                                    {renderImageForPart(value)}
                                </div>;
                            case 'specification':
                                return <div>
                                    <div>{value.specification.displayValue}</div>
                                    <button onClick={() => this.props.dispatch(updateOrderPart(value.id))}>update</button>
                                </div>;
                            case 'type':
                                return <div>
                                    <Dropdown value={value.type} options={typeOptions} onChange={(e,d) => {
                                        this.props.dispatch(changeOrderPartValue(value.id, 'type', d.value))
                                    }}/>
                                </div>;
                            case 'status':
                                return <div>
                                    <Dropdown value={value.status} options={statusOptions} onChange={(e,d) => {
                                        this.props.dispatch(changeOrderPartValue(value.id, 'status', d.value))
                                    }}/>
                                </div>;
                            case 'shortDescription':
                                return <div>
                                    {value.shortDescription} <Icon name='copy' link
                                                                   onClick={() => this.props.dispatch(changeOrderPartValue(value.id, 'dpDescription', value.shortDescription))}/>
                                </div>
                            case 'dpDescription':
                                return <TextArea autoHeight transparent fluid value={value.dpDescription}
                                                 onChange={e => this.props.dispatch(changeOrderPartValue(value.id, 'dpDescription', e.target.value))}
                                />;
                            case 'dpChoice':
                                return <div style={{textAlign: 'center'}}>
                                    <Icon name={value.dpChoice ? 'heart' : 'heart outline'} link color='red'
                                        onClick={() => this.props.dispatch(changeOrderPartValue(value.id, 'dpChoice', !value.dpChoice))}
                                    />
                                </div>
                            case 'update':
                                return <div>
                                    <Button.Group vertical>
                                        <Button disabled={!value.dirty} primary onClick={() => {
                                            this.props.dispatch(saveOrderpart(value))
                                        }}><Translate value="save"/></Button>
                                        <Button disabled={!value.dirty} onClick={() => {
                                            this.props.dispatch(revertOrderPartValue(value.id))
                                        }}><Translate value="cancel"/></Button>
                                    </Button.Group>
                                </div>;
                            case 'pitch':
                                return <Input transparent fluid value={value.pitch} onChange={e => this.props.dispatch(changeOrderPartValue(value.id, 'pitch', e.target.value))}/>
                            case 'width':
                                return <Input transparent fluid value={value.width} onChange={e => this.props.dispatch(changeOrderPartValue(value.id, 'width', e.target.value))}/>
                            default:
                                return <div>{value[name]}</div>
                        }
                    }}
                />
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        orderparts: state.bom.orderparts,
        orderpartsPage: state.bom.orderpartsPage
    }
}

export default connect(mapStateToProps)(BomOrderpartList)