import React from 'react';
import {
    changeNexarSetting,
    getNexarClientIdAndSecret,
    setNexarClientIdAndSecret,
    testNexar
} from "./actions";
import {Button, Form, Icon, Modal, Popup} from "semantic-ui-react";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import {formatDateTime} from "./actions/functions";
import Translate from "./Translate";

class NexarAuthentication extends React.Component {

    state = {
        connectTest: true
    }

    componentDidMount() {
        this.props.dispatch(getNexarClientIdAndSecret())

        if( this.props.statusOnly ) {
            setTimeout(() => {
                this.props.dispatch(getNexarClientIdAndSecret())
            }, 5000)
        }
    }

    render() {

        let d= this.props.nexar ? this.props.nexar : {};

        if( this.props.statusOnly ) {
            return <>
                <Popup
                    position='bottom center'
                    trigger={d.isConnected? <Icon name='check circle' color='green'/> : <Icon name='remove circle' color='red'/>}
                    content={'Nexar API is ' + (d.isConnected ? 'verbonden' : 'niet verbonden')}
                />

            </>
        }

        return <div>
            <Button style={{float: 'right'}} onClick={() => this.props.dispatch(getNexarClientIdAndSecret())}>Refresh</Button>

            <h1>Nexar {d.isConnected? <Icon name='check circle' color='green'/> : <Icon name='remove circle' color='red'/>}</h1>

            <Form>
                <Form.Input label='clientId' value={d.clientId} onChange={(e) => this.props.dispatch(changeNexarSetting('clientId', e.target.value))}/>
                <Form.Input label='clientSecret' value={d.clientSecret} onChange={(e) => this.props.dispatch(changeNexarSetting('clientSecret', e.target.value))}/>
                <Form.Input label='redirectUrl' value={d.redirectUrl} onChange={(e) => this.props.dispatch(changeNexarSetting('redirectUrl', e.target.value))}/>

                <div>
                    {d.expires}
                    {formatDateTime()}
                </div>

                <Form.Button primary={d.dirty}
                    onClick={() => this.props.dispatch(setNexarClientIdAndSecret(d))}
                ><Translate value='save'/></Form.Button>
            </Form>

            <div style={{marginTop: 16}}>
                <Button onClick={() => this.props.dispatch(testNexar((res) => {
                    this.setState({connectTest: res.isConnected, testOpen: true})
                }))} >Test connection</Button>

                <Modal
                    size={'mini'}
                    open={this.state.testOpen}
                    onClose={() => this.setState({testOpen: false})}
                >
                    <Modal.Header>Connection</Modal.Header>
                    <Modal.Content>
                        <p>{this.state.connectTest ? <Icon name='check circle' color='green'/> : <Icon name='remove circle' color='red'/>}</p>
                        <p>{this.state.connectTest ? 'Connection is OK' : 'Connection failed'}</p>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button onClick={() => this.setState({testOpen: false})}>
                            Sluiten
                        </Button>
                    </Modal.Actions>
                </Modal>
            </div>
        </div>
    }
}

NexarAuthentication.propTypes = {
    statusOnly: PropTypes.bool
}

const mapStateToProps = (state) => {
    return {
        nexar: state.main.nexar
    }
}

export default connect(mapStateToProps)(NexarAuthentication)