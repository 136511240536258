import React, {Component} from 'react';
import {collectparams} from "../actions/functions";
import {
    changeApplicationSettings,
    closeCalculatorSettings,
    saveApplicationSettings
} from "../actions";
import {Button, Grid, Header, Input, Label, List, Modal} from "semantic-ui-react";
import connect from "react-redux/es/connect/connect";
import UploadFile from "./UploadFile";
import Translate from "../Translate";

class CalculatorSettings extends Component {

    componentDidMount() {

        let lang= collectparams('lang')

        this.setState({lang: lang ? lang : 'nl'})

    }

    componentDidUpdate() {

    }

    handleClose = () => {
        this.props.dispatch(closeCalculatorSettings())
    }

    handleSettingsChange = (e, { name, value, index, checked }, additional) => {
        console.log(e)
        console.log(name)
        console.log(value)
        console.log(checked)

        this.props.dispatch(changeApplicationSettings(name, value))
    }

    render() {

        return (
            <div>



                {(this.props.calculatorSettingsBusy || this.props.calculatorSettingsError || this.props.calculatorSettings.length) ? <Modal
                    open={true}
                    onClose={this.handleClose}
                >
                    <Header icon='setting' content='' />
                    <Modal.Content>

                        <Grid columns={2} divided>
                            <Grid.Row>
                                <Grid.Column>
                                    <h1>Bestanden</h1>
                                    <List>
                                        {this.props.calculatorSettings.map((v) => (
                                            <List.Item><a download href={'/api/calculator/getfile?fileName='+encodeURIComponent(v)}>{v}</a></List.Item>
                                        ))}
                                    </List>

                                    <UploadFile text={"Bestand toevoegen of overschrijven"} url={'api/calculator/addfile'} onResult={(value) => {
                                        this.props.dispatch({type: 'RECEIVE_CALCULATOR_SETTINGS', value})
                                    }}/>
                                </Grid.Column>
                                <Grid.Column>
                                    <h1>Settings</h1>

                                    {this.props.applicationsettings && this.props.applicationsettings.filter((s) => s.key === "calculator.name").map((s) => {
                                        return <div>
                                            <Input labelPosition='right' action fluid name={s.key} value={s.value} onChange={this.handleSettingsChange}>
                                                <Label basic>{s.key}</Label>
                                                <input/>
                                                <Button primary={s.dirty} content={s.saving ? <Translate value='een_moment_aub'/> : <Translate value='save'/>} onClick={() => {
                                                    this.props.dispatch(saveApplicationSettings(s))
                                                }}/>
                                            </Input>

                                        </div>
                                    })}




                                </Grid.Column>
                            </Grid.Row>
                        </Grid>



                    </Modal.Content>
                    <Modal.Actions>
                        <Button color='green' onClick={this.handleClose}>
                            Sluiten
                        </Button>
                    </Modal.Actions>
                </Modal> : ''}

            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        lang: state.main.lang,
        currentLang: state.main.currentLang,
        calculatorSettings: state.main.calculatorSettings,
        calculatorSettingsBusy: state.main.calculatorSettingsBusy,
        calculatorSettingsError: state.main.calculatorSettingsError,
        applicationsettings: state.main.applicationsettings,
        applicationsettingsSaving: state.main.applicationsettingsSaving,
    }
}

export default connect(mapStateToProps)(CalculatorSettings)
