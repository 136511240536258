import React from 'react';
import connect from "react-redux/es/connect/connect";
import {withRouter} from "react-router-dom";
import {CSVLink} from "react-csv";
import moment from "moment/moment";
import {Button, Dropdown, Icon, Input, Menu, Modal, TextArea} from "semantic-ui-react";
import {addCompany, changeCompanyValue, getCompanyList, updateCompany} from "./actions";
import DeltaTableMenu from "./deltatable/DeltaTableMenu";
import DeltaTable from "./deltatable/DeltaTable";
import CSVDownload from "react-csv/src/components/Download";
import Translate from "./Translate";

class CompanyList extends React.Component {

    state = {}

    componentDidMount() {
        this.props.dispatch(getCompanyList())
    }

    handleChangeCompany(c, e) {
        this.props.dispatch(changeCompanyValue(c.id, e.target.name, e.target.value))
    }

    render() {
        const companyList = this.props.companyList;
        const headers = this.state.headersSharketing;

        return <main id={'companylist'} className={'pageContainerFullWidth'}>
            <div>

                <Modal
                    onClose={() => this.setState({addListOpen: false})}
                    closeIcon
                    open={this.state.addListOpen}
                    header='Lijst bedrijven toevoegen'
                    content={<Modal.Content>
                        Plaats op iedere regel een bedrijfsnaam die je wilt toevoegen.<br/>
                        Als je ook het kvk nummer weet, plaats deze dan achter een comma, na de bedrijfsnaam.
                        <TextArea
                            fluid style={{width: '100%'}}
                            value={this.state.addList}
                            onChange={(e) => this.setState({addList: e.target.value})}
                        />
                    </Modal.Content>}
                    actions={['Annuleren', { key: 'done', content: 'Toevoegen', primary: true, onClick: () => {
                            const list = this.state.addList.split(/\r?\n/).map(line => {
                                let value = line.trim()

                                if( line.includes(',') ) {
                                    let split = line.split(',')
                                    if( split.length > 1 ) {
                                        return {
                                            name: split[0].trim(),
                                            kvkNumber: split[1].trim(),
                                        }
                                    }
                                }

                                return {
                                    name: value
                                }


                            })

                            list.forEach(c => {
                                this.props.dispatch(updateCompany(c))
                            })
                        } }]}
                />
            </div>

            {this.state.downloadCsv ? <CSVDownload data={companyList.map(c => ({
                ...c,
                year: c.lastProjectOrderAanvraagDate ? moment( c.lastProjectOrderAanvraagDate ).format("YYYY") : '',
                lastProjectNumber: c.lastProjectOrderInternalName ? c.lastProjectOrderInternalName.replace(/[^\d]/g, "") : '',
            }))}/> : null}

            <DeltaTableMenu
                header={<Translate value='menu_company_overview'/>}
                menu={[
                    <Menu.Item onClick={() => this.setState({downloadCsv: true})}>
                        <Translate value="download_csv"/>
                    </Menu.Item>,
                    <Menu.Item onClick={() => this.setState({addListOpen: true})}>
                        <Translate value="add_list"/>
                    </Menu.Item>
                ]}
            >
                <DeltaTable
                    name={'companys'}
                    columns={[
                        {id: 1, header: 'Line', defaultWidth: 50, render: (c,i) => i},
                        {id: 2, header: 'KvK nummer', defaultWidth: 100, render: (c,i) => <div>
                                <Input fluid transparent name={'kvkNumber'} value={c.kvkNumber} onChange={e => this.handleChangeCompany(c, e)}/>
                            </div>},
                        {id: 3, header: 'Company', defaultWidth: 100, render: (c,i) => <div>
                                <Input fluid transparent name={'name'} value={c.name} onChange={e => this.handleChangeCompany(c, e)}/>
                            </div>},
                        {id: 4, header: 'ICC', defaultWidth: 100, render: (c,i) => <div>
                                <Input fluid transparent name={'internalName'} value={c.internalName} onChange={e => this.handleChangeCompany(c, e)}/>
                            </div>},
                        {id: 5, header: 'Laatste', defaultWidth: 100, render: (c,i) => <div>
                                {c.lastProjectOrderInternalName}
                        </div>},
                        {id: 6, header: 'Datum', defaultWidth: 100, render: (c,i) => <div>
                                { c.lastProjectOrderAanvraagDate ? moment( c.lastProjectOrderAanvraagDate ).format("YYYY") : ''}
                            </div>},
                        {id: 7, header: 'Type', defaultWidth: 100, render: (c,i) => <div>
                                <Dropdown fluid transparent name={'companyType'} value={c.companyType} onChange={(e,d) => this.props.dispatch(changeCompanyValue(c.id, 'companyType', d.value))}
                                          options={[
                                              'OEM',
                                              'STARTUP',
                                              'RD',
                                              'EDU',
                                              'EMS',
                                              'OTHER'
                                          ].map(o => ({key: o, text: o, value: o}))}
                                />
                            </div>},
                        {id: 8, header: 'Postcode', defaultWidth: 100, render: (c,i) => <div>
                                <Input fluid transparent name={'zip'} value={c.zip} onChange={e => this.handleChangeCompany(c, e)}/>
                            </div>},
                        {id: 9, header: 'Provincie', defaultWidth: 100, render: (c,i) => <div>
                                {c.provincie}
                            </div>},
                        {id: 10, header: 'Dropbox path', defaultWidth: 100, render: (c,i) => <div>
                                {c.dropboxPath}
                            </div>},
                        {id: 11, header: '', defaultWidth: 30, render: (c,i) => <div>
                                <Icon link name='save' color={c.dirty ? 'blue' : null} onClick={() => this.props.dispatch(updateCompany(c))}/>
                            </div>},
                    ]}
                    data={companyList}
                    loading={this.props.fetchingCompanyList}
                    onAddRow={() => this.props.dispatch(addCompany())}
                    addDisabled={companyList.some(p => p.isNew)}
                />
            </DeltaTableMenu>

            {/*<div id="scrolling" className="tableFixHead sharketingTable">
                <table className="ui celled very compact sortable table" style={{width: 'initial'}}>
                    <thead>
                    <tr>
                        <th style={{width: '50px'}}>Line</th>
                        <th style={{width: '280px'}}>KvK nummer</th>
                        <th style={{width: '280px'}}>Company</th>
                        <th style={{width: '280px'}}>ICC</th>
                        <th style={{width: '280px'}}>Laatste project</th>
                        <th style={{width: '280px'}}>Datum</th>
                        <th style={{width: '280px'}}>Type</th>
                        <th style={{width: '280px'}}>Postcode</th>
                        <th style={{width: '280px'}}>Provincie</th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    {companyList.map((c,i) => {
                        const dirty = c.dirty
                        return <tr>
                            <td>{i}</td>
                            <td><Input fluid transparent name={'kvkNumber'} value={c.kvkNumber} onChange={e => this.handleChangeCompany(c, e)}/></td>
                            <td><Input fluid transparent name={'name'} value={c.name} onChange={e => this.handleChangeCompany(c, e)}/></td>
                            <td><Input fluid transparent name={'internalName'} value={c.internalName} onChange={e => this.handleChangeCompany(c, e)}/></td>
                            <td>
                                {c.lastProjectOrderInternalName}
                            </td>
                            <td>
                                {formatDateTime(c.lastProjectOrderAanvraagDate)}
                                { c.lastProjectOrderAanvraagDate ? moment( c.lastProjectOrderAanvraagDate ).format("YYYY") : ''}
                            </td>
                            <td>
                                <Dropdown fluid transparent name={'companyType'} value={c.companyType} onChange={(e,d) => this.props.dispatch(changeCompanyValue(c.id, 'companyType', d.value))}
                                          options={[
                                              'OEM',
                                              'STARTUP',
                                              'RD',
                                              'EDU',
                                              'EMS',
                                              'OTHER'
                                          ].map(o => ({key: o, text: o, value: o}))}
                                />
                            </td>
                            <td><Input fluid transparent name={'zip'} value={c.zip} onChange={e => this.handleChangeCompany(c, e)}/></td>
                            <td>{c.provincie}</td>

                            <td>
                                <Button size={"tiny"} primary={dirty} onClick={() => this.props.dispatch(updateCompany(c))}>Opslaan</Button>
                            </td>
                        </tr>
                    })}
                    </tbody>
                </table>
            </div>*/}
        </main>
    }
}


const mapStateToProps = (state) => {
    return {
        lang: state.main.lang,
        currentUser: state.main.currentUser,
        sharketingPersonList: state.main.sharketingPersonList,
        companyList: state.main.companyList,
        fetchingCompanyList: state.main.fetchingCompanyList,
        sharketingSort: state.main.sharketingSort,
    }
}

export default connect(mapStateToProps)(withRouter(CompanyList))