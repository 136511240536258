import React from 'react'
import {connect} from "react-redux";
import PropTypes from "prop-types";
import {Button, Form, Icon, Loader, Popup} from "semantic-ui-react";
import {
    bomCheckin,
    getBomOrders,
    openBomOrder,
    saveBomOrder,
} from "../actions";
import Pill from "../basic/Pill";
import {CHECKIN_COLOR_FOR_1, CHECKIN_COLOR_GREY,
    CHECKIN_COLOR_RED, CHECKIN_COLOR_GREEN, CHECKIN_COLOR_YELLOW,
    getOrderItemsByRow,
    renderStock,
    renderSupplier,
    renderSupplierInformation, getOrderItemsByType} from "./functions";
import {formatDateTime} from "../actions/functions";

const inputStyle = {width: '72px', marginRight: 5}
const inputStyle2 = {width: '66px', marginRight: 5}


class BomCheckinItem extends React.Component {

    state = {
        receivedStock: '',
        boardLocation: null,
        width: null,
        pitch: null,
        saving: false
    }

    getValues(o) {
        let stock = o.stock != null ? o.stock : 0;

        let op = o.part ? o.part.orderpart : o.orderpart ? o.orderpart : (o.kla ? o.kla : {})

        let boardLocation= o.boardLocation;
        let width= op.width;
        let pitch= op.pitch;

        if( this.state.boardLocation !== null ) {
            boardLocation= this.state.boardLocation;
        }

        if( this.state.width !== null ) {
            width= this.state.width;
        }

        if( this.state.pitch !== null ) {
            pitch= this.state.pitch;
        }

        let color = '';

        if( !o.bomOrder ) {
            color = ''
        }
        else if( o.stock === null ) {
            color = CHECKIN_COLOR_GREY
        } else if( stock < o.orderAmount ) {
            if( this.props.r && this.props.amount && ((this.props.r.amount * this.props.amount)<= stock) ) {
                color = CHECKIN_COLOR_YELLOW
            }
            /*else if( this.props.r && (this.props.r.amount <= stock) ) {
                color = CHECKIN_COLOR_FOR_1
            }*/ else {
                color = CHECKIN_COLOR_RED
            }
        } else {
            color = CHECKIN_COLOR_GREEN
        }

        return {
            receivedStock: this.state.receivedStock,
            stock,
            boardLocation,
            width,
            pitch,
            color,
            id: o.id
        }
    }

    handleKeyPress = (e,v) => {
        if(e.key === 'Enter') {

            this.save(v)

        } else {

        }
    }

    save = (v) => {
        if( this.state.dirty && !this.state.saving && this.props.onSaveCheckin ) {
            this.setState({saving: true})
            this.props.onSaveCheckin(v, () => {
                this.setState({saving: false, receivedStock: '', dirty: false})
            })
        }
    }

    render() {

        let o = this.props.o;
        let i = this.props.i;

        let v = this.getValues(o);

        if( this.props.showStockOnly ) {
            return renderStock(v.stock, o.orderAmount, '')
        }

        if( this.props.showTrayOnly ) {
            return <div style={{textAlign: 'center'}}>{v.boardLocation}</div>
        }

        let boardLocations = this.props.boardLocations.filter(bl => bl.id !== o.id).filter(bl => bl.boardLocation === v.boardLocation)
        let duplicateTrayLane = boardLocations.length > 0

        return (
            <div style={{borderTop: i ? '1px solid lightgray' : ''}}>

                <div style={{display: "flex", gap: 4, margin: 10}}>
                    {renderSupplierInformation(o)}
                    <Pill color={v.color}>
                        <div style={{width: 64}}>
                            {o.bomOrder ? renderStock(v.stock, o.orderAmount, v.color) : v.stock}
                        </div>
                    </Pill>

                    {this.props.admin ? <>
                        <Form.Input fluid className={"checkin"} style={inputStyle} autoComplete="off" type="tel"
                                    value={this.state.receivedStock}
                                    onChange={(e) => this.setState({receivedStock: e.target.value, dirty: true})}
                                    placeholder="Qty" name="receivedStock"
                                    onKeyPress={(e) => {
                                        if (e.key === 'Enter') {
                                            e.preventDefault();
                                        }
                                    }}
                                    onKeyUp={(e) => {
                                        switch (e.keyCode) {
                                            case 37: //left
                                                break;
                                            case 13: //return
                                            case 39: //right
                                                if (this.boardLocation) this.boardLocation.focus();
                                                break;
                                        }
                                    }}
                        >
                            <input ref={(input) => this.receivedStock = input}/>
                        </Form.Input>
                        <span>
                                {duplicateTrayLane && <div style={{
                                    position: "absolute", zIndex: 2, marginLeft: 60,
                                    marginTop: -6
                                }}>
                                    <Icon name='warning sign' color='yellow'/>
                                </div>}
                            <Form.Input className={"checkin"} style={inputStyle} autoComplete="off"
                                        value={v.boardLocation} name="boardLocation" placeholder="TrayLane"
                                        onChange={(e) => this.setState({
                                            boardLocation: e.target.value.toUpperCase(),
                                            dirty: true
                                        })}
                                        onKeyPress={(e) => {
                                            if (e.key === 'Enter') {
                                                e.preventDefault();
                                            }
                                        }}
                                        onKeyUp={(e) => {
                                            switch (e.keyCode) {
                                                case 37: //left
                                                    if (this.receivedStock) this.receivedStock.focus();
                                                    break;
                                                case 13: //return
                                                case 39: //right
                                                    if (this.width) this.width.focus();
                                                    break;
                                            }
                                        }}
                            >
                                    <input ref={(input) => this.boardLocation = input}/>
                                </Form.Input>
                            </span>
                        <Form.Input className={"checkin"} style={inputStyle2} autoComplete="off" value={v.width}
                                    type="tel" name="width"
                                    onChange={(e) => this.setState({width: e.target.value, dirty: true})}
                                    placeholder="Width"
                                    onKeyPress={(e) => {
                                        if (e.key === 'Enter') {
                                            e.preventDefault();
                                        }
                                    }}
                                    onKeyUp={(e) => {
                                        switch (e.keyCode) {
                                            case 37: //left
                                                if (this.boardLocation) this.boardLocation.focus();
                                                break;
                                            case 13: //return
                                            case 39: //right
                                                if (this.pitch) this.pitch.focus();
                                                break;
                                        }
                                    }}
                        >
                            <input ref={(input) => this.width = input}/>
                        </Form.Input>
                        <Form.Input className={"checkin"} style={inputStyle2} autoComplete="off" value={v.pitch}
                                    type="tel" name="pitch"
                                    onChange={(e) => this.setState({pitch: e.target.value, dirty: true})}
                                    placeholder="Pitch"
                                    onKeyPress={(e) => {
                                        this.handleKeyPress(e, v)
                                    }}
                                    onKeyUp={(e) => {
                                        switch (e.keyCode) {
                                            case 37: //left
                                                if (this.width) this.width.focus();
                                                break;
                                        }
                                    }}
                        >
                            <input ref={(input) => this.pitch = input}/>
                        </Form.Input>


                        <Button
                            circular
                            color={'blue'}
                            content={'OK'}
                            loading={this.state.saving}
                            disabled={!this.state.dirty}
                            onClick={() => {
                                this.save(v)
                            }}
                        />
                    </> : ''}
                </div>

            </div>
        );
    }
}

class BomPcbStencilCheckinItem extends React.Component {

    state = {
        receivedStock: 0
    }

    save = () => {
        if( this.props.onSave ) {
            this.props.onSave( parseInt(this.state.receivedStock) )
        }
    }

    render () {
        let {r, o} = this.props;

        let color = CHECKIN_COLOR_GREEN

        if( o.amount === null ) {
            color = CHECKIN_COLOR_GREY
        }
        else if( o.stock < o.amount ) {
            if( r.pcbOrder && o.stock > 0 ) {
                color = CHECKIN_COLOR_FOR_1
            } else {
                color = CHECKIN_COLOR_RED;
            }
        }

        return <div key={o.id} style={{display: "flex", gap: 4, margin: 10}}>
            {o ? renderSupplierInformation(o) : null}

            <Pill color={color}>
                <div style={{width: 64}}>
                    {renderStock(o.stock, o.amount)}
                </div>
            </Pill>

            {this.props.admin ? <>
                <Form.Input fluid className={"checkin"} style={inputStyle} autoComplete="off" type="tel"
                            value={this.state.receivedStock}
                            onChange={(e) => this.setState({receivedStock: e.target.value, dirty: true})}
                            onKeyPress={(e) => {
                                if(e.key === 'Enter') {
                                    this.save()
                                    e.preventDefault();
                                }
                            }}
                >
                    <input />
                </Form.Input>

                <Button
                    circular
                    color={'blue'}
                    content={'OK'}
                    loading={this.state.saving}
                    disabled={!this.state.dirty}
                    onClick={() => {
                        this.save()
                    }}
                />
            </> : ''}
        </div>
    }
}

class BomCheckin extends React.Component {

    state = {
        receivedStock: '',
        boardLocation: null,
        width: null,
        pitch: null,
        saving: false
    }

    checkDisabled = () => {

        if( !this.state.receivedStock ) {
            return true;
        }

        return false
    }

    saveCheckin = (v, onSuccess) => {
        this.props.dispatch(bomCheckin(
            {receivedStock: v.receivedStock ? v.receivedStock : 0, boardLocation: v.boardLocation, width: v.width, pitch: v.pitch, id: v.id},
            null,
            null,
            null,
            () => {
                if( onSuccess ) {
                    onSuccess()
                }
            }
        ))
    }

    renderPcbStencil(r, admin) {

        let orderItems = getOrderItemsByType(r.pcbOrder ? 'PCB' : 'STENCIL', this.props.bomOrderList)

        if (orderItems.length === 0) {
            if( admin ) {
                return <button className='link-button' onClick={() => {
                    this.props.dispatch(openBomOrder(true))
                    this.props.dispatch(getBomOrders(this.props.print.id))
                }}>
                    No goods received
                </button>
            } else {
                return 'No goods received'
            }
        }

        return <div>
            {orderItems.map(o => <BomPcbStencilCheckinItem key={o.id} o={o} r={r} admin={admin} onSave={(addSStock => {
                this.props.dispatch(saveBomOrder({...o, stock: o.stock + addSStock}))
            })}/>)}
        </div>
    }

    /*renderAddWarehouse(r) {
        return <div style={{position: 'relative'}}>
            <div style={{position: 'absolute', bottom: 0, right: 0}}>
                <Icon link name='warehouse' color='grey'
                      onClick={() => {
                          let printId= this.props.print.id
                          let orderpartId= r.selectedSupplierPart ? r.selectedSupplierPart.orderpart.id : null
                          let klantLevertAanId= r.klantLevertAan ? r.klantLevertAan.id : null

                          this.props.dispatch(bomCheckin({
                              receivedStock: 0,
                              boardLocation: '',
                              width: null,
                              pitch: null
                          }, printId, orderpartId, klantLevertAanId))
                      }}
                />
            </div>
        </div>
    }*/

    render() {
        const admin = this.props.isAdmin

        if( this.props.bomOrderListBusy ) {
            return <Loader active inline/>
        }

        const r= this.props.r;

        if( r.pcbOrder || r.stencilOrder ) {
            return this.renderPcbStencil(r, admin)
        }

        let boardLocations = this.props.bomOrderList.map(bo => bo.items.filter(o => o.boardLocation).map(o => ({
            boardLocation: o.boardLocation,
            id: o.id
        }))).flat()

        let orderItems = getOrderItemsByRow(r, this.props.bomOrderList)

        const printId= this.props.print.id
        const orderpartId= r.selectedSupplierPart ? r.selectedSupplierPart.orderpart.id : null
        const klantLevertAanId= r.klantLevertAan ? r.klantLevertAan.id : null

        if (r && orderItems.length === 0 && (r.selectedSupplierPart || r.klantLevertAan)) {
            if( admin ) {

                return <div>

                    <div>
                        <button className='link-button' onClick={() => {
                            this.props.dispatch(openBomOrder(true))
                            this.props.dispatch(getBomOrders(this.props.print.id))
                        }}>
                            No order placed
                        </button>
                    </div>

                    <div style={{paddingTop: 8}}>
                        <button className='link-button' disabled={this.state.loading} onClick={() => {
                            this.setState({loading: true})
                            this.props.dispatch(bomCheckin({
                                receivedStock: 0,
                                boardLocation: '',
                                width: null,
                                pitch: null
                            }, printId, orderpartId, klantLevertAanId, () => {
                                this.setState({loading: false})
                            }))
                        }}>
                            Add warehouse stock
                        </button>
                    </div>

                </div>

                /*return <BomCheckinItem
                    key={-1}
                    boardLocations={boardLocations}
                    o={{}}
                    admin={admin}
                    onSaveCheckin={(v, onSuccess) => {
                        let printId= this.props.print.id
                        let orderpartId= r.selectedSupplierPart ? r.selectedSupplierPart.orderpart.id : null
                        let klantLevertAanId= r.klantLevertAan ? r.klantLevertAan.id : null
                        this.props.dispatch(bomCheckin({
                                receivedStock: v.receivedStock ? v.receivedStock : 0,
                                boardLocation: v.boardLocation,
                                width: v.width,
                                pitch: v.pitch,
                                id: v.id
                            }, printId, orderpartId, klantLevertAanId, () => {
                            if( onSuccess ) {
                                onSuccess()
                            }
                        }))
                    }}
                    showTrayOnly={this.props.showTrayOnly}
                    showStockOnly={this.props.showStockOnly}
                    r={this.props.r}
                    amount={this.props.print.amount}
                />*/

                /*return <button className='link-button' onClick={() => {
                    this.props.dispatch(openBomOrder(true))
                    this.props.dispatch(getBomOrders(this.props.print.id))
                }}>
                    No goods received
                </button>*/
            } else {
                return 'No goods received'
            }
        }

        return <div style={{textAlign: 'center'}}>

            {(r && orderItems.length > 0) && orderItems.map((o,i) => {

                return <BomCheckinItem
                    key={o.id}
                    boardLocations={boardLocations}
                    o={o}
                    admin={admin}
                    onSaveCheckin={(v, onSuccess) => {
                        this.saveCheckin(v, onSuccess)
                    }}
                    showTrayOnly={this.props.showTrayOnly}
                    showStockOnly={this.props.showStockOnly}
                    r={this.props.r}
                    amount={this.props.print.amount}
                />

            })}
        </div>
    }
}

BomCheckin.propTypes = {
    r: PropTypes.object,
    showTrayOnly: PropTypes.bool,
    showStockOnly: PropTypes.bool,
    isAdmin: PropTypes.bool,
};

BomCheckin.defaultProps = {
    r: null,
    showTrayOnly: false,
    showStockOnly: false,
    isAdmin: false
};

const mapStateToProps = (state) => {
    return {
        currentUser: state.main.currentUser,
        print: state.bom.print,
        bomOrderList: state.bom.bomOrderList,
        bomOrderListBusy: state.bom.bomOrderListBusy,
    }
}

export default connect(mapStateToProps)(BomCheckin)