import React, { Component } from 'react';
import {Icon, Label, Dropdown, Checkbox} from "semantic-ui-react";
import {connect} from "react-redux";
import {getPaymentStatusColor, isEmployee} from "../actions/functions";
import {getProjectList} from "../actions";
import Translate from "../Translate";

class ProjectListHeader extends Component{

    fetchProjects(nextPage, name, value, name2, value2) {

        //let all= this.state.showAll;
        //let searchValue= this.state.searchValue;
        //let fieldName= this.state.sort.value;
        //let dsc= this.state.sort.desc;
        //let paymentStatusFilter= this.state.paymentStatusFilter;
        //let hasOrderDate= this.state.hasOrderDate;

        let projectPageFilter= this.props.projectPageFilter;

        if( name ) {

            console.log(name + ': ' + value)

            projectPageFilter= Object.assign({}, projectPageFilter, {
                [name]: value
            })
        }

        if( name2 ) {

            console.log(name2 + ': ' + value2)

            projectPageFilter= Object.assign({}, projectPageFilter, {
                [name2]: value2
            })
        }

        if( nextPage ) {
            projectPageFilter= Object.assign({}, projectPageFilter, {
                pageNumber: this.props.projectPageFilter.pageNumber + 1
            })
        } else {
            projectPageFilter= Object.assign({}, projectPageFilter, {
                pageNumber: 0
            })
        }

        let {all, direction, sort, searchValue, pageNumber, paymentStatusFilter, hasOrderDate}= projectPageFilter;

        this.props.dispatch(getProjectList(all, direction, sort, searchValue, pageNumber, paymentStatusFilter, hasOrderDate))
    }

    setSortValue( value ) {

        let projectPageFilter= this.props.projectPageFilter;

        let newDirection= '';
        if( true ) {

            if( projectPageFilter.direction && projectPageFilter.sort === value ) {

                newDirection= projectPageFilter.direction === 'DESC' ? 'ASC' : 'DESC';

            } else {

                newDirection= 'DESC';

            }

        }

        //this.setState({sort: sortValue}, () => this.fetchProjects());
        this.fetchProjects(false, 'sort', value, 'direction', newDirection);

        //localStorage.setItem('deltaprotosort', JSON.stringify(sortValue));
    }

    setHasOrderDateFilter( value ) {
        this.fetchProjects(false, 'hasOrderDate', value)
    }

    setShowAllFilter( value ) {
        this.fetchProjects(false, 'all', value)
    }

    setPaymentStatusFilter( value ) {
        this.fetchProjects(false, 'paymentStatusFilter', value)
    }

    render(){
        const admin= isEmployee(this.props.currentUser);
        let projectPageFilter= this.props.projectPageFilter;
        return <div className={'project-overview-header'}>
            <Label.Group circular as={'a'} size={'large'}>
                <Dropdown icon
                          closeOnEscape={true}
                          closeOnBlur={false}
                          style={{marginLeft: '0.5%', marginRight:'0.5%', float: 'left', width: 28}}
                          trigger={
                              <Label className={'project-header-labels'}>
                                    <Icon name={'filter'}
                                          color={projectPageFilter.hasOrderDate === null && projectPageFilter.paymentStatusFilter === '' ? 'black' : 'red'}/>
                              </Label>}
                >
                    <Dropdown.Menu style={{left: -8, top: 40, padding: 0}}>
                        <Dropdown.Item icon='star'
                                       text='Alleen aanvragen'
                                       style={{color: projectPageFilter.hasOrderDate === false ? '#db2828' : ''}}
                                       onClick={() => {
                                           if(projectPageFilter.hasOrderDate === false){
                                               this.setHasOrderDateFilter(null)
                                           }
                                           else{
                                               this.setHasOrderDateFilter(false)
                                           }
                                       }}
                        />
                        <Dropdown.Item icon='rocket'
                                       text='Alleen orders' style={{color: projectPageFilter.hasOrderDate === true ? '#db2828' : ''}}
                                       onClick={() => {
                                           if(projectPageFilter.hasOrderDate === true){
                                               this.setHasOrderDateFilter(null)
                                           }
                                           else{
                                               this.setHasOrderDateFilter(true)
                                           }
                                       }}
                        />
                        <Dropdown.Header icon='hourglass'
                                         content='Filter op status' />
                        <Dropdown.Item icon='search'
                                       text='Analysis & Order' />
                        <Dropdown.Item icon='clipboard check'
                                       text='Preparation' />
                        <Dropdown.Item icon='cog'
                                       text='Sample Production' />
                        <Dropdown.Item icon='cogs'
                                       text='Serie Production' />
                        <Dropdown.Divider />
                        <Dropdown.Header icon='eye'
                                         content='Filter op zichtbaarheid' />
                        <Dropdown.Item><Checkbox label='Show all'
                                                 checked={projectPageFilter.all}
                                                 onChange={() => this.setShowAllFilter(!projectPageFilter.all)}
                        />
                        </Dropdown.Item>
                        <Dropdown.Divider />
                        <Dropdown.Header icon='money'
                                         content='Filter op betalingsstatus'
                        />
                            <Dropdown.Item icon={{name:'money', color: 'black'}}
                                           text='All'
                                           onClick={() => this.setPaymentStatusFilter('')}
                            />
                            <Dropdown.Item icon={{name:'money', color: 'green'}}
                                           text='Paid'
                                           onClick={() => this.setPaymentStatusFilter('payed')}
                            />
                            <Dropdown.Item icon={{name:'money', color: 'blue'}}
                                           text='Sent'
                                           onClick={() => this.setPaymentStatusFilter('sent')}
                            />
                            <Dropdown.Item icon={{name:'money', color: 'grey'}}
                                           text='Not approved'
                                           onClick={() => this.setPaymentStatusFilter('na')}
                            />
                    </Dropdown.Menu>
                </Dropdown>
                <Label className={'project-header-labels'}>
                    <Icon name={'factory'}/>
                    Bedrijf
                </Label>
                <Label className={'project-header-labels'}
                       color={projectPageFilter.sort === 'owner.firstName' ? 'grey' : ''}
                       onClick={() => this.setSortValue('owner.firstName')}
                >
                    <Icon name={'user'}/>
                    Eigenaar
                </Label>
                <Label className={'project-header-labels'}
                       color={projectPageFilter.sort === 'aanvraagDate' ? 'grey' : ''}
                       onClick={() => this.setSortValue('aanvraagDate')}
                       style={{width: '12.5%'}}>
                    <Icon name={'calendar plus outline'}/>
                    Aanvraagdatum
                </Label>
                <Label className={'project-header-labels'}
                       color={projectPageFilter.sort === 'name' ? 'grey' : ''}
                       onClick={() => this.setSortValue('name')}>
                    <Icon name={'tag'}/>
                    Project naam
                </Label>
                <Label className={'project-header-labels'}
                       color={projectPageFilter.sort === 'calculatorDetails.numPcbs' ? 'grey' : ''}
                       onClick={() => this.setSortValue('calculatorDetails.numPcbs')}
                       style={{width: '7%'}}>
                    <Icon name={'hashtag'}/>
                    Aantal
                </Label>
                <Label className={'project-header-labels'}
                       color={projectPageFilter.sort === 'deliveryDate' ? 'grey' : ''}
                       onClick={() => this.setSortValue('deliveryDate')}
                       style={{width: '13.5%'}}>
                    <Icon name={'time'}/>
                    Levertijd (leverdatum)
                </Label>
                <Label className={'project-header-labels'}
                       color={projectPageFilter.sort === 'internalName' ? 'grey' : ''}
                       onClick={() => this.setSortValue('internalName')}
                       style={{width: '10%'}}>
                    <Icon name={'tags'}/>
                    Interne naam
                </Label>
                <Label className={'project-header-labels'}
                       style={{width: '7%'}}>
                    <Icon name={'euro'}/>
                    Bedrag
                </Label>
            </Label.Group>
            <Icon name='money' style={{position: 'relative', top: 11, cursor: 'pointer'}}
                  color={getPaymentStatusColor(projectPageFilter.paymentStatusFilter)}
                  onClick={() => {
                      if( projectPageFilter.paymentStatusFilter === 'payed' ) {
                          this.setPaymentStatusFilter('sent')
                      } else if( projectPageFilter.paymentStatusFilter === 'sent' ) {
                          this.setPaymentStatusFilter('na')
                      } else if( projectPageFilter.paymentStatusFilter === 'na' ) {
                          this.setPaymentStatusFilter('')
                      } else {
                          this.setPaymentStatusFilter('payed')
                      }
                  }}
            />
        </div>
    }
}

const mapStateToProps = (state) => {
    return {
        projectPageFilter: state.project.projectPageFilter,
        currentUser: state.main.currentUser,
        fetchingProjects: state.project.fetchingProjects
    }
}

export default connect(mapStateToProps)(ProjectListHeader)