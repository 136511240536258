import React, {Component} from "react";
import {renderSupplier} from "./functions";
import PropTypes from "prop-types";
import ReactTextTransition, { presets } from "react-text-transition";
import Highlighter from "react-highlight-words";

export class BomTodo extends Component {

    state = {
        selected: 0,
        direction: "up"
    }


    componentDidMount() {

    }

    down = () => {
        console.log('down')
        let newSelected = this.state.selected + 1;

        if( (newSelected) >= (this.props.todos.filter((t) => this.props.opcode.some(op => t.opcode.includes(op))).length) ) {
            newSelected = 0;
        }

        this.setState({direction: "down", selected: newSelected})
    }

    getPartForTodo = (todo) => {
        if( todo ) {
            if (todo.opcode === 'MPN') {
                return this.props.orderparts.find((p) => p.mpn === todo.value)
            } else if (todo.opcode === 'SKU') {
                return this.props.orderparts.find((p) => {
                    return p.suppliers.some((s) => s.sku === todo.value)
                })
            }
        }
        return null;
    }

    render() {

        const {expandedView, highlightWords} = this.props;

        const list = this.props.todos.sort((a,b) => {
            if( a.opcode === 'CUSTOMER_ADDED_SKU' ) {
                return -1;
            } else if( b.opcode === 'CUSTOMER_ADDED_SKU' ) {
                return 1;
            }
            return 0;
        }).sort((a,b) => {
            if( a.opcode === 'CPN' ) {
                return -1;
            } else if( b.opcode === 'CPN' ) {
                return 1;
            }
            return 0;
        }).filter((t) => this.props.opcode.some(oc => t.opcode.includes(oc)) )

        const show = list[this.state.selected];

        const part = this.getPartForTodo(show);

        let text = show ? ((show.key ? show.key + " " : "") + (show.value ? show.value : "")) : ''

        let color;

        switch (show ? show.opcode : '') {
            case 'CPN':
                color = '#2fba51';
                text = `(${text})`
                break;
            case 'CUSTOMER_ADDED_SKU':
                color = '#4183c4';
                break;
            default:
                color = ''
        }

        if (show ) {
            text = <div style={{display: 'flex'}}>
                {renderSupplier({supplier: show.key, sku: show.value})}
                <span style={{marginLeft: 4, color: 'darkgray'}}>
                    <Highlighter highlightClassName="Highlight" searchWords={highlightWords}
                                 textToHighlight={(part && part.specification) ? part.specification.displayValue : ''}
                    />
                </span>
            </div>

        }

        let style = {
            color: color
        }

        if (expandedView) {
            return <div style={style}>
                {list.length ? list.map(t => <div>{t}</div>) : '...'}
            </div>
        }
        if(list && list.length > 1) {
            return <div
                style={{...style, cursor: 'pointer', display: 'flex', padding: 0.5}}
                onMouseDown={() => this.down()}
            >

                {text}

                {(list && list.length > 1) ? <>

                    <sup style={{ top: '+.4em', color: '#db2828', fontSize: '65%'}}>{this.state.selected + 1}{'/'}{list.length}</sup>

                </> : ''}

            </div>

        }
        return <div style={style}>
            {text}

        </div>
    }
}

BomTodo.propTypes = {
    opcode: PropTypes.array,
    todos: PropTypes.array,
    orderparts: PropTypes.array,
    highlightWords: PropTypes.array,
    selectedValue: PropTypes.string,
    expandedView: PropTypes.bool,
    showSpec: PropTypes.bool
};

BomTodo.defaultProps = {
    opcode: [],
    todos: [],
    orderparts: [],
    highlightWords: [],
    selectedValue: null,
    expandedView: false,
    showSpec: false
};