import React, { Component } from 'react';
import './App.css';
import connect from "react-redux/es/connect/connect";
import PropTypes from "prop-types";
import {isEmployee} from "./actions/functions";
import Translate, {getText} from "./Translate";
import {Checkbox, Confirm, Dropdown, Modal, TextArea} from "semantic-ui-react";
import {updateProjectStatus} from "./actions";

class ProjectCardStatusBarItem extends Component{

    state= {
        open: false
    }

    handleClick(e) {
        if( this.props && this.props.onClick ) {
            this.props.onClick()
        }
    }

    confirmSelect(project, phaseName, value) {
        this.setState({
            project, phaseName, value, confirmOpen: true
        })
    }

    handleSelect(e, onHoldReason) {
        let body;
        let value2 = '';

        if( this.state.value.endsWith('R') ) {
            value2 = 'R'
            let value = this.state.value.substring(0, this.state.value.length-1)
            body = {[this.state.phaseName]: value};
        } else {
            body = {[this.state.phaseName]: this.state.value};
        }

        if( this.props.phaseName2 ) {
            body = {...body, [this.props.phaseName2]: value2}
        }

        body = {...body, onHoldReason: onHoldReason}

        this.props.dispatch(updateProjectStatus(this.state.project.id, body, () => {
            this.setState({confirmOpen: false})
        }))
        e.preventDefault()
    }

    render() {

        let admin= isEmployee(this.props.currentUser)

        let { phaseName, project }= this.props;

        if( admin ) {

            let myOptions= this.props.options.map(o => ({
                key: o,
                text: <Translate value={'pp.'+this.props.phaseName+'.'+o} formatString={[this.props.sampleCounter, this.props.sampleCounterWithoutSuffix]}/>,
                icon: '',
                className: 'statusBarTabDefault statusBarTab-'+getText(this.props, 'pp.' + phaseName + '.' + o + '.color')
            }))

            const setOnHoldMessage = this.state.value === 'onhold'

            return (
                <div
                    onMouseEnter={() => this.setState({open: true})}
                    onMouseLeave={() => this.setState({open: false})}
                >

                    {this.props.counter ? <div className='notification-counter'>{this.props.counter}</div> : ''}
                    {this.props.counterInternal ? <div className='notification-counter yellow'>{this.props.counterInternal}</div> : ''}

                    <Confirm
                        content={<Modal.Content>
                            Project status aanpassen naar <Translate value={'pp.'+this.state.phaseName+'.'+this.state.value}/>?

                            {setOnHoldMessage ? <div style={{marginTop: 8}}>
                                <TextArea style={{width: '100%'}} autoFocus fluid autoHeight placeholder='Reden' value={this.state.onHoldReason} onChange={(e) => this.setState({onHoldReason: e.target.value})} />
                            </div> : null}

                        </Modal.Content>}
                        open={this.state.confirmOpen}
                        onCancel={(e) => {
                            this.setState({confirmOpen: false});
                            e.preventDefault();
                        }}
                        onConfirm={(e) => this.handleSelect(e, this.state.onHoldReason)}
                    />

                    <Dropdown
                        as={'div'}
                        onClick={(e,d) => {
                            //this.handleClick(e);
                            e.preventDefault()
                        }}
                        icon={null}
                        open={this.state.open}
                        trigger={<p onClick={() => this.handleClick()}><Translate value={this.props.selectedOption} formatString={[this.props.sampleCounter, this.props.sampleCounterWithoutSuffix]}/></p>}
                    >
                        <Dropdown.Menu>
                            {myOptions.map(x => x.key === 'DIVIDER' ? <Dropdown.Divider/> : (
                                <Dropdown.Item onClick={(e,d) => this.confirmSelect(project,phaseName,x.key)} as={'div'} key={x.key} value={x.key} className={x.className}>{x.text}</Dropdown.Item>
                            ))}
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
            )

        } else {
            return (
                <div>
                    {this.props.counter ? <div className='notification-counter'>{this.props.counter}</div> : ''}
                    <Translate value={this.props.selectedOption} formatString={[this.props.sampleCounter, this.props.sampleCounterWithoutSuffix]}/>
                </div>
            )
        }
    }
}

ProjectCardStatusBarItem.propTypes = {
    onClick: PropTypes.func,
    phaseName: PropTypes.any,
    selectedOption: PropTypes.any,
    project: PropTypes.any,
    options: PropTypes.array,
    counter: PropTypes.number,
    counterInternal: PropTypes.number,
    sampleCounter: PropTypes.string,
    sampleCounterWithoutSuffix: PropTypes.string
};

ProjectCardStatusBarItem.defaultProps = {
    onClick: null,
    phaseName: null,
    selectedOption: null,
    project: null,
    options: [],
    counter: 0,
    counterInternal: 0,
    sampleCounter: '1st',
    sampleCounterWithoutSuffix: '1st'
};

const mapStateToProps = (state) => {
    return {
        lang: state.main.lang,
        currentLang: state.main.currentLang,
        currentUser: state.main.currentUser,
    }
}

export default connect(mapStateToProps)(ProjectCardStatusBarItem)