import React, { Component } from 'react';

import {connect} from "react-redux";
import {getAllFloatingPcbPictures} from "./actions";
import DeltaTable from "./deltatable/DeltaTable";
import {formatDateTime} from "./actions/functions";

class PcbView extends Component {

    componentDidMount() {
        this.props.dispatch(getAllFloatingPcbPictures())
    }

    render() {
        return (
            <main id={'pcbs'} className={'pageContainer'} style={{textAlign: 'left'}}>
                <h1>PCB View</h1>

                <DeltaTable
                    data={this.props.floatingPcbPictures}
                    columns={[
                        {name: 'date', label: 'Datum', render: (pcb) => {
                                return formatDateTime( new Date(pcb.frontImage.dateCreated) )
                            }},
                        {name: 'name', label: 'Name', render: (pcb) => {
                                return pcb.frontImage.name
                            }},
                        {name: 'size', label: 'Size', render: (pcb) => {
                                return pcb.frontImage.size
                            }}
                    ]}

                />
            </main>
        );
    }
}



const mapStateToProps = (state) => {
    return {
        currentUser: state.main.currentUser,
        lang: state.main.lang,
        floatingPcbPictures: state.project.floatingPcbPictures
    }
}

export default connect(mapStateToProps)(PcbView)
