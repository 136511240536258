import React, {Component, Suspense, lazy } from 'react';
import './App.css';
import {BrowserRouter, Switch, Route} from 'react-router-dom';
import NavigationBar from './NavigationBar';
import Footer from './Footer';
import Home from './Home';
import Contact from './Contact';
//import ProjectList from './oldui/ProjectList';
import LoginSignup from "./LoginSignup";
import PageNotFound from "./PageNotFound";
import connect from "react-redux/es/connect/connect";
import Video from "./Video";
import About from "./About";
import CalculatorOld from "./oldui/Calculator";
//import Calculator from './Calculator';
import AccountViewId from "./AccountViewId";
import projectList from "./ProjectList";
import {
    changeUserModalOpen,
    getLanguageBundle,
    getUserChangeOptions, openSiteSearch,
    setUserIdleTimeout,
    validateLogin
} from "./actions";
import LanguageEditor from "./oldui/LanguageEditor";
import ScrollToTop from "./ScrollToTop";
import AccountList from "./oldui/AccountList";
import CompanyList from "./CompanyList";
//import ProjectViewId from "./ProjectViewId";
import Tips from "./Tips";
import Project from './Project';
import RegistrationConfirm from "./RegistrationConfirm";
import ReactGA from 'react-ga';
import RegistrationReset from "./RegistrationReset";
import OldUiRedirect from "./OldUiRedirect";
import Dashboard from "./Dashboard";
//import Bom from "./bom/Bom";
import Blog from "./Blog";
import ProjectOrderModal from "./ProjectOrderModal";
import MicroPlacer from "./MicroPlacer";
import MicroPlacerNew from "./MicroPlacerNew";
//import BomAdminView from "./bom/BomAdminView";
//import BomOverviewCheckin from "./bom/BomOverviewCheckin";
//import Motor from "./bom/Motor";
import LoginSignupInline from "./LoginSignupInline";
import ProtoflowProjectList from "./protoflow/ProtoflowProjectList";
import ProtoflowProject from "./protoflow/ProtoflowProject";
import EmployeeHours from "./EmployeeHours";
import ChatView from "./chat/ChatView";
//import Planning from "./oldui/Planning";
import ProjectListMicroplacer from "./ProjectListMicroplacer";
//import Sharketing from "./sharketing/Sharketing";
import IdleTimer from 'react-idle-timer'
import {Loader} from "semantic-ui-react";
import {GlobalHotKeys} from "react-hotkeys";
import SiteSearch from "./SiteSearch";
import DeltaTableTestPage from "./deltatable/DeltaTableTestPage";
import BomMyStockPage from "./bom/BomMyStockPage";
import BomStockPage from "./bom/BomStockPage";
import BomStockOverview from "./bom/BomStockOverview";
import DeltaSerial from "./tools/DeltaSerial";
import {Helmet} from "react-helmet";
import ProjectList from "./ProjectList";
import ProjectViewMicroplacer from "./ProjectViewMicroplacer";
import PcbView from "./PcbView";


const BomMicroPlacer = lazy(() => import('./bom/BomMicroPlacer'));
const ProjectViewId = lazy(() => import('./ProjectViewId'));
const BomAdminView = lazy(() => import('./bom/BomAdminView'));
const BomOverviewCheckin = lazy(() => import('./bom/BomOverviewCheckin'));
const Planning = lazy(() => import('./oldui/Planning'));

const getStorageItem = (name, key, onSetValue) => {
    let s = localStorage.getItem(name);
    if( s ) {
        let parsed = JSON.parse(s)
        if( parsed && onSetValue ) {
            return onSetValue(parsed[key])
        }
    }
}

const routes = [{path: '/', exact: true, component: <Home/>, title: '', titleAfter: 'PCBA service'},
    {path: '/about', component: <About/>, title: 'About Us'},
    {path: '/contact', component: <Contact/>, title: 'Contact'},
    {path: '/tips', component: <Tips/>, title: 'Tips'},
    {path: '/news', component: <Blog/>, title: 'Blog'},
    {path: '/projects', component: <ProjectList/>, title: 'Projects'},
    {path: '/video', component: <Video/>, title: 'Video'},
    {path: '/calculator', component: <CalculatorOld/>, title: 'Calculator'},
    {path: '/translate', component: <LanguageEditor/>, title: 'Language Editor'},
    {path: '/news', component: <Blog/>, title: 'Blog'},
    {path: '/projects-microplacer/:bomId', component: <ProjectViewMicroplacer/>, title: 'BomMicroPlacer'},
    {path: '/projects-microplacer/:bomId/:page', component: <ProjectViewMicroplacer/>, title: 'BomMicroPlacer'},
    {path: '/projects-microplacer', component: <ProjectListMicroplacer/>, title: 'MicroPlacer projects'},
    {path: '/protoflow/:projectId/:page', component: <ProtoflowProject/>, title: 'ProtoflowProject'},
    {path: '/protoflow', component: <ProtoflowProject/>, title: 'ProtoflowProject'},
    //{path: '/projects', component: <ProtoflowProjectList/>, title: 'ProtoflowProjectList'},
    {path: '/projectOld/:projectId', component: <ProjectViewId/>, title: 'ProjectViewId'},
    {path: '/project/:projectId/:page', component: <Project/>, title: 'Project'},
    {path: '/project/:projectId/:page/:subpage', component: <Project/>, title: 'Project'},
    {path: '/order/:projectId', component: <ProjectOrderModal/>, title: 'ProjectOrderModal'},
    {path: '/project/:projectId', component: <Project/>, title: 'Project'},
    {path: '/account/:id', component: <AccountViewId/>, title: 'AccountViewId'},
    {path: '/users', component: <AccountList/>, title: 'User overiew'},
    {path: '/companys', component: <CompanyList/>, title: 'Company overview'},
    {path: '/confirmRegistration/:id', component: <RegistrationConfirm/>, title: 'RegistrationConfirm'},
    {path: '/resetPassword/:userId/:token', component: <RegistrationReset/>, title: 'RegistrationReset'},
    {path: '/project.html', component: <OldUiRedirect/>, title: 'OldUiRedirect'},
    {path: '/prototips.html', component: <OldUiRedirect/>, title: 'OldUiRedirect'},
    {path: '/dashboard', component: <Dashboard/>, title: 'Dashboard'},
    {path: '/dashboard/:section', component: <Dashboard/>, title: 'Dashboard'},
    {path: '/dashboard/:section/:subsection', component: <Dashboard/>, title: 'Dashboard'},
    {path: '/microplacer', component: <MicroPlacerNew/>, title: 'MicroPlacer'},
    {path: '/up', component: <MicroPlacerNew/>, title: 'MicroPlacer'},
    {path: '/bomsettings', component: <BomAdminView/>, title: 'BomAdminView'},
    {path: '/checkin', component: <BomOverviewCheckin/>, title: 'BomOverviewCheckin'},
    {path: '/uren', component: <EmployeeHours/>, title: 'Employee Hours'},
    {path: '/communication', component: <ChatView/>, title: 'Messages'},
    {path: '/planning', component: <Planning/>, title: 'Planning'},
    /*{path: '/sharketing', component: <Sharketing/>, title: 'Sharketing'},*/
    {path: '/test', component: <DeltaTableTestPage/>, title: 'DeltaTableTestPage'},
    {path: '/pcbs', component: <PcbView/>, title: 'ProtoFoto'},

    //   {path: '/planning', component: <PageNotFound/>, title: 'PageNotFound'},
    //  <Route component={PageNotFound}/>
]


class App extends Component {


    constructor(props, context) {
        super(props, context);
        this.handleOnIdle = this.handleOnIdle.bind(this)
    }

    handleOnIdle (event) {
        this.props.dispatch(getUserChangeOptions())
        this.props.dispatch(changeUserModalOpen(true))
    }

    componentDidMount() {

        if (process.env.NODE_ENV === "production") {
            //ReactGA.initialize('UA-90811920-1');
            //ReactGA.pageview(window.location.pathname + window.location.search);
        }
        else {
            //ReactGA.initialize('UA-90811920-1', { testMode: true });
        }

        this.props.dispatch(validateLogin())
        this.props.dispatch(getLanguageBundle())

        this.myTimer = setInterval(() => {
            //if( this.props.currentUser != null ) {
                this.props.dispatch(validateLogin());
            //}
        }, 10000);

        getStorageItem('deltaprotoidletimeout', 'idleTimeout', (value) => {
            this.props.dispatch(setUserIdleTimeout(value))
        })
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!this.props.lang) {
            this.props.dispatch(getLanguageBundle())
        }
    }

    render() {
        return (
            <BrowserRouter basename={process.env.NODE_ENV !== 'development' ? process.env.PUBLIC_URL : ''}>

                {this.props.idleTimeout ? <IdleTimer
                    ref={ref => {
                        this.idleTimer = ref
                    }}
                    timeout={1000 * 60 * this.props.idleTimeout}
                    onIdle={this.handleOnIdle}
                    debounce={250}
                /> : ''}

                <div className={'App'}>
                    <GlobalHotKeys
                        innerRef={ (c) => this._container = c }
                        keyMap={{
                            S: "s"
                        }} handlers={{
                        S: () => {
                            this.props.dispatch(openSiteSearch(true))
                        }
                    }}>
                    {this.props.siteSearchOpen ? <SiteSearch/> : null}
                    <NavigationBar/>
                            <ScrollToTop>
                                <main>
                                    <Suspense fallback={<div style={{textAlign: 'center', marginTop: 32, marginBottom: 32}}><Loader active inline/></div>}>

                                        {( this.props.currentUser === null ) ? <>
                                            <Route path={["/translate", "/projects", "/account", "/users", "/bom", "/bomsettings", "/dashboard"]}
                                                   component={LoginSignupInline}/>
                                        </> : ''}

                                        <Switch>

                                            {routes.map(r => <Route path={r.path} exact

                                            >
                                                <div>
                                                    <Helmet>
                                                        <title>{r.title ? `${r.title} - ` : ''}DeltaProto{r.titleAfter ? ' - ' + r.titleAfter : ''}</title>
                                                    </Helmet>
                                                    {r.component}
                                                </div>
                                            </Route>)}




                                            <Route component={PageNotFound}/>

                                        </Switch>
                                    </Suspense>
                                </main>
                            </ScrollToTop>
                    <Footer/>
                    <LoginSignup/>
                    </GlobalHotKeys>
                </div>
            </BrowserRouter>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        currentUser: state.main.currentUser,
        lang: state.main.lang,
        idleTimeout: state.main.idleTimeout,
        siteSearchOpen: state.main.siteSearchOpen
    }
}

export default connect(mapStateToProps)(App)
