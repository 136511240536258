import React, { Component } from 'react';
import './App.css';
import connect from "react-redux/es/connect/connect";
import Cookies from 'js-cookie'

import {Button, Icon} from "semantic-ui-react";
import Translate, {getText} from "./Translate";

const SITE_MESSAGE = "message.site";

class NavigationBarMessage extends Component {

    state= {
        open: true
    }

    componentDidMount() {

        let c = Cookies.get('site-message');

        if (c === 'closed') {
            this.setState({open: false});
        } else {
            this.setState({open: true});
        }

    }

    handleClose = () => {
        this.setState({open: false});
        Cookies.set('site-message', 'closed', { expires: 14 })
    }

    render() {

        let siteMessageIsSet= getText(this.props, SITE_MESSAGE);

        const show= this.state.open && siteMessageIsSet && siteMessageIsSet !== SITE_MESSAGE;

        return(
            show ? <nav id={'thirdNavBar'} style={{top: this.props.top}}>
                <div id='thirdBarMessage'>

                    <div style={{flex: 1}}>
                        <Translate value={SITE_MESSAGE} dangerousHTML={true}/>
                    </div>
                    <div>
                        <Button primary onClick={this.handleClose}>
                            <Translate  value='close'/>
                        </Button>
                    </div>


                </div>
            </nav> : ''
        );
    }
}


const mapStateToProps = (state) => {
    return {
        lang: state.main.lang,
        currentLang: state.main.currentLang
    }
}

export default connect(mapStateToProps)(NavigationBarMessage)
