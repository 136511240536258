import React from 'react';
import {changeDigiKeySetting, getDigiKeyClientIdAndSecret, setDigiKeyClientIdAndSecret, testDigiKey} from "./actions";
import {Button, Form, Icon, Modal, Popup} from "semantic-ui-react";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import Translate from "./Translate";

class DigiKeyAuthentication extends React.Component {

    state = {
        connectTest: true
    }

    componentDidMount() {
        this.props.dispatch(getDigiKeyClientIdAndSecret())

        if( this.props.statusOnly ) {
            setTimeout(() => {
                this.props.dispatch(getDigiKeyClientIdAndSecret())
            }, 5000)
        }
    }

    render() {

        let d= this.props.digikey ? this.props.digikey : {};

        if( this.props.statusOnly ) {
            return <>
                <Popup
                    position='bottom center'
                    trigger={d.isConnected? <Icon name='check circle' color='green'/> : <Icon name='remove circle' color='red'/>}
                    content={'DigiKey API is ' + (d.isConnected ? 'verbonden' : 'niet verbonden')}
                />

            </>
        }

        return <div>
            <Button style={{float: 'right'}} onClick={() => this.props.dispatch(getDigiKeyClientIdAndSecret())}>Refresh</Button>

            <h1>DigiKey {d.isConnected? <Icon name='check circle' color='green'/> : <Icon name='remove circle' color='red'/>}</h1>

            <Form>
                <Form.Input label='clientId' value={d.clientId} onChange={(e) => this.props.dispatch(changeDigiKeySetting('clientId', e.target.value))}/>
                <Form.Input label='clientSecret' value={d.clientSecret} onChange={(e) => this.props.dispatch(changeDigiKeySetting('clientSecret', e.target.value))}/>
                <Form.Input label='redirectUrl' value={d.redirectUrl} onChange={(e) => this.props.dispatch(changeDigiKeySetting('redirectUrl', e.target.value))}/>

                <Form.Button primary={d.dirty}
                    onClick={() => this.props.dispatch(setDigiKeyClientIdAndSecret(d))}
                ><Translate value="save"/></Form.Button>
            </Form>

            <div style={{marginTop: 16}}>
                <Button as='a' href={d.authUrl} target="_blank" primary><Translate value="digikey_authoriseren"/></Button>
            </div>

            <div style={{marginTop: 16}}>
                <Button onClick={() => this.props.dispatch(testDigiKey((res) => {
                    this.setState({connectTest: res.isConnected, testOpen: true})
                }))} >Test connection</Button>

                <Modal
                    size={'mini'}
                    open={this.state.testOpen}
                    onClose={() => this.setState({testOpen: false})}
                >
                    <Modal.Header>Connection</Modal.Header>
                    <Modal.Content>
                        <p>{this.state.connectTest ? <Icon name='check circle' color='green'/> : <Icon name='remove circle' color='red'/>}</p>
                        <p>{this.state.connectTest ? 'Connection is OK' : 'Connection failed'}</p>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button onClick={() => this.setState({testOpen: false})}>
                            Sluiten
                        </Button>
                    </Modal.Actions>
                </Modal>
            </div>
        </div>
    }
}

DigiKeyAuthentication.propTypes = {
    statusOnly: PropTypes.bool
}

const mapStateToProps = (state) => {
    return {
        digikey: state.main.digikey
    }
}

export default connect(mapStateToProps)(DigiKeyAuthentication)