import React from 'react';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {
    addBomProduction,
    getBomProduction,
    setBomFilter, setBomProductionSelected,
    setBomProductionSetPlankje
} from "../actions";
import {Button, Dropdown, Form, Icon, Modal} from "semantic-ui-react";

export const sideOptions = [
    {key: 'alle', text: 'Side: Top & Bottom', value: 'alle'},
    {key: 'top', text: 'Side: Top', value: 'top'},
    {key: 'bottom', text: 'Side: Bottom', value: 'bottom'},
]

const rotateOptions = ['0°', '90°', '180°', '270°'].map(v => ({
    key: v.replaceAll('°', ''), text: v, value: parseInt(v.replaceAll('°', ''))
}));

class BomProductionSegment extends React.Component {

    state = {}

    componentDidMount() {
        this.props.dispatch(getBomProduction(this.props.printId))
    }

    render() {

        const {bomProductionList, bomProductionSelected, selectedPlankje} = this.props;
        let selected = null;

        if( !this.props.hasCentroid ) {
            return <div style={{color: 'red'}}>
                Er is geen centroid geladen
            </div>
        }

        if( bomProductionList.some(p => p.id === bomProductionSelected) ) {
            selected = bomProductionList.find(p => p.id === bomProductionSelected)
        }

        return <div >

            <Form style={{display: 'flex', gap: 8}}>


                    <Dropdown
                        options={[...this.props.bomProductionList.map(bp => ({
                            key: bp.id,
                            text: 'PCB number: ' + bp.pcbNumber,
                            value: bp.id
                        })), {
                            key: -1,
                            text: 'Add PCB',
                            value: -1
                        }]}
                        trigger={<Button color={this.props.bomProductionSelected ? 'blue' : 'red'} size='mini' icon>
                            {selected ? 'PCB: ' + selected.pcbNumber : 'Select a PCB number'}
                            <Icon name='caret down'/>
                        </Button> }
                        icon={null}
                        error={!this.props.bomProductionSelected}
                        additionLabel='Nieuwe PCB toevoegen: '
                        value={this.props.bomProductionSelected}
                        onAddItem={(e,{value}) => {
                            this.props.dispatch(addBomProduction(this.props.printId, value))
                        }}
                        onChange={(e,d) => {
                            if( d.value === -1 ) {
                                this.setState({addPcbNumberModal: true})
                            } else {
                                this.props.dispatch(setBomProductionSelected(d.value))
                            }
                        }}
                    />

                <Dropdown
                    options={[
                        {key: 1, text: 'Plankje 1', value: 1},
                        {key: 2, text: 'Plankje 2', value: 2},
                        {key: 3, text: 'Plankje 3', value: 3},
                        {key: 4, text: 'Plankje 4', value: 4},
                        {key: 5, text: 'Plankje 5', value: 5},
                    ]}
                    trigger={<Button color='blue' size='mini' icon>
                        {selectedPlankje ? 'Plankje: ' + selectedPlankje : 'Select a Plankje number'}
                        <Icon name='caret down'/>
                    </Button> }
                    value={selectedPlankje}
                    onChange={(e,d) => {
                        this.props.dispatch(setBomProductionSetPlankje(d.value))
                    }}
                />

                <Modal open={this.state.addPcbNumberModal} onClose={() => this.setState({addPcbNumberModal: false})} closeIcon>
                    <Modal.Content>
                        <Form>
                            <Form.Input label='Add PCB number' value={this.state.addPcbNumberValue} onChange={(e) => this.setState({addPcbNumberValue: e.target.value})}/>
                        </Form>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button onClick={() => this.setState({addPcbNumberModal: false})}>Close</Button>
                        <Button primary onClick={() => {
                            this.props.dispatch(addBomProduction(this.props.printId, this.state.addPcbNumberValue, () => {
                                this.setState({addPcbNumberModal: false})
                            }))
                        }}>Add</Button>
                    </Modal.Actions>
                </Modal>

                    <Form.Dropdown inline
                                   onChange={(e,d) => {
                                       this.props.dispatch(setBomFilter(d.value === 'alle' ? null : d.value))
                                   }}
                                   trigger={<Button primary size='mini' icon>
                                       {this.props.bomFilter ? this.props.bomFilter : sideOptions[0].text}
                                       <Icon name='caret down'/>
                                   </Button> }
                                   icon={null}
                                   options={sideOptions}
                                   value={this.props.bomFilter ? this.props.bomFilter : 'alle'}
                    />

            </Form>

        </div>
    }
}

BomProductionSegment.propTypes = {
    printId: PropTypes.string,
    hasCentroid: PropTypes.bool,
    buddyLink: PropTypes.bool,
}

BomProductionSegment.defaultProps = {
    printId: null,
    hasCentroid: true,
    buddyLink: true,
}

const mapStateToProps = (state) => {
    return {
        bomProductionList: state.bom.bomProductionList,
        bomProductionSelected: state.bom.bomProductionSelected,
        bomFilter: state.bom.bomFilter,
        selectedPlankje: state.bom.selectedPlankje,
    }
}

export default connect(mapStateToProps)(BomProductionSegment)