import React, {Component} from 'react';
import connect from "react-redux/es/connect/connect";
import './App.css';
import {Accordion, Divider, Icon, Menu, Tab, Table} from "semantic-ui-react";
import Translate from "./Translate";
import Planning from "./oldui/Planning";
import PackingTypes from "./oldui/PackingTypes";
import ProjectEventTable from "./oldui/ProjectEventTable";
import {collectparams, fetchData, handleErrors, isAdmin} from "./actions/functions";
import {fetchIbiza, fetchProjectEvents} from "./actions";
import ComponentDataView from "./bom/ComponentDataView";
import DigiKeyAuthentication from "./DigiKeyAuthentication";
import LanguageEditor from "./oldui/LanguageEditor";
import ChatView from "./chat/ChatView";
import AccountGroups from "./oldui/AccountGroups";
import ActiveUsers from "./oldui/ActiveUsers";
import BomAdminView from "./bom/BomAdminView";
import BomOverviewCheckin from "./bom/BomOverviewCheckin";
import EmployeeHoursOverview from "./EmployeeHoursOverview";
import NewsLetterOverview from "./NewsLetterOverview";
import NexarAuthentication from "./NexarAuthentication";
import KvkApiAuthentication from "./KvkApiAuthentication";
import MediaQuery from "react-responsive";
import MailView from "./mail/MailView";
import DropboxAuthentication from "./DropboxAuthentication";
import ApplicationSettings from "./ApplicationSettings";
import Sharketing from "./sharketing/Sharketing";
import { Chart } from "react-google-charts";


var moment = require('moment');
moment.locale('nl');

class Dashboard extends Component {

    state= {
        value: [
        ],
        fetching: false,
        lang: 'nl'
    }

    componentDidMount() {

        let value= [];
        var thisYear = parseInt( moment().format('YYYY') );
        var thisMonth = parseInt( moment().format('M') );


        for (let y = 2018; y < thisYear; y++) {
            value = [...value, ...moment.monthsShort().map((m,i) => ({text: m, month: i+1, year: y}))]
        }

        value = [...value, ...moment.monthsShort().filter((m,i) => {
            return i < thisMonth
        }).map((m,i) => ({text: m, month: i+1, year: thisYear}))]

        value= value.reverse();

        let lang= collectparams('lang')

        this.setState({
            lang: lang ? lang : 'nl',
            value: value
        })

        const admin = isAdmin(this.props.currentUser)

        if( admin ) {
            this.onTabChange(null, {activeIndex: 2})
        } else {
            this.onTabChange(null, {activeIndex: 0})
        }

    }

    componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS) {
        if( this.props.currentUser != null && (prevProps.currentUser == null || this.props.currentUser.id !== prevProps.currentUser.id) ) {
            const admin = isAdmin(this.props.currentUser)

            if( admin ) {
                this.onTabChange(null, {activeIndex: 2})
            } else {
                this.onTabChange(null, {activeIndex: 0})
            }
        }
    }

    fetchOverview(month, year) {

        this.setState({fetching: true})

        fetchData( '/api/project/getOverview?month=' + month + '&year=' + year )
            .then(response => handleErrors(response).json())
            .then(json => {

                let newList= this.state.value.map((v) => {
                    if( v.month === month && v.year === year ) {
                        return Object.assign({}, v, {
                            value: json[1],
                            newCustomers: json[2] ? Array.from(json[2]) : [],
                            otherCustomers: json[3] ? Array.from(json[3]) : [],
                            fetching: false
                        })
                    } else {
                        return v
                    }
                })

                this.setState({value: newList, fetching: false})
            })
            .catch(error => {
                this.setState({fetching: false})
                console.log(error)
            })

    }

    fetchOverviewPending() {

        this.setState({fetching: true})

        fetchData( '/api/project/getOverviewPending')
            .then(response => handleErrors(response).json())
            .then(json => {

                let newList;

                if( this.state.value.some((v) => v.name === json[4]) ) {
                    newList= this.state.value.map((v) => {
                        if( v.name === json[4] ) {
                            return Object.assign({}, v, {
                                value: json[5],
                                newCustomers: json[6],
                                otherCustomers: json[7],
                                fetching: false
                            })
                        } else if( v.name === json[0] ) {
                            return Object.assign({}, v, {
                                value: json[0],
                                newCustomers: json[1],
                                otherCustomers: json[2],
                                fetching: false
                            })
                        } else {
                            return v
                        }
                    })
                } else {
                    newList = [
                        {
                            name: json[4],
                            text: json[4],
                            year: '',
                            value: json[5],
                            newCustomers: json[6],
                            otherCustomers: json[7],
                            fetching: false
                        },
                        {
                            name: json[0],
                            text: json[0],
                            year: '',
                            value: json[1],
                            newCustomers: json[2],
                            otherCustomers: json[3],
                            fetching: false
                        },
                        ...this.state.value,
                    ]
                }

                this.setState({value: newList, fetching: false})
            })
            .catch(error => {
                this.setState({fetching: false})
                console.log(error)
            })

    }

    fetchOnderhandewerk() {

        this.setState({fetching: true})

        fetchData( '/api/project/getOnderhandeWerk' )
            .then(response => handleErrors(response).json())
            .then(json => {

                this.setState({onderhandeWerk: json, fetching: false})
            })
            .catch(error => {
                this.setState({fetching: false})
                console.log(error)
            })

    }

    onTabChange = (e,d) => {
        switch (d.activeIndex) {
            default:
            case 0:
                this.props.dispatch(fetchProjectEvents(null))
                break;
            case 1:
                this.props.dispatch(fetchProjectEvents(null))
                break;
            case 2:
                var thisYear = parseInt( moment().format('YYYY') );
                var thisMonth = parseInt( moment().format('M') );

                this.fetchOverview(thisMonth, thisYear)
                this.fetchOverview(thisMonth-1, thisYear)
                this.fetchOverview(thisMonth-2, thisYear)
                this.fetchOverviewPending()

                this.props.dispatch(fetchIbiza())
                break;
        }
    }

    renderPackingTypes() {
        return <Tab.Pane>
            <PackingTypes/>
        </Tab.Pane>
    }

    renderInvoices() {
        return <Tab.Pane>

            <p>{this.props.fetchingEventList ? <Translate value="een_moment_aub"/> : ''}</p>

            <ProjectEventTable list={this.props.eventList} onlyInvoice={true}/>

        </Tab.Pane>
    }

    renderMailView() {
        return <Tab.Pane>

            <MailView/>

        </Tab.Pane>
    }

    renderSharketing() {
        return <Tab.Pane>
            <Sharketing/>
        </Tab.Pane>
    }

    renderPackingSlip() {
        return <Tab.Pane>

            <p>{this.props.fetchingEventList ? <Translate value="een_moment_aub"/>: ''}</p>

            <ProjectEventTable list={this.props.eventList} onlyPackingSlip={true}/>

        </Tab.Pane>
    }

    renderIntake() {

        let minWidth= '220px'

        return <div>
            <MediaQuery maxWidth={926}>
                <div style={{textAlign: 'left'}}>
                    {this.state.value.map((r,i) => {
                        return <Accordion key={r.month + '-' + r.year}>
                            <Accordion.Title
                                onClick={() => {
                                    this.fetchOverview(r.month, r.year)
                                    this.setState({value: this.state.value.map((v) => {
                                        if( v.month === r.month && v.year === r.year ) {
                                            return {
                                                ...v,
                                                expanded: !v.expanded
                                            }
                                        } else {
                                            return v
                                        }
                                    })})
                                }}
                                active={r.expanded}
                            >

                                <div style={{display: 'flex'}}>
                                    <Icon fitted name='dropdown' />
                                    {r.text} {r.year}
                                    <div style={{flex: '1', textAlign: 'right'}}>
                                        {r.value ? '€ ' + r.value : null}
                                    </div>
                                </div>

                            </Accordion.Title>
                            <Accordion.Content active={r.expanded}>
                                <p>
                                    {(r.newCustomers && Array.isArray(r.newCustomers)) ? r.newCustomers.map(n => (<div style={{width: '100%'}}>{n}</div>)) : ''}
                                    <Divider/>
                                    {(r.otherCustomers && Array.isArray(r.otherCustomers)) ? r.otherCustomers.map(n => (<div style={{width: '100%'}}>{n}</div>)) : ''}
                                </p>
                            </Accordion.Content>
                        </Accordion>
                    })}
                </div>
            </MediaQuery>
            <MediaQuery minWidth={927}>
                <Tab.Pane>
                    <div style={{overflow: 'auto', direction: 'rtl'}}>
                        <Table celled>
                            <Table.Body>
                                <Table.Row>
                                    {this.state.value && this.state.value.map((r) => (
                                        <Table.Cell style={{minWidth}}>{r.text + ' ' + r.year}</Table.Cell>
                                    ))}
                                </Table.Row>
                                <Table.Row>
                                    {this.state.value && this.state.value.map((r) => (
                                        <Table.Cell style={{minWidth}}>{r.value ? '€ ' + r.value : <button disabled={this.state.fetching} onClick={() => this.fetchOverview(r.month, r.year)}><Translate value="ophalen"/></button>}</Table.Cell>
                                    ))}
                                </Table.Row>
                                <Table.Row style={{verticalAlign: 'top'}}>
                                    {this.state.value && this.state.value.map((r) => (
                                        <Table.Cell style={{minWidth}}>
                                            {(r.newCustomers && Array.isArray(r.newCustomers)) ? r.newCustomers.map(n => (<div style={{width: '100%'}}>{n}</div>)) : ''}
                                            <Divider/>
                                            {(r.otherCustomers && Array.isArray(r.otherCustomers)) ? r.otherCustomers.map(n => (<div style={{width: '100%'}}>{n}</div>)) : ''}
                                        </Table.Cell>
                                    ))}
                                </Table.Row>
                            </Table.Body>
                        </Table>
                    </div>
                </Tab.Pane>
            </MediaQuery>

            {JSON.stringify(this.props.ibiza)}

            <Chart
                chartType="LineChart"
                width="100%"
                height="400px"
                data={[
                    [{ type: "date", label: "Date" }, 'Intake', 'Ibiza'],
                    ...this.props.ibiza.map((i) => [new Date(i.date), i.intake, 1000000])
                ]}
                options={{
                    title: "🏝️🍸",
                    curveType: "function",
                    legend: { position: "bottom" },
                }}
            />
        </div>
    }

    renderPlanning = () => {

        return <Planning/>

    }

    renderOnderhandewerk() {

        let minWidth= '100%'

        return <Tab.Pane>
            <button disabled={this.state.fetching} onClick={() => this.fetchOnderhandewerk()}><Translate value="ophalen"/></button>

            <div style={{overflow: 'auto'}}>
                <Table celled>
                    <Table.Body>
                        {this.state.onderhandeWerk && this.state.onderhandeWerk.map((r) => (

                                Array.isArray(r) ?
                                    r.map((v) => <Table.Row>
                                        <Table.Cell style={{minWidth}}>{v}</Table.Cell>
                                    </Table.Row>)

                                    :
                                        <Table.Row><Table.Cell style={{minWidth}}>{r}</Table.Cell></Table.Row>


                        ))}
                    </Table.Body>
                </Table>
            </div>

        </Tab.Pane>
    }

    renderComponentData() {
        return <ComponentDataView/>
    }

    renderDigiKey() {
        return <Tab.Pane style={{textAlign: 'left'}}>
            <DigiKeyAuthentication/>
        </Tab.Pane>
    }

    renderNexar() {
        return <Tab.Pane style={{textAlign: 'left'}}>
            <NexarAuthentication/>
        </Tab.Pane>
    }

    renderKvk() {
        return <Tab.Pane style={{textAlign: 'left'}}>
            <KvkApiAuthentication/>
        </Tab.Pane>
    }

    renderDropbox() {
        return <Tab.Pane style={{textAlign: 'left'}}>
            <DropboxAuthentication/>
        </Tab.Pane>
    }

    renderApplicationSettings() {
        return <Tab.Pane style={{textAlign: 'left'}}>
            <ApplicationSettings/>
        </Tab.Pane>
    }

    renderVertalen() {
        return <Tab.Pane>
            <LanguageEditor/>
        </Tab.Pane>
    }

    renderChat() {
        return <Tab.Pane>
            <ChatView isPageView={false}/>
        </Tab.Pane>
    }

    renderUserGroups() {
        return <Tab.Pane>
            <AccountGroups/>
        </Tab.Pane>
    }

    renderActiveUsers() {
        return <Tab.Pane>
            <ActiveUsers/>
        </Tab.Pane>
    }

    renderBomSettings() {
        return <div>
            <BomAdminView/>
        </div>
    }

    renderCheckin() {
        return <Tab.Pane>
            <BomOverviewCheckin/>
        </Tab.Pane>
    }

    renderUrenOverzicht() {
        return <Tab.Pane>
            <EmployeeHoursOverview/>
        </Tab.Pane>
    }

    renderNewsletter() {
        return <Tab.Pane>
            <NewsLetterOverview/>
        </Tab.Pane>
    }

    render() {

        const admin = isAdmin(this.props.currentUser)

        const panes = [
            ...(admin ? [{ key: 4, menuItem: <Menu.Item>Intake</Menu.Item>, render: () => <Tab.Pane>{this.renderIntake()}</Tab.Pane> },
            { key: 1, menuItem: <Menu.Item><Translate value='menu_packing_slips'/></Menu.Item>, render: () => <Tab.Pane>{this.renderPackingSlip()}</Tab.Pane> },
            { key: 2, menuItem: <Menu.Item>Invoices</Menu.Item>, render: () => <Tab.Pane>{this.renderInvoices()}</Tab.Pane> },
            { key: 4, menuItem: <Menu.Item>E-mail</Menu.Item>, render: () => <Tab.Pane>{this.renderMailView()}</Tab.Pane> },
            /*{ key: 3, menuItem: <Menu.Item><Translate value='menu_admin_planning'/></Menu.Item>, render: () => <Tab.Pane>{this.renderPlanning()}</Tab.Pane> },*/
            { key: 5, menuItem: <Menu.Item><Translate value='menu_work_in_progress'/></Menu.Item>, render: () => this.renderOnderhandewerk() },
            { key: 6, menuItem: <Menu.Item>Sharketing</Menu.Item>, render: () => this.renderSharketing() },
            { key: 6, menuItem: <Menu.Item>Settings</Menu.Item>, render: () => this.renderApplicationSettings() }] : []),
            /*{ key: 6, menuItem: 'ComponentData', render: () => this.renderComponentData() },*/
            { key: 7, menuItem: 'DigiKey', render: () => this.renderDigiKey() },
            { key: 7, menuItem: 'Nexar', render: () => this.renderNexar() },
            { key: 16, menuItem: 'KvK', render: () => this.renderKvk() },
            { key: 16, menuItem: 'Dropbox', render: () => this.renderDropbox() },
            { key: 8, menuItem: <Menu.Item><Translate value='menu_translate'/></Menu.Item>, render: () => this.renderVertalen() },
            { key: 9, menuItem: 'BOM', render: () => this.renderBomSettings() },
            { key: 10, menuItem: <Menu.Item><Translate value='menu_user_groups'/></Menu.Item>, render: () => this.renderUserGroups() },
            ...( admin ? [{ key: 11, menuItem: <Menu.Item><Translate value='menu_active_users'/></Menu.Item>, render: () => this.renderActiveUsers() }] : []),
            { key: 13, menuItem: 'Checkin', render: () => this.renderCheckin() },
            /*{ key: 12, menuItem: <Menu.Item>PackingTypes</Menu.Item>, render: () => <Tab.Pane>{this.renderPackingTypes()}</Tab.Pane> },*/
            ...( admin ? [{ key: 14, menuItem: <Menu.Item><Translate value='menu_hours_overview'/></Menu.Item>, render: () => this.renderUrenOverzicht() }] : []),
            { key: 15, menuItem: <Menu.Item><Translate value='menu_nieuwsbrief_uplacer'/></Menu.Item>, render: () => this.renderNewsletter() },
        ]

        return <main id={'dashboard'} className={'pageContainer'}>

            <Tab menu={{  }} renderActiveOnly={true} panes={panes} onTabChange={this.onTabChange}/>
        </main>
    }
}

const mapStateToProps = (state) => {
    return {
        currentUser: state.main.currentUser,
        lang: state.main.lang,
        fetchingEventList: state.project.fetchingEventList,
        eventList: state.project.eventList,
        ibiza: state.main.ibiza,
    }
}

export default connect(mapStateToProps)(Dashboard)