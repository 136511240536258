import React, {Component} from "react";
import {Icon} from "semantic-ui-react";
import PropTypes from "prop-types";
import {fetchProjectStats} from "../actions";
import connect from "react-redux/es/connect/connect";

class BomStats extends Component<{}> {

    updateStats = () => {
        if( this.props.projectId ) {
            this.props.dispatch(fetchProjectStats(this.props.projectId))
        }
    }

    componentDidMount() {
        this.updateStats();
    }

    componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS) {
        if( prevProps.projectId !== this.props.projectId ) {
            this.updateStats();
        }
    }

    render() {
        
        let bomStats = null;

        if( this.props.bomStats && this.props.bomStats.projectOrderId === this.props.projectId ) {
            bomStats = this.props.bomStats;
        }

        if( bomStats == null ) {
            return <></>
        }

        return <p className='stats-summary' onMouseEnter={() => this.updateStats()}>
            <span className='stats-summary-done' style={{marginLeft: 4}}>{bomStats.pcbReceived || '0'}</span>
            <span>{bomStats.pcbNeed || '0'}</span>
            {" | "}
            <span className='stats-summary-done'>{bomStats.stencilReceived || '0'}</span>
            <span>{bomStats.stencilNeed || '0'}</span>
            {" | "}
            <span className='stats-summary-done'>{bomStats.checkinDone || '0'}</span>
            <span>{bomStats.bomLines || '0'}</span>
            {" | "}
            <span className='stats-summary-done'>{bomStats.hasCentroid ? 'C' : '-'}</span>
            {bomStats.fetching ? <Icon loading name='spinner'/> : ''}
        </p>
    }
}

BomStats.propTypes = {
    projectId: PropTypes.string,
};

BomStats.defaultProps = {
    projectId: null,
};

const mapStateToProps = (state) => {
    return {
        bomStats: state.project.bomStats
    }
}

export default connect(mapStateToProps)(BomStats)