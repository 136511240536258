import React from 'react'
import {Button, Form, Icon} from "semantic-ui-react";
import {connect} from "react-redux";

class MessageSideBar extends React.Component {

    state = {}

    render() {
        return <div style={{padding: 0, position: 'absolute', right: 12, top: 99}}>
            <Icon
                onClick={()=> {
                    this.setState({MessageSidebarOpen: true})
                }}
                link name='chat'
                size='large'/>
            <div
                className={'message-sidebar-main'}
                style={{width: this.state.MessageSidebarOpen? 480 : 0}}>
                <h2><Icon
                    name='chat'/>
                </h2>
                <Button icon style={{position: 'absolute', left:0, top:0}}
                        onClick={()=> {
                            this.setState({MessageSidebarOpen: false})}}>
                    <Icon name='close'/>
                </Button>
            </div>
        </div>
    }
}

const mapStateToProps = (state) => {
    return {
    }
}

export default connect(mapStateToProps)(MessageSideBar)