import React, {Component} from "react";
import connect from "react-redux/es/connect/connect";
import {getNewsletterList} from "./actions";
import {Table} from "semantic-ui-react";
import {formatDateTime} from "./actions/functions";

class NewsLetterOverview extends Component {

    componentDidMount() {
        this.props.dispatch(getNewsletterList())
    }

    render() {
        const list = this.props.newsletterList ? this.props.newsletterList : [];

        return <div>
            <Table>
                <Table.Body>
                    {list.map(n => <Table.Row>
                        <Table.Cell>{n.email}</Table.Cell>
                        <Table.Cell>{formatDateTime(n.date)}</Table.Cell>
                    </Table.Row>)}
                </Table.Body>
            </Table>
        </div>;
    }
}

const mapStateToProps = (state) => {
    return {
        newsletterList: state.main.newsletterList
    }
}

export default connect(mapStateToProps)(NewsLetterOverview)