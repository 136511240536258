import React, { Component } from 'react';
import connect from "react-redux/es/connect/connect";
import './App.css';
import PropTypes from "prop-types";
import {Link} from "react-router-dom";
import CalculatorPriceView from "./oldui/CalculatorPriceView";
import {
    formatDate,
    formatDateTime,
    getCompanyName,
    getDate, getPaymentStatusColor,
    isEmployee,
    projectStatus
} from "./actions/functions";
import Translate, {getText} from "./Translate";
import ProjectCardStatusBar from "./ProjectCardStatusBar";
import {Button, Dropdown, Icon, Input, Modal, Message, Popup} from "semantic-ui-react";
import {changeOwner, fetchUsers, saveProject, setProjectParkedStatus, setProjectPaymentStatus} from "./actions";
import ProjectCardOverview from "./ProjectCardOverview";
import {withRouter} from "react-router-dom";
import ProjectAdminMessages from "./ProjectAdminMessages";
import ProjectPlanning from "./ProjectPlanning";
import ProjectCardPcbPrice from "./ProjectCardPcbPrice";
import ProjectSetInternalNameModal from "./ProjectSetInternalNameModal";

function copyToClipboard(text) {
    // Create a hidden textarea element
    const textarea = document.createElement("textarea");
    textarea.style.position = "fixed";
    textarea.style.left = "0";
    textarea.style.top = "0";
    textarea.style.opacity = "0";
    textarea.value = text;
    document.body.appendChild(textarea);

    textarea.select();
    document.execCommand("copy");
    document.body.removeChild(textarea);
}

class ProjectCard extends Component{

    state= {
        changeField: null
    }

    traslateTable= {
        name: getText(this.props, 'project_name'),
        reference: getText(this.props, 'project_user_reference'),
        orderDate: getText(this.props, 'project_orderdate'),
        owner: getText(this.props, 'project_owner'),
        internalName: getText(this.props, 'project_internal_name'),
        bomId: 'BOM referentie',
        projectStatus: getText(this.props, 'project_status')
    }

    componentDidMount() {
        this.openChangeItem = this.openChangeItem.bind(this);
        //this.props.dispatch(fetchPrice(this.props.project.calculatorDetails, this.props.project.id))
    }

    componentDidUpdate() {
        this.openChangeItem = this.openChangeItem.bind(this);
    }

    checkIfNull(value){
        if(value == null) return ' '
        else return ' ' + value + ' '
    }

    openChangeItem(fieldName) {

        console.log('openChangeItem ' + fieldName)

        if( fieldName === 'owner' || fieldName === 'projectOwner' ) {
            this.props.dispatch(fetchUsers())
        }

        switch (fieldName) {
            case 'orderDate':
                this.setState({changeValue: formatDate(this.props.project[fieldName]), changeField: fieldName})
                break;
            case 'deliveryDate':
                this.setState({editProjectPlanning: true})
                break;
            //TODO case 'internalName':
            //    this.setState({editProjectInternalName: true})
            //    break;
            default:
                this.setState({changeValue: this.props.project[fieldName], changeField: fieldName})
                break;
        }

    }

    renderChangeItem(name) {
        switch (name) {
            case 'orderDate':
                return <Input fluid value={this.state.changeValue} onChange={(e, {value}) => this.setState({changeValue:value})} />
            case 'deliveryDate':
                return <ProjectPlanning/>
            case 'owner':
                return <Dropdown placeholder={getText(this.props, "project_owner")} onChange={(e, { value, text }) => {
                    //console.log(JSON.stringify(value))
                    this.setState({changeValue: value})
                }} value={this.state.changeValue} fluid search selection options={this.props.allUsers} />
            case 'projectOwner':
                return <Dropdown placeholder={getText(this.props, "project_owner")} onChange={(e, { value, text }) => {
                    //console.log(JSON.stringify(value))
                    this.setState({changeValue: value})
                }} value={this.state.changeValue} fluid search selection options={this.props.allUsers.filter(u => {
                    let show= false;

                    u.value.roles && u.value.roles.forEach(r => {
                        if( r.name === "ROLE_EMPLOYEE" ) {
                            show= true;
                        } else if( r.name === "ROLE_ADMIN" ) {
                            show= true;
                        }
                    })

                    return show
                })} />
            case 'projectStatus':
                return <Dropdown pointing placeholder={getText(this.props, "project_status")} options={projectStatus} value={this.state.changeValue} onChange={(e, {value}) => this.setState({changeValue:value})}/>
            default:
                return <Input fluid value={this.state.changeValue && this.state.changeValue} onChange={(e, {value}) => this.setState({changeValue: value})} />
        }

    }

    onSaveItem(fieldName, value) {

        this.setState({busy: true})

        if( fieldName === 'orderDate' || fieldName === 'deliveryDate' ) {

            let obj= {};
            obj[fieldName]= getDate(value);
            let newProject= Object.assign({}, this.props.project, obj);

            this.props.dispatch(saveProject(newProject, null,() => this.setState({changeField: null, savingField: false, busy: false})))

        }
        else if( fieldName === 'owner' ) {
            this.props.dispatch(changeOwner(this.props.project.id, value.id,() => this.setState({changeField: null, savingField: false, busy: false})))
        } else {
            let obj= {};
            obj[fieldName]= value;
            let newProject= Object.assign({}, this.props.project, obj);

            this.props.dispatch(saveProject(newProject, null, () => this.setState({changeField: null, savingField: false, busy: false})))
        }


    }

    setProjectPaymentStatus = (id, newValue) => {

        this.props.dispatch(setProjectPaymentStatus(id, newValue))

    }




    render() {
        let p = this.props.project;
        let price= null;

        let admin= isEmployee(this.props.currentUser)

        let companyName = p.owner ? p.owner.companyName : '';
        if( companyName == null || companyName === '' ) {
            companyName = getCompanyName(p.owner);
        }
        companyName= companyName ? companyName : '...';
        let ownerName= p.owner.firstName + this.checkIfNull(p.owner.tussenvoegsel) + p.owner.lastName;

        if( !isEmployee(this.props.currentUser) ) {
            companyName= formatDateTime(p.aanvraagDate);
            //ownerName= null;
        } else {
            ownerName= <Popup trigger={<div style={{display: 'inline'}}>
                {!this.props.listView && <Icon name='share square' style={{marginLeft: 8, cursor: 'pointer', color: this.state.hoverIcon ? 'blue' : ''}} onClick={(e) => {
                    this.props.history.push('/account/' + p.owner.id);
                e.preventDefault();
                   }}/>}

                {ownerName}
            </div>} hoverable>

                <a href={"mailto:"+p.owner.email}  target="_top">{p.owner.email} </a>
                    <Icon link name='clipboard outline' onClick={(e) => {
                        copyToClipboard(p.owner.email);

                        e.stopPropagation();
                    }} />
                <div>

                    {p.owner.telefoon ? <a href={"tel:"+ p.owner.telefoon} target="_top"><Icon name='phone'/>{  p.owner.telefoon}</a> : ''}
                    <Icon link name='clipboard outline' onClick={(e) => {
                        copyToClipboard(p.owner.telefoon);
                        e.stopPropagation();

                    }} />
                </div>
                <div>
                    {p.owner.mobiel ? <a href={"tel:" + p.owner.mobiel} target="_top"><Icon name='mobile alternate'/>{p.owner.mobiel}</a> : ''}
                </div>
                <div>
                    {p.owner.lang ? p.owner.lang.toUpperCase() : ''}
                </div>
            </Popup>


        }

        let className = 'projectCard';
        if (this.props.listView) {
            className += ' hover';
        }

        if( !this.props.dummy ) {
            price = <div style={{display: 'inline'}}>

                <CalculatorPriceView
                    orderId={p.id} selectedDays={p.calculatorDetails.selectedDays}
                    numPcbs={p.calculatorDetails.numPcbs}
                />

                {admin && <div title={p.paymentStatus} style={{display: 'inline', marginLeft: 8}}>
                    <Icon title={p.paymentStatus} name='money' color={getPaymentStatusColor(p.paymentStatus)}
                          style={{cursor: 'pointer'}} onClick={(e) => {

                        if (p.paymentStatus === 'payed') {
                            this.setProjectPaymentStatus(p.id, 'na')
                        } else if (p.paymentStatus === 'sent') {
                            this.setProjectPaymentStatus(p.id, 'payed')
                        } else {
                            this.setProjectPaymentStatus(p.id, 'sent')
                        }

                        e.preventDefault();
                    }}/>
                </div>}

            </div>
        }

        return(
            <div>

                {(isEmployee(this.props.currentUser)&&this.props.project&&!this.props.listView&&!this.props.dummy) ? <div style={{marginBottom: 16}}>
                        <ProjectAdminMessages project={this.props.project}
                                              onOpenChangeModal={(value) => this.openChangeItem(value)}
                        />
                </div>
                    : ''}

                <section className={className}>

                    {this.state.editProjectPlanning ? <ProjectPlanning onClose={() => {
                        this.setState({editProjectPlanning: false})
                    }}/> : ''}

                    {this.state.editProjectInternalName ? <ProjectSetInternalNameModal project={p} onClose={() => {
                        this.setState({editProjectInternalName: false})
                    }}/> : null}

                    <Modal dimmer={false} closeIcon={true} open={this.state.changeField != null} onClose={() => this.setState({changeField: null})}>
                        <Modal.Header>
                            {this.props.project.internalName} {this.state.changeField && this.traslateTable[this.state.changeField]}
                        </Modal.Header>
                        <Modal.Content>
                            <Modal.Description>
                                {this.state.error && <Message negative>
                                    <Message.Header><Translate value="wrong"/></Message.Header>
                                    <p>{JSON.stringify(this.state.error)}</p>
                                </Message>}
                                <p>{this.renderChangeItem(this.state.changeField)}</p>
                            </Modal.Description>
                        </Modal.Content>
                        <Modal.Actions>
                            <Button disabled={this.state.busy} onClick={() => this.setState({changeField: null, error: null})}>
                                <Icon name='remove' /> <Translate value="cancel"/>
                            </Button>
                            <Button primary disabled={this.state.busy} loading={this.state.busy} onClick={() => {
                                this.onSaveItem(this.state.changeField, this.state.changeValue);
                            }}>
                                <Icon name='checkmark' /> {this.state.busy ? <Translate value="moment"/> : this.state.saved ? <Translate value="saved"/> : <Translate value="save"/>}
                            </Button>
                        </Modal.Actions>
                    </Modal>



                    {(!this.props.dummy) ? (this.props.listView) ? <Link to={'/project/' + p.id}>
                        <ProjectCardOverview companyName={companyName}
                                         ownerName={ownerName}
                                         customerProjectName={p.name}
                                         poRef={p.reference}
                                         quantity={p.calculatorDetails.numPcbs}
                                         selectedDays={p.calculatorDetails.selectedDays}
                                         aanvraagDate={formatDateTime(p.aanvraagDate)}
                                         deliveryDate={formatDate(p.deliveryDate)}
                                         internalName={p.internalName ? p.internalName : admin ? '...' : ''}
                                         price={<ProjectCardPcbPrice price={price} projectId={p.id}/>}
                                         project={p}
                                         parked={p.parked}
                        />
                    </Link> : <ProjectCardOverview companyName={companyName}
                                               ownerName={ownerName}
                                               customerProjectName={p.name}
                                               poRef={p.reference}
                                               quantity={p.calculatorDetails.numPcbs}
                                               selectedDays={p.calculatorDetails.selectedDays}
                                               aanvraagDate={formatDateTime(p.aanvraagDate)}
                                               deliveryDate={formatDate(p.deliveryDate)}
                                               internalName={p.internalName ? p.internalName : admin ? <div style={{color: 'red', display: 'inline'}}>Interne naam</div> : ''}
                                               onOpenChangeModal={(value) => this.openChangeItem(value)}
                                               price={<ProjectCardPcbPrice price={price} projectId={p.id}/>}
                                               project={p}
                                               parked={p.parked}
                        /> : <ProjectCardOverview companyName={p.companyName}
                            ownerName={p.ownerName}
                            customerProjectName={p.name}
                            poRef={p.reference}
                            quantity={p.numPcbs}
                            aanvraagDate={formatDateTime(p.aanvraagDate)}
                            selectedDays={p.selectedDays}
                            deliveryDate={p.deliveryDate}
                            internalName={p.internalName}
                            price={<ProjectCardPcbPrice price={price} projectId={p.id}/>}
                            project={p}
                            parked={p.parked}
                        />}
                    <ProjectCardStatusBar loading={p.updateing} project={p} statusBarClickable={this.props.statusBarClickable} activePage={this.props.activePage} dummy={this.props.dummy}/>
                </section>
            </div>
        )
    }
}

ProjectCard.propTypes = {
    project: PropTypes.object,
    statusBarClickable: PropTypes.bool,
    activePage: PropTypes.string,
    subPage: PropTypes.string,
    listView: PropTypes.bool,
    dummy: PropTypes.bool
};

ProjectCard.defaultProps = {
    project: null,
    statusBarClickable: false,
    activePage: null,
    subPage: null,
    listView: false,
    dummy: false
};

const mapStateToProps = (state) => {
    return {
        lang: state.main.lang,
        currentLang: state.main.currentLang,
        currentUser: state.main.currentUser,
        allUsers: state.project.allUsers
    }
}

export default connect(mapStateToProps)(withRouter(ProjectCard))