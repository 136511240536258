
const initialState= {page: {content: []}, fetchingPage: false}

const mail = (state = initialState, action) => {
    switch( action.type ) {
        case 'START_GET_EMAIL_OVERVIEW':
            return {...state, fetchingPage: true}
        case 'RECEIVE_GET_EMAIL_OVERVIEW':
            return {...state, fetchingPage: false,
                page: action.value.number ? Object.assign({}, action.value, {
                    content: [...state.page.content, ...action.value.content]
                }) : action.value,
            }
        case 'ERROR_GET_EMAIL_OVERVIEW':
            return {...state}
        default:
            return state
    }
}
export default mail
