import React from 'react';
import connect from "react-redux/es/connect/connect";
import {
    addCompany, addCompanyFilter,
    addCompanySort,
    addSharketing,
    addSharketingPerson,
    addSharketingSort,
    changeCompanyValue,
    changeSharketingPersonValue,
    changeSharketingValue,
    getCompanyList,
    getSharketingPersonList, openCompanyFilter,
    removeCompanySort,
    removeSharketing,
    removeSharketingSort,
    updateCompany,
    updateSharketingPerson
} from "../actions";
import {
    Button,
    Checkbox,
    Dropdown,
    Form,
    Icon,
    Input,
    Label, Loader,
    Menu,
    Modal,
    Popup,
    Table,
    TextArea
} from "semantic-ui-react";
import DatePicker from "react-datepicker";
import {formatDateTime} from "../actions/functions";
import {hideFooter} from "../bom/functions";
import {withRouter} from "react-router-dom";
import moment from "moment";
import { CSVLink, CSVDownload } from "react-csv";
import Translate from "../Translate";

const bedrijvenStatus = [
    {key: '1', text:'Suspect', value: 'SUSPECT', label: <Label color='blue'>automatisch</Label>}, //default
    {key: '2', text: 'Prospect', value: 'PROSPECT', label: <Label color='blue'>automatisch</Label>}, //aanvraag geplaatst PR01
    {key: '3', text: 'Klant', value: 'KLANT', label: <Label color='blue'>automatisch</Label>}, //PR01 ordered
    /*{key: '4', text: 'Ambassadeur', value: 'AMBASSADEUR'},*/
    {key: '6', text: 'Slof', value: 'STALE', label: <Label color='blue'>automatisch</Label>}, //laatste order 24 maanden oud
    {key: '5', text: 'Afvaller', value: 'AFVALLER'}, //handmatig geset, overruled de automatische set
    {key: '7', text: 'Happy', value: 'HAPPY', label: <Label color='blue'>automatisch</Label>}, //Klant met meer dan 10 projecten
]

const companyTypes = [
    'OEM',
    'STARTUP',
    'RD',
    'EDU',
    'EMS',
    'OTHER'
].map(o => ({key: o, text: (o === 'RD' ? 'R&D': o), value: o}))

class Bedrijven extends React.Component {

    state = {
        activeItem: 'sharketing',
        headers: [
            {id: 1,  w: 60, show: true, name: 'i', text: 'Line', sortable: true, center: true},
            {id: 3,  w: 280, show: true, name: 'name', text: 'Company', sortable: true},
            {id: 4,  w: 100, show: true, name: 'internalName', text: 'ICC', sortable: true},
            {id: 4,  w: 100, show: true, name: 'kvkNumber', text: 'KvK nummer', sortable: true},
            {id: 4,  w: 100, show: true, name: 'status', text: 'Status', filterOptions: bedrijvenStatus},
            {id: 5,  w: 160, show: true, name: 'lastProjectOrderInternalName', text: 'Laatste project', sortable: true},
            {id: 6,  w: 100, show: true, name: 'lastProjectOrderAanvraagDate', text: 'Datum', sortable: true, center: true},
            {id: 6,  w: 60, show: true, name: 'numberOfOrderProjects', text: 'PrQty', sortable: true, center: true},
            {id: 9,  w: 100, show: true, name: 'companyType', text: 'Type', filterOptions: companyTypes},
            {id: 10, w: 100, show: true, name: 'zip', text: 'Postcode', sortable: true},
            {id: 11, w: 150, show: true, name: 'provincie', text: 'Provincie', sortable: true},
            {id: 12, w: 150, show: true, name: 'country', text: 'Land', sortable: true},
            {id: 13, w: 104, show: true, name: '', text: '', sortable: false}
        ]
    }

    componentDidMount() {
        this.props.dispatch(getCompanyList())
        hideFooter()

        window.addEventListener('mousemove', this.onMouseMove.bind(this), false);
        window.addEventListener('mouseup', this.onMouseUp.bind(this), false);
    }

    componentWillUnmount() {
        window.removeEventListener('mousemove', this.onMouseMove.bind(this), false);
        window.removeEventListener('mouseup', this.onMouseUp.bind(this), false);
    }

    handleChange = (p, event) => {
        this.props.dispatch(changeSharketingPersonValue(p.id, event.target.name, event.target.value))
    }

    getColStyle( id, header ) {

        const m_col= this.state.headers.filter(c => c.id === id)[0];

        return Object.assign({}, m_col.style ? m_col.style : {}, {
            maxWidth: m_col.w,
            minWidth: m_col.w,
            boxShadow: 'none',
            color: 'black',/*
            padding: 'unset'*/
            cursor: (header.sortable || header.filterable) ? 'pointer' : 'unset',
            borderRadius: 'unset',
            textAlign: header.center ? 'center' : null
        })

    }

    renderColResizer( col, value ) {
        return <>
            <>
                {value}
            </>
            <div style={{display: 'inline-block',
                position: 'absolute',
                width: '36px',
                top: 0,
                marginTop: '24px',
                height: 'calc(100% - 24px)',
                right: '-18px',
                cursor: 'col-resize',
                zIndex: 10}}
                 onMouseDown={(e, t) => {
                     this.handleMouseDown(e, t, col.id)
                 }}
            />

        </>
    }

    onMouseMove = (e) => {
        if( this.state.isResizing ) {

            const newWidth =  this.state.startWidth + (e.clientX - this.state.startX);

            this.setState({
                headers: this.state.headers.map((c, i) => {
                    return (c.id === this.state.colResizing) ? Object.assign({}, c, {w: newWidth}) : c
                })
            });

            //this.props.funcResizing( this.props.id, e.clientX, e.clientY);
        }
    }

    onMouseUp = (e) => {
        setTimeout(() => {
            this.setState({
                isResizing: false,
            });
        }, 100)


    }

    handleMouseDown = (event, t, col) => {

        let startWidth= this.state.headers.filter((c, i) => c.id === parseInt(col))[0].w;

        this.setState({
            startX: event.clientX,
            startWidth,
            isResizing: true,
            colResizing: col
        });

    };



    renderSharkTypeIcon = (p, name) => {

    }

    render = () => {
        const companyList = this.props.companyList;
        const fetchingCompanyList = this.props.fetchingCompanyList;
        const sort = this.props.companySort;
        const headers = this.state.headers

        if( fetchingCompanyList ) {
            return <div style={{marginTop: '20vh', textAlign: 'center', height: '50vh'}}>
                <Loader active inline/>
            </div>
        }

        return <>
            <div style={{position: 'absolute', right: 16, top: 108}}>

                <CSVLink filename={'DeltaProto bedrijven ' + formatDateTime(new Date())} data={companyList.map(c => ({
                    ...c,
                    year: c.lastProjectOrderAanvraagDate ? moment( c.lastProjectOrderAanvraagDate ).format("YYYY") : '',
                    lastProjectNumber: c.lastProjectOrderInternalName ? c.lastProjectOrderInternalName.replace(/[^\d]/g, "") : '',
                }))}>
                    <Button icon labelPosition='left' >
                        <Icon name={'file'}/>
                        <Translate value="download_csv"/>
                    </Button>
                </CSVLink>

                <Modal
                    trigger={<Button>Lijst toevoegen</Button>}
                    header='Lijst bedrijven toevoegen'
                    content={<Modal.Content>
                        Plaats op iedere regel een bedrijfsnaam die je wilt toevoegen.<br/>
                        Als je ook het kvk nummer weet, plaats deze dan achter een comma, na de bedrijfsnaam.
                        <TextArea
                            fluid style={{width: '100%'}}
                            value={this.state.addList}
                            onChange={(e) => this.setState({addList: e.target.value})}
                        />
                    </Modal.Content>}
                    actions={['Annuleren', { key: 'done', content: 'Toevoegen', primary: true, onClick: () => {
                            const list = this.state.addList.split(/\r?\n/).map(line => {
                                let value = line.trim()

                                if( line.includes(',') ) {
                                    let split = line.split(',')
                                    if( split.length > 1 ) {
                                        return {
                                            name: split[0].trim(),
                                            kvkNumber: split[1].trim(),
                                        }
                                    }
                                }

                                return {
                                    name: value
                                }


                            })

                            list.forEach(c => {
                                this.props.dispatch(updateCompany(c))
                            })
                        } }]}
                />

                <Button disabled={companyList.some(p => p.isNew)} onClick={() => {
                    this.props.dispatch(addCompany())

                    setTimeout( () => {
                        this.showme.scrollIntoView({behavior: 'smooth', block: 'nearest'})
                    }, 100)

                }}><Translate value="add"/></Button>
            </div>

            <div style={{display: 'flex', gap: 8, paddingBottom: 8, height: 34}}>
                {sort.map(s => {
                    const h = headers.find(x => x.name === s.name);
                    if( s.sort ) {
                        return <>
                            <Label color='blue' key={s.id}>
                                {s.sort === 'descending' ? <Icon name='caret down'/> : <Icon name='caret up'/>}
                                <button className='link-button' style={{color: 'white'}}
                                        onClick={() => this.props.dispatch(addCompanySort(s.name))}>
                                    {h.text}
                                </button>


                                <Icon name='close' link onClick={() => {
                                    this.props.dispatch(removeCompanySort(s.id))
                                }}/>
                            </Label>
                        </>
                    } else {
                        return <>
                            <Label color='blue' key={s.id}>

                                <Icon name='filter'/>

                                {h.text + " "}

                                <span color='white' style={{backgroundColor: 'white', color: 'black', paddingLeft: 2, paddingRight: 2, borderRadius: '.14rem'}}>
                                    <Dropdown value={s.filter} options={h.filterOptions} onChange={(e,d) => {
                                        this.props.dispatch(addCompanyFilter(s.name, d.value))
                                    }}/>
                                </span>

                                <Icon name='close' link onClick={() => {
                                    this.props.dispatch(removeCompanySort(s.id))
                                }}/>
                            </Label>
                        </>
                    }
                })}
            </div>

            <div id="scrolling" className="tableFixHead sharketingTable">
                <table className="ui celled very compact sortable table" style={{width: 'initial'}}>
                    <thead>
                    <tr>
                        {/* {headers.map(h => {
                                    let sortorder = sort.find(s => s.name === h.name)
                                    let sorted = sortorder ? sortorder.sort : null;

                                    return <Table.HeaderCell sorted={sorted}
                                                             onClick={() => h.sortable && this.props.dispatch(addSharketingSort(h.name))}>
                                        {h.text}
                                    </Table.HeaderCell>
                                })}*/}

                        {headers.map((h,i) => {

                            let sortorder = sort.find(s => s.name === h.name)
                            let sorted = sortorder ? sortorder.filter ? 'filtered' : sortorder.sort : null;

                            return <Table.HeaderCell sorted={sorted} key={i} style={this.getColStyle(h.id, h)}
                                                     onClick={() => {
                                                         if( !this.state.isResizing && h.sortable ) {
                                                             this.props.dispatch(addCompanySort(h.name))
                                                         }
                                                     }}
                                                     className={'ui ' + h.color + ' message ' + h.className }>
                                {this.renderColResizer(h, h.filterOptions ? <Dropdown text={h.text} icon={null} options={h.filterOptions} onChange={(e,d) => {
                                    this.props.dispatch(addCompanyFilter(h.name, d.value))
                                }}/> : h.text)}
                                {this.state.isResizing && <div style={{
                                    position: 'absolute',
                                    right: '0px',
                                    bottom: '-4px',
                                    fontSize: 12,
                                    fontWeight: 'normal',
                                    color: 'grey'
                                }}>
                                    {headers.find(tc => tc.id === h.id).w}
                                </div>}
                            </Table.HeaderCell>
                        })}

                    </tr>
                    </thead>
                    <tbody>
                    {companyList.filter(c => !c.hidden).map((c,i) => {
                        const dirty = c.dirty;
                        return <tr key={c.id}>

                            <td style={{textAlign: 'center'}}>{i}</td>
                            <td>
                                <div style={{display: 'flex'}}>
                                    <div style={{flex: 1}}>
                                        <Input fluid transparent name={'name'} value={c.name} onChange={e => this.handleChangeCompany(c, e)}/>
                                    </div>
                                    <Icon name='edit' link onClick={() => this.setState({editName: c.id})}/>
                                </div>

                                <div style={{flex: 1, textAlign: 'right'}}>
                                    <Modal open={this.state.editName === c.id} closeIcon onClose={() => this.setState({editName: null})}>
                                        <Modal.Header></Modal.Header>
                                        <Modal.Content>
                                            <Form>
                                                <Form.Input label='Company' fluid name={'name'} value={c.name} onChange={e => this.handleChangeCompany(c, e)}/>
                                                <Form.Input label='KvK nummer' fluid name={'kvkNumber'} value={c.kvkNumber} onChange={e => this.handleChangeCompany(c, e)}/>
                                                <Form.TextArea label='Status notitie' fluid name={'statusText'} value={c.statusText} onChange={e => this.handleChangeCompany(c, e)}/>
                                            </Form>

                                        </Modal.Content>
                                        <Modal.Actions>
                                            <Button primary={dirty} onClick={() => this.props.dispatch(updateCompany(c, () => {
                                                this.setState({editName: null})
                                            }))}><Translate value="digikey_opslaan"/></Button>
                                        </Modal.Actions>
                                    </Modal>
                                </div>
                            </td>
                            <td><Input fluid transparent name={'internalName'} value={c.internalName} onChange={e => this.handleChangeCompany(c, e)}/></td>
                            <td>
                                <Dropdown fluid transparent placeholder='' options={bedrijvenStatus} onChange={(event, data) => {
                                    const newValue = data.value
                                    this.props.dispatch(changeCompanyValue(c.id, 'status', newValue))
                                }} value={c.status}/>
                            </td>
                            <td>
                                {c.lastProjectOrderInternalName}
                            </td>
                            <td style={{textAlign: 'center'}}>
                                {/*{formatDateTime(c.lastProjectOrderAanvraagDate)}*/}
                                { c.lastProjectOrderAanvraagDate ? moment( c.lastProjectOrderAanvraagDate ).format("YYYY") : ''}
                            </td>
                            <td style={{textAlign: 'center'}}>
                                { c.numberOfOrderProjects }
                            </td>
                            <td>
                                <Dropdown fluid transparent name={'companyType'} value={c.companyType} onChange={(e,d) => this.props.dispatch(changeCompanyValue(c.id, 'companyType', d.value))}
                                          options={companyTypes}
                                />
                            </td>
                            <td><Input fluid transparent name={'zip'} value={c.zip} onChange={e => this.handleChangeCompany(c, e)}/></td>
                            <td>{c.provincie}</td>
                            <td>
                                <Dropdown
                                    fluid icon={null} trigger={
                                        <Input fluid transparent name={'country'} value={c.country} onChange={e => this.handleChangeCompany(c, e)}/>
                                    } options={[
                                            'Nederland',
                                            'België',
                                            'Duitsland',
                                            'Frankrijk',
                                            'United Kingdom',
                                            'Luxemburg'
                                    ].map(o => ({key: o, text: o, value: o}))}
                                    onChange={(e,d) => {
                                        this.props.dispatch(changeCompanyValue(c.id, 'country', d.value))
                                    }}
                                />

                            </td>
                            <td>
                                <Button size={"mini"} primary={dirty} onClick={() => this.props.dispatch(updateCompany(c))}><Translate value="digikey_opslaan"/></Button>
                            </td>

                        </tr>

                    })}
                    </tbody>
                </table>
                <div key={-1} ref={(ref) => this.showme = ref}></div>
            </div>
        </>
    }

    handleChangeCompany(c, e) {
        this.props.dispatch(changeCompanyValue(c.id, e.target.name, e.target.value))
    }


}

const mapStateToProps = (state) => {
    return {
        lang: state.main.lang,
        currentUser: state.main.currentUser,
        sharketingPersonList: state.main.sharketingPersonList,
        companyList: state.main.companyList,
        fetchingCompanyList: state.main.fetchingCompanyList,
        companySort: state.main.companySort,
    }
}

export default connect(mapStateToProps)(withRouter(Bedrijven))