import React from 'react';
import {connect} from "react-redux";
import TableResize from "../basic/TableResize";
import {
    changeBuddyPart,
    getBuddyParts, getDefaultAutoselectSettings,
    openOrderPartAdd,
    saveBuddyPart,
    searchPart,
    setPartForBuddyPart, updateOrderpart
} from "../actions";
import {Button, Icon, Input, Modal} from "semantic-ui-react";
import BomOrderparts, {renderImageForPart} from "./BomOrderparts";
import BomOrderpartList from "./BomOrderpartList";
import TabHeader from "../basic/TabHeader";
import TabPane from "../basic/TabPane";
import {Link} from "react-router-dom";
import BomSearchParts from "./BomSearchParts";
import BomImportParts from "./BomImportParts";
import BomAddPart from "./BomAddPart";
import BomSearchProjectParts from "./BomSearchProjectParts";
import KnownDielectric from "./admin/KnownDielectric";
import KnownPackages from "./admin/KnownPackages";
import BomTestImport from "./admin/BomTestImport";
import BomMicroPlacerPartSelectionSettings from "./BomMicroPlacerPartSelectionSettings";
import Translate from "../Translate";
import BoardsConfiguration from "./BoardsConfiguration";

class BomAdminView extends React.Component {

    state = {
        activeTab: 0,
        addOpen: false
    }

    componentDidMount() {
        this.props.dispatch(getBuddyParts())
    }

    handleSearch = () => {
        this.props.dispatch(searchPart(this.state.searchQuery, true))
    }

    renderAdd() {
        return <Modal size='large' closeIcon={true} open={true} onClose={() => this.setState({addOpen: false})}>
            <Modal.Header><Translate value="add"/></Modal.Header>
            <Modal.Content >
                <Modal.Description>

                    <div style={{marginTop: 16, textAlign: 'center'}}>
                        <Input
                            ref={(textarea) => { this.searchInput = textarea; }}
                            loading={this.props.searching}
                            value={this.state.searchQuery}
                            onChange={(e) => this.setState({searchQuery: e.target.value})}
                            icon={<Icon name='search' circular link onClick={this.handleSearch} />}
                            placeholder='Search...'
                            onKeyDown={(e) => {
                                if( e.key === 'Enter' ) {
                                    this.handleSearch()
                                }
                            }}
                        />
                    </div>

                    <div style={{marginTop: 16}}>
                        <h3>Found parts:</h3>
                        <BomOrderparts
                            parts={1}
                            amount={1}
                            list={this.props.searchList}
                            onSupplierpart={(sp, op) => {
                                this.props.dispatch(setPartForBuddyPart(null, op.id))
                            }}
                        />
                    </div>

                </Modal.Description>
            </Modal.Content>
        </Modal>
    }

    renderBuddyParts() {

        return <BoardsConfiguration/>

        return <div>
            <TableResize
                defaultWidth={120}
                headers={[
                    {name: 'name', text: 'name'},
                    {name: 'machine', text: 'machine'},
                    {name: 'location', text: 'location'},
                    {name: 'nozzle', text: 'nozzle'},
                    {name: 'price1', text: 'price'},
                    {name: 'orderpartimage', text: 'image', width: 50},
                    {name: 'orderpart', text: 'orderpart', width: 400},
                    {name: 'action', text: ''},
                ]}
                values={this.props.buddy}
                render={(name, value) => {

                    if( name === 'action' ) {
                        return <div style={{float: 'right'}}>
                            <Button size={'mini'} icon onClick={() => {
                                this.props.dispatch(updateOrderpart(value.orderpart.id))
                            }}>
                                <Icon name='refresh'/>
                            </Button>
                            <Button size={'mini'} primary={value.dirty}
                                    onClick={() => {
                                        this.props.dispatch(saveBuddyPart(value))
                                    }}
                            ><Translate value='save'/></Button>
                        </div>
                    } else if (name === 'orderpartimage') {

                        let op = value['orderpart'];

                        return <div>
                            {renderImageForPart(op)}
                        </div>

                    } else if (name === 'orderpart') {

                        let op = value['orderpart'];

                        return <div>

                            {/*<div style={{float: 'right'}}>
                                <Button size={'mini'} icon='edit'/>
                            </div>*/}

                            <div>{op.mpn}</div>
                            <div>{(op.specification && op.specification.displayValue) ? op.specification.displayValue : '...'}</div>

                        </div>
                    }

                    return <Input transparent value={value[name]}
                                  onChange={(e) => this.props.dispatch(changeBuddyPart(value.id, name, e.target.value))}
                    />
                }}
            />


            {/*<Button
                style={{marginTop: 16}}
                onClick={() => this.setState({addOpen: true})}
            >toevoegen</Button>*/}

            {this.state.addOpen && this.renderAdd()}
        </div>
    }

    renderTab(active) {
        switch (active) {
            case 0:
                return this.renderBuddyParts()
            case 1:
                return <div>
                    <BomOrderpartList/>
                </div>
            case 2:
                return <BomSearchParts/>;
            case 3:
                return <BomImportParts/>;
            case 4:
                return <BomSearchProjectParts/>
            case 5:
                return <KnownDielectric/>
            case 6:
                return <KnownPackages/>
            case 7:
                return <BomTestImport/>
            case 8:
                return <div>
                    <BomMicroPlacerPartSelectionSettings asModal={false} isDefaultView={true}/>
                </div>
        }

        return <div>???</div>
    }

    render() {
        return <div style={{textAlign: 'left'}}>

            <BomAddPart/>
            <Button
                onClick={() => this.props.dispatch(openOrderPartAdd(true, null))}
            >Add part</Button>

            <TabHeader panes={['Buddy parts', 'Edit database', 'Search database', 'Import', 'Find used part', 'Dielectric', 'Packages', 'TEST', 'Default supplier settings']} activeIndex={this.state.activeTab}
                       onChange={(value) => {
                           this.setState({activeTab: value})
                           switch (value) {
                               case 8:
                                   this.props.dispatch(getDefaultAutoselectSettings())
                                   break;
                           }
                       }}
            />
            <TabPane>
                {this.renderTab(this.state.activeTab)}
            </TabPane>


        </div>
    }
}

const mapStateToProps = (state) => {
    return {
        buddy: state.bom.buddy,
        searchList: state.bom.searchList,
        searching: state.bom.searching,
        searchQuery: state.bom.searchQuery
    }
}

export default connect(mapStateToProps)(BomAdminView)