import {Button, Dropdown, Modal} from "semantic-ui-react";
import React from "react";
import {isEmployee} from "../actions/functions";

class BomSelectionTools extends React.Component {

    state = {
        removeAllSelections: false,
        setAllNotSelectedToKla: false
    }

    render() {
        const employee = isEmployee(this.props.currentUser)

        if( !employee ) {
            return null
        }

        return (
            <>

            <Dropdown
                inline text='tools'
            >
                <Dropdown.Menu>
                    <Dropdown.Item
                        onClick={() => this.setState({removeAllSelections: true})}
                    >Alle selecties verwijderen</Dropdown.Item>
                    <Dropdown.Item
                        onClick={() => this.setState({setAllNotSelectedToKla: true})}
                    >Alle niet geselecteerde op KLA zetten</Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>

            <Modal open={this.state.removeAllSelections} closeIcon onClose={() => this.setState({removeAllSelections: false})}>
                <Modal.Header>Alle selecties verwijderen</Modal.Header>
                <Modal.Content>
                    <p>Weet je zeker dat je alle selecties wilt verwijderen?</p>
                </Modal.Content>
                <Modal.Actions>
                    <Button onClick={() => this.setState({removeAllSelections: false})}>
                        Annuleren
                    </Button>
                    <Button primary>
                        Selecties verwijderen
                    </Button>
                </Modal.Actions>
            </Modal>
            <Modal open={this.state.setAllNotSelectedToKla} closeIcon onClose={() => this.setState({setAllNotSelectedToKla: false})}>
                <Modal.Header>Alle niet geselecteerde op KLA zetten</Modal.Header>
                <Modal.Content>
                    <p>Weet je zeker dat je alle niet geselecteerde op KLA wilt zetten?</p>
                </Modal.Content>
                <Modal.Actions>
                    <Button onClick={() => this.setState({setAllNotSelectedToKla: false})}>
                        Annuleren
                    </Button>
                    <Button primary>
                        KLA
                    </Button>
                </Modal.Actions>
            </Modal>

            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        currentUser: state.user.currentUser
    }
}

export default BomSelectionTools;