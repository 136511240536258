import React, {Component} from "react";
import {Button, Checkbox, Dropdown, Icon, Input, Message, Modal, Popup, Table} from "semantic-ui-react";
import {
    addDesignator,
    openBomCentroidView, openEditBomRow,
    selectDesignatorBomProduction,
    setDesignatorRemoved,
    setDoNotPlace,
    setSelectedBomRow
} from "../actions";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import {isEmployee} from "../actions/functions";
import {renderDecimalPlaces} from "./BomOrderparts";
import {userDisplayName} from "../oldui/AccountListItem";
import DetectableOverflow from "react-detectable-overflow";

export const getRemovedDesignators = (print) => {

    const all = print.bom.map(b => b.designators).flat();

    let res = all.filter(d => d.removed).filter(d => {
        let exists = all.filter(d => !d.removed).some(a => a.value === d.value)
        return !exists;
    })

    return res.map(d => d.value).filter((d,i,a) => a.indexOf(d) === i)
}

class BomDesignator extends Component {

    state = {
        enter: false,
        addDesignator: ''
    }

    getDesignatorStyle(d) {

        let place = (d.place && !d.removed);

        return {
            color: (d.addedBy ? 'rgb(65, 131, 196)' : ''),
            opacity: place ? 1 : .3,
            textDecoration: d.removed ? 'line-through' : ''
        }
    }

    getDuplicateDesignators() {

        const all = this.props.print.bom.map(b => b.designators.map(d => ({...d, row: b.row}))).flat().filter(d => !d.removed);

        let res = all.filter(d => {
            return all.some(a => a.value === d.value && a.id !== d.id);
        })

        return res.map(d => d.value + ' (Regel ' + d.row + ')')
    }

    renderRow(d) {
        return <Table.Row key={d.id}>
            <Table.Cell>
                <div style={this.getDesignatorStyle(d)}>
                    {d.removed ? 'Verwijderd: ' : ''}
                    {d.value}
                </div>
                {d.addedBy ? <div>toegevoegd door {userDisplayName(d.addedBy)}</div> : ''}
            </Table.Cell>
            <Table.Cell disabled={d.removed}>
                {<Checkbox checked={d.place}
                           onClick={() => this.props.dispatch(setDoNotPlace(d.id, d.place ? 'false':'true'))}
                />}
            </Table.Cell>
            <Table.Cell disabled={d.removed}>{renderDecimalPlaces(d.x)}</Table.Cell>
            <Table.Cell disabled={d.removed}>{renderDecimalPlaces(d.y)}</Table.Cell>
            <Table.Cell disabled={d.removed}>{d.rotate}</Table.Cell>
            <Table.Cell disabled={d.removed}>{d.topBottom}</Table.Cell>
            <Table.Cell>
                <Popup position='top right' trigger={
                    <Button icon onClick={() => this.props.dispatch(setDesignatorRemoved(d.id, d.removed ? 'false':'true'))}>
                        <Icon name={d.removed ? 'undo' : 'close'}/>
                    </Button>
                } content={d.removed ? 'Verwijderen ongedaan maken' : 'Designator uit deze regel verwijderen'}/>
            </Table.Cell>
        </Table.Row>
    }

    handleClose = () => {
        this.props.dispatch(openEditBomRow(null))
        this.props.dispatch(setSelectedBomRow(this.props.bomLineId, true))
    }

    renderEdit(values) {

        const locked = this.props.locked;

        if( this.props.editRow && this.props.editRow === this.props.bomLineId ) {

            const removedDesignators = getRemovedDesignators(this.props.print);
            const duplicateDesignators = this.getDuplicateDesignators();

            return <Modal size='small' closeIcon={true} open={true} onClose={() => this.handleClose()}>
                <Modal.Header>
                    Designators
                    {this.props.bomLineId && <div style={{display: 'inline-block', float: 'right', fontSize: 14, marginTop: -6}}>
                        <Dropdown icon={null} trigger={<Input placeholder='Add designator' size='small'
                                                              value={this.state.addDesignator}
                                                              onChange={(e) => this.setState({addDesignator: e.target.value})}
                                                              onKeyDown={(e) => {
                                                                  if( e.key === 'Enter' ) {
                                                                      this.addDesignator();
                                                                  }
                                                              }}
                        />}>
                            <Dropdown.Menu>
                                {removedDesignators.length ? <Dropdown.Item disabled>Verwijderde designators:</Dropdown.Item> : ''}

                                {removedDesignators.map(v => (
                                    <Dropdown.Item text={v}
                                                   onClick={() => this.setState({addDesignator: v})}/>
                                ))}
                            </Dropdown.Menu>
                        </Dropdown>

                        <Button
                            icon
                            primary
                            size='small'
                            disabled={this.props.addingDesignator || !this.state.addDesignator}
                            loading={this.props.addingDesignator}
                            style={{marginLeft: 8}}
                            onClick={() => this.addDesignator()}
                        >
                            <Icon name='add'/>
                        </Button>
                    </div>}
                </Modal.Header>
                <Modal.Content style={{minHeight: '80vh'}} scrolling >
                    <Modal.Description>

                        {(duplicateDesignators && duplicateDesignators.length) ? <Message error>
                            <Message.Header>Let op! Er staan dubbele designators in de BOM!</Message.Header>
                            <Message.List items={duplicateDesignators}/>
                        </Message> : ''}

                        <Table compact>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell>
                                        Designator
                                        <Input style={{marginLeft: 8}} placeholder='Search...' value={this.state.search} onChange={(e) => this.setState({search: e.target.value})}/>
                                    </Table.HeaderCell>
                                    <Table.HeaderCell>

                                        <Dropdown
                                            inline
                                            text='Place'
                                            onChange={(e,d) => {
                                                if( d.value === 'all' ) {
                                                    values.filter(d => !d.removed).forEach(d => this.props.dispatch(setDoNotPlace(d.id, 'true')))
                                                } else if( d.value === 'none' ) {
                                                    values.filter(d => !d.removed).forEach(d => this.props.dispatch(setDoNotPlace(d.id, 'false')))
                                                }
                                            }}
                                            options={[
                                                {key: 'all', text: 'Alle plaatsen', value: 'all'},
                                                {key: 'none', text: 'Alle niet plaatsen', value: 'none'}
                                            ]}
                                        />
                                    </Table.HeaderCell>
                                    <Table.HeaderCell>X</Table.HeaderCell>
                                    <Table.HeaderCell>Y</Table.HeaderCell>
                                    <Table.HeaderCell>Rotate</Table.HeaderCell>
                                    <Table.HeaderCell>Side</Table.HeaderCell>
                                    <Table.HeaderCell collapsing>Verwijderen</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>

                            <Table.Body>
                                {values.filter(d => {
                                    if( this.state.search ) {
                                        return d.value.toLowerCase().includes(this.state.search.toLowerCase())
                                    }
                                    return true
                                }).map(d => {
                                    return this.renderRow(d)
                                })}

                            </Table.Body>
                        </Table>
                    </Modal.Description>
                </Modal.Content>
                <Modal.Actions>
                    <Button
                        onClick={() => this.handleClose()}
                    >Sluiten</Button>
                </Modal.Actions>
            </Modal>
        }

        if( this.props.bomLineId ) {
            return <div className='designatoredit' style={{marginRight: 8}}>
                {this.state.isOverflowed ? null : <span style={{marginRight: 16}} >...</span> }

                <Popup trigger={
                    <Icon fitted name='edit' color={(this.state.enter && !locked) ? 'black' : 'grey'} link={!locked} disabled={locked}
                          onClick={() => {
                              //this.setState({editOpen: true, enter: false, addDesignator: ''})
                              this.props.dispatch(openEditBomRow(this.props.bomLineId))
                              this.props.dispatch(setSelectedBomRow(this.props.bomLineId))
                          }}/>
                } content={'Designator(s) bewerken'} disabled={locked}/>

                {(isEmployee(this.props.currentUser) && this.props.values.some(d => d.x && d.y)) ? <Popup trigger={
                    <Icon fitted name='fly' color={this.state.enter ? 'black' : 'grey'} link
                          onClick={() => {
                              this.props.dispatch(openBomCentroidView(true, this.props.values.map(d => d.value).join(", ")))
                          }} style={{paddingLeft: 8}}/>
                } content={'Centroid bekijken'}/> : ''}
            </div>
        }

        return ''
    }

    addDesignator() {
        if( !this.props.addingDesignator ) {
            this.props.dispatch(addDesignator(this.state.addDesignator, this.props.bomLineId, () => {
                this.setState({addDesignator: ''})
            }))
        }
    }

    handleEnter = () => {
        this.setState({enter: true})
    }

    handleEnterDesignator = (d) => {
        this.props.dispatch(selectDesignatorBomProduction(d))
    }

    handleLeave = () => {
        this.setState({enter: false})
    }

    render() {

        let values = this.props.values.sort((a, b) => (a.value < b.value ? -1 : (a.value > b.value ? 1 : 0)));//.filter(d => !d.removed);

        //isAdmin(this.props.currentUser)
        let isPcbOrStencil = this.props.values.some(d => d.pcbOrder) || this.props.values.some(d => d.stencilOrder);

        if( this.props.collapsed ) {
            if( values.length > 2 ) {
                return <div className="designatorcolumn" onMouseEnter={this.handleEnter} onMouseLeave={this.handleLeave}>
                    {!isPcbOrStencil && this.renderEdit(values)}
                    {values[0].value} ... {values[values.length-1].value}
                </div>
            } else {
                return <div className="designatorcolumn" onMouseEnter={this.handleEnter} onMouseLeave={this.handleLeave}>
                    {!isPcbOrStencil && this.renderEdit(values)}
                    {values.map(d => (<span className="designator">
                        {d.value}
                    </span>))}
                </div>
            }
        }

        return <div className="designatorcolumn" onMouseEnter={this.handleEnter} onMouseLeave={this.handleLeave}>
            <DetectableOverflow style={{}} onChange={(isOverflowed) => {
                this.setState({isOverflowed: isOverflowed})
            }}>
                {!isPcbOrStencil && this.renderEdit(values)}
                {values.map(d => (
                    <span key={d.value} className="designator"
                          onMouseEnter={() => this.handleEnterDesignator(d)}
                          style={this.getDesignatorStyle(d)}>{d.value}</span>
                ))}
            </DetectableOverflow>
        </div>

    }
}

BomDesignator.propTypes = {
    designator: PropTypes.object,
    collapsed: PropTypes.bool,
    bomLineId: PropTypes.string,
    locked: PropTypes.bool
}

BomDesignator.defaultProps = {
    collapsed: false,
    bomLineId: null,
    locked: false
}

const mapStateToProps = (state) => {
    return {
        currentUser: state.main.currentUser,
        print: state.bom.print,
        editRow: state.bom.editRow,
        addingDesignator: state.bom.addingDesignator,
    }
}

export default connect(mapStateToProps)(BomDesignator)