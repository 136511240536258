import React from 'react'
import ProjectView from "./ProjectView";
import connect from "react-redux/es/connect/connect";
import {withRouter} from "react-router-dom";
import {fetchProject} from "../actions";
import SideBar from "../bom/SideBar";
import MessageSideBar from "./MessageSideBar";

class ProtoflowProject extends React.Component {

    componentDidMount() {
        this.props.dispatch(fetchProject(this.props.match.params.projectId))
    }

    render() {
        return <main id={'projects'} className={'pageContainer'}>
                <SideBar/>
                <MessageSideBar/>
                {this.props.project && <ProjectView project={this.props.project}/>}
            </main>
    }
}

const mapStateToProps = (state) => {
    return {
        project: state.project.project
    }
}

export default connect(mapStateToProps)(withRouter(ProtoflowProject))