import React from 'react'
import {Icon, Modal, Popup, Table} from "semantic-ui-react";
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import {closeOrderpartInfo} from "../actions";

class BomOrderpartInfo extends React.Component {

    handleClose = () => {
        this.props.dispatch(closeOrderpartInfo())
    }

    render() {
        if( this.props.orderpartInfo.length === 0) {
            return null;
        }

        return (
            <div>
                <Table compact='very' size='small' style={{width: '100%', lineHeight: '16px'}}>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>Project</Table.HeaderCell>
                            <Table.HeaderCell>Regel</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {this.props.orderpartInfo.filter(opi => opi.print != null).map(opi => {

                            const projectOrder = (opi && opi.print) ? opi.print.projectOrder : null;

                            return <Table.Row key={projectOrder ? projectOrder.id : opi.print.id}>
                                <Table.Cell>
                                    {projectOrder ? <>
                                        <Link to={'/project/' + projectOrder.id}>{opi.print.projectOrder.internalName ? opi.print.projectOrder.internalName : opi.print.projectOrder.name}</Link>
                                    </> : <>
                                        <Link to={'/bom/' + opi.print.id}>{opi.print.name}</Link>
                                    </>}

                                    {(opi.print && opi.print.id === this.props.print.id) ? ' (huidige project)' : ''}

                                    {(opi.print && opi.print.deleted) ? <Popup trigger={<Icon name='close' color={'red'}/>}
                                                                content={'Project is verwijderd'}/> : ''}
                                </Table.Cell>
                                <Table.Cell>
                                    {opi.bomLine.row}
                                </Table.Cell>
                            </Table.Row>
                        })}
                    </Table.Body>
                </Table>
            </div>
        );
    }

}


const mapStateToProps = (state) => {
    return {
        orderpartInfo: state.bom.orderpartInfo,
        print: state.bom.print
    }
}

export default connect(mapStateToProps)(BomOrderpartInfo)