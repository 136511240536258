import React from 'react';
import connect from "react-redux/es/connect/connect";
import {
    Button,
    Form,
    Table,
    Checkbox,
    Popup,
    Dropdown, Loader, Message, List, Icon
} from "semantic-ui-react";
import PropTypes from "prop-types";
import {
    getChatMentionable,
    getChatOverview, getChatOverviewByPage, getChatRefInfo,
    postChatMessage,
    setChatHandled,
    unhandledCount
} from "../actions";
import ChatUserView from "./ChatUserView";
import {formatDateTime, isEmployee} from "../actions/functions";
import {Link} from "react-router-dom";
import ChatInput from "./ChatInput";
import OnVisible from "react-on-visible";
import DeltaTable from "../deltatable/DeltaTable";
import DeltaTableMenu from "../deltatable/DeltaTableMenu";
import Translate from "../Translate";
import ChatNewMessageModal from "./ChatNewMessageModal";
import ChatSocket from "./ChatSocket";
import ChatAttachment from "./ChatAttachment";

class ChatView extends React.Component {

    state = {
        filter: 'alle'
    }

    componentDidMount() {
        if( this.props.chatId ) {
            this.props.dispatch(getChatOverview(this.props.chatId, null, this.scrollToBottom))
        } else if (this.props.isPageView && !this.props.chatId) {
            this.props.dispatch(getChatOverviewByPage(0, this.state.filter))
        }
        this.props.dispatch(getChatMentionable())
    }

    scrollToBottom = () => {
        if( this.props.scroll ) {
            if (this.showme) {
                if (!this.showme.isVisible) {
                    this.showme.scrollIntoView({behavior: 'smooth', block: 'nearest'})
                }
            }
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if( this.props.chatId != null ) {
            if ((prevProps.chatId == null) || (prevProps.chatId !== this.props.chatId)) {
                this.props.dispatch(getChatOverview(this.props.chatId, null))
            }
        }
    }

    renderInput(chatId) {
        const admin = isEmployee(this.props.currentUser)

        const label = <p>
            <Dropdown text={this.state.to ? this.state.to.firstName : 'Aan'} name="to"
                           options={
                               [...(this.state.to ? [{icon: 'close', onClick: () => this.setState({to: null})}] : []),...this.props.mentionable.map(u => ({key: u.id, text: u.firstName, value: u.id, u: u, onClick: (e, d) => {
                                       console.log('onClick ' + JSON.stringify(d))
                                       this.setState({to: d.u})
                                       //this.props.dispatch(changeChatValue(c.id, 'to', d.u))
                                   }}))]
                           }/>

        </p>
        const placeholder = admin ? 'schrijf eerste een ~ voor berichten aan de klant' : ''

        return <Form style={{display: 'flex', width: 600, margin: 'auto'}} onSubmit={(e) => {
            this.props.dispatch(postChatMessage(chatId, this.state.message, null, this.state.to ? this.state.to.id : null, null, () => {
                this.setState({message: ''})
                this.scrollToBottom()
            }))
        }}>

            <div style={{flex: 1}}>
                <Form.TextArea value={this.state.message} type="text" name="message" rows={1} placeholder={placeholder} label={label} onChange={e => this.setState({message: e.target.value})}/>
            </div>
            <Button style={{marginTop: 34}} primary type="submit">Versturen</Button>
        </Form>
    }

    getList() {
        let list = []

        if( this.props.isPageView ) {
            if( this.props.page ) {
                list = this.props.page.content
            }
        } else {
            list = this.props.chatOverview ? this.props.chatOverview : [];
        }

        //if( this.props.reverseOrder ) {
        //    list = list.reverse();
        //}

        return list;
    }

    renderView() {

        let list = this.getList();

        const employee = isEmployee(this.props.currentUser)

        const cellStyle = {verticalAlign: 'top'}

        return <div style={{textAlign: 'left'}} key={this.props.chatId}>

            {(this.props.isPageView && this.props.unhandledCountCustomer > 0) ? <div>
                <Message color='blue'>
                    <Message.Header>
                        Let op!
                    </Message.Header>
                    <Message.Content>
                        <button className='link-button' onClick={() => {
                            let filter = 'klantnothandled'
                            this.setState({filter: filter})
                            this.props.dispatch(getChatOverviewByPage(0, filter))
                        }}>
                            Er zijn {this.props.unhandledCountCustomer} ongelezen berichten van klanten die nog niet afgehandeld zijn!
                        </button>

                    </Message.Content>
                </Message>
            </div> : null}

            {this.props.chatId ? <ChatNewMessageModal chatId={this.props.chatId} showConfirm={!employee}/> : null}

            <div style={{float: 'right'}}>
                {this.props.chatId ? null : <ChatSocket chatId={this.props.chatId} hidden={!employee}/>}
            </div>

            <div>

                {/*<div style={{marginTop: 16, color: 'red', textAlign: 'center'}}>
                    Let op: deze berichten worden (nog) niet gedeeld met de klant
                </div>*/}

                <div style={this.props.scroll ? {marginTop: 0, overflow: 'auto', maxHeight: '30vh'} : {marginTop: 0}}>

                    {false ? <DeltaTableMenu nopadding={true}>
                        <DeltaTable
                            name={'communicationoverview'}
                            columns={[
                                {
                                    id: 1, header: 'Gebruiker', render: (c) => (
                                        <div>
                                            <ChatUserView user={c.from} type='icon'/>
                                            <div style={{display: 'inline-block'}}>
                                                <ChatUserView user={c.from}/>
                                            </div>
                                        </div>
                                    )
                                },
                                {id: 2, header: 'Datum', render: (c) => formatDateTime(c.date)},
                                {
                                    id: 3, header: 'Project', render: (c) => (
                                        <Link to={'/project/' + c.projectOrderId + '/buy'}>
                                            {c.projectOrderInternalName ? c.projectOrderInternalName : 'project'}
                                        </Link>
                                    )
                                },
                                {
                                    id: 4, header: 'Ref', render: (c) => (
                                        <Popup hoverable trigger={
                                            <span style={{textDecoration: 'underline'}}>{c.ref ? 'BOM' : ''}</span>
                                        } content={<div>
                                            {c.refInfo ? <div>
                                                <div>
                                                    Line: {c.refInfo.row}
                                                </div>
                                                <div>
                                                    Designators: {c.refInfo.designators.map(d => d.value).join(', ')}
                                                </div>
                                                <div style={{color: 'red'}}>
                                                    {c.refInfo.removedFromPrint ? 'Stond in een vorige BOM' : ''}
                                                </div>
                                            </div> : <Loader active inline/>}
                                        </div>}
                                               onOpen={() => c.ref && this.props.dispatch(getChatRefInfo(c.ref, c.id))}/>
                                    )
                                },
                                {
                                    id: 5, header: 'Done', render: (c) => (
                                        <div>
                                            {c.to ? <Popup trigger={<Checkbox fitted checked={!!c.handled}
                                                                              onChange={(e, {checked}) => {
                                                                                  this.props.dispatch(setChatHandled(c.id, !c.handled, () => {
                                                                                      this.props.dispatch(unhandledCount())
                                                                                  }))
                                                                              }}/>}
                                                           content={c.handled ? formatDateTime(c.handled) : 'Nog niet afgehandeld'}/> : ''}
                                        </div>
                                    )
                                },
                                {
                                    id: 6, header: 'Value', render: (c) => (
                                        <ChatInput value={c} renderFrom={false}/>
                                    )
                                },
                            ]}
                            data={list}
                            onLoadMore={() => {
                                if (this.props.page && this.props.page.content.length > 0 && !this.props.page.last && !this.props.fetchingPage) {
                                    this.props.dispatch(getChatOverviewByPage(this.props.page.number + 1, this.state.filter))
                                }
                            }}
                            loading={this.props.fetchingPage}
                        />
                    </DeltaTableMenu> : null}

                    <Table className={'tableRowFixedHeader'} compact='very' size='small' style={{width: '100%'}}>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell collapsing={true}><Translate value="gebruiker"/></Table.HeaderCell>
                                <Table.HeaderCell collapsing={true}><Translate value="datum"/></Table.HeaderCell>
                                {this.props.chatId ? '' :
                                    <Table.HeaderCell collapsing={true}><Translate value="project"/></Table.HeaderCell>}
                                <Table.HeaderCell collapsing={true}><Translate value="ref"/></Table.HeaderCell>
                                {employee ? <Table.HeaderCell collapsing={true}><Translate value="done"/></Table.HeaderCell> : null}
                                <Table.HeaderCell>
                                    {employee ? <Dropdown value={this.state.filter} options={[
                                        {value: 'alle', key: 'alle', text: <Translate value="alle_berichten"/>},
                                        {value: 'mij', key: 'mij', text: <Translate value="alle_berichten_aan_mij"/>},
                                        {
                                            value: 'mijnothandled',
                                            key: 'mijnothandled',
                                            text: <Translate
                                                value="alle_berichten_aan_mij_die_nog_niet_afgehandeld_zijn"/>
                                        },
                                        {
                                            value: 'vanmij',
                                            key: 'vanmij',
                                            text: <Translate value="alle_berichten_van_mij"/>
                                        },
                                        {
                                            value: 'klantnothandled',
                                            key: 'klantnothandled',
                                            text: <Translate value="alle_Klant_berichten_die_nog_niet_afgehandeld_zijn"/>
                                        },
                                    ]} onChange={(e, {value}) => {
                                        this.setState({filter: value})
                                        this.props.dispatch(getChatOverviewByPage(0, value))
                                    }}/> : <Translate value="alle_berichten"/>}
                                </Table.HeaderCell>
                                {!employee ?<Table.HeaderCell collapsing={true}>OK</Table.HeaderCell> : null}
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>

                            {this.props.page && this.props.fetchingPage ? <Table.Row key={-10}>
                                <div style={{textAlign: 'center', marginTop: 8}}>
                                <Loader active inline/>
                            </div>
                            </Table.Row>: ''}

                            {(this.props.fetching || this.props.error) ? <Table.Row key={-9}>
                                <Table.Cell colSpan={this.props.chatId ? 7 : 6} style={{textAlign: 'center'}}>
                                    {this.props.fetching ? <Loader active inline/> : ''}
                                    {this.props.error ? <Translate value="er_isiets_mis_gegaan"/>: ''}
                                </Table.Cell>
                            </Table.Row> : ''}

                            {list.map(c => {
                                return <Table.Row key={c.id} style={{backgroundColor: c.important ? '#fff6f6' : null, lineHeight: '27.5px'}}>

                                    <Table.Cell collapsing={true} style={cellStyle}>
                                        <ChatUserView user={c.from} type='icon'/>
                                        <div style={{display: 'inline-block'}}>
                                            <ChatUserView user={c.from}/>
                                        </div>
                                    </Table.Cell>

                                    <Table.Cell collapsing={true} style={cellStyle}>
                                        {formatDateTime(c.date)}
                                    </Table.Cell>

                                    {this.props.chatId ? null :
                                        <Table.Cell collapsing={true} style={cellStyle}>
                                            {c.projectOrderId ? <Link to={'/project/' + c.projectOrderId + '/buy'}>
                                                {c.projectOrderInternalName ? c.projectOrderInternalName : 'project'}
                                            </Link> : null}
                                        </Table.Cell>}

                                    <Table.Cell collapsing={true} style={cellStyle}>
                                        <Popup hoverable trigger={
                                            <span style={{textDecoration: 'underline'}}>{c.ref ? 'BOM' : ''}</span>
                                        } content={<div>
                                            {c.refInfo ? <div>
                                                <div>
                                                    Line: {c.refInfo.row}
                                                </div>
                                                <div>
                                                    Designators: {c.refInfo.designators.map(d => d.value).join(', ')}
                                                </div>
                                                <div style={{color: 'red'}}>
                                                    {c.refInfo.removedFromPrint ? 'Stond in een vorige BOM' : ''}
                                                </div>
                                            </div> : <Loader active inline/>}
                                        </div>}
                                               onOpen={() => c.ref && this.props.dispatch(getChatRefInfo(c.ref, c.id))}/>
                                    </Table.Cell>

                                    {employee ? <Table.Cell collapsing={true} style={{...cellStyle, textAlign: 'center'}}>
                                        {(c.to || !isEmployee(c.from) && c.ref === null) ? <Popup trigger={<Checkbox style={{marginTop: 4, marginBottom: -4}} fitted checked={!!c.handled}
                                                                          onChange={(e, {checked}) => {
                                                                              this.props.dispatch(setChatHandled(c.id, !c.handled, () => {
                                                                                  this.props.dispatch(unhandledCount())
                                                                              }))
                                                                          }}/>}
                                                       content={c.handled ? formatDateTime(c.handled) : 'Nog niet afgehandeld'}/> : ''}
                                    </Table.Cell> : null}

                                    <Table.Cell>
                                        <ChatInput value={c} renderFrom={false} mention={this.props.mention}/>
                                        <ChatAttachment attachments={c.attachments} chatline={c}/>
                                    </Table.Cell>
                                    {!employee ? <Table.Cell collapsing={true} style={{...cellStyle, textAlign: 'center'}}>
                                        {c.handled ? <Popup trigger={<Icon name ='checkmark' color={'green'}/>} content={'Actie uitgevoerd'}/> : ''}
                                            </Table.Cell> : null}

                                </Table.Row>
                            })}
                        </Table.Body>
                    </Table>

                    {(this.props.page || this.props.isPageView) && this.props.fetchingPage ? <div style={{textAlign: 'center', marginTop: 8}}>
                        <Loader active inline/>
                    </div> : ''}

                    <div key={-1} ref={(ref) => this.showme = ref}></div>

                    {(this.props.isPageView && this.props.page && this.props.page.content.length > 0 && !this.props.page.last && !this.props.fetchingPage) ?
                        <OnVisible bounce={true} onChange={(e) => {
                            //console.log('fetch next project ' + (this.props.projectPage.number + 1) )
                            //this.fetchProjects(true)
                            this.props.dispatch(getChatOverviewByPage(this.props.page.number + 1, this.state.filter))
                        }}/> : ''}

                </div>

                <div style={{display: 'flex', gap: 8}}>

                    {this.renderImportantHigh(list)}

                    {(this.props.showInput && this.props.chatId) ? <div style={{textAlign: 'right', marginTop: 12}}>
                        <Popup trigger={<Button primary icon='pencil' labelPosition='left' content='Opmerking'
                                                onClick={() => {
                                                    this.props.dispatch(postChatMessage(this.props.chatId, '', null, null, null, () => {
                                                        this.scrollToBottom()
                                                    }))
                                                    this.scrollToBottom()
                                                }}/>} content={'Opmerking toevoegen'}/>
                    </div> : null}

                </div>


            </div>

        </div>
    }


    renderImportantHigh(list) {
        return <>
            {(!this.props.isPageView || this.props.showImportantHighOnly) ? <div style={{flex: 1}}>
                {list.filter(c => c.importantHigh).map(c => <Message error key={c.id}>
                    <Message.Content>
                        {c.text}
                    </Message.Content>
                </Message>)}
            </div> : null}
        </>;
    }

    render() {
        if( this.props.showImportantHighOnly ) {
            return this.renderImportantHigh( this.getList() )
        }
        if( this.props.isPageView ) {
            return <main id={'chat'} className={'pageContainer'}>
                {this.renderView()}
            </main>
        }
        return this.renderView();
    }

}

ChatView.propTypes = {
    chatId: PropTypes.number,
    showInput: PropTypes.bool,
    isPageView: PropTypes.bool,
    reverseOrder: PropTypes.bool,
    scroll: PropTypes.bool,
    showImportantHighOnly: PropTypes.bool,
    mention: PropTypes.object
};

ChatView.defaultProps = {
    chatId: null,
    showInput: false,
    isPageView: true,
    reverseOrder: false,
    scroll: false,
    showImportantHighOnly: false,
    mention: null
};

const mapStateToProps = (state) => {
    return {
        currentUser: state.main.currentUser,
        chatOverview: state.chat.chatOverview,
        chat: state.chat.chat,
        mentionable: state.chat.mentionable,
        fetching: state.chat.fetching,
        fetchingPage: state.chat.fetchingPage,
        page: state.chat.page,
        unhandledCount: state.chat.unhandledCount,
        unhandledCountCustomer: state.chat.unhandledCountCustomer
    }
}

export default connect(mapStateToProps)(ChatView)