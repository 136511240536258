let initialState = {
    chatOverview: [],
    users: {},
    mentionable: [],
    unhandledCount: 0,
    unhandledCountCustomer: 0,
};

const chat = (state = initialState, action) => {
    switch (action.type) {
        case 'RECEIVE_GET_CHAT_UNHANDLED_COUNT':
            return {
                ...state,
                unhandledCount: action.value.unhandled,
                unhandledCountCustomer: action.value.unhandledCustomerMessages,
            }
        case 'RECEIVE_GET_CHAT_USER':
            return {
                ...state,
                users: {
                    ...state.users,
                    [action.value.id]: action.value
                }
            }
        case 'START_GET_CHAT_OVERVIEW':
            return {
                ...state,
                chatOverview: [],
                fetching: true
            }
        case 'RECEIVE_GET_CHAT_OVERVIEW':
            return {
                ...state,
                chatOverview: action.value,
                fetching: false
            }
        case 'START_GET_CHAT_OVERVIEW_BY_PAGE':
            return {...state, fetchingPage: true}
        case 'RECEIVE_GET_CHAT_OVERVIEW_BY_PAGE':
            return {...state, fetchingPage: false,
                page: action.value.number ? Object.assign({}, action.value, {
                    content: [...state.page.content, ...action.value.content]
                }) : action.value,
            }
        case 'ERROR_GET_CHAT_OVERVIEW_BY_PAGE':
            return {...state}
        case 'ERROR_GET_CHAT_OVERVIEW':
            return {...state, fetching: false, error: true}
        case 'WEBS_RECEIVE_CHAT_LINE':
        case 'RECEIVE_POST_CHAT_MESSAGE':
        case 'RECEIVE_UPDATE_CHAT_MESSAGE':
        case 'RECEIVE_SET_CHAT_HANDLED':
            let newChatOverview;
            let newChatLine = null;
            let newPageContent = null;

            if( state.chat && (action.type !== 'WEBS_RECEIVE_CHAT_LINE' || state.chat.id === action.value.chatDetails) ) {
                if( state.chat.chatLine.some(c => c.id === action.value.id) ) {
                    newChatLine = state.chat.chatLine.map(c => c.id === action.value.id ? action.value : c)
                } else {
                    newChatLine = [...state.chat.chatLine, action.value]
                }
            }

            if( state.chatOverview.some(c => c.id === action.value.id) ) {
                newChatOverview = state.chatOverview.map(c => c.id === action.value.id ? action.value : c)
            } else {
                newChatOverview = [...state.chatOverview, action.value]
            }

            if( state.page ) {
                if (state.page.content.some(c => c.id === action.value.id)) {
                    newPageContent = state.page.content.map(c => c.id === action.value.id ? action.value : c)
                } else {
                    newPageContent = [action.value, ...state.page.content]
                }
            }

            return {
                ...state,
                chatOverview: newChatOverview,
                chat: newChatLine ? {
                    ...state.chat,
                    chatLine: newChatLine
                } : state.chat,
                page: state.page ? {
                    ...state.page,
                    content: newPageContent
                } : null
            }
        case 'RECEIVE_GET_CHAT_MENTIONABLE':
            return {
                ...state,
                mentionable: action.value
            }
        case 'OPEN_CHAT_MENTIONABLE':
            return {
                ...state,
                mentionableOpen: action.value
            }
        case 'RECEIVE_GET_CHAT_FOR_PROJECT_ORDER':
            return {
                ...state,
                chat: action.value
            }
        case 'CHANGE_CHAT_VALUE':
            return {
                ...state,
                chat: state.chat ? {
                    ...state.chat,
                    chatLine: state.chat.chatLine.map(c => c.id === action.id ? {
                        ...c, [action.name]: action.value, dirty: true
                    } : c)
                } : state.chat,
                chatOverview: state.chatOverview.map(c => c.id === action.id ? {
                    ...c, [action.name]: action.value, dirty: true
                } : c)
            }
        case 'CHANGE_CHAT_ADD_LIST_VALUE':
            return {
                ...state,
                chat: state.chat ? {
                    ...state.chat,
                    chatLine: state.chat.chatLine.map(c => c.id === action.id ? {
                        ...c, [action.name]: [...(c[action.name]), action.value], dirty: true
                    } : c)
                } : state.chat,
                chatOverview: state.chatOverview.map(c => c.id === action.id ? {
                    ...c, [action.name]: [...(c[action.name]), action.value], dirty: true
                } : c)
            }
        case 'START_UPDATE_CHAT_MESSAGE':
            return {
                ...state,
                chat: state.chat ? {
                    ...state.chat,
                    chatLine: state.chat.chatLine.map(c => c.id === action.start.chatId ? {
                        ...c, busy: true
                    } : c)
                } : state.chat,
                chatOverview: state.chatOverview.map(c => c.id === action.start.chatId ? {
                    ...c, busy: true
                } : c)
            }
        case 'RECEIVE_DELETE_CHAT_MESSAGE':
            return {
                ...state,
                chat: state.chat ? {
                    ...state.chat,
                    chatLine: state.chat.chatLine.filter(c => c.id !== action.start.id)
                } : state.chat,
                chatOverview: state.chatOverview.filter(c => c.id !== action.start.id)
            }
        case 'RECEIVE_GET_CHAT_REF_INFO':
            return {
                ...state,
                chat: state.chat ? {
                    ...state.chat,
                    chatLine: state.chat.chatLine.map(c => c.id === action.start.chatId ? {
                        ...c, refInfo: action.value
                    } : c)
                } : state.chat,
                chatOverview: state.chatOverview.map(c => c.id === action.start.chatId ? {
                    ...c, refInfo: action.value
                } : c),
                page: state.page ? {
                    ...state.page,
                    content: state.page.content.map(c => c.id === action.start.chatId ? {
                        ...c, refInfo: action.value
                    } : c)
                } : null
            }
        case 'OPEN_NEW_CHAT_MODAL':
            return {
                ...state,
                newChatModalOpen: action.value
            }
        default:
            return state
    }
}
export default chat
