import React from 'react';
import {
    getKvkApiStatus,
    updateKvkKey, zoekKvkHandelsnaam, zoekKvkNummer
} from "./actions";
import {Button, Divider, Form, Icon, Modal, Popup} from "semantic-ui-react";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import {formatDateTime} from "./actions/functions";

class KvkApiAuthentication extends React.Component {

    state = {
        key: '',
        kvknumber: '',
        handelsnaam: ''
    }

    componentDidMount() {
        this.props.dispatch(getKvkApiStatus())

    }

    renderSearchResult(list) {
        if (!list) return null

        return <div>
            <h3>Resultaten</h3>
            <table className="ui celled table">
                <thead>
                <tr>
                    <th>kvkNummer</th>
                    <th>vestigingsnummer</th>
                    <th>handelsnaam</th>
                    <th>adresType</th>
                    <th>straatnaam</th>
                    <th>plaats</th>
                    <th>type</th>
                </tr>
                </thead>
                <tbody>
                {list.map(item => <tr key={item.kvkNumber}>
                    <td>{item.kvkNummer}</td>
                    <td>{item.vestigingsnummer}</td>
                    <td>{item.handelsnaam}</td>
                    <td>{item.adresType}</td>
                    <td>{item.straatnaam}</td>
                    <td>{item.plaats}</td>
                    <td>{item.type}</td>
                </tr>)}
                </tbody>
            </table>
        </div>
    }

    render() {

        const loading = this.props.kvkSearching

        return <div>
            KvK API status: {JSON.stringify(this.props.kvkStatus)}

            <Form style={{display: 'flex', gap: 8}}>
                <Form.Field>
                    <Form.Input value={this.state.key} label='API Key' onChange={e => this.setState({key: e.target.value})}   />
                </Form.Field>
                <Form.Button style={{marginTop: 25}} primary onClick={() => this.props.dispatch(updateKvkKey(this.state.key))}>Update key</Form.Button>
            </Form>

            <Divider/>

            Zoeken op KvK nummer of handelsnaam

            <div style={{display: 'flex', gap: 16}}>

                <div>
                    <Form style={{flex: 1, display: 'flex', gap: 8}}>
                        <Form.Field>
                            <Form.Input value={this.state.kvknumber} label='Kvk nummer' onChange={e => this.setState({kvknumber: e.target.value})}   />
                        </Form.Field>
                        <Form.Button
                            style={{marginTop: 25}} loading={loading}
                            primary onClick={() => this.props.dispatch(zoekKvkNummer(this.state.kvknumber))}>Zoek via kvk nummer</Form.Button>
                    </Form>

                </div>

                <div>
                    <Form style={{flex: 1, display: 'flex', gap: 8}}>
                        <Form.Field>
                            <Form.Input value={this.state.handelsnaam} label='Handelsnaam' onChange={e => this.setState({handelsnaam: e.target.value})}   />
                        </Form.Field>
                        <Form.Button
                            style={{marginTop: 25}} loading={loading}
                            primary onClick={() => this.props.dispatch(zoekKvkHandelsnaam(this.state.handelsnaam))}>Zoek via handelsnaam</Form.Button>
                    </Form>
                </div>

            </div>

            {this.renderSearchResult(this.props.kvkSearchResult)}

        </div>
    }
}

KvkApiAuthentication.propTypes = {
    statusOnly: PropTypes.bool
}

const mapStateToProps = (state) => {
    return {
        kvkStatus: state.main.kvkStatus,
        kvkSearchResult: state.main.kvkSearchResult,
        kvkSearching: state.main.kvkSearching,
    }
}

export default connect(mapStateToProps)(KvkApiAuthentication)