import React from 'react'
import PropTypes from 'prop-types'

class Progressbar extends React.Component {
    render() {

        const progress= this.props.progress ? this.props.progress : 0;

        return <div
            style={{
                backgroundColor: this.props.color ? this.props.color : '#00b5ad',
                width: progress+'%',
                height: '2px',
                position: 'relative',
                bottom: '0px',
                left: '0px'
            }}
        >

        </div>
    }
}

Progressbar.propTypes = {
    progress: PropTypes.number,
    color: PropTypes.any
}

Progressbar.defaultProps = {
    progress: 0,
    color: '#00b5ad'
}

export default Progressbar;