import {getDistance, sortBomRow, sortDesignators, getRowsFilterList} from "../bom/functions";

const defaultMohicanSettings = {
    cameraType: 'webcam',
    cameraFlip: true,
    cameraId: 'uXaCTbPC1DuWHUcvBAki6Cb5rc+yOVkvy9EMu4LNIOw=',
    brightness: 1.2,
    crosshair: {
        x: 952, y: 543
    },
    showOverlay: true,
    cameraZoom: 1.2,
    fontSize: 70,
    mohicanPlankjePosition: {x: 7.72, y: 154.395},
    viewWidth: 57,
    speed: 300,
    acceleration: 300,
    current: 500,
    pumpPower: 400,
    color: '#21ba45',
    updateSpeed: 5000,
    lens: '12mm',
    resolution: {width: 1920, height: 1080},
    pan: {
        x: 1, y: -14
    }
}

export const mohicanLensOptions = ['12mm', '25mm']

export const mohicanCameraResolutionOptions = [
    {width: 640, height: 480},
    {width: 1280, height: 720},
    {width: 1280, height: 960},
    {width: 1600, height: 1200},
    {width: 1920, height: 1080},
    {width: 2560, height: 1440},
    {width: 2592, height: 1944},
    {width: 3840, height: 2160},
    {width: 4032, height: 3040}
]

//array with 36 positions
export const plankjeArray = [
    1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36
]

const defaultSelectedColumns = [0,1,30,8,9,17,23,24,25,26,34];

const defaultSelectedViewId = 0;

const initialImport = {importRow: 0}

const initialState= {print: null, errorList: [], bomOrderList: [], bomOrderListBusy: false, componentDataList: [],
    packingTypeList: [], aggregateUpload: false, list: [], openeningBom: false, buddy: [],
    searchRow: null, bomList: [], searchList: [], buddySearchList: [], stockSearchList: [], production: {}, orderparts: [], orderpartsImport: [],
    orderpartsPage: {}, bomProductionList: [], bomProductionSelected: null,
    selectedColumns: defaultSelectedColumns, viewId: defaultSelectedViewId, boardLocations: [], suppliers: [],
    manufacturers: [], bomFilter: '', bomRotation: 0, selectedDesignator: null, bomSort: 'row',
    searchProjectUsedPart: [], bomOrderCheckinList: [], orderpartInfo: [],
    mohicanConnectionModal: false, knownDielectric: [], knownPackages: [], defaultAutoselectSettings: null,
    mohicanPositionList: [], mohicanPositionHistoryList: [], importData: [], importDataSelected: '', loadingImportData: false, import: initialImport,
    searchBomOrderMove: [], bomStockList: [],
    mohicanSettings: {
        ...defaultMohicanSettings,
        ...getSettings()
    }, mohicanEnableFeedbackControl: false, cameraDevices: [], selectedPlankje: 1, centroidList: []
}

//export const MOHICAN_SETTINGS = 'deltaprotomohican'

function getSettings() {
    let d = localStorage.getItem('deltaprotomohican');

    if( d != null && d !== "null" ) {
        try {
            let val = JSON.parse(d);

            // add missing properties
            for (const property in defaultMohicanSettings) {
                const name = property;
                const value = defaultMohicanSettings[property];

                if( val[name] == null ) {
                    val[name] = value;
                }
            }

            return {
                ...val,
                isMoving: false,
                isResizing: false,
                setCrosshair: false
            }
        } catch (e) {
            console.log('FAILED TO PARSE SETTINGS')
        }
    }

    return {}
}

const updateSupplierPart = (current, newList) => {
    if( current ) {
        let res = newList.filter(fsp => fsp.id === current.id);
        return res.length > 0 ? res[0] : current;
    }
    return current;
}

const updateSupplierPartSetIsLoading = (current, newList, loading, failed) => {
    if( current ) {
        let res = newList.filter(id => id === current.id);
        return res.length > 0 ? {...current, loading, failed} : current;
    }
    return current;
}

const bomStockItem = (state, bomStock, action) => {
    switch (action.type) {
        case 'ADD_BOM_STOCK_ITEM':
            return {
                ...bomStock,
                items: [...state.bomStock.items, action.item]
            }
        case 'START_SAVE_BOM_STOCK_ITEM':
            return {
                ...bomStock,
                items: bomStock.items.map(bs => {
                    if( bs.id === action.start.id ) {
                        return {...bs, busy: true}
                    }
                    return bs;
                })
            }
        case 'RECEIVE_SAVE_BOM_STOCK_ITEM':
            if( bomStock.items.some(bs => bs.id === action.value.id) ) {
                return {
                    ...bomStock,
                    items: bomStock.items.map(bs => {
                        if( bs.id === action.value.id ) {
                            return action.value
                        }
                        return bs;
                    })
                }
            } else {
                return {
                    ...bomStock,
                    items: [...bomStock.items, action.value]
                }
            }
        case 'CHANGE_BOM_STOCK_ITEM_VALUE':
            return {
                ...bomStock,
                items: bomStock.items.map(i => {
                    if( i.id === action.id ) {
                        return {
                            ...i,
                            [action.name]: action.value,
                            dirty: true
                        }
                    }
                    return i
                })
            }
        case 'RECEIVE_SAVE_BOM_STOCK_ITEM_TRANSACTION':
            return {
                ...bomStock,
                items: bomStock.items.map(i => {
                    if( i.id === action.value.id ) {
                        return action.value
                    }
                    return i
                })
            }
        default:
            return bomStock;
    }
}

const bom = (state = initialState, action) => {
    switch( action.type ) {
        case 'TOGGLE_AGGREGATE_UPLOAD':
            return {
                ...state, aggregateUpload: !state.aggregateUpload
            }
        case 'START_GET_PROJECT':
            return {
                ...initialState
            }
        case 'WEBS_START_UPDATE_SUPPLIER_PART':
        case 'WEBS_ERROR_UPDATE_SUPPLIER_PART':
        case 'START_BOM_UPDATE_STOCK':
        case 'ERROR_BOM_UPDATE_STOCK':
            let loading= action.type === 'START_BOM_UPDATE_STOCK' || action.type === 'WEBS_START_UPDATE_SUPPLIER_PART';
            let failed= action.type === 'ERROR_BOM_UPDATE_STOCK' || action.type === 'WEBS_ERROR_UPDATE_SUPPLIER_PART';
            return {...state, searchList: state.searchList.map(op => {
                    return {...op, suppliers: op.suppliers.map(sp => {
                            let res= action.start.ids.filter(id => id === sp.id);
                            return res.length > 0 ? {...sp, loading, failed} : sp;
                        })}
                }), print: state.print ? {...state.print, bom: state.print.bom.map(l => ({...l,
                        orderparts: l.orderparts.map(op => ({...op, suppliers: op.suppliers.map(sp => {
                                let res= action.start.ids.filter(id => id === sp.id);
                                return res.length > 0 ? {...sp, loading, failed} : sp;
                            })})),
                        selectedSupplierPart: updateSupplierPartSetIsLoading(l.selectedSupplierPart, action.start.ids, loading, failed)
                    }))} : state.print
            };
        case 'WEBS_RECEIVE_UPDATE_SUPPLIER_PART':
        case 'RECEIVE_BOM_UPDATE_STOCK':
            return {...state, searchList: state.searchList.map(op => {
                return {...op, suppliers: op.suppliers.map(sp => {
                    let res= action.value.filter(fsp => fsp.id === sp.id);
                    return res.length > 0 ? res[0] : sp;
                })}
            }), print: state.print ? {...state.print, bom: state.print.bom.map(l => ({...l,
                orderparts: l.orderparts.map(op => ({...op, suppliers: op.suppliers.map(sp => {
                    let res= action.value.filter(fsp => fsp.id === sp.id);
                    return res.length > 0 ? res[0] : sp;
                })})),
                selectedSupplierPart: updateSupplierPart(l.selectedSupplierPart, action.value)
            }))} : state.print
            };
        case 'START_BOM_SEARCH_PART':
        case 'START_BOM_SEARCH_BY_MPN':
            return {...state, searchList: [], buddySearchList: [], stockSearchList: [], searching: true, searchError: false}
        case 'RECEIVE_BOM_SEARCH_PART':
        case 'RECEIVE_BOM_SEARCH_BY_MPN':
            return {...state, searchList: action.value.search, buddySearchList: action.value.buddy, stockSearchList: action.value.stock, searching: false, searchError: false}
        case 'ERROR_BOM_SEARCH_PART':
        case 'ERROR_BOM_BOM_SEARCH_BY_MPN':
            return {...state, searchList: [], buddySearchList: [], stockSearchList: [], searching: false, searchError: true}
        case 'START_BOM_LIST':
            return {...state, fetchingList: true}
        case 'RECEIVE_BOM_LIST':
            return {...state, list: action.value, fetchingList: false}
        case 'ERROR_BOM_LIST':
            return {...state, fetchingList: false}
        case 'RECEIVE_DELETE_BOM':
            return {...state, list: action.value, print: null, bomOrderList: []}
        case 'BOM_EDIT_OPEN':
            return {...state, showEditBom: action.value, print: {
                ...state.print,
                    newName: state.print.name,
                    newAmount: state.print.amount
                }}
        case 'BOM_CHANGE_PRINT_VALUE':
            return {...state, print: {
                    ...state.print,
                    [action.name]: action.value
                }}
        case 'RECEIVE_SAVE_BOM_PRINT':
            return {...state, print: action.value, showEditBom: false}
        case 'BOM_ORDER_OPEN':
            return {
                ...state,
                showBomOrder: action.value,
                creatingBomOrders: false,
                showBomOrderMove: false
            }
        case 'BOM_ORDER_MOVE_OPEN':
            return {
                ...state,
                showBomOrderMove: action.value,
                selectedBomOrderMove: null
            }
        case 'START_BOM_ORDER_SEARCH':
            return {
                ...state, searchBomOrderMoveBusy: true, searchBomOrderMoveId: action.start.id
            }
        case 'RECEIVE_BOM_ORDER_SEARCH':
            if( state.searchBomOrderMoveId === action.start.id  ) {
                return {
                    ...state,
                    searchBomOrderMove: action.value,
                    searchBomOrderMoveBusy: false
                }
            }
            return state
        case 'SELECT_BOM_ORDER_TO_MOVE':
            return {
                ...state,
                selectedBomOrderMove: action.value
            }
        case 'START_BOM_ORDER_MOVE':
            return {
                ...state,
                bomOrderListBusy: true
            }
        case 'RECEIVE_BOM_ORDER_MOVE':
            return {
                ...state,
                bomOrderList: [...state.bomOrderList, ...action.value],
                bomOrderListBusy: false,
                showBomOrderMove: false,
                selectedBomOrderMove: null
            }
        case 'BOM_CHECKIN_ORDERS_OPEN':
            return {
                ...state,
                showCheckinOrders: action.value
            }
        case 'START_CREATE_BOM_ORDERS':
            return {
                ...state,
                creatingBomOrders: true
            }
        case 'RECEIVE_CREATE_BOM_ORDERS':
            return {
                ...state,
                creatingBomOrders: false,
                bomOrderList: [...state.bomOrderList, ...action.value.key]
            }
        case 'ERROR_CREATE_BOM_ORDERS':
            return Object.assign({}, state, {
                creatingBomOrders: false
            })
        case 'START_REMOVE_BOM_ORDER':
            return Object.assign({}, state, {
                removingBomOrders: action.start.id,
            })
        case 'RECEIVE_REMOVE_BOM_ORDER':
            return Object.assign({}, state, {
                removingBomOrders: null,
                bomOrderList: action.value.key
            })
        case 'ERROR_REMOVE_BOM_ORDER':
            return Object.assign({}, state, {
                removingBomOrders: null,
            })
        case 'SET_BOM_ORDER_VALUE':
            return Object.assign({}, state, {
                bomOrderList: state.bomOrderList.map(bo => {
                    if( bo.id === action.id ) {
                        return {...bo, [action.name]: action.value, dirty: true}
                    }
                    return bo;
                })
            })
        case 'RECEIVE_ADD_BOM_ORDER':
            return Object.assign({}, state, {
                bomOrderList: [...state.bomOrderList, action.value]
            })
        case 'WEBS_BOM_ORDER_ITEM':
            return Object.assign({}, state, {
                bomOrderList: state.bomOrderList.map(bo => {
                    return {...bo, items: bo.items.map(boi => {

                        if( boi.id === action.value.id ) {
                            return action.value
                        }

                        return boi;
                    })}
                })
            })
        case 'START_GET_BOM_ORDERS':
        case 'START_GET_ALL_BOM_ORDERS':
            return Object.assign({}, state, {
                bomOrderListBusy: true
            })
        case 'RECEIVE_GET_BOM_ORDERS':
        case 'RECEIVE_GET_ALL_BOM_ORDERS':
            return Object.assign({}, state, {
                bomOrderList: action.value.key.sort((a,b) => {
                    return a.orderPlaced-b.orderPlaced
                }),
                bomOrderListBusy: false
            })
        case 'START_GET_BOM_STOCK':
        case 'START_GET_MY_BOM_STOCK':
            return {
                ...state,
                bomStockList: [],
                fetchingBomStock: true
            }
        case 'RECEIVE_GET_BOM_STOCK':
            return {
                ...state,
                bomStockList: [action.value],
                fetchingBomStock: false
            }
        case 'RECEIVE_GET_MY_BOM_STOCK':
            return {
                ...state,
                bomStockList: action.value,
                fetchingBomStock: false
            }
        case 'ADD_BOM_STOCK_ITEM':
        case 'CHANGE_BOM_STOCK_ITEM_VALUE':
            return {
                ...state,
                bomStockList: state.bomStockList.map(bst => {
                    if( bst.id === action.bstId ) {
                        return bomStockItem(state, bst, action)
                    }
                    return bst;
                })
            }
        case 'START_SAVE_BOM_STOCK_ITEM':
        case 'RECEIVE_SAVE_BOM_STOCK_ITEM':
        case 'RECEIVE_SAVE_BOM_STOCK_ITEM_TRANSACTION':
            return {
                ...state,
                bomStockList: state.bomStockList.map(bst => {
                    if( bst.id === action.start.bstId ) {
                        return bomStockItem(state, bst, action)
                    }
                    return bst;
                })
            }
        case 'START_GET_ALL_BOM_ORDERS_NEED_CHECKIN':
            return {...state, bomOrderCheckinListBusy: true}
        case 'RECEIVE_GET_ALL_BOM_ORDERS_NEED_CHECKIN':
            return {...state, bomOrderCheckinList: action.value.key, bomOrderCheckinListBusy: false}
        case 'ERROR_GET_ALL_BOM_ORDERS_NEED_CHECKIN':
            return {...state, bomOrderCheckinListBusy: false}
        case 'ERROR_GET_BOM_ORDERS':
        case 'ERROR_GET_ALL_BOM_ORDERS':
            return Object.assign({}, state, {
                bomOrderListBusy: false
            })
        case 'START_SAVE_BOM_ORDER':
            return Object.assign({}, state, {
                bomOrderListBusy: true
            })
        case 'RECEIVE_SAVE_BOM_ORDER':
            return Object.assign({}, state, {
                bomOrderListBusy: false,
                bomOrderList: state.bomOrderList.map(o => o.id === action.value.id ? action.value : o)
            })
        case 'ERROR_SAVE_BOM_ORDER':
            return Object.assign({}, state, {
                bomOrderListBusy: false
            })
        case 'START_SAVE_BOM_ORDER_ITEM':
            return {
                ...state,
                bomOrderList: state.bomOrderList.map(o => {
                    return {
                        ...o,
                        items: o.items.map(i => i.id === action.start.id ? {...i, busy: true} : i)
                    }
                })
            }
        case 'RECEIVE_SAVE_BOM_ORDER_ITEM':
        case 'RECEIVE_EXPIRE_BOM_ORDER_ITEM':
            return {
                ...state,
                bomOrderList: state.bomOrderList.map(o => {
                    return {
                        ...o,
                        items: o.items.map(i => i.id === action.value.id ? action.value : i)
                    }
                })
            }
        case 'ERROR_SAVE_BOM_ORDER_ITEM':
            return {
                ...state,
                bomOrderList: state.bomOrderList.map(o => {
                    return {
                        ...o,
                        items: o.items.map(i => i.id === action.start.id ? {...i, busy: false} : i)
                    }
                })
            }

        case 'WEBS_START_CHECK_BOM_LINE':

            return Object.assign({}, state, {
                print: Object.assign({}, state.print, {
                    bom: state.print.bom.map(l => {
                        if( l.id === action.value ) {
                            return Object.assign({}, l, {
                                checking: true
                            })
                        } else {
                            return l;
                        }
                    })
                })
            })

        case 'START_AUTO_SELECT_BOM_LINE':
            return Object.assign({}, state, {
                print: Object.assign({}, state.print, {
                    bom: state.print.bom.map(l => {
                        if( l.id === action.start.id ) {
                            return Object.assign({}, l, {
                                busy: true
                            })
                        } else {
                            return l;
                        }
                    })
                })
            })

        case 'WEBS_START_SELECT_BOM_LINE':

            return Object.assign({}, state, {
                print: Object.assign({}, state.print, {
                    bom: state.print.bom.map(l => {
                            if( l.id === action.value ) {
                                return Object.assign({}, l, {
                                    busy: true
                                })
                            } else {
                                return l;
                            }
                        })
                })
            })

        case 'WEBS_RECEIVE_SELECT_BOM_LINE_NOT_FOUND':
            return Object.assign({}, state, {
                print: Object.assign({}, state.print, {
                    bom: state.print.bom.map(l => {
                        if( l.id === action.value ) {
                            return Object.assign({}, l, {
                                busy: false,
                                notFound: true
                            })
                        } else {
                            return l;
                        }
                    })
                })
            })

        /*case 'RECEIVE_CHECK_BOM_LINE':*/
        case 'WEBS_RECEIVE_SELECT_BOM_LINE':
        case 'WEBS_RECEIVE_CHECK_BOM_LINE':
        case 'RECEIVE_AUTO_SELECT_BOM_LINE':
        case 'RECEIVE_SET_VALUE_FOR_BOM_LINE':
        case 'RECEIVE_SET_BOM_LINE_REMARK':
        case 'RECEIVE_SET_BOM_LINE_MARKED':
        case 'RECEIVE_BOM_SET_DO_NOT_PLACE':

            if( action.value.key ) {
                return Object.assign({}, state, {
                    print: Object.assign({}, state.print, {
                        bom: state.print.bom.map(l => {
                            if (l.id === action.value.key.id) {
                                return action.value.key
                            } else {
                                return l;
                            }
                        })
                    })
                })
            }
            return state;

        case 'RECEIVE_SELECT_BOM_LINE_KLA':
            if( action.value.key ) {
                return Object.assign({}, state, {
                    searchRow: null,
                    print: Object.assign({}, state.print, {
                        bom: state.print.bom.map(l => {
                            if (l.id === action.value.key.id) {
                                return action.value.key
                            } else {
                                return l;
                            }
                        })
                    })
                })
            }
            return state;
        case 'RECEIVE_AUTO_SELECT_BOM':
        case 'RECEIVE_CHECK_BOM':
            return {...state, print: {...state.print, bom: action.value.key}, validatingBom: false}
        case 'ERROR_CHECK_BOM':
            return {...state, validatingBom: false}
        case 'START_GET_BOM_LINE_HISTORY':
            return {
                ...state,
                print: Object.assign({}, state.print, {
                    bom: state.print.bom.map(l => {
                        if (l.id === action.start.bomLineId) {
                            return {...l, fetchingHistory: true}
                        } else {
                            return l;
                        }
                    })
                })
            }
        case 'RECEIVE_GET_BOM_LINE_HISTORY':
            return Object.assign({}, state, {
                print: Object.assign({}, state.print, {
                    bom: state.print.bom.map(l => {
                        if (l.id === action.start.bomLineId) {
                            return {...l, ...action.value, fetchingHistory: false, showHistory: true}
                        } else {
                            return l;
                        }
                    })
                })
            })
        case 'SHOW_BOM_LINE_HISTORY':
            return {
                ...state,
                print: Object.assign({}, state.print, {
                    bom: state.print.bom.map(l => {
                        if (l.id === action.bomLineId) {
                            return {...l, showHistory: action.show}
                        } else {
                            return l;
                        }
                    })
                })
            }
        case 'RECEIVE_RESELECT_BOM_LINE_HISTORY':
        case 'RECEIVE_UNSET_BOM_LINE_PART':
        case 'RECEIVE_SET_BOM_LINE_PART':
        case 'RECEIVE_SET_BOM_LINE_BUDDY':
        case 'RECEIVE_SET_BOM_LINE_EXTRA_AMOUNT':

            if( action.value.id ) {
                return Object.assign({}, state, {
                    searchRow: null,
                    print: Object.assign({}, state.print, {
                        bom: state.print.bom.map(l => {
                            if (l.id === action.value.id) {
                                return action.value
                            } else {
                                return l;
                            }
                        })
                    })
                })
            }
            return state;


        case 'MOUSE_OVER_SUPPLIER_PART':

            return Object.assign({}, state, {
                print: Object.assign({}, state.print, {
                    bom: state.print.bom.map(l => {
                        if( l.id === action.bomLineId ) {
                            return Object.assign({}, l, {
                                orderparts: l.orderparts.map((op) => Object.assign({}, op, {
                                    suppliers: op.suppliers.map((s) => {
                                        if( s.id === action.supplierPartId ) {
                                            return Object.assign({}, s, {
                                                mouseOver: action.mouseOver
                                            })
                                        }
                                        return s;
                                    })
                                }))
                            })
                        }
                        return l;
                    })
                })
            })

        case 'ERROR_CHECK_BOM_LINE':

            return Object.assign({}, state, {
                print: Object.assign({}, state.print, {
                    bom: state.print.bom.map(l => {
                        if( l.id === action.id ) {
                            return Object.assign({}, l, {
                                busy: false
                            })
                        } else {
                            return l;
                        }
                    })
                })
            })

        case 'CLEAR_BOM':
            return Object.assign({}, state, {
                ...initialState,
                errorList: [],
                openeningBom: false
            })
        case 'START_OPEN_BOM':
            return Object.assign({}, state, {
                ...initialState,
                errorList: [],
                openeningBom: true
            })
        case 'START_UPLOAD_CENTROID':
            return Object.assign({}, state, {
                errorList: [],
                openeningBom: true
            })
        case 'BOM_UPLOAD_OPEN':
            return {...state, dialogUploadBom: action.open}
        case 'RECEIVE_OPEN_BOM':
            return Object.assign({}, state, {
                dialogUploadBom: false,
                print: action.value.key,
                errorList: action.value.value,
                importErrorDialogOpen: action.value.value && action.value.value.length,
                dialogStartValidate: false, /*!(action.value.value && action.value.value.length)*/
                openeningBom: false,
                //viewId: action.start ? action.start.viewId : state.viewId
            })
        case 'BOM_CLOSE_IMPORT_ERROR':
            return {...state, importErrorDialogOpen: false, dialogStartValidate: !true, openeningBom: false} //todo: implement socket on server
        case 'BOM_SHOW_IMPORT_ERROR':
            return {
                ...state,
                importErrorDialogOpen: true,
                errorList: action.errorList
            }
        case 'START_CHECK_BOM':
            return Object.assign({}, state, {
                validatingBom: true
            })
        case 'BOM_DIALOG_START_VALIDATE':
            return Object.assign({}, state, {
                dialogStartValidate: action.value
            })
        case 'BOM_SET_SEARCH_QUERY':
            return {...state, searchQuery: action.query}
        case 'BOM_SEARCH':
            return Object.assign({}, state, {
                searchRow: state.print.bom.filter((r) => r.id === action.id)[0],
                searchQuery: action.query,
                searchList: [],
                buddySearchList: [],
                stockSearchList: [],
                searchError: false
            })
        case 'BOM_SEARCH_CLOSE':
            return Object.assign({}, state, {
                searchRow: null,
                searchError: false
            })
        case 'START_GET_COMPONENT_DATA':
            return Object.assign({}, state, {
                componentDataList: [],
                errorAddComponent: null
            })
        case 'RECEIVE_GET_COMPONENT_DATA':
            return Object.assign({}, state, {
                componentDataList: action.value
            })
        case 'RECEIVE_SAVE_COMPONENT_DATA':
            if (state.componentDataList.some(c => c.id === action.value.id)) {
                return Object.assign({}, state, {
                    componentDataList: state.componentDataList.map(c => c.id === action.value.id ? action.value : c)
                })
            }
            else {
                return Object.assign({}, state, {
                    componentDataList: [...state.componentDataList, action.value]
                })
            }
        case 'RECEIVE_PARSE_COMPONENT_MPN':
            return Object.assign({}, state, {
                componentDataList: state.componentDataList.map(c => c.id === action.start.id ? Object.assign({}, c, {specification: action.value}) : c)
            })
        case 'CHANGE_PACKING_TYPES':
            return Object.assign({}, state, {
                packingTypeList: state.packingTypeList.map(p => p.id === action.id ? Object.assign({}, p, {
                    [action.name]: action.value,
                    dirty: true
                }) : p)
            })
        case 'RECEIVE_GET_ALL_PACKING_TYPES':
            return Object.assign({}, state, {
                packingTypeList: action.value
            })
        case 'RECEIVE_SAVE_PACKING_TYPES':
            if (state.packingTypeList.some(c => c.id === action.value.id)) {
                return Object.assign({}, state, {
                    packingTypeList: state.packingTypeList.map(c => c.id === action.value.id ? action.value : c)
                })
            } else {
                return Object.assign({}, state, {
                    packingTypeList: [...state.packingTypeList, action.value]
                })
            }
        case 'START_ADD_COMPONENT_DATA':
            return Object.assign({}, state, {
                busyAddComponent: true,
                errorAddComponent: null
            })
        case 'RECEIVE_ADD_COMPONENT_DATA':
            return Object.assign({}, state, {
                busyAddComponent: false,
                componentDataList: [action.value, ...state.componentDataList]
            })
        case 'ERROR_ADD_COMPONENT_DATA':
            return Object.assign({}, state, {
                busyAddComponent: false,
                errorAddComponent: 'Er is iets mis gegaan'
            })
        case 'RECEIVE_GET_ORDERPART_LIST':
            return {...state, orderPartList: action.value}
        case 'BOM_OPEN_CAMERA':
            return {...state, cameraOpen: action.value}
        case 'RECEIVE_BOM_CHECKIN':

            if( state.bomSearchRef ) {
                //state.bomSearchRef.focus()
            }

            return {...state,
                    bomOrderList: state.bomOrderList.some(bo => bo.id === action.value.bomOrderItem.bomOrder.id) ? state.bomOrderList.map(bo => ({
                        ...bo,
                        items: bo.items.some(li => li.id === action.value.bomOrderItem.id) ? bo.items.map(li => {
                            if( li.id === action.value.bomOrderItem.id ) {
                                return action.value.bomOrderItem
                            }

                            let m_boi = null;

                            if( action.value.bomOrderItems ) {
                                action.value.bomOrderItems.forEach(boi => {
                                    if (li.id === boi.id) {
                                        m_boi = boi
                                    }
                                })
                            }

                            if( m_boi ) return m_boi
                            return li
                        }) : (action.value.bomOrderItem.bomOrder.id === bo.id) ? [
                            ...bo.items, action.value.bomOrderItem
                        ] : bo.items
                    })) : [
                        ...state.bomOrderList, {
                            ...action.value.bomOrderItem.bomOrder,
                            items: [
                                action.value.bomOrderItem
                            ]
                        }
                    ],
                bomSearch: ''
            }
        case 'ERROR_BOM_CHECKIN':
            return {...state}
        case 'BOM_CHECKIN_FOCUS':
            return {...state, checkingFocus: action.name}
        case 'BOM_PRODUCTION_SELECT_DESIGNATOR':
            return {...state, selectedDesignator: action.value, nextDesignator: action.next, nextNextDesignator: action.nextNext}
        case 'BOM_PRODUCTION_SELECT_DESIGNATOR_LAST_GO_TO':
            return {...state, lastGoToDesignator: action.value}
        case 'RECEIVE_GET_BOM_PRODUCTION':
        case 'RECEIVE_DELETE_ALL_BOM_PRODUCTION_LINES':
            return {...state, bomProductionList: action.value, bomProductionSelected: (!state.bomProductionSelected && action.value.length === 1) ? action.value[0].id : state.bomProductionSelected}
        case 'RECEIVE_ADD_BOM_PRODUCTION':
            return {...state, bomProductionList: [...state.bomProductionList, action.value], bomProductionSelected: action.value.id}
        case 'RECEIVE_ADD_BOM_PRODUCTION_LINE':
            return {...state, bomProductionList: state.bomProductionList.map(bp => {
                if( bp.id === action.start.productionId ) {
                    return {...bp, lines: [...bp.lines, action.value]}
                }
                return bp
            })}
        case 'BOM_PRODUCTION_SELECT':
            return {...state, bomProductionSelected: action.id}
        case 'BOM_PRODUCTION_SET_PLANKJE':
            return {...state, selectedPlankje: action.value}
        case 'BOM_EDIT_ROW':
            return {...state, editRow: action.id}
        case 'BOM_ROW_SELECT':
            return {...state, selectedRow: {id: action.id, fade: !!action.fade}}
        case 'RECEIVE_FETCH_BUDDY_PARTS':
            return {...state, buddy: action.value}
        case 'RECEIVE_SAVE_PART_BUDDY_PART':
            return {...state, buddy: [...state.buddy, action.value]}
        case 'CHANGE_BUDDY_PART':
            return {...state, buddy: state.buddy.map(b => {
                if( b.id === action.id ) {
                    return Object.assign({}, b, {[action.name]: action.value, dirty: true})
                }
                return b;
            })}
        case 'ADD_BUDDY_PART':
            return {...state, buddy: [...state.buddy, {new: true, dirty: true}]}
        case 'RECEIVE_SAVE_BUDDY_PART':
            return {...state, buddy: state.buddy.map(b => {
                    if( b.id === action.value.id ) {
                        return action.value
                    }
                    return b;
                })}
        case 'START_BOM_ORDERPART_LIST':
            return {...state, orderpartsPage: {...state.orderpartsPage, fetching: true}}
        case 'RECEIVE_BOM_ORDERPART_LIST':
            return {...state,
                orderparts: action.value.page === 0 ? action.value.content : [...state.orderparts, ...action.value.content],
                orderpartsPage: {...action.value, content: null, fetching: false}
            }
        case 'REVERT_ORDERPART_VALUE':
            return {...state, orderparts: state.orderparts.map(o => o.id === action.id ? o.original : o)}
        case 'RECEIVE_UPDATE_ORDERPART':
        case 'RECEIVE_SAVE_ORDERPART':
        case 'RECEIVE_UPDATE_ORDER_PART':
            return {...state,
                orderPart: (state.orderPart && state.orderPart.id === action.value.id) ? action.value : state.orderPart,
                orderparts: state.orderparts.map(o => o.id === action.value.id ? action.value : o),
                orderpartsImport: state.orderpartsImport.map(o => o.id === action.value.id ? action.value : o),
                buddy: state.buddy.map(b => {
                    if( b.orderpart && b.orderpart.id === action.value.id ) {
                        return {...b, orderpart: action.value}
                    }
                    return b;
                }),
            }
        case 'CLEAR_UPDATE_ORDERPART':
            return {...state, orderpartsImport: []}
        case 'CHANGE_ORDERPART_VALUE':
            return {...state, orderparts: state.orderparts.map(o => (
                    o.id === action.id ?
                        {...o, [action.name]: action.value,
                            original: o.dirty ? o.original : JSON.parse(JSON.stringify(o)),
                            dirty: true
                        } : o
                ))}
        case 'CHANGE_IMPORTPART_VALUE':
            return {...state, orderpartsImport: state.orderpartsImport.map(o => (
                    o.id === action.id ?
                        {...o, [action.name]: action.value,
                            original: o.dirty ? o.original : JSON.parse(JSON.stringify(o)),
                            dirty: true
                        } : o
                ))}
        case 'REVERT_IMPORTPART_VALUE':
            return {...state, orderpartsImport: state.orderpartsImport.map(o => o.id === action.id ? o.original : o)}
        case 'START_BOM_SEARCH_TEST_SELECT':
        case 'START_BOM_SEARCH_TEST':
            return {...state, bomSearchTest: [], matching: true, matchError: false, bomSearchTestSelected: null}
        case 'RECEIVE_BOM_SEARCH_TEST':
            return {...state, bomSearchTest: action.value.list, bomSearchTestSelected: action.value.selected}
        case 'RECEIVE_BOM_SEARCH_TEST_SELECT':
            return {...state, bomSearchTest: action.value.list, bomSearchTestSelected: action.value.selected, matching: false}
        case 'ERROR_BOM_SEARCH_TEST_SELECT':
            return {...state, matching: false, matchError: true}
        case 'START_BOM_IMPORT_FROM_BOM_ID':
        case 'START_BOM_IMPORT_FROM_MPN':
            return {...state, importing: true, importError: false}
        case 'RECEIVE_BOM_IMPORT_FROM_BOM_ID':
        case 'RECEIVE_BOM_IMPORT_FROM_MPN':
            return {...state, orderpartsImport: action.value, importing: false}
        case 'ERROR_BOM_IMPORT_FROM_BOM_ID':
        case 'ERROR_BOM_IMPORT_FROM_MPN':
            return {...state, orderpartsImport: action.value, importing: false, importError: true}
        case 'RECEIVE_BOM_IMPORT_STATUS':
            return {...state, importStatus: action.value.status}
        case 'SET_MOHICAN_CLIENT':
            return {...state, mohicanClient: action.client}
        case 'SHOW_STOMP_NOTIFICATION':
            return {...state, mohicanClientNotify: true}
        case 'HIDE_STOMP_NOTIFICATION':
            return {...state, mohicanClientNotify: false}
        case 'MOHICAN_CLIENT_VERSION':
            return {...state, mohicanVersion: action.value}
        case 'MOHICAN_POSITION':
        case 'RECEIVE_MOHICAN_POS':

            const newList1 = [...state.mohicanPositionHistoryList, action.value].slice(-20);

            let slice = -7;
            if( state.mohicanPositionList.length > 0 ) {
                const d = getDistance(state.mohicanPositionList[state.mohicanPositionList.length - 1], action.value);
                if( d < 0.2 ) {
                    slice = -7;
                } else {
                    slice = -1;
                }
            }

            return {
                ...state,
                mohicanPosition: action.value,
                mohicanPositionList: [...state.mohicanPositionList, action.value].slice(slice),
                mohicanPositionHistoryList: newList1
            }
        case 'MOHICAN_ENABLE_FEEDBACK_CONTROL':
            return {...state, mohicanEnableFeedbackControl: action.value};
        case 'SET_PCB_POSITION':
            return {...state, mohicanPcbPosition: action.value};
        case 'MOHICAN_PCB_POSITION':
            if( state.selectedDesignator ) {

                let bottom = state.selectedDesignator.topBottom === "BOTTOM";

                let newPos = {
                    x: bottom ? action.value.x + state.selectedDesignator.x : action.value.x - state.selectedDesignator.x,
                    y: action.value.y - state.selectedDesignator.y
                }

                localStorage.setItem('deltaprotopcb', JSON.stringify(newPos));
                return {...state, mohicanPcbPosition: newPos};
            } else {
                localStorage.setItem('deltaprotopcb', JSON.stringify(action.value));
                return {...state, mohicanPcbPosition: action.value};
            }
        case 'SET_PLANKJE_POSITION':
            return {...state, mohicanPlankjePosition: action.value};
        case 'MOHICAN_PLANKJE_POSITION':
            localStorage.setItem('deltaprotoplankje', JSON.stringify(action.value));
            return {...state, mohicanPlankjePosition: action.value};
        case 'BOM_RESET_MOHICAN_SETTING':
            localStorage.setItem('deltaprotomohican', JSON.stringify({...defaultMohicanSettings}));
            return {...state, mohicanSettings: {...defaultMohicanSettings}}
        case 'BOM_UPDATE_MOHICAN_SETTING':
            localStorage.setItem('deltaprotomohican', JSON.stringify({...state.mohicanSettings, [action.name]: action.value}));
            return {...state, mohicanSettings: {...state.mohicanSettings, [action.name]: action.value}}
        case 'BOM_UPDATE_MOHICAN_SETTINGS':
            localStorage.setItem('deltaprotomohican', JSON.stringify(action.value));
            return {...state, mohicanSettings: action.value}
        case 'SET_CAMERA_DEVICES':
            return {...state, cameraDevices: action.value}
        case 'SET_BOM_FILTER':
            if( state.bomFilter === action.filter ) {
                return {...state, bomFilter: '', bomFilterList: []}
            } else {

                let list = [];
                if( action.filter ) {
                    list = getRowsFilterList(state.print, action.filter, state.bomSearch, state.bomOrderList)
                }

                return {...state, bomFilter: action.filter, bomFilterList: list}
            }
        case 'SET_BOM_SEARCH':
            let newList = getRowsFilterList(state.print, state.bomFilter, action.value, state.bomOrderList)
            return {...state, bomSearch: action.value, bomFilterList: newList}
        case 'SET_BOM_SORT':

            let newValue = action.value !== state.bomSort ? action.value : 'row';
            return {...state,
                bomSort: newValue,
                print: {
                    ...state.print,
                    bom: [...state.print.bom.map(r => ({...r, designators: r.designators.sort((a,b) => sortDesignators(a,b,newValue))}))
                        .sort((a,b) => sortBomRow(a,b,newValue,state.bomOrderList))]
                }
            }
        case 'SET_BOM_SEARCH_REF':
            return {...state, bomSearchRef: action.value}
        case 'SET_BOM_ROTATION':
            return {...state, bomRotation: action.value}
        case 'RECEIVE_BOM_SET_DESIGNATOR_REMOVED':
            return {...state, print: {...state.print, bom: state.print.bom.map(b => ({
                ...b, designators: b.designators.map(d => {
                    if( d.id === action.value.id ) {
                        return action.value
                    }
                    return d;
                })
            }))}}
        case 'BOM_SET_VIEW':
            return {...state,
                selectedColumns: action.selectedColumns,
                viewId: action.viewId
            }
        case 'RECEIVE_GET_BOM_BOARD_LOCATIONS':
            return {...state,
                boardLocations: action.value
            }
        case 'RECEIVE_UPDATE_BOM_BOARD_LOCATION':
        case 'WEBS_UPDATE_LAST_USED_BOARD_LOCATION':
            if (state.boardLocations.some(c => c.id === action.value.id)) {
                return {
                    ...state,
                    boardLocations: state.boardLocations.map(bl => {
                        if (bl.id === action.value.id) {
                            return action.value
                        }
                        return bl
                    })
                }
            } else {
                return {
                    ...state,
                    boardLocations: [...state.boardLocations, action.value]
                }
            }
        /*case 'WEBS_UPDATE_LAST_USED_BOARD_LOCATION':
            return {...state,
                boardLocations: state.boardLocations.map(bl => {
                    if(bl.id === action.value.id) {
                        return action.value
                    }
                    return bl
                })
            }*/
        case 'RECEIVE_BOM_SUPPLIERS':
            return {...state, suppliers: action.value}
        case 'RECEIVE_BOM_MANUFACTURERS':
            return {...state, manufacturers: action.value}
        case 'OPEN_ORDERPART_ADD':
            return {...state, orderPartAdd: action.value, orderPartAddOpen: action.open}
        case 'SET_ORDERPART_ADD':
            return {...state, orderPartAdd: action.value}
        case 'RECEIVE_BOM_ADD_SUPPLIER':
            return {...state,
                orderPartAdd: state.orderPartAdd.id === action.value.id ? action.value : state.orderPartAdd
            }
        case 'RECEIVE_BOM_ADD_MPN':
            return {...state,
                orderPartAdd: action.value
            }
        case 'START_REMOVE_CENTROID':
        case 'START_CONVERT_CENTROID':
            return {...state}
        case 'RECEIVE_REMOVE_CENTROID':
        case 'RECEIVE_CONVERT_CENTROID':
            return {...state, print: action.value}
        case 'ERROR_REMOVE_CENTROID':
        case 'ERROR_CONVERT_CENTROID':
            return {...state}
        case 'START_BOM_SEARCH_PROJECT_USED_PART':
            return {
                ...state,
                searchingProjectUsedPart: true,
                searchProjectUsedPart: []
            }
        case 'RECEIVE_BOM_SEARCH_PROJECT_USED_PART':
            return {
                ...state,
                searchingProjectUsedPart: false,
                searchProjectUsedPart: action.value
            }
        case 'ERROR_BOM_SEARCH_PROJECT_USED_PART':
            return {...state}
        case 'START_BOM_ADD_BOM_LINE':
            return {...state}
        case 'RECEIVE_BOM_REMOVE_ALL_BOM_LINE_INFO':
            return {
                ...state,
                print: {
                    ...state.print,
                    bom: state.print.bom.map(b => {
                        if( b.id === action.value.id ) {
                            return action.value
                        }
                        return b
                    })
                }
            }
        case 'RECEIVE_BOM_ADD_BOM_LINE':
            if( state.print.bom.some(b => b.id === action.value.id) ) {
                return {
                    ...state,
                    print: {
                        ...state.print,
                        bom: state.print.bom.map(b => {
                            if( b.id === action.value.id ) {
                                return action.value
                            }
                            return b
                        })
                    }
                }
            }
            return {
                ...state,
                print: {
                    ...state.print,
                    bom: [
                        ...state.print.bom,
                        action.value
                    ]
                }
            }
        case 'ERROR_BOM_ADD_BOM_LINE':
            return {...state}
        case 'START_BOM_ADD_DESIGNATOR':
            return {...state,
                addingDesignator: true
            }
        case 'RECEIVE_BOM_ADD_DESIGNATOR':
            return {
                ...state,
                addingDesignator: false,
                print: {
                    ...state.print,
                    bom: state.print.bom.map(b => {
                        if( b.id === action.value.id ) {
                            return action.value
                        }
                        return b;
                    })
                }
            }
        case 'ERROR_BOM_ADD_DESIGNATOR':
            return {...state, addingDesignator: false}
        case 'BOM_CENTROID_VIEW':
            return {
                ...state,
                bomCentroidView: action.open,
                bomCentroidViewSearch: action.searchValue,
            }
        case 'SET_BOM_CENTROID_VIEW_SEARCH':
            return {
                ...state, bomCentroidViewSearch: action.value
            }
        case 'SHOW_GET_ORDERPART_INFO':
            return {
                ...state, showOrderpartInfo: action.id
            }
        case 'START_GET_ORDERPART':
            return {
                ...state, orderPart: null, fetchingOrderPart: true
            }
        case 'RECEIVE_GET_ORDERPART':
            return {
                ...state, orderPart: action.value, fetchingOrderPart: false
            }
        case 'RECEIVE_SET_ORDERPART_FIELD':
            return {
                ...state, orderPart: action.value, editingOrderPart: false,
                print: state.print ? {...state.print, bom: state.print.bom.map(l => {
                    let ssp = null;
                    if( l.selectedSupplierPart && l.selectedSupplierPart.orderpart && l.selectedSupplierPart.orderpart.id === action.value.id) {
                        ssp = {
                            ...l.selectedSupplierPart,
                            orderpart: action.value
                        }
                    }

                    if( l.selectedOrderPart && l.selectedOrderPart.id === action.value.id) {
                        return {
                            ...l,
                            selectedOrderPart: action.value,
                            selectedSupplierPart: ssp ? ssp : l.selectedSupplierPart
                        }
                    }

                        return {
                            ...l,
                            selectedSupplierPart: ssp ? ssp : l.selectedSupplierPart
                        }
                })} : state.print
                };

        case 'START_GET_ORDERPART_PROJECT_INFO':
            return {
                ...state,
                orderpartInfo: [],
                fetchingOrderpartInfo: action.start.id
            }
        case 'RECEIVE_GET_ORDERPART_PROJECT_INFO':
            return {
                ...state,
                orderpartInfo: action.value,
                fetchingOrderpartInfo: false
            }
        case 'ERROR_GET_ORDERPART_PROJECT_INFO':
            return {
                ...state,
                fetchingOrderpartInfo: false
            }
        case 'CLOSE_GET_ORDERPART_PROJECT_INFO':
            return {
                ...state,
                orderpartInfo: [],
                fetchingOrderpartInfo: false
            }
        case 'SET_BOM_DEMO_MODE':
            return {
                ...state, bomDemoMode: action.value
            }
        case 'OPEN_MOHICAN_SELECTION_SETTINGS_MODAL':
            return {
                ...state, mohicanSelectionSettingsModal: action.open
            }
        case 'OPEN_MOHICAN_CONNECTION_MODAL':
            return {
                ...state, mohicanConnectionModal: action.open
            }
        case 'OPEN_MOHICAN_HOME_MODAL':
            return {
                ...state, mohicanHomeModal: action.open
            }
        case 'OPEN_MOHICAN_TRAY_MODAL':
            return {
                ...state, mohicanTrayModal: action.open
            }
        case 'OPEN_MOHICAN_PCB_MODAL':
            return {
                ...state, mohicanPcbModal: action.open
            }
        case 'BOM_BUGREPORT_OPEN':
            return {
                ...state, bomBugreport: action.open
            }
        case 'RECEIVE_FETCH_KNOWN_DIELECTRIC':
        case 'RECEIVE_SAVE_KNOWN_DIELECTRIC':
            return {
                ...state, knownDielectric: action.value
            }
        case 'RECEIVE_FETCH_KNOWN_PACKAGES':
        case 'RECEIVE_SAVE_KNOWN_PACKAGES':
            return {
                ...state, knownPackages: action.value
            }
        case 'CHANGE_KNOWN_DIELECTRIC':
            return {
                ...state, knownDielectric: state.knownDielectric.map(x => (
                    x.id === action.id ? {...x, [action.name]: action.value, dirty: true} : x
                ))
            }
        case 'CHANGE_KNOWN_PACKAGE':
            return {
                ...state, knownPackages: state.knownPackages.map(x => (
                    x.id === action.id ? {...x, [action.name]: action.value, dirty: true} : x
                ))
            }
        case 'START_BOM_TEST_PARSE_VALUE':
            return {
                ...state,
                bomTestParseBusy: true,
                bomTestParseError: null
            }
        case 'RECEIVE_BOM_TEST_PARSE_VALUE':
            return {
                ...state,
                bomTestParseBusy: false,
                bomTestParseResult: action.value,
                bomTestParseError: null
            }
        case 'ERROR_BOM_TEST_PARSE_VALUE':
            return {
                ...state,
                bomTestParseBusy: false,
                bomTestParseResult: null,
                bomTestParseError: action.error ? action.error.message : 'Error'
            }
        case 'RECEIVE_UPDATE_BOM_AUTO_SELECT_SETTINGS':
            return {
                ...state,
                print: {...state.print, bomAutoSelectSettings: action.value}
            }
        case 'CHANGE_BOM_AUTO_SELECT_SETTINGS':
            return {
                ...state,
                    print: {...state.print, bomAutoSelectSettings: {
                        ...state.print.bomAutoSelectSettings,
                        [action.name]: action.value,
                        original: state.print.bomAutoSelectSettings.dirty ? state.print.bomAutoSelectSettings.original : JSON.parse(JSON.stringify(state.print.bomAutoSelectSettings)),
                        dirty: true
                    }}
            }
        case 'REVERT_BOM_AUTO_SELECT_SETTINGS':
            if( state.print.bomAutoSelectSettings.original ) {
                return {
                    ...state,
                    print: {
                        ...state.print, bomAutoSelectSettings:
                        state.print.bomAutoSelectSettings.original
                    }
                }
            }
            return state;
        case 'REVERT_BOM_DEFAULT_AUTO_SELECT_SETTINGS':
            if( state.defaultAutoselectSettings.original ) {
                return {
                    ...state, defaultAutoselectSettings: state.defaultAutoselectSettings.original
                }
            }
            return state;
        case 'RECEIVE_GET_DEFAULT_BOM_AUTO_SELECT_SETTINGS':
        case 'RECEIVE_UPDATE_DEFAULT_BOM_AUTO_SELECT_SETTINGS':
            return {
                ...state, defaultAutoselectSettings: action.value
            }
        case 'CHANGE_BOM_DEFAULT_AUTO_SELECT_SETTINGS':
            return {
                ...state,
                defaultAutoselectSettings: {
                        ...state.defaultAutoselectSettings,
                        [action.name]: action.value,
                        original: state.defaultAutoselectSettings.dirty ? state.defaultAutoselectSettings.original : JSON.parse(JSON.stringify(state.defaultAutoselectSettings)),
                        dirty: true
                    }
            }
        case 'START_UPLOAD_IMPORT_DATA':
            return {
                ...state, loadingImportData: true, importDataRecognisedFormat: false
            }
        case 'RECEIVE_UPLOAD_IMPORT_DATA':
            return {
                ...state, loadingImportData: false, importData: action.body.key, importDataSelected: action.body.key[0].sheetName, import: initialImport
            }
        case 'RECEIVE_UPLOAD_IMPORT_DATA_RECOGNISED_FORMAT':
            return {
                ...state, loadingImportData: false, importData: [], importDataSelected: '', importDataRecognisedFormat: true
            }
        case 'CLOSE_IMPORT_DATA':
            return {
                ...state, importData: [], importDataSelected: '', loadingImportData: false, importDataRecognisedFormat: false
            }
        case 'SET_IMPORT_VALUE':
            return {
                ...state, import: {...state.import, [action.name]: action.value}
            }
        case 'CHANGE_IMPORT_VALUE':
            return {
                ...state, importData: state.importData.map(s => s.sheetName === action.sheetName ? {
                    ...s,
                    [action.name]: action.value
                } : s)
            }
        case 'DELETE_IMPORT_ROW':
            return {
                ...state, importData: state.importData.map(s => s.sheetName === action.sheetName ? {
                    ...s,
                    data: s.data.filter((x, i) => i !== action.i)
                } : s)
            }
        case 'CHANGE_IMPORT_CELL_VALUE':
            return {
                ...state, importData: state.importData.map(s => s.sheetName === action.sheetName ? {
                    ...s,
                    data: s.data.map((r,j) => {
                        return r.map((c,i) => {
                            if( (i === action.i) && (j === action.j) ) {
                                return {
                                    ...c,
                                    [action.name]: action.value,
                                    original: c.dirty ? c.original : JSON.parse(JSON.stringify(c)),
                                    dirty: true
                                }
                            }
                            return c;
                        })
                    })
                } : s)
            }
        case 'REVERT_IMPORT_CELL_VALUE':
            return {
                ...state, importData: state.importData.map(s => s.sheetName === action.sheetName ? {
                    ...s,
                    data: s.data.map((r,j) => {
                        return r.map((c,i) => {
                            if( (i === action.i) && (j === action.j) ) {
                                return {
                                    ...c.original
                                }
                            }
                            return c;
                        })
                    })
                } : s)
            }
        case 'RECEIVE_IMPORT_BOM_V2':
            return {
                ...state,
                importData: [],
                importDataSelected: null
            }
        case 'OPEN_BOM_CONFIRM_CHECKED':
            return {
                ...state, bomConfirmCheckedOpen: action.open, bomConfirmCheckedBusy: false, bomConfirmCheckedError: false, bomConfirmChecked: false
            }
        case 'START_SEND_BOM_CONFIRM_CHECKED':
            return {
                ...state, bomConfirmCheckedBusy: true, bomConfirmCheckedError: false
            }
        case 'RECEIVE_SEND_BOM_CONFIRM_CHECKED':
            return {
                ...state, bomConfirmChecked: true, bomConfirmCheckedBusy: false, bomConfirmCheckedOpen: false
            }
        case 'ERROR_SEND_BOM_CONFIRM_CHECKED':
            return {
                ...state, bomConfirmCheckedBusy: false, bomConfirmCheckedError: true, bomConfirmChecked: false
            }
        case 'RECEIVE_IMPORT_CENTROID_V2':
            return {
                ...state,
                importData: [],
                importDataSelected: null
            }
        case 'START_GET_CENTROID_BY_PRINT':
            return {
                ...state,
                centroidList: []
            }
        case 'RECEIVE_GET_CENTROID_BY_PRINT':
            return {
                ...state,
                centroidList: action.value
            }
        default:
            return state
    }
}
export default bom
