import React, {Component} from 'react';
import './App.css';
import connect from "react-redux/es/connect/connect";
import {isEmployee} from "./actions/functions";
import Translate from "./Translate";
import {Button, Tab} from "semantic-ui-react";
import SideBar from "./bom/SideBar";
import BasicProjectMenu from "./basic/BasicProjectMenu";
import BasicSheet from "./basic/BasicSheet";
import ChatView from "./chat/ChatView";
import BomMicroPlacer from "./bom/BomMicroPlacer";
import Calculator from "./oldui/Calculator";
import {getBomOrders, openBomOrder, openChatModal} from "./actions";

class ProjectStepCheckIn extends Component{

    state = {
        activeName: 'bom',
        bstStep: 'Bom laden',
        useFullPageWidth: true
    }

    getPanes(dispatch, project) {
        return [
            {
                menuItem: 'BOM Tool',
                render: () => <Tab.Pane attached={true}>

                </Tab.Pane>,
            }
        ]
    }

    /*renderPane(dispatch, project) {

        switch (this.state.activeTab) {
            default:
            case 0:
                return <div>
                    <BomMicroPlacer projectOrderId={project.id} defaultViewId={'Checkin'} />
                </div>;
            case 1:
                return <div>

                    {project.bomId ? '' : 'Vul aub het Bom Id in op de Order pagina'}

                    {project.bomId ? <iframe title="BST" style={{width: '100%', height: '80vh'}} src={'https://bst.deltaproto.com?frame=true&checkin=true&id='+project.bomId}/> : '' }

                </div>;
        }
    }*/

    renderChat(project) {
        return <BasicSheet header={'Berichten'}>
            <ChatView chatId={this.props.project.chat} showInput={false} isPageView={false} mention={project.owner} />

            <div style={{textAlign: 'right'}}>
                <Button primary onClick={() => this.props.dispatch(openChatModal(true))}>
                    <Translate value={'post_new_chat'}/>
                </Button>
            </div>

        </BasicSheet>
    }

    render() {

        let { dispatch, project } = this.props;

        if( !project ) {
            return <div/>
        }

        const admin = isEmployee(this.props.currentUser);

        if( !admin ) {

            return <div style={{marginTop: 16}}>
                <BasicProjectMenu
                    menu={[
                        {
                            name: 'bom',
                            item: <>
                                BOM
                                {(this.props.project && this.props.project.bomStats && this.props.project.bomStats.markedLines) ?
                                    <div className='notification-counter'>{this.props.project.bomStats.markedLines}</div> : ''}
                            </>
                        }
                    ]}
                    menuRight={[
                        {
                            name: 'chat',
                            item: 'Berichten'
                        }
                    ]}
                    activeName={this.state.activeName}
                    onTabChange={(name) => this.setState({activeName: name})}
                />
                {{
                    'bom':
                        <div className='fullwidthprojectview' style={{marginTop: 8}}>
                            <div className={'almostfullwidthprojectview'}>
                                <BomMicroPlacer projectOrderId={this.props.project.id} defaultViewId={'Klant'} locked={project.order === 'ready'} />
                            </div>
                        </div>,
                    'chat': this.renderChat(project)
                }[this.state.activeName]}

            </div>
        }

        return <div style={{marginTop: 16}}>
            {admin && <SideBar/>}

            <BasicProjectMenu
                menu={[
                    {name: 'bom', item: 'BOM'},
                    {name: 'orders', item: 'Orders', bstStep: 'Orders'},
                ]}
                menuRight={[
                    {
                        name: 'chat',
                        item: 'Chat'
                    }
                ]}
                activeName={this.state.activeName}
                onTabChange={(name) => {
                    if( name === 'orders' ) {
                        if( this.state.activeName !== 'bom' && this.state.activeName !== 'bomproductie' ) {
                            this.setState({activeName: 'bom'})
                        }
                        this.props.dispatch(openBomOrder(true))
                        if( this.props.print ) {
                            this.props.dispatch(getBomOrders(this.props.print.id))
                        }
                    } else {
                        this.setState({activeName: name})
                    }
                }}
                sideBar={true}
            />

            {
                {
                    'bom':
                        <div className='fullwidthprojectview' style={{marginTop: 8}}>
                            <div className={'almostfullwidthprojectview'}>
                                <BomMicroPlacer projectOrderId={project.id} defaultViewId={this.state.bstStep}/>
                            </div>
                        </div>,
                    'chat': <BasicSheet header='Chat'>
                        <ChatView chatId={this.props.project.chat} showInput={true} isPageView={false}/>
                    </BasicSheet>
                }[this.state.activeName]
            }


        </div>

        /*return (
            <div>
                <TabHeader panes={['Nieuwe BOM Tool', 'BOM Tool']} activeIndex={this.state.activeTab}
                           onChange={(value) => this.setState({activeTab: value})}
                />
                <TabMenu useFullPageWidth={this.state.useFullPageWidth}>
                    <ButtonTabMenuFullWidth onClick={() => this.setState({useFullPageWidth: !this.state.useFullPageWidth})}/>
                </TabMenu>
                <TabPane useFullPageWidth={this.state.useFullPageWidth}>
                    {this.renderPane(dispatch, project)}
                </TabPane>
            </div>
        )*/
    }
}

const mapStateToProps = (state) => {
    return {
        currentUser: state.main.currentUser,
        lang: state.main.lang,
        currentLang: state.main.currentLang,
        project: state.project.project,
    }
}

export default connect(mapStateToProps)(ProjectStepCheckIn)