import React from 'react'
import {Button, Dropdown, Icon, Menu, Segment} from "semantic-ui-react";
import connect from "react-redux/es/connect/connect";
import {setBomSearch, setBomSearchRef} from "../actions";
import {fullscreen, fullscreenstyle} from "../actions/functions";
import PropTypes from "prop-types";

class DeltaTableMenu extends React.Component {

    state = {
        fullscreen: false
    }

    render() {
        const {nopadding} = this.props;
        const disabled = false;

        const childrenWithProps = this.state.fullscreen ? React.Children.map(this.props.children, child => {
            // Checking isValidElement is the safe way and avoids a
            // typescript error too.
            if (React.isValidElement(child)) {
                return React.cloneElement(child, { maxHeight: 'calc(100vh - 108px)' });
            }
            return child;
        }) : this.props.children;

        return <div style={this.state.fullscreen ? {...fullscreenstyle, padding: '0px 8px 8px 8px'} : {}}>
            <Menu attached='top' style={{marginTop: 8}} color={this.props.color} inverted>

            <Dropdown item={'BOM'} icon={null} text={<div><Icon name='bars'/></div>}>
                <Dropdown.Menu>
                    {this.props.menu}
                </Dropdown.Menu>
            </Dropdown>

                <Menu.Item style={{fontSize: '1.2em'}}>{this.props.header}</Menu.Item>

            <Menu.Menu position='right'>
                {this.props.onSearchChange ? <div className='ui right aligned category search item' style={{padding: 8}}>
                    <div className='ui icon input'>
                        <input
                            disabled={disabled}
                            className='prompt'
                            type='text'
                            placeholder='Search...'
                            value={this.props.bomSearch}
                            onChange={(e) => this.props.dispatch(setBomSearch(e.target.value))}
                            ref={(input) => this.props.dispatch(setBomSearchRef(input))}
                        />
                        {this.props.bomSearch ? <i className='close link icon' onClick={() => this.props.dispatch(setBomSearch(''))}/>
                            : <i className='search link icon' disabled={disabled}/>}
                    </div>

                </div> : null}
                <Menu.Item style={{padding: 8}}>
                    <Button icon='expand' color={this.state.fullscreen ? 'red' : this.props.color} onClick={() => {
                        if( this.state.fullscreen ) {
                            this.setState({fullscreen: false})
                            fullscreen(false)
                        } else {
                            this.setState({fullscreen: true})
                            fullscreen(true)
                        }
                    }}/>
                </Menu.Item>
            </Menu.Menu>
        </Menu>

            <Segment attached style={{minHeight: this.state.fullscreen ? 'calc(100vh - 108px)' : 16, padding: nopadding ? 'unset' : null}}>
                {childrenWithProps}
            </Segment>

            <Menu attached='bottom' style={{marginBottom: 8}} color={this.props.color} inverted>
            </Menu>

        </div>
    }
}

DeltaTableMenu.propTypes = {
    header: PropTypes.string,
    menu: PropTypes.array,
    onSearchChange: PropTypes.func,
    nopadding: PropTypes.bool,
    color: PropTypes.string
}

DeltaTableMenu.defaultProps = {
    header: '',
    menu: [],
    onSearchChange: null,
    nopadding: false,
    color: 'blue',
}

const mapStateToProps = (state) => {
    return {}
}

export default connect(mapStateToProps)(DeltaTableMenu)