import React, {Component} from "react";
import {Button, Dropdown, Input, Popup, Search} from "semantic-ui-react";
import {connect} from "react-redux";
import {renderSupplier} from "./functions";
import {reSelectedSupplierPart, setSelectedSupplierPart} from "../actions";

class BomSearchFromOrders extends Component {

    state = {
        open: false,
        search: ''
    }

    renderPartOrders(list) {
        const search = this.state.search.trim().toLowerCase();

        return list.map((o) => {
            return o.items.filter((i) => !this.isAlreadySelected(i)).filter((i) => {
                if( search != '' ) {
                    const orderpart = ((i.part) ? i.part.orderpart : null)

                    if( orderpart ) {
                        if( orderpart.specification && orderpart.specification.displayValue ) {
                            if( orderpart.specification.displayValue.toLowerCase().includes(search) ) return true;
                        }
                        if( orderpart.mpn.toLowerCase().includes(search) ) return true;
                    }

                    if( i.part ) {
                        if( i.part.sku.toLowerCase().includes(search) ) return true;
                    }

                    if( i.kla ) {
                        if( i.kla.name && i.kla.name.toLowerCase().includes(search) ) return true;
                        if( i.kla.mpn && i.kla.mpn.toLowerCase().includes(search) ) return true;
                        if( i.kla.sku && i.kla.sku.toLowerCase().includes(search) ) return true;
                    }

                    return false;
                }
                return true;
            }).map(i => {

                const orderpart = ((i.part) ? i.part.orderpart : null)

                return (
                    <Dropdown.Item onClick={() => {
                        if( i.kla ) {
                            this.props.dispatch(reSelectedSupplierPart(this.props.id, i.kla.id))
                        } else if( i.part ) {
                            this.props.dispatch(setSelectedSupplierPart(this.props.id, i.part.id))
                        }
                        this.setState({open: false})
                    }}>
                        <div className='btitem'>
                            {orderpart ? ((orderpart.specification && orderpart.specification.displayValue) ? orderpart.specification.displayValue : orderpart.shortDescription) : ''}
                            {i.kla ? i.kla.sku : ''}
                        </div>
                        <div className='btitem'>
                            {(orderpart) ? orderpart.manufacturer + " " + orderpart.mpn : ''}
                            {i.kla ? i.kla.mpn : ''}
                        </div>
                        <div style={{maxWidth: 500}}>
                            {i.part ? renderSupplier({supplier: i.part.supplier, sku: i.part.sku}) : ''}
                            {i.kla ? renderSupplier({supplier: 'KLANT', sku: i.kla.name + ' ' + i.kla.sku}) : ''}
                        </div>

                    </Dropdown.Item>
                )
            })

        })
    }

    isAlreadySelected(i) {
        if( i.part ) {
            return this.props.print.bom.filter(bl => bl.selectedSupplierPart).some(bl => {
                return bl.selectedSupplierPart.id === i.part.id
            })
        } else if( i.kla ) {
            return this.props.print.bom.filter(bl => bl.klantLevertAan).some(bl => {
                return bl.klantLevertAan.id === i.kla.id
            })
        }
        return false;
    }


    render() {

        return <div>
            <Button disabled={this.props.validatingBom || this.props.busy}
                    icon='caret down' style={{height: 38.5, marginLeft: 3.5}}
                    onClick={() => {
                        this.setState({open: !this.state.open, search: ''})
                    }}
            />

            <Popup trigger={
                <Dropdown open={this.state.open} disabled={this.props.bomOrderList.length === 0} scrolling icon={null} onBlur={() => {
                    this.setState({open: false})
                }} trigger={<div></div>
                    }>
                    {this.state.open ? <Dropdown.Menu>
                        <Dropdown.Item>
                            <Search fluid autoFocus showNoResults={false} onKeyDown={(e,d) => {
                                //this is ugly!
                                if( e.keyCode === 32) {
                                    this.setState({search: this.state.search + ' '})
                                }
                            }} onSearchChange={(e,d) => {
                                this.setState({search: d.value})
                            }} value={this.state.search} />
                        </Dropdown.Item>
                        {this.renderPartOrders(this.props.bomOrderList)}
                    </Dropdown.Menu> : null}
                </Dropdown>}
           content={<div>
               <div>Select from existing orders</div>
           </div>}/>
        </div>
    }
}


const mapStateToProps = (state) => {
    return {
        currentUser: state.main.currentUser,
        print: state.bom.print,
        bomOrderList: state.bom.bomOrderList,
        bomOrderListBusy: state.bom.bomOrderListBusy,

    }
}

export default connect(mapStateToProps)(BomSearchFromOrders)