import {recentUpdated} from "./functions";
import {updateStockAndPrice} from "../actions";
import {Icon, Popup, Transition} from "semantic-ui-react";
import React from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {formateDateSince} from "../actions/functions";

class BomStockIcon extends React.Component {

    state = {
        visible: true,
    }

    render() {
        const {selectedSupplierPart} = this.props;
        const recent = recentUpdated(selectedSupplierPart);

        //const visible = this.state.visible;
        const loading = selectedSupplierPart.loading;

        return <>
            <Popup trigger={<div style={{display: 'flex', marginLeft: -6, marginTop: 0}}>
                <Icon style={{marginLeft: 4, color: '#38A700'}} link name='check'
                      onClick={() => this.props.dispatch(updateStockAndPrice([selectedSupplierPart.id]))}/>
                <Icon style={{marginLeft: -12, color: selectedSupplierPart.loading ? null : selectedSupplierPart.failed ? 'red' : recent ? '#38A700' : 'lightgray'}} className={loading ? 'pulsing' : null}
                  link
                  name={selectedSupplierPart.stock != null ? 'check' : 'help'}
                  onClick={() => this.props.dispatch(updateStockAndPrice([selectedSupplierPart.id]))}/>
            </div>} content={
                <div>
                    <div>
                        Last supplier stock update: {selectedSupplierPart.lastUpdate ? formateDateSince(selectedSupplierPart.lastUpdate, this.props.currentLang) : 'Never'}
                    </div>
                    <div>
                        {selectedSupplierPart.lastUpdate ? '' : 'Using Octopart stock data (is usually outdated)'}
                    </div>
                    <div>
                        <br/>
                        {selectedSupplierPart.failed ? 'Failed to update stock' : recent ? 'Updated recently' : selectedSupplierPart.loading ? 'Fetching stock from supplier...' : 'Click to update stock'}
                    </div>
                </div>}/>
        </>
    }
}

BomStockIcon.propTypes = {
    selectedSupplierPart: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
    return {
        currentLang: state.main.currentLang,
    }
};

export default connect(mapStateToProps)(BomStockIcon);