import React, {Component} from 'react';
import connect from "react-redux/es/connect/connect";
import TableResize from "../basic/TableResize";
import {Button, Dropdown, Icon, Input, Modal, Search} from "semantic-ui-react";
import {
    addNewBuddyPart,
    changeBuddyPart,
    getBuddyParts,
    getCompanyList,
    saveBuddyPart,
    updateOrderpart, updateOrderPartSpecification
} from "../actions";
import {renderImageForPart} from "./BomOrderparts";
import DeltaTable from "../deltatable/DeltaTable";
import DeltaTableMenu from "../deltatable/DeltaTableMenu";
import BomSearchDialog from "./BomSearchDialog";
import Translate from "../Translate";

class BoardsConfiguration extends React.Component {

    state = {edit: false}

    componentDidMount() {
        this.props.dispatch(getBuddyParts())
        this.props.dispatch(getCompanyList())
    }

    renderEdit(value, name) {
        return <Input transparent value={value[name]}
                      onChange={(e) => this.props.dispatch(changeBuddyPart(value.id, name, e.target.value))}
        />
    }

    render() {
        const machineOptions = [{key: -1, text: 'DeltaProto', value: -1},
            ...this.props.companyList.map(c => ({
                key: c.id, text: c.name, value: c.id
            }))]

        return <div>

            {this.state.edit ? <BomSearchDialog
                open={true}
                onClose={() => this.setState({edit: false})}
                onOrderpart={(op) => {
                    this.props.dispatch(changeBuddyPart(this.state.edit.id, 'orderpart', op))
                    this.setState({edit: false})
                }}
            /> : null}

            <DeltaTableMenu
                header={'Boards'}
            >
                {this.state.editSpec ? <Modal open={true} closeIcon onClose={() => this.setState({editSpec: null})}>
                    <Modal.Header>Edit spec</Modal.Header>
                    <Modal.Content>
                        <div>
                            <div>{this.state.editSpec.manufacturer} {this.state.editSpec.mpn}</div>
                            <div>{this.state.editSpec.shortDescription}</div>
                            <a target="_blank" href={this.state.editSpec.octopartUrl}>{this.state.editSpec.octopartUrl}</a>
                        </div>
                        <Input fluid value={this.state.editSpecValue ? this.state.editSpecValue : this.state.editSpec.specification.displayValue} onChange={(e) => this.setState({editSpecValue: e.target.value})}/>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button onClick={() => this.setState({editSpec: null})}>Close</Button>
                        <Button primary={this.state.editSpecValue} onClick={() => {
                            this.props.dispatch(updateOrderPartSpecification(this.state.editSpec.id, this.state.editSpecValue, () => {
                                this.setState({editSpec: null})
                                this.props.dispatch(getBuddyParts())
                            }))
                        }}>Save</Button>
                    </Modal.Actions>
                </Modal> : null}


                <DeltaTable
                    name='boardsconfiguration'
                    columns={[
                        {id: 1, header: 'name', sort: 'name', render: (p) => <div>
                            {this.renderEdit(p, 'name')}
                        </div>},
                        {id: 2, header: 'customer', filter: 'customer', filterOptions: machineOptions, render: (p) => <div>
                            {/*{this.renderEdit(p, 'machine')}*/}
                                <Dropdown
                                    fluid
                                    search
                                    selection value={p.customer ? p.customer : -1}
                                    options={machineOptions}
                                    onChange={(e,d) => {
                                        this.props.dispatch(changeBuddyPart(p.id, 'customer', d.value === -1 ? null : d.value))
                                    }}
                                />
                        </div>},
                        {id: 3, header: 'board', render: (p) => <div>
                                {this.renderEdit(p, 'machine')}
                            </div>},
                        {id: 4, header: 'location', render: (p) => <div>
                            {this.renderEdit(p, 'location')}
                        </div>},
                        {id: 5, header: 'nozzle', render: (p) => <div>
                            {this.renderEdit(p, 'nozzle')}
                        </div>},
                        {id: 6, header: 'price1', render: (p) => <div>
                            {this.renderEdit(p, 'price1')}
                        </div>},
                        {id: 7, header: 'orderpartimage', defaultWidth: 50, render: (p) => <div>
                            {p.orderpart ? <>
                                {renderImageForPart(p.orderpart)}
                            </> : null}
                        </div>},
                        {id: 8, header: 'orderpart', defaultWidth: 300, render: (p) => <div>
                            <div style={{float: 'right'}}>
                                <Icon link name='edit' onClick={() => this.setState({edit: p})}/>
                            </div>

                            {p.orderpart ? <>
                                <div>{p.orderpart.mpn}</div>
                                <button className='link-button' disabled={!p || !p.orderpart || !p.orderpart.specification} onClick={() => {
                                    this.setState({editSpec: p.orderpart, editSpecValue: null})
                                }}>
                                    {(p.orderpart.specification && p.orderpart.specification.displayValue) ? p.orderpart.specification.displayValue : '...'}
                                </button>
                                {(p.orderpart.specification && p.orderpart.specification.resistance != null) ? <div style={{color: 'red'}}>
                                    {p.orderpart.specification.packageOutline == null ? <div>No package</div> : null}
                                    {p.orderpart.specification.powerRating == null ? <div>No power rating</div> : null}
                                    {p.orderpart.specification.toleranceValue == null ? <div>No tolerance rating</div> : null}
                                </div> : null}
                                {(p.orderpart.specification && p.orderpart.specification.capacitance != null) ? <div style={{color: 'red'}}>
                                    {p.orderpart.specification.packageOutline == null ? <div>No package</div> : null}
                                    {p.orderpart.specification.voltageRating == null ? <div>No voltage rating</div> : null}
                                </div> : null}
                            </> : null}
                        </div>},
                        {id: 9, header: 'action', render: (p) => <div>
                            <Icon name={'refresh'} link onClick={() => {
                                this.props.dispatch(updateOrderpart(p.orderpart.id))
                            }}/>
                            <Icon name={'save'} link color={p.dirty ? 'blue' : null}
                                    onClick={() => {
                                        this.props.dispatch(saveBuddyPart(p))
                                    }}
                            />
                        </div>},
                    ]}
                    data={this.props.buddy}
                    render={(name, value) => {

                        if( name === 'action' ) {
                            return <div style={{float: 'right'}}>
                                <Button size={'mini'} icon onClick={() => {
                                    this.props.dispatch(updateOrderpart(value.orderpart.id))
                                }}>
                                    <Icon name='refresh'/>
                                </Button>
                                <Button size={'mini'} primary={value.dirty}
                                        onClick={() => {
                                            this.props.dispatch(saveBuddyPart(value))
                                        }}
                                ><Translate value='save'/></Button>
                            </div>
                        } else if (name === 'orderpartimage') {

                            let op = value['orderpart'];

                            return <div>
                                {renderImageForPart(op)}
                            </div>

                        } else if (name === 'orderpart') {

                            let op = value['orderpart'];

                            return <div>

                                <div style={{float: 'right'}}>
                                    <Button size={'mini'} icon='edit'/>
                                </div>

                                <div>{op.mpn}</div>
                                <div>{(op.specification && op.specification.displayValue) ? op.specification.displayValue : '...'}</div>

                            </div>
                        }

                        return <Input transparent value={value[name]}
                                      onChange={(e) => this.props.dispatch(changeBuddyPart(value.id, name, e.target.value))}
                        />
                    }}
                    onAddRow={() => {
                        this.props.dispatch(addNewBuddyPart({}))
                    }}
                    addDisabled={this.props.buddy.some(b => b.new)}
                />
            </DeltaTableMenu>


            {/*<Button
                style={{marginTop: 16}}
                onClick={() => this.setState({addOpen: true})}
            >toevoegen</Button>*/}

            {this.state.addOpen && this.renderAdd()}
        </div>
    }
}


const mapStateToProps = (state) => {
    return {
        lang: state.main.lang,
        currentLang: state.main.currentLang,
        currentUser: state.main.currentUser,
        buddy: state.bom.buddy,
        companyList: state.main.companyList,

    }
}

export default connect(mapStateToProps)(BoardsConfiguration)