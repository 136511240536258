import React, {Component} from 'react';
import './App.css';
import connect from "react-redux/es/connect/connect";
import {bestelBronnen, formatDateTime, getMissingSuppliers, isEmployee} from "./actions/functions";
import Translate from "./Translate";
import {Button, Dropdown, Form, Input, Tab, Table} from "semantic-ui-react";
import {
    changeProjectValue,
    getBomOrders,
    getProjectOrderList,
    openBomOrder,
    openChatModal,
    saveProject
} from "./actions";
import SideBar from "./bom/SideBar";
import BasicProjectMenu from "./basic/BasicProjectMenu";
import BasicSheet from "./basic/BasicSheet";
import ChatView from "./chat/ChatView";
import BomMicroPlacer from "./bom/BomMicroPlacer";

var moment = require('moment');
moment.locale('nl');

class ProjectStepBuyParts extends Component{

    state = {
        activeName: 'bom',
        bstStep: 'Bom laden',
        useFullPageWidth: true
    }

    componentDidMount() {
        if( this.props.project ) {
            this.props.dispatch(getProjectOrderList(this.props.project.id))
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if( this.props.project && !prevProps.project ) {
            this.props.dispatch(getProjectOrderList(this.props.project.id))
        }
    }

    onChangeBestellingen(index, name, value) {

        let bestellingen= this.props.project.bestellingen.map((b, i) => {
            if( i === index ) {
                let obj= {};
                obj[name]= value;
                return Object.assign({}, b, obj)
            } else {
                return b;
            }
        })

        this.props.dispatch(changeProjectValue('bestellingen', bestellingen))
    }

    onAddBestellingen() {
        let bestellingen= [...this.props.project.bestellingen, {
            supplier: '',
            supplierOrderNumber: '',
            status: 'ORDERED',
            orderPlaced: moment().unix() * 1000,
            created: moment().unix() * 1000
        }];
        this.props.dispatch(changeProjectValue('bestellingen', bestellingen))
    }

    onRemoveBestellingen(index) {
        let bestellingen= this.props.project.bestellingen.slice(0,index).concat(this.props.project.bestellingen.slice(index+1))
        this.props.dispatch(changeProjectValue('bestellingen', bestellingen))
    }

    onOpenBestellingen(project) {


        let addSuppliers= [];

        getMissingSuppliers(project).forEach(m => {
            let add= true;

            this.props.project.bestellingen.forEach(e => {

                if( m === 'PCB' ) {
                    if( e.supplier && e.supplier.endsWith('_PCB') ) {
                        add= false;
                    }
                } else  if( m === 'STENCIL' ) {
                    if( e.supplier && e.supplier.endsWith('_STENCIL') ) {
                        add= false;
                    }
                } else {
                    if( e.supplier && (m === e.supplier) ) {
                        add= false;
                    }
                }

            })

            if( add ) {
                //add our default suppliers when the list doesn't contain a _PCB and _STENCIL supplier
                addSuppliers.push({
                    supplier: (m === 'STENCIL') ? 'MULTI_CB_STENCIL' : (m === 'PCB') ? 'MULTI_CB_PCB' : m,
                    supplierOrderNumber: '',
                    status: 'ORDERED',
                    orderPlaced: moment().unix() * 1000,
                    created: moment().unix() * 1000
                })
            }
        })

        let bestellingen= (this.props.project.bestellingen ? this.props.project.bestellingen : []).concat( addSuppliers )

        this.props.dispatch(changeProjectValue('bestellingen', bestellingen))



    }

    renderBestellingen(project) {

        return <Table celled>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell>Leverancier</Table.HeaderCell>
                    <Table.HeaderCell>Order code van de leverancier</Table.HeaderCell>
                    <Table.HeaderCell>Datum</Table.HeaderCell>
                    <Table.HeaderCell>Status</Table.HeaderCell>
                </Table.Row>
            </Table.Header>

            <Table.Body>
                {project.bestellingen && project.bestellingen.sort( (a,b) => {
                    let objA= bestelBronnen.find((e) => e.value === a.supplier);
                    let objB= bestelBronnen.find((e) => e.value === b.supplier);

                    return ( objA ? objA.order : 99 ) - ( objB ? objB.order : 99 );
                } ).map((b, i) =>
                    <Table.Row>
                        <Table.Cell>
                            <Dropdown fluid pointing className='link item' placeholder='Bestelling' selection options={bestelBronnen} value={b.supplier} onChange={(e,d) => this.onChangeBestellingen(i, 'supplier', d.value)} />
                        </Table.Cell>
                        <Table.Cell>
                            <Input fluid value={b.supplierOrderNumber} onChange={(e, { name, value }) => this.onChangeBestellingen(i, 'supplierOrderNumber', value)}/>
                        </Table.Cell>
                        <Table.Cell>
                            {b.orderPlaced ? formatDateTime(b.orderPlaced) : ''}
                        </Table.Cell>
                        <Table.Cell>
                            <div>{b.status ? b.status : "Geen status"} <Button basic floated='right' icon='remove' title='Verwijderen' onClick={() => this.onRemoveBestellingen(i)} /></div>
                        </Table.Cell>
                    </Table.Row>
                )}

            </Table.Body>

            <Table.Footer>
                <Table.Row>
                    <Table.HeaderCell colSpan='3'>
                        <Button floated='right' title='Leverancier toevoegen' onClick={() => this.onAddBestellingen()}>toevoegen</Button>
                    </Table.HeaderCell>
                </Table.Row>
            </Table.Footer>
        </Table>
    }

    getPanes(dispatch, project) {

        let hasBomId= project.bomId !== null && project.bomId !== '';

        return [
            {
                menuItem: 'BOM Tool',
                render: () => <Tab.Pane attached={true} style={{padding: project.bomId ? 0 : ''}}>
                    {hasBomId ? '' : 'Vul aub het Bom Id in op de BOM ID pagina'}

                    {hasBomId ? <iframe title="BST" style={{width: '100%', height: '80vh'}} src={'https://bst.deltaproto.com?frame=true&id='+project.bomId}/> : '' }
                </Tab.Pane>,
            },
            {
                menuItem: 'BOM ID',
                render: () => <Tab.Pane attached={true}>
                    <div className='projectTabContent'>

                        <Form style={{maxWidth: 400}}>
                            <Form.Input label="Bom ID" value={project.bomId} onChange={(e) => dispatch(changeProjectValue('bomId', e.target.value))}/>

                            <Button primary={project.dirty} disabled={this.props.savingProject} onClick={() => this.props.dispatch(saveProject(this.props.project, null, () => {
                                this.onOpenBestellingen(project)
                            }))}>
                                <Translate value='save'/>
                            </Button>
                        </Form>
                    </div>
                </Tab.Pane>,
            },
            {
                menuItem: 'Bestelnummers',
                render: () => <Tab.Pane attached={true}>

                    {hasBomId ? '' : 'Vul aub het Bom Id in op de BOM ID pagina'}

                    {(!project.hasValidBomId || project.bestellingen.length) ? '' : <Button title='Test' onClick={() => this.onOpenBestellingen(this.props.project)}>Toepassen</Button>}

                    {(hasBomId && project.bestellingen.length) ? <div style={{marginTop: 16}}>
                        <p>{this.renderBestellingen(project)}</p>
                    </div> : ''}
                </Tab.Pane>

            }
        ]
    }

    /*renderPane() {

        let {dispatch, project}= this.props;
        let hasBomId= project != null && project.bomId !== null && project.bomId !== '';

        switch (this.state.activeTab) {
            default:
            case 0:
                return <div>
                    <BomMicroPlacer projectOrderId={project.id} />
                </div>;
            case 1:
                return <div>
                    {hasBomId ? '' : 'Vul aub het Bom Id in op de BOM ID pagina'}

                    {hasBomId ? <iframe title="BST" style={{width: '100%', height: '80vh'}} src={'https://bst.deltaproto.com?frame=true&id='+project.bomId}/> : '' }
                </div>;
            case 2:
                return <div>
                    <Form style={{maxWidth: 400}}>
                        <Form.Input label="Bom ID" value={project.bomId} onChange={(e) => dispatch(changeProjectValue('bomId', e.target.value))}/>

                        <Button primary={project.dirty} disabled={this.props.savingProject} onClick={() => this.props.dispatch(saveProject(this.props.project, null, () => {
                            this.onOpenBestellingen(project)
                        }))}>
                            <Translate value='save'/>
                        </Button>
                    </Form>
                </div>;
            case 3:
                return <div>
                    {hasBomId ? '' : 'Vul aub het Bom Id in op de BOM ID pagina'}

                    {(!project.hasValidBomId || project.bestellingen.length) ? '' : <Button title='Test' onClick={() => this.onOpenBestellingen(this.props.project)}>Toepassen</Button>}

                    {(hasBomId && project.bestellingen.length) ? <div style={{marginTop: 16}}>
                        <p>{this.renderBestellingen(project)}</p>
                    </div> : ''}
                </div>;

        }
    }*/

    renderChat() {
        return <BasicSheet header={'Berichten'}>
            <ChatView chatId={this.props.project.chat} showInput={false} isPageView={false} />

            <div style={{textAlign: 'right'}}>
                <Button primary onClick={() => this.props.dispatch(openChatModal(true))}>
                    <Translate value={'post_new_chat'}/>
                </Button>
            </div>

        </BasicSheet>
    }

    render() {

        let {dispatch, project}= this.props;

        if( !this.props.project ) {
            return <div/>
        }

        const admin = isEmployee(this.props.currentUser);

        if( !admin ) {
            return <div style={{marginTop: 16}}>
                <BasicProjectMenu
                    menu={[
                        {
                            name: 'bom',
                            item: <>
                                BOM
                                {(this.props.project && this.props.project.bomStats && this.props.project.bomStats.markedLines) ?
                                    <div className='notification-counter'>{this.props.project.bomStats.markedLines}</div> : ''}
                            </>
                        }
                    ]}
                    menuRight={[
                        {
                            name: 'chat',
                            item: 'Berichten'
                        }
                    ]}
                    activeName={this.state.activeName}
                    onTabChange={(name) => this.setState({activeName: name})}
                />
                {{
                    'bom':
                        <div className='fullwidthprojectview' style={{marginTop: 8}}>
                            <div className={'almostfullwidthprojectview'}>
                                <BomMicroPlacer projectOrderId={project.id} defaultViewId={'Checkin'} locked={project.order === 'ready'}/>
                            </div>
                        </div>,
                    'chat': this.renderChat()


                }[this.state.activeName]}

            </div>
        }

        return <div id='buy' style={{marginTop: 16}}>
            {admin && <SideBar/>}

            <BasicProjectMenu
                menu={[
                    {name: 'bom', item: 'BOM'},
                    {name: 'orders', item: 'Orders', bstStep: 'Orders'},
                ]}
                menuRight={admin ? [
                    {
                        name: 'chat',
                        item: 'Chat'
                    }
                ] : []}
                activeName={this.state.activeName}
                onTabChange={(name) => {
                    if( name === 'orders' ) {
                        if( this.state.activeName !== 'bom' && this.state.activeName !== 'bomproductie' ) {
                            this.setState({activeName: 'bom'})
                        }
                        this.props.dispatch(openBomOrder(true))
                        if( this.props.print ) {
                            this.props.dispatch(getBomOrders(this.props.print.id))
                        }
                    } else {
                        this.setState({activeName: name})
                    }
                }}
                sideBar={true}
            />

            {
                {
                    'bom':
                        <div className='fullwidthprojectview' style={{marginTop: 8}}>
                            <div className={'almostfullwidthprojectview'}>
                                <BomMicroPlacer projectOrderId={project.id} defaultViewId={'Checkin'}/>
                            </div>
                        </div>,
                    'chat': <BasicSheet header='Chat'>
                        <ChatView chatId={this.props.project.chat} showInput={true} isPageView={false}/>
                    </BasicSheet>
                }[this.state.activeName]
            }

        </div>

    }
}

const mapStateToProps = (state) => {
    return {
        currentUser: state.main.currentUser,
        lang: state.main.lang,
        currentLang: state.main.currentLang,
        project: state.project.project,
        orderList: state.project.orderList
    }
}

export default connect(mapStateToProps)(ProjectStepBuyParts)