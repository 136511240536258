import React, { Component } from 'react';
import './App.css';
import HomePrice from './HomePrice';
import Suppliers from './Suppliers';
import Steps from './Steps';
import HomeTiles from './HomeTiles';
import Carousel from "./HomeCarousel";

class Home extends Component {
    render() {
        return (
            <main className={'notCardSystem'}>
                <HomePrice/>
                <HomeTiles/>
                <Suppliers/>
                <Carousel/>
                <Steps/>
            </main>
        );
    }
}

export default Home;