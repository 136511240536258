import React, { Component } from 'react';
import './App.css';
import {Carousel} from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Translate from "./Translate";
import Link from "./Link";

class HomeCarousel extends Component {
    render() {
        return (
            <section id={'reviewsContainer'}>

                <h2 className='heading2'><Translate value={'tiles.testimonials'}/></h2>

                <Carousel showArrows={true} showThumbs={false} useKeyboardArrows={true} autoPlay={true} emulateTouch={true} stopOnHover={true} infiniteLoop={true} interval={3000}>
                    <div className='deltaprotocarousel '>
                        <img alt="review from TSR" style={{maxWidth: 800}} src={'images/reviews/Jeroen.png'}/>
                    </div>
                    <div className='deltaprotocarousel '>
                        <img alt="review from cremer" style={{maxWidth: 800}} src={'images/reviews/cremer.png'}/>
                    </div>
                    <div className='deltaprotocarousel '>
                        <img alt="review from quooker" style={{maxWidth: 800}} src={'images/reviews/quooker.png'}/>
                    </div>
                    <div className='deltaprotocarousel '>
                        <img alt="review from orbis" style={{maxWidth: 800}} src={'images/reviews/orbis.png'}/>
                    </div>
                    {/*<div className='deltaprotocarousel '>*/}
                    {/*    <img alt="review from vention" style={{maxWidth: 800}} src={'images/reviews/vention.png'}/>*/}
                    {/*</div>*/}
                    <div className='deltaprotocarousel '>
                        <img alt="review from andrew" style={{maxWidth: 800}} src={'images/reviews/andrew.png'}/>
                    </div>
                    <div className='deltaprotocarousel '>
                        <img alt="review from bart" style={{maxWidth: 800}} src={'images/reviews/bart.png'}/>
                    </div>
                    <div className='deltaprotocarousel '>
                        <img alt="review from frank" style={{maxWidth: 400}} src={'images/reviews/frank.jpg'}/>
                    </div>
                    <div className='deltaprotocarousel '>
                        <img alt="reviews on Google" style={{maxWidth: 600}} src={'images/reviews/google.png'}/><br/><br/>
                        <Link href="https://goo.gl/maps/NBg9EnsVwD1EcbKN9">Google maps</Link><br/><br/>
                    </div>
                    <div className='deltaprotocarousel '>
                        <img alt="review from marcel" style={{maxWidth: 800}} src={'images/reviews/marcel.png'}/>
                    </div>
                    <div className='deltaprotocarousel'>
                        <img alt="review from birdcontrol" className={'carouselshadow'} style={{maxWidth: 500}} src={'images/reviews/birdcontrol.png'}/>
                    </div>
                    <div className='deltaprotocarousel '>
                        <img alt="review from michiel" style={{maxWidth: 800}} src={'images/reviews/michiel.png'}/>
                    </div>
                    <div className='deltaprotocarousel '>
                        <img alt="review from johan" style={{maxWidth: 600}} src={'images/reviews/johan.png'}/>
                    </div>
                    <div className='deltaprotocarousel '>
                        <img alt="review from marcelm" style={{maxWidth: 800}} src={'images/reviews/marcelm.png'}/>
                    </div>
                </Carousel>
            </section>
        );
    }
}

export default HomeCarousel;