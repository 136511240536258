import React, {Component, lazy} from "react";
import {Link} from "react-router-dom";
import {Modal} from "semantic-ui-react";
import ReactMarkdown from "react-markdown";
import PropTypes from "prop-types";
import connect from "react-redux/es/connect/connect";
import {getText} from "./Translate";

const MarkdownRender = lazy(() => import('./MarkdownRender'));

class HomeTilesLine extends Component {

    handleScrollTo = () => {
        var myElement = document.getElementById(this.props.scrollTo);
        if( myElement ) {
            var topPos = myElement.offsetTop - 50;
            //window.scrollTo(0, topPos)
            window.scrollTo({
                'behavior': 'smooth',
                'left': 0,
                'top': topPos
            })
        }
    }

    render() {

        let title= getText(this.props, 'tiles.' + this.props.itemHeading);
        let body= getText(this.props, 'tiles.' + this.props.itemHeading +'.info');

        return <div className={'homeTile'}>

            {this.props.scrollTo ?
                <div className='linkWhite' onClick={this.handleScrollTo}>
                    <div className={'subtitle1 ' + this.props.className ? this.props.className : ''}>{title}</div>
                </div>
                : this.props.linkTo ?
                    <div style={this.props.video ? {position: 'relative'} : {}}>
                <Link to={this.props.linkTo} className = {'subtitle1 linkWhite'}>

                    { this.props.video ?
                        <div style={{position: 'relative', left: 0, top: 0, backgroundColor: '#ff000000', zIndex: 2}}>
                            Video
                        </div>
                     :  this.props.img ?
                        <>
                            <img alt={this.props.imgAlt} src={this.props.img} style={{width: '100%'}}/>
                            <div style={{position: 'relative', left: 0, top: -210, backgroundColor: '#ff000000'}}>
                                {this.props.imgAlt}
                            </div>
                        </>
                     :
                        <div className={'subtitle1 ' + this.props.className ? this.props.className : ''}>{title}</div>}

                </Link>
                        {this.props.video &&
                        <video autoPlay loop muted playsInline style={{width: '100%', position: 'absolute', left: 0, top: 0}}>
                            <source src={this.props.video} type="video/mp4"/>
                        </video>
                        }
                    </div>:
                <Modal
                    size={'large'}
                    trigger={<div className={'subtitle1 ' + this.props.className ? this.props.className : ''}>{title}</div>}
                    closeIcon
                >
                    <Modal.Header>{title}</Modal.Header>
                    <Modal.Content>

                        {/*<ReactMarkdown>
                            {body ? body : ''} //TODO: Move to new version of react-markdown!
                        </ReactMarkdown>*/}

                        {/*<ReactMarkdown rehypePlugins={[rehypeRaw]} children={body ? body : ''} renderers={{image: MyImage}} />*/}

                        {body ? <MarkdownRender children={body}/> : null}

                    </Modal.Content>

                </Modal>

            }
        </div>;
    }
}

HomeTilesLine.propTypes = {
    itemHeading: PropTypes.string,
    scrollTo: PropTypes.string,
    linkTo: PropTypes.string,
    img: PropTypes.object,
};

HomeTilesLine.defaultProps = {
    itemHeading: 'Default heading',
    scrollTo: null,
    linkTo: null,
    img: null,
};

const mapStateToProps = (state) => {
    return {
        lang: state.main.lang,
        currentLang: state.main.currentLang
    }
}

export default connect(mapStateToProps)(HomeTilesLine)
