import React from 'react';
import connect from "react-redux/es/connect/connect";
import {Dropdown, Icon, Label} from "semantic-ui-react";
import {
    addDeltaTableFilter,
    addDeltaTableSort,
    addSharketingFilter,
    addSharketingSort, removeDeltaTableFilter,
    removeDeltaTableSort,
    removeSharketingSort
} from "../actions";
import {remove} from "lodash/array";
import PropTypes from "prop-types";

class DeltaFilterAndSort extends React.Component {

    getOptions(columns, filter) {
        return columns.find(c => c.filter === filter).filterOptions
    }

    onChange() {
        if( this.props.onSortAndFilter ) {
            this.props.onSortAndFilter(this.props.value)
        }
    }

    render() {
        const {value, headers, name, columns} = this.props;
        return (
            <div style={{marginBottom: value.length ? 8 : 0}}>
                {value.map(s => {
                    if( s.sort ) {
                        return <Label color='blue' key={s.id}>
                                {s.sort === 'descending' ? <Icon name='sort down'/> : <Icon name='sort up'/>}
                                <button className='link-button' style={{color: 'white'}}
                                        onClick={() => {
                                            this.props.dispatch(addDeltaTableSort(name, s.column)).then(() => {
                                                this.onChange()
                                            })
                                        }}>
                                    {s.header}
                                </button>

                                <Icon name='close' link onClick={() => {
                                    this.props.dispatch(removeDeltaTableSort(name, s.id)).then(() => {
                                        this.onChange()
                                    })
                                }}/>
                            </Label>
                    } else {
                        return <Label color='blue' key={s.id}>

                                <Icon name='filter'/>

                                {s.header + " "}


                                <span color='white' style={{backgroundColor: 'white', color: 'black', paddingLeft: 2, paddingRight: 2, borderRadius: '.14rem'}}>
                                    <Dropdown search value={s.filter} options={this.getOptions(columns, s.column)} onChange={(e,d) => {
                                        this.props.dispatch(addDeltaTableFilter(name, s.column, d.value)).then(() => {
                                            this.onChange()
                                        })
                                    }}/>
                                </span>

                                <Icon name='close' link onClick={() => {
                                    this.props.dispatch(removeDeltaTableFilter(name, s.id)).then(() => {
                                        this.onChange()
                                    })
                                }}/>
                            </Label>
                    }

                })}
            </div>
        );
    }
}

DeltaFilterAndSort.propTypes = {
    onSortAndFilter: PropTypes.func
}

const mapStateToProps = (state) => {
    return {
        lang: state.main.lang,
        currentUser: state.main.currentUser,
    }
}

export default connect(mapStateToProps)(DeltaFilterAndSort)