import React from 'react'
import {Button, Checkbox, Dropdown, Header, Icon, Input, Loader, Modal, Table} from "semantic-ui-react";
import {getOrdersForRow, isPcbOrStencil, renderSupplier} from "./functions";
import {formatDateTime} from "../actions/functions";
import {createBomOrders, openBomOrder, openCheckinOrders, saveBomOrderItem} from "../actions";
import {orderStatus} from "./BomOrder";
import connect from "react-redux/es/connect/connect";

class CheckinOrders extends React.Component {

    getNotSelectedOrders(list) {

        if(list == null) {
            return []
        }

        list = list.filter(o => o.items != null)

        let res = list.map(o => ({...o, items: o.items.filter(item => {

            let isSelected = this.props.print.bom.some(r => getOrdersForRow(r, [{items: [item]}]).length > 0 )
            return !isSelected;

        })}))

        return res;
    }

    renderPartOrders(list) {

        let notselected = this.getNotSelectedOrders(list)

        return <div style={{marginTop: 24}}>

            <Header>Parts</Header>

            {this.props.bomOrderListBusy && <Loader active />}

            <p>
                {list === 0 && <p>There are no orders yet</p>}

                {/*{JSON.stringify(notselected)}*/}

                <Table compact>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>Supplier</Table.HeaderCell>
                            <Table.HeaderCell>Order code</Table.HeaderCell>
                            <Table.HeaderCell>Status</Table.HeaderCell>
                            <Table.HeaderCell>SKU</Table.HeaderCell>
                            <Table.HeaderCell>boardLocation</Table.HeaderCell>
                            <Table.HeaderCell>orderAmount</Table.HeaderCell>
                            <Table.HeaderCell>stock</Table.HeaderCell>
                            <Table.HeaderCell>vervallen</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>

                    <Table.Body>
                        {notselected.map(o => o.items.map(i => {
                            return <Table.Row disabled={i.busy} key={i.id}>
                                <Table.Cell>
                                    {i.part ? renderSupplier({supplier: i.part.supplier, sku: ''}) : ''}
                                    {i.kla ?
                                        i.kla.manualselection ? renderSupplier({supplier: 'MANUAL', sku: ''}) :  renderSupplier({supplier: 'KLANT', sku: ''})
                                        : ''}
                                </Table.Cell>
                                <Table.Cell>
                                    {o.supplierOrderCode}
                                </Table.Cell>
                                <Table.Cell>
                                    <Dropdown value={o.status} options={orderStatus} disabled={true}/>
                                </Table.Cell>
                                <Table.Cell>
                                    {i.part ? i.part.sku : ''}
                                    {i.klant ? i.klant.sku : ''}
                                </Table.Cell>
                                <Table.Cell>{i.boardLocation}</Table.Cell>
                                <Table.Cell>{i.orderAmount}</Table.Cell>
                                <Table.Cell>{i.stock}</Table.Cell>
                                <Table.Cell>
                                    <Checkbox checked={i.expired} disabled={true} onChange={(e) => this.props.dispatch(saveBomOrderItem({...i, expired: (!i.expired)}, o.id))}/>
                                </Table.Cell>
                            </Table.Row>
                        }))}
                    </Table.Body>
                </Table>

            </p>
        </div>
    }

    render() {
        if( !this.props.showCheckinOrders ) {
            return <></>
        }

        return (
            <div>
                <Modal size={'large'} open={this.props.showCheckinOrders} closeIcon onClose={() => this.props.dispatch(openCheckinOrders(false))}>
                    <Header icon='trash' content={'Ongeselecteerde bestellingen (' + (this.props.print ? this.props.print.name : '') + ')'} />
                    <Modal.Content scrolling>

                        {this.renderPartOrders(this.props.bomOrderList.filter((o) => ! isPcbOrStencil(o)))}

                    </Modal.Content>
                    <Modal.Actions>
                        <Button onClick={() => this.props.dispatch(openCheckinOrders(false))}>
                            <Icon name='close' /> Close
                        </Button>
                    </Modal.Actions>
                </Modal>
            </div>
        );
    }
}


const mapStateToProps = (state) => {
    return {
        lang: state.main.lang,
        print: state.bom.print,
        showBomOrder: state.bom.showBomOrder,
        bomOrderList: state.bom.bomOrderList,
        bomOrderListBusy: state.bom.bomOrderListBusy,
        creatingBomOrders: state.bom.creatingBomOrders,
        removingBomOrders: state.bom.removingBomOrders,
        suppliers: state.bom.suppliers,
        showCheckinOrders: state.bom.showCheckinOrders,
    }
}

export default connect(mapStateToProps)(CheckinOrders)