import React from 'react'
import PropTypes from 'prop-types'
import {Segment, Button, Search, Icon, Header, Input} from 'semantic-ui-react'
import {setBomSearch, setBomSearchRef} from "../actions";

class BasicSheet extends React.Component {
    render() {
        const {disabled} = this.props;

        return <Segment.Group>

            <Segment color='blue' inverted style={{display: 'flex', padding: 6}} disabled={disabled}>
                <div style={{fontSize: '18px', flex: '1', lineHeight: '36px', paddingLeft: 8}}>
                    {this.props.header}
                </div>
                <div style={{display: 'flex', textAlign: 'right', gap: 8}}>

                    <div style={{lineHeight: '36px', paddingRight: 8, color: 'white'}}>
                        {this.props.headerRight}
                    </div>

                    {this.props.hasSearch ?  <Input
                        placeholder='Find'
                        className={"checkin-search"}
                        icon='search'
                        clearable
                        value={this.props.bomSearch}
                        ref={(input) => {
                            if( this.props.searchRef ) {
                                this.props.searchRef(input)
                            }
                        }}
                        onChange={(e,d) => {
                            if( this.props.onSearchChange ) {
                                this.props.onSearchChange(e.target.value)
                            }
                        }}
                        onKeyPress={(e) => {
                            if(e.key === 'Enter') {
                                console.log('Enter..')
                            }
                        }}
                        onKeyDown={(e) => {
                            console.log(e.keyCode)
                        }}
                    /> : ''}
                    {this.props.hasMenu ? <Button icon primary onClick={() => {
                        if( this.props.onMenuClick ) {
                            this.props.onMenuClick()
                        }
                    }}>
                        <Icon name='bars'/>
                    </Button> : ''}
                </div>
            </Segment>
            {this.props.actionMenu !== null && <Segment style={{display: 'flex', gap: 8}}>
                {this.props.actionMenu}
            </Segment>}
            <Segment>
                {this.props.children}
            </Segment>
            {this.props.actionMenuBottom !== null && <Segment style={{display: 'flex', flexDirection: 'row-reverse', gap: 8}}>
                {this.props.actionMenuBottom}
            </Segment>}
        </Segment.Group>
    }
}

BasicSheet.protoTypes = {
    header: PropTypes.string,
    disabled: PropTypes.bool,
    headerRight: PropTypes.object,
    hasSearch: PropTypes.bool,
    hasMenu: PropTypes.bool,
    onSearch: PropTypes.func,
    actionMenu: PropTypes.object,
    actionMenuBottom: PropTypes.object,
    searchRef: PropTypes.func,
    onSearchChange: PropTypes.func
}

BasicSheet.defaultProps = {
    header: 'Insert header',
    disabled: false,
    headerRight: '',
    hasSearch: false,
    hasMenu: false,
    actionMenu: null,
    actionMenuBottom: null
}

export default BasicSheet
