import React, {Component} from 'react';
import './App.css';
import connect from "react-redux/es/connect/connect";
import ProjectEventView from "./oldui/ProjectEventView";
import {isEmployee} from "./actions/functions";
import {
    fetchProjectEvents, getBomOrders, getProductionRelease, getProjectOrderPcbInfo, openBomOrder, openChatModal
} from "./actions";
import Translate from "./Translate";
import BasicSheet from "./basic/BasicSheet";
import BasicProjectMenu from "./basic/BasicProjectMenu";
import ChatView from "./chat/ChatView";
import SideBar from "./bom/SideBar";
import PropTypes from "prop-types";
import ProtoFotoView from "./ProtoFotoView";
import BomMicroPlacer from "./bom/BomMicroPlacer";
import ProjectListOfChange from "./ProjectListOfChange";
import {Button} from "semantic-ui-react";

class ProjectStepSamplePCBA extends Component{

    state = {
        activeName: 'loc'
    }

    componentDidMount() {
        if (this.props.project) {
            this.props.dispatch(fetchProjectEvents(this.props.project.id));
            this.props.dispatch(getProductionRelease(this.props.project.id));
            this.props.dispatch(getProjectOrderPcbInfo(this.props.project.id));
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if ((!prevProps.project && this.props.project) ||
            (prevProps.project && this.props.project && prevProps.project.id !== this.props.project.id)
        ) {
            this.props.dispatch(fetchProjectEvents(this.props.project.id));
            this.props.dispatch(getProductionRelease(this.props.project.id));
            this.props.dispatch(getProjectOrderPcbInfo(this.props.project.id));
        }
    }

    renderPane(dispatch, project) {

        switch (this.state.activeName) {
            default:
            case 'loc':
                return this.renderVrijgave(dispatch, project)
            case 'packingslip':
                return <div>
                    <ProjectEventView header={'Packingslip'} project={project} projectId={project.id} packingslip={true}/>
                </div>;
            case 'invoice':
                return <div>
                    <ProjectEventView header={'Invoice'} project={project} projectId={project.id} invoice={true}/>
                </div>
            case 'chat':
                return <BasicSheet header='Chat'>
                    <ChatView chatId={this.props.project.chat} showInput={true} isPageView={false}/>
                </BasicSheet>
            case 'protofoto':
                return <div style={{marginTop: -16}}>
                    <ProtoFotoView/>
                </div>
            case 'bom':
                return <div className='fullwidthprojectview' style={{marginTop: -8}}>
                    <div className={'almostfullwidthprojectview'}>
                        <BomMicroPlacer projectOrderId={project.id} view={'bom'} locked={project.order === 'ready'}/>
                    </div>
                </div>
            case 'bomproductie':
                return <div className='fullwidthprojectview' style={{marginTop: -8}}>
                    <div className={'almostfullwidthprojectview'}>
                        <BomMicroPlacer projectOrderId={project.id} view={'production'} locked={project.order === 'ready'}/>
                    </div>
                </div>

        }
    }

    renderPaneUser(dispatch, project) {

        switch (this.state.tab) {
            default:
            case 0:
                return this.renderVrijgave(dispatch, project);
        }
    }

    renderVrijgave(dispatch, project) {

        return <ProjectListOfChange/>
    }

    renderChat() {
        return <BasicSheet header={'Berichten'}>
            <ChatView chatId={this.props.project.chat} showInput={false} isPageView={false} />

            <div style={{textAlign: 'right'}}>
                <Button primary onClick={() => this.props.dispatch(openChatModal(true))}>
                    <Translate value={'post_new_chat'}/>
                </Button>
            </div>

        </BasicSheet>
    }

    render() {
        let { dispatch, project }= this.props;

        if( !project ) {
            return <div/>
        }

        if( !isEmployee(this.props.currentUser) ) {
            return <div style={{paddingTop: 16, textAlign: 'left'}}>
                <BasicProjectMenu
                    menu={[
                        {
                            name: 'loc',
                            item: <>
                                LoC
                            </>
                        }
                    ]}
                    menuRight={[
                        {
                            name: 'chat',
                            item: 'Berichten'
                        }
                    ]}
                    activeName={this.state.activeName}
                    onTabChange={(name) => this.setState({activeName: name})}
                />
                {{
                    'loc': <div style={{marginTop: 16}}>
                        {this.renderPaneUser(dispatch, project)}
                    </div>,
                    'chat': this.renderChat()
                }[this.state.activeName]}

            </div>
        }

        return (
            <div style={{paddingTop: 16, textAlign: 'left'}}>

                <SideBar/>

                <BasicProjectMenu
                    menu={[
                        {name: 'bom', item: 'BOM'},
                        {name: 'orders', item: 'Orders', bstStep: 'Orders'},
                        {name: 'bomproductie', item: 'Productie', bstStep: 'Productie'},
                        {name: 'protofoto', item: 'ProtoFoto'},
                        {name: 'loc', item: <Translate value='production.release'/>},
                        {name: 'packingslip', item: 'Packingslip'},
                        {name: 'invoice', item: 'Invoice'},
                    ]}
                    menuRight={[
                        { name: 'chat', item: 'Chat'}
                    ]}
                    activeName={this.state.activeName}
                    onTabChange={(name) => {
                        if( name === 'orders' ) {
                            if( this.state.activeName !== 'bom' && this.state.activeName !== 'bomproductie' ) {
                                this.setState({activeName: 'bom'})
                            }
                            this.props.dispatch(openBomOrder(true))
                            if( this.props.print ) {
                                this.props.dispatch(getBomOrders(this.props.print.id))
                            }
                        } else {
                            this.setState({activeName: name})
                        }
                    }}
                    sideBar={true}
                />


                {/*<TabHeader panes={[<Translate value='production.release'/>, 'Packingslip', 'Invoice']} activeIndex={this.state.activeTab}
                           onChange={(value) => this.setState({activeTab: value})}
                />*/}
                {/*<TabMenu/>*/}
                <div style={{marginTop: 16}}>
                    {this.renderPane(dispatch, project)}
                </div>
            </div>
        )
    }
}

ProjectStepSamplePCBA.propTypes = {
    sampleNr: PropTypes.string,
    status: PropTypes.string,
    isSerie: PropTypes.bool
};

ProjectStepSamplePCBA.defaultProps = {
    sampleNr: '',
    status: '',
    isSerie: false
};

const mapStateToProps = (state) => {
    return {
        lang: state.main.lang,
        currentLang: state.main.currentLang,
        currentUser: state.main.currentUser,
        pcbs: state.project.pcbs,
        project: state.project.project,
        releaseProduction: state.project.releaseProduction,
        errorProductionRelease: state.project.errorProductionRelease,
        savingProductionRelease: state.project.savingProductionRelease,
    }
}

export default connect(mapStateToProps)(ProjectStepSamplePCBA)