import React, { Component } from 'react';
import connect from "react-redux/es/connect/connect";
import {confirmRegistration, validateLogin} from "./actions";
import Translate from "./Translate";
import {withRouter} from "react-router-dom";

class RegistrationConfirm extends Component {

    componentDidMount() {
        let id= this.props.match.params.id;

        this.props.dispatch(confirmRegistration(id, () => {
            this.props.dispatch(validateLogin())

            setTimeout(() => {
                this.props.history.push('/calculator/')
            }, 3000);
        }))
    }

    render() {

        let {confirmingOk, confirmingBusy, confirmingFailed}= this.props;

        return (
            <section className='pageContainer'>

                {confirmingOk ? <Translate value='login_thankyou'/> : ''}

                {confirmingBusy ? <Translate value='moment_please'/> : ''}

                {confirmingFailed ? <Translate value='auth.message.expired'/> : ''}

            </section>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        confirmingOk: state.main.confirmingOk,
        confirmingBusy: state.main.confirmingBusy,
        confirmingFailed: state.main.confirmingFailed,
    }
}

export default connect(mapStateToProps)(withRouter(RegistrationConfirm))
