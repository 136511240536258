import React from "react";
import PropTypes from "prop-types";

export class DeltaTableCell extends React.Component<{}> {
    render() {
        return <div>
            <div className='cornertopright'>
                <div className='btitem'>{this.props.r1}</div>
                <div className='btitem'>{this.props.r2}</div>
                <div className='btitem'>{this.props.r3}</div>
            </div>

            <div className='btitem'>{this.props.l1}</div>
            <div className='btitem'>{this.props.l2}</div>
            <div className='btitem'>{this.props.l3}</div>
        </div>;
    }
}

DeltaTableCell.propTypes = {
    'l1': PropTypes.any,
    'l2': PropTypes.any,
    'l3': PropTypes.any,
    'r1': PropTypes.any,
    'r2': PropTypes.any,
    'r3': PropTypes.any,
}

DeltaTableCell.defaultProps = {

}