import React from 'react';
import connect from "react-redux/es/connect/connect";
import {Popup, Icon} from "semantic-ui-react";
import PropTypes from "prop-types";
import {userDisplayName} from "../oldui/AccountListItem";
import {formatDateTime} from "../actions/functions";

class ChatUserView extends React.Component {

    state = {

    }

    componentDidMount() {

    }

    getUserById(id) {
    }

    render() {

        const user = this.props.user

        if( !user ) {
            return <>...</>
        }

        let color = user.email.endsWith('@deltaproto.com') ? 'black' : 'blue'

        if( this.props.type === 'name' ) {
            return <div style={{lineHeight: '21px'}}>

                {userDisplayName(user)}
                {' '}
                {formatDateTime(this.props.date)}

            </div>
        }
        else if( this.props.type === 'icon' ) {
            return <>
                <Popup trigger={
                    <Icon name='user' color={color} size=''/>
                } content={<p style={{lineHeight: '21px'}}>
                    <div>{userDisplayName(user)}</div>
                    <div>{formatDateTime(this.props.date)}</div>
                </p>}/>
            </>
        }
    }
}

ChatUserView.propTypes = {
    user: PropTypes.object,
    type: PropTypes.oneOf(['name', 'icon']),
    date: PropTypes.number
};

ChatUserView.defaultProps = {
    user: null,
    type: 'name',
    date: null
};

const mapStateToProps = (state) => {
    return {
        users: state.chat.users
    }
}

export default connect(mapStateToProps)(ChatUserView)