import React, {Component} from "react";
import {autoUpdateStockAndPrice} from "../actions";
import {Icon, Popup} from "semantic-ui-react";
import {connect} from "react-redux";

class BomUpdateStockForProject extends Component {
    render() {
        return (
            <Popup trigger={

                <Icon link loading={false} name={'check'}
                      onClick={() => this.props.dispatch(autoUpdateStockAndPrice(this.props.print.id))}/>
            } content={'Update voorraad informatie'} position='bottom center'/>
        );
    }
}


const mapStateToProps = (state) => {
    return {
        print: state.bom.print,
    }


}

export default connect(mapStateToProps)(BomUpdateStockForProject)