import React from 'react'
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {Button, Divider, Dropdown, Form, Header, Icon, Modal, Search} from "semantic-ui-react";
import {
    addMpn,
    addSku,
    clearImportFromMpn,
    getManufacturers,
    getSuppliers,
    importFromMpn, openOrderPartAdd,
    searchPartByMpn,
    setOrderPartAdd
} from "../actions";
import BomOrderparts from "./BomOrderparts";
import BomOrderpartList from "./BomOrderpartList";
import {renderSupplier} from "./functions";

class BomAddPart extends React.Component {

    state = {
        open: false,
        newMpn: {
            mpn: ''
        },
        newSku: {
            moq: 1,
            price1: 0.01,
            break1: 1
        }
    }

    componentDidMount() {
        this.props.dispatch(clearImportFromMpn())
        this.props.dispatch(getSuppliers())
        this.props.dispatch(getManufacturers())
    }

    handleSearchChange = (e, data) => {
        let mpn = data.value;
        //this.props.dispatch(searchPartByMpn(mpn, false))

        this.props.dispatch(importFromMpn(mpn, false))

        this.setState({newValue: {
            ...this.state.newValue, mpn
        }})
    }

    setMpnValue = (name, value) => {
        this.setState({
            newMpn: {...this.state.newMpn, [name]: value}
        })
    }

    setSkuValue = (name, value) => {
        this.setState({
            newSku: {...this.state.newSku, [name]: value}
        })
    }

    render() {

        if( !this.props.orderPartAddOpen ) {
            return <></>
        }

        let selected  = this.props.orderPart ? this.props.orderPart : this.props.orderPartAdd;
        let newSku = this.state.newSku
        let newMpn = this.state.newMpn

        let disabled = selected == null || !selected.id;

        return (
            <div>

                <Modal open={true}>
                    <Modal.Header>
                        Add supplier to part
                    </Modal.Header>
                    <Modal.Content>

                        <Form>
                            <div style={{display:'flex', gap: 8}}>

                                <Form.Input
                                    label="MPN"
                                    placeholder="Zoek eerst een MPN"
                                    loading={this.props.importing}
                                    onBlur={(e) => {
                                        if( e.target.value ) {
                                            this.props.dispatch(importFromMpn(e.target.value, true, () => {
                                                this.setState({searched: true})
                                            }))
                                        }
                                    }}
                                    onKeyPress={(e) => {
                                        if(e.key === 'Enter'){
                                            this.props.dispatch(importFromMpn(e.target.value, true, () => {
                                                this.setState({searched: true})
                                            }))
                                            e.preventDefault();
                                        }
                                    }}
                                    disabled={selected}
                                    value={selected ? selected.mpn : newMpn.mpn}
                                    onChange={(e) => {
                                        this.setState({newValue: {
                                            ...this.state.newValue, mpn: e.target.value
                                        }})
                                        this.setMpnValue('mpn', e.target.value)
                                    }}
                                />

                                <Form.Dropdown
                                    selection
                                    search
                                    deburr
                                    disabled={selected}
                                    label={'Manufacturer'}
                                    value={selected ? selected.manufacturer : newMpn.manufacturer}
                                    options={this.props.manufacturers.map(s => ({
                                        key: s,
                                        text: s,
                                        value: s
                                    }))}
                                    onChange={(e,d) => this.setMpnValue('manufacturer', d.value)}
                                />
                                <div style={{flexGrow: 1}}>
                                    <Form.Input
                                        disabled={selected}
                                        value={selected ? selected.shortDescription : newMpn.shortDescription}
                                        fluid={true}
                                        label={'Part omschrijving'}
                                        onChange={e => this.setMpnValue('shortDescription', e.target.value)}
                                    />
                                </div>
                            </div>

                            {!selected && <div>

                                {(this.state.searched && this.props.orderpartsImport && this.props.orderpartsImport.length === 0) && <div>
                                    Geen bestaande parts gevonden in de database
                                </div>}

                                {(this.props.orderpartsImport && this.props.orderpartsImport.length > 0) && <div>
                                    Gevonden parts:
                                    <ul>
                                        {this.props.orderpartsImport.map(o => <li>
                                            <button className='link-button' onClick={() => {
                                                //this.setState({selected: o})
                                                this.props.dispatch(setOrderPartAdd(o))

                                                if( o.manufacturer === 'Würth Elektronik' ) {

                                                    this.setState({
                                                        newSku: {...this.state.newSku,
                                                            sku: o.mpn,
                                                            supplier: 'WURTH'
                                                        }
                                                    })

                                                }

                                            }}>{o.mpn}</button> {o.manufacturer} {o.shortDescription}
                                        </li>)}
                                    </ul>
                                </div>}
                            </div>}

                            {(selected && selected.suppliers) && <div>
                                <Header>Suppliers:</Header>
                                {selected.suppliers.length === 0 && 'Geen suppliers voor dit part'}
                                {selected.suppliers.map(s => (<div>
                                    {renderSupplier(s)}
                                </div>))}
                            </div>}

                            <div style={{textAlign: "right"}}>
                                <Button
                                    primary
                                    disabled={selected || (!newMpn.mpn || !newMpn.manufacturer || !newMpn.shortDescription) }
                                    onClick={() => this.props.dispatch(addMpn(newMpn))}
                                >Part aanmaken</Button>
                            </div>
                        </Form>

                        <Divider/>

                        <Form autocomplete="off">

                            <Header>Add supplier:</Header>

                            <div style={{display:'flex', gap: 8}}>
                                <Form.Dropdown
                                    selection
                                    disabled={disabled}
                                    label={'Supplier'}
                                    options={this.props.suppliers.map(s => ({
                                        key: s,
                                        text: renderSupplier({supplier: s, sku: s}),
                                        value: s
                                    }))}
                                    value={newSku.supplier}
                                    onChange={(e,d) => this.setSkuValue('supplier', d.value)}
                                />
                                <Form.Input
                                    disabled={disabled}
                                    label={'SKU'}
                                    value={newSku.sku}
                                    onChange={e => this.setSkuValue('sku', e.target.value)}
                                />
                                <Form.Input
                                    disabled={disabled}
                                    label={'MOQ'}
                                    value={newSku.moq}
                                    onChange={e => this.setSkuValue('moq', e.target.value)}
                                />
                            </div>
                            <div style={{display:'flex', gap: 8}}>
                                {[1,2,3,4,5,6,7,8,9].map(i => (<div style={{maxWidth: '10%'}}>
                                    <Form.Input
                                        disabled={disabled}
                                        label={'Prijs ' + i}
                                        name={'price'+i}
                                        type="number"
                                        step={0.01}
                                        min={0.01}
                                        value={newSku['price'+i]}
                                        onChange={e => this.setSkuValue('price'+i, e.target.value)}
                                    />
                                    <Form.Input
                                        disabled={disabled}
                                        label={'Break ' + i}
                                        name={'break'+i}
                                        type="number"
                                        step={1}
                                        min={1}
                                        value={newSku['break'+i]}
                                        onChange={e => this.setSkuValue('break'+i, e.target.value)}
                                    />
                                </div>))}
                            </div>

                        </Form>

                    </Modal.Content>
                    <Modal.Actions>
                        <Button onClick={() => this.props.dispatch(openOrderPartAdd(false))}>
                            Sluiten
                        </Button>
                        <Button primary disabled={selected == null || !selected.id || !newSku.supplier || !newSku.sku} onClick={() => {
                            this.props.dispatch(addSku(newSku, selected.id))
                        }}>
                            Supplier toevoegen
                        </Button>
                    </Modal.Actions>
                </Modal>



            </div>
        );
    }

}

BomAddPart.propTypes = {
    orderPart: PropTypes.object
};

BomAddPart.defaultProps = {
    orderPart: null
};

const mapStateToProps = (state) => {
    return {
        orderPartAdd: state.bom.orderPartAdd,
        orderPartAddOpen: state.bom.orderPartAddOpen,
        loading: state.bom.searching,
        searchList: state.bom.searchList,
        orderpartsImport: state.bom.orderpartsImport,
        importing: state.bom.importing,
        suppliers: state.bom.suppliers,
        manufacturers: state.bom.manufacturers,
    }
}

export default connect(mapStateToProps)(BomAddPart)