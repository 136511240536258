import React from 'react'
import connect from "react-redux/es/connect/connect";
import {withRouter} from "react-router-dom";
import PropTypes from "prop-types";
import DeltaTableHeader, {getCellStyle, getStyle} from "./DeltaTableHeader";
import {deltaTableSetColWidth, deltaTableSetResize, getChatOverviewByPage} from "../actions";
import OnVisible from "react-on-visible";
import {Button, Icon, Loader, Segment} from "semantic-ui-react";
import DeltaFilterAndSort from "./DeltaFilterAndSort";

export function getColumnName(name, c) {
    return name + c.id
}

const headerColor = '#f8f8f9';

class DeltaTable extends React.Component {

    state = {
        isResizing: false
    }

    componentDidMount() {
        window.addEventListener('mousemove', this.onMouseMove.bind(this), false);
        window.addEventListener('mouseup', this.onMouseUp.bind(this), false);
    }

    componentWillUnmount() {
        window.removeEventListener('mousemove', this.onMouseMove.bind(this), false);
        window.removeEventListener('mouseup', this.onMouseUp.bind(this), false);
    }

    onMouseMove = (e) => {
        const resize = this.props.resize;
        if( resize.isResizing ) {
            const newWidth =  resize.startWidth + (e.clientX - resize.startX);

            this.props.dispatch(deltaTableSetColWidth(resize.isResizing.name, resize.isResizing.id, newWidth));
        }
    }

    onMouseUp = (e) => {
        const resize = this.props.resize;
        if( resize && resize.isResizing ) {
            this.props.dispatch(deltaTableSetResize('isResizing', false))
        }
    }

    getColumns() {
        return this.props.columns
    }

    getRows() {
        return this.props.data
    }

    getSort() {
        if( this.props.sort && this.props.sort[this.props.name] ) {
            return this.props.sort[this.props.name]
        }
        return []
    }

    render() {
        const {key, name, maxHeight, showBottomIcon, inline} = this.props;

        const columns = this.getColumns();
        const sort = this.getSort();

        return <div style={{}}>
            <DeltaFilterAndSort name={name} columns={columns} value={sort} onSortAndFilter={(value) => {
                if (this.props.onSortAndFilter) {
                    this.props.onSortAndFilter(value)
                }
            }}/>

            <div className='deltatablediv' style={{height: maxHeight ? maxHeight : null}}>

                {inline ? this.renderTable(key, name, sort, columns) : <div style={{display: 'flex'}}>
                    <div style={{flex: 1}}></div>
                        {this.renderTable(key, name, sort, columns)}
                    <div style={{flex: 1}}></div>
                </div>}

                {(this.props.onLoadMore && !this.props.loading) ? <OnVisible bounce={true} onChange={(e) => {
                    if (this.props.onLoadMore) {
                        this.props.onLoadMore()
                    }
                }}/> : null}

                <div style={{height: 16, margin: 'auto', textAlign: 'center', paddingTop: 16}}>
                    {this.props.loading ?
                        <Loader active inline size='tiny'/> : showBottomIcon ?
                            <img src={'/logo.svg'} style={{maxHeight: 16}} alt='DeltaProto'/> : null}

                    {this.props.onAddRow ?
                        <Button disabled={this.props.addDisabled || this.props.loading} icon='add' labelPosition='left'
                                floated='left' onClick={() => this.props.onAddRow()}>
                            <Icon name='add'/>
                            Add
                        </Button> : null}
                </div>

            </div>

        </div>
    }

    renderTable(key, name, sort, columns) {
        return <table className="deltatable" key={this.props.key} style={{flex: 0}}>

            <thead>
            <tr key={key + 'tr'}>
                {this.getColumns().map(c => <DeltaTableHeader key={c.id} name={name} c={c} sort={sort}/>)}
            </tr>
            </thead>
            <tbody>
            {this.getRows().map((r, i) => <tr key={r.id}>
                {columns.map((c, j) => {
                    let style = getCellStyle(name, c, r, i, this.props.colwidth)
                    return <td key={toString(r.id) + c.id} style={style}>
                        {c.render(r, i)}
                    </td>
                })}
            </tr>)}
            </tbody>
        </table>;
    }
}

DeltaTable.propTypes = {
    name: PropTypes.string.isRequired,
    columns: PropTypes.array,
    data: PropTypes.any,
    onLoadMore: PropTypes.func,
    onAddRow: PropTypes.func,
    addDisabled: PropTypes.bool,
    loading: PropTypes.bool,
    maxHeight: PropTypes.string,
    onSortAndFilter: PropTypes.func,
    showBottomIcon: PropTypes.bool,
    inline: PropTypes.bool
}

DeltaTable.defaultProps = {
    name: '',
    columns: [],
    data: [],
    onVisible: null,
    loading: false,
    maxHeight: 'calc(100vh - 300px)',
    showBottomIcon: true,
    inline: false,
}

const mapStateToProps = (state) => {
    return {
        lang: state.main.lang,
        currentUser: state.main.currentUser,
        resize: state.deltatable.resize,
        colwidth: state.deltatable.colwidth,
        sort: state.deltatable.sort,
    }
}

export default connect(mapStateToProps)(withRouter(DeltaTable))