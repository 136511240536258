import React, { Component } from 'react';
import connect from "react-redux/es/connect/connect";
import Translate, {getText} from "./Translate";
import {Button, Form, Header, Message} from "semantic-ui-react";
import {changePasswordNew, savePassword, validate} from "./actions";
import {withRouter} from "react-router-dom";

class RegistrationReset extends Component {

    state= {
        password: '',
        matchingPassword: null,
    };

    componentDidMount() {
        let userId= this.props.match.params.userId;
        let token= this.props.match.params.token;

        console.log('Confirm registration for user ' + userId + ' with token' + token)

        this.setState({
            userId, token
        })

        if( userId && token ) {
            this.props.dispatch(changePasswordNew(userId, token))
        }
    }

    handleChange = (e, { name, value }) => {

        this.setState({ [name]: value })
    }

    render() {
        return (
            <main className='pageContainer'>

                {this.props.currentUserChangePassword ?
                    <div style={{textAlign: 'left', padding: 16}}>{'Hoi ' + this.props.currentUserChangePassword.firstName}</div> : ''}
                <Header><Translate value="message.resetPassword"/></Header>

                {this.props.changePasswordError && <Message
                    negative>
                    <Message.Header><Translate value="login_error"/></Message.Header>
                    <p><Translate value="login_error_token_verlopen"/></p>
                </Message>}

                {this.props.changePasswordError ? '' :
                <Form autocomplete="off" style={{maxWidth: '300px', textAlign: 'left', margin: 'auto'}}>
                    <Form.Field>
                        <Form.Input autoComplete="new-password" label={getText(this.props, "login_password")} name='password' type='password' placeholder={getText(this.props, "login_password_placeholder")} value={this.state.password} onChange={this.handleChange} disabled={this.props.setPasswordBusy}/>
                    </Form.Field>
                    <Form.Field>
                        <Form.Input autoComplete="new-password" label={getText(this.props, "login_password_validation")} name='matchingPassword' type='password' placeholder={getText(this.props, "login_password_placeholder_validation")} value={this.state.matchingPassword} error={this.state.matchingPassword != null && this.state.matchingPassword !== this.state.password} onChange={this.handleChange} disabled={this.props.setPasswordBusy}/>
                    </Form.Field>
                    <Button loading={this.props.savePasswordBusy} disabled={this.props.savePasswordBusy || this.props.savePasswordOk} onClick={() => {
                        this.props.dispatch(savePassword(this.state.password, this.state.token, () => {
                            this.props.dispatch(validate());
                        }))
                    }}><Translate value="submit"/></Button>

                    {this.props.savePasswordOk && <Message
                        positive
                        icon='inbox'
                        header={<Translate value="login_thankyou"/>}
                        content={<Translate value="login_password_is_set"/>}
                    />}

                    {this.props.savePasswordError && <Message
                        negative>
                        <Message.Header><Translate value="login_error"/></Message.Header>
                        <p><Translate value="login_error_something_went_wrong"/></p>
                    </Message>}

                </Form>}

            </main>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        lang: state.main.lang,
        currentLang: state.main.currentLang,
        currentUserChangePassword: state.main.currentUserChangePassword,
        changePasswordBusy: state.main.changePasswordBusy,
        changePasswordOk: state.main.changePasswordOk,
        changePasswordError: state.main.changePasswordError,
        savePasswordBusy: state.main.savePasswordBusy,
        savePasswordOk: state.main.savePasswordOk,
        savePasswordError: state.main.savePasswordError,
    }
}

export default connect(mapStateToProps)(withRouter(RegistrationReset))