import React from 'react';
import PropTypes from 'prop-types';

const BLEH = ' doesn\'t match ';



function getResistanceFromValue(value) {

    const prefixes= ["μ", "m", "", "k", "M", "G",];

    let res= "?"

    prefixes.forEach((p,i) => {
        let val= ( value / (Math.pow(1000, i)) );

        if( res === "?" && 0.1 <= val && val < 1000 ) {

            res=  val.toString() + prefixes[i] + "Ω";

        }
    })

    return res
}

function getCapacitanceFromValue(value) {

    const prefixes= ["f", "p", "n", "μ", "m", ""];

    let res= "?"

    prefixes.forEach((p,i) => {
        let val= ( value / (Math.pow(1000, i)) );

        if( res === "?" && 0.1 <= val && val < 1000 ) {

            res=  val + prefixes[i] + "F";
        }
    })

    return res
}

class NormalizedComponentSpecification extends React.Component {



    render() {

        const {value, compareTo, onlyError}= this.props;

        let errors = {};

        let compareList= [
            {field: 'resistance', display: (a,b) => getResistanceFromValue(a) + BLEH + getResistanceFromValue(b)},
            {field: 'capacitance', display: (a,b) => getCapacitanceFromValue(a) + BLEH + getCapacitanceFromValue(b)},
            {field: 'packageOutline', display: (a,b) => (a) + BLEH + (b)},
            {field: 'tolerance', display: (a,b) => (a) + BLEH + (b)},
            {field: 'voltage_rating', display: (a,b) => (a) + BLEH + (b)},
            {field: 'power_rating', display: (a,b) => (a) + BLEH + (b)},
            {field: 'dielectric', display: (a,b) => (a) + BLEH + (b)},
        ]

        if( value != null && compareTo != null ) {

            compareList.forEach(c => {
                if( value[c.field] && compareTo[c.field] && value[c.field] !== compareTo[c.field] ) {
                    errors[c.field]= c.display(value[c.field], compareTo[c.field])
                }
            })

        }

        return (
            <div>
                {onlyError ? '' : value.displayValue}
                {Object.keys(errors).map((key, index) => <div>
                    {errors[key]}
                </div>)}
            </div>
        );
    }
}

NormalizedComponentSpecification.propTypes = {
    value: PropTypes.object,
    compareTo: PropTypes.object,
    onlyError: PropTypes.bool,
}

const mapStateToProps = (state) => {
    return {

    }
}

export default NormalizedComponentSpecification;