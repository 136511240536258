import React, {useEffect, useRef, useState} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {Icon, Popup} from "semantic-ui-react";
import {recentUpdated} from "./functions";
import {updateStockAndPrice} from "../actions";
import BomStockIcon from "./BomStockIcon";

function useWasSeen() {
    // to prevents runtime crash in IE, let's mark it true right away
    const [wasSeen, setWasSeen] = useState(
        typeof IntersectionObserver !== "function"
    );

    const ref = useRef(null);
    React.useEffect(() => {
        if (ref.current && !wasSeen) {
            const observer = new IntersectionObserver(
                ([entry]) => entry.isIntersecting && setWasSeen(true)
            );
            observer.observe(ref.current);
            return () => {
                observer.disconnect();
            };
        }
    }, [wasSeen]);
    return [wasSeen, ref];
}

const BomOrderpartStock = (props) => {

    const {sp, refreshWhenVisible, dispatch} = props;

    const [wasSeen, ref] = useWasSeen();

    const canGetRealtimeStock = sp.supplier !== 'WURTH';

    useEffect(() => {
        if( refreshWhenVisible && canGetRealtimeStock && wasSeen && !recentUpdated(sp) && !sp.loading ) {
            dispatch(updateStockAndPrice([sp.id]))
        }
    }, [wasSeen]);

    let backgroundColor = (sp.stock != null && sp.stock === 0 && (sp.stockUS == null || sp.stockUS === 0)) ? 'dp-error' : '';

    if( sp.stock != null && sp.stock > 0 && sp.stock < (props.requiredAmount || 1) ) {
        backgroundColor = 'dp-error';
    }

    return <div ref={ref} style={{display: "flex", gap: 4}} className={backgroundColor}>
        <div>
            {sp.stock != null ? sp.stock : ''}
            {(sp.stockUS != null && sp.stockUS > 0) ? <Popup trigger={<div>{`${sp.stockUS}🇺🇸`}</div>} content={<p>US Stock</p>}/>  : ''}
        </div>
        {canGetRealtimeStock ? <BomStockIcon selectedSupplierPart={sp}/>  : null}
    </div>
}

BomOrderpartStock.propTypes= {
    sp: PropTypes.object.isRequired,
    refreshWhenVisible: PropTypes.bool,
    requiredAmount: PropTypes.number,
}

BomOrderpartStock.defaultProps = {
    refreshWhenVisible: false,
    requiredAmount: null,
}

const mapStateToProps = (state) => {
    return {

    }
}

export default connect(mapStateToProps)(BomOrderpartStock);