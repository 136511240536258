import React, {Component} from "react";
import {renderSupplierUrl} from "./functions";

function renderImageForPart(op, sp) {
    if( op && op.imageUrl ) {

        let image = <img src={op.imageUrl} alt="part image" className="bst-image" style={{maxHeight: '32px', maxWidth: '32px', background: 'URL(https://www.deltaproto.com/img/logo.png) center center black'}} />;

        let credit = <div style={{fontSize: '.4em'}}>
            <a href={op.imageCreditUrl}>{op.imageCreditString}</a>
        </div>

        if( sp && sp.supplier ) {
            return <>
                {renderSupplierUrl(sp, image)}
                {credit}
            </>
        } else {
            return <>
                {image}
                {credit}
            </>
        }
    } else {
        return <></>;
    }
}

class BomSelectedPartImage extends Component {
    render() {
        const {supplierPart, orderPart} = this.props;

        if( orderPart ) {
            return <div style={{marginTop: 10, width: '100%', textAlign: 'center'}}>
                {renderImageForPart(orderPart, supplierPart)}
            </div>
        }

        return <div></div>
    }
}

export default BomSelectedPartImage;