import {combineReducers} from 'redux'
import fileimport from "./fileimport";
import bom from "./bom";
import project from "./project";
import employee from "./employee";
import chat from "./chat";
import deltatable from "./deltatable";
import mail from "./mail";
import {getLangFromOs} from "../actions/functions";
import _ from 'lodash'

const initialState= {
    images: [],
    calculatorSettings: [],
    stompClient: null,
    stompSubscriptions: [],
    loginOpen: false,
    currentLang: getLangFromOs(),
    users: [],
    blogitems: [],
    userOptions: [],
    changeUserModalOpen: false,
    idleTimeout: null,
    activeUsers: [],
    roles: [],
    newsletterList: [],
    sharketingPersonList: [],
    companyList: [],
    sharketingSort: [{id: 1, name: 'lastExecuted', text: 'Last-sharketing', sort: 'ascending'}],
    companySort: [{id: 1, name: 'name', text: 'Company', sort: 'ascending'}],
    siteSearchOpen: false,
    siteSearchValue: '',
    siteSearch: {list: []},
    searchPrintOrCompany: {list: []},
    kvkSearchResult: [],
    ibiza: []
}

const main = (state = initialState, action) => {
    switch( action.type ) {
        case 'OPEN_SITE_SEARCH':
            return {...state, siteSearchOpen: action.open}
        case 'CHANGE_SITE_SEARCH_VALUE':
            return {...state, siteSearchValue: action.value}
        case 'RECEIVE_SITE_SEARCH':
            if( state.siteSearch && state.siteSearch.date ) {
                if( action.value.date < state.siteSearch.date ) {
                    return state;
                }
                return {...state, siteSearch: action.value}
            }
            return {...state, siteSearch: action.value}
        case 'RECEIVE_SEARCH_PRINT_OR_COMPANY':
            if( state.searchPrintOrCompany && state.searchPrintOrCompany.date ) {
                if( action.value.date < state.searchPrintOrCompany.date ) {
                    return state;
                }
                return {...state, searchPrintOrCompany: action.value}
            }
            return {...state, searchPrintOrCompany: action.value}
        case 'START_SAVE_USER':
            return {...state}
        case 'RECEIVE_SAVE_USER':
            return {...state,
                currentUser: state.currentUser.id === action.value.id ? action.value : state.currentUser,
                users: state.users.map(u => u.id === action.value.id ? action.value : u)
            }
        case 'ERROR_SAVE_USER':
            return {...state}
        case 'RECEIVE_GET_USER':
            return {...state, currentUser: action.value}
        case 'START_CONFIRM_REGISTRATION':
            return Object.assign({}, state, {
                confirmingBusy: true,
                confirmingOk: false,
                confirmingFailed: false
            })
        case 'RECEIVE_CONFIRM_REGISTRATION':
            return Object.assign({}, state, {
                confirmingBusy: false,
                confirmingOk: true
            })
        case 'ERROR_CONFIRM_REGISTRATION':
            return Object.assign({}, state, {
                confirmingBusy: false,
                confirmingFailed: true
            })
        case 'TOGGLE_USER_RENDER':

            if( state.currentUser.renderAsUser && !state.currentUser.renderAsMicroplacerUser ) {
                return {
                    ...state,
                    currentUser: {
                        ...state.currentUser,
                        renderAsMicroplacerUser: true
                    }
                }
            }
            return {
                ...state,
                currentUser: {
                    ...state.currentUser,
                    renderAsUser: !state.currentUser.renderAsUser,
                    renderAsMicroplacerUser: false
                }
            }
        case 'OPEN_LOGIN_SIGNUP':
            return Object.assign({}, state, {
                userExists: false,
                loginOpen: action.value,
                loginActiveIndex: action.index ? action.index : 0,
                invalidEmail: false,
            })
        case 'START_SIGNUP':
            return Object.assign({}, state, {
                signupBusy: true,
                signupOk: false,
                signupError: false
            })
        case 'RECEIVE_SIGNUP':
            return Object.assign({}, state, {
                signupBusy: false,
                signupOk: true
            })
        case 'ERROR_SIGNUP':
            return Object.assign({}, state, {
                signupBusy: false,
                signupError: true
            })
        case 'START_LOGIN':
            return Object.assign({}, state, {
                loginBusy: true
            })
        case 'RECEIVE_LOGIN':
            return Object.assign({}, state, {
            })
        case 'START_CHECK_EMAIL':
            return Object.assign({}, state, {
                userExists: false,
                invalidEmail: false,
            })
        case 'RECEIVE_CHECK_EMAIL':
            return Object.assign({}, state, {
                userExists: action.value.userExists,
                invalidEmail: !!action.value.invalidEmail,
            })
        case 'ERROR_LOGIN':
            return Object.assign({}, state, {
                loginBusy: false,
                loginError: 'Failed'
            })
        case 'START_VALIDATE_LOGIN':
            return Object.assign({}, state, {
                //loginBusy: true
            })
        case 'RECEIVE_VALIDATE':
        case 'RECEIVE_VALIDATE_LOGIN':

            let renderAsUser = state.currentUser && state.currentUser.renderAsUser
            let renderAsMicroplacerUser = state.currentUser && state.currentUser.renderAsMicroplacerUser

            return Object.assign({}, state, {
                loginBusy: false,
                currentUser: renderAsUser ? {...action.value, renderAsUser, renderAsMicroplacerUser} : action.value,
                loginOpen: false,
                currentLang: action.value.lang ? action.value.lang : state.currentLang
            })
        case 'ERROR_VALIDATE_LOGIN':
            return Object.assign({}, state, {
                loginBusy: false,
                currentUser: null
            })
        case 'START_CHANGE_PASSWORD':
            return Object.assign({}, state, {
                changePasswordBusy: true,
                changePasswordOk: false,
                changePasswordError: false,
                currentUserChangePassword: null
            })
        case 'RECEIVE_CHANGE_PASSWORD':
            return Object.assign({}, state, {
                changePasswordBusy: false,
                changePasswordOk: true,
                currentUserChangePassword: action.value
            })
        case 'ERROR_CHANGE_PASSWORD':
            return Object.assign({}, state, {
                changePasswordBusy: false,
                changePasswordOk: false,
                changePasswordError: true
            })
        case 'START_SAVE_PASSWORD':
            return Object.assign({}, state, {
                savePasswordBusy: true,
                savePasswordError: false
            })
        case 'RECEIVE_SAVE_PASSWORD':
            return Object.assign({}, state, {
                savePasswordBusy: false,
                savePasswordOk: true
            })
        case 'ERROR_SAVE_PASSWORD':
            return Object.assign({}, state, {
                savePasswordBusy: false,
                savePasswordError: true
            })
        case 'RECEIVE_I18N':
            return Object.assign({}, state, {
                lang: action.value
            })
        case 'SET_MOHICAN_CLIENT':
            return Object.assign({}, state, {
                mohicanClient: action.client,
            })
        case 'SET_STOMP_CLIENT':
            return Object.assign({}, state, {
                stompClient: action.client,
            })
        case 'ADD_STOMP_SUBSCRIPTION':
            return Object.assign({}, state, {
                stompSubscriptions: [...state.stompSubscriptions, action.subscription]
            })
        case 'STOMP_CLIENT_UNSUBSCRIBE_ALL':

            state.stompSubscriptions.forEach((s) => s.unsubscribe())

            return Object.assign({}, state, {
                stompSubscriptions: []
            })
        case 'SET_LANG':
            return Object.assign({}, state, {
                currentLang: action.value,
            })
        case 'SET_IMAGES':
            return Object.assign({}, state, {
                images: action.value,
            })
        case 'SET_IMAGE_CHECKED':
            return Object.assign({}, state, {
                images: state.images.map((image,i) => (i === action.index ? Object.assign({}, image, {checked: action.value}) : image))
            })
        case 'ADD_IMAGE':
            return Object.assign({}, state, {
                images: [...state.images, action.value]
            })
        case 'START_CALCULATOR_SETTINGS':
            return Object.assign({}, state, {
                calculatorSettings: [],
                calculatorSettingsBusy: true,
                calculatorSettingsError: false
            })
        case 'RECEIVE_CALCULATOR_SETTINGS':
            return Object.assign({}, state, {
                calculatorSettings: action.value,
                calculatorSettingsBusy: false
            })
        case 'ERROR_CALCULATOR_SETTINGS':
            return Object.assign({}, state, {
                calculatorSettings: [],
                calculatorSettingsBusy: false,
                calculatorSettingsError: true
            })
        case 'CLOSE_CALCULATOR_SETTINGS':
            return Object.assign({}, state, {
                calculatorSettings: [],
                calculatorSettingsBusy: false,
                calculatorSettingsError: false
            })
        case 'START_APPLICATION_SETTINGS':
            return Object.assign({}, state, {
                applicationsettingsSaving: action.saving,
            })
        case 'RECEIVE_APPLICATION_SETTINGS':
            return Object.assign({}, state, {
                applicationsettings: action.value,
                applicationsettingsSaving: false
            })
        case 'RECEIVE_SAVE_APPLICATION_SETTINGS':
            return Object.assign({}, state, {
                applicationsettings: state.applicationsettings.map(s => {
                    if(s.key === action.value.key) {
                        return action.value
                    } else {
                        return s
                    }
                })
            })
        case 'CHANGE_APPLICATION_SETTINGS':
            return Object.assign({}, state, {
                applicationsettings: state.applicationsettings.map(s => {
                    if(s.key === action.key) {
                        return Object.assign({}, s, {value: action.value, dirty: true})
                    } else {
                        return s
                    }
                })
            })
        case 'START_GET_USERS':
            return Object.assign({}, state, {
                fetchingUsers: true,
            })
        case 'RECEIVE_GET_USERS':
        case 'RECEIVE_DELETE_USER':
            return Object.assign({}, state, {
                fetchingUsers: false,
                users: action.value
            })
        case 'ERROR_GET_USERS':
            return Object.assign({}, state, {
                fetchingUsers: false,
            })
        case 'RECEIVE_GET_USER_ROLES':
            return {...state, roles: action.value}
        case 'CHANGE_USER_VALUE':
            return {
                ...state,
                users: state.users.map(u => u.id === action.id ? {
                    ...u, [action.name]: action.value, dirty: true
                } : u)
            }
        case 'START_BLOG_ITEM':
            return Object.assign({}, state, {
                fetchingBlogItem: true
            })
        case 'RECEIVE_BLOG_ITEM':
            return Object.assign({}, state, {
                blogitems: [...state.blogitems, ...action.value],
                fetchingBlogItem: false
            })
        case 'ERROR_BLOG_ITEM':
            return Object.assign({}, state, {
                fetchingBlogItem: false
            })
        case 'CHANGE_BLOG_ITEM_VALUE':
            return Object.assign({}, state, {
                blogitems: state.blogitems.map(b => {
                    if(b.id === action.id) {
                        return Object.assign({}, b, {
                            [action.name]: action.value,
                            dirty: action.name !== 'edit'
                        })
                    } else {
                        return b;
                    }
                })
            })
        case 'START_SAVE_BLOG_ITEM':
            return Object.assign({}, state, {
            })
        case 'RECEIVE_SAVE_BLOG_ITEM':
            return Object.assign({}, state, {
                blogitems: state.blogitems.map(b => {
                    if(b.id === action.start.id) {
                        return Object.assign({}, action.value, {
                            edit: false
                        })
                    } else {
                        return b;
                    }
                })
            })
        case 'ERROR_SAVE_BLOG_ITEM':
            return Object.assign({}, state, {
            })
        case 'ADD_BLOG_ITEM':
            return Object.assign({}, state, {
                blogitems: [{author: 'harm', date: new Date(), edit: true}, ...state.blogitems],
            })
        case 'RECEIVE_GET_DIGIKEY_SETTINGS':
            return {...state, digikey: action.value}
        case 'START_SET_DIGIKEY_SETTINGS':
            return state;
        case 'RECEIVE_SET_DIGIKEY_SETTINGS':
            return {...state, digikey: action.value}
        case 'ERROR_SET_DIGIKEY_SETTINGS':
            return state;
        case 'CHANGE_DIGIKEY_SETTINGS':
            return {...state, digikey: {...state.digikey, [action.name]: action.value, dirty: true}}

        case 'RECEIVE_GET_NEXAR_SETTINGS':
            return {...state, nexar: action.value}
        case 'START_SET_NEXAR_SETTINGS':
            return state;
        case 'RECEIVE_SET_NEXAR_SETTINGS':
            return {...state, nexar: action.value}
        case 'ERROR_SET_NEXAR_SETTINGS':
            return state;
        case 'CHANGE_NEXAR_SETTINGS':
            return {...state, nexar: {...state.nexar, [action.name]: action.value, dirty: true}}
        case 'RECEIVE_GET_DROPBOX_API_STATUS':
            return {...state, dropboxStatus: action.value}
        case 'START_GET_DROPBOX_AUTH_URL':
            return {...state, fetchingDropboxAuthUrl: true}
        case 'RECEIVE_GET_DROPBOX_AUTH_URL':
            return {...state, dropboxAuthUrl: action.value.url, fetchingDropboxAuthUrl: false}
        case 'RECEIVE_DROPBOX_UPDATE_COMPANY':
            return {...state, dropboxCompanyUpdateResult: action.value}
        case 'ERROR_GET_DROPBOX_AUTH_URL':
            return {...state, fetchingDropboxAuthUrl: false}
        case 'RECEIVE_GET_DROPBOX_PROJECT_FOLDERS':
            return {...state, dropboxProjectFolders: action.value}
        case 'RECEIVE_GET_KVK_API_STATUS':
            return {...state, kvkStatus: action.value}
        case 'START_GET_KVK_BY_NAME':
            return {...state, kvkSearching: true}
        case 'RECEIVE_GET_KVK_BY_NAME':
        case 'RECEIVE_GET_KVK_BY_NUMBER':
            return {...state, kvkSearchResult: action.value.resultaten, kvkSearching: false}
        case 'ERROR_GET_KVK_BY_NAME':
            return {...state, kvkSearching: false}

        case 'RECEIVE_USER_OPTIONS':
            return {...state, userOptions: action.value}
        case 'RECEIVE_CHANGE_USER':
            return {...state, currentUser: action.value, changeUserModalOpen: false}
        case 'OPEN_CHANGE_USER_MODAL':
            return {...state, changeUserModalOpen: action.value}
        case 'SET_USER_IDLE_TIMEOUT':
            localStorage.setItem('deltaprotoidletimeout', JSON.stringify({idleTimeout: action.value}));
            return {...state, idleTimeout: action.value}
        case 'START_ACTIVE_USERS':
            return {...state, activeUsers: []}
        case 'RECEIVE_ACTIVE_USERS':
            return {...state, activeUsers: action.value}
        case 'RECEIVE_GET_NEWSLETTER_LIST':
            return {...state, newsletterList: action.value}
        case 'RECEIVE_GET_SHARKETING_PERSON_LIST':
            return {...state, sharketingPersonList: sortSharketing( action.value, state.sharketingSort )}
        case 'RECEIVE_UPDATE_SHARKETING_PERSON':
        case 'RECEIVE_UPDATE_SHARKETING':
            if( state.sharketingPersonList.some(c => c.id === action.value.id )) {
                return {
                    ...state, sharketingPersonList: state.sharketingPersonList.map(c => (
                        c.id === action.value.id ? {...action.value, expanded: action.start.expanded} : c
                    ))
                }
            } else {
                return {
                    ...state, sharketingPersonList: [...state.sharketingPersonList, action.value].filter(p => !p.isNew)
                }
            }
        case 'CHANGE_SHARKETING_PERSON_VALUE':
            if( action.name === 'expanded' ) {
                return {
                    ...state, sharketingPersonList: state.sharketingPersonList.map(c => {
                        return c.id === action.id ? {...c, [action.name]: action.value} : {...c, expanded: false}
                    })
                }
            } else {
                return {
                    ...state, sharketingPersonList: state.sharketingPersonList.map(c => {
                        return c.id === action.id ? {...c, [action.name]: action.value, dirty: true} : c
                    })
                }
            }
        case 'CHANGE_SHARKETING_VALUE':
            return {
                ...state, sharketingPersonList: state.sharketingPersonList.map(c => {
                    if( c.id === action.personId ) {
                        return {
                            ...c,
                            dirty: true,
                            sharketingList: c.sharketingList.map(s => (
                                s.id === action.id ? {...s, [action.name]: action.value} : s
                            ))
                        }
                    }
                    return c;
                })
            }
        case 'ADD_SHARKETING_PERSON':
            return {...state, sharketingPersonList: [...state.sharketingPersonList, {id: '', status: 'SUSPECT', dirty: true, isNew: true, sharketingList: [], companyList: []}]}
        case 'ADD_SHARKETING':
            return {
                ...state, sharketingPersonList: state.sharketingPersonList.map(c => {
                    return c.id === action.personId ?
                        {...c,
                            expanded: true,
                            sharketingList: [...c.sharketingList, {dirty: true, isNew: true, executed: new Date(), color: 'blue', type: action.typeValue}]}
                        : {...c, expanded: false}
                })
            }
        case 'REMOVE_SHARKETING':
            return {
                ...state, sharketingPersonList: state.sharketingPersonList.map(c => {
                    if( c.id === action.personId ) {
                        return {...c, dirty: true, sharketingList: c.sharketingList.filter(s => s.id !== action.id)}
                    }
                    return c;
                })
            }
        case 'ADD_SORT_SHARKETING':
            let newSort1 = []
            if( state.sharketingSort.find(s => s.name === action.name) ) {
                newSort1 = state.sharketingSort.map(s => (
                        s.name === action.name ? {...s, sort: s.sort === 'descending' ? 'ascending' : 'descending'} : s
                    ))
            } else {
                newSort1= [...state.sharketingSort, {
                        name: action.name,
                        sort: 'ascending',
                        id: state.sharketingSort.length + 1
                    }]
            }
            return {
                ...state,
                sharketingSort: newSort1,
                sharketingPersonList: sortSharketing(state.sharketingPersonList, newSort1)
            }
        case 'ADD_FILTER_SHARKETING':
            let newSort5 = []
            if( state.sharketingSort.find(s => s.name === action.name) ) {
                newSort5 = state.sharketingSort.map(s => (
                    s.name === action.name ? {...s, filter: action.filter} : s
                ))
            } else {
                newSort5= [...state.sharketingSort, {
                    name: action.name,
                    filter: action.filter,
                    id: state.sharketingSort.length + 1
                }]
            }
            return {
                ...state,
                sharketingSort: newSort5,
                sharketingPersonList: sortSharketing(state.sharketingPersonList, newSort5)
            }
        case 'REMOVE_SORT_SHARKETING':
            const newSort = state.sharketingSort.filter(s => s.id !== action.id);
            return {
                ...state,
                sharketingSort: newSort,
                sharketingPersonList: sortSharketing(state.sharketingPersonList, newSort)
            }
        case 'ADD_SORT_COMPANYS':
            let newSort2 = []
            if( state.companySort.find(s => s.name === action.name) ) {
                newSort2 = state.companySort.map(s => (
                    s.name === action.name ? {...s, sort: s.sort === 'descending' ? 'ascending' : 'descending'} : s
                ))
            } else {
                newSort2= [...state.companySort, {
                    name: action.name,
                    sort: 'ascending',
                    id: state.companySort.length + 1
                }]
            }
            return {
                ...state,
                companySort: newSort2,
                companyList: sortCompanyList(state.companyList, newSort2)
            }
        case 'ADD_FILTER_COMPANYS':
            let newSort4 = []
            if( state.companySort.find(s => s.name === action.name) ) {
                newSort4 = state.companySort.map(s => (
                    s.name === action.name ? {...s, filter: action.filter} : s
                ))
            } else {
                newSort4= [...state.companySort, {
                    name: action.name,
                    filter: action.filter,
                    id: state.companySort.length + 1
                }]
            }
            return {
                ...state,
                companySort: newSort4,
                companyList: sortCompanyList(state.companyList, newSort4)
            }
        case 'REMOVE_SORT_COMPANYS':
            const newSort3 = state.companySort.filter(s => s.id !== action.id);
            return {
                ...state,
                companySort: newSort3,
                companyList: sortCompanyList(state.companyList, newSort3)
            }
        case 'START_GET_COMPANY_LIST':
            return {...state, fetchingCompanyList: true}
        case 'RECEIVE_GET_COMPANY_LIST':
            return {...state, companyList: sortCompanyList(action.value, state.companySort), fetchingCompanyList: false }
        case 'ERROR_GET_COMPANY_LIST':
            return {...state, fetchingCompanyList: false}
        case 'RECEIVE_UPDATE_COMPANY':
            if( state.companyList.some(c => c.id === action.value.id )) {
                return {
                    ...state, companyList: state.companyList.map(c => (
                        c.id === action.value.id ? action.value : c
                    ))
                }
            } else {
                return {
                    ...state, companyList: [...state.companyList, action.value].filter(p => !p.isNew)
                }
            }
        case 'ADD_COMPANY':
            return {...state, companyList: [...state.companyList, {dirty: true, isNew: true}]}
        case 'CHANGE_COMPANY_VALUE':
            return {
                ...state, companyList: state.companyList.map(c => {
                    if( c.id === action.id ) {
                        return {
                            ...c,
                            dirty: true,
                            [action.name]: action.value
                        }
                    }
                    return c;
                })
            }
        case 'RECEIVE_FETCH_IBIZA':
            return {...state, ibiza: action.value}
        default:
            return state;
    }
}

function sortCompanyList(list, sort) {
    if( !sort ) {
        return list;
    }

    const iteratee = sort.map(s => s.name)
    const order = sort.filter(s => s.sort).map(s => s.sort === 'descending' ? 'desc' : 'asc')

    const filters = sort.filter(s => s.filter);
    list = list.map(i => {
        let hidden = (filters.length > 0)
        if( filters.length > 0 ) {
            const show = filters.every(f => f.filter === i[f.name])
            if( show ) {
                hidden = !show;
            }
        }
        return {...i, hidden}
    })

    return _.orderBy(list, iteratee, order);
}

function sortSharketing(list, sort) {
    if( !sort ) {
        return list;
    }

    const iteratee = sort.map(s => s.name)
    const order = sort.filter(s => s.sort).map(s => s.sort === 'descending' ? 'desc' : 'asc')

    const filters = sort.filter(s => s.filter);
    list = list.map(i => {
        let hidden = (filters.length > 0)
        if( filters.length > 0 ) {
            const show = filters.every(f => f.filter === i[f.name])
            if( show ) {
                hidden = !show;
            }
        }
        return {...i, hidden}
    })

    const res = _.orderBy(list, iteratee, order)
    return res.map(sp => ({
        ...sp,
        sharketingList: _.orderBy(sp.sharketingList, 'executed', 'asc')
    }));
}

const myReducer = combineReducers({
    main,
    fileimport,
    bom,
    project,
    employee,
    chat,
    deltatable,
    mail
})

export default myReducer
