import React from 'react'
import PropTypes from "prop-types";
import {Button, Dropdown} from "semantic-ui-react";

class TabHeader extends React.Component {

    handleClick = (index) => {

        const {onChange}= this.props;

        if( onChange ) {
            onChange(index)
        }
    }

    render() {

        const {activeIndex, panes}= this.props;
        const showAdd= this.props.onAdd !== null;
        const additionalItems= this.props.additionalItems;

        return <div
            className='paper-tab'
        >
            {panes.map((p,i) => {
                if( p.text ) {
                    return <button
                        className={p.active ? 'link-button paper-tab-item-active' : 'link-button paper-tab-item'}
                        onClick={() => this.handleClick(p)}
                    >
                        {p.text}
                    </button>
                } else {
                    if (i === activeIndex) {
                        return <button className='link-button paper-tab-item-active'>
                            {p}
                        </button>
                    } else {
                        return <div className='link-button paper-tab-item' onClick={() => this.handleClick(i)}>
                            {p}
                        </div>
                    }
                }
            })}
            {showAdd && <div style={{marginLeft: 4, marginTop: 4}}>
                <Button basic circular icon='add' size='tiny' style={{boxShadow: 'unset', backGround: '#f5f5f5'}} onClick={() => this.props.onAdd()}/>
            </div>}
            {additionalItems && additionalItems.map(item => <>
                {item}
            </>)}
        </div>
    }
}

TabHeader.propTypes = {
    activeIndex: PropTypes.number,
    onChange: PropTypes.func,
    onAdd: PropTypes.func,
    panes: PropTypes.array,
    additionalItems: PropTypes.array,
};

TabHeader.defaultProps = {
    activeIndex: 0,
    onChange: null,
    onAdd: null,
    panes: [],
};

export default TabHeader;