import React from 'react'
import {connect} from "react-redux";
import {Icon, Popup} from "semantic-ui-react";
import {receiveStomp} from "../actions";
import {Stomp} from "@stomp/stompjs/esm6/compatibility/stomp";
import SockJS from 'sockjs-client'
import PropTypes from "prop-types";

class BomSocket extends React.Component {

    state = {
        connected: false
    }

    componentDidMount() {
        this.reconnect();

        let d = localStorage.getItem('deltaprotoplankje');

        if( d != null && d !== "null" ) {
            this.props.dispatch({
                type: 'SET_PLANKJE_POSITION',
                value: JSON.parse(d)
            })
        }

        let d1 = localStorage.getItem('deltaprotopcb');

        if( d1 != null && d1 !== "null" ) {
            this.props.dispatch({
                type: 'SET_PCB_POSITION',
                value: JSON.parse(d1)
            })
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(
            (prevProps.print == null && this.props.print) || (this.props.print && this.props.print.id !== prevProps.print.id) ||
            (prevProps.chat == null && this.props.chat) || (this.props.chat && this.props.chat.id !== prevProps.chat.id)
        ) {
            this.reconnect()
        }
    }

    componentWillUnmount() {
        if (this.stompClient) {
            this.stompClient.disconnect(() => {
                this.stompClient = null;
            });
        }

        if (this.socket) {
            this.socket.close(1000, "Normal Closure")
        }
    }

    reconnect() {
        let bomId = this.props.print ? this.props.print.id : '123'
        let chatId = this.props.chat ? this.props.chat.id : null
        this.connect(bomId, chatId)
    }

    connect(bomId, chatId) {

        console.log('connect to ' + bomId)

        let stompClient = this.state.stompClient;
        let socket;

        if( bomId == null ) {

            if( stompClient ) {
                stompClient.unsubscribe('1');
                stompClient.unsubscribe('2');
            }

            return;
        }

        let routeBom = bomId ? ('/topic/bom/' + bomId) : null;
        let routeChat = chatId ? ('/topic/chat/' + chatId) : null;

        if( !stompClient ) {
            socket = SockJS('/sock');
            socket.debug = () => {}
            stompClient = Stomp.over(socket);
            stompClient.debug = () => {}
        }

        let handleMessage = this.handleMessage;
        let setIsConnected = this.setIsConnected;

        if( stompClient && stompClient.connected ) {
            stompClient.unsubscribe('1');
            stompClient.unsubscribe('2');

            if( routeBom ) {
                stompClient.subscribe(routeBom, (value) => {
                    handleMessage(value)
                }, {id: '1'})
            }

            if( routeChat ) {
                stompClient.subscribe(routeChat, (value) => {
                    handleMessage(value)
                }, {id: '2'})
            }
        } else {

            stompClient.connect({bomId}, function () {

                if( routeBom ) {
                    stompClient.subscribe(routeBom, (value) => {
                        handleMessage(value)
                    }, {id: '1'})
                }

                if( routeChat ) {
                    stompClient.subscribe(routeChat, (value) => {
                        handleMessage(value)
                    }, {id: '2'})
                }

                setIsConnected()
            });

        }

        this.setState({stompClient: stompClient, socket: socket})

        this.stompClient = stompClient;
        this.socket = socket;
    }

    handleMessage = (value) => {
        //console.log(JSON.stringify(value))
        this.props.dispatch( receiveStomp(JSON.parse(value.body)) );
    }

    setIsConnected = () => {
        this.setState({connected: true})
    }

    render() {

        let connected = this.state.stompClient && this.state.stompClient.connected;

        if( this.props.hidden ) {
            return <span style={{opacity: .5, color: connected ? 'green' : 'red'}}>.</span>
        }
        return <>

            <Popup position='bottom center' trigger={
                <Icon style={{cursor: 'pointer'}} name='lightning' color={connected ? 'green' : 'red'} onClick={() => {
                    this.reconnect();
                }}/>
            } content={`${this.props.name} is ` + (connected ? 'connected' : 'disconnected') + '\r\n Click to reconnect'} />

            {/*{connected ? <Popup position='bottom center' trigger={<Icon name='lightning' color='green'/>} content={`${this.props.name} is connected`} /> :
                <Popup position='bottom center' trigger={<Icon name='lightning' color='red' as='a' onClick={() => {
                    console.log('reconnect')
                }}/>} content={`${this.props.name} is disconnected`} />}*/}
        </>
    }
}

BomSocket.propTypes = {
    hidden: PropTypes.bool,
    name: PropTypes.string
};

BomSocket.defaultProps = {
    hidden: false,
    name: 'Server socket'
};

const mapStateToProps = (state) => {
    return {
        print: state.bom.print,
        chat: state.chat.chat,
    }
}

export default connect(mapStateToProps)(BomSocket)