import React from 'react';
import connect from "react-redux/es/connect/connect";
import {Dropdown, Table, Button} from "semantic-ui-react";
import {changeUserValue, fetchUserRoles, fetchUsers, saveUser} from "../actions";
import Translate from "../Translate";
import {isAdmin} from "../actions/functions";

class AccountGroups extends React.Component {

    componentDidMount() {
        this.props.dispatch(fetchUsers())
        this.props.dispatch(fetchUserRoles())
    }

    render() {

        const admin = isAdmin(this.props.currentUser);

        let roles = this.props.roles.map(r => (
            {key: r.id, text: r.name, value: r.id, r}
        ))

        if( !admin ) {
            roles = roles.map(r => r.text === 'ROLE_ADMIN' ? {...r, disabled: true} : r)
        }

        return <div>

            <Table>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>User</Table.HeaderCell>
                        <Table.HeaderCell>E-mail</Table.HeaderCell>
                        <Table.HeaderCell>Group</Table.HeaderCell>
                        <Table.HeaderCell></Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {this.props.users.map(u => {
                        let rolesValue = u.roles.map(r => r.id)[0]

                        let disabled = !admin && u.roles.some(r => r.name === 'ROLE_ADMIN');

                        return <Table.Row>
                            <Table.Cell>{u.fullName}</Table.Cell>
                            <Table.Cell>{u.email}</Table.Cell>
                            <Table.Cell>
                                <Dropdown
                                    disabled={disabled}
                                    placeholder='Roles' fluid selection options={roles} value={rolesValue}
                                    onChange={(e, d) => {
                                        let newValue = this.props.roles.filter(r => d.value.includes(r.id))
                                        this.props.dispatch(changeUserValue(u.id, 'roles', newValue))
                                    }}
                                />

                            </Table.Cell>
                            <Table.Cell>
                                <Button disabled={disabled} primary={u.dirty} onClick={() => this.props.dispatch(saveUser(u))}><Translate value="save"/></Button>
                            </Table.Cell>
                        </Table.Row>
                    })}

                </Table.Body>
            </Table>

        </div>
    }
}

AccountGroups.propTypes = {
};

AccountGroups.defaultProps = {
};

const mapStateToProps = (state) => {
    return {
        currentUser: state.main.currentUser,
        lang: state.main.lang,
        users: state.main.users,
        roles: state.main.roles,
    }
}

export default connect(mapStateToProps)(AccountGroups)