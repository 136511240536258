import React, {Component} from "react";
import {renderSupplierUrl} from "./functions";
import {renderSelectparts} from "./BomSelectedPartView";

class BomSelectedPartPrice extends Component {
    render() {
        const {r} = this.props;

        if( r /*&& r.place*/ ) {
            return <div>
                <div className="cornertopright">
                    <div style={{fontWeight: 'bold'}}>{renderSelectparts(r, 'Price')}{" "}</div>
                    <div>{renderSelectparts(r, 'PriceSumWithMoq')}{" "}</div>
                </div>
                <div className='btitem' >{renderSelectparts(r, 'Stock')}{" "}</div>
                <div className='btitem' >{renderSelectparts(r, 'MOQ')}{" "}</div>
                <div className='btitem' >{renderSelectparts(r, 'Packing')}{" "}</div>
            </div>
        }
        return <div/>
    }
}

export default BomSelectedPartPrice;