import React from 'react';
import PropTypes from "prop-types";
import OnVisible from "react-on-visible";

class TableResize extends React.Component {

    state = {
        colwidth: [],
        isResizing: false
    }

    componentDidMount() {
        window.addEventListener('mousemove', this.onMouseMove.bind(this), false);
        window.addEventListener('mouseup', this.onMouseUp.bind(this), false);
    }

    componentWillUnmount() {
        window.removeEventListener('mousemove', this.onMouseMove.bind(this), false);
        window.removeEventListener('mouseup', this.onMouseUp.bind(this), false);
    }

    getColStyle( m_col ) {

        let m_col_width= this.getColWidth( m_col.name, m_col.width );

        return Object.assign({}, m_col.style ? m_col.style : {}, {
            maxWidth: m_col_width,
            minWidth: m_col_width,
        })

    }

    getColWidth = (name, width) => {
        let m_col_width= width ? width : this.props.defaultWidth;

        if( this.state.colwidth.filter(c => c.name === name).length ) {
            m_col_width = this.state.colwidth.filter(c => c.name === name)[0].w;
        }

        return m_col_width;
    }

    renderColResizer( name, value ) {
        return <div>
            <div>
                {value}
            </div>
            <div style={{display: 'inline-block',
                position: 'absolute',
                width: '36px',
                top: 0,
                height: '100%',
                right: '-18px',
                cursor: 'col-resize',
                zIndex: 10}}
                 onMouseDown={(e, t) => this.handleMouseDown(e, t, name)}
            />

        </div>
    }

    onMouseMove = (e) => {
        if( this.state.isResizing ) {

            const newWidth =  this.state.startWidth + (e.clientX - this.state.startX);

            if( this.state.colwidth.filter(c => c.name === this.state.colResizing).length ) {
                this.setState({
                    colwidth: this.state.colwidth.map((c, i) => {
                        return (c.name === this.state.colResizing) ? Object.assign({}, c, {w: newWidth}) : c
                    })
                });
            } else {
                this.setState({
                    colwidth: [...this.state.colwidth, {name: this.state.colResizing, w: newWidth}]
                });
            }

        }
    }

    onMouseUp = (e) => {
        this.setState({
            isResizing: false,
        });
    }

    handleMouseDown = (event, t, name) => {

        let startWidth= this.getColWidth(name);

        this.setState({
            startX: event.clientX,
            startWidth,
            isResizing: true,
            colResizing: name
        });

    };

    render() {
        return <table className="bomtable fixed">
                <thead>
                <tr>

                    {this.props.headers.filter(c => c.show == null || c.show).map((c,i) => (<th key={i} style={this.getColStyle(c)}>
                        {this.renderColResizer(c.name, c.text)}
                        <div style={{position: 'absolute', right: '0px', bottom: '-4px', fontSize: 12, fontWeight: 'normal', color: 'grey'}}>{this.getColWidth(c.name, c.width)}</div>
                    </th>))}

                </tr>
                </thead>

                <tbody>
                {this.props.values.map((r,ri) => {


                        return <tr>
                            {this.props.headers.filter(c => c.show == null || c.show).map((c, i) => (
                                <td style={this.getColStyle(c)} key={i}> {this.props.render(c.name, r)} </td>
                            ))}
                        </tr>


                })}

                {(this.props.values && this.props.values.length > 0 && !this.props.last && !this.props.fetching) ?
                    <OnVisible bounce={true} onChange={(e) => {
                        console.log('fetch next page ')
                        if( this.props.fetchNextPage ) {
                            this.props.fetchNextPage()
                        }
                }}/> : ''}

                {this.props.fetching ? <tr>
                    <td style={{textAlign: 'center'}} colSpan={this.props.headers.length}>Loading...</td>
                </tr> : ''}

                </tbody>

            </table>
    }
}

TableResize.propTypes = {
    headers: PropTypes.array,
    values: PropTypes.array,
    defaultWidth: PropTypes.number,
    last: PropTypes.bool,
    fetching: PropTypes.bool,
    fetchNextPage: PropTypes.func,
};

TableResize.defaultProps = {
    headers: [],
    values: [],
    defaultWidth: 200
};

export default (TableResize)