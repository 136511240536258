import React from 'react';

class Frontpage extends React.Component {
  render() {
    return (
      <div>
        <h1 className="animate-pulse">Hello World!</h1>
      </div>
    );
  }
}

export default Frontpage;