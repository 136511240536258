import React from 'react'
import PropTypes from "prop-types";

class TabPane extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            height: 0
        }
    }

    componentDidMount() {
        const height = this.divElement.clientHeight;
        this.setState({ height });
    }

    render() {
        const {useFullPageWidth} = this.props;
        return <div>
            <div
                className='paper'
                ref={ (divElement) => { this.divElement = divElement } }
                style={useFullPageWidth ? {position: 'absolute', left: 8, width: 'calc(100vw - 16px)', ...this.props.style} : {...this.props.style}}
            >
                {this.props.children}
            </div>
            <div style={useFullPageWidth ? {height: this.state.height} : {}}/>
        </div>
    }
}

TabPane.propTypes = {
    style: PropTypes.object,
    useFullPageWidth: PropTypes.bool
};

TabPane.defaultProps = {
    style: {},
    useFullPageWidth: false
};

export default TabPane;