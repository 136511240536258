import React from "react";
import connect from "react-redux/es/connect/connect";
import EmployeeHours from "./EmployeeHours";

class EmployeeHoursOverview extends React.Component {
    render () {
        return <div>
            <EmployeeHours isOverview={true}/>
        </div>
    }
}

const mapStateToProps = (state) => {
    return {
        currentUser: state.main.currentUser,
        lang: state.main.lang,
        currentLang: state.main.currentLang,
        hours: state.employee.hours
    }
}

export default connect(mapStateToProps)(EmployeeHoursOverview)