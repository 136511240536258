import React from 'react';
import {
    dropboxUpdateCompany,
    getDropboxAuthUrl,
    getDropboxProjectFolders,
    getDropboxStatus,
    getKvkApiStatus, setDropboxAuthResponse,
    updateKvkKey, zoekKvkHandelsnaam, zoekKvkNummer
} from "./actions";
import {Button, Divider, Form, Icon, Modal, Popup} from "semantic-ui-react";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import {formatDateTime} from "./actions/functions";

class DropboxAuthentication extends React.Component {

    state = {
    }

    componentDidMount() {
        this.props.dispatch(getDropboxStatus())
        this.props.dispatch(getDropboxProjectFolders())

    }

    renderProjectFolders = (folders) => {
        if (!folders) return null

        return <div>
            <h3>Project folders <Icon name='refresh' link onClick={() => this.props.dispatch(getDropboxProjectFolders(true))}/></h3>

            {folders.map(f => f.projects.length).reduce((a, b) => a + b, 0)} projects

            <table className={'deltatable'}>
                <thead>
                    <tr>
                        <th>Folder</th>
                        <th>Folder name</th>
                        <th>Project</th>
                        <th>Project name</th>
                        <th>Path</th>
                    </tr>
                </thead>

                {folders.map((folder) => {
                    if (folder.projects) {
                        return folder.projects.map((project) => {
                            let color = project.internalName.startsWith('PR') ? '' : 'yellow';
                            return <tr key={folder.internalName + project.internalName}>
                                <td>{folder.internalName}</td>
                                <td>"{folder.name}"</td>
                                <td style={{backgroundColor: color}}>{project.internalName}</td>
                                <td>"{project.name}"</td>
                                <td>
                                    <span style={{color: 'gray'}}>{project.path}</span>
                                </td>
                            </tr>
                        })
                    }
                    return <li key={folder.internalName}>{folder.internalName}</li>
                })}
            </table>
        </div>
    }

    render() {


        return <div style={{display: 'flex', gap: 8, flexFlow: 'column'}}>
            <div>
                Dropbox API status: {JSON.stringify(this.props.dropboxStatus)}
            </div>

            <div>
                <Button primary loading={this.props.fetchingDropboxAuthUrl} onClick={() => {
                    this.props.dispatch(getDropboxAuthUrl((res) => {
                        console.log(res.url)
                        if (res.url) {
                            window.open(res.url, "_blank");
                            this.setState({showApiKeyInput: true})
                        }
                    }))
                }}>Authenticate</Button>
            </div>
            <div style={{display: this.state.showApiKeyInput ? '' : 'none'}}>
                <Form>
                    <Form.Input label='Access code' placeholder='' value={this.state.dropboxApiKey} onChange={(e) => {
                        this.setState({dropboxApiKey: e.target.value})
                    }}/>
                    <Form.Button primary onClick={() => {
                        this.props.dispatch(setDropboxAuthResponse(this.state.dropboxApiKey, () => {
                            this.props.dispatch(getDropboxStatus())
                            this.setState({showApiKeyInput: false})
                        }))
                    }}>Update</Form.Button>
                </Form>
            </div>

            <div>
                <Button onClick={() => {
                    this.setState({updating: true})
                    this.props.dispatch(dropboxUpdateCompany(true, () => {
                        this.setState({updating: false})
                    }))
                }} loading={this.state.updating}>
                    Update company info
                </Button>

                {this.props.dropboxCompanyUpdateResult ? <div>
                    <h3>Comapany's not found in the Company Overview</h3>
                    <ul>
                        {this.props.dropboxCompanyUpdateResult.map((company) => {
                            return <li key={company.internalName}>{company.internalName} <span style={{color: 'gray'}}>{company.path}</span></li>
                        })}
                    </ul>
                </div> : null}
            </div>

            <Divider/>

            {this.renderProjectFolders(this.props.dropboxProjectFolders)}
        </div>
    }
}

DropboxAuthentication.propTypes = {
    statusOnly: PropTypes.bool
}

const mapStateToProps = (state) => {
    return {
        dropboxStatus: state.main.dropboxStatus,
        dropboxProjectFolders: state.main.dropboxProjectFolders,
        fetchingDropboxAuthUrl: state.main.fetchingDropboxAuthUrl,
        dropboxAuthUrl: state.main.dropboxAuthUrl,
        dropboxCompanyUpdateResult: state.main.dropboxCompanyUpdateResult,
    }
}

export default connect(mapStateToProps)(DropboxAuthentication)