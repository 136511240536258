import React from 'react';
import {getAllMyStock} from "../actions";
import connect from "react-redux/es/connect/connect";
import {Link, withRouter} from "react-router-dom";
import BomStockEdit from "./BomStockEdit";
import {Loader} from "semantic-ui-react";

class BomMyStockPage extends React.Component {

    componentDidMount() {
        this.props.dispatch(getAllMyStock())
    }

    render() {
        return <section className="pageContainer" style={{textAlign: 'left'}}>

            <Link to='/stock'>back</Link>

            {this.props.fetchingBomStock ?
                <div style={{textAlign: 'center'}}>
                    <Loader active inline/>
                </div>
            : null }

            {this.props.bomStockList.map(bs => <BomStockEdit key={bs.id} bomStock={bs}/>)}

        </section>
    }
}

const mapStateToProps = (state) => {
    return {
        currentUser: state.main.currentUser,
        lang: state.main.lang,
        bomStockList: state.bom.bomStockList,
        fetchingBomStock: state.bom.fetchingBomStock,
    }
}

export default connect(mapStateToProps)(withRouter(BomMyStockPage))