import React from 'react';
import {Link, withRouter} from "react-router-dom";
import {
    addBomStockItem,
    changeBomStockItemValue, getAllMyStock,
    getBomStock,
    saveBomStockItem,
    saveBomStockTransaction
} from "../actions";
import {Button, Dropdown, Form, Header, Icon, Input, Loader, Modal} from "semantic-ui-react";
import connect from "react-redux/es/connect/connect";
import DeltaTable from "../deltatable/DeltaTable";
import {formatDateTime} from "../actions/functions";
import Deltatable from "../reducers/deltatable";
import DeltaTableMenu from "../deltatable/DeltaTableMenu";
import BomSearchDialog from "./BomSearchDialog";
import PropTypes from "prop-types";

const transactionTypes = [
    'RESERVED',
    'TOBE_ORDERED',
    'ORDERED',
    'STOCK'
]

const getValueByType = (list, type) => {
    return (list && list.some(st => st.action === type)) ? list.find(st => st.action === type).sum : 0
}

class BomStockEdit extends React.Component {

    state = {
        details: null
    }

    componentDidMount() {
        //if( this.props ) {
        //    let stockId= this.props.match.params.stockId;
        //    if( stockId  ) {
        //        this.props.dispatch(getBomStock(stockId))
        //    }
        //}
    }

    renderTransactions(bomStock) {
        if( this.state.details ) {
            const d = this.state.details;
            return <Modal open={true} closeIcon onClose={() => this.setState({details: null})}>
                <Modal.Content>
                    <div>

                        <div>RESERVED: {getValueByType(d.stock, 'RESERVED')}</div>
                        <div>TOBE_ORDERED: {getValueByType(d.stock, 'TOBE_ORDERED')}</div>
                        <div>ORDERED: {getValueByType(d.stock, 'ORDERED')}</div>
                        <div>STOCK: {getValueByType(d.stock, 'STOCK')}</div>

                        <DeltaTable
                            name={'stock-stransactions'}
                            columns={[
                                {id: 1, header: 'Date', render: (s) => {
                                    return <div>
                                        {formatDateTime(s.date)}
                                    </div>
                                }},
                                {id: 2, header: 'Action', render: (s) => {
                                    return s.action
                                }},
                                {id: 3, header: 'Amount', render: (s) => {
                                    return s.amount
                                }},
                                {id: 4, header: 'Comment', render: (s) => {
                                    return s.comment
                                }},
                                {id: 5, header: 'User', render: (s) => {
                                    return s.by
                                }}
                            ]}
                            data={this.state.details.transactions}
                        />
                    </div>
                </Modal.Content>
            </Modal>
        }
        return null;
    }

    renderAddStockModal(bomStock) {
        if( this.state.addStock ) {
            return <Modal open={true} closeIcon onClose={() => this.setState({addStock: null})}>
                <Modal.Content>
                    <Form>
                        <Form.Input label='Amount' type="number" value={this.state.addStockValue} onChange={(e) => this.setState({addStockValue: e.target.value})}/>
                        <Form.Input label='Comment' value={this.state.addStockComent} onChange={(e) => this.setState({addStockComent: e.target.value})}/>
                    </Form>
                </Modal.Content>
                <Modal.Actions>
                    <Button onClick={() => this.setState({addStock: null})}>Close</Button>
                    <Button primary disabled={!this.state.addStockValue} onClick={() => {
                        this.props.dispatch(saveBomStockTransaction(
                            bomStock.id,
                            {
                            action: 'STOCK',
                            amount: this.state.addStockValue,
                            comment: this.state.addStockComent
                        }, this.state.addStock, () => {
                            this.setState({addStock: null})
                        }))
                    }}>Add</Button>
                </Modal.Actions>
            </Modal>
        }
        return null
    }

    renderSetMpn(bomStock) {
        if( this.state.setMpn ) {
            return  <BomSearchDialog
                open={true}
                onOrderpart={(op) => {
                    this.props.dispatch(changeBomStockItemValue(bomStock.id, this.state.setMpn, 'orderpart', op))
                    this.setState({setMpn: null})
                }}
                onClose={() => {
                    this.setState({setMpn: null})
                }}
            />
        }
        return null
    }

    render() {

        const bomStock = this.props.bomStock;

        let data = []
        let name = ''
        let isCompanyStock = false;
        let id = '';

        if( bomStock ) {
            if( bomStock.print ) {
                name = 'Project: ' + bomStock.print.name;
            } else if( bomStock.userCompany ) {
                name = 'Company: ' + bomStock.userCompany.name;
                isCompanyStock = true;
            }
            data = bomStock.items
            id = bomStock.id
        }

        if( this.props.pageView ) {
            return (
                <section className="pageContainer" style={{textAlign: 'left'}}>

                    <Link to='/stock'>back</Link>

                    {this.renderTable(id, name, bomStock, data, isCompanyStock)}

                </section>
            );
        } else {
            return <div>
                {this.renderTable(id, name, bomStock, data, isCompanyStock)}
            </div>
        }
    }


    renderTable(id, name, bomStock, data, isCompanyStock) {
        return <>
            <DeltaTableMenu
                key={id+'-menu'}
                color={isCompanyStock ? 'green' : 'blue'}
                header={name}
                menu={[
                    <Dropdown.Item key={'x'} onClick={() => {
                        this.props.dispatch(saveBomStockItem({stock: [], transactions: []}, bomStock.id, (res) => {
                            this.setState({setMpn: res.id})
                        }))
                    }}>Add stock</Dropdown.Item>,

                    ...((!isCompanyStock) ? [<Dropdown.Item onClick={() => {
                        if( bomStock.projectOrder ) {
                            this.props.history.push('/project/' + bomStock.projectOrder)
                        } else {
                            this.props.history.push('/projects-microplacer/' + bomStock.print.id)
                        }
                    }}>
                        Open project
                    </Dropdown.Item>] : [])
                ]}
            >
                <DeltaTable
                    key={id}
                    name={'bomstock'}
                    loading={this.props.loading}
                    columns={[
                        {
                            id: 0, header: 'Value', render: (s) => {
                                if (s.orderpart) {
                                    if (s.orderpart.specification) {
                                        return s.orderpart.specification.displayValue
                                    } else {
                                        return s.orderpart.shortDescription
                                    }
                                }
                                return ''
                            }
                        },
                        {
                            id: 1, header: 'MPN', render: (s) => {
                                if (s.orderpart) {
                                    return s.orderpart.mpn
                                }
                                if (!s.orderpart && !s.kla) {
                                    return <button className='link-button'
                                                   onClick={() => this.setState({setMpn: s.id})}>Set
                                        MPN</button>
                                }
                                return ''
                            }
                        },

                        /*,
                        {
                            id: 2, header: 'KLA', render: (s) => {
                                if (s.kla) {
                                    return s.kla.name
                                }
                                return ''
                            }
                        }*/,
                        {
                            id: 3, header: 'Free stock', render: (s) => {
                                return <div style={{display: 'flex'}}>
                                    {getValueByType(s.stock, 'STOCK') - getValueByType(s.stock, 'RESERVED')}
                                    {s.id ? <div style={{flex: 1, textAlign: 'right'}}>
                                        <Icon name='edit' link onClick={() => this.setState({addStock: s.id})}/>
                                    </div> : null}

                                </div>
                            }
                        },
                        {
                            id: 4, header: 'Tray-Lane', sort: 'boardLocation', render: (s) => {
                                return <Input fluid transparent value={s.boardLocation}
                                              onChange={(e) => this.props.dispatch(changeBomStockItemValue(bomStock.id, s.id, 'boardLocation', e.target.value))}/>
                            }
                        },
                        {
                            id: 5, header: 'Width', render: (s) => {
                                let width = '';
                                if (s.orderpart) {
                                    width = s.orderpart.width
                                } else if (s.kla) {
                                    width = s.kla.width
                                }

                                return <Input fluid transparent value={width}
                                              onChange={(e) => this.props.dispatch(changeBomStockItemValue(bomStock.id, s.id, 'width', e.target.value))}/>
                            }
                        },
                        {
                            id: 6, header: 'Pitch', render: (s) => {
                                let pitch = '';
                                if (s.orderpart) {
                                    pitch = s.orderpart.pitch
                                } else if (s.kla) {
                                    pitch = s.kla.pitch
                                }
                                return <Input fluid transparent value={pitch}
                                                     onChange={(e) => this.props.dispatch(changeBomStockItemValue(bomStock.id, s.id, 'pitch', e.target.value))}/>
                            }
                        },
                        {
                            id: 7, header: '', render: (s) => {
                                return <div>
                                    <Icon color={s.dirty ? 'blue' : null} name='save' link
                                          loading={s.busy}
                                          onClick={() => this.props.dispatch(saveBomStockItem(s, bomStock.id))}/>
                                </div>
                            }
                        },
                        {
                            id: 8, header: 'Details', render: (s) => {
                                return <div>
                                    <button className='link-button' onClick={() => this.setState({details: s})}>Show
                                        details
                                    </button>
                                </div>
                            }
                        },
                    ]}
                    data={data}
                    onSortAndFilter={(value) => {
                        alert(JSON.stringify(value))
                    }}
                />
            </DeltaTableMenu>

            {this.renderTransactions(bomStock)}

            {this.renderAddStockModal(bomStock)}

            {this.renderSetMpn(bomStock)}

            {JSON.stringify(this.props.stock)}
        </>;
    }
}

BomStockEdit.propTypes = {
    bomStock: PropTypes.object,
    loading: PropTypes.bool,
    pageView: PropTypes.bool,
};

BomStockEdit.defaultProps = {
    bomStock: [],
    loading: false,
    pageView: false,
};

const mapStateToProps = (state) => {
    return {
        currentUser: state.main.currentUser,
        lang: state.main.lang,
    }
}

export default connect(mapStateToProps)(withRouter(BomStockEdit))