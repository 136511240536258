import React, {Component} from 'react';
import './App.css';
import Translate from "./Translate";

class AboutItem extends Component {
    render() {
        return (
          <div className = {'aboutCard'}>
              <div className = {'photoContainer'}><img alt={this.props.name + "'s picture"} src={'/images/team/' + this.props.name + '.jpg'}/></div>
              <div className = {'cardContent'}>
                <h3 className = {'heading3'}><Translate value={'about_' + this.props.name + '_name'}/></h3>
                <h4 className = {'heading4'}><span className='date'><Translate value={'about_' + this.props.name + '_title'}/></span></h4>
                <p className = {'body3'}><Translate value={'about_' + this.props.name + '_text'}/></p>
              </div>
          </div>
      )
    }
}

class About extends Component {
    render() {
        return (
            <main id = {'about'} className = {'pageContainer'}>
                <AboutItem name={'arnoud'}/>
                <AboutItem name={'eddy'}/>
                <AboutItem name={'harm'}/>
                <AboutItem name={'maarten'}/>
                <AboutItem name={'michiel'}/>
                <AboutItem name={'wesley'}/>
            </main>
        );
    }
}

export default About;
