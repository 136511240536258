import React, {Component} from "react";
import {Button, Confirm, Icon, Table} from "semantic-ui-react";
import {deleteUser} from "../actions";
import connect from "react-redux/es/connect/connect";
import {formatDateTime} from "../actions/functions";

export function userDisplayName(user) {
    if( user ) {
        return user.firstName + ' ' + (user.tussenvoegsel ? user.tussenvoegsel + ' ' : '') + user.lastName
    }
    return ''
}

class AccountListItem extends Component {

    state= {
        confirm: false
    }

    selectUser(p) {
        if (this.props.onSelect) {
            this.props.onSelect(p)
        }
    }

    render() {

        let p = this.props.p;

        let deliveryAddress = p.deliveryAddress && p.deliveryAddress.filter(d => d.isDefault).length ? p.deliveryAddress.filter(d => d.isDefault)[0] : {}

        return <Table.Row>
            <Table.Cell>
                <Button icon color={'red'} onClick={() => this.setState({confirm: true})}>
                        <Icon name='trash' />
                </Button>
                <Confirm
                    open={this.state.confirm}
                    onCancel={() => this.setState({confirm: false})}
                    onConfirm={() => this.props.dispatch(deleteUser(p.id, () => {
                        this.setState({confirm: false})
                    }))}
                />
            </Table.Cell>
            <Table.Cell>
                <a href={'#/'} onClick={() => this.selectUser(p)}>{p.firstName}</a>
            </Table.Cell>
            <Table.Cell>
                {p.tussenvoegsel ? p.tussenvoegsel + ' ' : ''}<a href={'#/'}
                                                                 onClick={() => this.selectUser(p)}>{p.lastName}</a>
            </Table.Cell>
            <Table.Cell>
                <div>{p.telefoon}</div>
            </Table.Cell>
            <Table.Cell>
                <div>{p.mobiel}</div>
            </Table.Cell>
            <Table.Cell>
                <div>{p.email}</div>
            </Table.Cell>
            <Table.Cell>
                <div>{p.emailInvoice}</div>
            </Table.Cell>
            <Table.Cell>
                <div>{p.btwNumber}</div>
            </Table.Cell>
            <Table.Cell>
                <div>{p.kvkNumber}</div>
            </Table.Cell>
            <Table.Cell>
                {p.internalCompanyName}
            </Table.Cell>
            <Table.Cell>
                {p.lastProjectOrderInternalName}
            </Table.Cell>
            <Table.Cell>
                {formatDateTime(p.lastProjectOrderAanvraagDate) }
            </Table.Cell>
            <Table.Cell>
                {deliveryAddress.companyName}
            </Table.Cell>
            <Table.Cell>
                {deliveryAddress.zip}
            </Table.Cell>
            <Table.Cell>
                {deliveryAddress.number}
            </Table.Cell>
            <Table.Cell>
                {deliveryAddress.addition}
            </Table.Cell>
            <Table.Cell>
                {deliveryAddress.street}
            </Table.Cell>
            <Table.Cell>
                {deliveryAddress.city}
            </Table.Cell>
            <Table.Cell>
                {deliveryAddress.provincie}
            </Table.Cell>
            <Table.Cell>
                {deliveryAddress.country}
            </Table.Cell>
        </Table.Row>
    }
}

const mapStateToProps = (state) => {
    return {
    }
}

export default connect(mapStateToProps)(AccountListItem)