import React, {Component} from "react";
import {renderSupplier} from "./functions";
import connect from "react-redux/es/connect/connect";
import {Icon} from "semantic-ui-react";
import {getDesignatorColor} from "./functions";

const gap = {
    flex: 1,
}

const children = {
    textAlign: 'center',
    flex: 1
}

class BomSelectedDesignator extends Component {
    render() {


        const d = this.props.selectedDesignator;

        if (!d) {
            return <div style={{textAlign: 'center'}}>
                No designator selected
            </div>
        }

        const r = this.props.selectedDesignator.r;

        let color = 'white';
        if (this.props.bomProductionList && this.props.bomProductionSelected) {
            color = getDesignatorColor(r, d, null, null, this.props.bomProductionList.find(pl => pl.id === this.props.bomProductionSelected))
        }

        let p = null;
        let kla = null;

        if( d.r ) {
            if( d.r.selectedSupplierPart ) {
                p = d.r.selectedSupplierPart
            } else if( d.r.selectedBuddyPart ) {
                p = d.r.selectedBuddyPart
            } else if( d.r.klantLevertAan ) {
                kla = d.r.klantLevertAan
            }
        }

        return (
            <div>
            <div style={{display: 'flex', gap: 8}}>
                <div style={children}>
                    X:{isNaN(d.x) ? d.x : Math.round(d.x * 100) / 100}
                    {"   "}Y:{isNaN(d.y) ? d.y : Math.round(d.y * 100) / 100}
                </div>
                <div style={{...children, fontSize: '2em'}}>{d.value}</div>
                <div style={children}>
                    {p ? renderSupplier(p) : null}
                    {kla ? renderSupplier({supplier: 'KLANT'}) : null}
                </div>
                <div style={children}>{(p && p.orderpart) ? p.orderpart.specification.displayValue : null}</div>

                <div style={children}>{d.rotate}°</div>
                <div style={children}>{d.topBottom === "BOTTOM" ? "Bottom" : "Top"}</div>
                <div style={{...children, color: d.place ? null : 'red'}}>
                    {!d.place ? <div>
                        <Icon name='close' circular inverted color='red'/>
                    </div>: "Place"}
                </div>

            </div>
                <div style={{marginTop: 4, marginBottom: -8, height: 4, background: color}}></div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        selectedDesignator: state.bom.selectedDesignator,
        nextDesignator: state.bom.nextDesignator,
        lastGoToDesignator: state.bom.lastGoToDesignator,
        bomProductionList: state.bom.bomProductionList,
        bomProductionSelected: state.bom.bomProductionSelected,
    }
}

export default connect(mapStateToProps)(BomSelectedDesignator)
