import React, { Component } from 'react';
import connect from "react-redux/es/connect/connect";
import {withRouter} from "react-router-dom";
import {getURLParameter} from "./actions/functions";

class RegistrationConfirm extends Component {

    componentDidMount() {
        let id= getURLParameter("id");
        let tab= getURLParameter("tab");

        if( id && tab ) {
            this.props.history.push('/project/' + id + '/foto');
        }
        else if( id ) {
            this.props.history.push('/project/' + id);
        } else {
            this.props.history.push('/news');
        }
    }

    render() {
        return (
            <section className="pageContainer">
                <h1 className="heading1">404</h1>
                This is not the page you are looking for
            </section>
        );
    }
}

const mapStateToProps = (state) => {
    return {
    }
}

export default connect(mapStateToProps)(withRouter(RegistrationConfirm))
