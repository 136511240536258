import React from 'react'
import PropTypes from "prop-types";
import {Loader} from "semantic-ui-react";

class Pill extends React.Component {
    render() {
        const {color, onClick, loading} = this.props;

        return (
            <div style={{
                paddingLeft: 16, paddingRight: 16, backgroundColor: color, textAlign: "center",
                paddingTop: 8, paddingBottom: 8, borderRadius: "25px", marginRight: 5,
                cursor: onClick ? 'pointer' : null
            }} onClick={() => {
                if( !loading && onClick ) {
                    onClick()
                }
            }}>
                {loading ? <Loader active inline size='tiny'/> : this.props.children}
            </div>
        );
    }
}

Pill.propTypes = {
    color: PropTypes.string,
    onClick: PropTypes.func,
    loading: PropTypes.bool,
};

Pill.defaultProps = {
    color: ''
};

export default Pill