import React, {Component} from "react";
import {connect} from "react-redux";
import {Divider, Form, Input, Message} from "semantic-ui-react";
import {testParseBomValue} from "../../actions";

class BomTestImport extends Component {

    state = {
        designator: '',
        value: ''
    }

    componentDidMount() {
    }

    render() {

        const {value, designator} = this.state;

        const busy = this.props.bomTestParseBusy;
        const result = this.props.bomTestParseResult;
        const error = this.props.bomTestParseError;

        return (
            <Form>
                Test parse value
                <Form.Input label='Designator(s)' value={designator} onChange={e => this.setState({designator: e.target.value})}/>
                <Form.Input label="All value's" value={value} onChange={e => this.setState({value: e.target.value})}/>
                <Form.Button onClick={() => {
                    this.props.dispatch(testParseBomValue(designator, value))
                }} loading={busy} disabled={busy}>
                    Test
                </Form.Button>

                <Divider/>

                {result ? <div>
                    <h3>{result.displayValue}</h3>
                    <h3 style={{color: 'red'}}>{result.warning}</h3>
                    Resultaat:
                    <div style={{whiteSpace: 'pre'}}>
                        {JSON.stringify(result, null, 2)}
                    </div>
                </div> : ''}

                <div>
                    {error ? <Message negative>
                        <Message.Content>{error}</Message.Content>
                    </Message> : ''}
                </div>
            </Form>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        bomTestParseBusy: state.bom.bomTestParseBusy,
        bomTestParseResult: state.bom.bomTestParseResult,
        bomTestParseError: state.bom.bomTestParseError,
    }
}

export default connect(mapStateToProps)(BomTestImport)