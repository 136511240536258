import React, { Component } from 'react';

class PageNotFound extends Component {
    render() {
        return (
                <section className="pageContainer">
                    <h1 className="heading1">404</h1>
                    This is not the page you are looking for
                </section>
        );
    }
}

export default PageNotFound