import React from 'react'
import {
    changeImportCellValue,
    changeImportValue,
    closeImportData,
    deleteImportRow,
    getImportHeaders,
    getImportHeadersBom,
    getImportHeadersCentroid,
    importDataSelectSheet, importSetDecimalSeparator, importSetUnitOfMeasurement,
    receiveUploadImportData, receiveUploadImportDataRecognisedFormat,
    revertImportCellValue,
    startUploadImportData,
    toggleAggregateUpload
} from "../actions";
import UploadFile from "../oldui/UploadFile";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import DeltaTable from "../deltatable/DeltaTable";
import {Button, Checkbox, Dropdown, Icon, Input, Modal, Popup} from "semantic-ui-react";
import Highlighter from "react-highlight-words";

const headerColor = 'rgb(242,242,242)'
const activeHeaderColor = '#2e84cc'
const selectedHeaderColor = 'rgb(56,167,0)'

class BomImportAndEdit extends React.Component {

    state = {
        search: '',
        replace: '',
        editColumn: null,
        bom: {
            isBom: true,
            text: "Upload BOM (.xls/.xlsx)",

        },
        centroid: {
            isCentroid: true,
            text: "Upload Centroid (.xls/.xlsx)"
        },
        open: false
    }

    componentDidMount() {
        this.props.dispatch(getImportHeaders())
        this.props.dispatch(getImportHeadersBom())
        this.props.dispatch(getImportHeadersCentroid())
    }

    getImportHeaders() {
        return this.props.importHeaders;
    }

    getColumns(data, importRow) {
        const {importDataSelected, importData} = this.props;

        const importHeaders = this.getImportHeaders()

        let cols = data.length ? data[0].map((c, i) => {
            let char = String.fromCharCode(65 + i)
            const x = data[importRow][i] ? data[importRow][i] : {}
            return {
                id: i,
                defaultWidth: 300,
                header: <div style={{display: 'flex', gap: 8}}>

                    <div>{char}</div>
                    <div style={{flex: 1}}></div>

                    <div>
                        <Popup wide='very' position='bottom left' hoverable trigger={
                            <div style={{display: 'flex', width: '100%', color: ( x.header === 'NA' || x.header === 'UNKNOWN' ) ? activeHeaderColor : selectedHeaderColor}}>
                                <div>({x.header})</div>
                                <Icon name='caret down'/>
                            </div>
                        } content={<div style={{width: '256px', maxHeight: '256px', overflowY: 'auto', overflowX: 'hidden', padding: 8}} >

                            {importHeaders.map(h => <div>
                                <button className='link-button'
                                        onClick={e => this.props.dispatch(changeImportCellValue(importDataSelected,i,importRow,'header',h))}
                                >{h}</button>
                            </div>)}

                        </div>} style={{padding: 0}}/>
                    </div>
                    <div>

                        <Popup open={i === this.state.editColumn}
                               trigger={<Icon name='cut' color='white' link onClick={() => {
                                   if( i === this.state.editColumn ) {
                                       this.setState({editColumn: null})
                                   } else {
                                       this.setState({editColumn: i})
                                   }
                                 }}
                               />} position='top center' content={<div>
                            <div style={{position: 'absolute', top: 0, right: 0}}>
                                <Icon name='close' link onClick={() => this.setState({editColumn: null})} />
                            </div>
                            <div>
                                Find and replace
                            </div>

                            <div style={{display: 'flex', gap: 4, marginTop: 2}}>
                                <Input size='mini' placeholder='Search' value={this.state.search} onChange={(e) => this.setState({search: e.target.value})} icon={this.state.search ? { name: 'close', circular: false, link: true, onClick: () => this.setState({search: ''}) } : null}/>
                                <Input size='mini' placeholder='Replace' value={this.state.replace} onChange={(e) => this.setState({replace: e.target.value})} icon={this.state.replace ? { name: 'close', circular: false, link: true, onClick: () => this.setState({replace: ''}) } : null}/>
                                <Button size='mini' style={{width: 150}} onClick={() => {
                                    for (let k = importRow + 1; k < data.length; k++) {
                                        let newValue = data[k][i].value.replaceAll(this.state.search, this.state.replace)
                                        if( newValue !== data[k][i].value ) {
                                            this.props.dispatch(changeImportCellValue(importDataSelected,i,k,'value',newValue))
                                        }
                                    }
                                    this.setState({search: '', replace: ''})
                                }}>
                                    Replace All in Column
                                </Button>
                            </div>



                        </div>}/>
                    </div>
                </div>,
                getCellStyle: (c, j) => {
                    const beforeImport = (j < importRow);
                    const isImportRow = (j === importRow);
                    const x = c[i] ? c[i] : {}

                    let style = {}

                    if( isImportRow ) {
                        if( x.header === 'NA' || x.header === 'UNKNOWN' ) {
                            style.backgroundColor = activeHeaderColor;
                        } else {
                            style.backgroundColor = selectedHeaderColor;
                        }
                    }

                    return style
                },
                render: (c, j) => {
                    const beforeImport = (j < importRow);
                    const isImportRow = (j === importRow);
                    const x = c[i] ? c[i] : {}

                    const edit = (i === this.state.editColumn) && !beforeImport && !isImportRow;

                    return <div style={{display: 'flex', gap: 8}}>

                        {(edit && !isImportRow) ?
                            <div style={{flex: 1}}>
                                <Highlighter highlightClassName="Highlight" searchWords={[this.state.search]}
                                             textToHighlight={x.value}
                                />
                            </div>
                            :
                            <Input disabled={beforeImport} style={{flex: 1}}
                                   fluid transparent value={x.value}
                                   onChange={e => this.props.dispatch(changeImportCellValue(importDataSelected, i, j, 'value', e.target.value))}
                            />}

                        {(x.dirty && !isImportRow) ? <Popup trigger={<Icon link name='history' color='purple' onClick={() => {
                            this.props.dispatch(revertImportCellValue(importDataSelected, i, j))
                        }}/>} position='top center' content={<div>
                            <div>Original value:</div>
                            <div>{JSON.stringify(x.original.value)}</div>
                            <div>Click to revert back</div>
                        </div>}/> : null }
                    </div>
                },
                headerStyle: {backgroundColor: headerColor}
            }
        }) : []

        if( cols.length ) {
            return [
                {
                    id: -1,
                    defaultWidth: 50,
                    header: <div style={{textAlign: 'center'}}></div>,
                    getCellStyle: (c, j) => {
                        return {backgroundColor: headerColor}
                    },
                    render: (c,i) => <div style={{textAlign: 'center'}}>
                        {/*{(i === importRow) ?
                        <Popup trigger={<div>{i+1}</div>} content={<p>This row is used as the import header</p>}/> :
                        <button className='link-button' disabled={(i === importRow)} onClick={() => this.props.dispatch(changeImportValue(importDataSelected, 'importRow', i))}>{i+1}</button>}*/}

                        <Dropdown trigger={i+1}>
                            <Dropdown.Menu>
                                <Dropdown.Item
                                    onClick={() => this.props.dispatch(changeImportValue(importDataSelected, 'importRow', i))}
                                >Set as header</Dropdown.Item>
                                <Dropdown.Item
                                    onClick={() => this.props.dispatch(deleteImportRow(importDataSelected, i))}
                                >Delete line</Dropdown.Item>
                                {/*<Dropdown.Item
                                    onClick={() => {
                                        for (let k = i+1; k < data.length; k++) {
                                            //issue: the data does not have indexes!
                                            this.props.dispatch(deleteImportRow(importDataSelected, k))
                                        }
                                    }}
                                >Delete All lines below this line</Dropdown.Item>*/}
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>,
                    headerStyle: {backgroundColor: headerColor},
                    cellStyle: {backgroundColor: headerColor, fontWeight: 'bold'},
                },
                ...cols
            ]
        }
        return cols;
    }

    renderContent(d) {
        const {importDataSelected, importData} = this.props;

        let data = d.data;
        let importRow = d.importRow;

        return (
            <div>
                <div>
                    <DeltaTable
                        loading={this.props.loadingImportData}
                        data={data}
                        columns={this.getColumns(data, importRow)}
                    />

                    <div className='tabbuttons'>
                        {importData.map(d => <button
                            className={'tabbutton' + (importDataSelected === d.sheetName) ? ' active' : ''}
                            onClick={() => this.props.dispatch(importDataSelectSheet(d.sheetName))}
                        >{d.sheetName}</button>)}
                    </div>

                </div>
            </div>
        );
    }

    getValues() {

        if( this.props.type === 'bom' ) {
            return this.state.bom;
        }

        return this.state.centroid;
    }

    renderUpload() {
        const centroid = this.props.type === 'centroid' ? 'true' : 'false';
        const id = this.props.printId ? this.props.printId : '';
        return <div>
            <UploadFile style={{}} icon={false} button={this.props.button} primary={true} text={this.props.text ? this.props.text : this.getValues().text}
                    url={`/api/bom/fileToGrid?centroid=${centroid}&id=${id}`}
                    color={'red'}
                    onStart={() => {
                        this.props.dispatch(startUploadImportData())
                        this.setState({open: true})
                    }} onResult={(body) => {
                        if( body && body.key && body.key.nameCentroid ) {
                            if (this.props.onReceivePrint) {
                                this.props.onReceivePrint(body)
                            }
                            this.props.dispatch(receiveUploadImportDataRecognisedFormat(body))
                        } else {
                            this.props.dispatch(receiveUploadImportData(body))
                        }
                    }}
            />
        </div>
    }

    getSelectedData() {
        const {importDataSelected, importData} = this.props;

        let foundData = {};

        if( importData && importDataSelected ) {
            let foundData = importData.find(d => d.sheetName === importDataSelected)
            if( foundData && foundData.data ) {
                return foundData;
            }
        }

        return foundData
    }

    close = () => {
        this.setState({open: false})
        this.props.dispatch(closeImportData())
    }

    render() {
        if( this.props.importDataRecognisedFormat ) {
            return <Modal open={this.state.open} closeIcon onClose={this.close}>
                <Modal.Header>
                    Import data recognised
                </Modal.Header>
                <Modal.Content>
                    Data is imported successfully.
                </Modal.Content>
                <Modal.Actions>
                    <Button onClick={this.close}>Close</Button>
                </Modal.Actions>
            </Modal>
        }

        const hasData = !!(this.props.importData && this.props.importDataSelected)

        let data = this.getSelectedData()

        if( this.props.asModal ) {
            if( !hasData ) {
                return this.renderUpload()
            }
            return <Modal open={this.state.open} closeIcon onClose={this.close} size='fullscreen'>
                <Modal.Header>
                    Import data
                </Modal.Header>
                <Modal.Content>
                    {this.renderContent(data)}
                </Modal.Content>
                <Modal.Actions>
                    {this.getValues().isBom ? <Checkbox style={{marginTop: 10}} label={'Regels samenvoegen'} checked={this.props.aggregateUpload}
                               onChange={() => this.props.dispatch(toggleAggregateUpload())}
                    /> : <span>
                        Unit of measurement:{"    "}
                        <Dropdown
                            selection
                            value={this.props.unitOfMeasurement}
                            options={[{key: '1', value: 'mm', text: 'mm (millimeter)'}, {key: '2', value: 'mil', text: 'mil (thousandth of an inch)'}]}
                            onChange={(e, d) => this.props.dispatch(importSetUnitOfMeasurement(d.value))}
                        />
                        {"    "}Decimal separator:{"    "}
                        <Dropdown
                            selection
                            value={this.props.decimalSeparator}
                            options={[{key: '1', value: ',', text: ', (comma)'}, {key: '2', value: '.', text: '. (point)'}]}
                            onChange={(e, d) => this.props.dispatch(importSetDecimalSeparator(d.value))}
                        />
                    </span>}
                    <Button onClick={this.close}>Annuleren</Button>
                    <Button primary loading={this.props.importing} onClick={() => {
                        if( this.props.onImport ) {
                            this.props.onImport(data)
                        }
                    }}>Import</Button>
                </Modal.Actions>
            </Modal>
        }

        if( !hasData ) {
            return this.renderUpload()
        }

        if( this.state.open ) {
            return this.renderContent()
        }

        return <></>
    }
}


BomImportAndEdit.propTypes = {
    printId: PropTypes.number,
    onImport: PropTypes.func,
    asModal: PropTypes.bool,
    type: PropTypes.oneOf(['bom', 'centroid']),
    color: PropTypes.string,
    text: PropTypes.string,
    button: PropTypes.bool,
    key: PropTypes.string.isRequired
}

BomImportAndEdit.defaultProps = {
    printId: null,
    onImport: null,
    asModal: true,
    type: 'bom',
    color: 'blue',
    text: null,
    button: true,
    key: null
}

const mapStateToProps = (state) => {
    return {
        currentUser: state.main.currentUser,
        loadingImportData: state.bom.loadingImportData,
        importDataRecognisedFormat: state.bom.importDataRecognisedFormat,
        import: state.bom.import,
        importData: state.bom.importData,
        importDataSelected: state.bom.importDataSelected,
        importHeaders: state.fileimport.importHeaders,
        importHeadersCentroid: state.fileimport.importHeadersCentroid,
        importHeadersBom: state.fileimport.importHeadersBom,
        importing: state.fileimport.importing,
        decimalSeparator: state.fileimport.decimalSeparator,
        unitOfMeasurement: state.fileimport.unitOfMeasurement,
        aggregateUpload: state.bom.aggregateUpload,
    }


}

export default connect(mapStateToProps)(BomImportAndEdit)