import React from 'react'
import {Button, Divider, Form, Icon, Input} from "semantic-ui-react";
import {connect} from "react-redux";
import {
    changeImportPartValue,
    searchDatabaseTest,
    searchDatabaseTestMpn,
    searchDatabaseTestSelect,
    updateOrderpart
} from "../actions";
import {formatDateTime} from "../actions/functions";
import {getPriceForAmount, renderSupplier, renderSupplierUrl} from "./functions";
import {renderImageForPart} from "./BomOrderparts";
import TableResize from "../basic/TableResize";

const options = ["AVX","Kemet","Vishay","Murata","Wurth","Yageo"].map(v => ({key: v, text: v, value: v}))

class BomSearchParts extends React.Component {

    state = {
        manufacturers: ["AVX","Kemet","Vishay","Murata","Wurth","Yageo"]
    }

    handleSubmit = (event) => {
        event.preventDefault();
        console.log(JSON.stringify(this.state));

        this.props.dispatch(searchDatabaseTest(this.state));
        this.props.dispatch(searchDatabaseTestSelect(this.state));
    }

    handleSubmitMpn = (event) => {
        event.preventDefault();
        console.log(JSON.stringify(this.state));

        this.props.dispatch(searchDatabaseTestMpn(this.state));
        //this.props.dispatch(searchDatabaseTest(this.state));
        //this.props.dispatch(searchDatabaseTestSelect(this.state));
    }

    handleChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    getSelectedColor = (s) => {
        if( this.props.bomSearchTestSelected && s ) {
            if( s.sku === this.props.bomSearchTestSelected.sku ) {
                return {backgroundColor: 'rgba(47,186,81,0.51)'}
            }
        }

        return {}
    }

    render() {
        return (
            <div>
                <Form onSubmit={this.handleSubmit}>
                    <Form.Group widths='equal'>
                        <Form.Input name="designators" label="designator(s)" onChange={this.handleChange}/>
                        <Form.Input name="value" label="value (Fahrad or Ohm)" onChange={this.handleChange}/>
                        <Form.Input name="toleranceValueMax" label="tolerance" onChange={this.handleChange}/>
                        <Form.Input name="value2" label="value2 (Volt or Watt)" onChange={this.handleChange}/>
                        <Form.Input name="packageOutline" label="package" onChange={this.handleChange}/>

                        <Form.Dropdown value={this.state.manufacturers} label="manufacturers" multiple selection options={options} onChange={(e,d) => this.setState({manufacturers: d.value})}/>
                        <Form.Button style={{marginTop: 24}} type="submit" value="Submit" >Zoeken</Form.Button>
                    </Form.Group>
                </Form>

                <Divider/>

                <Form onSubmit={this.handleSubmitMpn}>
                    <Form.Group>
                        <Form.Input style={{width: 300}} name="query" label="query" onChange={this.handleChange}/>

                        <Form.Button style={{marginTop: 24}} type="submit" value="Submit" >Zoeken</Form.Button>
                    </Form.Group>
                </Form>

                <div style={{textAlign: 'right', color: this.props.matchError ? 'red' : ''}}>
                    {this.props.matching ? 'Beste part selecteren 😴' : ''}
                    {this.props.matchError ? 'Fout bij selectie 😢' : ''}
                </div>

                <Divider/>

                <div>
                    <TableResize
                        defaultWidth={200}
                        headers={[
                            {name: 'mpn', text: 'MPN'},
                            {name: 'manufacturer', text: 'manufacturer'},
                            {name: 'octopartUrl', text: 'link', width: 40},
                            {name: 'image', text: 'image', width: 50},
                            {name: 'shortDescription', text: 'short description', width: 240},
                            {name: 'dpBuddy', text: 'Buddy part', width: 50},
                            {name: 'dpChoice', text: 'dpChoice', width: 50},
                            {name: 'type', text: 'type', width: 100},
                            {name: 'specification', text: 'specification', width: 240},
                            {name: 'lastUpdate', text: 'last update', width: 100},
                            {name: 'suppliers', text: 'suppliers', width: 300},
                            {name: 'price', text: 'price', width: 70},
                            {name: 'price10', text: 'price 10', width: 70},
                            {name: 'stock', text: 'stock', width: 70},
                            {name: 'pitch', text: 'pit', width: 40},
                            {name: 'width', text: 'wit', width: 40},
                        ]}
                        values={this.props.bomSearchTest}
                        render={(name, value) => {

                            switch (name) {
                                case 'dpBuddy':
                                    return <div style={{textAlign: 'center'}}>
                                        {value.buddyPart ? <Icon name='factory' /> : ''}
                                    </div>
                                case 'dpChoice':
                                    return <div style={{textAlign: 'center'}}>
                                        <Icon name={value.dpChoice ? 'heart' : 'heart outline'} color='red'/>
                                    </div>
                                case 'lastUpdate':
                                    let lastUpdate = null;
                                    value.suppliers.forEach((s) => {
                                        if( lastUpdate == null || s.lastUpdate < lastUpdate ) {
                                            lastUpdate = s.lastUpdate
                                        }
                                    })

                                    return <div>
                                        <div>{lastUpdate ? formatDateTime(lastUpdate) : ''}</div>

                                    </div>
                                case 'suppliers':
                                    return <div>
                                        {value.suppliers.map(s => (<div style={this.getSelectedColor(s)}>
                                            {renderSupplier(s)}
                                            {renderSupplierUrl(s)}
                                        </div>))}
                                    </div>
                                case 'price':
                                    return <div>
                                        {value.suppliers.map(s => (<div style={this.getSelectedColor(s)}>
                                            {getPriceForAmount(s, 1)}
                                        </div>))}
                                    </div>
                                case 'price10':
                                    return <div>
                                        {value.suppliers.map(s => (<div style={this.getSelectedColor(s)}>
                                            {getPriceForAmount(s, 10)}
                                        </div>))}
                                    </div>
                                case 'stock':
                                    return <div>
                                        {value.suppliers.map(s => (<div style={this.getSelectedColor(s)}>
                                            {s.stock ? s.stock : '?'}
                                        </div>))}
                                    </div>
                                case 'octopartUrl':
                                    return <div>
                                        <a href={value[name]}>link</a>
                                        <div>
                                            <div>
                                                <Button icon onClick={() => this.props.dispatch(updateOrderpart(value.id))}>
                                                    <Icon name='refresh' />
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                case 'image':
                                    return <div>
                                        {renderImageForPart(value)}
                                    </div>
                                case 'specification':
                                    return <div>
                                        {value.specification && value.specification.displayValue}
                                    </div>
                            }
                            return value[name]
                        }}
                    />
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        bomSearchTest: state.bom.bomSearchTest,
        bomSearchTestSelected: state.bom.bomSearchTestSelected,
        matching: state.bom.matching,
        matchError: state.bom.matchError,
    }
}

export default connect(mapStateToProps)(BomSearchParts)