import React from 'react'
import {Button, Dropdown, Form, Icon} from "semantic-ui-react";
import {changePrintValue, convertCentroid, openCheckinOrders, saveBom} from "../actions";
import {connect} from "react-redux";
import {encodeQueryData} from "../actions/functions";
import Translate from "../Translate";

const toKTV = (v) => ({key: v, text: v, value: v})

const capTolOptions = ['1%', '5%', '10%'].map(toKTV)

const capVoltageOptions = ['4V', '6.3V', '10V', '16V', '25V', '50V', '100V'].map(toKTV)

const resTolOptions  = ['1%', '5%', '10%'].map(toKTV)

const resPowerOptions = ['0.0625W', '0.1W', '0.125W', '0.250W', '0.5W'].map(toKTV)


function getSetDefaultValues(props) {
    let cap = {
        toleranceValueMax: (props.print.setSpecificationCap && props.print.setSpecificationCap.toleranceValueMax) ? props.print.setSpecificationCap.toleranceValueMax : props.print.specificationCap.toleranceValue,
        value2: (props.print.setSpecificationCap && props.print.setSpecificationCap.value2) ? props.print.setSpecificationCap.value2 : props.print.specificationCap.voltageRating,
    }

    let res = {
        toleranceValueMax: (props.print.setSpecificationRes && props.print.setSpecificationRes.toleranceValueMax) ? props.print.setSpecificationRes.toleranceValueMax : props.print.specificationRes.toleranceValue,
        value2: (props.print.setSpecificationRes && props.print.setSpecificationRes.value2) ? props.print.setSpecificationRes.value2 : props.print.specificationRes.powerRating,
    }

    return {res, cap}
}

class BomDefaultValues extends React.Component {

    render() {
        let props = this.props;

        let {res, cap} = getSetDefaultValues(props)

        return <div>

            <div>
                <Button onClick={() => props.dispatch(convertCentroid(props.print.id, true))}>Convert from mils to mm</Button>
            </div>

            <div>
                <Button color='purple' onClick={() => props.dispatch(openCheckinOrders(true))}>
                    Ongeselecteerde bestellingen
                </Button>
            </div>

            <div>
                <Button as='a' primary icon labelPosition='left'
                        href={'/api/bom/downloadCustomerBom' + encodeQueryData({id: props.print.id})} download>
                    <Icon name='download'/>
                    ProtoMemo Excel
                </Button>
            </div>

            <Form>
                <Form.Input autoComplete="off" type='number' min={1} step={1} label='Aantal printen'
                            value={props.print.newAmount ? props.print.newAmount : props.print.amount}
                            onChange={(e) => props.dispatch(changePrintValue('newAmount', e.target.value))}/>
                <p style={{color: 'black'}}>
                    Geef hieronder voor generieke <u>condensatoren</u> de minimale tolerantie en het vermogen
                    aan<br/>
                    Default: 10% 10V
                </p>
                <Form.Group>
                    <Dropdown fluid icon={null} inline={true} trigger={
                        <Form.Input autoComplete="off" label='Capacitor tolerance' name='toleranceValueMax'
                                    value={cap.toleranceValueMax}
                                    onChange={(e) => props.dispatch(changePrintValue('setSpecificationCap', {
                                        ...props.print.setSpecificationCap, toleranceValueMax: e.target.value
                                    }))}/>
                    } onChange={(e, d) => props.dispatch(changePrintValue('setSpecificationCap', {
                        ...props.print.setSpecificationCap, toleranceValueMax: d.value
                    }))} options={capTolOptions}/>

                    <Dropdown fluid icon={null} inline={true} trigger={
                        <Form.Input autoComplete="off" label='Volt' name='value2'
                                    value={cap.value2}
                                    onChange={(e) => props.dispatch(changePrintValue('setSpecificationCap', {
                                        ...props.print.setSpecificationCap, value2: e.target.value
                                    }))}/>
                    } onChange={(e, d) => props.dispatch(changePrintValue('setSpecificationCap', {
                        ...props.print.setSpecificationCap, value2: d.value
                    }))} options={capVoltageOptions}/>

                </Form.Group>
                <p style={{color: 'black'}}>
                    Geef hieronder voor generieke <u>weerstanden</u> de tolerantie en het vermogen aan<br/>
                    Default: 1% 0.0625W (1/8)
                </p>
                <Form.Group>

                    <Dropdown fluid icon={null} inline={true} trigger={
                        <Form.Input autoComplete="off" label='Resistor tolerance' name='toleranceValueMax'
                                    value={res.toleranceValueMax}
                                    onChange={(e) => props.dispatch(changePrintValue('setSpecificationRes', {
                                        ...props.print.setSpecificationRes, toleranceValueMax: e.target.value
                                    }))}/>
                    } onChange={(e, d) => props.dispatch(changePrintValue('setSpecificationRes', {
                        ...props.print.setSpecificationRes, toleranceValueMax: d.value
                    }))} options={resTolOptions}/>

                    <Dropdown fluid icon={null} inline={true} trigger={
                        <Form.Input autoComplete="off" label='Watt' name='value2'
                                    value={res.value2}
                                    onChange={(e) => props.dispatch(changePrintValue('setSpecificationRes', {
                                        ...props.print.setSpecificationRes, value2: e.target.value
                                    }))}/>
                    } onChange={(e, d) => props.dispatch(changePrintValue('setSpecificationRes', {
                        ...props.print.setSpecificationRes, value2: d.value
                    }))} options={resPowerOptions}/>
                </Form.Group>

                <Form.Button primary onClick={() => {
                    let {res, cap} = getSetDefaultValues(props)
                    props.dispatch(saveBom(props.print.id, {
                        name: props.print.name,
                        amount: props.print.newAmount ? props.print.newAmount : props.print.amount,
                        resistor: {...res, value: '1'},
                        capacitor: {...cap, value: '1nF'},
                    }))
                }}>
                    <Translate value="save"/>
                </Form.Button>
            </Form>
        </div>
    }

}

const mapStateToProps = (state) => {
    return {
        print: state.bom.print,
    }
}

export default connect(mapStateToProps)(BomDefaultValues)