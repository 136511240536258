import React from 'react';
import {Card, Header, Input, Message, Segment} from "semantic-ui-react";
import {regsiterForNewsletter} from "./actions";
import {connect} from "react-redux";

class MicroPlacerNew extends React.Component {

    state = {
        email: ''
    }

    renderSignup() {
        return <div>

            <div style={{maxWidth: 400, padding: '2em', margin: 'auto'}}>
                <p>
                    Sign up for our free newsletter and we will be happy to keep you updated!
                </p>

                <Input action={{
                    content: 'Subscribe',
                    color: 'red',
                    disabled: !this.state.email,
                    loading: this.state.loading,
                    onClick: () => {
                        this.setState({loading: true})
                        this.props.dispatch(regsiterForNewsletter(this.state.email, () => {
                            this.setState({email: '', thankYou: true, loading: false})
                        }))
                    }
                }} placeholder='Email address' onChange={ (e) => {
                    this.setState({email: e.target.value})
                }} onKeyDown={(e) => {
                    if( e.key === 'Enter' ) {
                        this.setState({loading: true})
                        this.props.dispatch(regsiterForNewsletter(this.state.email, () => {
                            this.setState({email: '', thankYou: true, loading: false})
                        }))
                    }
                }}
                       fluid
                       value={this.state.email} type="email"/>

                {this.state.thankYou ? <Message positive>
                    <Message.Content>
                        Thanks for subscribing!
                    </Message.Content>
                </Message> : ''}
            </div>
        </div>
    }

    render() {

        return (
            <div className='microplacermain'>
                <div className='microplacermainhero'>
                    <div>
                        <img className='microplacerimg' src={'https://www.deltaproto.com/images/up/uplacer1.jpg'}/>
                        {/*<div className='microplacerherotext'>
                            <h1>MicroPlacer</h1>
                        </div>*/}
                    </div>

                </div>
                <div className='microplacersmallhero'>
                    {this.renderSignup()}
                </div>
                <div className='microplacersmallhero'>
                    <div style={{display: 'flex', marginTop: 0, gap: '2em'}}>
                        <div style={{flex: 1}}>
                            <img className='microplacerimg' src={'https://www.deltaproto.com/images/up/delta01.jpg'}/>
                        </div>
                        <div style={{flex: 1}}>
                            <img className='microplacerimg' src={'https://www.deltaproto.com/images/up/delta05.jpg'}/>
                        </div>
                    </div>
                </div>


                <div className='microplacersmallhero'>
                    <img className='microplacerimg' src={'https://www.deltaproto.com/images/up/Micropl_Hi_0184.jpg'}/>
                </div>

                <div className='microplacersmallhero'>
                    <div style={{display: 'flex', marginTop: 0, gap: '2em'}}>
                        <div style={{flex: 1}}>
                            <img className='microplacerimg' src={'https://www.deltaproto.com/images/up/delta04.jpg'}/>
                        </div>
                        <div style={{flex: 1}}>
                            <img className='microplacerimg' src={'https://www.deltaproto.com/images/up/delta15.jpg'}/>
                        </div>
                    </div>
                </div>

                <div className='microplacersmallhero'>
                    <img className='microplacerimg' src={'https://www.deltaproto.com/images/up/delta12.jpg'}/>
                </div>



                <div className='microplacersmallhero'>
                    <div style={{display: 'flex', marginTop: 0, gap: '2em'}}>
                        <div style={{flex: 1}}>
                            <img className='microplacerimg' src={'https://www.deltaproto.com/images/up/delta22.jpg'}/>
                        </div>
                        <div style={{flex: 1}}>
                            <img className='microplacerimg' src={'https://www.deltaproto.com/images/up/delta25.jpg'}/>
                        </div>
                    </div>
                </div>


                <div className='microplacersmallhero'>
                    <img className='microplacerimg' src={'https://www.deltaproto.com/images/up/Micropl_Hi_0203.jpg'}/>
                </div>

                <div className='microplacersmallhero'>
                    <img className='microplacerimg' src={'https://www.deltaproto.com/images/up/delta36.jpg'}/>
                </div>

                <div className='microplacersmallhero' style={{textAlign: 'center', paddingTop: 64, paddingBottom: 96}}>
                    <h1>
                        € 9950 and € 79 per month.
                    </h1>

                    <a
                        href="mailto:harm@deltaproto.com?subject=MicroPlacer"
                    >
                        <button style={{marginTop: 16}} className="ui red button">Contact</button>
                    </a>
                </div>

                <div className='microplacersmallhero'>
                    {this.renderSignup()}
                </div>

                <div className='microplacersmallhero' style={{textAlign: 'center', paddingTop: 64, paddingBottom: 96}}>
                    <div>
                        <h1>Specifications</h1>
                    </div>
                    <img style={{maxWidth: 450}} src={'https://www.deltaproto.com/images/up/specs.jpg'}/>
                </div>
            </div>
        );
    }
}

MicroPlacerNew.propTypes = {
};

MicroPlacerNew.defaultProps = {
};

const mapStateToProps = (state) => {
    return {
    }
}

export default connect(mapStateToProps)(MicroPlacerNew)