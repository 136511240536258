import React from "react";
import connect from "react-redux/es/connect/connect";
import {getEmployeeHours, saveEmployeeHours, setEmployeeHoursStartDate} from "./actions";
import {isEmployee, isTestUser} from "./actions/functions";
import {Button, Table, TableRow} from "semantic-ui-react";
import DatePicker from "react-datepicker";
import EmployeeHoursDay from "./EmployeeHoursDay";
import {renderEuro} from "./bom/BomOrderparts";
import {Link} from "react-router-dom";
import Translate from "./Translate";


var moment = require('moment');
moment.locale('NL');


class EmployeeHours extends React.Component {

    state = {
        startDate: new Date()
    }

    startDate = new Date();
    componentDidMount() {
        this.setState({startDate: new Date()})
        this.props.dispatch(getEmployeeHours())
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(
            (prevProps.currentUser == null && this.props.currentUser) ||
            (this.props.currentUser && prevProps.currentUser.id !== this.props.currentUser.id)
        ) {
            this.props.dispatch(getEmployeeHours())
        }
    }

    renderMonthControl() {
        return <DatePicker
            onKeyDown={(e) => e.preventDefault()}
            selected={this.props.startDate}
            onChange={date => this.props.dispatch(setEmployeeHoursStartDate(date))}
            dateFormat="MMMM yyyy"
            todayButton={'Vandaag'}
            showMonthYearPicker
            disabledKeyboardNavigation
        />
    }

    render() {
        if( this.props.isOverview ) {

            let users = this.props.hours.filter(u => u.user).map(u => u.user);

            users = [...new Map(users.map(item => [item['id'], item])).values()];

            users.sort(((a,b)=> (a.fullName.toLowerCase() > b.fullName.toLowerCase() ? 1 : -1)))

            return <div>

                {this.renderMonthControl()}

                <Table>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell><Translate value="medewerker"/></Table.HeaderCell>
                            <Table.HeaderCell><Translate value="uren"/></Table.HeaderCell>
                            <Table.HeaderCell><Translate value="reiskosten"/></Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {users.map((u, i) => this.renderForUser(u))}
                    </Table.Body>
                </Table>

            </div>
        } else {
            return <main id={'employee-hours'} className={'pageContainer'}>
                {this.renderMonthControl()}

                {this.renderForUser(this.props.currentUser)}
            </main>
        }
    }


    renderForUser(currentUser) {
        const employee= isEmployee(currentUser) || isTestUser(currentUser);

        if( currentUser == null ) {
            return <></>
        }

        const weeks= [];
        for(let i=1; i<53; i++){
            weeks[i-1] = {key: i, value: i, text: 'Week ' + i};
        }

        const months = ["Januari","Februari","Maart","April","Mei","Juni","Juli","Augustus","September","Oktober","November","December"];
        const days = ["Zondag", "Maandag", "Dinsdag", "Woensdag", "Donderdag", "Vrijdag", "Zaterdag"]
        const today = this.props.startDate
        const mm = today.getMonth(); // keep track of the month
        const year = today.getFullYear(); // keep track of the year
        const day = today.getDay();
        const daysInMonths = [31, (((year%4==0)&&(year%100!=0))||(year%400==0)) ? 29 : 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

        let html=[];
        let headers=[];
        let cells=[];
        let daysaftercells=[];
        let rows=[];
        let datum = new Date();
        let daybegin= 0;
        let dayend= 0;
        let existinghours = null;
        let headeramount = 0;
        const viewportwidth = window.innerWidth;
        let totalhours = 0;
        let totalCommute = 0;


        if(viewportwidth<=720){
            if(daysInMonths[mm] <= 28){
                headeramount = 4;
            }
            else if(daysInMonths[mm] === 30 && (day === 0) || daysInMonths[mm] === 31 && (day === 5 || day === 0)){
                headeramount = 6;
            }
            else{
                headeramount = 5;
            }
        }
        else{
            headeramount=1
        }

        for(let x=1; x<=headeramount; x++) {
            for (let i = 1; i < 7; i++) {
                headers.push(<Table.HeaderCell>{days[i]}</Table.HeaderCell>);
            }
            headers.push(<Table.HeaderCell>{days[0]}</Table.HeaderCell>);
        }


        for(let i=1; i<=daysInMonths[mm]; i++){
            datum = new Date(year, mm, i, 12, 0);
            daybegin = new Date(year, mm, i, 0, 0).getTime();
            dayend = new Date(year, mm, i, 23, 59).getTime();

            if(i === 1 && datum.getDay() !== 1 && datum.getDay() !== 0) {
                for (let j = 0; j < datum.getDay(); j++) {
                    if (j > 0) {
                        cells.push(<Table.Cell key={i+'-'+j}/>);
                    }
                }
            }

            if(i === 1 && datum.getDay() === 0){
                for (let j = 0; j < 7; j++) {
                    if (j > 0) {
                        cells.push(<Table.Cell key={i+'-'+j}/>);
                    }
                }
            }
            existinghours = this.props.hours.filter((hours) => hours.startDate >= daybegin && hours.startDate <= dayend && hours.user.id === currentUser.id);
            if(existinghours.length) {
                let starttime = existinghours[0].startDate;
                let endtime = existinghours[0].endDate;
                let startdate = new Date(starttime);
                let enddate = new Date(endtime);
                totalhours += endtime - starttime;
                if(enddate.getHours() >= 13 && (startdate.getHours() === 12 && startdate.getMinutes() <= 30 || startdate.getHours() <= 11)){
                    totalhours -= 1800000;
                }
                if((startdate.getHours() === 12 && startdate.getMinutes() === 45) || (enddate.getHours() === 12 && enddate.getMinutes() === 45)){
                    totalhours -= 900000;
                }

                totalCommute += existinghours[0].commuteCost;
            }

            cells.push(
                <EmployeeHoursDay
                    key={'empl'+i}
                    datum={datum}
                    existinghours={existinghours}
                    frozen={this.state.frozen}
                    deletebuttons={this.state.deletebuttons}
                    commuteCost={existinghours.length ? existinghours[0].commuteCost : 0}
                />
            );
            if(datum.getDay() === 0){
                rows.push(<Table.Row>{cells ? cells : ''}</Table.Row>);
                cells =[];
            }

            if(datum.getDate() === daysInMonths[mm]){
                if(datum.getDay() !== 0){
                    for(let j=datum.getDay()+1; j<=7; j++){
                        daysaftercells.push(<Table.Cell key={'after'+i}/>);
                    }
                    rows.push(<TableRow>{cells ? cells : ''}{daysaftercells ? daysaftercells : ''}</TableRow>);
                }
                html.push(<Table verticalAlign='center' className='employee-hours-table' celled columns={7}><Table.Header><Table.Row>{headers}</Table.Row></Table.Header><Table.Body>{rows}</Table.Body></Table>);
                cells =[];
            }
        }

        return <>
            {employee && <>

                {this.props.isOverview ? <>

                    <Table.Row>
                        <Table.Cell>{currentUser.fullName}</Table.Cell>
                        <Table.Cell>{totalhours/3600000}</Table.Cell>
                        <Table.Cell>{renderEuro(totalCommute)}</Table.Cell>
                    </Table.Row>

                </> : <>
                    <h1><Translate value="uren_van"/> <b>{currentUser.firstName}</b></h1>
                    {html}
                    <Button style={{float: 'top'}} icon={'trash'} disabled={this.state.frozen} color={this.state.deletebuttons ? 'red' : 'lightgrey'} onClick={() => {this.setState({deletebuttons: !this.state.deletebuttons})}}/>
                    <br/>
                    <br/>
                    <Translate value="totaal_aantal_uren_gewerkt_in"/> {months[mm]} door {currentUser.firstName}: {totalhours/3600000} <Translate value="en_gemaakte_reiskosten"/> {renderEuro(totalCommute)}
                    <Button className={'hiddenbutton'} onClick={() => {this.setState({frozen: !this.state.frozen})}}/>
                </>}


            </>}
        </>
    }
}

const mapStateToProps = (state) => {
    return {
        currentUser: state.main.currentUser,
        lang: state.main.lang,
        currentLang: state.main.currentLang,
        hours: state.employee.hours,
        startDate: state.employee.startDate,
    }
}

export default connect(mapStateToProps)(EmployeeHours)