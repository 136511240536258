import React, {Component} from 'react';
import connect from "react-redux/es/connect/connect";
import './App.css';
import {Form, Header, Message, Popup} from "semantic-ui-react";
import Translate from "./Translate";
import {submitContactForm} from "./actions";

class Contact extends Component {

    state = {
        firstname: '',
        lastname: '',
        email: '',
        phone: '',
        message: '',
    }

    render() {
        return (
            <section className='pageContainer'>

                <div style={{textAlign: 'left', margin: 'auto', maxWidth: 600, paddingTop: 64, paddingBottom: 64}}>

                    <Header as='h1'>Contact</Header>
                    <Form>
                        <Form.Input label={<Translate value='user_firstname'/>} value={this.state.firstname} onChange={e => this.setState({'firstname': e.target.value})}/>
                        <Form.Input label={<Translate value='user_surname'/>} value={this.state.lastname} onChange={e => this.setState({'lastname': e.target.value})}/>
                        <Form.Input label={<Translate value='user_phone'/>} value={this.state.phone} onChange={e => this.setState({'phone': e.target.value})}/>
                        <Form.Input label={<Translate value='email_address'/>} value={this.state.email} onChange={e => this.setState({'email': e.target.value})}/>
                        <Form.TextArea required label={<Translate value='chat_message'/>} value={this.state.message} onChange={e => this.setState({'message': e.target.value})}/>

                        <div style={{textAlign: 'right'}}>
                            <Form.Button disabled={this.state.submitting} type="button" primary onClick={() => {
                                this.setState({submitting: true})
                                this.props.dispatch(submitContactForm(this.state.firstname, this.state.lastname, this.state.email, this.state.phone, this.state.message, () => {
                                    this.setState({
                                        firstname: '',
                                        lastname: '',
                                        email: '',
                                        phone: '',
                                        message: '',
                                        submitting: false,
                                        thankYou: true
                                    })

                                }))
                            }}><Translate value='submit'/> </Form.Button>
                        </div>

                    </Form>

                    {this.state.thankYou ? <Message positive>
                        <Message.Content>
                            Thank you for your message. We will get back to you as soon as possible.
                        </Message.Content>
                    </Message> : ''}

                </div>

                <div className='contactContainer'>

                DeltaProto | Broek in Waterland | Hellingweg 10 | 1151 CS |

                <a className='' href="tel:+31207173868"> 020 717 3 868</a>{" | "}

                    <Popup
                        trigger={
                            <span>B.g.g.:</span>
                        }
                        content='Bij geen gehoor'
                        position='bottom center'
                    /> <a className='' href="tel:+3646601111"> 06 46 60 11 11</a>{" | "}

                <a data-a="info" data-b="deltaproto" data-c="com"
                 className="footer__link" href="#/"
                 onClick={(e) => window.location.href = 'mailto:' + e.target.dataset.a + '@' + e.target.dataset.b + '.' + e.target.dataset.c}
                 style={{cursor: 'pointer'}}>e-mail</a>

                </div>



                <iframe className='gmapFrame' title={'map'} id={'map'} src={'https://www.google.com/maps/embed/v1/place?key=AIzaSyDtc_xaFag-jq4Aq1UDidND0mkquBahpiY&q=DeltaProto+B.V.'} allowFullScreen></iframe>
            </section>
        );
    }
}

Contact.propTypes = {
};

Contact.defaultProps = {
};

const mapStateToProps = (state) => {
    return {
    }
}

export default connect(mapStateToProps)(Contact)