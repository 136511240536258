import React from "react";
import PropTypes from "prop-types";
import {Dropdown, Button} from "semantic-ui-react";
import connect from "react-redux/es/connect/connect";
import {getTextForLang} from "../Translate";
import {encodeUrlParameters} from "../actions";
import {langOptions} from "../NavigationBar";
import {copyToClipboard} from "../bom/functions";

String.prototype.format = function() {
    var formatted = this;
    for (var i = 0; i < arguments.length; i++) {
        var regexp = new RegExp('\\{'+i+'\\}', 'gi');
        formatted = formatted.replace(regexp, arguments[i]);
    }
    return formatted;
};

class MailBuilder extends React.Component {

    getEml(value) {
        let emlCont = 'To: '+ this.props.to + '\n';
        if(value.cc)
            emlCont += 'cc: '+ value.cc + '\n';
        if(value.bcc)
            emlCont += 'bcc: '+ value.bcc + '\n';
        emlCont += 'Subject: '+ value.subject +'\n';
        emlCont += 'X-Unsent: 1'+'\n';
        if(value['X-MSMail-Priority'])
            emlCont += 'X-MSMail-Priority:'+ value['X-MSMail-Priority'] + '\n'
        if(value['X-Priority'])
            emlCont += 'X-Priority:' + value['X-Priority'] + '\n'
        emlCont += 'Content-Type: text/plain'+'\n\n';

        emlCont += value.body;
        let textFile = null;
        let data = new Blob([emlCont], {type: 'text/plain'});
        if (textFile !== null) {
            window.URL.revokeObjectURL(textFile);
        }

        textFile = window.URL.createObjectURL(data);
        return textFile
    }

    getBody(lang) {
        let value = {
            body: this.props.body,
        }

        if( this.props.mailKey ) {
            let body = getTextForLang(this.props, this.props.mailKey + '', lang)
            if( body ) {
                value.body = body;
            }
        }

        if( value.body && this.props.args ) {
            value.body = value.body.format(...this.props.args)
        }

        return value.body
    }

    getHref(lang) {
        let value = {
            cc: this.props.cc,
            bcc: this.props.bcc,
            subject: this.props.subject,
            priority: this.props.priority,
        }

        if( this.props.mailKey ) {
            let bcc = getTextForLang(this.props, this.props.mailKey + '.bcc.address', lang)
            if( bcc && bcc !== this.props.mailKey + '.bcc.address' ) {
                value.bcc = bcc;
            }
            let subject = getTextForLang(this.props, this.props.mailKey + '.subject', lang)
            if( subject ) {
                value.subject = subject;
            }
            let body = getTextForLang(this.props, this.props.mailKey + '', lang)
            if( body ) {
                value.body = body;
            }
            const priorityKey = this.props.mailKey + '.priority';
            let priority = getTextForLang(this.props, priorityKey, lang)
            priority = value.priority ? value.priority : priority;
            if( priority !== null && priority !== priorityKey && priority !== '3' ) {
                value['X-Priority'] = priority

                if (parseInt(priority) > 3) {
                    value['X-MSMail-Priority'] = 'High';
                    value['Importance'] = 'High';
                } else if (parseInt(priority) < 3) {
                    value['X-MSMail-Priority'] = 'Low';
                    value['Importance'] = 'Low';
                }
            }
        }

        if( value.subject && this.props.args ) {
            value.subject = value.subject.format(...this.props.args)
        }

        if( value.body && this.props.args ) {
            value.body = value.body.format(...this.props.args)
        }
        let link = `mailto:${this.props.to}` + encodeUrlParameters(value)

        //This is a limitation of MS Outlook, when a mailto link is longer than 2000 characters, the link is not opened.
        if (link.length > 2000) {
            return [this.getEml(value), true]
        }
        return [link, false]
    }

    render() {
        let name_download = getTextForLang(this.props, this.props.mailKey + '.subject', 'nl')
        if( !name_download ) {
            name_download = this.props.subject
        }

        if( this.props.button ) {
            return (<Dropdown trigger={<Button color={'blue'}>{this.props.children}</Button>} icon={null}>
                    <Dropdown.Menu>
                        {langOptions.map(l => (this.getHref(l.key)[1] ?
                            <Dropdown.Item
                                as='a'
                                href={this.getHref(l.key)[0]}
                                key={l.key}
                                download = {name_download + ".eml"}
                            >
                                {l.text}
                            </Dropdown.Item> :
                            <Dropdown.Item
                                as='a'
                                href={this.getHref(l.key)[0]}
                                key={l.key}
                            >
                                {l.text}
                            </Dropdown.Item>))}
                    </Dropdown.Menu>
                </Dropdown>
            );
        } else {

            return (<Dropdown item text={this.props.children}>
                    <Dropdown.Menu>
                        {langOptions.map(l => (this.getHref(l.key)[1] ?
                            <Dropdown.Item
                                as='a'
                                href={this.getHref(l.key)[0]}
                                key={l.key}
                                download = {name_download + ".eml"}
                            >
                                {l.text}
                            </Dropdown.Item> :
                            <Dropdown.Item
                                as='a'
                                href={this.getHref(l.key)[0]}
                                key={l.key}
                            >
                                {l.text}
                            </Dropdown.Item>))}
                    </Dropdown.Menu>
                </Dropdown>
            );
        }
    }

}

MailBuilder.propTypes = {
    disabled: PropTypes.bool,
    to: PropTypes.string,
    cc: PropTypes.string,
    bcc: PropTypes.string,
    subject: PropTypes.string,
    body: PropTypes.string,
    priority: PropTypes.number,
    mailKey: PropTypes.string,
    args: PropTypes.array,
    style: PropTypes.object,
    button: PropTypes.bool,
};

MailBuilder.defaultProps = {
    disabled: false,
    to: '',
    cc: '',
    bcc: '',
    subject: '',
    body: '',
    priority: null,
    mailKey: null,
    args: [],
    style: {},
    button: false
}


const mapStateToProps = (state) => {
    return {
        lang: state.main.lang,
        currentLang: state.main.currentLang,
    }
}

export default connect(mapStateToProps)(MailBuilder)