import {Button, Icon, Popup, Segment} from "semantic-ui-react";
import DatePicker from "react-datepicker";
import {highlightProjectPlanning, postChatMessage, updateProjectPlanning} from "../actions";
import BomStats from "./BomStats";
import React from "react";
import {projectPhases} from "../Project";
import Chat from "../reducers/chat";
import ChatView from "../chat/ChatView";
import ChatInput from "../chat/ChatInput";
import connect from "react-redux/es/connect/connect";
import PropTypes from "prop-types";
import {formatDate} from "../actions/functions";

var moment = require('moment');
moment.locale('nl');

export function getNextAssemblyDate(p) {

    if( p.planningProductieSerie ) {
        return p.planningProductieSerie
    }

    if( p.planningProductieFirst ) {
        return p.planningProductieFirst
    }

    return null;
}

class PlanningEntryProject extends React.Component {

    state = {
        isDragging: false
    }

    renderLatestPhase = (p) => {
        let res = <div>Not found</div>
        projectPhases.forEach(pp => {
            if( p[pp.name] !== 'notstarted' ) {

            }
        })

        return res;
    }

    handleDragStart = (e) => {
        this.setState({isDragging: true})
        const data = JSON.stringify({ type: "card", project: this.props.p.project, projectFieldDateName: this.props.p.projectFieldDateName});
        e.dataTransfer.setData("text/plain", data);
    }

    handleDragEnd = (e) => {
        this.setState({isDragging: false})
        //console.log('handleDragEnd', e.dataTransfer.getData("text/plain"));
        //console.log('handleDragEnd', this.props.p.projectFieldDateName);
        //e.dataTransfer.clearData();

    }

    renderNextAssemblyDate = (p) => {
        return p ? <div>
            Make on {formatDate(p)}
        </div> : null;
    }

    render() {
        const {p} = this.props;

        return <Segment dragable draggable color={p.color}
                        className={'planning ' + (p.highlight ? 'selected' : 'unselected')}
                        style={{
                            backgroundColor: this.state.isDragging ? "palegoldenrod" : null,
                            padding: 4,
                            marginBottom: 4,
                            marginTop: 0
                        }}
                        onDragStart={this.handleDragStart}
                        onDragEnd={this.handleDragEnd}
                        onMouseEnter={() => {
                            if( p.project ) {
                                this.props.dispatch(highlightProjectPlanning(p.project.id))
                            }
                        }}
                        onMouseLeave={() => {
                            //this.props.dispatch(highlightProjectPlanning(null))
                        }}
        >
            <Popup content={<div>
                {p.projectFieldDateName}
                {p.fieldName}
                <DatePicker inline selected={p.date}
                            onChange={v => {
                                let value = moment(v).utc().unix() * 1000
                                this.props.dispatch(updateProjectPlanning(Object.assign({}, p.project, {
                                    [p.projectFieldDateName]: value
                                })))
                            }}
                            todayButton={"Vandaag"} dateFormat={"d-M-yyyy"}/>
            </div>} hoverable trigger={<Icon name='calendar alternate outline'
                                             style={{display: 'inline', cursor: 'pointer', padding: 3}}/>}/>

            <Popup wide='very' position='top right' content={<div>
                <div>{p.project.name}</div>
                <div>{this.renderLatestPhase(p)}</div>
                <BomStats projectId={p.project.id}/>

            </div>} trigger={<div style={{display: 'inline-block'}}>
                <a href={"/project/" + p.project.id}>{p.project.name}</a>
                {" "}
                {p.text ? <>(<span
                    style={{color: (p.text && p.text === 'S1' ? '#D12A2F' : '')}}>{p.text}</span>) </> : ''}
                {Math.trunc(p.minutes / 60)}:{("0" + Math.round(p.minutes % 60)).slice(-2)}
            </div>} hoverable={true}/>

            <div style={{float: 'right'}}>

                <Popup trigger={<Icon link primary name='add'
                                      onClick={() => {
                                          this.props.dispatch(postChatMessage(p.project.chat, '', null, null, null, () => {

                                          }, true))
                                      }}/>} content={'Opmerking toevoegen'}/>
            </div>

            <div>
                {this.renderNextAssemblyDate(p.nextAssemblyDate)}
            </div>

            <div>
                {p.project.chatLines.filter(c => c.planning).map(c => (
                    <ChatInput value={c} compact={true} enableAutoFocus={false}/>
                ))}


            </div>
        </Segment>
    }
}


PlanningEntryProject.propTypes = {
    minutes: PropTypes.number,
    text: PropTypes.string,
    projectlist: PropTypes.array,
};

PlanningEntryProject.defaultProps = {
    minutes: null,
    text: null,
    projectlist: []
};

const mapStateToProps = (state) => {
    return {
    }
}

export default connect(mapStateToProps)(PlanningEntryProject)