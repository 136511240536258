import React, {Component} from 'react';
import PropTypes from 'prop-types';
import '../App.css';
import {
    Button,
    Dropdown,
    Form,
    Grid,
    Header,
    Icon,
    Menu,
    Message,
    Progress,
    Segment, Table
} from "semantic-ui-react";
import Dropzone from 'react-dropzone'

import {
    defaultCalculatorValues,
    encodeQueryData,
    fetchData, getPrice, isEmployee,
    maxOrderQuantity,
    minOrderQuantity,
    validateEmail
} from "../actions/functions";
import Translate, {getText, getTextSubheader} from "../Translate";
import connect from "react-redux/es/connect/connect";
import CalculatorSettings from "./CalculatorSettings";
import {
    calculateExpectedDeliveryDate, encodeUrlParameters,
    fetchApplicationSettings,
    fetchCalculatorSettings,
    fetchPrice, openChatModal,
    openLoginSignup
} from "../actions";
import CalculatorPriceView from "./CalculatorPriceView";
import MyInfo from "./MyInfo";
import {pcbWay} from "../tools/functions";
import BrowserWarning from "../BrowserWaring";

var request = require('superagent');

export function renderInputHorizontal(text, input, width, locked, info) {
    return <div style={{display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        opacity: locked ? .45 : 1
    }}>
        <div>
            {text}
            {info ? info : null}
        </div>

        <div style={{width, textAlign: 'right'}}>
            {input}
        </div>
    </div>
}

class Calculator extends Component {

    constructor(props) {
        super(props);

        this.handleCloseLogin = this.handleCloseLogin.bind(this)

        let data = props.data;

        if( props.data == null ) {
            let d = localStorage.getItem('deltaprotocalculator');

            if( d != null && data !== "null" ) {
                let val= JSON.parse(d);
                if(val != null && val && val.options) {
                    data= val;
                }
            }

            data = defaultCalculatorValues;
            //this.state = {data};
        }

        this.state={
            data,
            //uploading: false,
            progress: {perc: 100, name: ''},
            dropzoneActive: false,
            uploadError: null,
            user: null,
            error: null,
            errorNaam: null,
            errorFile: null,
            verstuurd: null,
            login: false,
            mobileView: false
        };
    }

    initialize(data) {

        this.setState({
            data,
            //uploading: false,
            progress: {perc: 100, name: ''},
            dropzoneActive: false,
            uploadError: null,
            error: null,
            errorNaam: null,
            errorFile: null,
            verstuurd: null
        })

        this.fetchPrice(data)

        localStorage.setItem('deltaprotocalculator', JSON.stringify(data));
    }

    handleCloseLogin() {
        this.setState({login: false})
    }

    componentDidMount() {

        window.addEventListener("resize", this.resize.bind(this));
        this.resize();

        if( this.props.data == null ) {
            this.fetchPrice(this.state.data);
        } else {
            this.setState({data: this.props.data})
            this.fetchPrice(this.props.data);
        }

    }

    resize() {
        let mobileView = (window.innerWidth <= 768);
        if (mobileView !== this.state.mobileView) {
            this.setState({mobileView: mobileView});
        }
    }

    onDrop = (acceptedFiles, rejectedFiles) => {
        this.setState({
            message: '',
            dropzoneActive: false,
            uploadError: null,
            errorFile: null
        });

        acceptedFiles.forEach((file)=> {
            var req = request.post('/api/calculator/upload');

            this.setState({progress: {perc: 0, name: file.name}})

            req.attach("file", file, file.name);

            req.on('progress', (e) => {

                if( e.percent != null ) {
                    this.setState({progress: {perc: e.percent, name: file.name}});
                }


            })
            req.end((err,res) => {
                if( !err ) {

                    let current= this.state.data.files;
                    if( current == null ) {
                        current= [];
                    }

                    if( res.body ) {
                        res.body.forEach(o => current.push(o));
                    }

                    let newData= Object.assign({}, this.state.data, {
                        files: current
                    })

                    this.setState({data: newData})

                    //this.props.change(field, current)
                    //this.props.dispatch( fileUploaded(res.body) )
                } else {
                    this.setState({uploadError: err.message, progress: {perc: 100, name: ''}})
                }
            });
        });


    }

    removeFile = (data, file) => {

        let newList = data.files.filter(f => f.id !== file.id);

        let newData= Object.assign({}, this.state.data, {
            files: newList
        })

        this.setState({data: newData})
    }

    fetchPrice(value) {

        const employee = isEmployee(this.props.currentUser);

        this.setState({error: null, errorNumPcbs: null})

        if( value.estComponentCost === null || value.estComponentCost === undefined || value.estComponentCost === "" ) {
            value.estComponentCost= '';
        }
        //if( typeof value.estComponentCost === 'string' || value.estComponentCost instanceof String ) {
        //    value.estComponentCost = value.estComponentCost.replace(',', '.');
        //}

        if( !employee && (value.numPcbs <= minOrderQuantity || maxOrderQuantity < value.numPcbs) ) {

            this.setState({errorNumPcbs: getText(this.props, "calc_error_min_max"), result: null})

        } else {

            this.props.dispatch(fetchPrice(value, this.props.orderid))

            /*fetchData(this.props.orderid ? 'api/calculator/calculate?orderId=' + this.props.orderid : 'api/calculator/calculate', value)
                .then(response => handleErrors(response).json())
                .then(json => {
                    this.setState({result: json})
                })
                .catch(error => {
                    localStorage.clear();
                    console.log(error)
                    this.setState({error: getText(this.props, "calc_error_invalid_input")})
                })*/
        }

        this.props.dispatch(calculateExpectedDeliveryDate(value.selectedDays))

    }

    pcbThicknessType = [
        { text: '0.4 mm', value: 'TH_0_4' },
        { text: '0.6 mm', value: 'TH_0_6' },
        { text: '0.8 mm', value: 'TH_0_8' },
        { text: '1.0 mm', value: 'TH_1_0' },
        { text: '1.2 mm', value: 'TH_1_2' },
        { text: '1.6 mm', value: 'TH_1_6' },
        { text: '2.4 mm', value: 'TH_2_4' },
        { text: '3.2 mm', value: 'TH_3_2' },
    ];

    layersType = [
        { text: '2',  value: '2' },
        { text: '4',  value: '4' },
        { text: '6',  value: '6' },
        { text: '8',  value: '8' },
    ];

    trackWidthType = [
        { text: '0.05',  value: 'TW_0_05' },
        { text: '0.075',  value: 'TW_0_075' },
        { text: '0.1',  value: 'TW_0_1' },
        { text: '0.125',  value: 'TW_0_125' },
        { text: '0.15',  value: 'TW_0_15' },
    ]

    drillSizeType = [
        { text: '0.10',  value: 'DS_0_1' },
        { text: '0.15',  value: 'DS_0_15' },
        { text: '0.20',  value: 'DS_0_20' },
        { text: '0.25',  value: 'DS_0_25' },
        { text: '0.30',  value: 'DS_0_30' }
    ]

    finishType = [
        { text: 'HALROHS',  value: 'HALROHS' },
        { text: 'HALLead',  value: 'HALLEAD' },
        { text: 'GoldENIG',  value: 'GOLDENIG' }
    ]

    componentSizeType = [
        { text: '1206',  value: 'CS_1206' },
        { text: '0805',  value: 'CS_0805' },
        { text: '0603',  value: 'CS_0603' },
        { text: '0402',  value: 'CS_0402' },
        { text: '0201',  value: 'CS_0201' },
    ]

    handleChange = (e, { name, value, index, checked }, additional) => {

        if( name === 'numPcbs' ) {
            value = parseInt(value)
        }
        if( name === 'selectedDays' ) {
            value = parseInt(value)
        }

        if( name === 'sizeX' || name === 'sizeY' || name === 'estComponentCost' ) {
            value= value.replace(',', '.')
        }

        let obj;

        if( checked !== undefined ) {

            value= checked
            obj = Object.assign({}, this.state.data, {[name]: checked})

        } else {

            if (index == null) {
                obj = Object.assign({}, this.state.data, {[name]: value})
            }
            else {
                //console.log(this.state.data[name][index]);

                let newList = this.state.data[name].map((r, i) =>
                    i === index ? value : r
                );
                obj = Object.assign({}, this.state.data, {[name]: newList})
            }
        }

        if( additional ) {
            obj = Object.assign({}, obj, {[additional.name]: additional.value})
        }

        if( name === 'numPcbs' ) {
            if( value <= (minOrderQuantity + 1) ) {
                obj.amounts[0] = 0;
                obj.amounts[1] = value;
                obj.amounts[2] = value + 1;
            } else if( value >= maxOrderQuantity ) {
                obj.amounts[0]= value - 1;
                obj.amounts[1]= value;
                obj.amounts[2]= 0;
            } else {
                obj.amounts[0]= value - 1;
                obj.amounts[1]= value;
                obj.amounts[2]= value + 1;
            }

        }

        if( name === 'selectedDays' ) {
            if( value === 5 ) {
                obj.days[1] = 10;
            } else if( value === 20 ) {
                obj.days[1] = 10;
            } else {
                obj.days[1] = value;
            }
        }

        obj['touched']= true;

        this.setState( {data: obj, error: null})

        if( this.props.onChange ) {
            this.props.onChange(name, value)
        } else {
            localStorage.setItem('deltaprotocalculator', JSON.stringify(obj));
        }

        this.fetchPrice(obj, this.props.orderid)
    }

    handleSubmit = () => {

        this.setState({error: null, errorNaam: null, errorFile: null, verstuurd: null, busy: true})

        let error= false;

        if( this.state.data.files == null || this.state.data.files.length === 0) {

            this.setState({errorFile: getText(this.props, "calc_error_no_files"), busy: false})
            error= true;
        }

        if( this.state.data.name == null || this.state.data.name === "") {

            this.setState({errorNaam: getText(this.props, "calc_error_no_name"), busy: false})
            error= true;
        }

        if( this.state.data.numPcbs == null || this.state.data.numPcbs === "") {

            this.setState({error: getText(this.props, "error_no_amount"), busy: false})
            error= true;
        }

        if( this.props.currentUser == null && !validateEmail(this.state.data.email) ) {

            this.setState({error: getText(this.props, "calc_error_invalid_email") , busy: false})
            error= true;
        }

        if( !error ) {

            fetchData('/api/project/saveNew' + (this.state.data.email ? ('?email=' + this.state.data.email) : ''), this.state.data)
                .then(response => {
                    if (!response.ok) {
                        response.text().then(text => {
                            this.setState({error: JSON.parse(text), busy: false})
                        })
                    }
                    return response.json();
                })
                .then(json => {
                    this.setState({verstuurd: json, busy: false})
                    localStorage.clear();
                })
                .catch(error => {
                    this.setState({error, busy: false})
                })
        }

    }

    getFormStyle = (fieldname, value, style) => {
        if( defaultCalculatorValues[fieldname] !== value ) {
            return Object.assign({}, style, {
                borderColor: '#8bd985',
                backgroundColor: 'rgba(139, 217, 133, 0.09)'

        })
        }

        return style;
    }

    addKorting() {
        let newData= Object.assign({}, this.state.data, {
            priceAdjust: 0.0,
            priceAdjustComment: ''
        })

        this.setState({data: newData})

        if( this.props.onChange ) {
            this.props.onChange(newData)
        }

        this.fetchPrice(newData)
    }

    removeKorting() {
        let newData= Object.assign({}, this.state.data, {
            priceAdjust: null,
            priceAdjustComment: null
        })

        this.setState({data: newData})

        if( this.props.onChange ) {
            this.props.onChange(newData)
        }

        this.fetchPrice(newData)
    }

    renderSubForm(data, result, submitable, locked, dateAmountLocked, isAdmin) {

        let soldermaskType = [
            { text: getText(this.props, "calc_green"),  value: 'GREEN' },
            { text: getText(this.props, "calc_red"),  value: 'RED' },
            { text: getText(this.props, "calc_blue"),  value: 'BLUE' },
            { text: getText(this.props, "calc_white"),  value: 'WHITE' },
            { text: getText(this.props, "calc_black"),  value: 'BLACK' },
        ];

        let silkScreenType = [
            { text: getText(this.props, "calc_white"),  value: 'WHITE' },
            { text: getText(this.props, "calc_black"),  value: 'BLACK' },
        ];

        let mStyle= locked ? {cursor: 'not-allowed'} : {};

        return <div>
            <Form.Group widths={4}>


                <Form.Group widths={2} style={{marginLeft: 0, marginRight: 0}}>
                    <Form.Input fluid label={getTextSubheader(this.props, "calc_length")} value={data.sizeY} name='sizeY' onChange={this.handleChange} disabled={locked} style={this.getFormStyle('sizeY', data.sizeY, mStyle)}/>
                    <Form.Input fluid label={getTextSubheader(this.props, "calc_width")} value={data.sizeX} name='sizeX' onChange={this.handleChange} disabled={locked} style={this.getFormStyle('sizeX', data.sizeX, mStyle)}/>
                </Form.Group>

                <Form.Select fluid label={getTextSubheader(this.props, "calc_thickness")} options={this.pcbThicknessType} value={data.thickness} name='thickness' onChange={this.handleChange} disabled={locked} style={this.getFormStyle('thickness', data.thickness, mStyle)}/>
                <Form.Select fluid label={getTextSubheader(this.props, "calc_layers")} options={this.layersType} value={data.layers} name='layers' onChange={this.handleChange} disabled={locked} style={this.getFormStyle('layers', data.layers, mStyle)}/>
                <Form.Select fluid label={getTextSubheader(this.props, "calc_finish")} options={this.finishType} value={data.finish} name='finish' onChange={this.handleChange} disabled={locked} style={this.getFormStyle('finish', data.finish, mStyle)}/>
            </Form.Group>
            <Form.Group widths={4}>
                <Form.Select fluid label={getTextSubheader(this.props, "calc_soldermask_color")} options={soldermaskType} value={data.soldermask} name='soldermask' onChange={this.handleChange} disabled={locked} style={this.getFormStyle('soldermask', data.soldermask, mStyle)}/>
                <Form.Select fluid label={getTextSubheader(this.props, "calc_silkscreen_color")} options={silkScreenType} value={data.silkScreen} name='silkScreen' onChange={this.handleChange} disabled={locked} style={this.getFormStyle('silkScreen', data.silkScreen, mStyle)}/>
                <Form.Select fluid label={getTextSubheader(this.props, "calc_track_thickness")} options={this.trackWidthType} value={data.trackWidth} name='trackWidth' onChange={this.handleChange} disabled={locked} style={this.getFormStyle('trackWidth', data.trackWidth, mStyle)}/>
                <Form.Select fluid label={getTextSubheader(this.props, "calc_drill_diameter")} options={this.drillSizeType} value={data.drillSize} name='drillSize' onChange={this.handleChange} disabled={locked} style={this.getFormStyle('drillSize', data.drillSize, mStyle)}/>
            </Form.Group>

            <div style={{marginTop: '1.5em'}}>
                {renderInputHorizontal(getTextSubheader(this.props, "calc_pcb_supplied"),
                    <Form.Checkbox toggle checked={data.customerSuppliesPCBs} name='customerSuppliesPCBs' onChange={this.handleChange} disabled={locked}/>,
                    143, locked
                )}
                {/*<Form.Checkbox toggle fluid label={getText(this.props, "calc_pcb_supplied")} checked={data.customerSuppliesPCBs} name='customerSuppliesPCBs' onChange={this.handleChange} disabled={locked} style={this.getFormStyle('customerSuppliesPCBs', data.customerSuppliesPCBs, mStyle)}/>*/}
                {/*<Form.Checkbox fluid label='Ik lever zelf het stencil aan' checked={data.customerSuppliesStencil} name='customerSuppliesStencil' onChange={this.handleChange} disabled={locked} style={this.getFormStyle('customerSuppliesStencil', data.customerSuppliesStencil, mStyle)}/>*/}
            </div>

            {false && <div style={{marginTop: '1.5em'}}>
                {renderInputHorizontal(getTextSubheader(this.props, "calc_stencil_supplied"),
                    <Form.Checkbox toggle fluid checked={data.customerSuppliesStencil} name='customerSuppliesStencil' onChange={this.handleChange} disabled={dateAmountLocked || locked}/>,
                    143, locked
                )}
            </div>}

            {false && <div style={{marginTop: '1.5em'}}>
                {renderInputHorizontal(getTextSubheader(this.props, "calc_extra_pcb"),
                    <Form.Checkbox toggle checked={data.extraPcb} name='extraPcb' onChange={this.handleChange}
                                   disabled={dateAmountLocked || locked}/>,
                    143, locked
                )}
            </div>}

            <div style={{marginTop: '1.5em'}}>
                {renderInputHorizontal(getTextSubheader(this.props, "calc_pcba_test"),
                    <Form.Checkbox toggle checked={data.pcbaTest} name='pcbaTest' onChange={this.handleChange} disabled={locked}/>,
                    143, locked, <div style={{display: 'inline', marginLeft: 8}}>
                        <MyInfo message={<Translate value='calc_pcba_info'/>}/>
                    </div>
                )}
            </div>

            <div style={{marginTop: '1.5em'}}>
                {renderInputHorizontal(getTextSubheader(this.props, "calc_split_delivery"),
                    <Form.Checkbox toggle checked={data.splitDelivery} name='splitDelivery' onChange={this.handleChange} disabled={!isAdmin}/>,
                    143, locked
                )}
            </div>

            <div style={{marginTop: '1.5em'}}>
                {renderInputHorizontal(getTextSubheader(this.props, "calc_odb_discount"),
                    <Form.Checkbox toggle checked={data.supplyOdb} name='supplyOdb' onChange={this.handleChange} disabled={locked}/>,
                    143, locked, <div>
                        <a download href="/images/DeltaProto%20SBOM.xlsx" target="_blank">download voorbeeld bom</a>
                </div>
                )}
            </div>

            <div style={{marginTop: '1.5em'}}>
                {renderInputHorizontal(getTextSubheader(this.props, "calc_vias"),
                    <Form.Checkbox toggle checked={data.specialVias} name='specialVias' onChange={this.handleChange} disabled={locked}/>,
                    143, locked
                )}
            </div>

            <br/>
            <div style={{borderTop: '1px solid rgba(34,36,38,.15)'}}></div>
            <br/>

            <Form.Group widths={4}>
                <Form.Input fluid type='number' label={getTextSubheader(this.props, "calc_nr_bom_lines")} value={data.numberOfComponents} name='numberOfComponents' onChange={this.handleChange} disabled={locked} style={this.getFormStyle('numberOfComponents', data.numberOfComponents, mStyle)}/>
                <Form.Input fluid type='number' label={getTextSubheader(this.props, "calc_nr_placements")} value={data.numberOfPlacements} name='numberOfPlacements' onChange={this.handleChange} disabled={locked} style={this.getFormStyle('numberOfPlacements', data.numberOfPlacements, mStyle)}/>
                <Form.Input fluid type='number' min='1' max='2' label={getTextSubheader(this.props, "calc_smd_sides")} value={data.sides} name='sides' onChange={this.handleChange} disabled={locked} style={this.getFormStyle('sides', data.sides, mStyle)}/>
                <Form.Input fluid type='number' label={getTextSubheader(this.props, "calc_nr_bgas")} value={data.numberOfBGAs} name='numberOfBGAs' onChange={this.handleChange} disabled={locked} style={this.getFormStyle('numberOfBGAs', data.numberOfBGAs, mStyle)}/>
            </Form.Group>

            <div style={{marginTop: '1.5em'}}>
                {renderInputHorizontal(getTextSubheader(this.props, "calc_avg_comp_size"),
                    <Form.Select fluid options={this.componentSizeType} value={data.averageComponentSize} name='averageComponentSize' onChange={this.handleChange} disabled={locked} style={this.getFormStyle('averageComponentSize', data.averageComponentSize, mStyle)}/>,
                    143, locked
                )}
            </div>

            <div style={{marginTop: '1.5em'}}>
                {renderInputHorizontal(getTextSubheader(this.props, "calc_component_price"),
                    <Form.Input fluid value={data.estComponentCost} error={isNaN(data.estComponentCost)} name='estComponentCost' onChange={this.handleChange} disabled={locked} style={this.getFormStyle('estComponentCost', data.estComponentCost, mStyle)}/>,
                    143, locked
                )}
            </div>

            {!submitable && data.priceAdjust !== null && <div>
                <br/><br/>

                <Form.Group>
                    <Form.Input width={4} type='priceAdjust' label={getTextSubheader(this.props, "calc_charge_or_discount")} value={data.priceAdjust} name='priceAdjust' onChange={this.handleChange} disabled={locked} style={this.getFormStyle('priceAdjust', data.priceAdjust, mStyle)}/>
                    <Form.Input width={11} label={getTextSubheader(this.props, "calc_charge_or_discount_description")} options={this.priceAdjustComment} value={data.priceAdjustComment} name='priceAdjustComment' onChange={this.handleChange} disabled={locked} style={this.getFormStyle('priceAdjustComment', data.priceAdjustComment, mStyle)}/>
                    {isAdmin && <Form.Button style={{marginTop: 24}} width={1} title='Verwijderen' floated='right' type="button" icon='trash' onClick={() => this.removeKorting()}/>}
                </Form.Group>
            </div>}

            {isAdmin && data.priceAdjust === null && <div>
                <br/>

                <Form.Group widths={4}>
                    <Form.Button style={{marginTop: 24}} content='Korting of toeslag toevoegen' type="button" icon='plus' onClick={() => this.addKorting()}/>
                </Form.Group>
            </div>}

            <Segment.Group style={{backgroundColor: this.state.errorFile ? '#fff6f6' : '', borderColor: this.state.errorFile ? '#e2babb' : '', boxShadow: 'none', marginTop: '30px'}}>

                {!locked && <Segment placeholder style={{marginTop: 2, backgroundColor: this.state.errorFile ? '#fff6f6' : ''}} inverted={this.state.dropzoneActive} color={this.state.dropzoneActive ? 'green' : null}
                                     loading={this.state.progress.perc < 100}>

                    <Dropzone onDrop={this.onDrop}
                              ref={(node) => {
                                  this.dropzoneRef = node;
                              }}
                              multiple={true}
                              disableClick={true}
                              disablePreview={true}
                              style={{}}
                              onDragEnter={() => this.setState({dropzoneActive: true})}
                              onDragLeave={() => this.setState({dropzoneActive: false})}
                    >


                        <Form.Group widths={1}>

                            <div style={{textAlign: 'center', width: '100%', marginTop: '14px'}}>

                                <Header as='h2'>
                                    <p>{getText(this.props, 'calc_drag_files')}</p>
                                </Header>
                                <p>({getText(this.props,'calc_file_types')} Gerbers<b>.zip</b>, ODB<b>.zip</b>, BOM<b>.xlsx</b>, Centroid<b>.csv/.txt/.xlsx</b>)</p>
                                <Header as='h3'>
                                    <div style={{display: 'inline'}}>{getText(this.props,'calc_choose_your_files_or_space')}</div>
                                    <button className="link-button" type="button" onClick={() => {this.dropzoneRef.open()}}>
                                        {getText(this.props, 'calc_choose_your_files')}
                                    </button>
                                </Header>

                                <BrowserWarning/>

                            </div>


                        </Form.Group>


                    </Dropzone>

                </Segment>}

                {this.state.progress.perc < 100 && <Segment>
                    <Progress active indicating percent={this.state.progress.perc}>{this.state.progress.name}</Progress>
                </Segment>}

                {this.state.uploadError != null && <Segment>
                    {this.state.uploadError}
                </Segment>}

                {data.files && data.files.map(f => <Segment key={f.id} disabled={locked} style={mStyle}>
                    <Icon name='file outline'/>

                    {this.props.isAdmin && this.props.orderid ? <a href={'/api/project/getProjectFile' + encodeQueryData({id: this.props.orderid, fileId: f.id})} download>{f.name}</a> : f.name}

                    {!locked && <div style={{float: 'right',
                        marginRight: 0,
                        cursor: 'pointer',
                        marginLeft: '.25em'}}
                                     onClick={() => this.removeFile(data, f)}
                    >
                        <Icon name='close' />
                    </div>}

                </Segment>)}

            </Segment.Group>

            {this.props.showAddadditionalFiles ? <div style={{textAlign: 'right'}}>
                <Button type="button" primary onClick={() => this.props.dispatch(openChatModal(true))} icon labelPosition='left'>
                    <Icon name='add'/>
                    <Translate value={'calc_add_additional_files'}/>
                </Button>
            </div> : null}

        </div>
    }

    renderForm(data, result, submitable, locked, dateAmountLocked, isAdmin, embed) {

        let minLevertijd= result && result.length ? result[0].days : 5;
        let maxLevertijd= result && result.length ? result[6].days : 20;

        let levertijdOptions = []

        for (let i = minLevertijd; i <= maxLevertijd; i++) {
            levertijdOptions.push({ text: i.toString() ,  value: i })
        }

        const employee = isEmployee(this.props.currentUser);

        return <Form onSubmit={this.handleSubmit} loading={this.state.busy}>

            <Grid padded={false} doubling>
                <Grid.Column width={this.state.mobileView ? 16 : 10}>

                    <div>
                        <Menu attached='top' inverted color='blue'>
                            {(isAdmin || !embed) ?<Dropdown item icon='bars' simple style={{color: 'white'}} disabled={locked}>
                                <Dropdown.Menu>
                                    <Dropdown.Item onClick={() => this.initialize(defaultCalculatorValues)}>{getText(this.props, "calc_reset_to_default")}</Dropdown.Item>
                                    {isAdmin ? <Dropdown.Item onClick={this.openSettings}>Calculator aanpassen</Dropdown.Item> : null}
                                    {isAdmin ? <Dropdown.Item onClick={() => {
                                        let currentPcbWay = pcbWay;
                                        let url = 'https://www.pcbway.com/QuickOrderOnline.aspx' + encodeUrlParameters(currentPcbWay);
                                        window.open(url);
                                    }}>Open in PcbWay</Dropdown.Item> : null}
                                </Dropdown.Menu>
                            </Dropdown> : null}
                        </Menu>

                        <Segment attached='bottom' style={{padding: 40}}>
                            {this.renderSubForm(data, result, submitable, locked, dateAmountLocked, isAdmin)}
                        </Segment>
                    </div>



                </Grid.Column>

                <Grid.Column className={'aanvraagFixedMobile'} width={ this.state.mobileView ? 16 : 6}>
                    <Menu secondary attached='top' inverted color='blue'>
                        <Menu.Item header>{getText(this.props, "calc_aanvraag")}</Menu.Item>
                    </Menu>

                    <Segment attached='bottom' style={{padding: 20}}>

                        <Grid columns={2} divided>
                            <Grid.Row>
                                <Grid.Column>
                                    <Form.Input label={<Translate value={'calc_amount'}/>} fluid type='number' min={employee ? 1 : 2} max={100} value={data.numPcbs} name='numPcbs' error={this.state.errorNumPcbs} onChange={this.handleChange} disabled={dateAmountLocked}/>
                                </Grid.Column>
                                <Grid.Column>
                                    <Form.Select label={<Translate value={'calc_deliverytime'}/>} fluid options={levertijdOptions} error={!(minLevertijd <= data.selectedDays && data.selectedDays <= maxLevertijd)} value={data.selectedDays} name='selectedDays' onChange={this.handleChange} disabled={dateAmountLocked}/>
                                </Grid.Column>
                            </Grid.Row>


                        </Grid>

                        <div style={{
                            textAlign: 'center',
                            paddingTop: 28,
                            paddingBottom: 14,
                            fontSize: '2em',
                            fontWeight: 'bold',
                            borderTop: '1px solid rgba(34, 36, 38, 0.15)',
                            borderBottom: '1px solid rgba(34, 36, 38, 0.15)'
                        }}>

                            <CalculatorPriceView orderId={this.props.orderid}  numPcbs={data.numPcbs} selectedDays={data.selectedDays} data={data}/>

                            <div style={{
                                textAlign: 'right',
                                fontSize: '.3em',
                                fontWeight: 'normal',
                            }}>

                                {getText(this.props, "calc_excl_btw")}

                            </div>

                        </div>


                        {submitable && this.props.currentUser == null && <div style={{textAlign: 'center'}}>
                            <br/>
                            <p>{getText(this.props, "calc_login_or_signup")}</p>
                            <br/>
                            <Button color='red' size='large' type="button" onClick={() => this.props.dispatch(openLoginSignup(true))}>
                                <p>{getText(this.props, "login_login")}</p>
                            </Button>
                            <Button size='large' type="button" onClick={() => this.props.dispatch(openLoginSignup(true, 1))}>
                                <p>{getText(this.props, "login_signup")}</p>
                            </Button>
                        </div>}

                        {submitable && this.props.currentUser != null && <div style={{textAlign: 'center'}}>
                            {this.props.currentUser == null && <Form.Group widths={3}>
                                <Form.Input disabled style={{visibility: 'hidden'}}/>
                                <Form.Input disabled style={{visibility: 'hidden'}}/>
                                <Form.Input label={<p>{getText(this.props, "email_address")}</p>} value={this.props.currentUser !== null ? this.props.currentUser.email : data.email} name='email' onChange={this.handleChange}/>
                            </Form.Group>}



                            {this.state.verstuurd != null && <Message
                                style={{marginTop: 14, textAlign: 'center'}}
                                positive
                                header={getText(this.props, "project_sent_in")}
                                content={<div><p>{getText(this.props, "project_sent_in_description")}</p> <a href={'/project/'+this.state.verstuurd.id}>{getText(this.props, "here")}</a></div>}
                            />}

                            <br/>
                            <Form.Button disabled={this.state.busy || this.state.verstuurd} size='large' color='red' icon labelPosition='left'>
                                <Icon name='mail'/>
                                <p>
                                    {getText(this.props, "calc_submit")}
                                </p>
                            </Form.Button>

                        </div>}

                    </Segment>
                </Grid.Column>

                <Grid.Column className={'aanvraagFixedDesktop'} width={ this.state.mobileView ? 16 : 6}>

                    <Menu secondary attached='top' inverted color='blue'>
                        <Menu.Item header>{getText(this.props, "calc_aanvraag")}</Menu.Item>
                    </Menu>

                    <Segment attached='bottom' style={{padding: 20}}>

                        {renderInputHorizontal(getTextSubheader(this.props, "calc_amount"),
                            <Form.Input style={{width: '100px'}} fluid type='number' min={employee ? 1 : 2} max={100} value={data.numPcbs} name='numPcbs' error={this.state.errorNumPcbs} onChange={this.handleChange} disabled={locked}/>,
                            100, false
                        )}

                        <div style={{marginTop: 14}}>
                            {renderInputHorizontal(<>{getTextSubheader(this.props, "calc_deliverytime")}{/*<span style={{marginLeft: 4, fontSize: '.8em', lineHeight: '2em'}}>{this.props.expectedDelivery ? formatDate(this.props.expectedDelivery, 'dddd DD MMMM YYYY') : ''}</span>*/}</>,
                                <Form.Select fluid options={levertijdOptions} error={!(minLevertijd <= data.selectedDays && data.selectedDays <= maxLevertijd)} value={data.selectedDays} name='selectedDays' onChange={this.handleChange} disabled={locked}/>,
                                100, false
                            )}
                        </div>

                        {this.props.displayName && <div style={{marginTop: 14}}>
                            <Form.Input fluid error={this.state.errorNaam} label={<div style={{marginBottom: 4}}>{getTextSubheader(this.props, "calc_project_name")}</div>} value={data.name} name='name' disabled={dateAmountLocked} onChange={(e,t) => {
                                this.setState({errorNaam: null})
                                this.handleChange(e,t)
                            }}/>
                        </div>}

                        {this.props.displayName && <div style={{marginTop: 14}}>
                            <Form.Input fluid error={this.state.errorReference} label={<div style={{marginBottom: 4}}>{getTextSubheader(this.props, "calc_project_ordernumber")}</div>} value={data.reference} name='reference' disabled={dateAmountLocked} onChange={(e,t) => {
                                this.setState({errorNaam: null})
                                this.handleChange(e,t)
                            }}/>
                        </div>}

                        {!this.state.mobileView ? <div style={{marginTop: 14}}>
                            <Form.TextArea label={<div style={{marginBottom: 4}}>{getTextSubheader(this.props, "calc_project_customer_info")}</div>} value={this.props.remark ? this.props.remark : data.remark} name='remark' onChange={(e,t,a) => !(dateAmountLocked) && this.handleChange(e,t,a)} disabled={dateAmountLocked}/>
                        </div> : null}

                        <div style={{
                            textAlign: 'center',
                            paddingTop: 28,
                            paddingBottom: 14,
                            fontSize: '2em',
                            fontWeight: 'bold',
                            borderBottom: '1px solid rgba(34, 36, 38, 0.15)'
                        }}>

                            <CalculatorPriceView orderId={this.props.orderid}  numPcbs={data.numPcbs} selectedDays={data.selectedDays} data={data}/>

                            <div style={{
                                textAlign: 'right',
                                fontSize: '.3em',
                                fontWeight: 'normal',
                            }}>

                                {getText(this.props, "calc_excl_btw")}

                            </div>

                        </div>



                        <div style={{marginTop: 14}}>

                            {this.state.errorNumPcbs != null && <Message
                                negative
                                header={getText(this.props, "calc_error")}
                                content={this.state.errorNumPcbs}
                            />}

                            {this.state.errorNaam != null && <Message
                                negative
                                header={getText(this.props, "calc_error")}
                                content={this.state.errorNaam}
                            />}

                            {this.state.error != null && <Message
                                negative
                                header={getText(this.props, "calc_error")}
                                content={this.state.error ? this.state.error.message : null}
                            />}

                            {this.state.errorFile != null && <Message
                                negative
                                header={getText(this.props, "calc_error")}
                                content={this.state.errorFile}
                            />}

                        </div>

                        {submitable && this.props.currentUser == null && <div style={{textAlign: 'center'}}>
                            <br/>
                            <p>{getText(this.props, "calc_login_or_signup")}</p>
                            <br/>
                            <Button color='red' size='large' type="button" onClick={() => this.props.dispatch(openLoginSignup(true))}>
                                <p>{getText(this.props, "login_login")}</p>
                            </Button>
                            <Button basic color='red' size='large' type="button" onClick={() => this.props.dispatch(openLoginSignup(true, 1))}>
                                <p>{getText(this.props, "login_register")}</p>
                            </Button>
                        </div>}

                        {submitable && this.props.currentUser != null && <div style={{textAlign: 'center'}}>
                            {this.props.currentUser == null && <Form.Group widths={3}>
                                <Form.Input disabled style={{visibility: 'hidden'}}/>
                                <Form.Input disabled style={{visibility: 'hidden'}}/>
                                <Form.Input label={<p>{getText(this.props, "email_address")}</p>} value={this.props.currentUser !== null ? this.props.currentUser.email : data.email} name='email' onChange={this.handleChange}/>
                            </Form.Group>}



                            {this.state.verstuurd != null && <Message
                                style={{marginTop: 14, textAlign: 'center'}}
                                positive
                                header={getText(this.props, "project_sent_in")}
                                content={<div><p>{getText(this.props, "project_sent_in_description")}</p> <a href={'/project/'+this.state.verstuurd.id}>{getText(this.props, "here")}</a></div>}
                            />}

                            <br/>
                            <Form.Button disabled={this.state.busy || this.state.verstuurd} size='large' color='red' icon labelPosition='left'>
                                <Icon name='mail'/><p>{getText(this.props, 'calc_submit')}</p>
                            </Form.Button>

                        </div>}

                        {this.props.renderAction != null && <div style={{textAlign: 'center', marginTop: 14}}>{this.props.renderAction()}</div>}

                    </Segment>

                    {!this.state.mobileView && !locked && (submitable) && <Menu secondary attached='top' inverted color='blue'>
                        <Menu.Item header>{getText(this.props, "calc_alternatives")}</Menu.Item>
                    </Menu>}

                    {!this.state.mobileView && !locked && (submitable) && <Segment attached='bottom' style={{padding: 0}}>

                        <Table fixed basic='very' celled>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell style={{textAlign: 'center', paddingTop: 12, fontWeight: 'normal'}}>{getText(this.props, "calc_amount")}</Table.HeaderCell>

                                    {[0,1,2].map((j) =>
                                        <Table.HeaderCell key={j} style={{textAlign: 'center', paddingTop: 12, fontWeight: 'normal'}}>
                                            {(result && result.length ? result[j*3].days : null) + ' ' + getText(this.props, "calc_workdays")}
                                        </Table.HeaderCell>
                                    )}
                                </Table.Row>
                            </Table.Header>

                            <Table.Body>
                                {this.state.data.options.map((i,j) =>


                                    <Table.Row key={j} style={{marginTop: 24}}>
                                        <Table.Cell style={{textAlign: 'center'}}>{data.amounts[i] === 0 ? '-' : data.amounts[i]}</Table.Cell>
                                        {[0,1,2].map((j) => {

                                            let style= {textAlign: 'center'}
                                            if( data.amounts[i] === data.numPcbs && data.days[j] === data.selectedDays ) {
                                                style['fontWeight']= 'bold'
                                            }

                                            return <Table.Cell key={j} style={style}>

                                                <div style={{cursor: 'pointer', textDecoration: 'underline'}} onClick={() => {
                                                    this.handleChange({}, {name: 'numPcbs', value: data.amounts[i]}, {name: 'selectedDays', value: result[j*3].days})
                                                }}>
                                                    {result && result.length && getPrice(result, data.amounts[i], result[j*3].days)}
                                                </div>

                                            </Table.Cell>
                                        })}
                                    </Table.Row>)
                                }
                            </Table.Body>

                        </Table>

                    </Segment>}

                </Grid.Column>

            </Grid>

        </Form>
    }

    renderPrice(data, days) {

        let price= '';

        if( data && data.length ) {

            data.forEach(e => {
                if( e.days === days ) {
                    price= e.price;
                }
            })

            price= price + ' (' + days + ' ' + getText(this.props, "calc_workdays")+ ')';

        }

        return price;
    }

    openSettings = () => {
        this.props.dispatch(fetchCalculatorSettings())
        this.props.dispatch(fetchApplicationSettings())
    }

    render() {

        let userIsAdmin= isEmployee(this.props.currentUser)

        return (
            <div id = {this.props.embed ? '' : 'calculator'} className={this.props.embed ? '' : 'pageContainer'}>

                <CalculatorSettings/>

                {(this.state.data) ?
                    <div style={{textAlign: 'left'}}>

                        {this.renderForm(this.state.data, this.props.calculatorPrice, !this.props.data, this.props.locked, this.props.dateAmountLocked, userIsAdmin, this.props.embed)}

                    </div> : null
                }
            </div>

        );
    }
}

Calculator.propTypes = {
    data: PropTypes.any,
    locked: PropTypes.bool,
    dateAmountLocked: PropTypes.bool,
    orderid: PropTypes.string,
    isAdmin: PropTypes.bool,
    embed: PropTypes.bool,
    renderAction: PropTypes.func,
    remark: PropTypes.string,
    displayName: PropTypes.bool,
    showAddadditionalFiles: PropTypes.bool,
};

Calculator.defaultProps = {
    data: null,
    locked: false,
    dateAmountLocked: false,
    orderid: null,
    isAdmin: false,
    embed: false,
    renderAction: null,
    remark: null,
    displayName: true,
    showAddadditionalFiles: false,
};

const mapStateToProps = (state) => {
    return {
        lang: state.main.lang,
        currentLang: state.main.currentLang,
        currentUser: state.main.currentUser,
        calculatorPrice: state.project.calculatorPrice,
        expectedDelivery: state.project.expectedDelivery,
    }
}

export default connect(mapStateToProps)(Calculator)
