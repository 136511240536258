import React from 'react'
import {connect} from "react-redux";
import {Button, Checkbox, Confirm, Dropdown, Form, Icon, Loader, Menu, Modal, Popup, Table} from "semantic-ui-react";
import Translate from "../Translate";
import {
    autoSelectBomLine,
    autoUpdateStockAndPrice, checkBom, deleteBom, getMohicanUrl, importBOM2, importNewBOM2, mohicanSendMessage,
    openBomOrder,
    openMohicanConnectionModal,
    openMohicanHomeModal,
    openMohicanPcbModal,
    openMohicanSelectionSettingsModal,
    openMohicanTrayModal,
    receivePrint,
    saveBom, setBomDemoMode, setBomFilter,
    setBomView,
    startReceivePrint,
    startUploadCentroid
} from "../actions";
import UploadFile from "../oldui/UploadFile";
import {encodeQueryData, isEmployee} from "../actions/functions";
import MailBuilder from "../tools/MailBuilder";
import {defaultSelectedColumns, fetchAllBomData} from "./functions";
import PropTypes from "prop-types";
import BomImportAndEdit from "./BomImportAndEdit";

class BomMenu extends React.Component {

    state = {

    }

    renderBuddyDownloadMenu() {
        return <>
            {isEmployee(this.props.currentUser) ? <Dropdown item text={'Download buddy file'}>
                <Dropdown.Menu>
                    <Dropdown.Item as={'a'}
                            href={'/api/bom/buddy/productionFile' + encodeQueryData({id: this.props.print.id, top: 'true'})} download
                        >top
                    </Dropdown.Item>
                    <Dropdown.Item as={'a'}
                        style={{color: 'black'}}
                            href={'/api/bom/buddy/productionFile' + encodeQueryData({id: this.props.print.id, top: 'false'})} download
                        >bottom
                    </Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown> : null}
        </>
    }

    getMissingCentroid() {
        if( this.props.print ) {

            const bom = this.props.print.bom.map(r => r.designators).flat().filter(d => !d.x || !d.y);

            return bom;

        }
        return []
    }

    render() {

        const {noProject, admin, cameraView, ordersView} = this.props;
        let marked_L = this.props.print.bom.filter(b => b.marked).length;
        return <>

            {!admin ? <div style={{lineHeight: 'unset', marginLeft: 16, marginTop: 4}}>
                {marked_L !==0 ? <Checkbox className='white' toggle checked={this.props.bomFilter === 'marked'} label={"Toon alleen gemarkeeerde regels (" + marked_L  + ")"}
                          onChange={() => {
                              this.props.dispatch(setBomFilter('marked'))
                          }}/> :''}


                <Button as='a' style={{marginLeft: 16}} primary icon labelPosition='left'
                        href={'/api/bom/downloadCustomerBom' + encodeQueryData({id: this.props.print.id})} download>
                    <Icon name='download'/>
                    ProtoMemo Excel
                </Button>
            </div> : null}

            {((this.props.viewId === 0 || this.props.viewId === 'bom' || this.props.viewId === 'valideren') && admin) ?
                <Dropdown item={'BOM'} text={<div><Icon name='list ol'/>BOM</div>} onOpen={() => this.props.dispatch(setBomView(null, 'bom'))}>
                    <Dropdown.Menu>
                        <Dropdown.Item disabled={noProject} onClick={(e) => {
                            this.props.dispatch(openMohicanSelectionSettingsModal(true))
                        }}>Supplier settings</Dropdown.Item>
                        <Dropdown.Item
                            className={(this.props.viewId === 'valideren') ? 'activehighlight' : ''}
                            disabled={noProject} onClick={(e) => {
                                if(this.props.viewId === 'valideren') {
                                    this.props.dispatch(setBomView(null, 'bom'))
                                } else {
                                    this.props.dispatch(setBomView(null, 'valideren'))
                                }
                            e.stopPropagation()
                        }}>Analysis {this.props.validatingBom ?
                            <Loader active inline size='mini'/> : ''}

                            {(this.props.viewId === 'valideren') ? <Icon link name='close' className='right floated' onClick={() => {
                                this.props.dispatch(setBomView(null, 'bom'))
                                this.props.dispatch(checkBom(this.props.print.id))
                            }
                            }/> : null}
                        </Dropdown.Item>
                        <Dropdown.Item disabled={noProject} onClick={() => this.setState({confirmDelete: this.props.print.id, confirmDeleteName: this.props.print.name})}>
                            Remove BOM
                        </Dropdown.Item>

                        {this.props.print ? <>
                            <Dropdown.Item disabled={noProject}>
                                {/*<UploadFile style={{}} icon={false} button={false} text={"Upload a new BOM"}
                                            url={`/api/bom/importNewBOM?printId=${this.props.print.id}&=aggregate${this.props.aggregateUpload}&=uplacer=true`}
                                            onStart={() => {
                                                this.props.dispatch(startReceivePrint())
                                            }} onResult={(body) => {
                                    this.props.dispatch(receivePrint(body))
                                    this.props.dispatch(setBomView(null, 'valideren'))
                                    this.props.dispatch(checkBom(body.key.id))
                                    fetchAllBomData(body.key.id, null, null, this.props.dispatch)
                                }}/>*/}
                                <BomImportAndEdit
                                    key={'uploadnewbom'}
                                    text={"Upload a new BOM"}
                                    type={'bom'}
                                    button={false}
                                    printId={this.props.print ? this.props.print.id : null}
                                    onImport={(d) => {
                                        console.log('import new bom', d)
                                        this.props.dispatch(importNewBOM2(d, this.props.print ? this.props.print.id : null, this.props.aggregateUpload, !this.props.projectOrderId, (body) => {
                                            this.props.dispatch(receivePrint(body))
                                            //this.props.dispatch(setBomView(null, 'valideren'))
                                            this.props.dispatch(checkBom(body.key.id))

                                            if (window.location.href.includes('projects-microplacer')) {
                                                this.props.history.push(`/projects-microplacer/${body.key.id}/bom`)
                                            }
                                        }))
                                    }}
                                />
                            </Dropdown.Item>

                        </> : null}
                        {this.renderBuddyDownloadMenu()}
                        <Dropdown.Item disabled={noProject} onClick={(e) => {
                            this.setState({compareOpen: true})
                        }}>Compare BOM & Centroid</Dropdown.Item>

                        <Dropdown.Item
                            as='a' disabled={noProject}
                            href={'/api/bom/downloadCustomerBom' + encodeQueryData({id: this.props.print.id})} download
                        >
                            Download ProtoMemo Excel
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown> : null}

            {this.state.compareOpen ? <Modal
                open={true}
                size='tiny'
                onClose={() => this.setState({compareOpen: false})}
                header='Compare BOM & Centroid' closeIcon
                content={<Modal.Content scrolling>
                    <Table>
                        <Table.Header>
                            <Table.HeaderCell>In BOM, not in Centroid</Table.HeaderCell>
                            {/*<Table.HeaderCell>In Centroid, not in BOM</Table.HeaderCell>*/}
                        </Table.Header>
                        <Table.Body>
                            {this.getMissingCentroid().map(d => <Table.Row>
                                <Table.Cell>{d.value}</Table.Cell>
                            </Table.Row>)}
                        </Table.Body>
                    </Table>
                </Modal.Content>}
                actions={[]}
            /> : null}

            {admin ? <>
                {(this.props.viewId === 'production' && this.props.print) ? <Dropdown disabled={noProject} item text={<div><Icon name='factory'/>Productie</div>}>
                        <Dropdown.Menu>
                            <Dropdown.Item onClick={(e) => {this.props.dispatch(openMohicanConnectionModal(true))}}>uPlacer connection</Dropdown.Item>
                            <Dropdown.Item disabled={noProject} onClick={(e) => {this.props.dispatch(openMohicanPcbModal(true))}}>
                                <Icon name='thumbtack' />
                                <span className='text'

                                >PCB position</span>

                                <Dropdown.Menu>
                                    {this.props.selectedDesignator ? <Dropdown.Item
                                        onClick={(e) => {
                                            if( this.props.selectedDesignator ) {
                                                mohicanSendMessage('SET_PCB_POSITION', null)
                                                e.stopPropagation();
                                            }
                                        }}
                                    >
                                        {this.props.selectedDesignator.value} at current position {this.props.selectedDesignator.topBottom === "BOTTOM" ? '(top side)' : '(bottom side)'}
                                    </Dropdown.Item> : ''}
                                </Dropdown.Menu>
                            </Dropdown.Item>

                            <Dropdown.Item icon='crosshairs' text={
                                <span style={this.state.setCrosshair ? {color: 'blue'} : {}}>Plaats crosshair</span>
                            } onClick={(e) => {
                                this.setState({setCrosshair: !this.state.setCrosshair})
                            }} style={this.state.setCrosshair ? {color: 'blue !important'} : {}}/>

                            <Dropdown.Item icon='redo' text={
                                'Reset done flags'
                            } onClick={(e) => {
                                this.deleteProductionLines()
                            }} disabled={!this.props.bomProductionSelected}/>

                            <Dropdown.Item text={
                                <span style={this.props.bomDemoMode ? {color: 'blue'} : {}}>Demo mode</span>
                            } onClick={(e) => {
                                this.props.dispatch(setBomDemoMode(!this.props.bomDemoMode))
                            }} disabled={!this.props.bomProductionSelected} style={this.props.bomDemoMode ? {color: 'blue !important'} : {}}/>

                            {this.renderBuddyDownloadMenu()}
                        </Dropdown.Menu>
                    </Dropdown>:
                    null
                }
            </> : null}

            <Confirm
                content={<Modal.Content>
                    <h4>
                        {this.state.confirmDeleteName}
                    </h4>
                    Remove project?
                </Modal.Content>}
                open={this.state.confirmDelete}
                onCancel={(e) => {
                    this.setState({confirmDelete: false});
                    e.preventDefault();
                }}
                onConfirm={(e) => {
                    this.props.dispatch(deleteBom(this.state.confirmDelete, true, () => {
                        this.setState({confirmDelete: false});
                        this.props.history.goBack();
                    }))
                }}
                confirmButton={'Remove'}
            />
        </>
    }
}

BomMenu.propTypes = {
    noProject: PropTypes.bool,
    admin: PropTypes.bool,
    ordersView: PropTypes.bool,
    cameraView: PropTypes.bool,
    viewId: PropTypes.string
}

BomMenu.defaultProps = {
    noProject: true
}

const mapStateToProps = (state) => {
    return {
        currentUser: state.main.currentUser,
        print: state.bom.print,
        aggregateUpload: state.bom.aggregateUpload,
        bomProductionSelected: state.bom.bomProductionSelected,
        selectedDesignator: state.bom.selectedDesignator,
        bomFilter: state.bom.bomFilter,
        bomDemoMode: state.bom.bomDemoMode,
    }


}

export default connect(mapStateToProps)(BomMenu)