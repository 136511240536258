import React from 'react'
import connect from "react-redux/es/connect/connect";
import ProjectListHeader from "./ProjectListHeader";
import ProjectView from "./ProjectView";
import {isEmployee} from "../actions/functions";
import {Loader, Search} from "semantic-ui-react";
import {withRouter} from 'react-router-dom';
import {getProjectList} from "../actions";

class ProtoflowProjectList extends React.Component {

    componentDidMount() {
        this.fetchProjects()
        this.setState({
            searchValue: this.props.projectPageFilter.searchValue
        })
    }

    checkIfNull(value){
        if(value == null) return ' '
        else return ' ' + value + ' '
    }

    fetchProjects(nextPage, name, value, name2, value2) {

        let projectPageFilter= this.props.projectPageFilter;

        if( name ) {

            console.log(name + ': ' + value)

            projectPageFilter= Object.assign({}, projectPageFilter, {
                [name]: value
            })
        }

        if( name2 ) {

            console.log(name2 + ': ' + value2)

            projectPageFilter= Object.assign({}, projectPageFilter, {
                [name2]: value2
            })
        }

        if( nextPage ) {
            projectPageFilter= Object.assign({}, projectPageFilter, {
                pageNumber: this.props.projectPageFilter.pageNumber + 1
            })
        } else {
            projectPageFilter= Object.assign({}, projectPageFilter, {
                pageNumber: 0
            })
        }

        let {all, direction, sort, searchValue, pageNumber, paymentStatusFilter, hasOrderDate}= projectPageFilter;

        this.props.dispatch(getProjectList(all, direction, sort, searchValue, pageNumber, paymentStatusFilter, hasOrderDate, true))
    }

    handleKeyPress = (event, value) => {
        if( event.charCode === 13 ) {
            this.fetchProjects(false, 'searchValue', this.state.searchValue);
        }
    }

    render() {
        const admin= isEmployee(this.props.currentUser);
        let projects= this.props.projectPage ? this.props.projectPage.content : [];

        return <main id={'projects'} className={'pageContainer'}>

            {admin && <>

                <div style={{textAlign: 'center', width: '100%', paddingBottom: '1em'}}>
                    <Search value= "" onSearchChange={(e, { value }) => this.setState({searchValue: value})}
                            onKeyPress={this.handleKeyPress} placeholder={'Search...'} results={[]} showNoResults={false}
                            icon={{ name: 'search', circular: true, link: true, onClick: () => this.handleKeyPress({charCode: 13}) }}
                    />
                </div>

                <ProjectListHeader/>

            </>}
            {(this.props.projectPage && this.props.fetchingProjects) ? <Loader active inline/> : ''}

            {(this.props.projectPage && !this.props.fetchingProjects && projects.length === 0) ? 'Er zijn nog geen projecten' : ''}

            {projects.map((p) => {
                return <ProjectView listView={true} project={p}/>
            })}

        </main>
    }
}

const mapStateToProps = (state) => {
    return {
        lang: state.main.lang,
        projectPage: state.project.projectPage,
        projectPageFilter: state.project.projectPageFilter,
        fetchingProjects: state.project.fetchingProjects,
        currentUser: state.main.currentUser
    }
}

export default connect(mapStateToProps)(withRouter(ProtoflowProjectList))
