import React, {Component} from 'react';
import {Accordion, Icon, List, Popup, Segment,} from "semantic-ui-react";

import 'moment/locale/nl.js'
import connect from "react-redux/es/connect/connect";
import PropTypes from "prop-types";
import {updateProjectPlanning} from "../actions";
import DatePicker from "react-datepicker";
import ChatView from "../chat/ChatView";
import BomStats from "./BomStats";
import {projectPhases} from "../Project";
import PlanningEntryProject from "./PlanningEntryProject";

var moment = require('moment');
moment.locale('nl');

class PlanningEntry extends Component {

    state= {
        open: true
    }

    constructor(props) {
        super();
        this.state.open = props.open
    }

    handleClick = () => {
        this.setState({open: !this.state.open})
    }



    render() {

        return (
            <div style={{marginBottom: 48}}>

                {this.props.projectlist.length && this.props.minutes ? <Accordion>
                        <Accordion.Title active={this.state.open} index={0} onClick={this.handleClick} style={{padding: 0, fontWeight: 'bold'}}>
                            {this.state.open ? <Icon name='dropdown' /> : <Icon name='right triangle' />}
                            {this.props.header + ' '}
                            <Icon name='clock outline' />
                            {Math.trunc(this.props.minutes / 60)}:{("0" + Math.round(this.props.minutes % 60)).slice(-2)} ({this.props.projectlist.length})
                        </Accordion.Title>
                        <Accordion.Content active={this.state.open}>
                            <>
                                {this.props.projectlist.map(p => {
                                    return (
                                        <PlanningEntryProject p={p}/>
                                )})}
                            </>
                        </Accordion.Content>
                    </Accordion> :
                    <div>

                </div>}



            </div>
        )
    }
}

PlanningEntry.propTypes = {
    minutes: PropTypes.number,
    text: PropTypes.string,
    projectlist: PropTypes.array,
    open: PropTypes.bool
};

PlanningEntry.defaultProps = {
    minutes: null,
    text: null,
    projectlist: [],
    open: true
};

const mapStateToProps = (state) => {
    return {
    }
}

export default connect(mapStateToProps)(PlanningEntry)
