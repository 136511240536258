import React, {lazy, Suspense} from 'react'
import {connect} from "react-redux";
import PropTypes from "prop-types";
import {Icon, Popup, Button, Divider, Loader, Dropdown} from "semantic-ui-react";
import {getOrderItemsByRow} from "./functions";
import {
    addBomProductionLine, deleteAllProductionLines,
    getBomOrders,
    mohicanSendMessage,
    openBomOrder, selectDesignatorBomProduction, selectDesignatorBomProductionLastGoTo,
    updateLastUsedBoardLocation, updateMohicanSetting
} from "../actions";
import {formatDateTime} from "../actions/functions";
import {GlobalHotKeys, HotKeys} from "react-hotkeys";
import _ from "lodash";


const plankjeDelta = 8.50;
const plankjeSprocketOffset = 2;

function tapeWidthToSprocketToPocket(width) {

    return (width - 1) / 2;

}

export function gotoPickup(printId, {boardLocation, part, orderpart, klantLevertAan, kla, lastUsedBoardLocation}, plankjePosition, dispatch, reset) {

    let width = (part && part.orderpart) ? part.orderpart.width : orderpart ? orderpart.width : klantLevertAan ? klantLevertAan.width : kla ? kla.width : 0;
    let pitch = (part && part.orderpart) ? part.orderpart.pitch : orderpart ? orderpart.width : klantLevertAan ? klantLevertAan.pitch : kla ? kla.pitch : 0;

    let usedLocation = lastUsedBoardLocation ? (lastUsedBoardLocation.lastUsedLocation) : 0

    if( reset != null ) {

        //console.log('gotoPickup reset=' + reset)

        if (reset === 999) {
            usedLocation = 0
        } else {
            usedLocation = usedLocation + reset;
        }
    }

    //console.log('gotoPickup ' + boardLocation + ' | ' + JSON.stringify(plankjePosition) + ' | ' + width + ' , ' + pitch + ' | ' + usedLocation)

    let board = Number(boardLocation);

    if( board && width && pitch ) {

        let posX = (board % 100) - 1;
        let posY = usedLocation;

        let x= plankjePosition.x + ( (posX * plankjeDelta) + ( tapeWidthToSprocketToPocket(width) ) ) - 8.5;
        let y= plankjePosition.y - ( ( posY * pitch ) - plankjeSprocketOffset );

        console.log('GOTO ', JSON.stringify({x,y}))

        mohicanSendMessage("GOTO", {x, y});

        dispatch(updateLastUsedBoardLocation(printId, boardLocation, usedLocation ? usedLocation : '0'))
        return true;
    }

    return false;
}

export function gotoPlace(printId, designator, pcbLocation, bomProductionSelected, bomProductionName, dispatch) {
    console.log('gotoPlace ' + designator.value + ' | ' + designator.topBottom + ' | ' + JSON.stringify(pcbLocation))

    let bottom = designator.topBottom === "BOTTOM";

    if( designator && pcbLocation && designator.x != null && pcbLocation.x != null ) {
        let x = bottom ? (pcbLocation.x - designator.x) : (pcbLocation.x + designator.x);
        let y = pcbLocation.y + designator.y;

        mohicanSendMessage("GOTO", {x, y});

        dispatch(selectDesignatorBomProductionLastGoTo(designator))

        if( bomProductionSelected ) {
            dispatch(addBomProductionLine(bomProductionSelected, designator.value, bomProductionName))
        }

        return true;
    }
    return false;
}

export function getRowByDesignator(d, rows) {
    console.log('getRowByDesignator ' + JSON.stringify(d))
    let res;
    rows.forEach(r => {
        r.designators.forEach(r_d => {
            if( r_d.id === d.id ) {
                res = r;
            }
        })
    })
    return res;
}

class BomProduction extends React.Component {

    state = {
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if( this.props.selectedDesignator && this.props.d && prevProps.selectedDesignator !== this.props.selectedDesignator ) {

            if( this.props.d.value === this.props.selectedDesignator.value ) {
                if (this._container) {
                    this._container.focus();
                }
            }
        }

        if( this.props.selectedDesignator && this.props.d && this.props.d.id === this.props.selectedDesignator.id && this.props.nextDesignator == null ) {
            this.props.dispatch(selectDesignatorBomProduction(this.props.d, this.props.nextD))
        }

        if(
            (this.props.bomDemoMode && this.props.selectedDesignator && this.props.d && prevProps.selectedDesignator !== this.props.selectedDesignator && this.props.d.value === this.props.selectedDesignator.value) ||
            (this.props.bomDemoMode && this.props.bomDemoMode !== prevProps.bomDemoMode && this.props.selectedDesignator && this.props.d && this.props.d.value === this.props.selectedDesignator.value)
        ) {
            setTimeout(() => {
                this.goToPlace();
            }, 3000)

            if( this.props.nextD ) {
                setTimeout(() => {
                    this.selectNextD()
                }, 6000)
            } else {

                this.resetProduction()

            }
        }
    }

    selectNextD = () => {
        if( !this.isDisabled() ) {
            console.log('pickupNext')
            const printId = this.props.print.id;

            if (this.props.nextD) {
                this.props.dispatch(selectDesignatorBomProduction(this.props.nextD, this.props.nextNextD))

                let r = getRowByDesignator(this.props.nextD, this.props.print.bom);
                //console.log(JSON.stringify(r))
                let orderItems = getOrderItemsByRow(r, this.props.bomOrderList);
                orderItems = orderItems.map(o => {
                    let lastUsedBoardLocation = this.props.boardLocations.find(bl => bl.boardLocation === o.boardLocation)
                    return {...o, lastUsedBoardLocation}
                })

                //console.log(JSON.stringify(orderItems))
                if (orderItems && orderItems.length) {
                    gotoPickup(printId, orderItems[0], this.props.mohicanPlankjePosition, this.props.dispatch);
                }
            }
        }
    }

    goToPlace = () => {
        if( !this.isDisabled() ) {
            const printId = this.props.print.id;
            const d = this.props.d;

            let {bomProductionSelected} = this.props;

            let bomProductionName = this.props.currentUser.firstName;

            gotoPlace(printId, d, this.props.mohicanPcbPosition, bomProductionSelected, bomProductionName, this.props.dispatch)
        }
    }

    goToNextAndPickup = () => {
        if (this.props.lastGoToDesignator && this.props.lastGoToDesignator.id === this.props.d.id) {
            this.selectNextD()
        } else {
            this.goToPickup()
        }
    }

    goToPickup = (reset) => {
        const printId = this.props.print.id;
        const r= this.props.r;

        let orderItems = getOrderItemsByRow(r, this.props.bomOrderList);
        orderItems = orderItems.map(o => {
            let lastUsedBoardLocation = this.props.boardLocations.find(bl => bl.boardLocation === o.boardLocation)
            return {...o, lastUsedBoardLocation}
        })

        if (orderItems && orderItems.length) {
            gotoPickup(printId, orderItems[0], this.props.mohicanPlankjePosition, this.props.dispatch, reset);
        }
    }

    resetProduction = () => {
        this.props.dispatch(deleteAllProductionLines(this.props.bomProductionSelected))
        this.selectFirstD();
    }

    selectFirstD = () => {
        if( !this.isDisabled() ) {
            this.props.dispatch(selectDesignatorBomProduction(this.props.firstD, this.props.secondD))
        }
    }

    isDisabled() {
        let {bomProductionSelected} = this.props;
        return !(this.props.viewId === 'production') || !bomProductionSelected;
    }

    render() {

        const r= this.props.r;
        const d= this.props.d;
        let {bomProductionSelected} = this.props;
        const printId = this.props.print.id;
        let bomProductionName = this.props.currentUser.firstName;

        const buddyPart = r.selectedBuddyPart != null

        //if( buddyPart ) {
        //    return <div style={{lineHeight: '28.2812px'}}>
        //        {`🤖 Buddy Lane: ${r.selectedBuddyPart.location}`}
        //    </div>
        //}

        let orderItems = getOrderItemsByRow(r, this.props.bomOrderList);

        let noPartSelected = !r.selectedSupplierPart && !r.klantLevertAan;

        //if( noPartSelected ) {
        //    return <div style={{lineHeight: '28.2812px'}}>
        //        No part selected
        //    </div>
        //}

        let noOrderPlaced = r && orderItems.length === 0 && (r.selectedSupplierPart || r.klantLevertAan)

        //if ( noOrderPlaced ) {
        //    return <button className='link-button' onClick={() => {
        //        this.props.dispatch(openBomOrder(true))
        //        this.props.dispatch(getBomOrders(this.props.print.id))
        //    }}>
        //        No supplier order placed
        //    </button>
        //}

        let notCheckedIn = orderItems.filter(b => b.boardLocation).length === 0

        //if( notCheckedIn ) {
        //    return <div style={{lineHeight: '28.2812px'}}>
        //        Not checked-in
        //    </div>
        //}

        let disabled = this.isDisabled();

        orderItems = orderItems.map(o => {
            let lastUsedBoardLocation = this.props.boardLocations.find(bl => bl.boardLocation === o.boardLocation)
            return {...o, lastUsedBoardLocation}
        })

        let prodList = this.props.bomProductionList ? this.props.bomProductionList.find(pl => pl.id ===this.props.bomProductionSelected) : null

        let producedInfo = prodList ? prodList.lines.filter(p => p.designator === d.value) : null

        const noPickup = notCheckedIn || noOrderPlaced || noPartSelected || buddyPart;

        const noCentroid = !d.x || !d.y;

        return <Suspense fallback={<div style={{textAlign: 'center'}}><Loader active inline size='mini'/></div>}>

            <div style={{display: 'flex', gap: 4, minHeight: 28.2812, paddingLeft: 4, overflow: 'auto', maxHeight: 'calc(100vh - 300px)'}}>

                <Popup
                    trigger={
                        <Button icon disabled={disabled || noPickup} size='mini' color='blue' onClick={() => {
                            this.goToPickup()
                        }}>
                            <Icon name='arrow down'/>
                        </Button>
                    }
                    content='Pickup next [SPACE]'
                    position='bottom center'
                />

                <Popup
                    trigger={
                        <Button icon disabled={disabled || noCentroid} size='mini' color='green' onClick={() => {
                            this.goToPlace();
                        }}>
                            <Icon name='arrow up'/>
                        </Button>
                    }
                    content='Place [CTRL]'
                    position='bottom center'
                />

                {/*<Popup
                    trigger={
                        <Button disabled={disabled} size='mini' onClick={() => {
                            this.props.dispatch(addBomProductionLine(bomProductionSelected, d.value, bomProductionName))
                        }}>
                            Done
                        </Button>
                    }
                    content='Mark placed [D]'
                    position='bottom center'
                />*/}

                <Popup
                    trigger={
                        <Button icon disabled={disabled || noPickup} size='mini' basic onClick={() => {
                            this.goToPickup(999)
                        }}>
                            <Icon name='home'/>
                        </Button>
                    }
                    content='Reset pickup location back to 0'
                    position='bottom center'
                />

                <div style={{flex: 1, textAlign: 'right', display: 'none'}}>
                    <div>
                        <Popup content={<div>

                            {buddyPart ? <div style={{color: 'blue'}}>
                                {`🤖 Buddy Lane: ${r.selectedBuddyPart.location}`}
                            </div> : ''}

                            {noPartSelected ? <div style={{color: 'orange'}}>
                                No part selected
                            </div> : ''}

                            {noOrderPlaced ? <button style={{color: 'orange'}} className='link-button' onClick={() => {
                                this.props.dispatch(openBomOrder(true))
                                this.props.dispatch(getBomOrders(this.props.print.id))
                            }}>
                                No supplier order placed
                            </button> : ''}

                            {notCheckedIn ? <div style={{color: 'orange'}}>
                                Not checked-in
                            </div> : ''}

                            <Divider/>

                            <table>
                                <tr>
                                    <th>location</th>
                                    <th>stock</th>
                                    <th>lastpickup</th>
                                </tr>
                                {orderItems.map(o => (<tr key={o.id}>
                                    <td>{o.boardLocation}</td>
                                    <td>{o.stock}</td>
                                    <td>{o.lastUsedBoardLocation ? o.lastUsedBoardLocation.lastUsedLocation : '?'}</td>
                                </tr>))}
                            </table>
                            {producedInfo && producedInfo.map(p => (<div>
                                {`Geplaatst door ${p.placedBy} op ` + formatDateTime(p.placed)}
                            </div>))}
                        </div>} trigger={<Icon name='info circle' color={buddyPart ? 'blue' : noPickup ? 'orange' : ''}/>} />



                    </div>
                </div>

            </div>

        </Suspense>
    }
}

BomProduction.propTypes = {
    r: PropTypes.object,
    d: PropTypes.object,
    nextD: PropTypes.object,
    nextNextD: PropTypes.object,
    firstD: PropTypes.object,
    secondD: PropTypes.object,
    lastD: PropTypes.object,
};

BomProduction.defaultProps = {
    r: null,
    d: null,
    nextD: null,
    nextNextD: null,
    firstD: null,
    secondD: null,
    lastD: null,
};

const mapStateToProps = (state) => {
    return {
        print: state.bom.print,
        production: state.bom.production,
        bomOrderList: state.bom.bomOrderList,
        bomProductionList: state.bom.bomProductionList,
        bomProductionSelected: state.bom.bomProductionSelected,
        boardLocations: state.bom.boardLocations,
        mohicanPcbPosition: state.bom.mohicanPcbPosition,
        mohicanPlankjePosition: state.bom.mohicanPlankjePosition,
        selectedDesignator: state.bom.selectedDesignator,
        nextDesignator: state.bom.nextDesignator,
        lastGoToDesignator: state.bom.lastGoToDesignator,
        currentUser: state.main.currentUser,
        bomFilter: state.main.bomFilter,
        viewId: state.bom.viewId,
        bomDemoMode: state.bom.bomDemoMode,
    }
}

export default connect(mapStateToProps)(BomProduction)