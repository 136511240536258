import React, {Component} from 'react';
import connect from "react-redux/es/connect/connect";
import PropTypes from "prop-types";
import {activeUsers} from "../actions";
import {Button, Checkbox, Icon, Table} from "semantic-ui-react";
import {formatDateTime, isEmployee} from "../actions/functions";
import Translate, {getText} from "../Translate";

class ActiveUsers extends React.Component {

    state = {}

    componentDidMount() {
        this.props.dispatch(activeUsers())
    }

    render() {
        return <div>
            <div style={{textAlign: 'right'}}>
                <Checkbox toggle label={getText(this.props, 'deltaProto_gebruikers_verbergen')} checked={this.state.hideDP} onChange={() => {
                    this.setState({hideDP: !this.state.hideDP})
                }}/>

                <Button style={{marginLeft: 32}} icon onClick={() => this.props.dispatch(activeUsers())}>
                    <Icon name='refresh'/>
                </Button>
            </div>

            <Table compact='very' size='small' style={{width: '100%', lineHeight: '16px'}}>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell></Table.HeaderCell>
                        <Table.HeaderCell><Translate value="naam"/></Table.HeaderCell>
                        <Table.HeaderCell><Translate value="dashboard_datum"/></Table.HeaderCell>
                        <Table.HeaderCell><Translate value="page_projects"/></Table.HeaderCell>
                        <Table.HeaderCell><Translate value="laatste_projectaanvraag"/></Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {this.props.activeUsers.filter(u => {
                        if( this.state.hideDP ) {
                            return !isEmployee(u.user)
                        }
                        return true
                    }).map(u => (
                        <Table.Row>
                            <Table.Cell>{isEmployee(u.user) ? 'DeltaProto' : ''}</Table.Cell>
                            <Table.Cell>{u.user.fullName}</Table.Cell>
                            <Table.Cell>{formatDateTime(u.lastActivity)}</Table.Cell>
                            <Table.Cell style={{color: u.numberOfProjects === 0 ? 'red' : ''}}>{u.numberOfProjects}</Table.Cell>
                            <Table.Cell>{formatDateTime(u.lastAanvraagDate)}</Table.Cell>
                        </Table.Row>
                    ))}

                </Table.Body>
            </Table>

        </div>
    }
}

ActiveUsers.propTypes = {
};

ActiveUsers.defaultProps = {
};

const mapStateToProps = (state) => {
    return {
        lang: state.main.lang,
        activeUsers: state.main.activeUsers,
    }
}

export default connect(mapStateToProps)(ActiveUsers)