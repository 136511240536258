import React from 'react'
import {Accordion, Divider, Menu, Form, Icon, Button, Checkbox, Dropdown} from "semantic-ui-react";
import {connect} from "react-redux";
import {
    openSideBar,
    saveBom

} from "../actions";
import BomSettings from "./BomSettings";

const initial = {
    activeIndex: 0
}

class SideBar extends React.Component {

    state = {initial}

    handleChange = (e, { value }) => this.setState({ value })

    handleClick = (e, titleProps) => {
        const { index } = titleProps
        const { activeIndex } = this.state
        const newIndex = activeIndex === index ? -1 : index

        this.setState({ activeIndex: newIndex })
    }


    render(){
        const { sideBarOpen } = this.props;

        if(!this.props.print) {
            return <div/>
        }
        const { activeIndex } = this.state

        return <div style={{}}>
            {/*<Icon
                onClick={()=> {
                    this.props.dispatch(openSideBar(true))

                }}
                link name='cog'
                size='large'/>*/}

            <div
                className={'sidebar-main'}
                style={{width: sideBarOpen ? 480 : 0}}>
                <h2><Icon
                    name='cog'/>
                    Settings
                </h2>
                <Button icon style={{position: 'absolute', right:2, top:4}}
                      onClick={()=> {
                          this.props.dispatch(openSideBar(false))
                      }}>
                    <Icon name='close'/>
                </Button>

                <Accordion fluid as={Menu} vertical>
                    <Menu.Item>
                        <Accordion.Title
                            active={activeIndex === 0}
                            content={<p className={'menu-title'}><Icon
                                name ='cog'
                                color='black'/>
                                {this.props.print.name}
                            </p>}
                            index={0}
                            onClick={this.handleClick}
                        />
                        <Accordion.Content active={activeIndex === 0} content={
                            <BomSettings/>
                        }/>
                    </Menu.Item>

                </Accordion>

                <div
                    onClick={()=>{
                        this.props.dispatch(openSideBar(false))
                    }}
                    className='sidebar-blackout'
                    style={{width: sideBarOpen ? '100%' : '0', opacity: sideBarOpen ? 0.75 : 0}}>
                </div>
            </div>
        </div>

    }
}

const mapStateToProps = (state) => {
    return {
        suppliers: state.bom.suppliers,
        manufacturers: state.bom.manufacturers,
        print: state.bom.print,
        sideBarOpen: state.project.sideBarOpen
    }
}

export default connect(mapStateToProps)(SideBar)