import React, {Component, lazy} from 'react';
import './App.css';
import Translate from "./Translate";
import {
    Button,
    Container,
    Divider,
    Form,
    Header,
    Icon,
    Image,
    Input,
    Modal, Select,
    TextArea
} from "semantic-ui-react";
import connect from "react-redux/es/connect/connect";
import PropTypes from "prop-types";
import {changeBlogItemValue, getBlogItem, saveBlogItem} from "./actions";
import OnVisible from "react-on-visible";
import {formatDate, isEmployee} from "./actions/functions";
import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css';
import Link from "./Link";

const MarkdownRender = lazy(() => import('./MarkdownRender'));

const testemonials= {
    harm: <div>
        <Link href="https://www.linkedin.com/in/harmslijkhuis/">Harm Slijkhuis</Link>
        <div><Translate value={'about_harm_title'}/></div>
    </div>,
    arnoud: <div>
        <Link href="https://www.linkedin.com/in/avanderheijde/">Arnoud van der Heijde</Link>
        <div><Translate value={'about_arnoud_title'}/></div>
    </div>,
    michiel: <div>
        <Link href="https://www.linkedin.com/in/michiel-wanninkhof-0443488a/">Michiel Wanninkhof</Link>
        <div><Translate value={'about_michiel_title'}/></div>
    </div>
}

const authors= [
    { key: 'arnoud', value: 'arnoud', text: 'Arnoud' },
    { key: 'harm', value: 'harm', text: 'Harm' },
    { key: 'michiel', value: 'michiel', text: 'Michiel' },
];

class BlogItem extends Component {

    renderAdmin(b) {
        return <div>
            <button className="link-button" style={{textAlign: 'right', float: 'right'}} onClick={() => this.props.dispatch(changeBlogItemValue(b.id,'edit', true))}><Translate value='prototips_edit'/></button>

            <Modal open={b.edit} onClose={() => this.props.dispatch(changeBlogItemValue(b.id,'edit', false))} closeIcon>
                <Modal.Header>Bewerken</Modal.Header>
                <Modal.Content scrolling>
                    <Modal.Description>
                        <Form>
                            <Input placeholder='Titel' fluid value={b.title} onChange={(e) => this.props.dispatch(changeBlogItemValue(b.id,'title', e.target.value))}/>
                            <DatePicker customInput={<Input style={{width: 196}} fluid value={formatDate(b.date)}/>}
                                        selected={b.date} onChange={v => this.props.dispatch(changeBlogItemValue(b.id, 'date', v))}
                                        todayButton={"Vandaag"} dateFormat={"d-M-yyyy"}/>
                            <Select placeholder='Author' options={authors} value={b.author} onChange={(e,d) => this.props.dispatch(changeBlogItemValue(b.id,'author', d.value))} />
                            <TextArea style={{ minHeight: 300 }} placeholder='Bericht' value={b.nl} onChange={(e,d) => this.props.dispatch(changeBlogItemValue(b.id,'nl', d.value))}/>
                            <div>
                                Gebruik <a target="_balnk" href="https://github.com/adam-p/markdown-here/wiki/Markdown-Cheatsheet">Markdown</a> om de post te stylen.
                            </div>
                        </Form>
                        <Divider horizontal>Voorbeeld</Divider>
                        <div>
                            {this.renderBlogItem(b)}
                        </div>
                    </Modal.Description>
                </Modal.Content>
                <Modal.Actions>
                    <Button onClick={() => this.props.dispatch(changeBlogItemValue(b.id,'edit', false))}>
                        <Translate value='cancel'/>
                    </Button>
                    <Button primary={b.dirty} onClick={() => this.props.dispatch(saveBlogItem(b))}>
                        <Translate value='save'/> <Icon name='save' />
                    </Button>
                </Modal.Actions>
            </Modal>
        </div>
    }

    renderBlogItem(b) {
        return <div className={'blogitem'}>
            <Header as='h1'>{b.title}</Header>
            {(b.edit || b.id == null) && <div style={{float: 'right', color: 'red'}}>Niet opgeslagen</div>}

            <div style={{color: 'grey'}}><Translate value='prototip_posted'/>{" "}{formatDate(b.date)}</div>

            <Divider/>
            <div style={{display: 'flex', marginBottom: 14}}>
                <div style={{padding: 8}}>
                    <Image avatar src={'/images/team/'+b.author+'_avatar.jpeg'} />
                </div>
                <div style={{color: 'grey'}}>
                    {testemonials[b.author]}
                </div>
            </div>
            <Divider/>

            {/*<ReactMarkdown renderers={{image: ({node, ...props}) => <MyImage {...props}/>}} >
                    {b.nl}      //TODO: Move to new version of react-markdown!
            </ReactMarkdown>*/}

            {/*<ReactMarkdown rehypePlugins={[rehypeRaw]} children={b.nl} renderers={{image: MyImage}} />*/}

            <MarkdownRender children={b.nl}/>

        </div>
    }

    render() {

        let{ b }= this.props;

        let admin= isEmployee(this.props.currentUser);

        return (
            <Container text style={{ width: '100%', textAlign: 'left', paddingBottom: '4em' }}>
                {admin && this.renderAdmin(b)}

                {this.renderBlogItem(b)}

                {this.props.index !== null && <OnVisible onChange={(e) => {
                    console.log('visible ' + (this.props.index + 1) )
                    this.props.dispatch(getBlogItem(this.props.index + 1))
                }}/>}

            </Container>
        );
    }
}

BlogItem.propTypes = {
    b: PropTypes.object,
    index: PropTypes.number
};

BlogItem.defaultProps = {
    b: null,
    index: null
};

const mapStateToProps = (state) => {
    return {
        lang: state.main.lang,
        blogitems: state.main.blogitems,
        currentUser: state.main.currentUser
    }
}

export default connect(mapStateToProps)(BlogItem)
