import React from 'react'
import LoginSignup from "./LoginSignup";

class LoginSignupInline extends React.Component {
    render() {
        return <div style={{paddingTop: 100, paddingBottom: 100}}>
            <div style={{maxWidth: 400, margin: 'auto'}}>
                <LoginSignup inline={true}/>
            </div>
        </div>
    }
}

export default LoginSignupInline;