import React from 'react'
import {Button, Modal, Table} from "semantic-ui-react";
import PropTypes from "prop-types";
import connect from "react-redux/es/connect/connect";

class BomImportErrorDialog extends React.Component {
    render() {
        return <Modal closeIcon={true} open={this.props.open} onClose={() => this.props.onClose && this.props.onClose()}>
            <Modal.Header>Fout bij importeren ({this.props.print ? this.props.print.name : ''})</Modal.Header>
            <Modal.Content scrolling>
                <Table celled>
                    <Table.Header>
                        <Table.HeaderCell>Row</Table.HeaderCell>
                        <Table.HeaderCell>Column</Table.HeaderCell>
                        <Table.HeaderCell>Message</Table.HeaderCell>
                    </Table.Header>
                    <Table.Body>
                        {this.props.errors.map(e => <Table.Row>
                            <Table.Cell>{e.row}</Table.Cell>
                            <Table.Cell>{String.fromCharCode(64 + e.col)}</Table.Cell>
                            <Table.Cell>{e.message}</Table.Cell>
                        </Table.Row>)}
                    </Table.Body>
                </Table>
            </Modal.Content>
            <Modal.Actions>
                <Button primary onClick={() => this.props.onClose && this.props.onClose()}>
                    Sluiten
                </Button>
            </Modal.Actions>
        </Modal>
    }
}

BomImportErrorDialog.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    errors: PropTypes.array,
};

BomImportErrorDialog.defaultProps = {
    onClose: null,
};

const mapStateToProps = (state) => {
    return {
        searchRow: state.bom.searchRow,
        print: state.bom.print
    }
}

export default connect(mapStateToProps)(BomImportErrorDialog)