import React from 'react';
import {Button, Popup} from "semantic-ui-react";
import PropTypes from "prop-types";

class ButtonTabMenuFullWidth extends React.Component {

    onClick = () => {
        if( this.props.onClick ) {
            this.props.onClick();
        }
    }

    render() {
        return (
            <Popup
                content='Gebruik volledige breedte van het scherm'
                position='bottom right'
                trigger={
                    <Button
                        {...this.props}
                        size='tiny'
                        icon='expand'
                        floated='right'
                        onClick={this.onClick}

                    />
                }
            />
        );
    }
}

ButtonTabMenuFullWidth.propTypes = {
    onClick: PropTypes.func,
};

ButtonTabMenuFullWidth.defaultProps = {
    onClick: null,
};

export default ButtonTabMenuFullWidth;