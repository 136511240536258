import React from 'react'
import PropTypes from "prop-types";
import connect from "react-redux/es/connect/connect";
import {Button, Card, Checkbox, Form, Header, Icon, Input, Message, Modal, Popup, Table} from "semantic-ui-react";
import Translate, {getText} from "./Translate";
import {formatDateTime, isEmployee} from "./actions/functions";
import TextareaAutosize from "react-textarea-autosize";
import {userDisplayName} from "./oldui/AccountListItem";
import BasicSheet from "./basic/BasicSheet";
import UploadFile from "./oldui/UploadFile";
import {addProjectAttachment, receiveProjectOrderPcbInfoFileUploaded} from "./actions";
import InformationPopup from "./InformationPopup";

class ProjectChanges extends React.Component {

    state = {
        newAction: null
    }

    changeValue = (i, name, value) => {
        if( this.props.onChangeValue ) {
            this.props.onChangeValue(i, name, value)
        }
    }

    changeValueAndSave = (i, name, value) => {
        if( this.props.onChangeValue ) {
            this.props.onChangeValue(i, name, value)

            this.saveDelay('', 200 )
        }
    }

    removeLine = (i) => {
        if( this.props.onRemoveLine ) {
            this.props.onRemoveLine(i)

            this.saveDelay('', 200 )
        }
    }

    addLine = (line, file) => {
        if( this.props.onAddLine ) {
            this.props.onAddLine(line, file)

            this.saveDelay('', 200 )
        }
    }

    save = (action) => {
        if( this.props.onSave ) {
            this.props.onSave(action)
        }
    }

    saveDelay = (action, delay) => {
        if( this.props.onSave ) {

            setTimeout(() => {
                this.props.onSave(action)
            }, delay);
        }
    }

    renderActionButton(textKey, action, disabled, selection, submitDisabled = false) {

        return <Popup trigger={
            <div>
                <Button
                    icon
                    labelPosition='left'
                    color={disabled ? '' : 'green'}
                    size='small'
                    disabled={disabled || selection === null || submitDisabled}
                    onClick={() => this.save(selection)}
                >
                    <Icon name={this.props.startIcon}/>
                    {selection ? <Translate value={textKey + '.' + selection.toLowerCase()}/> : 'Submit'}
                </Button>
            </div>
        } content={
            selection ? <Translate value={textKey + '.' + selection.toLowerCase() + '.info'}/> : 'Maak een selectie'
        } />
    }

    handleChangeAction = (newAction) => {
        this.setState({newAction: newAction})
    }

    renderActionRadioButton(textKey, action, disabled, selection, onChange) {

        const checked = selection === action;
        return <div style={{fontWeight: checked ? 'bold' : null}}>
            <Form.Field>
                <Checkbox
                    radio
                    label={getText(this.props, textKey + '.' + action.toLowerCase())}
                    disabled={disabled}
                    name='actionRadioGroup'
                    value='that'
                    checked={checked}
                    onChange={(e, data) => {
                        if( onChange ) {
                            onChange(action)
                        }
                    }}
                />
                <span style={{marginLeft: 4, opacity: disabled ? .5 : null, color: disabled ? 'black' : null}}>
                    <InformationPopup value={textKey + '.' + action.toLowerCase() + '.info'}/>
                </span>
            </Form.Field>
        </div>
    }

    render() {

        let {value, disabled, saving, error, textKey, action, action2, action3, allowChange, showInputNew} = this.props;

        const inputDisabled = false;//disabled && !allowChange;
        const submitDisabled = disabled && !allowChange

        let lines = value.lines.sort((a,b) => a.line-b.line)

        let releasedLines = lines.filter(l => l.released).reduce(function (r, a, i) {
            r[a.released] = r[a.released] || [];
            r[a.released].push({...a, i});
            return r;
        }, Object.create(null));

        let newLines = lines.filter(l => !l.released)

        let existingLength = lines.filter(l => l.released).length

        let highestLine = Math.max(...lines.map(l => l.line), 0);

        return (
            <div>
                {Object.entries(releasedLines).map(([key, value], i) => {
                    const disabled = key;
                    const selectedAction = key ? (value[0].releasedForRepair ? 'REPAIR' : value[0].releasedForProduction ? 'RELEASE' : 'SAMPLE') : null;
                    const selectedActionText = key ? (value[0].releasedForRepair ? 'Repareer de sample' : value[0].releasedForProduction ? 'Maak de serie' : 'Maak de volgende sample') : null;
                    return <>
                        <Form style={{marginTop: i > 0 ? 16 : 0}}>
                            <BasicSheet
                                disabled={disabled}
                                header={<div>
                                    <Translate value={textKey + '.cardheader'}/>{selectedActionText ? ': '+selectedActionText : ''} <InformationPopup value={textKey+'.message'}/>
                                </div>}
                                actionMenuBottom={key ? <div style={{display: 'flex'}}>
                                    <div style={{textAlign: 'right'}}>
                                        <div>{key ? 'Vrijgegeven op ' + formatDateTime( parseInt(key) ) : ''}</div>
                                        <div>{key ? <Translate value='listofchange_call_for_edit'/> : ''}</div>
                                    </div>
                                    {isEmployee(this.props.currentUser) ? <Popup trigger={<Button style={{marginLeft: 8}} icon color='red' labelPosition='left' onClick={() => {
                                        if (this.props.onCancel) {
                                            this.props.onCancel(parseInt(key))
                                        }
                                    }}>
                                        <Icon name='undo'/>
                                        Ongedaan maken
                                    </Button>} content={'Vrijgave annuleren, om weer invulbaar te maken. Let op: De projectfase en leverdata moeten handmatig teruggezet worden'} /> : null}

                                    {isEmployee(this.props.currentUser) ? <Button
                                        icon
                                        labelPosition='left'
                                        size='small'
                                        color={this.props.saved ? 'green' : null}
                                        primary={value.dirty}
                                        onClick={() => this.save('')}
                                        loading={saving}
                                    >
                                        <Icon name='save' />
                                        {this.props.saved ? <Translate value='saved'/> : <Translate value={textKey+'.save'}/>}
                                    </Button> : null}
                                </div> : <>

                                    {/*{action ? this.renderActionButton(textKey, action, disabled || saving || releasedLines.length === 0) : ''}
                                    {action2 ? this.renderActionButton(textKey, action2, disabled || saving || releasedLines.length === 0) : ''}
                                    {action3 ? this.renderActionButton(textKey, action3, disabled || saving || releasedLines.length === 0) : ''}*/}

                                    <Button
                                        icon
                                        labelPosition='left'
                                        size='small'
                                        color={this.props.saved ? 'green' : null}
                                        primary={value.dirty}
                                        disabled={disabled || saving}
                                        onClick={() => this.save(selectedAction)}
                                        loading={saving}
                                    >
                                        <Icon name='save' />
                                        {this.props.saved ? <Translate value='saved'/> : <Translate value={textKey+'.save'}/>}
                                    </Button>

                                    {selectedAction}

                                </>}
                            >

                                {this.renderActionRadioButton(textKey, selectedAction, disabled || saving || newLines.length === 0, selectedAction)}
                                {/*{action3 ? this.renderActionRadioButton(textKey, action3, disabled || saving || newLines.length === 0, selectedAction) : ''}
                                {action2 ? this.renderActionRadioButton(textKey, action2, disabled || saving || newLines.length === 0, selectedAction) : ''}
                                {action ? this.renderActionRadioButton(textKey, action, disabled || saving || newLines.length === 0, selectedAction) : ''}
*/}
                                <Table celled compact='very'>
                                    <colgroup>
                                        <col width="5%"/>
                                        <col width="15%"/>
                                        <col width="35%"/>
                                        <col width="35%"/>
                                        {/*<col width="5%"/>
                                            <col width="5%"/>
                                            <col width="5%"/>*/}
                                    </colgroup>

                                    <Table.Body>
                                        <Table.Row style={{opacity: disabled ? .5 : 1, backgroundColor: 'rgba(33,133,208)', color: 'white'}}>
                                            <Table.HeaderCell
                                                style={{paddingTop: 5, paddingBottom: 5}}
                                            >#</Table.HeaderCell>
                                            <Table.HeaderCell><Translate value={textKey+'.designator'}/></Table.HeaderCell>
                                            <Table.HeaderCell><Translate value={textKey+'.issue'}/></Table.HeaderCell>
                                            <Table.HeaderCell><Translate value={textKey+'.suggested'}/></Table.HeaderCell>
                                            <Table.HeaderCell collapsing={true}></Table.HeaderCell>
                                            <Table.HeaderCell collapsing={true}>
                                                <Popup
                                                    trigger={<Icon name='cart'/>} position='top right'
                                                    content={<Translate value={textKey+'purchased'}/>}
                                                />
                                            </Table.HeaderCell>
                                            <Table.HeaderCell collapsing={true}>
                                                <Popup
                                                    trigger={<Icon name='clipboard list'/>} position='top right'
                                                    content={<Translate value={textKey+'checkin'}/>}
                                                />
                                            </Table.HeaderCell>
                                            <Table.HeaderCell collapsing={true}>
                                                <Popup
                                                    trigger={<Icon name='factory'/>} position='top right'
                                                    content={<Translate value={textKey+'checked'}/>}
                                                />
                                            </Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Body>
                                    <Table.Body>

                                        {value.map((l,i) => this.renderRow(l.i, inputDisabled, l, value))}

                                    </Table.Body>
                                </Table>
                            </BasicSheet>
                        </Form>
                    </>
                })}


                {showInputNew ? <Form style={{marginTop: 16}}>
                    <BasicSheet
                        header={<div>
                            <Translate value={textKey + '.cardheader'}/> <InformationPopup
                            value={textKey + '.message'}/>
                        </div>}
                        actionMenuBottom={<>
                            <div style={{display: 'flex'}}>
                                <Button
                                    icon
                                    labelPosition='left'
                                    size='small'
                                    color={this.props.saved ? 'green' : null}
                                    primary={value.dirty}
                                    disabled={inputDisabled || saving}
                                    onClick={() => this.save('')}
                                >
                                    <Icon name='save'/>
                                    {this.props.saved ? <Translate value='saved'/> :
                                        <Translate value={textKey + '.save'}/>}
                                </Button>

                                {this.renderActionButton(textKey, action, inputDisabled || saving || newLines.length === 0, this.state.newAction, submitDisabled)}
                            </div>
                        </>}
                    >

                        <div style={{flex: 1}}>
                            {action3 ? this.renderActionRadioButton(textKey, action3, inputDisabled || saving || newLines.length === 0, this.state.newAction, this.handleChangeAction) : ''}
                            {action2 ? this.renderActionRadioButton(textKey, action2, inputDisabled || saving || newLines.length === 0, this.state.newAction, this.handleChangeAction) : ''}
                            {action ? this.renderActionRadioButton(textKey, action, inputDisabled || saving || newLines.length === 0, this.state.newAction, this.handleChangeAction) : ''}
                        </div>

                        <Table celled compact='very'>

                            <col width="5%"/>
                            <col width="15%"/>
                            <col width="35%"/>
                            <col width="35%"/>
                            {/*<col width="5%"/>
                                <col width="5%"/>
                                <col width="5%"/>*/}

                            <Table.Body>
                                <Table.Row style={{
                                    opacity: inputDisabled ? .5 : 1,
                                    backgroundColor: '#2fba51',
                                    color: 'white'
                                }}>
                                    <Table.HeaderCell
                                        style={{paddingTop: 5, paddingBottom: 5}}
                                    >#</Table.HeaderCell>
                                    <Table.HeaderCell><Translate value={textKey + '.designator'}/></Table.HeaderCell>
                                    <Table.HeaderCell><Translate value={textKey + '.issue'}/></Table.HeaderCell>
                                    <Table.HeaderCell><Translate value={textKey + '.suggested'}/></Table.HeaderCell>
                                    <Table.HeaderCell collapsing={true}></Table.HeaderCell>
                                    <Table.HeaderCell collapsing={true}>
                                        <Popup
                                            trigger={<Icon name='cart'/>} position='top right'
                                            content={<Translate value={textKey + 'purchased'}/>}
                                        />
                                    </Table.HeaderCell>
                                    <Table.HeaderCell collapsing={true}>
                                        <Popup
                                            trigger={<Icon name='clipboard list'/>} position='top right'
                                            content={<Translate value={textKey + 'checkin'}/>}
                                        />
                                    </Table.HeaderCell>
                                    <Table.HeaderCell collapsing={true}>
                                        <Popup
                                            trigger={<Icon name='factory'/>} position='top right'
                                            content={<Translate value={textKey + 'checked'}/>}
                                        />
                                    </Table.HeaderCell>
                                </Table.Row>
                            </Table.Body>
                            <Table.Body>
                                {newLines.map((l, i) => this.renderRow(existingLength + i, inputDisabled, l, value))}
                            </Table.Body>
                        </Table>


                        <div style={{display: 'flex', gap: 4, flexFlow: 'row-reverse'}}>

                            <UploadFile text={<>
                                <Icon name='add'/>
                                <Icon name='file'/>
                            </>} icon={false} size='mini'
                                        url={'/api/project/uploadAttachment'}
                                        style={{visibility: inputDisabled ? 'hidden' : ''}}
                                        onResult={(body) => {
                                            this.addLine(highestLine + 1, body)
                                        }} popupContent={<Translate value='addfile'/>}/>

                            <Modal open={this.state.addFileModal} onClose={() => this.setState({addFileModal: false})}>
                                <Modal.Header>Bestand toevoegen</Modal.Header>
                                <Modal.Content>
                                    <Modal.Description>
                                        <UploadFile text={'Bestand toevoegen'}
                                                    url={'/api/project/uploadAttachment'}
                                                    onResult={(body) => {
                                                        this.setState({addFileModal: false})
                                                        this.addLine(highestLine + 1, body)
                                                    }}/>
                                    </Modal.Description>
                                </Modal.Content>
                            </Modal>

                            <div>
                                <Popup
                                    position='top right'
                                    trigger={<Button
                                        icon
                                        size='mini'
                                        style={{visibility: inputDisabled ? 'hidden' : ''}}
                                        onClick={() => this.addLine(highestLine + 1)}
                                    >
                                        <Icon name='add'/>
                                    </Button>}
                                    content={<Translate value='addline'/>}
                                />
                            </div>

                        </div>

                        {value.extra ? <div style={{paddingTop: 14}}>
                            <b>{getText(this.props, textKey + '.extra')}</b>
                            <p>{value.extra}</p>
                        </div> : ''}

                        {error && <Message negative>
                            <Message.Header><Translate value="wrong"/></Message.Header>
                        </Message>}


                    </BasicSheet>
                </Form> : null}
            </div>
        );
    }

    renderRow(i, inputDisabled, l, value) {

        inputDisabled = inputDisabled || l.released;

        let lineNumber = l.line != null ? l.line : i+1;

        return <Table.Row row={lineNumber} key={lineNumber} style={{textDecoration: l.isRemoved ? 'line-through' : ''}}>
            <Table.Cell>{lineNumber}</Table.Cell>
            <Table.Cell onMouseEnter={() => this.setState({mouseOverDesignator: i})}
                        onMouseLeave={() => this.setState({mouseOverDesignator: null})}>
                {inputDisabled ? <div>{l.designator}</div> :
                    <TextareaAutosize rows={1} disabled={inputDisabled} transparent fluid
                                      placeholder='C1, C2...'
                                      value={l.designator ? l.designator : ''}
                                      onChange={e => this.changeValue(i, 'designator', e.target.value)}
                                      onBlur={() => this.save('')}
                    />
                }
            </Table.Cell>
            <Table.Cell onMouseEnter={() => this.setState({mouseOverIssue: i})}
                        onMouseLeave={() => this.setState({mouseOverIssue: null})}>
                {inputDisabled ? <div>{l.issue}</div> :
                    <TextareaAutosize rows={1} disabled={inputDisabled} transparent fluid
                                      value={l.issue ? l.issue : ''}
                                      onChange={e => this.changeValue(i, 'issue', e.target.value)}
                                      onBlur={() => this.save('')}
                    />
                }
            </Table.Cell>

            {l.file ? <Table.Cell>
                    <a download href={'/api/project/getAttachment?id='+l.file.id}>{l.file.name}</a>
            </Table.Cell> :
            <>
                <Table.Cell onMouseEnter={() => this.setState({mouseOverSuggested: i})}
                            onMouseLeave={() => this.setState({mouseOverSuggested: null})}>
                    {inputDisabled ? <div>{l.suggested}</div> :
                        <TextareaAutosize rows={1} disabled={inputDisabled} transparent fluid
                                          value={l.suggested ? l.suggested : ''}
                                          onChange={e => this.changeValue(i, 'suggested', e.target.value)}
                                          onBlur={() => this.save('')}
                        />
                    }
                </Table.Cell>
            </>}
            <Table.Cell style={{textAlign: 'center'}}>
                {l.released ? <Popup
                    position='top right'
                    hoverable={true}
                    trigger={<Button
                        icon
                        size='mini'
                        disabled={inputDisabled && !isEmployee(this.props.currentUser)}
                        onClick={e => this.changeValue(i, 'isRemoved', !l.isRemoved)}
                    >
                        <Icon name={l.isRemoved ? 'redo' : 'close'}/>
                    </Button>}
                    content={<div>
                        <Translate value='deleteline'/>
                        {isEmployee(this.props.currentUser) ?
                            <Button
                                icon='trash' color='red' style={{marginLeft: 8}}
                                onClick={() => {
                                    this.removeLine(i)
                                }}
                            />
                            : ''}
                    </div>}
                /> : <Popup
                    position='top right'
                    trigger={<Button
                        icon
                        size='mini'
                        disabled={inputDisabled}
                        onClick={() => this.removeLine(i)}
                    >
                        <Icon name='minus'/>
                    </Button>}
                    content={<Translate value='removeline'/>}
                />}
            </Table.Cell>
            <Table.Cell style={{textAlign: 'center'}}>
                {l.released ? <Popup
                    position='top right'
                    trigger={<Checkbox checked={l.isPurchasedBy} disabled={!isEmployee(this.props.currentUser)} onChange={e => this.changeValueAndSave(i, 'isPurchasedBy', !l.isPurchasedBy)}/>}
                    content={<div>
                        <Translate value='checked.purchase'/>
                        <div>{userDisplayName(l.purchasedBy)}</div>
                        <div>{formatDateTime(l.purchasedDate)}</div>
                    </div>}
                /> : ''}
            </Table.Cell>
            <Table.Cell style={{textAlign: 'center'}}>
                {l.released ? <Popup
                    position='top right'
                    trigger={<Checkbox checked={l.isCheckinBy} disabled={!isEmployee(this.props.currentUser)} onChange={e => this.changeValueAndSave(i, 'isCheckinBy', !l.isCheckinBy)}/>}
                    content={<div>
                        <Translate value='checked.checkin'/>
                        <div>{userDisplayName(l.checkinBy)}</div>
                        <div>{formatDateTime(l.checkinDate)}</div>
                    </div>}
                /> : ''}
            </Table.Cell>
            <Table.Cell style={{textAlign: 'center'}}>
                {l.released ? <Popup
                    position='top right'
                    trigger={<Checkbox checked={l.isCheckedBy} disabled={!isEmployee(this.props.currentUser)} onChange={e => this.changeValueAndSave(i, 'isCheckedBy', !l.isCheckedBy)}/>}
                    content={<div>
                        <Translate value='checked.production'/>
                        <div>{userDisplayName(l.checkedBy)}</div>
                        <div>{formatDateTime(l.checkedDate)}</div>
                    </div>}
                /> : ''}
            </Table.Cell>
        </Table.Row>;
    }
}


ProjectChanges.propTypes = {
    showInputNew: PropTypes.bool,
    textKey: PropTypes.string,
    startIcon: PropTypes.string,
    action: PropTypes.string,
    action2: PropTypes.oneOf([null, 'SAMPLE', 'REPAIR']),
    onChangeValue: PropTypes.func,
    onRemoveLine: PropTypes.func,
    onAddLine: PropTypes.func,
    onSave: PropTypes.func,
    value: PropTypes.object,
    saving: PropTypes.bool,
    saved: PropTypes.bool,
    error: PropTypes.bool,
    disabled: PropTypes.bool,
    allowChange: PropTypes.bool,
    sampleCheckedDate: PropTypes.object,
};

ProjectChanges.defaultProps = {
    showInputNew: false,
    textKey: 'production.release',
    startIcon: 'rocket',
    action: 'RELEASE',
    action2: 'SAMPLE',
    action3: 'REPAIR',
    onSave: null,
    value: {lines: []},
    saving: false,
    saved: false,
    error: false,
    disabled: true,
    allowChange: false,
    sampleCheckedDate: null,
};

const mapStateToProps = (state) => {
    return {
        lang: state.main.lang,
        currentLang: state.main.currentLang,
        currentUser: state.main.currentUser,
    }
}

export default connect(mapStateToProps)(ProjectChanges);