import React, { Component } from 'react';
import {Button, Form, Message, Modal, Tab} from "semantic-ui-react";
import connect from "react-redux/es/connect/connect";
import {
    checkEmail,
    fetchFormData,
    login,
    openLoginSignup,
    signup,
    validateLogin, zoekKvkHandelsnaam,
    zoekKvkNummer
} from "./actions";
import Translate, {getText} from "./Translate";
import PropTypes from "prop-types";
import _ from 'lodash'

const initialState = {
    user: null,
    showLogin: false,
    register: false,
    loading: false,
    loginFailed: false,
    registerSuccess: false,
    registerFailed: false,
    forgotPassword: false,
    forgotPasswordDone: false,
    setPasswordDone: false,
    setPasswordError: false,

    firstName: '',
    lastName: '',
    tussenvoegsel: '',
    password: '',
    matchingPassword: '',
    telefoon: '',
    companyName: '',
    kvkNumber: '',

    'remember-me': true,

    showValidPassword: false,

    openOptions: false,

    touched : {}
}

class LoginSignup extends Component {

    constructor(props) {
        super(props);

        this.state = initialState
    }

    handleChange = (e, { name, value }) => {

        if( name === "username" ) {
            value= value.toLowerCase();
        }

        this.setState({ [name]: value })
    }

    showValidPassword = () => {
        this.setState({showValidPassword: true})
    }

    checkEmailExists = (e) => {
        console.log(e.target.name + ' ' + e.target.value)
        this.props.dispatch(checkEmail(e.target.value))
    }

    handleSubmit = () => {
        const { username, password } = this.state

        this.props.dispatch(login(username, password, () => {
            this.props.dispatch(validateLogin())

            //ReactGA.event({category: 'User', action: 'Login'});

            if( this.props.onLoginSuccess ) {
                this.props.onLoginSuccess()
            }

        }))
    }

    handleSubmitRegister = () => {

        let value= {
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            tussenvoegsel: this.state.tussenvoegsel ? this.state.tussenvoegsel : '',
            email: this.state.username,
            password: this.state.password,
            matchingPassword: this.state.matchingPassword,
            telefoon: this.state.telefoon,
            submit: 'Submit',
            lang: this.props.currentLang,
            kvkNumber: this.state.kvkNumber,
            companyName: this.state.companyName
        }

        let submit = true;

        if( !value.email ) {
            this.setTouched('email');
            submit= false;
        }

        if( !value.companyName ) {
            this.setTouched('companyName');
            submit= false;
        }

        if( !value.password ) {
            this.setTouched('password');
            submit= false;
        }

        if( !value.matchingPassword ) {
            this.setTouched('matchingPassword');
            submit= false;
        }

        if( submit ) {
            this.props.dispatch(signup(value))
        }

    }

    handleForgot = () => {
        this.setState({loading: true, forgotPasswordError: false, forgotPasswordDone: false});

        fetchFormData( '/api/user/resetPassword', {email: this.state.username, new: 'true'} )
            .then(response => {})
            .then(() => {
                this.setState({forgotPasswordDone: true, loading: false});
            })
            .catch(error => {
                this.setState({forgotPasswordError: true, loading: false});
            })
    }

    renderLogin() {

        let loading= this.props.loginBusy;

        return <Form style={{textAlign: 'left'}}>
            <Form.Field>
                <Form.Input autoFocus autoComplete="username" label={getText(this.props, "login_mail_address")} name='username' type='text' placeholder={getText(this.props, "login_mail_address")} value={this.state.username} onChange={this.handleChange} disabled={loading} />
            </Form.Field>
            <Form.Field>
                <Form.Input autoComplete="current-password" label={getText(this.props, "login_password")} name='password' type='password' minLength="6" placeholder={getText(this.props, "login_password")} value={this.state.password} onChange={this.handleChange} disabled={loading}/>
            </Form.Field>
            <Form.Field>
                <Form.Checkbox label={getText(this.props, "login_remember")} name='remember-me' checked={this.state['remember-me']} onChange={this.handleChange} disabled={loading} />
            </Form.Field>

            {this.props.loginError && <Message
                negative>
                <Message.Header><Translate value="login_failed"/></Message.Header>
                <p><Translate value="login_invalid_username_or_password"/></p>
            </Message>}

            <div style={{display: 'flex'}}>
                <button className="link-button" type="button" style={{fontWeight: 500}} onClick={(e) => {
                    this.props.dispatch(openLoginSignup(true, 2))
                    this.setState({forgotPasswordDone: false})
                    e.stopPropagation();
                    e.nativeEvent.stopImmediatePropagation();
                }
                }><Translate value="login_forgot_password"/></button>

                <div style={{flex: 1, textAlign: 'right'}}>
                    <Button style={{backgroundColor: '#D12A2F', color: 'white', boxShadow: '0px 1.74348px 1.74348px rgba(0, 0, 0, 0.25)'}}
                            loading={loading} disabled={loading} onClick={this.handleSubmit} ><Translate value="login_login"/></Button>
                </div>

            </div>
        </Form>
    }

    searchCompany = (name) => {
        console.log('searchCompany: ' + name)
        this.props.dispatch(zoekKvkHandelsnaam(name, () => {
            this.setState({openOptions: true})
        }))
    }

    deb= _.debounce(this.searchCompany, 300);

    getCompanyOptions = () => {
        return this.props.kvkSearchResult.map((item) => {
            return {key: item.kvkNummer+'-'+item.type, value: item, text: item.handelsnaam, description: item.kvkNummer}
        })
    }

    getLabel( key, showMandatory = true ) {
        let res = '';
        let mandatory = false;

        switch (key) {
            case 'firstName':
                res = getText(this.props, "user_firstname");
                break;
            case 'lastName':
                res = getText(this.props, "user_surname");
                break;
            case 'tussenvoegsel':
                res = getText(this.props, "user_tussenvoegsel");
                break;
            case 'password':
                mandatory = true;
                res = getText(this.props, "login_password");
                break;
            case 'matchingPassword':
                mandatory = true;
                res = getText(this.props, "login_password_validation");
                break;
            case 'telefoon':
                res = getText(this.props, "user_phone");
                break;
            case 'kvkNumber':
                res = getText(this.props, "user_kvk_code");
                break;
            case 'companyName':
                mandatory = true;
                res = getText(this.props, "user_company_name");
                break;
            case 'username':
                mandatory = true;
                res = getText(this.props, "login_mail_address");
                break;
            default:
                res = '';
        }

        if( showMandatory ) {
            return <label>
                {res}
                {(mandatory) && <span style={{color: 'red', marginLeft: 4}}>*</span>}
            </label>
        } else {
            return res;
        }
    }

    setTouched(field) {
        console.log('setTouched: ' + field)
        this.setState({['touched'+field]: true})
    }

    renderSignupInput(field, onBlur, props = {}) {
        return <Form.Input {...props} label={this.getLabel(field)} name={field}
                           error={this.state['touched'+field] && !this.state[field]}
                           placeholder={this.getLabel(field, false)} value={this.state[field]}
                           onChange={this.handleChange} disabled={this.state.loading} onBlur={onBlur}/>;
    }

    render() {


        const searchingKvkHandelsnaam = this.props.kvkSearching;

        const panes = [
            { menuItem: getText(this.props, "login_login"), render: () => <Tab.Pane>
                    {this.renderLogin()}
                </Tab.Pane> },
            { menuItem: getText(this.props, "login_register"), render: () => <Tab.Pane>
                    <Form autocomplete="off">
                        <Form.Field>
                            {this.renderSignupInput('firstName', null, {autoFocus: true})}
                        </Form.Field>
                        <Form.Field>
                            {this.renderSignupInput('tussenvoegsel')}
                        </Form.Field>
                        <Form.Field>
                            {this.renderSignupInput('lastName')}
                        </Form.Field>
                        <Form.Field>
                            <Form.Input label={this.getLabel('companyName')} name='companyName'
                                        placeholder={this.getLabel('companyName', false)}
                                        value={this.state.companyName}
                                        error={this.state.touchedcompanyName && !this.state.companyName}
                                        loading={searchingKvkHandelsnaam}
                                        onBlur={() => setTimeout(() => {
                                            this.setState({openOptions: false})
                                            this.setTouched('companyName');
                                        }, 100)}
                                        onChange={(event, data) => {
                                            this.handleChange(event, data);
                                            this.deb(data.value);
                                        }} disabled={this.state.loading}/>

                            <Form.Dropdown open={this.state.openOptions} openOnFocus={false} trigger={''} text={<></>}
                                           options={this.getCompanyOptions()} icon={null} fluid
                                           onChange={(event, data) => {
                                               if (data.value) {
                                                   this.handleChange(null, {
                                                       name: 'companyName',
                                                       value: data.value.handelsnaam
                                                   });
                                                   this.handleChange(null, {
                                                       name: 'kvkNumber',
                                                       value: data.value.kvkNummer
                                                   });
                                                   //this.setState({openOptions: false});
                                               }
                                           }}/>
                        </Form.Field>
                        <Form.Field>
                            {this.renderSignupInput('kvkNumber', () => {
                                this.props.dispatch(zoekKvkNummer(this.state.kvkNumber))
                            })}
                        </Form.Field>

                        <Form.Field>
                            {this.renderSignupInput('username', (e) => {
                                this.checkEmailExists(e);
                                this.setTouched('username');
                            })}
                            {this.props.userExists ? <Message negative>
                                <Message.Header><Translate value="login_already_exists"/></Message.Header>
                                <p>
                                    <button className="link-button"
                                            onClick={() => this.props.dispatch(openLoginSignup(true, 0))}><Translate
                                        value="login_login"/></button>
                                    <br/>
                                    <button className="link-button"
                                            onClick={() => this.props.dispatch(openLoginSignup(true, 2))}><Translate
                                        value="login_forgot_password"/></button>
                                </p>
                            </Message> : ''}

                            {this.props.invalidEmail ? <Message negative>
                                <Message.Header><Translate value="login_invalid_email"/></Message.Header>
                            </Message> : null }
                        </Form.Field>
                        <Form.Field>
                            {this.renderSignupInput('telefoon')}
                        </Form.Field>
                        <Form.Field>
                            <Form.Input autoComplete="new-password" label={this.getLabel('password')} name='password'
                                        type='password' placeholder={getText(this.props, "login_password_placeholder")}
                                        error={this.state.touchedpassword && !this.state.password}
                                        value={this.state.password} onChange={this.handleChange}
                                        disabled={this.state.loading} onBlur={() => {
                                            this.showValidPassword();
                                            this.setTouched('password');
                            }}/>
                        </Form.Field>
                        <Form.Field>
                            <Form.Input autoComplete="new-password" label={this.getLabel('matchingPassword')}
                                        name='matchingPassword' type='password'
                                        placeholder={getText(this.props, "login_password_placeholder_validation")}
                                        error={this.state.touchedmatchingPassword && !this.state.matchingPassword}
                                        value={this.state.matchingPassword} onChange={this.handleChange}
                                        disabled={this.state.loading} onFocus={this.handleFocus} onBlur={() => {
                                            this.setTouched('matchingPassword')
                            }}/>

                            {/*{errorMatchingPassword && <p style={{color: '#9f3a38', marginTop: -8, fontSize: '.8em'}}>{getText(this.props, "login_password_not_matching")}</p>}*/}
                        </Form.Field>

                        {(this.state.showValidPassword && this.state.password && !(5 < this.state.password.length && this.state.password.length < 30)) ?
                            <Message negative>
                                <Message.Header><Translate value="login_password"/></Message.Header>
                                <p><Translate value="login_error_password_requirements"/></p>
                            </Message> : ''}

                        {this.props.signupOk && <Message
                            positive
                            icon='inbox'
                            header={<Message.Header><Translate value="login_register_success"/></Message.Header>}
                            content={<Translate value="login_register_success_details"/>}
                        />}

                        {this.props.signupError && <Message
                            negative
                            icon='exclamation circle'
                            header={<Message.Header><Translate value="login_error"/></Message.Header>}
                            content={<Translate value="login_failed"/>}
                        />}

                        <div style={{textAlign: 'right'}}>
                            <Button style={{
                                backgroundColor: '#D12A2F',
                                color: 'white',
                                boxShadow: '0px 1.74348px 1.74348px rgba(0, 0, 0, 0.25)'
                            }} loading={this.props.signupBusy} disabled={this.props.signupBusy || this.props.signupOk}
                                    onClick={this.handleSubmitRegister}>{getText(this.props, 'login_register')}</Button>
                        </div>
                    </Form>
                </Tab.Pane> },
            {
                menuItem: null, render: () => <Tab.Pane>
                    <Form>

                        <Translate value="login_forgot_password_description"/>

                        <Form.Field>
                            <Form.Input autoFocus autoComplete="username" label={getText( this.props, "login_mail_address")} name='username' type='username' placeholder={getText( this.props, "login_mail_address")} value={this.state.username} onChange={this.handleChange} disabled={this.state.loading} />
                        </Form.Field>

                        {this.state.forgotPasswordDone && <Message
                            positive
                            icon='inbox'
                            header={<Translate value="login_thankyou"/>}
                            content={<Translate value="login_mailsent"/>}
                        />}

                        {this.state.forgotPasswordError && <Message
                            negative
                            icon='exclamation circle'
                            header={<Translate value="login_error"/>}
                            content={<Translate value="login_failed"/>}
                        />}

                        <div style={{textAlign: 'right'}}>
                            <Button loading={this.state.loading} disabled={this.state.loading || this.state.forgotPasswordDone} onClick={this.handleForgot}><Translate value="submit"/></Button>
                        </div>
                    </Form>
                </Tab.Pane>
            }
        ]

        if( this.props.inline ) {
            return <>
                <Tab panes={panes} activeIndex={this.props.loginActiveIndex} onTabChange={(e, {activeIndex}) => this.props.dispatch(openLoginSignup(true, activeIndex))}/>
            </>
        }

        return <div>

            <Modal size='tiny' closeIcon open={this.props.loginOpen} onClose={() => this.props.dispatch(openLoginSignup(false))}>
                <Modal.Content>

                    <Tab panes={panes} activeIndex={this.props.loginActiveIndex} onTabChange={(e, {activeIndex}) => this.props.dispatch(openLoginSignup(true, activeIndex))}/>

                </Modal.Content>
            </Modal>
        </div>
    }
}

LoginSignup.propTypes = {
    inline: PropTypes.bool,
    onLoginSuccess: PropTypes.func,
};

LoginSignup.defaultProps = {
    inline: false,
    onLoginSuccess: null,
};

const mapStateToProps = (state) => {
    return {
        lang: state.main.lang,
        currentLang: state.main.currentLang,
        loginOpen: state.main.loginOpen,
        loginActiveIndex: state.main.loginActiveIndex,
        loginError: state.main.loginError,
        loginBusy: state.main.loginBusy,
        signupBusy: state.main.signupBusy,
        signupOk: state.main.signupOk,
        signupError: state.main.signupError,
        userExists: state.main.userExists,
        invalidEmail: state.main.invalidEmail,
        kvkSearchResult: state.main.kvkSearchResult,
        kvkSearching: state.main.kvkSearching,
    }
}

export default connect(mapStateToProps)(LoginSignup)