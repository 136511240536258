import React from 'react'
import {Button, Icon, Loader, Popup, Table} from "semantic-ui-react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {
    getSelectedSupplierPartHistory,
    reSelectedSupplierPart, setKlantLevertAan,
    setSelectedBuddyPart,
    setSelectedSupplierPart, showBomLineHistory
} from "../actions";
import BomSelectedPartView from "./BomSelectedPartView";
import {isEmployee} from "../actions/functions";

class BomSelectedHistory extends React.Component {

    state = {}

    handleOpen = () => {
        this.setState({loading: true})
        this.props.dispatch(getSelectedSupplierPartHistory(this.props.r.id, () => {
            this.setState({loading: false})
        }))
    }

    handleReselect = (id) => {
        this.props.dispatch(reSelectedSupplierPart(this.props.r.id, id, () => {
            this.props.dispatch(getSelectedSupplierPartHistory(this.props.r.id, () => {
                this.setState({loading: false})
            }))
        }))
    }

    handleSelectSupplierPart = (p) => {
        this.props.dispatch(setSelectedSupplierPart(this.props.r.id, p.id))
    }
    handleSelectBuddyPart = (p) => {
        this.props.dispatch(setSelectedBuddyPart(this.props.r.id, p.id))
    }
    handleSelectKlantLevertAan = (p) => {
        this.props.dispatch(setKlantLevertAan(this.props.print.id, this.props.r.id, p.manualselection))
    }

    renderContent = () => {

        const r = this.props.r;

        let sp = (r && r.selectedSupplierPartHistoryList) ? r.selectedSupplierPartHistoryList : [];
        let bp = (r && r.selectedBuddyPartHistoryList) ? r.selectedBuddyPartHistoryList : [];
        let kla = (r && r.klantLevertAanHistoryList) ? r.klantLevertAanHistoryList : [];

        let sp_p = (r && r.selectedSupplierPartPreviousBomList) ? r.selectedSupplierPartPreviousBomList : [];
        let bp_p = (r && r.selectedBuddyPartPreviousBomList) ? r.selectedBuddyPartPreviousBomList : [];
        let kla_p = (r && r.klantLevertAanPreviousBomList) ? r.klantLevertAanPreviousBomList : [];

        return <div>

                <Table compact='very' size='small' style={{width: '100%', lineHeight: '16px'}}>
                    <Table.Row>
                        <Table.HeaderCell>
                            Eerder geselecteerde parts {r.fetchingHistory ? <Loader active inline size='tiny'/> : ''}
                        </Table.HeaderCell>
                        <Table.HeaderCell>
                        </Table.HeaderCell>
                    </Table.Row>
                    {sp.map(p => <Table.Row>
                        <Table.Cell>
                            <BomSelectedPartView part={p}/>
                        </Table.Cell>
                        <Table.Cell>
                            <Button onClick={() => this.handleReselect(p.id)}>selecteren</Button>
                        </Table.Cell>
                    </Table.Row>)}
                    {bp.map(p => <Table.Row>
                        <Table.Cell>
                            <BomSelectedPartView part={p} isBuddyPart={true}/>
                        </Table.Cell>
                        <Table.Cell>
                            <Button onClick={() => this.handleReselect(p.id)}>selecteren</Button>
                        </Table.Cell>
                    </Table.Row>)}
                    {kla.map(p => <Table.Row>
                        <Table.Cell>
                            <BomSelectedPartView part={p} isKlaView={true}/>
                        </Table.Cell>
                        <Table.Cell>
                            <Button onClick={() => this.handleReselect(p.id)}>selecteren</Button>
                        </Table.Cell>
                    </Table.Row>)}

                    {sp_p.map(p => <Table.Row warning>
                        <Table.Cell>
                            <BomSelectedPartView part={p}/>
                        </Table.Cell>
                        <Table.Cell>
                            <Popup trigger={
                                <Button onClick={() => this.handleSelectSupplierPart(p)}>selecteren</Button>
                            } content={'Dit part was geselecteerd in een eerder ingeladen BOM'}/>
                        </Table.Cell>
                    </Table.Row>)}
                    {bp_p.map(p => <Table.Row warning>
                        <Table.Cell>
                            <BomSelectedPartView part={p} isBuddyPart={true}/>
                        </Table.Cell>
                        <Table.Cell>
                            <Popup trigger={
                                <Button onClick={() => this.handleSelectBuddyPart(p)}>selecteren</Button>
                            } content={'Dit part was geselecteerd in een eerder ingeladen BOM'}/>
                        </Table.Cell>
                    </Table.Row>)}
                    {kla_p.map(p => <Table.Row warning>
                        <Table.Cell>
                            <BomSelectedPartView part={p} isKlaView={true}/>
                        </Table.Cell>
                        <Table.Cell>
                            <Popup trigger={
                                <Button onClick={() => this.handleSelectKlantLevertAan(p)}>selecteren</Button>
                            } content={'Dit part was geselecteerd in een eerder ingeladen BOM'}/>
                        </Table.Cell>
                    </Table.Row>)}
                </Table>
        </div>
    }

    render() {
        const r = this.props.r;

        if( !(r && (
            r.selectedSupplierPartHistory || r.selectedBuddyPartHistory || r.klantLevertAanHistory ||
            r.hasHistory
        )) ) {
            return <></>
        }

        if( false ) {
            return <>
                <Icon link style={{cursor: 'pointer', marginLeft: 4}} name='history' color='purple'
                      loading={r.fetchingHistory}
                      onMouseDown={() => {
                          if (r.showHistory) {
                              this.props.dispatch(showBomLineHistory(r.id, false))
                          } else {
                              this.props.dispatch(getSelectedSupplierPartHistory(this.props.r.id))
                          }
                      }}
                />
            </>
        }

        return <>
            <Popup trigger={
                  this.props.children ? this.props.children :
                      <Icon link style={{cursor: 'pointer', marginLeft: 4}} name='history' color='purple'/>
            } content={this.renderContent} position='top right' hoverable={true}
              onOpen={this.handleOpen} onClose={() => {
                this.props.dispatch(showBomLineHistory(r.id, false))
              }
            }
            />
        </>
    }
}

BomSelectedHistory.propTypes = {
    r: PropTypes.object.isRequired,
    printId: PropTypes.string,
    isKlaView: PropTypes.bool,
};

BomSelectedHistory.defaultProps = {
    r: null,
    printId: '',
    isKlaView: false,
};

const mapStateToProps = (state) => {
    return {
        currentUser: state.main.currentUser,
        print: state.bom.print,
    }
}

export default connect(mapStateToProps)(BomSelectedHistory)