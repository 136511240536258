import React from 'react'
import {Icon, Menu} from "semantic-ui-react";
import PropTypes from "prop-types";
import {openSideBar} from "../actions";
import connect from "react-redux/es/connect/connect";
import {isEmployee} from "../actions/functions";
import sideBar from "../bom/SideBar";

class BasicProjectMenu extends React.Component {

    state = {
        activeName: ''
    }

    handleItemClick = (e, { name }) => {
        this.setState({ activeItem: name })

        if( this.props.onTabChange ) {
            this.props.onTabChange(name)
        }
    }

    render() {
        const {activeName, sideBar} = this.props;
        const admin = isEmployee(this.props.currentUser)

        return <Menu pointing style={{margin: '0 0 0 0'}}>

            {(admin && sideBar) ? <Menu.Item key={'cog'} inverted onClick={() => this.props.dispatch(openSideBar(true))}>
                <Icon name='cog' />
            </Menu.Item> : ''}

            {this.props.menu.map(i =>
                <Menu.Item active={activeName === i.name} name={i.name}
                           onClick={this.handleItemClick}>
                    {i.item}
                </Menu.Item>
            )}

            <Menu.Menu position='right'>
                {this.props.menuRight.map(i =>
                    <Menu.Item active={activeName === i.name} name={i.name}
                               onClick={this.handleItemClick}>
                        {i.item}
                    </Menu.Item>
                )}
            </Menu.Menu>
        </Menu>;
    }
}

BasicProjectMenu.protoTypes = {
    menu: PropTypes.array,
    menuRight: PropTypes.array,
    activeName: PropTypes.any,
    onTabChange: PropTypes.func,
    sideBar: PropTypes.bool
}

BasicProjectMenu.defaultProps = {
    menu: [],
    menuRight: [],
    activeName: '',
    onTabChange: null,
    sideBar: false
}

const mapStateToProps = (state) => ({
    currentUser: state.main.currentUser
})

export default connect(mapStateToProps)(BasicProjectMenu)