import React from 'react'
import {connect} from "react-redux";
import {deleteBomOrders, getAllBomOrders, saveBomOrder, saveProjectBestelling} from "../actions";
import Translate from "../Translate";
import {Button, Dropdown, Input, Loader, Popup, Tab, Table} from "semantic-ui-react";
import {getSupplierDeliveryTime, renderSupplier} from "./functions";
import {formatDateTime} from "../actions/functions";
import {Link} from "react-router-dom";
import BomOverviewCheckin2 from "./BomOverviewCheckin2";

var moment = require('moment');
moment.locale('NL');

class BomOverviewCheckin extends React.Component {

    state = {
        filter: 0
    }

    componentDidMount() {
        this.props.dispatch(getAllBomOrders())
    }

    receivedOrder(o) {

        let value= Object.assign({}, o, {
            status: "RECEIVED"
        })

        if( o.print ) {
            this.props.dispatch(saveBomOrder(value))
        }
        else {
            this.props.dispatch(saveProjectBestelling(value))
        }
    }

    renderActions = (o) => {

        //let allCheckedId = o.items.filter(li => !li.boardLocation).length === 0

        switch (o.status) {
            case "TO_BE_ORDERED":
                return <div>Bestelling is nog niet geplaatst</div>
            case "ORDERED":
                return <Button size='large' primary content='Verwerk' icon='box' labelPosition='right' onClick={() => this.receivedOrder(o)}/>
            case "RECEIVED":

                return <div>Received</div>

                /*if( o.supplier.endsWith('_PCB') || o.supplier.endsWith('_STENCIL') ) {

                    let orderCount = this.getOrdersCount(o.print);
                    if (orderCount === 0) {
                        return <div>
                            Waiting for orders
                        </div>
                    }

                    let count = this.getWaitingForOrdersCount(o.print);
                    if (count) {
                        return <div>
                            Waiting for {count} more orders
                        </div>
                    }

                    if( o.supplier.endsWith('_PCB') && !this.hasStencil(o.print)) {
                        return "Waiting for Stencil"
                    }

                    if( o.supplier.endsWith('_STENCIL') && !this.hasPcb(o.print)) {
                        return "Waiting for PCB"
                    }

                    return <div>
                        You should assemble this project
                    </div>

                } else {

                    if (allCheckedId) {
                        return <div>All parts are checked-in</div>
                    }
                    let count = this.getWaitingForOrdersCount(o.print);
                    if (count) {
                        return <div>
                            Waiting for {count} more orders
                        </div>
                    }
                    return <div>
                        You should check-in
                    </div>
                }*/
            default:
                return <div></div>
        }
    }

    getOrdersCount(print) {
        return this.props.bomOrderList.filter(o => o.print.id === print.id).filter(o => {
            return !o.supplier.endsWith('_PCB') && !o.supplier.endsWith('_STENCIL');
        }).length
    }

    getWaitingForOrdersCount(print) {
        return this.props.bomOrderList.filter(o => o.print.id === print.id).filter(o => {
            return (o.status !== 'RECEIVED' && o.status !== 'PROCESSED')
        }).filter(o => {
            return !o.supplier.endsWith('_PCB') && !o.supplier.endsWith('_STENCIL');
        }).length
    }

    hasPcb(print) {
        return this.props.bomOrderList.find(o => {
            return o.print.id === print.id && o.supplier.endsWith('_PCB') && o.status === 'RECEIVED'
        })
    }

    hasStencil(print) {
        return this.props.bomOrderList.find(o => {
            return o.print.id === print.id && o.supplier.endsWith('_STENCIL') && o.status === 'RECEIVED'
        })
    }

    getClassName(o) {
        if( o.late ) {
            return 'busy';
        } else if( o.today ) {
            return ''
        }
        return 'statusBarTab-gray'
    }

    getClassNameEx(o) {

        if( o.supplier.endsWith('_PCB') || o.supplier.endsWith('_STENCIL') ) {

            let orderCount = this.getOrdersCount(o.print);
            if (orderCount === 0) {
                return ''
            }

            let count = this.getWaitingForOrdersCount(o.print);
            if (count) {
                return ''
            }

            if( o.supplier.endsWith('_PCB') && !this.hasStencil(o.print)) {
                return ''
            }

            if( o.supplier.endsWith('_STENCIL') && !this.hasPcb(o.print)) {
                return ''
            }

            if( o.status === 'RECEIVED' ) {
                return 'done';
            }

            return '';

        } else {

            let allCheckedId = o.items.filter(li => !li.boardLocation).length === 0;

            if (allCheckedId) {
                return '';
            }

            if (this.getWaitingForOrdersCount(o.print) === 0 && this.getOrdersCount(o.print)) {
                return 'selected';
            }
        }
    }

    renderExpected(o) {

        if( !o.orderPlaced || !o.expected ) {
            return <></>
        }

        return <Popup size='large' trigger={<div style={{width: 140}}>
            <div>{moment(o.expected).startOf( moment() ).fromNow()}</div>
        </div>} content={formatDateTime(o.expected)} />
    }

    renderPackages(list) {
        return (
            <div>
                <h1><Translate value='menu_admin_checkin'/></h1>

                <Dropdown options={[
                    {key: 0, text: 'Alle', value: 0},
                    {key: 1, text: 'Late', value: 1},
                    {key: 2, text: 'Vandaag', value: 2},
                ]} onChange={(e,d) => this.setState({filter: d.value})}
                          value={this.state.filter}
                />

                {this.props.bomOrderListBusy && <Loader active />}

                {list && <Table size='large'>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>Project</Table.HeaderCell>
                            <Table.HeaderCell>Supplier</Table.HeaderCell>
                            <Table.HeaderCell>Order code</Table.HeaderCell>
                            <Table.HeaderCell>Order date</Table.HeaderCell>
                            <Table.HeaderCell>Expected</Table.HeaderCell>
                            {/*<Table.HeaderCell collapsing></Table.HeaderCell>*/}
                        </Table.Row>
                    </Table.Header>

                    <Table.Body>

                        {list.filter(o => {
                            if( this.state.filter === 1 ) {
                                return o.late;
                            } else if( this.state.filter === 2 ) {
                                return o.today;
                            }
                            return true;
                        }).map(o => (
                            <Table.Row key={o.id} className={'bst-prod-row ' + this.getClassName(o)}>
                                <Table.Cell>
                                    <div>{o.print.projectOrder ? <Link  to={'/project/'+o.print.projectOrder.id+'/analysis'}>
                                        {o.print.projectOrder.internalName ? o.print.projectOrder.internalName : o.print.projectOrder.name}
                                    </Link> : o.print.name}</div>

                                    <div style={{opacity: .5}} >{(o.print && o.print.created) ? formatDateTime(o.print.created) : ''}</div>

                                </Table.Cell>
                                <Table.Cell>{renderSupplier({supplier: o.supplier})}</Table.Cell>
                                <Table.Cell>
                                    {o.supplierOrderCode ? o.supplierOrderCode : o.supplierOrderNumber}
                                </Table.Cell>
                                <Table.Cell>

                                    <Popup size='large' trigger={<div style={{width: 140}}>
                                        <div>{formatDateTime(o.orderPlaced)}</div>
                                    </div>} content={o.orderPlaced ? (moment(o.orderPlaced).startOf( moment() ).fromNow()) : ''} />

                                </Table.Cell>
                                <Table.Cell>

                                    {o.today ? 'Vandaag' : this.renderExpected(o)}

                                </Table.Cell>

                                {/*<Table.Cell collapsing>
                                    {this.renderActions(o)}
                                </Table.Cell>*/}
                            </Table.Row>
                        ))}

                    </Table.Body>
                </Table>}

            </div>
        );
    }

    render() {

        let list = this.props.bomOrderList;

        list = list.map(o => {
            if( o.supplier.endsWith('_COMP') ) {
                return {...o, supplier: o.supplier.replace('_COMP', '')}
            }
            return o;
        })

        list = list.map(o => {
            if( o.orderPlaced ) {
                let expected = getSupplierDeliveryTime(o.supplier, o.orderPlaced, o.deliverytime)
                let late = expected.isBefore(moment());
                let today = expected.isSame(moment(), 'day');
                return {...o, expected, late, today }
            }
            return o;
        })

        /*list.sort((a,b) => {
            return a.orderPlaced-b.orderPlaced
        })*/

        return <main id={'checkin'}/* className={'pageContainer'}*/ style={{textAlign: 'left'}}>
            <Tab menu={{ attached: false }} panes={[
                {
                    menuItem: 'Tab 1',
                    render: () => <Tab.Pane attached={false}>{this.renderPackages(list)}</Tab.Pane>,
                },
                {
                    menuItem: 'Tab 2',
                    render: () => <Tab.Pane attached={false}>
                        <BomOverviewCheckin2/>
                    </Tab.Pane>,
                },
            ]} />
        </main>


    }

}

const mapStateToProps = (state) => {
    return {
        bomOrderList: state.bom.bomOrderList,
        bomOrderListBusy: state.bom.bomOrderListBusy,
    }
}

export default connect(mapStateToProps)(BomOverviewCheckin)