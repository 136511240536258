import React, {Component} from "react";
import {connect} from "react-redux";
import {changeKnownDielectric, getKnownDielectric, saveKnownDielectric} from "../../actions";
import {Button, Input, Table} from "semantic-ui-react";

class KnownDielectric extends Component {

    state = {}

    componentDidMount() {
        this.props.dispatch(getKnownDielectric())
    }

    render() {
        const d = this.props.knownDielectric
        return (
            <div>
                <Table compact='very' size='small' style={{width: '100%', lineHeight: '16px'}}>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>Dielectric</Table.HeaderCell>
                            <Table.HeaderCell>Ook bekend als</Table.HeaderCell>
                            <Table.HeaderCell collapsing></Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {d.map(d => <Table.Row key={d.id}>
                            <Table.Cell>
                                <Input value={d.value} fluid onChange={e => {
                                    this.props.dispatch(changeKnownDielectric(d.id, 'value', e.target.value))
                                }}/>
                            </Table.Cell>
                            <Table.Cell>
                                <Input value={d.alsoKnownAs} fluid onChange={e => {
                                    this.props.dispatch(changeKnownDielectric(d.id, 'alsoKnownAs', e.target.value))
                                }} placeholder={'Meerdere waarden gescheiden door een ;'}/>
                            </Table.Cell>
                            <Table.Cell style={{textAlign: 'right'}}>
                                <Button onClick={() => {
                                    this.props.dispatch(saveKnownDielectric(d))
                                }} primary={d.dirty}>
                                    Save
                                </Button>
                            </Table.Cell>
                        </Table.Row>)}

                        <Table.Row key={-1}>
                            <Table.Cell>
                                <Input placeholder={'Dielectric'} value={this.state.newValue} fluid onChange={e => this.setState({newValue: e.target.value})}/>
                            </Table.Cell>
                            <Table.Cell></Table.Cell>
                            <Table.Cell style={{textAlign: 'right'}}>
                                <Button onClick={() => {
                                    this.props.dispatch(saveKnownDielectric(this.state.newValue, () => {
                                        this.setState({newValue: ''})
                                    }))
                                }}>
                                    Add new
                                </Button>
                            </Table.Cell>
                        </Table.Row>
                    </Table.Body>
                </Table>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        knownDielectric: state.bom.knownDielectric
    }
}

export default connect(mapStateToProps)(KnownDielectric)
