import React from 'react';
import connect from "react-redux/es/connect/connect";
import {
    Button,
    Form,
    Table,
    Checkbox,
    Popup,
    Dropdown, Loader, Message, List, Icon, Modal, Tab
} from "semantic-ui-react";
import PropTypes from "prop-types";
import {
    getChatMentionable,
    getChatOverview, getChatOverviewByPage, getChatRefInfo,
    postChatMessage,
    setChatHandled,
    unhandledCount
} from "../actions";
import ChatUserView from "./ChatUserView";
import {formatDateTime, isEmployee} from "../actions/functions";
import {Link} from "react-router-dom";
import ChatInput from "./ChatInput";
import OnVisible from "react-on-visible";
import DeltaTable from "../deltatable/DeltaTable";
import DeltaTableMenu from "../deltatable/DeltaTableMenu";
import Translate from "../Translate";
import ChatNewMessageModal from "./ChatNewMessageModal";
import ChatSocket from "./ChatSocket";
import PdfViewer from "../tools/PdfViewer";

class ChatAttachment extends React.Component {

    state = {
        attachment: null
    };

    //Show a modal with the image, and a download button
    renderImage(attachment) {
        return <Modal open={true} closeIcon onClose={() => this.setState({attachment: null})}>
            <Modal.Header>{attachment.name}</Modal.Header>
            <Modal.Content>
                <div style={{marginBottom: 16}}>
                    {this.props.chatline.text}
                </div>
                {this.renderAttachmentTabs(this.props.chatline.attachments, attachment)}
                <div style={{padding: 8}}>
                    <img style={{maxWidth: '100%', border: '1px solid grey'}}
                         src={'/api/project/getAttachment?id=' + attachment.id}
                         alt={attachment.name + ' ' + attachment.contentType}/>
                </div>
                <Button icon labelPosition='left' as='a' primary download
                        href={'/api/project/getAttachment?id=' + attachment.id}>
                    <Icon name='download'/> Download
                </Button>
            </Modal.Content>
            <Modal.Actions>
                <Button onClick={() => this.setState({attachment: null})}>Close</Button>
                <Button as='a' primary download href={'/api/project/getAttachment?id=' + attachment.id}>
                    <Icon name='download'/> Download
                </Button>
            </Modal.Actions>

        </Modal>
    }

    renderPdf(attachment) {
        return <Modal open={true} closeIcon onClose={() => this.setState({attachment: null})}>
            <Modal.Header>{attachment.name}</Modal.Header>
            <Modal.Content>
                <div style={{marginBottom: 16}}>
                    {this.props.chatline.text}
                </div>
                {this.renderAttachmentTabs(this.props.chatline.attachments, attachment)}
                <div style={{padding: 8}}>
                    <PdfViewer url={'/api/project/getAttachment?id=' + attachment.id}/>
                </div>
                <Button icon labelPosition='left' as='a' primary download href={'/api/project/getAttachment?id=' + attachment.id}>
                    <Icon name='download'/> Download
                </Button>
            </Modal.Content>
            <Modal.Actions>
                <Button onClick={() => this.setState({attachment: null})}>Close</Button>
            </Modal.Actions>

        </Modal>
    }

    renderFile(attachment) {
        return <div key={attachment.id}>
            <Icon name='file outline'/>
            <span>
                <a download href={'/api/project/getAttachment?id=' + attachment.id}>{attachment.name}</a>
            </span>
        </div>;
    }

    renderAttachmentTabs(attachments, attachment) {
        return <div className={'wrappingtab'}>
            <Tab
                menu={{ attached: false, tabular: false }}
                panes={attachments.map(a => ({menuItem: a.name}))}
                activeIndex={attachments.findIndex(a => a.id === attachment.id)}
                onTabChange={(e, {activeIndex}) => {
                    let attachment = attachments[activeIndex];
                    this.setState({attachment})
                }}
            />
        </div>
    }

    handleTabChange = (e, {activeIndex}) => this.setState({activeIndex})

    getType(attachment) {
        switch (attachment.contentType) {
            case 'image/png':
            case 'image/jpeg':
            case 'image/gif':
                return 'image';
            case 'application/pdf':
                return 'pdf';
            default:
                return null;
        }
    }

    render() {
        let {attachments} = this.props;

        return (attachments && attachments.length) ? <div style={{marginLeft: 4, display: 'flex', gap: 8}}>
            {attachments.map(a => {
                const t = this.getType(a);
                if (t) {
                    return <div style={{whiteSpace: 'nowrap'}}>
                        <Icon name={`file ${t} outline`}/>
                        <button className='link-button link-button-blue'
                                style={{textDecoration: 'unset'}}
                                onClick={() => this.setState({attachment: a})}
                        >{a.name}</button>
                    </div>
                }
                return <div key={a.id} style={{whiteSpace: 'nowrap'}}>
                    <Icon name='file outline'/>
                    <span>
                        <a download href={'/api/project/getAttachment?id=' + a.id}>{a.name}</a>
                    </span>
                </div>
            })}

            {this.renderModal(this.state.attachment)}
        </div> : null
    }

    renderModal(attachment) {

        if (!attachment) {
            return null
        }

        switch (attachment.contentType) {
            case 'image/png':
            case 'image/jpeg':
            case 'image/gif':
                return this.renderImage(attachment);
            case 'application/pdf':
                return this.renderPdf(attachment);
            default:
                if( this.state.attachment ) {
                    return this.renderImage(attachment)
                }
                return this.renderFile(attachment);
        }


    }
}


ChatAttachment.propTypes = {
    id: PropTypes.number.isRequired,
    attachment: PropTypes.object,
    chatline: PropTypes.object
};

ChatAttachment.defaultProps = {
    id: null,
    attachment: null,
    chatline: null
};

const mapStateToProps = (state) => {
    return {}
}

export default connect(mapStateToProps)(ChatAttachment)