import React, { Component } from 'react';
import './App.css';
import ProjectView from "./oldui/ProjectView";
import connect from "react-redux/es/connect/connect";
import {Button, Dropdown, Form, Icon, Message, Modal} from "semantic-ui-react";
import Translate from "./Translate";
import {openLoginSignup, searchPrintOrCompany} from "./actions";
import PropTypes from "prop-types";
import _ from "lodash";

class ProjectSetInternalNameModal extends Component {

    state = {}

    constructor(props) {
        super();
        this.state = {
            internalName: props.project.internalName,
        }
    }

    searchUserCompany = (value) => {
        this.props.dispatch(searchPrintOrCompany(value, true))
    }

    deb= _.debounce(this.searchUserCompany, 300);

    handleClose = () => {
        if (this.props.onClose) {
            this.props.onClose();
        }
    }



    render() {
        let {project} = this.props;

        const s= this.props.searchPrintOrCompany.list;

        const options = s.map(s => ({
            key: s.id,
            text: s.title,
            value: s.id,
            title: s.title,
        })).sort((a,b) => b.title.localeCompare(a.title))

        return  <Modal dimmer={false} closeIcon={true} open={true} onClose={this.handleClose}>
            <Modal.Header>
                <Translate value='project_internal_name'/>
            </Modal.Header>
            <Modal.Content>
                <Modal.Description>
                    <h4>Customer</h4>
                    <span><b>Request by: </b></span> {project.owner.fullName}<br/>
                    <span><b>E-mail: </b></span> {project.owner.email}<br/>
                    <span><b>Projectname: </b></span> {project.name}<br/>
                    <span><b>kvkNumber: </b></span> {project.owner.kvkNumber}<br/>
                    <span><b>Company: </b></span> {project.owner.companyName}<br/>

                    {this.state.error && <Message negative>
                        <Message.Header><Translate value="wrong"/></Message.Header>
                        <p>{JSON.stringify(this.state.error)}</p>
                    </Message>}
                    <Form style={{marginTop: 16}}>

                        <Dropdown fluid trigger={
                            <Form.Input label={<Translate value='project_internal_name'/>} value={this.state.internalName} onChange={(e) => {
                                this.setState({internalName: e.target.value});
                                this.deb(e.target.value);
                            }}>
                                <input autoFocus={true}/>
                            </Form.Input>
                        } value={null} icon={null} open={s.length > 0} options={options} onChange={(e,d) => {
                            console.log(JSON.stringify(d))
                        }}/>
                    </Form>
                </Modal.Description>
            </Modal.Content>
            <Modal.Actions>
                <Button disabled={this.state.busy} onClick={this.handleClose}>
                    <Icon name='remove' /> <Translate value="cancel"/>
                </Button>
                <Button primary disabled={this.state.busy} loading={this.state.busy} onClick={() => {

                }}>
                    <Icon name='checkmark' /> {this.state.busy ? <Translate value="moment"/> : this.state.saved ? <Translate value="saved"/> : <Translate value="save"/>}
                </Button>
            </Modal.Actions>
        </Modal>
    }
}

ProjectSetInternalNameModal.propTypes = {
    project: PropTypes.object.isRequired,
    onClose: PropTypes.func,
};

ProjectSetInternalNameModal.defaultProps = {
    project: null,
    onClose: null
};

const mapStateToProps = (state) => {
    return {
        currentUser: state.main.currentUser,
        searchPrintOrCompany: state.main.searchPrintOrCompany,
    }
}

export default connect(mapStateToProps)(ProjectSetInternalNameModal)