import React, { Component } from 'react';
import {Link, withRouter} from 'react-router-dom';
import PropTypes from 'prop-types';
import './App.css';
import Translate from "./Translate";
import {hasRole, isAdmin, isEmployee, isTestUser} from "./actions/functions";
import connect from "react-redux/es/connect/connect";
import {
    getDigiKeyClientIdAndSecret,
    logout,
    openBomCamera,
    openEditBom,
    openUploadBom,
    unhandledCount,
    validateLogin
} from "./actions";
import {Button, Icon, Label, Popup, Transition} from "semantic-ui-react";
import BomCamera from "./bom/BomCamera";
import BomMohican from "./bom/BomMohican";
import BomSocket from "./bom/BomSocket";
import DigiKeyAuthentication from "./DigiKeyAuthentication";
import NexarAuthentication from "./NexarAuthentication";

class SecondaryMenuItem extends Component {
    render() {
        if( this.props.linkTo ) {
            return (
                <div className={'secondaryMenuItem body3'} onClick={this.props.onClick}>
                    <Link to={this.props.linkTo} className={'link'}>{this.props.itemText}</Link>
                </div>
            );
        } else {
            return (
                <div className={'secondaryMenuItem body3'}>
                    <button className="link-button link" onClick={this.props.onClick}>{this.props.itemText}</button>
                </div>
            );
        }
    }
}

SecondaryMenuItem.propTypes = {
    itemText: PropTypes.any,
    linkTo: PropTypes.string
};

SecondaryMenuItem.defaultProps = {
    itemText: 'Menu Item',
    linkTo: null
};

class NavigationBarSecondary extends Component {
    constructor(props){
        super(props);
        this.state = {
            active: true,
            unhandledPopup: false
        };
    }
    componentDidMount() {
        this.props.dispatch(unhandledCount())
        setTimeout(() => {
            this.props.dispatch(unhandledCount())
            if( (this.props.unhandledCountCustomer > 0) ) {
                this.setState({unhandledPopup: true})
            }
        }, 5000)

        this.myTimer = setInterval(() => {
            this.props.dispatch(unhandledCount());
        }, 60000);
    }

    componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS) {
        if( prevProps.unhandledCountCustomer != null && prevProps.unhandledCountCustomer < this.props.unhandledCountCustomer ) {
            if( this.props.unhandledCountCustomer > 0 ) {
                this.setState({unhandledPopup: true})
            }
        }

    }

    handleClick = () => {
        this.props.parentCallback(false);
    }
    render() {

        let employee= isEmployee(this.props.currentUser);
        let admin = isAdmin(this.props.currentUser);
        let uPlacer= hasRole(this.props.currentUser, 'ROLE_MICROPLACER');
        let test= isTestUser(this.props.currentUser);

        return(
            <div id={'secondaryNavBar'} className={this.state.active ? this.props.visibleClass: 'navBarHidden'}>
                <div id={'secondaryMenuItemsContainer'}>
                    {/*{employee ? <SecondaryMenuItem itemText={'Database'} linkTo={'/bomsettings'}/> : ''}*/}
                    {/*{employee ? <SecondaryMenuItem itemText={'ProtoFlow'} linkTo={'/protoflow'}/> : ''}*/}
                    <SecondaryMenuItem itemText={<Translate value='menu_projects'/>} linkTo={'/projects'} onClick={this.handleClick}/>
                    {(uPlacer || employee) ? <SecondaryMenuItem itemText={<Translate value='menu_projects_microplacer'/>} linkTo={'/projects-microplacer'} onClick={this.handleClick}/> : ''}
                    {employee ? <SecondaryMenuItem itemText={<Translate value='menu_admin_planning'/>} linkTo={'/planning'} onClick={this.handleClick}/> : ''}
                    <SecondaryMenuItem itemText={<Translate value='menu_my_details'/>} linkTo={'/account/'+this.props.currentUser.id} onClick={this.handleClick}/>
                    {/*{employee ? <SecondaryMenuItem itemText={<Translate value='menu_translate'/>} linkTo={'/translate'} onClick={this.handleClick}/> : ''}*/}
                    {employee ? <SecondaryMenuItem itemText={<Translate value='menu_users_overview'/>} linkTo={'/users'} onClick={this.handleClick}/> : ''}
                    {employee ? <SecondaryMenuItem itemText={<Translate value='menu_company_overview'/>} linkTo={'/companys'} onClick={this.handleClick}/> : ''}
                    {employee ? <SecondaryMenuItem itemText={<Translate value='menu_admin_dashbaord'/>} linkTo={'/dashboard'} onClick={this.handleClick}/> : ''}
                    {/*{employee ? <SecondaryMenuItem itemText={'🦈 Sharketing'} linkTo={'/sharketing'} onClick={this.handleClick}/> : ''}*/}
                    {/*{employee ? <SecondaryMenuItem itemText={<Translate value='menu_admin_bom'/>} linkTo={'/bom'} onClick={this.handleClick}/> : ''}*/}
                    {/*{employee ? <SecondaryMenuItem itemText={<Translate value='menu_admin_checkin'/>} linkTo={'/checkin'} onClick={this.handleClick}/> : ''}*/}
                    {(employee || test) ? <SecondaryMenuItem itemText={<Translate value='menu_admin_uren'/>} linkTo={'/uren'} onClick={this.handleClick}/> : ''}
                    {employee ? <SecondaryMenuItem itemText={<Transition animation='shake' duration={1500}><Popup open={admin && this.state.unhandledPopup} trigger={<div>
                        <Translate value='menu_admin_chat'/>
                        {(this.props.unhandledCount || this.props.unhandledCountCustomer) ? <Label className='relativeLabel' floating>
                            <div style={{display: 'flex', gap: 4}}>
                                <div style={{color: '#D12A2F'}}>{this.props.unhandledCount}</div>
                                {this.props.unhandledCountCustomer ? <div style={{color: 'black'}}>/</div> : null}
                                {this.props.unhandledCountCustomer ? <div style={{color: '#2185d0'}}>{this.props.unhandledCountCustomer}</div> : null}
                            </div>
                        </Label> : ''}
                    </div>} content={
                        <div>
                            <div style={{marginRight: 8}}>
                                Er zijn <b>{this.props.unhandledCountCustomer}</b> ongelezen berichten van klanten die nog niet afgehandeld zijn!
                            </div>
                            <div style={{position: 'absolute', right: 8, top: 8, cursor: 'pointer'}} onClick={(e) => {
                                this.setState({unhandledPopup: false})
                                e.preventDefault();
                            }}>
                                <Icon color='blue' name='close'/>
                            </div>
                        </div>
                    } position={"bottom left"} /></Transition>} linkTo={'/communication'} onClick={this.handleClick}/> : ''}
                    <SecondaryMenuItem itemText={<Translate value='menu_logout'/>} onClick={() => {
                        this.props.dispatch(logout());
                        setTimeout(() => {
                            this.props.dispatch(validateLogin());
                            this.props.history.push('/')
                        }, 1000);
                        this.handleClick();
                    }}/>
                    {employee ? <div style={{display: "flex", marginLeft: 15, marginRight: 15, marginTop: 1}}>
                        {/*<div style={{display: 'inline'}}>
                            <Popup position='bottom center' trigger={
                                <Icon name='video camera' style={{cursor: 'pointer'}}
                                      color={this.props.cameraOpen ? 'blue' : null}
                                      onClick={() => this.props.dispatch(openBomCamera((true)))}/>
                            } content='Camera openen'/>

                            {this.props.cameraOpen ?
                                <BomCamera onClose={() => this.props.dispatch(openBomCamera((false)))}/> : ''}
                        </div>*/}

                        {/*<BomMohican/>*/}

                        <div style={{display: 'inline'}}>
                            <BomSocket name={'BOM & Chat Socket'}/>
                        </div>

                        <DigiKeyAuthentication statusOnly={true}/>
                        <NexarAuthentication statusOnly={true}/>

                    </div> : <BomSocket name={'BOM & Chat Socket'} hidden={true}/>}
                </div>
            </div>
        );
    }
}

NavigationBarSecondary.propTypes = {
    visibleClass: PropTypes.string
};

NavigationBarSecondary.defaultProps = {
    visibleClass: 'navBarHidden'
};

const mapStateToProps = (state) => {
    return {
        currentUser: state.main.currentUser,
        lang: state.main.lang,
        currentLang: state.main.currentLang,
        cameraOpen: state.bom.cameraOpen,
        unhandledCount: state.chat.unhandledCount,
        unhandledCountCustomer: state.chat.unhandledCountCustomer
    }
}

export default connect(mapStateToProps)(withRouter(NavigationBarSecondary))