import React from 'react';
import {connect} from "react-redux";
import {Button, Dropdown, Icon, Loader, Modal} from "semantic-ui-react";
import {
    closeOrderpartInfo,
    getOrderpart,
    getOrderpartInfo,
    setOrderpartField,
    showOrderpartInfo,
    updateOrderpart
} from "../actions";
import DeltaTable from "../deltatable/DeltaTable";
import {renderImageForPart} from "./BomOrderparts";
import {formatDateTime} from "../actions/functions";
import BomOrderpartInfo from "./BomOrderpartInfo";
import {renderSupplier} from "./functions";
import { Document, Page } from 'react-pdf';

import { pdfjs } from 'react-pdf';
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

class PartInfo extends React.Component {

    state = {}

    componentDidUpdate(prevProps, prevState, snapshot) {
        if( this.props.showOrderpartInfo !== prevProps.showOrderpartInfo){
            if( this.props.showOrderpartInfo ) {
                this.props.dispatch(getOrderpart(this.props.showOrderpartInfo))
            }
        }
    }

    handleClose = () => {
        this.props.dispatch(showOrderpartInfo(null));
        this.props.dispatch(closeOrderpartInfo())
    }

    renderWashingAllowed = (op) => {
        let icon;
        if( op.washingAllowed ) {
            icon = <Icon name='check circle' color='green'/>
        } else {
            icon = <Icon name='shower' color='red'/>
        }

        return <Dropdown trigger={icon} options={[
            {key: 'yes', text: 'Yes', value: 'true', icon: <Icon name='check circle' color='green' />, color: 'green'},
            {key: 'no', text: 'No', value: 'false', icon: <Icon name='shower' color='red' />, color: 'red'},
        ]} value={op.washingAllowed} onChange={(e, {value}) => {
            this.props.dispatch(setOrderpartField(op.id, 'washingAllowed', value))
        }}/>
    }

    getData = (op) => {
        if( !op ) return [];

        return [
            {field: 'Manufacturer', value: op.manufacturer},
            {field: 'Manufacturer Part Number', value: op.mpn},
            {field: 'Description', value: op.shortDescription},
            {field: 'Image', value: renderImageForPart(op)},
            {field: 'Datasheet', value: op.datasheetUrl ? <a href={op.datasheetUrl} target="_blank">Datasheet</a> : 'Niet gevonden'},
            {field: 'Octopart url', value: <a href={op.octopartUrl} target="_blank">Octopart</a>},
            {field: 'Type', value: op.type},
            {field: 'Width', value: op.width},
            {field: 'Pitch', value: op.pitch},
            {field: 'Washing allowed', value: this.renderWashingAllowed(op) },
            ...(op.specification ? [
                {field: 'Package outline', value: op.specification.packageOutline},
                {field: 'Capacitance', value: op.specification.capacitanceDisplayValue},
                {field: 'Resistance', value: op.specification.resistanceDisplayValue},
                {field: 'Tolerance rating', value: op.specification.toleranceValue},
                {field: 'Voltage rating', value: op.specification.voltageRating},
                {field: 'Power rating', value: op.specification.powerRating},
                {field: 'Dielectric', value: op.specification.dielectric},
                {field: 'Warning', value: op.specification.warning},
            ] : []),

        ]
    }

    getDataSupplier = (sp) => {
        if( !sp ) return [];

        return [
            {field: 'Supplier', value: sp.supplier},
            {field: 'Stock Keeping Unit', value: sp.sku},
            {field: 'Last update', value: formatDateTime(sp.lastUpdate)},
            {field: 'Stock', value: sp.stock},
            {field: 'Stock US', value: sp.stockUS},
            {field: 'MOQ', value: sp.moq},
            {field: 'URL', value: <a href={sp.supplierUrl} target="_blank">{sp.supplier}</a>},
        ]
    }

    renderPdf(url) {
        return <div style={{height: '607px', overflow: 'auto'}}>
            <Document
                noData={
                    <div>
                        <Icon style={{marginTop: '30%'}} name='file pdf outline'/><br/>
                        Hier komt een preview
                    </div>
                }
                loading={
                    <div>
                        <Icon style={{marginTop: '30%'}} name='file pdf outline'/><br/>
                        Een moment aub...
                    </div>
                }
                file={url}
                onLoadSuccess={({numPages}) => this.setState({numPages: numPages})}
            >
                {
                    Array.from(
                        new Array(this.state.numPages),
                        (el, index) => (
                            <Page
                                className={'pdf_canvas'}
                                key={`page_${index + 1}`}
                                pageNumber={index + 1}
                            />
                        ),
                    )
                }
            </Document>
        </div>
    }

    render() {
        const partId = this.props.showOrderpartInfo;
        const loading = this.props.fetchingOrderPart;
        const part = this.props.orderPart;

        return (
            <Modal closeIcon onClose={this.handleClose} open={partId != null} size='fullscreen'>
                <Modal.Header>{part ? part.manufacturer + " " + part.mpn : 'Een moment...'}</Modal.Header>
                <Modal.Content>
                    <Modal.Description>
                        <div style={{display: 'flex'}}>
                            <div>

                                {/*<Document className='partinfodatasheet' file={"/api/bom/getDataSheet?id="+partId} onLoadSuccess={(numPages) => this.setState({numPages: numPages})} >
                                    <Page pageNumber={1} />
                                </Document>*/}

                                {this.state.hidePdf ? null : this.renderPdf("/api/bom/getDataSheet?id="+partId)}

                                {/*{JSON.stringify(this.state.numPages) }*/}

                            </div>
                            <div style={{flex: 1}}>
                                <Button floated='right' icon onClick={() => {
                                    this.setState({hidePdf: true})
                                    this.props.dispatch(updateOrderpart(part ? part.id : null, () => {
                                        this.setState({hidePdf: false})
                                    }))
                                }}>
                                    <Icon name='refresh' />
                                </Button>
                                <DeltaTable
                                    name={partId}
                                    loading={loading}
                                    data={this.getData(part)}
                                    maxHeight={null}
                                    showBottomIcon={false}
                                    columns={[
                                        {id: 1, header: 'Field', defaultWidth: 160, render: (r) => r.field},
                                        {id: 2, header: 'Value', defaultWidth: 320, render: (r) => r.value},
                                    ]}
                                />
                            </div>
                        </div>

                        <div>
                            {part ? <DeltaTable
                                name={'suppliers'}
                                data={part.suppliers}
                                maxHeight={null}
                                showBottomIcon={false}
                                columns={[
                                    {id: 1, header: 'Suppliers', defaultWidth: 80, render: (sp) => <div>
                                            {renderSupplier({...sp, sku: ''}, true)}
                                        </div>},
                                    {id: 2, header: 'SKU', defaultWidth: 210, render: (sp) => sp.sku},
                                    {id: 3, header: 'Last update', defaultWidth: 160, render: (sp) => formatDateTime(sp.lastUpdate)},
                                    {id: 4, header: 'Stock', defaultWidth: 120, render: (sp) => sp.stock},
                                    {id: 5, header: 'Stock US', defaultWidth: 120, render: (sp) => sp.stockUS},
                                    {id: 6, header: 'MOQ', defaultWidth: 120, render: (sp) => sp.stockUS},
                                    {id: 7, header: 'Link', defaultWidth: 120, render: (sp) => <a href={sp.supplierUrl} target="_blank">{sp.supplier}</a>},
                                    ...[1,2,3,4,5,6,7,8,9].map(i => {
                                        return {id: 8+i, header:
                                                <div style={{display: 'flex', width: '100%'}}>
                                                    <div>Price</div>
                                                    <div style={{flex: 1, textAlign: 'right', color: 'gray'}}>Quantity</div>
                                                </div>, defaultWidth: 160, render: (sp) => <div style={{display: 'flex', width: '100%'}}>
                                                <div>{sp['price'+i]} </div>
                                                <div style={{flex: 1, textAlign: 'right', color: 'gray'}}>{sp['break'+i]}</div>
                                            </div>}
                                    })

                                ]}
                            /> : null}
                        </div>

                        <div style={{width:'100%', textAlign: 'center', paddingTop: 16, paddingBottom: 16}}>
                            <Button
                                onClick={() => this.props.dispatch(getOrderpartInfo(partId))}
                                loading={this.props.fetchingOrderpartInfo === partId}
                            >Zoek projecten waar dit part wordt gebruikt</Button>
                        </div>

                        <BomOrderpartInfo/>

                    </Modal.Description>
                </Modal.Content>
                <Modal.Actions>
                    <Button onClick={this.handleClose}>Close</Button>
                </Modal.Actions>
            </Modal>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        orderPart: state.bom.orderPart,
        fetchingOrderPart: state.bom.fetchingOrderPart,
        showOrderpartInfo: state.bom.showOrderpartInfo,
        fetchingOrderpartInfo: state.bom.fetchingOrderpartInfo,
    }
}

export default connect(mapStateToProps)(PartInfo);