import React, {Component} from 'react';
import './App.css';
import {
    Button,
    Confirm,
    Header,
    Image,
    Modal,
} from "semantic-ui-react";
import connect from "react-redux/es/connect/connect";
import {
    changeProjectCalculatorValue, openChatModal, saveProject
} from "./actions";
import {
    isEmployee
} from "./actions/functions";
import Translate, {getText} from "./Translate";
import Calculator from "./oldui/Calculator";
import {Link, withRouter} from "react-router-dom";
import AccountAddress from "./oldui/AccountAddress";
import SideBar from "./bom/SideBar";
import ChatView from "./chat/ChatView";
import BasicSheet from "./basic/BasicSheet";
import BasicProjectMenu from "./basic/BasicProjectMenu";
import BomMicroPlacer from "./bom/BomMicroPlacer";
import ChatNewMessageModal from "./chat/ChatNewMessageModal";


class ProjectStepAnalysisAndOrder extends Component{

    state= {
        activeName: 'aanvraag'
    }

    componentDidMount() {
        if( this.props.subPageName === 'messages' ) {
            this.setState({activeName: 'chat'})
        }
        this.renderAction = this.renderAction.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.renderAction = this.renderAction.bind(this);
        this.handleChange = this.handleChange.bind(this);

        if( this.props.project && prevProps.project && this.props.project.calculatorDetails !== prevProps.project.calculatorDetails ) {
            //this.props.dispatch(fetchPrice(this.props.project.calculatorDetails, this.props.project.id))
        }
    }

    saveProject( value, args ) {

        this.props.dispatch(saveProject(value, args, () => {
            if( args && args.action === "ORDER" ) {
                this.setState({thanks: true})
            }
            this.setState({cancelConfirm: false})
        }))
    }

    renderAction() {

        if(!this.props) {
            return <div></div>
        }

        let project= this.props.project;
        let saving= this.props.savingProject;

        if(!project) {
            return <div></div>
        }

        let hasLegacyAddress= !project.deliveryAddress && !project.invoiceAddress && project.owner.deliveryAddress != null && project.owner.deliveryAddress.length > 0 && project.owner.deliveryAddress[0] !== "";

        let canCancel;
        let canOrder;


        canCancel = project.analysis === null || project.analysis === "notstarted" || project.analysis === "active" || project.analysis === "ready";
        canOrder = project.analysis === "ready";


        return <div>

            {canCancel && <Button type="button" size='large' title='Project aanvraag annuleren' loading={saving} disabled={saving || this.state.busy} positive={this.state.saved} onClick={() => {
                this.setState({cancelConfirm: true})
            }}>{this.state.busy ? getText(this.props, "moment_please") : this.state.saved ? getText(this.props, "canceled") : getText(this.props, "cancel")}</Button>}

            {canOrder && <Link to={'/order/'+project.id}>
                <Button type="button" size='large' color='red'  data-tip data-for='global' loading={saving} disabled={saving || this.state.busy} positive={this.state.saved} style={{marginLeft: 16}}>
                    {this.state.busy ? getText(this.props, "moment_please") : this.state.saved ? getText(this.props, "ordered") : getText(this.props, "order")}
                </Button>
            </Link>}

            <div style={{textAlign: 'left'}}>
                {project.deliveryAddress ? <div>
                    <Header><Translate value="user_delivery_address"/></Header>
                    <AccountAddress address={project.deliveryAddress} displayOnly={false} color={'blue'} canHasDefault={false}
                                    modalText={<Translate value="user_delivery_address"/>}
                                    onSave={(a) => {
                                        console.log('save ' + JSON.stringify(a) )
                                        this.props.dispatch( saveProject({...project, deliveryAddress: a}) );
                                    }}
                                    onChange={(a) => {
                                        console.log(a)
                                    }}
                    />
                </div>: ''}

                {project.invoiceAddress ? <div style={{marginTop: 16}}>
                    <Header><Translate value="user_invoice_address"/></Header>
                    <AccountAddress address={project.invoiceAddress} displayOnly={false} color={'red'} canHasDefault={false}
                                    modalText={<Translate value="user_invoice_address"/>}
                                    onSave={(a) => {
                                        console.log('save ' + JSON.stringify(a) )
                                        this.props.dispatch( saveProject({...project, invoiceAddress: a}) );
                                    }}
                                    onChange={(a) => {
                                        console.log(a)
                                    }}
                    />
                </div>: ''}

                {hasLegacyAddress ? <div><div style={{marginBottom: 14, whiteSpace: 'pre', display: 'inline-block', textAlign: 'left'}}>
                    {
                        project.owner.deliveryAddress.filter(a => a.isDefault).length > 0
                            ?
                        project.owner.deliveryAddress.filter(a => a.isDefault)[0].address
                            :
                        project.owner.deliveryAddress[0].address
                    }
                </div></div> : ''}

            </div>
        </div>
    }

    handleChange = (name, value) => {
        this.props.dispatch(changeProjectCalculatorValue(name, value))
    }


    renderChat() {
        return <BasicSheet header={'Berichten'}>
            <ChatView chatId={this.props.project.chat} showInput={false} isPageView={false} />

            <div style={{textAlign: 'right'}}>
                <Button primary onClick={() => this.props.dispatch(openChatModal(true))}>
                    <Translate value={'post_new_chat'}/>
                </Button>
            </div>

        </BasicSheet>
    }

    render() {

        let {project}= this.props;
        const employee = isEmployee(this.props.currentUser);

        if( project == null ) {
            return <div/>
        }

        if( !employee ) {
            return <div style={{marginTop: 16}}>

                {this.props.project.chat ? <ChatNewMessageModal showConfirm={!employee} chatId={this.props.project.chat}/> : null}

                <BasicProjectMenu
                    menu={[
                        {
                            name: 'aanvraag',
                            item: <>
                                Aanvraag
                                {this.props.project.analysis === 'ready' ? <div className='notification-counter'>1</div> : ''}
                            </>
                        }/*,
                        {
                            name: 'chat',
                            item: <>
                                Berichten
                                {this.props.project.customerMessages ? <div className='notification-counter'>{this.props.project.customerMessages}</div> : ''}
                            </>
                        }*/,
                        {
                            name: 'inkoop',
                            item: <>
                                BOM
                                {(this.props.project && this.props.project.bomStats && this.props.project.bomStats.markedLines) ?
                                    <div className='notification-counter'>{this.props.project.bomStats.markedLines}</div> : ''}
                            </>
                        }
                    ]}
                    menuRight={[
                        {
                            name: 'chat',
                            item: 'Berichten'
                        }
                    ]}
                    activeName={this.state.activeName}
                    onTabChange={(name) => this.setState({activeName: name})}
                />

                <Modal size='tiny' closeIcon={true} open={this.state.thanks} onClose={() => this.setState({thanks: false})}>
                    <Modal.Header style={{textAlign: 'center', fontSize: '3em'}}><Translate value="project_thanks_order"/></Modal.Header>
                    <Modal.Content style={{textAlign: 'center'}} >
                        <Header><Translate value="project_thanks_we_start_immediatly"/></Header>
                        <Image centered style={{aligned: 'center'}} wrapped size='medium' src='/images/undraw_To_the_stars_qhyy.svg' />

                    </Modal.Content>
                </Modal>

                <Confirm
                    open={this.state.cancelConfirm}
                    onCancel={() => this.setState({cancelConfirm: false})}
                    onConfirm={() => this.saveProject(project, {action: "CANCEL"})}
                    content={getText(this.props, "project_cancel_order")}
                    cancelButton={getText(this.props, "no")}
                    confirmButton={getText(this.props, "yes")}
                />

                <div style={{marginTop: 16}}>

                    <div className={this.state.activeName === 'inkoop' ? 'fullwidthprojectview' : ''} style={{marginTop: 0, paddingTop: 0}} id='analysis'>
                        {
                            {
                                'aanvraag':
                                    <>
                                        <Calculator embed={true} remark={project.calculatorDetails.remark} data={project.calculatorDetails} orderid={project.id}
                                                    isAdmin={false} onChange={this.handleChange} locked={true} renderAction={() => this.renderAction()}
                                                    dateAmountLocked={project.orderDate !== null} displayName={false} showAddadditionalFiles={project.id && !employee}
                                        />
                                    </>,
                                'inkoop': <div className={'fullwidthprojectview'} style={{marginTop: -8}} >
                                    <div className={'almostfullwidthprojectview'}>
                                        <BomMicroPlacer projectOrderId={this.props.project.id} defaultViewId={'Klant'} locked={project.order === 'ready'}/>
                                    </div>
                                </div>,
                                'chat': this.renderChat()

                            }[this.state.activeName]
                        }

                    </div>

                </div>
            </div>
        }

        if( this.props.view === 'aanvraag' ) {
            return <div style={{marginTop: 16}}>
                <Calculator embed={true} remark={project.calculatorDetails.remark} data={project.calculatorDetails}
                            orderid={project.id}
                            isAdmin={true} onChange={this.handleChange} locked={false}
                            renderAction={() => this.renderAction()}
                            dateAmountLocked={false} displayName={false}
                />
            </div>
        }

        return(
            <div style={{marginTop: 16}}>

                {employee && <SideBar/>}

                <BasicProjectMenu
                    menu={[
                        {
                            name: 'aanvraag',
                            item: 'Aanvraag'
                        },
                        {
                            name: 'inkoop',
                            item: <>
                                BOM
                                {(this.props.project && this.props.project.bomStats && this.props.project.bomStats.markedLines) ?
                                    <div className='notification-counter'>{this.props.project.bomStats.markedLines}</div> : ''}
                            </>
                        }
                    ]}
                    menuRight={[
                        {
                            name: 'chat',
                            item: 'Chat'
                        }
                    ]}
                    activeName={this.state.activeName}
                    onTabChange={(name) => this.setState({activeName: name})}
                    sideBar={true}
                />

                <div className={this.state.activeName === 'inkoop' ? 'almostfullwidthprojectview' : ''} style={{marginTop: 0, paddingTop: 8}} id='analysis'>
                    {
                        {
                            'aanvraag':
                            <div style={{marginTop: 8}}>
                                 <Calculator embed={true} remark={project.calculatorDetails.remark} data={project.calculatorDetails}
                                                   orderid={project.id}
                                                   isAdmin={true} onChange={this.handleChange} locked={false}
                                                   renderAction={() => this.renderAction()}
                                                   dateAmountLocked={false} displayName={false}
                                />
                            </div>,
                            'inkoop': <div className={'fullwidthprojectview'} style={{marginTop: 0}} >
                                <div className={'almostfullwidthprojectview'}>
                                    <BomMicroPlacer projectOrderId={this.props.project.id} defaultViewId={this.state.bstStep} locked={project.order === 'ready'}/>
                                </div>
                            </div>
                            ,
                            'chat': this.renderChat()

                        }[this.state.activeName]
                    }

                </div>

            </div>
        )
    }
}


const mapStateToProps = (state) => {
    return {
        currentUser: state.main.currentUser,
        lang: state.main.lang,
        currentLang: state.main.currentLang,
        project: state.project.project,
        savingProject: state.project.savingProject,
    }
}

export default connect(mapStateToProps)(withRouter(ProjectStepAnalysisAndOrder))