import {Icon, Image, Popup} from "semantic-ui-react";
import React from "react";
import {formatDateTime} from "../actions/functions";
import {
    encodeUrlParameters,
    getBomOrders,
    getBomProduction,
    getChatForPrint, getChatForProjectOrder,
    getLastUsedBoardLocations,
    openBom
} from "../actions";

var moment = require('moment');
moment.locale('NL');

export const opcodes = [
    'MPN',
    'SKU',
    'CUSTOMER_ADDED_SKU',
    'CPN',
    'CUSTOMER_COMMENT'
]

export const defaultSelectedColumns = [0,1,30,8,9,17,23,24,25,26,34];

export const WEBCAM_CAMERA_WIDTH = 1920
export const WEBCAM_CAMERA_HEIGHT = 1080

export function getOrderItemsByTray(tray, bomOrderList) {
    let orderItems= [];

    if( bomOrderList ) {
        orderItems= bomOrderList.filter(bo => {
            let hasPart = bo.items.filter(i => {
                if( i.boardLocation ) {
                    return i.boardLocation === tray
                }
                return false;
            }).length > 0;
            return hasPart
        })

        orderItems= orderItems.map(bo => {
            return bo.items.filter(i => {
                if( i.boardLocation ) {
                    return i.boardLocation === tray
                }
                return false;
            }).map(i => ({...i, supplier: bo.supplier, supplierOrderCode: bo.supplierOrderCode}))
        }).flat()
    }

    return orderItems;
}

export function getOrderItemsByType(type, bomOrderList) {
    let orderItems= [];

    if( bomOrderList ) {
        orderItems= bomOrderList.filter(bo => {
            return bo.supplier && bo.supplier.endsWith('_'+type.toUpperCase())
        })
    }

    return orderItems;
}

export const CHECKIN_COLOR_FOR_1 = '#c0ebff'
export const CHECKIN_COLOR_RED = '#f5c6cb'
export const CHECKIN_COLOR_GREEN = '#c3e6cb'
export const CHECKIN_COLOR_YELLOW = '#f8f0a4'
export const CHECKIN_COLOR_GREY = '#e7e7e7'

export function renderStock(stock, orderAmount, color) {
    if( orderAmount ) {
        return <>
            {stock} / {orderAmount}
        </>
    }
    return <>{stock}</>
}

export function renderSupplierInformation(o) {
    return <div style={{minWidth: 50, maxWidth: 50, overflow: 'hidden', marginTop: 8, textAlign: 'center'}}>
        {o.bomOrder ?
            <Popup
                trigger={renderSupplier({supplier: o.bomOrder.supplier})}
                content={<div>
                    <b>Bestelling</b>
                    <div>Order code: {o.bomOrder.supplierOrderCode}</div>
                    <div>Created: {formatDateTime(o.bomOrder.created)}</div>
                    <div>Status: {o.bomOrder.status}</div>
                </div>}
            /> : <Popup
                trigger={renderSupplier({supplier: o.supplier}, true)}
                content={o.supplier}
            />}
    </div>;
}

export function renderImageForPart(op, sp) {
    if( op && op.imageUrl ) {

        let image = <img src={op.imageUrl} alt="part image" className="bst-image" style={{maxHeight: '32px', maxWidth: '32px', background: 'URL(https://www.deltaproto.com/img/logo.png) center center black'}} />;

        let credit = <div style={{fontSize: '.4em'}}>
            <a href={op.imageCreditUrl}>{op.imageCreditString}</a>
        </div>

        if( sp && sp.supplier ) {
            return <>
                {renderSupplierUrl(sp, image)}
                {credit}
            </>
        } else {
            return <>
                {image}
                {credit}
            </>
        }
    } else {
        return <></>;
    }
}

export function renderDecimalPlaces(value, decimals = 2) {
    if( value != null ) {
        let round = Math.pow(10, decimals);
        return new Number((Math.round(value * round) / round)).toLocaleString('nl-NL', {
            minimumFractionDigits: decimals,
            maximumFractionDigits: decimals
        });
    }
    return value;
}

export function renderEuro(value, decimals = 2) {
    if( value != null ) {
        let round = Math.pow(10, decimals);
        return "€ " + new Number((Math.round(value * round) / round)).toLocaleString('nl-NL', {
            minimumFractionDigits: decimals,
            maximumFractionDigits: decimals
        });
    }
    return value;
}

export function renderPriceSinglePart(sp, need) {

    let priceOnePart= null;

    for (let i = 0; i < 9; i++) {
        let brk = sp['break' + (i+1)];
        let price= sp['price' + (i+1)]

        if( brk <= need ) {
            if( priceOnePart == null || price < priceOnePart) {
                priceOnePart = price;
            }
        }
    }

    if( priceOnePart == null ) {
        priceOnePart= getHighestPrice(sp)
    }

    let decimals = 2;
    if( priceOnePart < 0.01 ) {
        decimals = 3;
    }

    return <div style={{whiteSpace: 'nowrap'}}>
        {renderEuro(priceOnePart, decimals)}
    </div>
}

function getHighestPrice(list) {
    let highest = 0;

    for (let i = 0; i < 9; i++) {
        let price = list['price' + (i + 1)]

        if( price && price > highest ) {
            highest = price
        }
    }
    return highest;
}

export function renderPrice(sp, parts, amount) {

    let need= parts * amount;

    let priceOnePart= null;

    for (let i = 0; i < 9; i++) {
        let brk = sp['break' + (i+1)];
        let price= sp['price' + (i+1)]

        if( brk <= need ) {
            if( priceOnePart == null || price < priceOnePart) {
                priceOnePart = price;
            }
        }
    }

    if( priceOnePart == null ) {
        priceOnePart = getHighestPrice(sp)
    }

    if( sp.moq && need < sp.moq ) {
        need = sp.moq;
    }

    return <div style={{whiteSpace: 'nowrap'}}>
        {renderEuro((need * priceOnePart) / amount)}
    </div>
}

export function getNeed(parts, amount, extra) {
    return (parts * amount) + extra;
}

export function getPriceOnePartMoq(sp, parts, amount, extra = 0) {
    let need= getNeed(parts, amount, extra)

    if( sp.moq && need < sp.moq ) {
        need = sp.moq;
    }

    if( need % sp.moq ) {
        need = need + (sp.moq - (need % sp.moq))
    }

    let priceOnePart= null;

    for (let i = 0; i < 9; i++) {
        let brk = sp['break' + (i+1)];
        let price= sp['price' + (i+1)]

        if( brk != null && price != null ) {
            if (brk <= need) {
                if (priceOnePart == null || price < priceOnePart) {
                    priceOnePart = price;
                }
            }
        }
    }

    if( priceOnePart == null ) {
        priceOnePart = getHighestPrice(sp)
    }

    return {need, price: priceOnePart};
}

export function getPriceWithoutMoq(sp, parts, amount) {

    let need= parts * amount;

    let priceOnePart= null;

    for (let i = 0; i < 9; i++) {
        let brk = sp['break' + (i+1)];
        let price= sp['price' + (i+1)]

        if( brk <= need ) {
            if( priceOnePart == null || price < priceOnePart) {
                priceOnePart = price;
            }
        }
    }

    if( priceOnePart == null ) {
        return getHighestPrice(sp)
    }

    return parts * priceOnePart;
    /*return <div>
        {renderEuro(parts * priceOnePart)}
    </div>*/
}

export function getOrderItemsByRow(r, bomOrderList) {
    let orderItems= [];

    if( r && bomOrderList ) {
        orderItems= bomOrderList.filter(bo => {
            let hasPart = bo.items.filter(i => {
                if( r.klantLevertAan && i.kla ) {
                    return i.kla.id === r.klantLevertAan.id
                }
                else if( r.selectedSupplierPart && i.part ) {
                    return i.part.orderpart.id === r.selectedSupplierPart.orderpart.id
                }
                else if( r.selectedSupplierPart && i.orderpart ) {
                    return i.orderpart.id === r.selectedSupplierPart.orderpart.id
                }
                return false;
            }).length > 0;
            return hasPart
        })

        orderItems= orderItems.map(bo => {
            return bo.items.filter(i => {
                if( r.klantLevertAan && i.kla ) {
                    return i.kla.id === r.klantLevertAan.id
                }
                else if( r.selectedSupplierPart && i.part ) {
                    return i.part.orderpart.id === r.selectedSupplierPart.orderpart.id
                }
                else if( r.selectedSupplierPart && i.orderpart ) {
                    return i.orderpart.id === r.selectedSupplierPart.orderpart.id
                }
            }).map(i => ({...i, supplier: bo.supplier, supplierOrderCode: bo.supplierOrderCode}))
        }).flat()
    }

    return orderItems;
}

export function renderSupplierUrl({supplier, supplierUrl, sku}, children) {
    if( supplierUrl ) {
        return <Popup content={'Open de ' + supplier + ' website'} wide='very' trigger={
            <a href={supplierUrl} style={{marginLeft: 8}} target="_blank">
                {children ? children : <Icon name='external' size='small'/>}
            </a>
        }/>
    } else {
        let url;
        switch (supplier) {
            case 'FARNELL':
                url = 'https://nl.farnell.com/'+sku
                break;
            case 'DIGIKEY':
                url = 'https://www.digikey.com/nl/products/result?keywords='+sku
                break;
            case 'MOUSER':
                url = 'https://nl.mouser.com/Search/Refine?Keyword='+sku
                break;
            case 'RSCOMPONENTS':
                url = 'https://nl.rs-online.com/web/c/?sra=oss&r=t&searchTerm='+sku
                break;
            case 'TME':
                url = 'https://www.tme.eu/nl/details/'+sku+'/'
                break;
            case 'ARROW':
                url = 'https://www.arrow.com/en/products/search?q='+sku
                break;
        }

        if( url ) {
            return <Popup content={'Zoek op de ' + supplier + ' website'} wide='very' trigger={
                <a href={url} style={{marginLeft: 8}} target="_blank">
                    {children ? children : <Icon name='external' size='small'/>}
                </a>
            }/>
        }
    }
    return <></>
}

export function getPriceForAmount (supplier, amount) {

    let res= null;

    for (let i = 0; i < 100; i++) {
        let price= supplier['price' + i]
        let brk= supplier['break' + i]

        if( brk !== null && brk <= amount ) {
            if( res == null || price < res ) {
                res = price;
            }
        }
    }

    return res ? res : 0.0;

}

export function getSupplierSendCost(supplier, cost) {
    let value= supplier.trim().toUpperCase();

    switch (value) {
        case 'FARNELL':
            return cost < 50.0 ? 5.0 : 0.0;
        case 'DIGIKEY':
            return cost < 50.0 ? 18.0 : 0.0; //todo: dollar
        case 'TME':
            return 8.0;
        case 'MOUSER':
            return cost < 50.0 ? 20.0 : 0.0;
        case 'RSCOMPONENTS':
            return 4.95;
        case 'ARROW':
            if( cost < 100.0) {
                return 25.0;
            }
            return 0;
        case 'WURTH':
            return 20.0;
        case 'LCSC':
            return 28.0;
    }

    return 0.0;
}

export function addBusinessDays(originalDate, numDaysToAdd) {
    const Sunday = 0;
    const Saturday = 6;
    let daysRemaining = numDaysToAdd;

    const newDate = originalDate.clone();

    while (daysRemaining > 0) {
        newDate.add(1, 'days');
        if (newDate.day() !== Sunday && newDate.day() !== Saturday) {
            daysRemaining--;
        }
    }

    return newDate;
}

export function getSupplierDeliveryTime(supplier, orderDateTime, deliverytime) {
    let value= supplier.trim().toUpperCase();

    let time = 1;
    let hour = moment(orderDateTime).hour();

    switch (value) {
        case 'FARNELL':
            time = hour < 18 ? 1 : 2; //voor 18.00
            break;
        case 'DIGIKEY':
            time = hour < 18 ? 3 : 4; //voor 18.00
            break;
        case 'TME':
            time = hour < 14 ? 1 : 2; //voor 14.00
            break;
        case 'MOUSER':
            time = hour < 18 ? 2 : 3; //voor 18.00
            break;
        case 'RSCOMPONENTS':
            time = hour < 17 ? 1 : 2; //voor 17.00
            break;
        case 'ARROW':
            time = hour < 18 ? 2 : 3; //voor 18.00
            break;
        case 'WURTH':
            time = hour < 18 ? 2 : 3; //voor 18.00
            break;
        case 'CUSTOMER':
            time = hour < 18 ? 3 : 4; //voor 18.00
            break;
        case 'AVNET':
            //time = hour < 18 ? 2 : 3; //voor 18.00
            break;

        case 'CUSTOMER_PCB':
            time = hour < 18 ? 5 : 6; //voor 18.00
            break;
        case 'PCBWAY_PCB':
            time = (hour < 12 ? 3 : 4) + deliverytime; //voor 12.00
            break;
        case 'EUROCIRCUITS_PCB':
            time = (hour < 18 ? 2 : 3) + deliverytime; //voor 18.00
            break;
        case 'MULTI_CB_PCB':
            time = (hour < 18 ? 2 : 3) + deliverytime; //voor 18.00
            break;
        case 'JLC_PCB':
            time = (hour < 12 ? 3 : 4) + deliverytime; //voor 12.00
            break;

        case 'CUSTOMER_STENCIL':
            time = hour < 18 ? 5 : 6; //voor 18.00
            break;
        case 'PCBWAY_STENCIL':
            time = (hour < 12 ? 3 : 4) + deliverytime; //voor 18.00
            break;
        case 'ALPHAASSEMBLY_STENCIL':
            time = (hour < 14 ? 1 : 2); //voor 14.00
            break;
        case 'MULTI_CB_STENCIL':
            time = (hour < 18 ? 2 : 3) + deliverytime; //voor 18.00
            break;
        case 'JLC_STENCIL':
            time = (hour < 12 ? 3 : 4) + deliverytime; //voor 12.00
            break;
    }

    let res = addBusinessDays( moment(orderDateTime), time );

    return res.hour(18).minutes(0).seconds(0)
}

export function renderSupplier({supplier, sku}, nolabel: false) {

    let style = {display: 'inline', height: 17, paddingRight: 4}

    //let skuText= sku ? (" " + sku) : "";
    let skuText = sku;

    let value= '';

    if( supplier ) {
        value = supplier.trim().toUpperCase();
    }

    const label = nolabel ? null : (value.endsWith("_PCB") ? ' PCB ' : ' Stencil ')

    if (value === "FARNELL") {
        return <div className="supplierimageparent">
            <div className="supplierimage"><Image style={style} src={"/images/logos/farnell-logo-100.jpeg"}
                                                  alt={"Farnell"}/></div>
            {skuText}</div>
    } else if (value === "DIGIKEY" || value === "DIGI-KEY") {
        return <div className="supplierimageparent">
            <div className="supplierimage"><Image style={style} src={"/images/logos/digi-key-logo-100.png"}
                                                  alt={"Digi-key"}/></div>
            {skuText}</div>
    } else if (value === "TME") {
        return <div className="supplierimageparent">
            <div className="supplierimage"><Image style={style} src={"/images/logos/tme-logo-100.png"} alt={"TME"}/>
            </div>
            {skuText}</div>
    } else if (value === "MOUSER") {
        return <div className="supplierimageparent">
            <div className="supplierimage"><Image style={style} src={"/images/logos/mouser-logo-100.png"}
                                                  alt={"Mouser"}/></div>
            {skuText}</div>
    } else if (value === "RSCOMPONENTS" || value === "RS" || value === "RS-COMPONENTS" || value === "RS COMPONENTS") {
        return <div className="supplierimageparent">
            <div className="supplierimage"><Image style={style} src={"/images/logos/rs-logo-100.png"}
                                                  alt={"RS Components"}/></div>
            {skuText}</div>
    } else if (value === "ARROW") {
        return <div className="supplierimageparent">
            <div className="supplierimage"><Image style={style} src={"/images/logos/arrow-logo-100.png"} alt={"Arrow"}/>
            </div>
            {skuText}</div>
    } else if (value === "WURTH" || value === "WÜRTH" || value === "WURTH ELEKTRONIK" || value === "WÜRTH ELEKTRONIK") {
        return <div className="supplierimageparent">
            <div className="supplierimage"><Image style={style} src={"/images/logos/wurth-logo-100.png"} alt={"Würth"}/>
            </div>
            {skuText}</div>
    } else if (value === "LCSC" || value === "lcsc") {
        return <div className="supplierimageparent">
            <div className="supplierimage"><Image style={style} src={"/images/logos/lcsc-logo-100.png"} alt={"LCSC"}/>
            </div>
            {skuText}</div>
    } else if( value === "DELTAPROTO" ) {
        return <div className="supplierimageparent">
            <div className="supplierimage">
                <Image style={style} src={"/images/logos/deltaproto-logo-100.png"} alt={"DeltaProto"}/>
            </div>
            {skuText}</div>
    } else if( value === "KLANT" || value === "CUSTOMER" ) {
        return <div className="supplierimageparent">
            <div className="klantimage">KLANT</div>
            {skuText}
        </div>
    } else if( value === "CUSTOMER_STENCIL" || value === "CUSTOMER_PCB" ) {
        return <div className="supplierimageparent">
            <div className="klantimage">KLANT</div>
            {label}
            {skuText}
        </div>
    } else if( value === "MANUAL" || value === "MAN" ) {
        return <div className="supplierimageparent">
            <div className="manualimage">MANUAL
            </div>
            {skuText}</div>
    } else if( value === "WAREHOUSE" || value === "Warehouse" ) {
        return <div className="supplierimageparent">
            <Icon name='warehouse'/>
            {skuText}
        </div>
    } else if( value === "JLC_PCB" || value === "JLC_STENCIL" ) {
        return <div className="supplierimageparent">
            <div className="supplierimage">
                <Image style={style} src={"/images/logos/jlc.png"} alt={"JLC"}/>{label ? <span style={{paddingRight: 8}}>{label}</span> : ''}
            </div>
            {skuText}
        </div>
    } else if( value === "EUROCIRCUITS_PCB" ) {
        return <div className="supplierimageparent">
            <div className="supplierimage">
                <Image style={style} src={"/images/logos/eurocircuits.png"} alt={"Eurocircuits"}/>{label ? <span style={{paddingRight: 8}}>{label}</span> : ''}
            </div>
            {skuText}
        </div>
    } else if( value === "MULTI_CB_PCB" || value === "MULTI_CB_STENCIL" ) {
        return <div className="supplierimageparent">
            <div className="supplierimage">
                <Image style={style} src={"/images/logos/multicb.png"} alt={"Multicb"}/>{label ? <span style={{paddingRight: 8}}>{label}</span> : ''}
            </div>
            {skuText}
        </div>
    } else if( value === "ALPHAASSEMBLY_STENCIL" ) {
        return <div className="supplierimageparent">
            <div className="supplierimage">
                <Image style={style} src={"/images/logos/alphaassembly.png"} alt={"Alpha Assembly"}/>{label ? <span style={{paddingRight: 8}}>{label}</span> : ''}
            </div>
            {skuText}
        </div>
    } else if( value === "PCBWAY_PCB" || value === "PCBWAY_STENCIL" ) {
        return <div className="supplierimageparent">
            <div className="supplierimage">
                <Image style={style} src={"/images/logos/pcbway-100.png"} alt={"PcbWay"}/>{label ? <span style={{paddingRight: 8}}>{label}</span> : ''}
            </div>
            {skuText}
        </div>
    }

    return <div>{supplier ? supplier + ' ' : ''}{sku}</div>
}

export function isInTodos(value, opcode, todos) {

    let list = todos.filter((t) => t.opcode === opcode).filter((t) => t.value === value);

    return list.length > 0

}

export function hideFooter() {
    document.getElementById("footer").style.display = 'none';
    document.getElementById("footer").style.padding = '0px';
    document.getElementById("contactDetails").style.display = 'none';
    document.getElementById("social").style.display = 'none';
    document.getElementById("footerLegal").style.display = 'none';
}

export function recentUpdated(sp) {

    let now = new Date().getTime();

    if( (now - (5*60*60*1000)) < sp.lastUpdate ) {
        return true;
    }

    return false
}

export const sortDesignators = (da, db, bomSort) => {
    switch (bomSort) {
        case 'designator':
        case 'boardLocation':
        case 'row':
            let a = da.value;
            let b = db.value;

            return a.localeCompare(b, undefined, { sensitivity: 'accent' });
        default:
            return 0;
    }
}

export const sortBomRow = (ra, rb, bomSort, bomOrderList) => {
    switch( bomSort ) {
        case 'boardLocation':

            let orderItemsA = getOrderItemsByRow(ra, bomOrderList);
            let orderItemsB = getOrderItemsByRow(rb, bomOrderList);

            let locA = ''
            let locB = ''

            if( orderItemsA && orderItemsA.length ) {
                locA = orderItemsA[0].boardLocation;
                locA = locA ? locA : ''
            }

            if( orderItemsB && orderItemsB.length ) {
                locB = orderItemsB[0].boardLocation;
                locB = locB ? locB : ''
            }

            if( !locA ) return 1;
            if( !locB ) return -1;

            return locA.localeCompare(locB);
        case 'row':
            return ra.row - rb.row;
        case 'designator':
            let da = ra.designators.length ? ra.designators[0].value : '';
            let db = rb.designators.length ? rb.designators[0].value : '';

            return da.localeCompare(db, undefined, { sensitivity: 'accent' });
        default:
            return 0;
    }
}

export const sortBomDesignator = (ra, rb, da, db, bomSort) => {
    switch( bomSort ) {

        default:
            return 0;
    }
}

export function getOrdersForRow(r, bomOrderList) {
    let orders = []
    if( bomOrderList ) {
        orders= bomOrderList.map(bo => {
            let items = bo.items.filter(i => {
                if( r.klantLevertAan && i.kla ) {
                    return i.kla.id === r.klantLevertAan.id
                }
                else if( r.selectedSupplierPart && i.part ) {
                    return i.part.orderpart.id === r.selectedSupplierPart.orderpart.id
                }
                return false;
            });
            return {...bo, items}
        })

        orders = orders.filter(bo => {
            return bo.items.length > 0
        })
    }
    return orders;
}

export function isPcbOrStencil(order) {
    return order.supplier === null || order.supplier.endsWith("_PCB") || order.supplier.endsWith("_STENCIL");
}

export function getCostForPrint(print) {
    let cost = 0;
    let costPerSupplier = {}
    let sendCostTotal = 0;

    print && print.bom.forEach((r) => {

            if (r.selectedSupplierPart) {
                r.extraAmount = (r.extraAmount != null) ? r.extraAmount : 0;

                let a = getPriceOnePartMoq(r.selectedSupplierPart, r.amount, print.amount, r.extraAmount)
                cost = cost + (a.need * a.price);

                let supplierName = r.selectedSupplierPart.supplier;

                let wantCost = (r.amount * a.price) * print.amount;
                let extraAmountCost = r.extraAmount * a.price;
                let moqCost = ((a.need - (r.amount * print.amount) - r.extraAmount) * a.price);

                let usStock = false;
                if( r.selectedSupplierPart.stock <= 0 && r.selectedSupplierPart.stockUS != null && r.selectedSupplierPart.stockUS > 0 ) {
                    usStock = true;
                }

                if (costPerSupplier[supplierName] != null) {

                    costPerSupplier[supplierName] = {
                        wantCost: wantCost + costPerSupplier[supplierName].wantCost,
                        extraAmountCost: extraAmountCost + costPerSupplier[supplierName].extraAmountCost,
                        moqCost: moqCost + costPerSupplier[supplierName].moqCost,
                        usStock: usStock ? true : costPerSupplier[supplierName].usStock
                    }

                } else {
                    costPerSupplier[supplierName] = {
                        wantCost: wantCost,
                        extraAmountCost: extraAmountCost,
                        moqCost: moqCost,
                        usStock: usStock
                    }
                }
            }

            if (r.selectedBuddyPart) {
                let a = r.amount * print.amount * r.selectedBuddyPart.price1;
                cost = cost + a;
                let supplierName = 'DELTAPROTO';

                if (costPerSupplier[supplierName] != null) {

                    costPerSupplier[supplierName] = {
                        moqCost: 0,
                        wantCost: a + costPerSupplier[supplierName].wantCost,
                        extraAmountCost: 0
                    }

                } else {
                    costPerSupplier[supplierName] = {
                        moqCost: 0,
                        wantCost: a,
                        extraAmountCost: 0
                    }
                }
            }

        }
    )

    Object.entries(costPerSupplier).map(p => {

        let wantCost = p[1].wantCost;
        let moqCost = p[1].moqCost;
        let extraAmountCost = p[1].extraAmountCost;
        let sendCost = getSupplierSendCost(p[0], wantCost + extraAmountCost + moqCost);
        if( p[1].usStock ) {
            sendCost = sendCost + 25;
        }

        p[1].sendCost = sendCost;

        sendCostTotal = sendCost + sendCostTotal;
    })

    return {cost, costPerSupplier, sendCostTotal};
}

export function checkHasCentroid(print) {
    let hasCentroid= false;
    if( print && print.bom ) {
        print.bom.forEach(r => {
            if (r.designators) {
                r.designators.forEach(d => {
                    if (d.x) {
                        hasCentroid = true;
                    }
                })
            }
        })
    }
    return hasCentroid;
}

export function encodeProjectId(props, uplacer = false) {

    if( props.projectOrderId ) {
        return encodeUrlParameters({
            projectOrderId: props.projectOrderId,
            aggregate: props.aggregateUpload,
            uplacer: uplacer
        })
    }

    return encodeUrlParameters({
        aggregate: props.aggregateUpload,
        uplacer: uplacer
    })
}

export function toggleFullScreen(elem) {
    // ## The below if statement seems to work better ## if ((document.fullScreenElement && document.fullScreenElement !== null) || (document.msfullscreenElement && document.msfullscreenElement !== null) || (!document.mozFullScreen && !document.webkitIsFullScreen)) {
    if ((document.fullScreenElement !== undefined && document.fullScreenElement === null) || (document.msFullscreenElement !== undefined && document.msFullscreenElement === null) || (document.mozFullScreen !== undefined && !document.mozFullScreen) || (document.webkitIsFullScreen !== undefined && !document.webkitIsFullScreen)) {
        if (elem.requestFullScreen) {
            elem.requestFullScreen();
        } else if (elem.mozRequestFullScreen) {
            elem.mozRequestFullScreen();
        } else if (elem.webkitRequestFullScreen) {
            elem.webkitRequestFullScreen(Element.ALLOW_KEYBOARD_INPUT);
        } else if (elem.msRequestFullscreen) {
            elem.msRequestFullscreen();
        }
    } else {
        if (document.cancelFullScreen) {
            document.cancelFullScreen();
        } else if (document.mozCancelFullScreen) {
            document.mozCancelFullScreen();
        } else if (document.webkitCancelFullScreen) {
            document.webkitCancelFullScreen();
        } else if (document.msExitFullscreen) {
            document.msExitFullscreen();
        }
    }
}

export const copyToClipboard = str => {
    const el = document.createElement('textarea');
    el.value = str;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
};

export const getAveragePositionFromList = (list) => {
    if( list.length ) {
        const x = list.map(p => p.x).reduce((a, b) => a + b, 0) / list.length
        const y = list.map(p => p.y).reduce((a, b) => a + b, 0) / list.length
        return {
            x: Math.round(x*1000)/1000,
            y: Math.round(y*1000)/1000,
        }
    }
    return {x: 0, y: 0}
}

export function getDistance (p1, p2) {
    const a = p1.x - p2.x;
    const b = p1.y - p2.y;

    return Math.sqrt(a * a + b * b);
}

//this function returns the derivative of the array of points
export const getDerivative = (points) => {
    let derivative = [];
    for (let i = 0; i < points.length - 1; i++) {

        //getDistance(points[i], points[i + 1])

        derivative.push({
            y: points[i + 1].y - points[i].y
        })
    }
    return derivative;
}

export function getRowsFilterList(print, bomFilter, bomSearch, bomOrderList) {
    let rows = [];
    let designators = [];

    if( print ) {
        rows = print.bom.filter(r => {
            return filterBom(r, null, bomFilter, bomSearch, bomOrderList)
        }).map((r, ri) => {
            return ({r, ri})
        })

        designators = print.bom.map((r, ri) => {
            return r.designators.filter((d) => {
                return filterBom(r, d, bomFilter, bomSearch, bomOrderList)
            }).map((d) => ({r, ri, d}))
        })

        designators = designators.flat()
    }

    return {
        r: rows.map(v => v.r.id),
        d: designators.map(v => v.d.id)
    }
}

export function getRows(print, expandDesignators, bomFilterList, bomFilter, bomSearch) {
    let rows = [];

    if( print ) {
        rows = print.bom.map((r, ri) => {

            if (expandDesignators) {
                return r.designators.map((d) => ({r, ri, d}))
            } else {
                return ({r, ri})
            }

        })

        if( expandDesignators ) {
            rows = rows.flat()
        }
    }

    if( expandDesignators ) {
        if (bomFilter || bomSearch) {
            rows = rows.filter(m => bomFilterList.d.includes(m.d.id))
        }
    } else {
        if (bomFilter || bomSearch) {
            rows = rows.filter(m => {
                return bomFilterList.r.includes(m.r.id) ||
                    m.r.designators.some(d => bomFilterList.d.includes(d.id) )
            })
        }
    }

    return {rows}
}

export const filterBom = (r,d, bomFilter, bomSearch, bomOrderList) => {

    let filter = true;

    if( bomFilter != null && bomFilter !== '' ) {

        filter = false;

        if (d && bomFilter === 'top') {
            filter = d.topBottom === 'TOP'
        }
        else if (d && bomFilter === 'bottom') {
            filter = d.topBottom === 'BOTTOM'
        }
        else if (bomFilter === 'marked') {
            filter = r.marked;
        }
        else if (bomFilter === 'notmarked') {
            filter = !r.marked;
        }
        else if (bomFilter === 'nopart') {
            filter = r.place && r.selectedSupplierPart == null && r.selectedBuddyPart == null && r.klantLevertAan == null;
        }
        else if (bomFilter === 'nostock') {
            filter = r.place && r.selectedSupplierPart != null && r.selectedSupplierPart.stock === 0;
        }
        else if (bomFilter.includes('supplier-CUSTOMER') || bomFilter.includes('supplier-KLANT')) {
            filter = r.klantLevertAan != null;
        }
        else if (bomFilter.includes('supplier-DELTAPROTO')) {
            filter = r.selectedBuddyPart != null;
        }
        else if (bomFilter.includes('supplier-')) {
            filter = r.selectedSupplierPart != null && r.selectedSupplierPart.supplier === bomFilter.replaceAll('supplier-', '');
        }
        else if (bomFilter === 'nocheckin') {
            let ordersForRow = getOrderItemsByRow(r, bomOrderList);
            if( ordersForRow && ordersForRow.length > 0 ) {
                filter = ordersForRow.some(o => {
                    return o.boardLocation == null || o.boardLocation === ''
                });
            } else {
                filter = false;
            }
        }
        else if (bomFilter === 'noorder') {
            let ordersForRow = getOrderItemsByRow(r, bomOrderList);
            if( ordersForRow && ordersForRow.length > 0 ) {
                filter = false;
            } else {
                filter = true;
            }
        }
    }

    if(bomSearch != null && bomSearch !== '') {
        let search = false

        if( r.selectedSupplierPart != null ) {
            search = r.selectedSupplierPart.sku.toLowerCase().includes(bomSearch.toLowerCase())
        }
        if( !search && r.selectedOrderPart != null ) {
            search = r.selectedOrderPart.mpn.toLowerCase().includes(bomSearch.toLowerCase())
        }
        if( !search && d ) {
            search = d.value.toLowerCase().includes(bomSearch.toLowerCase())
        }

        return search && filter;
    }

    return filter;
}

export function getClassNameForRow(r, selectedRow) {
    let className = 'bst-row';
    let style = {}

    if( selectedRow && r.id === selectedRow.id ) {
        if( selectedRow.fade ) {
            className += ' unselected'
        } else {
            className += ' selected'
        }
    }

    return {className, style};
}

export function getDesignatorColor(r, d, selectedDesignator, lastGoToDesignator, bomProduction) {
    let color = null;

    if( !d.place ) {
        color = '#D12A2F';
        return color;
    }
    else if( bomProduction && bomProduction.lines && bomProduction.lines.some(p => p.designator === d.value) ) {
        color = '#38A700';
    }
    else if( r && r.selectedBuddyPart != null ) {
        color = '#99498E';
    }
    else if( lastGoToDesignator && d.id === lastGoToDesignator.id ) {
        color = '#f9be38'
    }

    if( selectedDesignator && d.id === selectedDesignator.id ) {
        color = '#2e84cc';
    }

    return color;
}

export function getClassName(r, d, selectedDesignator, lastGoToDesignator, bomProduction) {
    let className = 'bst-prod-row';

    if( !d.place ) {
        className += ' dnp';
    }
    else if( bomProduction && bomProduction.lines && bomProduction.lines.some(p => p.designator === d.value) ) {
        className += ' done';
    }
    else if( r && r.selectedBuddyPart != null ) {
        className += ' buddy';
    }
    else if( lastGoToDesignator && d.id === lastGoToDesignator.id ) {
        className += ' busy';
    }

    if( selectedDesignator && d.id === selectedDesignator.id ) {
        className += ' selected';
    }

    return className;
}

export const fetchAllBomData = (bomId, projectId, viewId, dispatch) => {
    dispatch(openBom(bomId, projectId, viewId, (value) => {
        dispatch(getBomOrders(value.key.id))
        dispatch(getBomProduction(value.key.id))
        dispatch(getLastUsedBoardLocations(value.key.id));

        if( value.key.uplacerOwner != null ) {

            dispatch(getChatForPrint(value.key.id))

        } else if( value.key.projectOrder != null ) {
            dispatch(getChatForProjectOrder(value.key.projectOrder.id))
        }
    }))
}

export function getSelectedSuppliers(print) {
    if( print ) {
        return print.bom.filter(r => {
            return r.selectedSupplierPart || r.selectedBuddyPart != null || r.klantLevertAan != null
        }).map(r => {
            if( r.selectedBuddyPart != null ) {
                return 'DELTAPROTO'
            } else if( r.klantLevertAan != null ) {
                return 'KLANT'
            }
            return r.selectedSupplierPart.supplier;
        }).filter(onlyUnique)
    }
    return []
}

function onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
}