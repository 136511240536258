import React from "react";
import {Icon} from "semantic-ui-react";

const browser = (function (agent) {
    switch (true) {
        case agent.indexOf("edge") > -1: return "edge";
        case agent.indexOf("edg/") > -1: return "chromium based edge (dev or canary)"; // Match also / to avoid matching for the older Edge
        case agent.indexOf("opr") > -1 && !!window.opr: return "opera";
        case agent.indexOf("chrome") > -1 && !!window.chrome: return "chrome";
        case agent.indexOf("trident") > -1: return "ie";
        case agent.indexOf("firefox") > -1: return "firefox";
        case agent.indexOf("safari") > -1: return "safari";
        default: return "other";
    }
})(window.navigator.userAgent.toLowerCase());

export default function BrowserWarning() {

    if(browser === "ie" || browser === "edge") {
        return (
            <div className="browser-warning">
                <p>
                    <Icon name='warning' size='large' color='yellow' />
                    Your browser is not supported. Please use a modern browser like{" "}
                    <a href="https://www.google.com/chrome/">Google Chrome</a> or{" "}
                    <a href="https://www.mozilla.org/en-US/firefox/new/">Mozilla Firefox</a>.
                </p>
            </div>
        );
    }

    return null;
}