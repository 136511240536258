import React, {Component} from 'react';
import {
    Header,
    Icon, Loader,
    Table, Button, Label, Message
} from "semantic-ui-react";

import {
    collectparams,
    fetchData, formatDateTime,
    handleErrors, isEmployee,
} from "../actions/functions";

import 'moment/locale/nl.js'
import connect from "react-redux/es/connect/connect";
import PlanningEntry from "./PlanningEntry";
import {getProjectPlanning, setProjectParkedStatus, updateProjectPlanning} from "../actions";
import Translate, {getText} from "../Translate";
import {hideFooter, toggleFullScreen} from "../bom/functions";
import {getNextAssemblyDate} from "./PlanningEntryProject";
import {Link} from "react-router-dom";

var moment = require('moment-business-days');
moment.locale('nl');

/*Date.prototype.addDays = function(days) {
    var date = new Date(this.valueOf());
    date.setDate(date.getDate() + days);
    return date;
}

Date.prototype.addWorkDays = function(days) {
    var date = new Date(this.valueOf());

    let momentDate= moment.unix( date.getTime() / 1000 )

    return momentDate.businessAdd(days)._d
}*/

function addDays(date, days) {
    var d = new Date(date);
    d.setDate(d.getDate() + days);
    return d;
}

function addWorkDays(date, days) {
    var d = new Date(date);
    let momentDate= moment.unix( d.getTime() / 1000 )
    return momentDate.businessAdd(days)._d
}

function sameDay(d1, d2) {
    return d1.getFullYear() === d2.getFullYear() &&
        d1.getMonth() === d2.getMonth() &&
        d1.getDate() === d2.getDate();
}

function before(d1, d2) {

    let d1moment = moment.unix( d1.getTime() / 1000 )
    let d2moment = moment.unix( d2.getTime() / 1000 )

    return d1moment.isBefore(d2moment, 'day');
}

class Dashboard extends Component {

    state= {
        value: [],
        fetching: false,
        list: [],
        lang: 'nl',
        isOver: null
    }

    componentDidMount() {

        let lang= collectparams('lang')

        this.setState({lang: lang ? lang : 'nl'})

        this.validateLogin();
        this.fetchProjects();

        setTimeout(() => {
            this.fetchProjects();
        }, 60000 * 15);

        hideFooter();
    }

    /*fetchOverview() {

        this.setState({fetching: true})

        fetchData( '/api/project/getPlanning' )
            .then(response => handleErrors(response).json())
            .then(json => {
                this.setState({value: json, fetching: false})
            })
            .catch(error => {
                this.setState({fetching: false})
                console.log(error)
            })

    }*/

    fetchProjects() {

        this.setState({fetching: true})

        this.props.dispatch(getProjectPlanning(() => {
            this.setState({fetching: false})
        }))

        /*fetchData( '/api/project/getProjectPlanning' )
            .then(response => handleErrors(response).json())
            .then(json => {
                this.setState({list: json, fetching: false})
            })
            .catch(error => {
                this.setState({fetching: false})
                console.log(error)
            })*/

    }

    validateLogin() {

        fetchData( '/api/user/validate' )
            .then(response => handleErrors(response).json())
            .then(json => {
                console.log(json)
                this.setState({user: json, showAll: !isEmployee(json)});
                /*if( isAdmin(json) ) {
                    let d = localStorage.getItem('deltaprotoshowhide');

                    if( d != null && d !== "null" ) {
                        let val= JSON.parse(d);
                        if(val != null && val) {
                            this.setState({showhide: val})
                        }
                    }
                }*/
            })
            .catch(error => {
                console.log(error)
            })

    }

    renderMinuuten = (header, d, fieldName, projectFieldDateName, statusFieldName, date, list, notDoneStatusList) => {

        let value= 0;
        let text= null;
        let icon= null;
        let color= null;
        let projectlist= [];
        let projectlistDelivery= [];

        let isWorkingday= moment.unix( date.getTime() / 1000 ).isBusinessDay();
        let isToday= moment.unix( date.getTime() / 1000 ).isSame(moment(), 'day');
        let isLate= moment.unix( date.getTime() / 1000 ).isBefore(moment(), 'day');

        let isOver = this.state.isOver === date.getTime() && this.state.fieldName === fieldName

        let isSample = fieldName === 'productieFirst';

        if( fieldName === 'checkin' && d !== -2 ) {
            color = isLate ? 'red' : null;
        }

        if( list ) {

            list.forEach((p) => {

                let notDone = notDoneStatusList.includes( p[statusFieldName] );
                let pDate = new Date(p[projectFieldDateName]);

                if( d === -2 ) {
                    //The 'parked' project list

                    if( p[projectFieldDateName] && notDone && p.parked ) {
                        let x= p[fieldName];
                        let text = p[statusFieldName+'Text']

                        projectlist.push({...p, minutes : x, project: p, date: pDate, statusFieldName, text, color, bomId: p.bomId, projectFieldDateName})

                        value = value + (x ? x : 0);
                    }

                } else {


                    if( !p.parked && p[projectFieldDateName] && notDone ) {
                        if (pDate) {

                            if (sameDay(date, pDate) || (isLate && before(pDate, date))) {

                                let x= p[fieldName];
                                let text = p[statusFieldName+'Text']

                                let nextAssemblyDate = null;
                                if( fieldName === 'checkin' ) {
                                    nextAssemblyDate = getNextAssemblyDate(p);
                                    if (moment(nextAssemblyDate).isSame(date, 'day')) {
                                        color = 'yellow'
                                    } else if (moment(nextAssemblyDate).isBefore(date, 'day')) {
                                        color = 'red'
                                    }
                                }

                                projectlist.push({...p, minutes : x, project: p, date: pDate, statusFieldName, text, color, bomId: p.bomId, projectFieldDateName, isLate, nextAssemblyDate})

                                value = value + (x ? x : 0);
                            }
                        }
                    }
                }

            })

        }

        return <Table.Cell key={d+'-'+ date.getTime()} id={d+'-'+ date.getTime()} style={{
                textAlign: 'left',
                verticalAlign: 'top',
                padding: 3,
                backgroundColor: isOver ? '#F7F4D5' : isWorkingday ? isSample ? '#add8e63d' : '' : '#e7e7e7'}}
                           onDragOver={(e) => this.handleDragOver(e, date.getTime(), fieldName)}
                           onDrop={(e) => this.handleDrop(e,date.getTime(), d === -2, projectFieldDateName)}
                           onDragLeave={(e) => this.handleDragLeave(e, date.getTime())}
        >

            <div

            >
                <PlanningEntry header={header} minutes={value} projectlist={projectlist}/>
            </div>
        </Table.Cell>
    }


    handleDragOver = (e, date, fieldName) => {

        if (e.dataTransfer.types[0] === "text/plain") {
            //console.log('handleDragOver ' + date)
            this.setState({isOver: date, fieldName})
            e.preventDefault();
        }
    }

    handleDrop = (e, date, parked, projectFieldDateName) => {
        const dataJSON = e.dataTransfer.getData("text/plain");
        //console.log('handleDrop ' + moment(date).format() + ' ' + dataJSON)
        let data;
        this.setState({isOver: null})
        try {
            data = JSON.parse(dataJSON);
        } catch {}

        console.log('data', data, parked, projectFieldDateName)

        if (data && data.type === "card" && data.projectFieldDateName === projectFieldDateName) {
            //moveCard();
            //console.log('movePlanning ' + moment(date).format() + ' ' + JSON.stringify(data))

            if( parked ) {

                this.props.dispatch(updateProjectPlanning(Object.assign({}, data.project, {
                    parked: (moment(date).utc().unix() * 1000)
                })))

            } else {
                let value = moment(date).utc().unix() * 1000
                //console.log('value', value);
                this.props.dispatch(updateProjectPlanning(Object.assign({}, data.project, {
                    [data.projectFieldDateName]: value,
                    parked: null
                })))
            }

        }
    }

    handleDragLeave = (e, date) => {
        this.setState({isOver: null})
    }

    renderPlanningWithError = (list) => {

        if( list.length === 0 ) {
            return null;
        }

        return <div style={{padding: 16}}>
            <Message error>
                <Message.Header>Let op!</Message.Header>
                <Message.Content>
                    <div>
                        {list.map((p, i) => {
                            return <div key={i} style={{display: 'flex', gap: 8}}>
                                <div>
                                    <Link to={'/project/' + p.id + '/'}><b>{p.name}</b></Link>
                                </div>
                                <div>
                                    {p.error}
                                </div>
                            </div>
                        })}
                    </div>

                </Message.Content>
            </Message>
        </div>
    }

    render() {

        let days= [-2,null,-1,0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19]

        let list= this.props.planning;

        return (
            <main id={'dashboard'} className={'pageContainerFullWidth'}>
            <div style={{textAlign: 'left', paddingLeft: '', width: '100%', paddingBottom: 64}}>

                <div style={{display: 'flex', gap: 16}}>
                    <Header>
                        <Translate value={'menu_admin_planning'}/>
                    </Header>
                    <div style={{marginTop: 4}}>
                        <Translate value='planning_last_picked_up_on'/> {formatDateTime(this.props.planningRefreshDate)}
                    </div>
                    <div style={{marginTop: 4}}>
                        {this.state.fetching ? <Loader active inline/> : ''}
                    </div>
                    <div style={{flex: 1, textAlign: 'right'}}>
                        <Button icon onClick={() => {
                            toggleFullScreen(document.body)
                        }}>
                            <Icon name='expand'/>
                        </Button>
                    </div>
                </div>

                <div>
                    {this.renderPlanningWithError(list.filter(p => p.error))}
                </div>

                <div style={{overflow: 'auto', maxHeight: 'calc(100vh - 100px)'}}>
                    <div style={{width: 3200}}>


                        <Table fixed celled className="tableRowFixedHeader tableFixedBorders" style={{borderLeft: '1px solid #ddd', borderTop: 'none'}}>
                            <Table.Header>
                                <Table.Row>
                                    {days.map((d,i) => {

                                        if( d === null ) {
                                            return <Table.HeaderCell key={i} style={{width: 40, background: 'whitesmoke', border: 'none'}}>
                                                <div>

                                                </div>
                                            </Table.HeaderCell>
                                        }
                                        var date = new Date();
                                        date= addDays(date, d);

                                        let isToday= moment.unix( date.getTime() / 1000 ).isSame(moment(), 'day');
                                        let isWorkingday= moment.unix( date.getTime() / 1000 ).isBusinessDay();
                                        let isLate= moment.unix( date.getTime() / 1000 ).isBefore(moment(), 'day');

                                        let isParked = d === -2;

                                        return <Table.HeaderCell key={i} style={{textAlign: 'center', width: (isLate || isWorkingday) ? 280 : 40}}>
                                            {isParked ? <div>
                                                Geparkeerd
                                            </div> : isLate ? <div>
                                                Aandacht vereist
                                                <div style={{fontWeight: 300}}>Verplaats naar een datum in de toekomst, of parkeer het project</div>
                                            </div> : <>
                                            {moment(date).format(isWorkingday ? "dd D-MM" : "dd")}
                                                {isToday ? <div style={{paddingTop: 4}}>
                                                    <Label color={'blue'} key={'blue'}>
                                                        Vandaag
                                                    </Label>
                                                </div> : ''}
                                            </>}
                                        </Table.HeaderCell>
                                    })}
                                </Table.Row>
                            </Table.Header>

                            <Table.Body>
                                {/*<Table.Row>
                                    {days.map((d,i) => {
                                        var date = new Date();
                                        date= addDays(date, d);

                                        return this.renderMinuuten('Analyse', i, 'analyse', 'planningAnalyse', 'orderStatus',  date, list, ['inactive', 'notstarted', 'active'])
                                    })}
                                </Table.Row>*/}

                                {/*<Table.Row>
                                    {days.map((d,i) => {
                                        var date = new Date();
                                        date= addDays(date, d);

                                        return this.renderMinuuten('Buying', i, 'inkoop', 'planningInkoop', 'buyStatus',  date, list, ['inactive', 'notstarted', 'purchase'])
                                    })}
                                </Table.Row>*/}

                                <Table.Row>
                                    {days.map((d,i) => {
                                        if( d === null ) {
                                            return <Table.Cell key={i} style={{background: 'whitesmoke', border: 'none'}}>
                                            </Table.Cell>
                                        }

                                        var date = new Date();
                                        date.setHours(0, 0, 0, 0);
                                        date= addDays(date, d);

                                        return this.renderMinuuten('Analyse',d, 'analyse', 'planningAnalyse', 'analysisStatus',  date, list, ['inactive', 'notstarted', 'active'])
                                    })}
                                </Table.Row>

                                <Table.Row>
                                    {days.map((d,i) => {
                                        if( d === null ) {
                                            return <Table.Cell key={i} style={{background: 'whitesmoke', border: 'none'}}>
                                            </Table.Cell>
                                        }

                                        var date = new Date();
                                        date.setHours(0, 0, 0, 0);
                                        date= addDays(date, d);

                                        return this.renderMinuuten('Buy parts',d, 'inkoop', 'planningInkoop', 'buyStatus',  date, list, ['inactive', 'notstarted', 'purchase'])
                                    })}
                                </Table.Row>

                                <Table.Row>
                                    {days.map((d,i) => {
                                        if( d === null ) {
                                            return <Table.Cell key={i} style={{background: 'whitesmoke', border: 'none'}}>
                                            </Table.Cell>
                                        }

                                        var date = new Date();
                                        date.setHours(0, 0, 0, 0);
                                        date= addDays(date, d);

                                        return this.renderMinuuten('Check-In',d, 'checkin', 'planningCheckIn', 'checkinStatus',  date, list, ['inactive', 'notstarted', 'purchase', 'checkin'])
                                    })}
                                </Table.Row>

                                <Table.Row>
                                    {days.map((d,i) => {
                                        if( d === null ) {
                                            return <Table.Cell key={i} style={{background: 'whitesmoke', border: 'none'}}>
                                            </Table.Cell>
                                        }

                                        var date = new Date();
                                        date.setHours(0, 0, 0, 0);
                                        date= addDays(date, d);

                                        return this.renderMinuuten('Make Sample', d, 'productieFirst', 'planningProductieFirst', 'sampleStatus',  date, list, ['inactive', 'notstarted', 'purchase', 'checkin', 'active'])
                                    })}
                                </Table.Row>

                                <Table.Row>
                                    {days.map((d,i) => {
                                        if( d === null ) {
                                            return <Table.Cell key={i} style={{background: 'whitesmoke', border: 'none'}}>
                                            </Table.Cell>
                                        }

                                        var date = new Date();
                                        date.setHours(0, 0, 0, 0);
                                        date= addDays(date, d);

                                        return this.renderMinuuten('Make Serie', d, 'productieSerie', 'planningProductieSerie', 'seriesStatus',  date, list, ['inactive', 'notstarted', 'purchase', 'checkin', 'active', 'partready', 'partshipped'])
                                    })}
                                </Table.Row>
                            </Table.Body>
                        </Table>
                    </div>
                </div>

            </div>
            </main>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        lang: state.main.lang,
        planning: state.project.planning,
        planningRefreshDate: state.project.planningRefreshDate,
    }
}

export default connect(mapStateToProps)(Dashboard)
