
export function downloadFile(filename, content) {
    var element = document.createElement('a');
    element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(content));
    element.setAttribute('download', filename);

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
}

export function truncate(str, n){
    return (str.length > n) ? str.slice(0, n-1) + '...' : str;
};

export const pcbWay = {
    "cxipt": "",
    "cxiptselectiveGold": "",
    "cxiptselectiveHold": "",
    "cxiptselectiveGoldHold": "",
    "txtUserid": "10895",
    "tmpdaysfor": "0",
    "txtFactoryId": "1",
    "gerberFileUrl": "",
    "gerberFileNm": "",
    "gerberImg": "",
    "radBoardType": "Single PCB",
    "hidEdgeRails": "Yes",
    "txtRailsContent": "",
    "hidRouteProcess": "--",
    "txtPinBanNum": "1",
    "intselPinBanNum": "",
    "hidLength": "100",
    "hidWidth": "100",
    "hidNum": "5",
    "txtSelNum": "",
    "hidLayers": "2",
    "hidCopperlayer": "--",
    "hidSoldermask": "--",
    "hidSilkscreenLegend": "--",
    "FR4Type": "FR-4",
    "hidRogers": "Rogers4003C",
    "hidTCE": "1.0",
    "hidStructure": "middle",
    "hidFR4TG": "TG150",
    "iptOtherBoardThickness": "",
    "radBoardThickness": "1.6",
    "radBoardThicknessRogersC": "",
    "radBoardThicknessRogersB": "",
    "radLineWeight": "6/6mil",
    "radVias": "0.3",
    "radSolderColor": "Green",
    "radFontColor": "White",
    "radGoldfingers": "No",
    "hidChamferedborder": "No",
    "radGoldPlatingType": "Hard Gold",
    "radGoldFingersThickness": "1",
    "radAUGoldThickness": "10",
    "radNiGoldThickness": "120",
    "radPdAUGoldThickness": "2",
    "radPdNiGoldThickness": "200",
    "radPdPdGoldThickness": "2",
    "radPlatingType": "Immersion gold",
    "radSendPdAUGoldThickness": "2",
    "radSendPdNiGoldThickness": "200",
    "radSendPdPdGoldThickness": "2",
    "radSendAUGoldThickness": "5",
    "radSendNiGoldThickness": "120",
    "radGoldThickness": "1",
    "radGoldThicknessSelective": "1",
    "radAuHoldSelective": "10",
    "radNiHoldSelective": "120",
    "radGoldHoldSelective": "1",
    "radAuGoldHoldSelective": "10",
    "radNiGoldHoldSelective": "120",
    "radSolderCover": "Tenting vias",
    "radCopperThickness": "1 oz Cu",
    "radInsideThickness": "1",
    "radRemoveProNo": "No",
    "hidPeelableSoldermask": "None",
    "hidHoleCopperThickness": "None",
    "hidULMaker": "None",
    "hidDataCode": "1",
    "txtDataCodeDes": "",
    "txtPCBNote": "",
    "PONumber": "DEPR PR01",
    "chkSMDStencil": true,
    "radStencilType": "Non-framework",
    "radStepStencil": "No",
    "radStencilId": "190×290mm",
    "hidStencilId": "9",
    "radStencilSide": "Top Side",
    "iptStencilNum": "1",
    "radStencilThickness": "0.1",
    "radExistingfiducials": "none",
    "radElectropolishing": "No",
    "StencilPONumber": "DEPR PR99",
    "txtStencilNote": "",
    "partVias": "1",
    "boardType": "1",
    "hidAssemblySide": "3",
    "txtBoardNum": "",
    "hidICSensitive": "No",
    "txtICSensitiveNote": "",
    "hidICLocalIC": "No",
    "SmtPONumber": "",
    "txtICType": "",
    "txtPadsNum": "",
    "txtBGA": "",
    "txtHolesNum": "",
    "DepanelBoards": "No",
    "FunctionTest": "No",
    "ConformalCoating": "No",
    "FirmwareLoading": "No",
    "PressfitParts": "No",
    "BoxBuildAssembly": "No",
    "CableWireHarnessAssembly": "No",
    "SMTCustompackaging": "No",
    "XrayTest": "",
    "txtSmtNote": "",
    "selShipCountry": "152",
    "selShip": "1",
    "nowdate": "2023-11-01",
    "hidStenciltotal": "10.00",
    "hidSMTtotal": "0",
    "hidDaySMT": "",
    "hidDeliveryDaysSMT": "",
    "hidSMTQty": "",
    "hidMoney": "46.21",
    "hidTmpNO": "",
    "hidDay": "3-4 days",
    "hidShipMoney": "35.66",
    "hidShipName": "DHL",
    "hidCountryId": "",
    "hidWebSaveMoney": "",
    "logFlag": "1",
    "saveact": "save",
    "hidDeliveryDays": "72",
    "hidisjiaji": "",
    "VolumeWeight": "Volume",
    "HopeCashTxt": "",
    "HopeTimeTxt": "",
    "pagetype": "1",
    "seqtype": "0",
    "hiduserNo": "W10895Y",
    "txtMail": "",
    "tmpShipCountry": "152",
    "tmpShipCountryPic": "153",
    "tmpShipCountryName": "NETHERLANDS, THE",
    "tmpselShip": "1",
    "tmpselShipName": "DHL",
    "visitorGuid": "7e97a478-9a88-4fa1-b8e02030591068834",
    "pagefrom": "quickorderonline",
    "pcbfrom": "",
    "Projectid": "0",
    "filename": "",
    "CentroidFileName": "",
    "CentroidFilePath": "",
    "BomFileName": "",
    "BomFilePath": "",
    "SupplyFileName": "",
    "SupplyFilePath": "",
    "smtProjectid": "",
    "hidLengthAuto": "",
    "hidWidthAuto": "",
    "hidLayersAuto": "",
    "radLineWeightAuto": "",
    "radViasAuto": ""
}
