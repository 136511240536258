import React, { useState, createRef, useRef, useEffect } from 'react'

const useContainerDimensions = myRef => {
    const getDimensions = () => ({
        width: myRef.current.offsetWidth,
        height: myRef.current.offsetHeight
    })

    const [dimensions, setDimensions] = useState({ width: 0, height: 0 })

    useEffect(() => {
        const handleResize = () => {
            setDimensions(getDimensions())
        }

        if (myRef.current) {
            setDimensions(getDimensions())
        }

        window.addEventListener("resize", handleResize)

        return () => {
            window.removeEventListener("resize", handleResize)
        }
    }, [myRef])

    return dimensions;
};

export const MaintainAspectRatio = (props) => {
    const componentRef = useRef()
    const { width, height } = useContainerDimensions(componentRef)

    let set_height = ((width/16)*9)

    return (
        <div ref={componentRef} style={{height: set_height}}>
            {/*<div style={{position: 'relative', top: 0, left: 0, zIndex: 999}}>
                <p>width: {width}px</p>
                <p>height: {height}px</p>
            </div>*/}
            {props.children}
        </div>
    )
}