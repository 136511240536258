import React from 'react';
import {Button, Dropdown, Icon, Loader, Popup} from "semantic-ui-react";
import {autoSelectBomLine, openBomSearch, setSelectedBomRow} from "../actions";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import BomRemarkEdit from "./BomRemarkEdit";
import BomSelectedHistory from "./BomSelectedHistory";
import BomSearchFromOrders from "./BomSearchFromOrders";

class BomSearchPart extends React.Component {

    state = {
        value: null,
        loading: false
    }

    render() {

        let id = this.props.id;
        const locked = this.props.locked;
        let val = this.state.value;

        const admin = this.props.isAdmin;
        const microplacer = this.props.isMicroplacer;

        let content = this.state.value != null ? this.state.value : this.props.remark != null ? this.props.remark : this.props.alert

        let bigButton = !content && !admin;

        return (
            <div style={{margin: 10, display: 'flex'}}>

                {admin ? <BomRemarkEdit value={content} bomLineId={this.props.id}/> : <div style={{flex: 1}}>{content}</div>}

                {!admin ? <Button floated='right' primary loading={this.props.busy} disabled={locked}
                         onClick={() => {
                             this.props.dispatch(setSelectedBomRow(id))
                             this.props.dispatch(openBomSearch(id, ''))
                         }}
                         icon labelPosition={'left'}
                >
                    <Icon name={'search'}/>
                    Search part
                </Button> : <Button floated='right' primary loading={admin ? false : this.props.busy} disabled={locked}
                                    onClick={() => {
                                        this.props.dispatch(setSelectedBomRow(id))
                                        this.props.dispatch(openBomSearch(id, ''))
                                    }}
                                    icon='search'
                />}

                {(admin || microplacer) ? <Popup trigger={<Button floated='right' loading={this.props.busy} disabled={locked || this.props.validatingBom || this.props.busy}
                                 onClick={() => {
                                     this.props.dispatch(autoSelectBomLine(this.props.print.id, this.props.id))
                                 }}
                                 icon='flag checkered'
                    />} content={<div>
                        <div>Automatisch selecteren</div>
                        {this.props.validatingBom ? <div>Waiting for analysis <Loader active inline size='mini'/></div> : ''}
                    </div>} /> : ''}

                {(admin) ? <BomSearchFromOrders id={this.props.id}/> : null}



            </div>
        );
    }

}

BomSearchPart.propTypes = {
    id: PropTypes.string.isRequired,
    remark: PropTypes.string,
    defaultValue: PropTypes.string,
    isAdmin: PropTypes.bool,
    isMicroplacer: PropTypes.bool,
    locked: PropTypes.bool,
};

BomSearchPart.defaultProps = {
    defaultValue: '',
    remark: null,
    isAdmin: false,
    isMicroplacer: false,
    locked: false,
};

const mapStateToProps = (state) => {
    return {
        currentUser: state.main.currentUser,
        print: state.bom.print,
        validatingBom: state.bom.validatingBom
    }
}

export default connect(mapStateToProps)(BomSearchPart)