import React, {lazy} from 'react';
import {getText} from "./Translate";
import {connect} from "react-redux";
import ReactTextTransition, { presets } from "react-text-transition";
import {Input, Message} from "semantic-ui-react";
import {regsiterForNewsletter, setMohicanUrl} from "./actions";

const MarkdownRender = lazy(() => import('./MarkdownRender'));

const texts= ["Netherlands", "Solar System", "Galaxy", "Universe", "Multiverse" ];

class TextTransition extends React.Component {
    state = {
        textIndex: 0,
    };

    componentDidMount() {
        setInterval(() => {
            this.setState({
                textIndex: this.state.textIndex + 1,
                show: false
            });
        }, 2000);
    }

    render() {
        return (
                <ReactTextTransition
                    text={texts[this.state.textIndex % texts.length]}
                    spring={presets.gentle}
                    inline
                />
        );
    }
}

class MicroPlacer extends React.Component {

    state = {
        email: ''
    }

    renderSignup() {
        return <div>

            <div style={{maxWidth: 400, padding: '2em', margin: 'auto'}}>
                <p>
                    Sign up for our free newsletter and we will be happy to keep you updated!
                </p>

                <Input action={{
                    content: 'Subscribe',
                    color: 'red',
                    disabled: !this.state.email,
                    loading: this.state.loading,
                    onClick: () => {
                        this.setState({loading: true})
                        this.props.dispatch(regsiterForNewsletter(this.state.email, () => {
                            this.setState({email: '', thankYou: true, loading: false})
                        }))
                    }
                }} placeholder='Email address' onChange={ (e) => {
                    this.setState({email: e.target.value})
                }} onKeyDown={(e) => {
                    if( e.key === 'Enter' ) {
                        this.setState({loading: true})
                        this.props.dispatch(regsiterForNewsletter(this.state.email, () => {
                            this.setState({email: '', thankYou: true, loading: false})
                        }))
                    }
                }}
                       fluid
                value={this.state.email} type="email"/>

                {this.state.thankYou ? <Message positive>
                    <Message.Content>
                        Thanks for subscribing!
                    </Message.Content>
                </Message> : ''}
            </div>
        </div>
    }

    render() {

        let body= getText(this.props, 'machines.microplacer.page');

        return (
            <main id={'microplacer'} className = {'pageContainer'} style={{textAlign: 'left', backgroundColor: 'white'}}>

                {/*<ReactMarkdown>
                    {body ? body : ''} //TODO: Move to new version of react-markdown!
                </ReactMarkdown>*/}

                {this.renderSignup()}

                {body ? <MarkdownRender children={body}/> : null}

                {this.renderSignup()}

            </main>
        );
    }
}


MicroPlacer.propTypes = {
};

MicroPlacer.defaultProps = {
};

const mapStateToProps = (state) => {
    return {
        lang: state.main.lang,
        currentLang: state.main.currentLang,
    }
}

export default connect(mapStateToProps)(MicroPlacer)