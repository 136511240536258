import React from 'react'
import ImageCapture from "./ImageCapture";

class DeltaSerial extends React.Component {

    state = {

    }
    componentDidMount() {
        if ("serial" in navigator) {
            // The Web Serial API is supported.
            console.log("Web Serial API is supported")
            this.setState({serialSupported: true})
        } else {
            // The Web Serial API is not supported.
            console.log("Web Serial API is not supported")
            this.setState({serialSupported: false})
        }

        const func = async () => {
            const ports = await navigator.serial.requestPort();
            console.log(ports)
        }

        func()
    }

    render() {
        const {serialSupported} = this.state;

        return <main id={'test'} className={'pageContainer'} style={{textAlign: 'left'}}>
            { serialSupported ? <div>Serial is supported</div> : <div>Serial is not supported</div> }

            <ImageCapture/>
        </main>
    }
}

export default DeltaSerial