import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import './App.css';
import connect from "react-redux/es/connect/connect";
import {
    changeUser, changeUserModalOpen, fetchUsers,
    getUserChangeOptions,
    openLoginSignup,
    setLangauge, setUserIdleTimeout,
    setUserLanguage,
    toggleRenderAsUser, validateLogin
} from "./actions";
import SecondaryNavigationBar from './NavigationBarSecondary';

import {
    withRouter
} from 'react-router-dom'
import {Button, Card, Dropdown, Icon, Modal, Popup} from "semantic-ui-react";
import Translate from "./Translate";
import NavigationBarMessage from "./NavigationBarMessage";
import {collectparams, isAdmin, isEmployee} from "./actions/functions";

export const langOptions = [{text: 'Deutsch', key: 'de'}, {text: 'English', key: 'en'}, {text: 'Français', key: 'fr'}, {text: 'Nederlands', key: 'nl'}]

class NavToggle extends Component{
    constructor(props){
        super(props);
        this.state = {
            active: false
        };
    }
    render() {
        return (
            <div id={'navToggle'} onClick={this.props.onClick}>
                <Icon name={'bars'} color={'white'} inverted size={'big'} style={{marginTop: 8 }} link/>
            </div>
        );
    }
}

NavToggle.propTypes = {
    onClick: PropTypes.func,
    visible: PropTypes.bool
}

class MenuItem extends Component {
    render() {
        return (
            <div className={this.props.className} onClick={this.props.onClick}>
                {this.props.linkTo ? <Link to={this.props.linkTo} className={'link'}>{this.props.itemText}</Link> :
                    <div className={'link'}>{this.props.itemText}</div>}
            </div>
        );
    }
}

MenuItem.propTypes = {
    onClick: PropTypes.func,
    itemText: PropTypes.any,
    className: PropTypes.string,
    linkTo: PropTypes.string
};

MenuItem.defaultProps = {
    itemText: 'Menu Item',
    className: 'menuItem body2',
    linkTo: null
};

class NavigationBar extends Component {
    constructor(props){
        super(props);
        this.state = {
            active: false,
            message: ''
        };
    }
    componentDidMount = () => {
        //let url = new URL(document.location.href);
        //let lang = url.searchParams.get("lang");

        let lang= collectparams('lang')

        if( lang ) {
            lang = lang.toLowerCase();

            if( langOptions.some(o => o.key === lang) ) {
                setTimeout(() => {
                    this.selectLanguage(lang)
                }, 500)

            }
        }
    }
    toggleState(){
        this.setState((state) => {
            return {
                active: !state.active
            }
        });
    }
    toggleStateFalse(){
        this.setState((state) => {
            return {
                active: false
            }
        });
    }
    callbackFunction = (childData) => {
        this.setState({active: childData})
    }
    renderUserMenu(user) {

        if( !user ) {
            return <></>
        }

        let admin= user.roles != null && (user.roles.every((v) => v.name === "ROLE_ADMIN"))
        let employee= user.roles != null && (user.roles.every((v) => v.name === "ROLE_EMPLOYEE"))

        return <div className={'menuItem body2'}>
            {(employee || admin) ? <div>

                <Modal
                    dimmer='inverted'
                    open={this.props.changeUserModalOpen}
                    onClose={() => this.props.dispatch(changeUserModalOpen(false))}
                >
                    <Modal.Header>Hoe heet jij eigenlijk?</Modal.Header>
                    <Modal.Content style={{display: 'grid', gridTemplateColumns: '32% 32% 32%', gap: 16}}>

                        {this.props.userOptions.map(u => (
                            <Button
                                onClick={() => this.props.dispatch(changeUser(u.email))}
                                content={u.firstName}
                                size='huge'
                                style={{}}
                            />
                            ))}

                    </Modal.Content>
                </Modal>

                <Dropdown text={user.firstName} onClick={() => this.props.dispatch(getUserChangeOptions())} scrolling={true}>
                    <Dropdown.Menu >

                        <Dropdown.Item>
                            <Dropdown text='Timeout' direction='left' style={{width: 150}}>
                                <Dropdown.Menu>
                                    <Dropdown.Item icon={this.props.idleTimeout ? '' : 'check'} text='Uit' onClick={() => this.props.dispatch(setUserIdleTimeout(null))} />
                                    <Dropdown.Item icon={this.props.idleTimeout === 1 ? 'check' : ''} text='1 min.' onClick={() => this.props.dispatch(setUserIdleTimeout(1))}/>
                                    <Dropdown.Item icon={this.props.idleTimeout === 5 ? 'check' : ''} text='5 min.' onClick={() => this.props.dispatch(setUserIdleTimeout(5))}/>
                                    <Dropdown.Item icon={this.props.idleTimeout === 10 ? 'check' : ''} text='10 min.' onClick={() => this.props.dispatch(setUserIdleTimeout(10))}/>
                                    <Dropdown.Item icon={this.props.idleTimeout === 15 ? 'check' : ''} text='15 min.' onClick={() => this.props.dispatch(setUserIdleTimeout(15))}/>
                                </Dropdown.Menu>
                            </Dropdown>
                        </Dropdown.Item>

                        {admin ? <Dropdown.Item onClick={() => {
                            this.setState({selectUserModal: true})
                            this.props.dispatch(fetchUsers())
                        }}>
                            Select user
                        </Dropdown.Item> : null}

                        {this.props.userOptions.filter(u => {
                            if( u.email === user.email ) {
                                return false;
                            }
                            if( !admin ) {
                                if( u.roles.some((v) => v.name === "ROLE_ADMIN") ) {
                                    return false;
                                }
                            }
                            return true;
                        }).map(u => (<Dropdown.Item
                            onClick={() => this.props.dispatch(changeUser(u.email))}
                            text={u.firstName}
                        />))}
                    </Dropdown.Menu>
                </Dropdown>
                <Popup trigger={
                    <div style={{display: 'inline-block'}}>
                        <Icon
                            title='Toggle display as user'
                            onClick={() => this.props.dispatch(toggleRenderAsUser())}
                            style={{cursor: 'pointer', color: user.renderAsMicroplacerUser ? 'orange' : user.renderAsUser ? 'red' : '', marginLeft: 8}}
                            name={user.renderAsUser ? 'user' : 'user outline'}/>
                    </div>
                } content={user.renderAsMicroplacerUser ? 'Rendering as a uPlacer user' : user.renderAsUser ? 'Rendering as a user' : 'Rendering as admin, click to change to \'customer\' view'} position="bottom center"/>

            </div> : <div>
                {user.email}
            </div>}

            {this.state.selectUserModal ? <Modal open={true} closeIcon onClose={() => this.setState({selectUserModal: false})}>
                <Modal.Header>Select user</Modal.Header>
                <Modal.Content>
                    <Dropdown selection fluid search loading={this.props.fetchingUsers} placeholder='Change to user'
                        options={this.props.users.sort((a, b) => a.firstName.localeCompare(b.firstName)
                        ).map(u => ({key: u.id, text: u.fullName, value: u.email, description: u.email}))}
                        onChange={(e, {value}) => {
                            this.props.dispatch(changeUser(value, () => {
                                this.setState({selectUserModal: false})
                            }))
                        }}
                    />
                </Modal.Content>
            </Modal> : null}
        </div>
    }
    selectLanguage = (lang) => {
        this.props.dispatch(setLangauge(lang))
        this.props.dispatch(setUserLanguage(lang))
    }
    render() {

        let options = langOptions

        let currentOption = this.props.currentLang ? options.filter(x => x.key === this.props.currentLang)[0] : options[3];

        let currentUser = this.props.currentUser;

        return(
            <div id={'navBars'}>
                <nav id={'navBar'} className={'navBarVisible' + (this.state.active ? ' navBarOpen': '')}>
                    <NavToggle onClick={() => this.toggleState()} visible={this.state.active}/>
                    <div style={{display: 'inline-block', width: 64}} className='navBarLogo'>
                        <Link to={'/'} className={'link'}>
                            <img src='/logo.svg' id={'navLogo'} alt={'logo'} style={{padding: 8}}/>
                        </Link>
                    </div>

                    <div id={'menuItemsContainer'} className={this.state.active ? 'navBarVisible' : 'navBarHidden'} >
                        <MenuItem key='1' itemText={<Translate value='menu_about'/>} linkTo={'/about'} onClick={() => this.toggleStateFalse()}/>
                        <MenuItem key='2' itemText={<Translate value='menu_contact'/>} linkTo={'/contact'} onClick={() => this.toggleStateFalse()}/>
                        <MenuItem key='3' itemText={<Translate value='menu_prices'/> } linkTo={'/calculator'} onClick={() => this.toggleStateFalse()}/>
                        {/*<MenuItem key='4' itemText={<Translate value='menu_machines'/> } linkTo={'/microplacer'} onClick={() => this.toggleStateFalse()}/>*/}
                        <MenuItem key='5' itemText={<Translate value='menu_news'/> } linkTo={'/news'} onClick={() => this.toggleStateFalse()}/>
                        <MenuItem key='6' itemText={[<Icon name='world' key='icon'/>,<Dropdown key='d1' icon={null} text={currentOption.text}>
                            <Dropdown.Menu>
                                {options.map(x => (
                                    <Dropdown.Item key={x.key} onClick={() => {
                                        this.selectLanguage(x.key)
                                        this.toggleStateFalse()
                                    }}>{x.text}</Dropdown.Item>
                                ))}
                            </Dropdown.Menu>
                        </Dropdown>]}/>
                        {this.props.currentUser ? this.renderUserMenu(currentUser) : <div className={'menuItem body2'}><button className="link-button" id={'signInReg'} onClick={() => {
                            this.props.dispatch(openLoginSignup(true))
                            this.props.dispatch(validateLogin());
                            this.toggleStateFalse();
                        }}><Translate value='menu_login'/> / <Translate value='login_register'/> </button></div>}
                    </div>
                    {this.props.currentUser ? <SecondaryNavigationBar parentCallback = {this.callbackFunction} visibleClass={this.state.active ? 'navBarVisible' : 'navBarHidden'}/> : ''}

                </nav>
                <NavigationBarMessage top={this.props.currentUser ? 106 : 68}/>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        currentUser: state.main.currentUser,
        lang: state.main.lang,
        currentLang: state.main.currentLang,
        userOptions: state.main.userOptions,
        users: state.main.users,
        fetchingUsers: state.main.fetchingUsers,
        changeUserModalOpen: state.main.changeUserModalOpen,
        idleTimeout: state.main.idleTimeout,
    }
}

export default connect(mapStateToProps)(withRouter(NavigationBar))
