import React, { Component } from 'react';
import {
    Icon,
    Label,
    Menu,
    Popup,
    Segment,
    Step,
    Button,
    Table,
    Image,
    Modal
} from "semantic-ui-react";
import {connect} from "react-redux";
import {isEmployee, formatDateTime, formatDate, getCompanyName, getPaymentStatusColor} from "../actions/functions";
import PropTypes from "prop-types";
import {toggleProjectShow, setProjectPaymentStatus} from "../actions";
import CalculatorPriceView from "../oldui/CalculatorPriceView";
import Calculator from "../oldui/Calculator";
import {Link} from "react-router-dom";
import TextareaAutosize from "react-textarea-autosize";

class ProjectView extends Component{

    state = { activeItem: '', hidden: false , active: false}

    handleItemClick = (e, { name }) => this.setState({ activeItem: name })

    handleClick = (e, { title }) => this.setState({ active: title })

    checkIfNull(value){
        if(value == null) return ' '
        else return ' ' + value + ' '
    }

    toggleShowHide = (project) => {

        this.props.dispatch(toggleProjectShow(project.id, !project.filterState))
        this.setState({hidden: !this.state.hidden})
    }

    setProjectPaymentStatus = (id, newValue) => {

        this.props.dispatch(setProjectPaymentStatus(id, newValue))

    }

    render() {
        const { active } = this.state
        const { activeItem } = this.state

        const project = this.props.project;

        let price = null;

        const admin= isEmployee(this.props.currentUser);
        const inputDisabled = false;

        const bedrijf = getCompanyName(project.owner)
        const naam = project.owner.firstName + this.checkIfNull(project.owner.tussenvoegsel) + project.owner.lastName
        const aanvraagdatum = formatDateTime(project.aanvraagDate)
        const projectnaam = project.name
        const aantal = project.calculatorDetails.numPcbs
        const levertijd = project.calculatorDetails.selectedDays
        const leverdatum = formatDate(project.deliveryDate)
        const internenaam = project.internalName

        if( !this.props.dummy ) {
            price = <div style={{display: 'inline'}}>

                <CalculatorPriceView
                    orderId={project.id} selectedDays={project.calculatorDetails.selectedDays}
                    numPcbs={project.calculatorDetails.numPcbs}
                />

            </div>
        }

        return <div className={this.props.listView ? 'project-container-listview' : 'project-container'}>
            <div className={'project-header'}>
                <Label.Group circular as={'a'} size={'large'}>
                    {this.props.listView && !project.filterState && <Popup position= 'top center' content= 'Hide' trigger={<Label onClick={() => this.toggleShowHide(project)} className={'project-header-labels'} style={{width: 0}}><Icon name={'eye'}/></Label>}/>}
                    {this.props.listView && project.filterState && <Popup position= 'top center' content= 'Unhide' trigger={<Label onClick={() => this.toggleShowHide(project)} className={'project-header-labels'} style={{width: 0}}><Icon name={'eye slash'}/></Label>}/>}
                    {!this.props.listView && <Link to={'/protoflow/'}><Label className={'project-header-labels'} style={{width: 0}} ><Icon name={'arrow left'}/></Label></Link>}
                    <Link to={'/protoflow/'+project.id}>
                        <Popup position= 'top center' content= {bedrijf} trigger={<Label className={'project-header-labels'}><Icon name={'factory'}/>{bedrijf}</Label >}/>
                        <Popup position= 'top center' content= {<a href={"mailto:"+project.owner.email} target="_top">{project.owner.email}</a>} hoverable trigger={<Label className={'project-header-labels'}><Icon name={'user'}/>{naam}</Label>}/>
                        <Label className={'project-header-labels'} style={{width: '12.5%'}}><Icon name={'calendar plus outline'}/>{aanvraagdatum}</Label>
                        <Popup position= 'top center' content={projectnaam} trigger={<Label className={'project-header-labels'}><Icon name={'tag'}/>{projectnaam}</Label>}/>
                        <Label className={'project-header-labels'} style={{width: '7%'}}><Icon name={'hashtag'}/>{aantal} PCBA</Label>
                        <Label className={'project-header-labels'} style={{width: '13.5%'}}><Icon name={'time'} />{levertijd} wdays ({leverdatum})</Label>
                        {admin && <Label className={'project-header-labels'} style={{width: '10%'}}><Icon name={'tags'}/>{internenaam}</Label>}
                        <Label className={'project-header-labels'} style={{width: '7%'}}><Icon name={'money bill alternate outline'}/>{price}</Label>
                    </Link>
                </Label.Group>
                <Icon title={project.paymentStatus} name='money' color={getPaymentStatusColor(project.paymentStatus)}
                      style={{cursor: 'pointer', position: 'relative', top: 11}} onClick={(e) => {

                    if (project.paymentStatus === 'payed') {
                        this.setProjectPaymentStatus(project.id, 'na')
                    } else if (project.paymentStatus === 'sent') {
                        this.setProjectPaymentStatus(project.id, 'payed')
                    } else {
                        this.setProjectPaymentStatus(project.id, 'sent')
                    }

                    e.preventDefault();
                }}/>

            </div>
            <Link to={'/protoflow/'+project.id}>
            <Step.Group className={'project-steps'} fluid widths={4}>
                <Step
                    active={active === 'Analysis & Order'}
                    icon='search'
                    link
                    onClick={this.handleClick}
                    title='Analysis & Order'
                    description='Status: Ordering'
                />
                <Step
                    active={active === 'Preparation'}
                    icon='clipboard check'
                    link
                    onClick={this.handleClick}
                    title='Preparation'
                    description='Status: -'
                />
                <Step
                    active={active === 'Sample Production'}
                    icon='cog'
                    link
                    onClick={this.handleClick}
                    title='Sample Production'
                    description='Status: -'
                />
                <Step
                    active={active === 'Serie Production'}
                    icon='cogs'
                    link
                    onClick={this.handleClick}
                    title='Serie Production'
                    description='Status: -'
                />
            </Step.Group>
            </Link>

            {this.state.active === 'Analysis & Order' &&
            <div>
                <Menu className = {'project-menu'} attached='top' tabular>
                    <Menu.Item
                        name='Calculator'
                        active={activeItem === 'Calculator'}
                        onClick={this.handleItemClick}
                    >
                        <Icon name={'calculator'}/>Calculator
                    </Menu.Item>
                    <Menu.Item
                        name='Project Data'
                        active={activeItem === 'Project Data'}
                        onClick={this.handleItemClick}
                    >
                        <Icon name={'file'}/>Project Data
                    </Menu.Item>
                    <Menu.Item
                        name='BOM'
                        active={activeItem === 'BOM'}
                        onClick={this.handleItemClick}
                    >
                        <Icon name={'list'}/>BOM
                    </Menu.Item>
                    <Menu.Item
                        name='Centroid'
                        active={activeItem === 'Centroid'}
                        onClick={this.handleItemClick}
                    >
                        <Icon name={'crosshairs'}/>Centroid
                    </Menu.Item>
                    <Menu.Item
                        name='Validate Data'
                        active={activeItem === 'Validate Data'}
                        onClick={this.handleItemClick}
                    >
                        <Icon name={'check square'}/>Validate Data
                    </Menu.Item>
                    <Menu.Item
                        name='Check stock'
                        active={activeItem === 'Check stock'}
                        onClick={this.handleItemClick}
                    >
                        <Icon name={'search'}/>Check stock
                    </Menu.Item>
                    <Menu.Item
                        name='Proposal'
                        active={activeItem === 'Proposal'}
                        onClick={this.handleItemClick}
                    >
                        <Icon name={'star'}/>Proposal
                    </Menu.Item>
                    <Menu.Item
                        name='Order'
                        active={activeItem === 'Order'}
                        onClick={this.handleItemClick}
                    >
                        <Icon name={'rocket'}/>Order
                    </Menu.Item>
                </Menu>
                <Segment className={'project-segment'} attached='bottom'>
                    {this.state.activeItem === 'Calculator' && <Calculator embed={true}/>}
                    {this.state.activeItem === 'Project Data' && <>
                        <div className={'project-segment-button-bar'}>
                            <Button primary icon='cog'/>
                            <Button primary labelPosition='left' icon='upload' content='Gerber'/>
                            <Button primary labelPosition='left' icon='upload' content='BOM'/>
                            <Button primary labelPosition='left' icon='upload' content='Centroid'/>
                            <Button style={{float: 'right', margin: 0}} primary labelPosition='left' icon='upload' content='Centroid'/>
                        </div>
                        <div style={{overflow: "hidden", border: '1px solid lightgrey', borderRadius: '.28571429rem'}}>
                        <header className={'project-segment-header'}>
                            <p>Gerber</p>
                            <p>BOM</p>
                            <p>Centroid</p>
                        </header>
                        <div className={'project-segment-grid-3'} >
                            <p style={{writingMode: 'tb-rl'}}>DeltaProto</p>
                        </div>
                        <div className={'project-segment-grid-3'} style={{marginLeft: '0.5%', marginRight: '0.5%'}}>
                        </div>
                        <div className={'project-segment-grid-3'}>
                        </div>
                        <hr style={{display: 'flex'}}/>
                        <div className={'project-segment-grid-3'}>
                            <p style={{writingMode: 'tb-rl'}}>Klant</p>
                        </div>
                        <div className={'project-segment-grid-3'} style={{marginLeft: '0.5%', marginRight: '0.5%'}}>
                        </div>
                        <div className={'project-segment-grid-3'}>

                        </div>
                        </div>

                        </>}
                    {this.state.activeItem === 'Upload BOM' && <img alt='upload' src='https://react.semantic-ui.com/images/wireframe/paragraph.png' />}

                </Segment>
            </div>}
            {this.state.active === 'Preparation' &&
            <div>
                <Menu className = {'project-menu'} attached='top' tabular>
                    <Menu.Item
                        name='Purchase Components'
                        active={activeItem === 'Purchase Components'}
                        onClick={this.handleItemClick}
                    >
                        <Icon name={'shopping cart'}/>Purchase Components
                    </Menu.Item>
                    <Menu.Item
                        name='Check-in'
                        active={activeItem === 'Check-in'}
                        onClick={this.handleItemClick}
                    >
                        <Icon name={'clipboard check'}/>Check-in
                    </Menu.Item>
                </Menu>
                <Segment className={'project-segment'} attached='bottom'>
                    {this.state.activeItem === 'Buy components' && <img src='https://react.semantic-ui.com/images/wireframe/paragraph.png' />}
                    {this.state.activeItem === 'Check-in' && <img src='https://react.semantic-ui.com/images/wireframe/paragraph.png' />}
                </Segment>
            </div>}
            {this.state.active === 'Sample Production' &&
            <div>
                <Menu className = {'project-menu'} attached='top' tabular>
                    <Menu.Item
                        name='Sample Production'
                        active={activeItem === 'Sample Production'}
                        onClick={this.handleItemClick}
                    >
                        <Icon name={'industry'}/>Sample Production
                    </Menu.Item>
                    <Menu.Item
                        name='ProtoFoto'
                        active={activeItem === 'ProtoFoto'}
                        onClick={this.handleItemClick}
                    >
                        <Icon name={'camera'}/>ProtoFoto
                    </Menu.Item>
                    <Menu.Item
                        name='List of Change'
                        active={activeItem === 'List of Change'}
                        onClick={this.handleItemClick}
                    >
                        <Icon name={'list ol'}/>List of Change
                    </Menu.Item>
                    <Menu.Item
                        name='Modification'
                        active={activeItem === 'Modification'}
                        onClick={this.handleItemClick}
                    >
                        <Icon name={'wrench'}/>Modification
                    </Menu.Item>
                    <Menu.Item
                        name='Delivery '
                        active={activeItem === 'Delivery '}
                        onClick={this.handleItemClick}
                    >
                        <Icon name={'truck'}/>Delivery
                    </Menu.Item>
                    <Menu.Item
                        name='Review '
                        active={activeItem === 'Review '}
                        onClick={this.handleItemClick}
                    >
                        <Icon name={'search'}/>Review
                    </Menu.Item>
                </Menu>
                <Segment className={'project-segment'} attached='bottom'>
                    {this.state.activeItem === 'List of Change' && <>
                        <div className={'project-segment-button-bar'}>
                            <Button primary icon='cog'/>
                            <Button primary labelPosition='left' icon='upload' content='Knopje'/>
                            <Button primary labelPosition='left' icon='upload' content='Knopje'/>
                            <Button primary labelPosition='left' icon='upload' content='Knopje'/>
                            <Button style={{float: 'right', margin: 0}} primary labelPosition='left' icon='upload' content='Knopje rechts'/>
                        </div>
                        <div style={{overflow: "hidden", border: '1px solid lightgrey', borderRadius: '.28571429rem', paddingBottom: 12}}>
                            <div style={{height:38, background: "#2185d0", width: "100%", marginBottom: 12, padding: 11, fontSize: 14, color: "white"}}>List of Change</div>

                        <Table verticalAlign='center' className='loc-table' celled definition>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell/>
                                    <Table.HeaderCell>Designator</Table.HeaderCell>
                                    <Table.HeaderCell>Issue</Table.HeaderCell>
                                    <Table.HeaderCell>Wijziging</Table.HeaderCell>
                                    <Table.HeaderCell></Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>

                            <Table.Body>
                                <Table.Row>
                                    <Table.Cell style={{padding: 0}}> <Image style={{margin:0}} src='https://react.semantic-ui.com/images/avatar/small/elliot.jpg' avatar/>
                                    </Table.Cell>
                                    <Table.Cell></Table.Cell>
                                    <Table.Cell></Table.Cell>
                                    <Table.Cell></Table.Cell>
                                    <Table.Cell><Button circular positive icon='check' />
                                        <Button circular negative icon='close' />
                                        <Button circular primary icon='wrench' />
                                        <Button circular icon='settings' />
                                    </Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.Cell><img style={{width: 28}} src="/images/logos/deltaproto-beta.png"/></Table.Cell>
                                    <Table.Cell>
                                    </Table.Cell>
                                    <Table.Cell>
                                    </Table.Cell>
                                    <Table.Cell></Table.Cell>
                                    <Table.Cell><Button circular positive icon='check' />
                                        <Button circular negative icon='close' />
                                        <Button circular primary icon='wrench' />
                                        <Button circular icon='settings' />
                                    </Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.Cell></Table.Cell>
                                    <Table.Cell style={{padding: 0}} ><div style={{display: 'flex'}}><TextareaAutosize rows={1} disabled={inputDisabled} transparent fluid
                                                                                                                       placeholder='C1, C2...'/></div>
                                    </Table.Cell>
                                    <Table.Cell style={{padding: 0}}><div style={{display: 'flex'}}><TextareaAutosize rows={1} disabled={inputDisabled} transparent fluid
                                                                                                                      placeholder='...'/></div>
                                    </Table.Cell>
                                    <Table.Cell style={{padding: 0}}><div style={{display: 'flex'}}><TextareaAutosize rows={1} disabled={inputDisabled} transparent fluid
                                                                                                                      placeholder='...'/></div>
                                    </Table.Cell>
                                    <Table.Cell><Modal
                                        trigger={<Button positive content='Voeg toe aan lijst'/>}
                                        header='Weet u het zeker?'
                                        content='U kunt de opmerking later enkel doorhalen, niet wijzigen.'
                                        actions={['Cancel', { key: 'add', content: 'Voeg toe', positive: true }]}
                                    /> </Table.Cell>
                                </Table.Row>
                            </Table.Body>
                        </Table>
                        </div>




                    </>}
                </Segment>
            </div>}
            {this.state.active === 'Serie Production' &&
            <div>
                <Menu className = {'project-menu'} attached='top' tabular>
                    <Menu.Item
                        name='Buy to Modify'
                        active={activeItem === 'Buy to Modify'}
                        onClick={this.handleItemClick}
                    >
                        <Icon name={'shopping cart'}/>Buy to Modify
                    </Menu.Item>
                    <Menu.Item
                        name='Check-in to Modify'
                        active={activeItem === 'Check-in to Modify'}
                        onClick={this.handleItemClick}
                    >
                        <Icon name={'clipboard check'}/>Check-in to Modify
                    </Menu.Item>
                    <Menu.Item
                        name='Serie Production'
                        active={activeItem === 'Serie Production'}
                        onClick={this.handleItemClick}
                    >
                        <Icon name={'industry'}/>Serie Production
                    </Menu.Item>
                    <Menu.Item
                        name='Shipping'
                        active={activeItem === 'Shipping'}
                        onClick={this.handleItemClick}
                    >
                        <Icon name={'ship'}/>Shipping
                    </Menu.Item>
                    <Menu.Item
                        name='Modification'
                        active={activeItem === 'Modification'}
                        onClick={this.handleItemClick}
                    >
                        <Icon name={'bug'}/>Modification
                    </Menu.Item>
                </Menu>
                <Segment className={'project-segment'} attached='bottom'>
                    {this.state.activeItem === 'Buy components' && <img src='https://react.semantic-ui.com/images/wireframe/paragraph.png' />}
                    {this.state.activeItem === 'Check-in' && <img src='https://react.semantic-ui.com/images/wireframe/paragraph.png' />}
                </Segment>
            </div>}
        </div>
    }


}

ProjectView.propTypes = {
    listView: PropTypes.bool,
    project: PropTypes.object,
    value: PropTypes.object,
    textKey: PropTypes.string
};

ProjectView.defaultProps = {
    listView: false,
    project: null,
    value: {lines: []},
    textKey: 'production.release'
};

const mapStateToProps = (state) => {
    return {
        currentUser: state.main.currentUser
    }
}

export default connect(mapStateToProps)(ProjectView)