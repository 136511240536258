import React, { Component }  from 'react';
import {projectOrderPcbDownloadProgress} from "./actions";
import connect from "react-redux/es/connect/connect";

class TranslatedImage extends Component {

    g_transform = { 'a': 1, 'b': 0, 'c': 0, 'd': 1, 'e': 0, 'f': 0 };
    g_targetFirstPoint = null;
    g_targetSecondPoint = null;
    g_targetThirdPoint = null;
    g_sourceFirstPoint = null;
    g_sourceSecondPoint = null;
    g_sourceThirdPoint = null;
    g_isMouseDown = false;

    state= {
        transform: '1, 0, 0, 1, 0, 0'
    }
    crosshair;
    img;
    svg;

    constructor(props) {
        super(props);
        this.canvas = React.createRef();
    }

    handleUpdatePoints() {
        if( this.props.onPointChange ) {
            let value = {
                tx1: this.g_targetFirstPoint ? this.g_targetFirstPoint[0] : null,
                ty1: this.g_targetFirstPoint ? this.g_targetFirstPoint[1] : null,
                tx2: this.g_targetSecondPoint ? this.g_targetSecondPoint[0] : null,
                ty2: this.g_targetSecondPoint ? this.g_targetSecondPoint[1] : null,
                tx3: this.g_targetThirdPoint ? this.g_targetThirdPoint[0] : null,
                ty3: this.g_targetThirdPoint ? this.g_targetThirdPoint[1] : null,
                sx1: this.g_sourceFirstPoint ? this.g_sourceFirstPoint[0] : null,
                sy1: this.g_sourceFirstPoint ? this.g_sourceFirstPoint[1] : null,
                sx2: this.g_sourceSecondPoint ? this.g_sourceSecondPoint[0] : null,
                sy2: this.g_sourceSecondPoint ? this.g_sourceSecondPoint[1] : null,
                sx3: this.g_sourceThirdPoint ? this.g_sourceThirdPoint[0] : null,
                sy3: this.g_sourceThirdPoint ? this.g_sourceThirdPoint[1] : null
            }
            this.props.onPointChange(value)
        }
    }

    getPointsFromProps = () => {
        let p= this.props.points;
        if( p ) {
            this.g_targetFirstPoint= p.tx1 ? [p.tx1, p.ty1] : null;
            this.g_targetSecondPoint = p.tx2 ? [p.tx2, p.ty2] : null;
            this.g_targetThirdPoint = p.tx3 ? [p.tx3, p.ty3] : null;
            this.g_sourceFirstPoint = p.sx1 ? [p.sx1, p.sy1] : null;
            this.g_sourceSecondPoint = p.sx2 ? [p.sx2, p.sy2] : null;
            this.g_sourceThirdPoint = p.sx3 ? [p.sx3, p.sy3] : null;
        }
    }

    componentDidMount() {

        this.marker1= new Image();
        this.marker1.src= '/images/naald1.png';

        this.marker2= new Image();
        this.marker2.src= '/images/naald2.png';

        this.marker3= new Image();
        this.marker3.src= '/images/naald3.png';

        this.getPointsFromProps();
        this.calculateTransform();
        this.refreshImage();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if( prevProps.src !== this.props.src || prevProps.updateId !== this.props.updateId) {
            console.log('refreshImage')
            this.refreshImage();
        }

        if( prevProps.hidePoints !== this.props.hidePoints ) {
            this.renderOverlayWithPhoto();
        }

        if( JSON.stringify(prevProps.points) !== JSON.stringify(this.props.points ) ) {
            console.log('points updated')
            this.getPointsFromProps();
            this.calculateTransform();
            this.renderOverlayWithPhoto();
        }
    }

    refreshImage() {

        this.getPointsFromProps();

        Image.prototype.load = function(url, loading){
            var thisImg = this;
            var xmlHTTP = new XMLHttpRequest();
            xmlHTTP.open('GET', url,true);
            xmlHTTP.responseType = 'arraybuffer';
            xmlHTTP.onload = function(e) {
                var blob = new Blob([this.response]);
                thisImg.src = window.URL.createObjectURL(blob);
                if( loading ) {
                    loading(100)
                }
            };
            xmlHTTP.onprogress = function(e) {
                thisImg.completedPercentage = parseInt((e.loaded / e.total) * 100);
                if( loading ) {
                    loading(thisImg.completedPercentage)
                }
            };
            xmlHTTP.onloadstart = function() {
                thisImg.completedPercentage = 0;
                if( loading ) {
                    loading(0)
                }
            };
            xmlHTTP.send();
        };

        this.img= new Image();
        this.img.load("/api/project/getAttachment?id=" + this.props.src, (percentage) => {
            this.props.dispatch(projectOrderPcbDownloadProgress(this.props.pcbId, percentage))
        });

        this.img.onload = () => {
            var width = this.img.width;
            var height = this.img.height;
            this.copyImageWithTransform(width, height, this.g_transform);
        }
    }

    getOffset(evt) {
        var el = evt.target,
            x = 0,
            y = 0;

        while (el && !isNaN(el.offsetLeft) && !isNaN(el.offsetTop)) {
            x += el.offsetLeft - el.scrollLeft;
            y += el.offsetTop - el.scrollTop;
            el = el.offsetParent;
        }

        x = evt.clientX - x;
        y = evt.clientY - y;

        el= evt.target;
        while(el) {
            if(el && el.parentNode) {
                x += el.scrollLeft;
                y += el.scrollTop;
                el = el.parentNode;
            } else {
                el = null;
            }
        }

        return { x: x, y: y };
    }

    calculateTransform() {
        if (this.g_targetFirstPoint && this.g_targetSecondPoint && this.g_targetThirdPoint && this.g_sourceFirstPoint && this.g_sourceSecondPoint && this.g_sourceThirdPoint) {
            var tx1 = this.g_targetFirstPoint[0];
            var ty1 = this.g_targetFirstPoint[1];
            var tx2 = this.g_targetSecondPoint[0];
            var ty2 = this.g_targetSecondPoint[1];
            var tx3 = this.g_targetThirdPoint[0];
            var ty3 = this.g_targetThirdPoint[1];
            var sx1 = this.g_sourceFirstPoint[0];
            var sy1 = this.g_sourceFirstPoint[1];
            var sx2 = this.g_sourceSecondPoint[0];
            var sy2 = this.g_sourceSecondPoint[1];
            var sx3 = this.g_sourceThirdPoint[0];
            var sy3 = this.g_sourceThirdPoint[1];

            // OK, I'm not going to explain this. It's basic linear algebra, determining the transformation matrix
            // from a given transformation of 3 points. Can be verified using a single piece of paper.
            var det = sx1 * (sy2 - sy3) + sx2 * (sy3 - sy1) + sx3 * (sy1 - sy2);
            this.g_transform = { };
            this.g_transform.a = (tx1 * (sy2 - sy3) + tx2 * (sy3 - sy1) + tx3 * (sy1 - sy2)) / det;
            this.g_transform.c = (tx1 * (sx2 - sx3) + tx2 * (sx3 - sx1) + tx3 * (sx1 - sx2)) / -det;
            this.g_transform.b = (ty1 * (sy2 - sy3) + ty2 * (sy3 - sy1) + ty3 * (sy1 - sy2)) / det;
            this.g_transform.d = (ty1 * (sx2 - sx3) + ty2 * (sx3 - sx1) + ty3 * (sx1 - sx2)) / -det;
            this.g_transform.e = tx1 - this.g_transform.a * sx1 - this.g_transform.c * sy1;
            this.g_transform.f = ty1 - this.g_transform.b * sx1 - this.g_transform.d * sy1;

        } else if (this.g_targetFirstPoint && this.g_targetSecondPoint && this.g_sourceFirstPoint && this.g_sourceSecondPoint) { // We have four points
            this.g_sourceThirdPoint = null;
            // The transform maps the source image points [ Xs, Ys ] to the target image points [ Xt, Yt ]in the following way:
            // | Xt |   | a  c |   | Xs |   | e |
            // |    | = |      | * |    | + |   |
            // | Yt |   | b  d |   | Ys |   | f |
            //
            // If we know the two point mappings g_sourceFirstPoint -> g_targetFirstPoint and g_sourceSecondPoint -> g_targetFirstPoint,
            // we can derive the matrix coefficients. For a complete set, we'd neet three point mappings. This would allow for skew etc.
            // For simple scaling, rotation and translation, the coefficients are constrained and then 2 point mappings suffice.
            // In case of scaling with S, rotation with A and translation [Tx, Ty], the matrix becomes:
            // | Xt |   | S * cos(A) -S * sin(A) |   | Xs |   | Tx |
            // |    | = |                        | * |    | + |    |
            // | Yt |   | S * sin(A)  S * cos(A) |   | Ys |   | Ty |
            // so in this case b = -c and d = a. (Note that mathematical Y coordinates are bottom up and graphics Y coordinates are top down.)
            //
            // Solving for the known 4 points gives us:
            // a = ((Xt1 - Xt2) * (Xs1 - Xs2) + (Yt1 - Yt2) * (Ys1 - Ys2)) / ((Xs1 - Xs2) * (Xs1 - Xs2) + (Ys1 - Ys2) * (Ys1 - Ys2))
            // c = ((Xt1 - Xt2) * (Ys1 - Ys2) - (Yt1 - Yt2) * (Xs1 - Xs2)) / ((Xs1 - Xs2) * (Xs1 - Xs2) + (Ys1 - Ys2) * (Ys1 - Ys2))
            // or simplified:
            // a = (dXt * dXs + dYt * dYs) / (dXs * dXs + dYs * dYs)
            // c = (dXt * dYs - dYt * dXs) / (dXs * dXs + dYs * dYs)
            //
            // e and f can be derived from filling in Xs, Ys, Xt and Yt
            const dXt = this.g_targetFirstPoint[0] - this.g_targetSecondPoint[0];
            const dYt = this.g_targetFirstPoint[1] - this.g_targetSecondPoint[1];
            const dXs = this.g_sourceFirstPoint[0] - this.g_sourceSecondPoint[0];
            const dYs = this.g_sourceFirstPoint[1] - this.g_sourceSecondPoint[1];
            const div = dXs * dXs + dYs * dYs;
            this.g_transform = { };
            this.g_transform.a = (dXt * dXs + dYt * dYs) / div;
            this.g_transform.c = (dXt * dYs - dYt * dXs) / div;
            this.g_transform.b = -this.g_transform.c;
            this.g_transform.d = this.g_transform.a;
            this.g_transform.e = this.g_targetFirstPoint[0] - this.g_transform.a * this.g_sourceFirstPoint[0] - this.g_transform.c * this.g_sourceFirstPoint[1];
            this.g_transform.f = this.g_targetFirstPoint[1] - this.g_transform.b * this.g_sourceFirstPoint[0] - this.g_transform.d * this.g_sourceFirstPoint[1];

        } else if (this.g_targetFirstPoint && this.g_sourceFirstPoint) { // We have two points
            // We translate only
            this.g_sourceSecondPoint = null;
            this.g_sourceThirdPoint = null;
            const dx = this.g_targetFirstPoint[0] - this.g_sourceFirstPoint[0];
            const dy = this.g_targetFirstPoint[1] - this.g_sourceFirstPoint[1];
            this.g_transform = { 'a': 1, 'b': 0, 'c': 0, 'd': 1, 'e': dx, 'f': dy };
        } else {
            this.g_transform = { 'a': 1, 'b': 0, 'c': 0, 'd': 1, 'e': 0, 'f': 0 };
        }
    }

    handleMouseEvent(ev, modify) {
        var x = (this.getOffset(ev).x / this.props.scale) - (this.props.translation.x / this.props.scale);
        var y = (this.getOffset(ev).y / this.props.scale) - (this.props.translation.y / this.props.scale);

        if (ev.type === 'mousedown') {

            this.setState({opacity: this.props.opacity});

            if (!this.g_transform) {
                this.g_transform = { 'a': 1, 'b': 0, 'c': 0, 'd': 1, 'e': 0, 'f': 0 };
            }

            this.g_isMouseDown = true;
            // Sample starting points. The coordinate space is target coordinates here.
            // We click at most 3 points. The first will allow only translation. The second will enable
            // rotation and scaling. The third will enable separate X/Y scaling and skew.
            // Normally, with multi-touch devices, you would simply pick one, two or three touch points
            // as your starting points.

            //this.g_sourceThirdPoint = this.g_sourceSecondPoint;
            //this.g_targetThirdPoint = this.g_targetSecondPoint;
            //this.g_sourceSecondPoint = this.g_sourceFirstPoint;
            //this.g_targetSecondPoint = this.g_targetFirstPoint;

            // Transform the clicked point back to the coordinate space of the source.
            var det = this.g_transform.a * this.g_transform.d - this.g_transform.b * this.g_transform.c;
            var dx = (x - this.g_transform.e);
            var dy = (y - this.g_transform.f);
            var sx = (this.g_transform.d * dx - this.g_transform.c * dy) / det;
            var sy = -(this.g_transform.b * dx - this.g_transform.a * dy) / det;

            if( modify === '3' ) {
                this.g_sourceThirdPoint = [sx, sy];
            } else if( modify === '2' ) {
                this.g_sourceSecondPoint = [sx, sy];
            } else if( modify === '1' ) {
                this.g_sourceFirstPoint = [sx, sy];
            }

            return;
        } else if (ev.type === 'mouseout') {
            // Don't take any further action any more.
            this.g_isMouseDown = false;
            return;
        } else if (!this.g_isMouseDown) {
            // The mouse button  is not pressed so don't take any action.
            return;
        } else if (ev.type === 'mousemove') {
            // Fall through.
        } else if (ev.type === 'mouseup') {
            // Don't take any further action any more after this event.
            this.g_isMouseDown = false;
            this.setState({opacity: 1})
            this.handleUpdatePoints()
            // Fall through.
        } else {
            // Unhandled event: ignore.
            return;
        }

        // Update the new coordinates.
        if( modify === '3' ) {
            this.g_targetThirdPoint = [x, y];
        } else if( modify === '2' ) {
            this.g_targetSecondPoint = [x, y];
        } else if( modify === '1' ) {
            this.g_targetFirstPoint = [x, y];
        }

        //console.log('handleMouseEvent', ev, x, y, w, h);
        // Update the transform with the coordinates and display the results of new transform.

        // If we have all six points, we can compute the transform
        this.calculateTransform();

        //let g= this.g_transform;
        //this.setState({tranform: g.a+', '+g.b+', '+g.c+', '+g.d+', '+g.e+', '+g.f})
        // Update our image
        if( modify ) {
            this.renderOverlayWithPhoto();
        }
    }

    renderOverlayWithPhoto() {
        var testImage = this.img;
        if( testImage ) {
            var width = testImage.width;
            var height = testImage.height;
            this.copyImageWithTransform(width, height, this.g_transform);
        }
    }

    copyImageWithTransform(width, height, transform) {
        if (transform == null) {
            transform = { 'a': 1, 'b': 0, 'c': 0, 'd': 1, 'e': 0, 'f': 0 };
        }

        var dstCanvas = this.canvas.current;

        if( !dstCanvas ) {
            return;
        }

        dstCanvas.width = width;
        dstCanvas.height = height;
        var dstContext = dstCanvas.getContext('2d');

        var imageElement = this.img;

        dstContext.save();
        dstContext.setTransform(transform);
        dstContext.drawImage(imageElement, 0, 0);

        if( !this.props.hidePoints ) {
            if (this.g_sourceFirstPoint && this.g_sourceFirstPoint[0] && this.g_sourceFirstPoint[1]) {
                dstContext.drawImage(this.marker1, this.g_sourceFirstPoint[0] - (this.marker1.width/2), this.g_sourceFirstPoint[1] - (this.marker1.height/2));
            }

            if (this.g_sourceSecondPoint && this.g_sourceSecondPoint[0] && this.g_sourceSecondPoint[1]) {
                dstContext.drawImage(this.marker2, this.g_sourceSecondPoint[0] - (this.marker2.width/2), this.g_sourceSecondPoint[1] - (this.marker2.height/2));
            }

            if (this.g_sourceThirdPoint && this.g_sourceThirdPoint[0] && this.g_sourceThirdPoint[1]) {
                dstContext.drawImage(this.marker3, this.g_sourceThirdPoint[0] - (this.marker3.width/2), this.g_sourceThirdPoint[1] - (this.marker3.height/2));
            }
        }

        //dstContext.drawImage(this.svg, 0, 0)

        dstContext.save();

        dstContext.restore();
    }

    render() {

        return (
            <canvas ref={this.canvas}
                    style={{
                        position: 'absolute',
                        opacity: this.state.opacity,
                        left: 0,
                        top: 0,
                        zIndex: this.props.zIndex,
                    }}
                    onMouseDown={(e) => {
                        if( this.props.modify ) {
                            this.m_modify= this.props.modify;
                            this.handleMouseEvent(e, this.m_modify)
                        }
                    }}
                    onMouseMove={(e) => {
                        if( this.m_modify ) {
                            this.handleMouseEvent(e, this.m_modify)
                        }
                    }}
                    onMouseUp={(e) => {
                        if( this.m_modify ) {
                            this.handleMouseEvent(e, this.m_modify)
                            this.m_modify= null;
                        }
                    }}
            />
        )
    }
}

TranslatedImage.defaultProps = {
    opacity: 1,
    zIndex: 1,
    points: null,
    onPointChange: null,
    backSide: false,
    hidePoints: false
};

const mapStateToProps = (state) => {
    return {

    }
}

export default connect(mapStateToProps)(TranslatedImage)