import React from "react";
import {Form, Icon, Popup, Table} from "semantic-ui-react";
import {searchProjectUsedPart, searchProjectUsedPartBySpec} from "../actions";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import {renderSupplier} from "./functions";

class BomSearchProjectParts extends React.Component {

    state= {
        sku: '',
        mpn: '',
        resistor: true
    }

    render() {
        return <div>
            <Form onSubmit={() => this.props.dispatch(searchProjectUsedPart(this.state.mpn, this.state.sku))}>
                <Form.Group widths='equal'>
                    <Form.Input label='SKU' value={this.state.sku} onChange={(e) => this.setState({sku: e.target.value})}/>
                    <Form.Input label='MPN' value={this.state.mpn} onChange={(e) => this.setState({mpn: e.target.value})}/>
                    <Form.Button
                        type="submit"
                        disabled={!this.state.sku && !this.state.mpn}
                        style={{marginTop: '1.6em'}}
                        loading={this.props.searchingProjectUsedPart}
                    >Search by {this.state.sku ? 'SKU' : 'MPN'}</Form.Button>
                </Form.Group>
            </Form>

            <Form onSubmit={() => this.props.dispatch(searchProjectUsedPartBySpec(this.state.resistor, this.state.value, this.state.packageOutline))}>
                <Form.Group widths='equal'>
                    <Form.Dropdown selection label='Resistor or Capacitor' value={this.state.resistor} onChange={(e) => this.setState({resistor: e.target.value})} options={[
                        {key: true, text: 'Resistor', value: true},
                        {key: false, text: 'Capacitor', value: false}
                    ]}/>
                    <Form.Input label='Value' value={this.state.value} onChange={(e) => this.setState({value: e.target.value})} placeholder={this.state.resistor ? 'Bijvoorbeeld: 100K, 4K7, 100R of 1R' : 'Bijvoorbeeld: 100nF, 1uF of 10000pF'}/>
                    <Form.Input label='Package' value={this.state.packageOutline} onChange={(e) => this.setState({packageOutline: e.target.value})} placeholder={'Bijvoorbeeld: 0603, 1210 of 0402'}/>
                    <Form.Button
                        type="submit"
                        disabled={!this.state.value}
                        style={{marginTop: '1.6em'}}
                        loading={this.props.searchingProjectUsedPart}
                    >Search by value</Form.Button>
                </Form.Group>
            </Form>

            <div>
                <Table celled compact='very' size='small'>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>Project</Table.HeaderCell>
                            <Table.HeaderCell>Supplier</Table.HeaderCell>
                            <Table.HeaderCell>SKU</Table.HeaderCell>
                            <Table.HeaderCell>MPN</Table.HeaderCell>
                            <Table.HeaderCell>Value</Table.HeaderCell>
                            <Table.HeaderCell>TrayLane</Table.HeaderCell>
                            <Table.HeaderCell>Qty</Table.HeaderCell>
                            <Table.HeaderCell>Stock</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {this.props.searchProjectUsedPart.map(p => {

                            let deleted = p.bomOrder == null || p.bomOrder.print == null || p.bomOrder.print.deleted;
                            let hasPrint = p.bomOrder != null && p.bomOrder.print != null;
                            let id = hasPrint ? p.bomOrder.print.id : null;

                            let hasProjectOrder = hasPrint ? !!p.bomOrder.print.projectOrder : null;
                            let projectId = hasProjectOrder ? p.bomOrder.print.projectOrder.id : null;

                            return <Table.Row disabled={deleted}>
                                <Table.Cell>
                                    {(hasPrint && !hasProjectOrder) ? <Link to={'/projects-microplacer/' + id + '/bom'}>{p.bomOrder.print.name}</Link> : null}
                                    {(hasProjectOrder) ? <Link to={'/project/' + projectId}>{p.bomOrder.print.projectOrder.internalName}</Link> : null}
                                    {deleted ? <Popup trigger={<Icon name='close' color={'red'}/>} content={'Project is verwijderd'}/> : ''}
                                </Table.Cell>
                                <Table.Cell>{p?.part?.supplier ? renderSupplier({supplier: p?.part?.supplier}) : null}{p.kla ? renderSupplier({supplier: 'KLANT'}) : null}{p?.kla?.name}</Table.Cell>
                                <Table.Cell>{p?.part?.sku} {p?.kla?.sku}</Table.Cell>
                                <Table.Cell>{p?.part?.orderpart?.mpn}{p?.kla?.mpn}</Table.Cell>
                                <Table.Cell>{p?.part?.orderpart?.specification?.displayValue}</Table.Cell>
                                <Table.Cell>{p?.boardLocation}</Table.Cell>
                                <Table.Cell>{p?.orderAmount}</Table.Cell>
                                <Table.Cell>{p?.stock}</Table.Cell>
                            </Table.Row>
                        })}
                    </Table.Body>
                </Table>
                {this.props.searchProjectUsedPart.length === 0 ? <p>Geen resultaten</p> : null}
            </div>
        </div>;
    }
}

const mapStateToProps = (state) => {
    return {
        searchingProjectUsedPart: state.bom.searchingProjectUsedPart,
        searchProjectUsedPart: state.bom.searchProjectUsedPart
    }
}

export default connect(mapStateToProps)(BomSearchProjectParts)