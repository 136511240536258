import React from 'react'
import {connect} from "react-redux";
import {Button, Form, Modal, Header} from "semantic-ui-react";
import {isEmployee} from "../actions/functions";
import Translate from "../Translate";

export function getChatLinesByRef(ref, chat, currentUser) {

    let admin = isEmployee(currentUser)

    if( chat && chat.chatLine ) {
        let res = chat.chatLine.filter(c => c.ref === ref)

        if( admin ) {
            return res;
        } else {
            return res.filter(c => {
                return c.text.startsWith('~') || c.from.id === currentUser.id || (c.to && c.to.id === currentUser.id)
            })
        }
    }
    return []
}

class BomChat extends React.Component {

    state = {};

    render() {

        return <div>
            <Modal
                open={true}
                onClose={() => {}}
                size=''
            >
                <Modal.Content>

                </Modal.Content>
                <Modal.Actions>
                    <Button onClick={() => {}}>
                        Annuleren
                    </Button>
                    <Button primary onClick={() => {}}>
                        <Translate value="save"/>
                    </Button>
                </Modal.Actions>
            </Modal>
        </div>
    }
}

const mapStateToProps = (state) => {
    return {
        print: state.bom.print,
    }


}

export default connect(mapStateToProps)(BomChat)