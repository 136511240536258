import React from 'react';
import {
    fetchApplicationSettings,
    getDropboxStatus,
    getKvkApiStatus,
    updateKvkKey, zoekKvkHandelsnaam, zoekKvkNummer
} from "./actions";
import {Button, Divider, Form, Icon, Modal, Popup} from "semantic-ui-react";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import {formatDateTime} from "./actions/functions";
import ApplicationSettingItem from "./ApplicationSettingItem";

class ApplicationSettings extends React.Component {

    state = {
    }

    componentDidMount() {
        this.props.dispatch(fetchApplicationSettings())

    }


    render() {

        const list = this.props.applicationsettings;

        return <div>
            {list ? list.map(s => {
                return <ApplicationSettingItem key={s.id} setting={s} />
            }) : 'Een moment...'}
        </div>
    }
}

ApplicationSettings.propTypes = {
    statusOnly: PropTypes.bool
}

const mapStateToProps = (state) => {
    return {
        applicationsettings: state.main.applicationsettings,
        applicationsettingsSaving: state.main.applicationsettingsSaving,
    }
}

export default connect(mapStateToProps)(ApplicationSettings)