import React, {Component} from "react";
import {withRouter} from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import {deleteBom, getBomList} from "./actions";
import {Confirm, Icon, Modal, Dropdown} from "semantic-ui-react";
import {formatDateTime} from "./actions/functions";
import {Link} from "react-router-dom";
import {userDisplayName} from "./oldui/AccountListItem";
import DeltaTable from "./deltatable/DeltaTable";
import DeltaTableMenu from "./deltatable/DeltaTableMenu";

class ProjectListMicroplacer extends Component {

    state = {}

    componentDidMount() {
        this.props.dispatch(getBomList(true))
    }

    render() {

        return (
            <main id={'projects'} className={'pageContainer'}>
                <DeltaTableMenu
                    header={'MicroPlacer projects'}
                    menu={[
                        <Dropdown.Item onClick={() => {
                            this.props.history.push('/projects-microplacer/new')
                        }}>Create new project</Dropdown.Item>
                    ]}
                >
                    <DeltaTable
                        name={'microplacerprojects'}
                        data={this.props.list}
                        loading={this.props.fetchingList}
                        columns={[
                            {id: 1, header: 'Project', defaultWidth: 400, render: (p) => (
                                <Link to={'/projects-microplacer/' + p.id + '/bom' }>
                                    {p.name}
                                </Link>
                            )},
                            {id: 2, header: 'Created', defaultWidth: 200, render: (p) => (
                                <div>
                                    {formatDateTime(p.created)}
                                </div>
                            )},
                            {id: 3, header: 'Owner', defaultWidth: 300, render: (p) => (
                                <div>
                                    {userDisplayName(p.owner)}
                                </div>
                            )},
                            {id: 4, header: '', defaultWidth: 30, render: (p) => (
                                <div>
                                    <Icon style={{cursor: 'pointer', float: ''}} name='trash' onClick={(e) => {
                                        this.setState({confirmDelete: p.id, confirmDeleteName: p.name});
                                    }}/>
                                </div>
                            )}
                        ]}
                    />
                </DeltaTableMenu>

                <Confirm
                    content={<Modal.Content>
                        <h4>
                            {this.state.confirmDeleteName}
                        </h4>
                        Remove project?
                    </Modal.Content>}
                    open={this.state.confirmDelete}
                    onCancel={(e) => {
                        this.setState({confirmDelete: false});
                        e.preventDefault();
                    }}
                    onConfirm={(e) => {
                        this.props.dispatch(deleteBom(this.state.confirmDelete, true, () => {
                            this.setState({confirmDelete: false});
                        }))
                    }}
                    confirmButton={'Remove'}
                />
            </main>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        lang: state.main.lang,
        currentUser: state.main.currentUser,
        list: state.bom.list,
        fetchingList: state.bom.fetchingList,
    }
}

export default connect(mapStateToProps)(withRouter(ProjectListMicroplacer))
