import {Dropdown} from "semantic-ui-react";
import React from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {getCentroidByPrint} from "../actions";
import {formatDateTime} from "../actions/functions";

class BomSelectExistingCentroid extends React.Component {

    componentDidMount() {
        if( this.props.printId ) {
            this.props.dispatch(getCentroidByPrint(this.props.printId))
        }
    }

    componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS) {
        if( this.props.printId && this.props.printId !== prevProps.printId ) {
            this.props.dispatch(getCentroidByPrint(this.props.printId))
        }
    }

    render() {
        return <>
            <Dropdown
                className='button icon'
                floating
                /*onChange={(e, data) => {
                    if( this.props.onSelectCentroid ) {
                        this.props.onSelectCentroid(data.value)
                    }
                }}*/

                trigger={<></>}
            >
                <Dropdown.Menu>
                    <Dropdown.Header icon='tags' content='Import an existing centroid' />
                    {this.props.centroidList.map( (centroid) => {
                        return <Dropdown.Item key={centroid.id} text={<div>
                            <div><b>{centroid.name}</b></div>
                            <div>{centroid.createdBy ? centroid.createdBy.fullName : ''}</div>
                            <div>{formatDateTime(centroid.created)}</div>
                        </div>} value={centroid} onClick={() => {
                            if( this.props.onSelectCentroid ) {
                                this.props.onSelectCentroid(centroid)
                            }
                        }}/>

                    })}
                </Dropdown.Menu>
            </Dropdown>
        </>
    }
}


BomSelectExistingCentroid.propTypes = {
    onSelectCentroid: PropTypes.func,
    printId: PropTypes.number
}

BomSelectExistingCentroid.defaultProps = {
    onSelectCentroid: null,
    printId: null
}

const mapStateToProps = (state) => {
    return {
        currentUser: state.main.currentUser,
        centroidList: state.bom.centroidList,

    }


}

export default connect(mapStateToProps)(BomSelectExistingCentroid)