import fetch from 'isomorphic-fetch'
import React, { useEffect, useState } from "react";

var moment = require('moment');
moment.locale('nl');

export const languageOptions= [{text: 'Deutsch', key: 'de'}, {text: 'English', key: 'en'}, {text: 'Français', key: 'fr'}, {text: 'Nederlands', key: 'nl'}]

export const getLangFromOs = () => {
    const lang = navigator.language.split("-")[0].toLowerCase();

    let found = languageOptions.some(lo => lo.key === lang)

    if( found ) {
        return lang
    }
    return 'nl'
}

export function getPaymentStatusColor(value) {
    switch (value) {
        case "payed":
            return "green";
        case "sent":
            return "blue";
        case "na":
            return "grey";
        default:
            return "white";
    }
}

export function requiredLangKeys(list) {
    return <div style={{display: 'none'}}>
        {list.map(e => (
            <div data-langkey={e.key} id={e.key}>{e.text}</div>
        ))}
    </div>
}

export function formatDate(date, format) {
    if( date != null ) {
        return moment( date ).format(format ? format : "D-M-YYYY");
    }
    return "";
}

export function getDate(string) {
    if( string != null ) {
        return moment( string, "D-M-YYYY" ).format('x');
    }
    return null;
}

export function formatDateTime(date) {
    if( date != null ) {
        return moment( date ).format("D-M-YYYY@HH:mm");
    }
    return null;
}

export function formateDateSince(date, lang = 'nl') {
    if( date != null ) {
        moment.locale(lang);
        return moment( date ).fromNow();
    }
    return null;
}

export function getDateTime(string) {
    if( string != null ) {
        return moment( string, "D-M-YYYY@HH:mm" ).format('x');
    }
    return null;
}

export const bestelBronnen= [
    {
        text: 'PCB Way (PCB)',
        value: 'PCBWAY_PCB',
        order: 0
    },
    {
        text: 'Eurocircuits (PCB)',
        value: 'EUROCIRCUITS_PCB',
        order: 1
    },
    {
        text: 'Multi-CB (PCB)',
        value: 'MULTI_CB_PCB',
        order: 2
    },
    {
        text: 'JLC (PCB)',
        value: 'JLC_PCB',
        order: 3
    },
    {
        text: 'Klant (PCB)',
        value: 'CUSTOMER_PCB',
        order: 4
    },
    {
        text: 'Alpha assembly (stencil)',
        value: 'ALPHAASSEMBLY_STENCIL',
        order: 5
    },
    {
        text: 'PCB Way (stencil)',
        value: 'PCBWAY_STENCIL',
        order: 6
    },
    {
        text: 'Multi-CB (stencil)',
        value: 'MULTI_CB_STENCIL',
        order: 7
    },
    {
        text: 'JLC (stencil)',
        value: 'JLC_STENCIL',
        order: 8
    },
    {
        text: 'Klant (stencil)',
        value: 'CUSTOMER_STENCIL',
        order: 9
    },
    {
        text: 'Digi-Key',
        value: 'DIGIKEY_COMP',
        order: 10
    },
    {
        text: 'Farnell',
        value: 'FARNELL_COMP',
        order: 11
    },
    {
        text: 'Mouser',
        value: 'MOUSER_COMP',
        order: 12
    },
    {
        text: 'RS Components',
        value: 'RS_COMP',
        order: 13
    },
    {
        text: 'TME',
        value: 'TME_COMP',
        order: 14
    },
    {
        text: 'Wurth',
        value: 'WURTH_COMP',
        order: 15
    },
    {
        text: 'Klant (componenten)',
        value: 'CUSTOMER_COMP',
        order: 16
    },
    {
        text: 'Delta Proto (componenten)',
        value: 'DELTAPROTO_COMP',
        order: 17
    }
]

export function getMissingSuppliers(projectOrder) {
    let res= [];

    if( projectOrder.requiredComponentSuppliers ) {
        projectOrder.requiredComponentSuppliers.forEach((r) => {
            res.push(r)
        })
    }

    let hasPcb= false;
    let hasStencil= false;

    projectOrder.bestellingen.forEach((b) => {

        if( b.supplierOrderNumber && b.supplierOrderNumber !== "" ) {

            if (b.supplier.endsWith('_PCB')) {
                hasPcb = true;
            }

            if (b.supplier.endsWith('_STENCIL')) {
                hasStencil = true;
            }
        }

    })

    if( !hasStencil ) {
        res.unshift('STENCIL')
    }

    if( !hasPcb ) {
        res.unshift('PCB')
    }

    return res;
}

export const projectStatus= [
    {
        id: 0,
        text: 'Geen',
        secondary: '',
        icon: '',
        value: 'NA',
        style: {
            backgroundColor:'#ffffff',
            color: '#000000',
            fontSize: '1.2em',
            whiteSpace: 'pre-wrap',
        }
    },
    {
        id: 1,
        text: 'On hold',
        secondary: '',
        icon: 'question',
        value: 'ON_HOLD',
        style: {
            opacity: '0.45'
        }
    },
    {
        id: 2,
        text: 'Wij hebben een vraag',
        secondary: 'Neem even contact op',
        icon: 'question',
        value: 'WACHT_KLANT',
        style: {
            backgroundColor:'#d82a30',
            color: '#ffffff',
            fontSize: '1.2em',
            whiteSpace: 'pre-wrap',
        }
    },
    {
        id: 3,
        text: 'Geannuleerd',
        secondary: 'Opdracht is geannuleerd',
        icon: 'cancel',
        value: 'GEANNULEERD',
        style: {
            backgroundColor:'#bdbdbd',
            color: '#4b4b4b',
            fontSize: '1.2em',
            whiteSpace: 'pre-wrap',
        }
    },
    {
        id: 4,
        text: 'Verwijderd',
        secondary: 'Opdracht is verwijderd',
        icon: 'cancel',
        value: 'VERWIJDERD',
        style: {
            backgroundColor:'#bdbdbd',
            color: '#4b4b4b',
            fontSize: '1.2em',
            whiteSpace: 'pre-wrap',
        }
    },
]

export const bestelFase= [
    {
        id: 0,
        text: 'Beoordeling',
        secondary: 'We bekijken je gegevens',
        icon: 'detective',
        value: 'AANVRAAG',
        styleName: 'orderstep_AANVRAAG',
        style: {
            backgroundColor:'#2e84cc',
            color: '#ffffff',
            fontSize: '1.2em',
            whiteSpace: 'pre-wrap'
        }
    },
    {
        id: 1,
        text: 'Gereed voor bestelling',
        secondary: 'Je aanbieding staat klaar',
        icon: 'shopping cart',
        value: 'GEREED',
        style: {
            backgroundColor:'#2e84cc',
            color: '#ffffff',
            fontSize: '1.2em',
            whiteSpace: 'pre-wrap',
        }
    },
    {
        id: 2,
        text: 'Opdracht',
        secondary: '',
        icon: 'check',
        value: 'BESTELD',
        style: {
            backgroundColor:'#2fba51',
            color: '#ffffff',
            fontSize: '1.2em',
            whiteSpace: 'pre-wrap',
        }
    },
    {
        id: 3,
        text: 'Wij hebben een vraag',
        secondary: 'Neem even contact op',
        icon: 'question',
        value: 'WACHT_KLANT',
        style: {
            backgroundColor:'#d82a30',
            color: '#ffffff',
            fontSize: '1.2em',
            whiteSpace: 'pre-wrap',
        }
    },
    {
        id: 4,
        text: 'Geannuleerd',
        secondary: 'Opdracht is geannuleerd',
        icon: 'cancel',
        value: 'GEANNULEERD',
        style: {
            backgroundColor:'#bdbdbd',
            color: '#000000',
            fontSize: '1.2em',
            whiteSpace: 'pre-wrap',
        }
    },
    {
        id: 5,
        text: 'Verwijderd',
        secondary: 'Opdracht is verwijderd',
        icon: 'cancel',
        value: 'VERWIJDERD',
        style: {
            backgroundColor:'#bdbdbd',
            color: '#000000',
            fontSize: '1.2em',
            whiteSpace: 'pre-wrap',
        }
    },
]

export const voorbereidingFase= [
    {
        id: 0,
        text: 'Productie',
        secondary: 'Nog niet gestart',
        icon: '',
        value: 'NA',
        style: {
            backgroundColor:'#ffffff',
            color: '#000000',
            fontSize: '1.2em',
            whiteSpace: 'pre-wrap',
        }
    },
    {
        id: 1,
        text: 'Voorbereiding',
        secondary: 'Wij zijn aan het inkopen',
        icon: 'rocket',
        value: 'VOORBEREIDEN',
        style: {
            backgroundColor:'#2e84cc',
            color: '#ffffff',
            fontSize: '1.2em',
            whiteSpace: 'pre-wrap',
        }
    },
    {
        id: 2,
        text: 'Voorbereiding',
        secondary: 'Wij zijn aan het inchecken',
        icon: 'crosshairs',
        value: 'CHECKIN',
        style: {
            backgroundColor:'#2e84cc',
            color: '#ffffff',
            fontSize: '1.2em',
            whiteSpace: 'pre-wrap',
        }
    },
    {
        id: 3,
        text: 'Productie',
        secondary: 'Wij zijn aan het produceren',
        icon: 'cogs',
        value: 'IN_PRODUCTIE',
        style: {
            backgroundColor:'#2e84cc',
            color: '#ffffff',
            fontSize: '1.2em',
            whiteSpace: 'pre-wrap',
        }
    },
    {
        id: 4,
        text: 'Deel gereed',
        secondary: 'De eerste PCBA\'s zijn gereed',
        icon: 'pie chart',
        value: 'DEEL_GEREED',
        style: {
            backgroundColor:'#2e84cc',
            color: '#ffffff',
            fontSize: '1.2em',
            whiteSpace: 'pre-wrap',
        }
    },
    {
        id: 5,
        text: 'Gereed',
        secondary: 'Alle PCBA\'s zijn gereed',
        icon: 'check',
        value: 'GEREED',
        style: {
            backgroundColor:'#2fba51',
            color: '#ffffff',
            fontSize: '1.2em',
            whiteSpace: 'pre-wrap',
        }
    }
]

export const leveringFase = [
    {
        id: 0,
        text: 'Levering',
        secondary: 'Nog niet geleverd',
        icon: '',
        value: 'NA',
        style: {
            backgroundColor:'#ffffff',
            color: '#000000',
            fontSize: '1.2em',
            whiteSpace: 'pre-wrap',
        }
    },
    {
        id: 1,
        text: 'Deellevering',
        secondary: 'Eerste PCBA\'s zijn verstuurd',
        icon: 'pie chart',
        value: 'DEEL_VERZONDEN',
        style: {
            backgroundColor:'#2e84cc',
            color: '#ffffff',
            fontSize: '1.2em',
            whiteSpace: 'pre-wrap',
        }
    },
    {
        id: 2,
        text: 'Klant test de proto\'s',
        secondary: 'Wij wachten op de bevindingen',
        icon: 'plug',
        value: 'KLANT_TEST',
        style: {
            backgroundColor:'#2e84cc',
            color: '#ffffff',
            fontSize: '1.2em',
            whiteSpace: 'pre-wrap',
        }
    },
    {
        id: 3,
        text: 'Levering compleet',
        secondary: 'Alle PCBA\'s zijn verstuurd',
        icon: 'shipping',
        value: 'VERZONDEN',
        style: {
            backgroundColor:'#2e84cc',
            color: '#ffffff',
            fontSize: '1.2em',
            whiteSpace: 'pre-wrap',
        }
    },
    {
        id: 4,
        text: 'Modificatie',
        secondary: 'Wij passen de PCBA(\'s) aan',
        icon: 'cogs',
        value: 'MOD',
        style: {
            backgroundColor: '#f9be38',
            color: '#000000',
            fontSize: '1.2em',
            whiteSpace: 'pre-wrap',
        }
    },
    {
        id: 5,
        text: 'Gefactureerd',
        secondary: 'De factuur is verstuurd',
        icon: 'credit card',
        value: 'GEFACTUREERD',
        style: {
            backgroundColor:'#2fba51',
            color: '#ffffff',
            fontSize: '1.2em',
            whiteSpace: 'pre-wrap',
        }
    },
    {
        id: 6,
        text: 'Betaald',
        secondary: 'De opdracht is betaald',
        icon: 'check',
        value: 'BETAALD',
        style: {
            backgroundColor:'#2fba51',
            color: '#ffffff',
            fontSize: '1.2em',
            whiteSpace: 'pre-wrap',
        }
    },
]

export const fases = {
    bestelFase,
    voorbereidingFase,
    leveringFase,
    projectStatus
}

export function getFase(value, list) {
    let res= {};
    list.forEach(s => {
        if( s.value === value) res= s;
    })
    return res;
}

export function getFaseText(value, list) {
    let res= value;
    list.forEach(s => {
        if( s.value === value) res= s.text;
    })
    return res;
}

export function getFaseTextSec(value, list) {
    let res= value;
    list.forEach(s => {
        if( s.value === value) res= s.secondary;
    })
    return res;
}

export function getFaseStyle(value, list) {
    let res= {};
    list.forEach(s => {
        if( s.value === value) res= s.style;
    })
    return res;
}

export function getFaseStyleName(value, list) {
    let res= '';
    list.forEach(s => {
        if( s.value === value) res= s.styleName;
    })
    return res;
}


export function isAdmin(user) {
    if( user != null && user.roles != null && user.roles.every((v) => v.name === "ROLE_ADMIN") && !user.renderAsUser ) {
        return true;
    } else {
        return false;
    }
}

export function isEmployee(user) {
    if( isAdmin(user) ) return true;

    if( user != null && user.roles != null && user.roles.every((v) => v.name === "ROLE_EMPLOYEE") && !user.renderAsUser ) {
        return true;
    } else {
        return false;
    }
}

export function isTestUser(user) {
    if( isAdmin(user) ) return true;

    if( user != null && user.email != null && user.email === 'test@deltaproto.com' ) {
        return true;
    } else {
        return false;
    }
}

export function hasRole(user, role) {
    if( user != null && role === 'ROLE_MICROPLACER' && user.renderAsMicroplacerUser ) {
        return true;
    }
    else if( user != null && user.roles != null && user.roles.every((v) => v.name === role) ) {
        return true;
    } else {
        return false;
    }
}

export const minOrderQuantity= 1;
export const maxOrderQuantity= 100;

export const defaultCalculatorValues= {
    numPcbs: 5,
    selectedDays: 20,
    sizeX: 80,
    sizeY: 50,
    thickness: 'TH_1_6',
    trackWidth: 'TW_0_15',
    drillSize: 'DS_0_30',
    finish: 'GOLDENIG',
    layers: '2',
    type: 'RIGID',
    soldermask: 'GREEN',
    silkScreen: 'WHITE',
    estComponentCost: 25.00,
    numberOfComponents: 25,
    numberOfPlacements: 60,
    sides: 1,
    averageComponentSize: 'CS_0603',
    numberOfBGAs: 0,
    customerSuppliesPCBs: false,
    customerSuppliesStencil: false,
    customerSuppliesAllComponents: false,
    extraPcb: false,
    specialVias: false,
    splitDelivery: true,
    remark: '',
    name: '',
    days: [5, 10, 20],
    amounts: [4, 5, 6],
    files: [],
    options: [0,1,2],
};

export function fetchData(url, content, params, plain) {

    let lang = collectparams('lang');

    let config;
    if( content != null ) {

        let body= JSON.stringify(
            content
        );

        config = {
            method: 'POST',
            credentials: 'same-origin',
            params: params,
            headers: new Headers({
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Accept-Language': lang ? lang : 'nl'
            }),
            body: body
        }
    } else {
        config = {
            method: 'GET',
            credentials: 'same-origin',
            params: params,
            headers: new Headers({
                'Accept': 'application/json',
                'Accept-Language': lang ? lang : 'nl'
            })
        }
    }

    return fetch(url, config)
}

export function fetchFormData(url, content) {

    let lang = collectparams('lang');

    let form = new FormData();

    Object.keys(content).forEach(c => {
        form.append(c, content[c]);
    })

    let config = {
        method: 'POST',
        credentials: 'same-origin',
        headers: new Headers({
            'Accept-Language': lang ? lang : 'nl'
        }),
        body: form

    }

    return fetch(url, config)
}

export function collectparams( name ) {
    name = name.replace(/([[]])/g,"\\$1");
    var regex = new RegExp("[\\?&]"+name+"=([^&#]*)"),
        results = regex.exec( window.location.href );
    return results? results[1]:"";
}

export function handleErrors(response) {
    if (!response.ok) {
            //throw Error(response.json());
        throw Error(response.statusText);
    }
    return response;
}

export function validateEmail(email) {
    var re = /^(([^<>()[]\\.,;:\s@"]+(\.[^<>()[]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

export function getURLParameter(name) {
    return decodeURIComponent((new RegExp('[?|&]' + name + '=([^&;]+?)(&|#|;|$)').exec(window.location.search) || [null, ''])[1].replace(/\+/g, '%20')) || null;
}

export function encodeQueryData(data) {
    let ret = [];
    for (let d in data)
        ret.push(encodeURIComponent(d) + '=' + encodeURIComponent(data[d]));
    return '?' + ret.join('&');
}

export function encodeUriObject(obj) {
    let res= "";
    for (var key in obj) {
        if (obj.hasOwnProperty(key)) {
            res= res + (res === "" ? '?' : '&') + encodeURIComponent(key) + '=' + encodeURIComponent(obj[key]);
        }
    }
    return res;
}

export function getColSize(sheet) {
    let colSize= 0;
    for (let i = 0; i < sheet.length; i++) {
        colSize= sheet[i].length > colSize ? sheet[i].length : colSize
    }
    return colSize
}

export function getPrice(data, numPcbs, selectedDays) {

    if( numPcbs === 0 || isNaN(numPcbs) || isNaN(selectedDays) ) {
        return '';
    }

    let res= '';

    data && data.forEach((v) => {
        if( (parseInt(v.days) === parseInt(selectedDays)) && (parseInt(v.amount) === parseInt(numPcbs)) ) {
            res= v.price;
        }
    })

    return res.toLocaleString("nl-NL") + ',-';
}

export function navigateTabs(tabName){
    let projectTab = document.getElementsByClassName('projectTabs');
    for (let i = 0; i < projectTab.length; i++) {
        projectTab[i].style.display = 'none';
    }
    document.getElementById(tabName).style.display = 'block';
}

export function getCompanyName(user) {
    if( user && user.deliveryAddress && user.deliveryAddress.length ) {
        return user.deliveryAddress[0].companyName
    }

    return '';
}

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    };
}

export function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(
        getWindowDimensions()
    );

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return windowDimensions;
}

export const fullscreenstyle= {
    position: 'fixed',
    left: 0,
    top: 0,
    backgroundColor: 'white',
    width: '100vw',
    height: 'calc(100vh)',
    zIndex: 9999
}

export function fullscreen( set ) {
    var isInFullScreen = (document.fullscreenElement && document.fullscreenElement !== null) ||
        (document.webkitFullscreenElement && document.webkitFullscreenElement !== null) ||
        (document.mozFullScreenElement && document.mozFullScreenElement !== null) ||
        (document.msFullscreenElement && document.msFullscreenElement !== null);

    var docElm = document.documentElement;
    if (!isInFullScreen && set) {
        if (docElm.requestFullscreen) {
            docElm.requestFullscreen();
        } else if (docElm.mozRequestFullScreen) {
            docElm.mozRequestFullScreen();
        } else if (docElm.webkitRequestFullScreen) {
            docElm.webkitRequestFullScreen();
        } else if (docElm.msRequestFullscreen) {
            docElm.msRequestFullscreen();
        }
    } else if( !set ) {
        if (document.exitFullscreen) {
            document.exitFullscreen();
        } else if (document.webkitExitFullscreen) {
            document.webkitExitFullscreen();
        } else if (document.mozCancelFullScreen) {
            document.mozCancelFullScreen();
        } else if (document.msExitFullscreen) {
            document.msExitFullscreen();
        }
    }
}

export function ordinal_suffix_of(i) {
    var j = i % 10,
        k = i % 100;
    if (j == 1 && k != 11) {
        return i + "st";
    }
    if (j == 2 && k != 12) {
        return i + "nd";
    }
    if (j == 3 && k != 13) {
        return i + "rd";
    }
    return i + "th";
}