import React, {Component} from "react";
import {withRouter} from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import {clearBomData, getBomOrders, openBomOrder, setBomView} from "./actions";
import {defaultSelectedColumns, fetchAllBomData} from "./bom/functions";
import {Icon, Popup} from "semantic-ui-react";
import Translate from "./Translate";
import BasicProjectMenu from "./basic/BasicProjectMenu";
import {hasRole, isEmployee} from "./actions/functions";
import {Link} from "react-router-dom";
import {Helmet} from "react-helmet";
import BomMicroPlacer from "./bom/BomMicroPlacer";

class ProjectViewMicroplacer extends Component {

    state = {}

    componentDidMount() {
        let id= this.props.match ? this.props.match.params.bomId : null;

        if( id ) {
            if( id !== 'new' ) {
                fetchAllBomData(id, null, null, this.props.dispatch)
            } else {
                this.props.dispatch(clearBomData())
            }
        }
    }

    onViewChange(name) {
        switch (name) {
            case 'projectname':
                this.setState({editPrintName: this.props.print.name})
                break;
            case 'home':
                this.props.history.push('/projects-microplacer')
                break;
            case 'orders':
                this.props.dispatch(openBomOrder(true))
                this.props.dispatch(getBomOrders(this.props.print.id))
                break;
            default:
                this.setState({activeName: name})
                this.props.dispatch(setBomView(defaultSelectedColumns, name))
                break;
        }
    }

    getLinkTo(name) {
        return '/projects-microplacer/' + (this.props.print ? this.props.print.id : '') + '/' + name;
    }

    render() {
        let admin = isEmployee(this.props.currentUser) || hasRole(this.props.currentUser, 'ROLE_MICROPLACER');


        let page= this.props.match ? this.props.match.params.page : 'bom';

        const p = this.props.print;

        return <main id = {'microplacerproject'} className='pageContainerFullWidth'>

            <Helmet>
                <title>{(p && p.name) ? p.name : '...'} - DeltaProto</title>
            </Helmet>

            <main className='pageContainer' style={{padding: '0px', margin: 'auto'}}>
                <BasicProjectMenu
                    menu={[
                        {name: 'home', item: <Icon name='home'/>},
                        {name: 'projectname', item: <div>
                                {this.props.print ?
                                    admin ? <Popup content={<Translate value="edituplacerprojectname"/> } trigger={<span>
                                                    {this.props.print.name}</span>}/> : this.props.print.name
                                    : '...'}
                            </div>},
                        {name: 'bom', item: 'BOM'},
                        {name: 'orders', item: 'Orders', bstStep: 'Orders'},
                        {name: 'production', item: 'Productie', bstStep: 'Productie'}
                    ]}
                    menuRight={[
                        /*{ name: 'chat', item: 'Chat'}*/
                    ]}
                    activeName={page}
                    onTabChange={(name) => {
                        if( name === 'home' ) {
                            this.onViewChange(name);
                        } else if( this.props.print ) {
                            this.onViewChange(name);

                            let url = this.getLinkTo(name);
                            this.props.history.push(url)
                        }
                    }}
                />
            </main>

            <BomMicroPlacer view={page} onCloseView={() => {
                let url = this.getLinkTo('bom');
                this.props.history.push(url)
            }}/>
        </main>
    }
}

const mapStateToProps = (state) => {
    return {
        lang: state.main.lang,
        currentUser: state.main.currentUser,
        print: state.bom.print,
    }
}

export default connect(mapStateToProps)(withRouter(ProjectViewMicroplacer))
