import React, {Component} from 'react';
import connect from "react-redux/es/connect/connect";
import './App.css';
import PropTypes from "prop-types";
import {formatDateTime, isEmployee} from "./actions/functions";
import {ProjectCardOverviewInfo} from "./ProjectCardOverviewInfo";
import {Button, Icon, Loader, Modal, Popup} from "semantic-ui-react";
import {fetchProjectStats, setProjectParkedStatus} from "./actions";

class ProjectCardOverview extends Component{

    state = {
        parkProjectOpen: false
    }

    componentDidMount() {
        this.onItemClick= this.onItemClick.bind(this);
    }

    onItemClick = (name) => {
        if( this.props.onOpenChangeModal != null ) {
            this.props.onOpenChangeModal(name);
        }
    }

    renderItem(value, onClickName, onMouseEnter = null) {
        let button = this.props.onOpenChangeModal != null;

        if( button && onClickName ) {
            return <div onMouseEnter={onMouseEnter}>
                <button className='link-button' onClick={() => this.onItemClick(onClickName)}>
                    {value}
                </button>
            </div>
        }
        return <div onMouseEnter={onMouseEnter}>
            {value ? value : ''}
        </div>
    }

    updateStats = () => {
        this.props.dispatch(fetchProjectStats(this.props.project.id))
    }

    render() {

        let admin= isEmployee(this.props.currentUser)
        const {project} = this.props;

        if( admin ) {

            const fetchingStats = project != null && (project.bomStats === null || project.bomStats.fetching)

            return (
                <div>
                <section className={'projectOverview'}>

                    {this.renderItem(this.props.companyName)}
                    {this.renderItem(this.props.ownerName, 'owner')}
                    {this.renderItem(this.props.aanvraagDate)}
                    {this.renderItem(this.props.customerProjectName, 'name')}

                    <Popup trigger={this.renderItem(this.props.quantity + ' PCBA', null, this.updateStats)} content={(project != null && project.bomStats != null) ?
                        <div>
                            <div>{project.bomStats.pcbNeed} PCB's</div>
                            <div>{project.bomStats.pcbApproved} PCB's goedgekeurd</div>
                            <div>{project.bomStats.pcbReceived} PCB's ontvangen</div>
                            <br/>
                            <div>{project.bomStats.stencilNeed} Stencil(s)</div>
                            <div>{project.bomStats.stencilApproved} Stencil(s) goedgekeurd</div>
                            <div>{project.bomStats.stencilReceived} Stencil(s) ontvangen</div>
                            <br/>
                            <div>{project.bomStats.bomLines} BOM-Regels</div>
                            <div>{project.bomStats.bomLinesDone} BOM-Regels met een geselecteerd part</div>
                            <br/>
                            <div>{project.bomStats.checkinOrdered} Bestelde BOM-Regels</div>
                            <div>{project.bomStats.checkinDone} Bestelde BOM-Regels ingechecked</div>
                            <br/>
                            <div>{project.bomStats.hasCentroid ? 'Centroid geladen' : 'Geen Centroid geladen'}</div>
                            <div>{project.bomStats.designators} Designators</div>
                            <div>{project.bomStats.designatorsWithCentroid} Designators met centroid informatie</div>
                            <br/>
                            <div>{project.bomStats.stencilNeed} SMD sides</div>
                            <div>{project.bomStats.totalDesignators} Actieve plaatsingen</div>

                            <Loader active={fetchingStats}/>

                        </div> : <div></div>} position='bottom center' wide='very' hoverable/>


                    {this.renderItem(this.props.selectedDays + ' wday' + (this.props.deliveryDate ? ' (' + this.props.deliveryDate + ')' : ''), 'deliveryDate')}
                    {this.renderItem(this.props.internalName, 'internalName')}
                    {this.renderItem(this.props.price)}

                </section>
                    {(admin && this.props.parked) ? <div style={{backgroundColor: 'var(--main-DP2Yellow)', color: 'black', padding: 2, fontSize: '12px'}}>
                        🚗   Project is geparkeerd per {formatDateTime(this.props.parked)}   🚗 <button className='link-button' onClick={() => this.setState({parkProjectOpen: true})}>opheffen</button>
                        <Modal open={this.state.parkProjectOpen} closeIcon onClose={() => this.setState({parkProjectOpen: false})}>
                            <Modal.Header>Project parkeren</Modal.Header>
                            <Modal.Content>
                                <p>Weet je zeker dat je de parkering wilt opheffen?</p>
                            </Modal.Content>
                            <Modal.Actions>
                                <Button onClick={() => this.setState({parkProjectOpen: false})}>Annuleren</Button>
                                <Button primary onClick={() => {
                                    this.props.dispatch(setProjectParkedStatus(project.id, false, () => {
                                        this.setState({parkProjectOpen: false})
                                    }))
                                }}>Parkeren opheffen</Button>
                            </Modal.Actions>
                        </Modal>
                    </div> : null}
                </div>
            )
        } else {
            return (
                <section className={'projectOverview'}>

                    {this.renderItem(this.props.companyName)}
                    {this.renderItem(this.props.ownerName)}
                    {this.renderItem(this.props.customerProjectName, 'name')}
                    {this.renderItem(this.props.poRef ? this.props.poRef : 'Geen referentie', 'reference')}
                    {this.renderItem(this.props.quantity + ' PCBA')}
                    {this.renderItem(this.props.selectedDays + ' wday' + (this.props.deliveryDate ? ' (' + this.props.deliveryDate + ')' : ''))}
                    {this.renderItem(this.props.internalName)}
                    {this.renderItem(this.props.price)}

                </section>
            )
        }
    }
}

ProjectCardOverview.propTypes = {
    onOpenChangeModal: PropTypes.func,
    companyName: PropTypes.string,
    ownerName: PropTypes.string,
    customerProjectName: PropTypes.string,
    poRef: PropTypes.string,
    orderDate: PropTypes.number,
    deliveryDate: PropTypes.any,
    aanvraagDate: PropTypes.number,
    quantity: PropTypes.number,
    selectedDays: PropTypes.any,
    internalName: PropTypes.string,
    price: PropTypes.string,
    isLink: PropTypes.bool,
    parked: PropTypes.string
};

ProjectCardOverview.defaultProps = {
    onOpenChangeModal: null,
    companyName: 'Default text',
    ownerName: 'Default text',
    customerProjectName: 'Default text',
    poRef: 'Default text',
    orderDate: 11111111,
    deliveryDate: 11111111,
    aanvraagDate: '',
    quantity: 0,
    selectedDays: 10,
    internalName: 'ABCD1234',
    price: '2000',
    isLink: false,
    parked: null
};

const mapStateToProps = (state) => {
    return {
        currentUser: state.main.currentUser
    }
}

export default connect(mapStateToProps)(ProjectCardOverview)