import React, {Component} from 'react';
import './App.css';
import {Button, Loader} from "semantic-ui-react";
import connect from "react-redux/es/connect/connect";
import {addNewBlogItem, getBlogItem, receivePrint, startReceivePrint} from "./actions";
import BlogItem from "./BlogItem";
import {isEmployee} from "./actions/functions";
import UploadFile from "./oldui/UploadFile";
import {encodeProjectId} from "./bom/functions";
import Translate from "./Translate";


class Blog extends Component {

    state = {upload: 'test'}

    componentDidMount() {
        if( this.props.blogitems.length === 0 ) {
            this.props.dispatch(getBlogItem(0))
        }
    }

    render() {

        let admin= isEmployee(this.props.currentUser);

        return (
            <main id = {'blog'} className = {'pageContainer'}>

                <div style={{margin: 'auto'}}>
                    <div style={{display: 'flex'}}>
                        {admin && <Button primary style={{textAlign: 'right', cursor: 'pointer', float: 'center', marginBottom: '4em'}}
                                          onClick={() => this.props.dispatch(addNewBlogItem())}><Translate value='nieuwe_schrijven'/>
                        </Button>}

                        {admin && <UploadFile icon={false} text={<Translate value='bestand_uploaden'/>} url={'/api/saveattachment'} color='blue'
                                    onResult={(body) => {
                                        this.setState({upload: body})
                                    }}
                        />}
                    </div>
                </div>

                {this.state.upload && this.state.upload.path ? 'URL= ' + this.state.upload.path : ''}

                {this.props.blogitems.map((b,i) => <BlogItem b={b} index={(i + 1) === this.props.blogitems.length ? i : null}/>)}

                {this.props.fetchingBlogItem && <Loader style={{paddingBottom: '2em'}} active inline='centered' />}

            </main>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        lang: state.main.lang,
        blogitems: state.main.blogitems,
        fetchingBlogItem: state.main.fetchingBlogItem,
        currentUser: state.main.currentUser
    }
}

export default connect(mapStateToProps)(Blog)
