import React, {Component} from 'react';
import PropTypes from "prop-types";
import connect from "react-redux/es/connect/connect";
import {Button, Divider, Icon, Input, Message, Modal, Popup} from "semantic-ui-react";
import BomCustomerView from "./BomCustomerView";
import {
    bomSetSearchQuery, openEditBomRow, openOrderPartAdd,
    searchPart,
    setKlantLevertAan, setSelectedBomRow,
    setSelectedBuddyPart,
    setSelectedSupplierPart
} from "../actions";
import BomOrderparts from "./BomOrderparts";
import {formatDateTime, isEmployee} from "../actions/functions";
import BomAddPart from "./BomAddPart";
import BomRemarkEdit from "./BomRemarkEdit";
import {renderSupplier} from "./functions";
import Translate from "../Translate";
import MyInfo from "../oldui/MyInfo";


class BomSearchDialog extends Component {

    state= {
        hasSearched: false
    }

    componentDidMount() {
        if( this.props.searchQuery ) {
            this.props.dispatch(searchPart(this.props.searchQuery, this.props.usebuddy))

            this.setState({hasSearched: true})
        }

        this.searchInput && this.searchInput.focus()
    }

    handleSearch = (designators) => {

        //limit to 10 designators
        designators = designators.split(',').slice(0,10).join(',')

        let useCompanyStock = true;
        let printId = this.props.print ? this.props.print.id : null;

        this.props.dispatch(searchPart(this.props.searchQuery, this.props.usebuddy, designators, useCompanyStock, printId))

        this.setState({hasSearched: true})
    }

    render() {

        let {searchQuery, header} = this.props;
        let r = this.props.searchRow;

        let admin = this.props.isAdmin;

        let administrator = isEmployee(this.props.currentUser)

        let hasSelection = (r && (r.selectedSupplierPart || r.selectedBuddyPart || r.klantLevertAan))

        const designators = r ? r.designators.map(d => d.value).join(', ') : '';

        const requiredAmount = this.props.amount * (r ? r.designators.filter(d => d.place).length : 0)

        return <Modal size='large' closeIcon={true} open={this.props.open} onClose={() => this.props.onClose && this.props.onClose()}>
            <Modal.Header>
                Search Nexar {header ? `(${header})` : ''}

                {r ? <div style={{display: 'inline-block', float: 'right'}}>
                    {admin && <>
                        <div style={{fontSize: 14, marginRight: 8, display: 'inline-block', width: 300}}>
                            <BomRemarkEdit value={r.remark} bomLineId={this.props.searchRow.id}/>
                        </div>
                    </>}
                </div> : null}
            </Modal.Header>
            <Modal.Content scrolling >
                <Modal.Description>

                    <div style={{position: 'sticky', top: '-3.9em', zIndex: 2, backgroundColor: 'white'}}>
                        {r ? <BomCustomerView r={r} requiredAmount={requiredAmount} onClick={(v) => {
                            this.props.dispatch(bomSetSearchQuery(v))
                            this.props.dispatch(searchPart(v, this.props.usebuddy, designators))
                            this.setState({hasSearched: true})
                        }}/> : null}

                        <div style={{textAlign: 'center'}}>
                            <Input
                                style={{width: '50%'}}
                                ref={(textarea) => { this.searchInput = textarea; }}
                                loading={this.props.searching}
                                value={searchQuery}
                                onChange={(e) => this.props.dispatch(bomSetSearchQuery(e.target.value))}
                                icon={<Icon name='search' circular link onClick={() => this.handleSearch(designators)} />}
                                placeholder='Search...'
                                onKeyDown={(e) => {
                                    if( e.key === 'Enter' ) {
                                        this.handleSearch(designators)
                                    }
                                }}
                            />
                        </div>

                        <Divider/>
                    </div>

                    <BomAddPart/>

                    {(hasSelection && r) ? <div style={{marginTop: 16}}>
                        <div style={{border: '2px solid grey', padding: 3, width: 300, margin: 'auto', textAlign: 'center'}}>
                            <div style={{fontWeight: 'bold', color: ''}}>Let op! Er is al een selectie gemaakt:</div>
                            {r.selectedBuddyPart ? <div>{renderSupplier({supplier: 'DELTAPROTO', sku: r.selectedBuddyPart.name})}</div> : ''}
                            {r.selectedSupplierPart ? <div>{renderSupplier(r.selectedSupplierPart)}</div> : ''}
                            {r.klantLevertAan ? <div>{renderSupplier({supplier: 'KLANT', sku: ''})} of {renderSupplier({supplier: 'MANUAL', sku: ''})}</div> : ''}

                            <div style={{marginTop: 16}}>
                                Als je een nieuwe selecteerd, dan wordt de huidige selectie overschreven.
                            </div>
                        </div>
                    </div> : ''}

                    {this.state.hasSearched ? <div style={{marginTop: 16}}>
                        <BomOrderparts
                            uplacer={this.props.uplacerOwner}
                            requiredAmount={requiredAmount}
                            amount={this.props.amount}
                            list={this.props.searchList}
                            buddyList={this.props.buddySearchList}
                            stockList={this.props.stockSearchList}
                            todos={r ? this.props.searchRow.todos : []}
                            admin={admin}
                            hasSelection={hasSelection}
                            onOrderpart={this.props.onOrderpart}
                            onSupplierpart={this.props.onSupplierpart}
                            onBuddypart={this.props.onBuddypart}
                        />
                        {(this.props.searchList.length === 0 && this.state.hasSearched && !this.props.searchError && !this.props.searching) &&
                        <div style={{textAlign: "center", marginTop: 8}}>Niets gevonden 😥</div>}
                        {(this.props.searchError) && <Message negative icon>
                            <Icon name='warning circle'/>
                            <Message.Content>
                                <Message.Header>Fout bij zoeken, geef door aan Arnoud</Message.Header>
                                <p>
                                    searchQuery: {this.props.searchQuery}<br/>
                                    Datum: {formatDateTime(new Date())}<br/>
                                    Project: {this.props.print.id}
                                </p>
                            </Message.Content>
                        </Message>}
                    </div> : ''}



                </Modal.Description>
            </Modal.Content>
            <Modal.Actions>
                {(r && isEmployee(this.props.currentUser)) ? <>
                    <Button disabled={hasSelection} onClick={() => {
                        this.props.dispatch(setKlantLevertAan(this.props.print.id, this.props.searchRow.id, true))
                        this.props.dispatch(setSelectedBomRow(this.props.searchRow.id, true))
                    }}>Handmatig</Button>

                    <Button
                        onClick={() => this.props.dispatch(openOrderPartAdd(true, null))}
                    >Add part</Button>
                </> : ''}

                {(r && !hasSelection) ? <Popup content={<Translate value={"placedonotplace_info"}/>} trigger={
                    <Button onClick={() => {
                        this.props.dispatch(openEditBomRow(this.props.searchRow.id))
                    }}>
                        <Translate value="placedonotplace"/>
                    </Button>
                }/> : null}

                {r ? <Popup content={<Translate value={administrator ? "kla_info" : "kla_klant_info"}/>} trigger={
                    <Button disabled={hasSelection} onClick={() => {
                        this.props.dispatch(setKlantLevertAan(this.props.print.id, this.props.searchRow.id))
                        this.props.dispatch(setSelectedBomRow(this.props.searchRow.id, true))
                    }}>
                        <Translate value={administrator ? "kla" : "kla_klant"}/>
                    </Button>
                }/> : null}

                <Button
                    onClick={() => this.props.onClose && this.props.onClose()}
                >
                    <Translate value="cancel"/>
                </Button>
            </Modal.Actions>
        </Modal>
    }
}

BomSearchDialog.propTypes = {

    onClose: PropTypes.func,
    amount: PropTypes.number,
    isAdmin: PropTypes.bool,
    header: PropTypes.string,
    open: PropTypes.bool.isRequired,
    usebuddy: PropTypes.bool,
    uplacerOwner: PropTypes.bool,
    onOrderpart: PropTypes.func,
    onSupplierpart: PropTypes.func,
    onBuddypart: PropTypes.func
};

BomSearchDialog.defaultProps = {
    onClose: null,
    isAdmin: false,
    header: null,
    open: false,
    usebuddy: false,
    uplacerOwner: false,
    onOrderpart: null,
    onSupplierpart: null,
    onBuddypart: null
};

const mapStateToProps = (state) => {
    return {
        currentUser: state.main.currentUser,
        searchRow: state.bom.searchRow,
        searchList: state.bom.searchList,
        buddySearchList: state.bom.buddySearchList,
        stockSearchList: state.bom.stockSearchList,
        searching: state.bom.searching,
        searchError: state.bom.searchError,
        searchQuery: state.bom.searchQuery,
        print: state.bom.print,
    }
}

export default connect(mapStateToProps)(BomSearchDialog)