import React from 'react'
import {connect} from "react-redux";
import BomDefaultValues from "./BomDefaultValues";

class BomSettings extends React.Component {
    render() {
        const {print} = this.props;

        if( !print ) {
            return <div></div>
        }

        return <div>
            <BomDefaultValues/>
        </div>
    }
}

const mapStateToProps = (state) => {
    return {
        print: state.bom.print,
    }
}

export default connect(mapStateToProps)(BomSettings)