import React from 'react';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {Form, Modal, Button, Icon, Popup, Dropdown, Divider} from "semantic-ui-react";
import {addNewBomLine, checkBomLine} from "../actions";
import {getRemovedDesignators} from "./BomDesignator";
import Translate from "../Translate";

class BomAddRow extends React.Component {

    state = {
        addModal: false,
        designators: '',
        isResistor: false,
        isCapacitor: false,
        busy: false
    }

    componentDidMount() {
        if( this.props.bomLineId ) {
            this.setState({addModal: true});
        }
    }

    handleChange = (e, { name, value }) => this.setState({ [name]: value })

    render() {
        let removedDesignators = [];
        if( this.state.addModal ) {
            removedDesignators = getRemovedDesignators(this.props.print);
        }

        const row = Math.max(...this.props.print.bom.map(r => parseInt(r.row), 98)) + 1;

        return <div>

            {!this.props.bomLineId ? <Popup trigger={
                <Button icon onClick={() => this.setState({addModal: true})}>
                    <Icon name='add'/>
                </Button>
            } content={'Regel toevoegen'} position='top right'/> : null}

            <Modal closeIcon={true} open={this.state.addModal} onClose={this.closeModal}>
                <Modal.Header>
                    {this.props.bomLineId ? 'Alle gegevens aanpassen en overschrijven' : 'Regel toevoegen'}
                </Modal.Header>
                <Modal.Content>
                    <Modal.Description>
                        <Form>

                            {!this.props.bomLineId ? <>
                                <div>
                                    Row: {row}
                                </div>

                                <Divider/>

                                {this.renderDesignatorInput(removedDesignators)}
                            </> : <div>
                                Designators: {this.props.designators}
                                <br/>
                                <br/>
                            </div>}

                            <Form.Input
                                onChange={this.handleChange} value={this.state.description}
                                label='Description' name='description' fluid
                                placeholder='Description'
                            />

                            <Form.Field style={{display: 'flex', gap: 8}}>
                                <div style={{flex: 1}}>
                                    <Form.Input
                                        onChange={this.handleChange} value={this.state.value}
                                        label='Value' name='value' fluid
                                        placeholder='4.7uF 10% 50V X5R'
                                    />
                                </div>
                                <div style={{flex: 1}}>
                                    <Form.Input
                                        onChange={this.handleChange} value={this.state.package}
                                        label='Package' name='package' fluid
                                        placeholder='1206'
                                    />
                                </div>
                            </Form.Field>
                            <Form.Field style={{display: 'flex', gap: 8}}>

                                <div style={{flex: 1}}>
                                    <Form.Input
                                        onChange={this.handleChange} value={this.state.manufacturer}
                                        label={'Manufacturer'} name='manufacturer' fluid
                                        placeholder='Manufacturer'
                                    />
                                </div>

                                <div style={{flex: 1}}>
                                    <Form.Input
                                        onChange={this.handleChange} value={this.state.mpn}
                                        label='MPN' name='mpn' fluid
                                        placeholder='Manufacturer Part Number'
                                    />
                                </div>

                                <div style={{flex: 1}}>
                                    <Form.Input
                                        onChange={this.handleChange} value={this.state.supplier}
                                        label={'Supplier'} name='supplier' fluid
                                        placeholder='Supplier'
                                    />
                                </div>

                                <div style={{flex: 1}}>
                                    <Form.Input
                                        onChange={this.handleChange} value={this.state.sku}
                                        label='SKU' name='sku' fluid
                                        placeholder='Supplier number, without the supplier name'
                                    />
                                </div>

                                <div style={{flex: 1}}>
                                    <Form.Input
                                        onChange={this.handleChange} value={this.state.cpn}
                                        label='Art. No.' name='cpn' fluid
                                        placeholder='Your own internal article number'
                                    />
                                </div>
                            </Form.Field>

                            {/*<div style={{display: 'flex', gap: 24, paddingTop: 24, marginBottom: -16}}>
                                <Form.Checkbox toggle label='Resistor' name='isResistor' onChange={this.handleChange} value={this.state.isResistor}/>
                                <Form.Checkbox toggle label='Capacitor' name='isCapacitor' onChange={this.handleChange} value={this.state.isCapacitor}/>
                            </div>*/}
                        </Form>
                    </Modal.Description>
                </Modal.Content>
                <Modal.Actions>
                    <Button
                        onClick={this.closeModal}
                    ><Translate value="cancel"/></Button>
                    <Button
                        primary
                        disabled={this.state.busy}
                        onClick={() => {
                            this.setState({busy: true, designators: ''})
                            this.props.dispatch(addNewBomLine(
                                this.props.print.id,
                                this.props.bomLineId,
                                row,
                                this.state.designators,
                                this.state.description,
                                this.state.value,
                                this.state.package,
                                this.state.manufacturer,
                                this.state.mpn,
                                this.state.supplier,
                                this.state.sku,
                                this.state.cpn,
                                (val) => {
                                    this.props.dispatch(checkBomLine(this.props.print.id, val.id)) //execute the validate
                                    this.closeModal()
                                })
                            )
                        }}
                    >
                        {this.props.bomLineId ? 'Aanpassen' : 'Toevoegen'}
                    </Button>
                </Modal.Actions>
            </Modal>
        </div>
    }

    closeModal = () => {
        this.setState({addModal: false, busy: false})
        if( this.props.onClose ) {
            this.props.onClose();
        }
    }

    renderDesignatorInput(removedDesignators) {
        return <Dropdown icon={null} fluid trigger={
            <Form.Input label='Designator(s) (voeg meerde designators toe gescheiden door een comma)' name='designators'
                        onChange={this.handleChange} value={this.state.designators} autoFocus
                        placeholder='C1, C2, C3'
                        onBlur={(e) => {
                            const value = this.state.designators
                            value.split(',').forEach(v => {
                                v = v.trim().toUpperCase();
                                if (v.startsWith('R')) {
                                    this.setState({isResistor: true, isCapacitor: false});
                                } else if (v.startsWith('C')) {
                                    this.setState({isResistor: false, isCapacitor: true});
                                }
                            })
                        }}
            />}
        >
            <Dropdown.Menu>
                {removedDesignators.length ? <Dropdown.Item disabled>Verwijderde designators:</Dropdown.Item> : ''}

                {removedDesignators.map(v => (
                    <Dropdown.Item text={v}
                                   onClick={() => {
                                       if (this.state.designators) {
                                           this.setState({designators: this.state.designators + ', ' + v})
                                       } else {
                                           this.setState({designators: v})
                                       }
                                   }}/>
                ))}
            </Dropdown.Menu>
        </Dropdown>;
    }
}

BomAddRow.propTypes = {
    orderPart: PropTypes.object,
    bomLineId: PropTypes.string,
    onClose: PropTypes.func,
};

BomAddRow.defaultProps = {
    orderPart: null,
    bomLineId: null,
    onClose: null,
};

const mapStateToProps = (state) => {
    return {
        print: state.bom.print,
    }
}

export default connect(mapStateToProps)(BomAddRow)