import React, { Component } from 'react';
import './App.css';
import Link from "./Link";

class Contact extends Component {
    render() {
        return (
            <section id={'contactDetails'}>
                <p className={'body2'}><Link className='linkWhite' href="https://goo.gl/maps/8AwJwHh63UPzq4W26">DeltaProto B.V.</Link></p>
                <p className={'body2'}><Link className='linkWhite' href="https://goo.gl/maps/8AwJwHh63UPzq4W26">Hellingweg 10</Link></p>
                <p className={'body2'}><Link className='linkWhite' href="https://goo.gl/maps/8AwJwHh63UPzq4W26">1151 CS</Link></p>
                <p className={'body2'}><Link className='linkWhite' href="https://goo.gl/maps/8AwJwHh63UPzq4W26">Broek in Waterland</Link></p>
                <p className={'body2'}><a className='linkWhite' href="tel:+31207173868">020 717 3 868</a></p>
                <p className={'body2'}>KvK 64253988</p>
                <p className={'body2'}>BTW NL855586771B01</p>
                <p className={'body2'}>NL84RABO0119927888</p>
            </section>
        );
    }
}

class Social extends Component {
    render() {
        return (
            <section id={'social'}>
                <Link href="https://www.linkedin.com/company/deltaproto/"><img src={'/images/linkedInLogo.png'} className={'socialLogo'} alt={'LinkendIn'}/></Link>
                <Link href="https://www.facebook.com/deltaproto/"><img src={'/images/fbLogo.png'} className={'socialLogo'} alt={'Facebook page'}/></Link>
                <Link href="https://www.youtube.com/channel/UCicF5MFJohgYe6IGFs5GJ_w"><img src={'/images/youtubeLogo.png'} className={'socialLogo'} alt={'Youtube Channel'}/></Link>
            </section>
        );
    }
}

class Footer extends Component {
    render() {
        return (
            <section id={'footer'}>
                <Contact/>
                <Social/>
                <div id={'footerLegal'}>
                    <p className={'subtitle2'}><button className="link-button footer__link" data-a="info" data-b="deltaproto" data-c="com"
                                                  onClick={(e) => window.location.href = 'mailto:' + e.target.dataset.a + '@' + e.target.dataset.b + '.' + e.target.dataset.c}
                                                  style={{cursor: 'pointer', color: 'white'}}>E-mail</button></p>
                    {/*<p className={'subtitle2'}><a className={'link'} href={"#"}>Terms and Conditions</a></p>
                    <p className={'subtitle2'}><a className={'link'} href={"#"}>Privacy Policy</a></p>*/}
                </div>
            </section>
        );
    }
}

export default Footer;