import React, { Component } from 'react';
import './App.css';

class Suppliers extends Component{
    render() {
        return (
            <section id={"suppliers"}>
                <div className={"imgContainer"}>
                    <img src={'/images1/arrowLogo.png'} alt={'Arrow Logo'}/>
                </div>
                <div className={"imgContainer"}>
                    <img src={'/images1/digikeyLogo.png'} alt={'Digi-key Logo'}/>
                </div>
                <div className={"imgContainer"}>
                    <img src={'/images1/farnellLogo.svg'} alt={'Farnell Logo'}/>
                </div>
                <div className={"imgContainer"}>
                    <img src={'/images1/mouserLogo.png'} alt={'Mouser Logo'}/>
                </div>
                <div className={"imgContainer"}>
                    <img src={'/images1/rsLogo.png'} alt={'RS Logo'}/>
                </div>
                <div className={"imgContainer"}>
                    <img src={'/images1/tmeLogo.png'} alt={'TME Logo'}/>
                </div>
            </section>
        );
    }
}

export default Suppliers;