import React, { Component } from 'react';
import connect from "react-redux/es/connect/connect";
import Camera from 'react-webcam';
import {Dropdown, Icon, Input, Segment} from "semantic-ui-react";
import PropTypes from "prop-types";
import CentroidSvg from "./CentroidSvg";
import CrosshairSvg from "./CrosshairSvg";
import {filterBom} from "./functions";
import {MaintainAspectRatio} from "../basic/MaintainAspectRatio";
import {selectDesignatorBomProduction, setCameraDevices, updateMohicanSetting} from "../actions";
import {getAveragePositionFromList} from "./functions";
import {getDesignatorColor} from "./functions";
import {gotoPlace} from "./BomProduction";
import jsQR from "jsqr";
import {plankjeArray} from "../reducers/bom";
import {getOrderItemsByTray} from "./functions";


export const WEBCAM_CAMERA_WIDTH = 1920
export const WEBCAM_CAMERA_HEIGHT = 1080

//export const PADDING_FOR_ASPECT_RATIO = '65.13%'
//export const PADDING_FOR_ASPECT_RATIO_16_9 = '56.25%' /* 16:9 Aspect Ratio */
//export const PADDING_FOR_ASPECT_RATIO_4_3 = '75%' /* 4:3 Aspect Ratio */


//1356.77 x 758.41 = 1,7889663902

//1,535446011079203

//1,5238

const defaultValues = {
    top: 100,
    left: 10,
    imageSrc: null,
    cameraType: 'webcam',
    //width: WEBCAM_CAMERA_WIDTH, height: WEBCAM_CAMERA_HEIGHT,
    crosshair: {
        x: (WEBCAM_CAMERA_WIDTH / 2), y: (WEBCAM_CAMERA_HEIGHT / 2)
    },
                     //USB      Pi
    viewWidth: null, //70, /** 72mm **/
    viewHeight: null, //43, /** 49mm **/
    showOverlay: true
}

//0.599

class BomCamera extends Component {

    //state= defaultValues;

    state = {}

    constructor(props) {
        super(props);
        this.takePicture = this.takePicture.bind(this);

        window.addEventListener('mousemove', this.onMouseMove.bind(this), false);
        window.addEventListener('mouseup', this.onMouseUp.bind(this), false);

        this.handleMouseDown = this.handleMouseDown.bind(this)
    }

    componentDidMount() {

        navigator.mediaDevices
            .enumerateDevices()
            .then((devices) => {
                let m_devices = devices.filter(device => device.kind === 'videoinput')
                this.props.dispatch(setCameraDevices(m_devices))
            })
            .catch((err) => {
                console.error(`${err.name}: ${err.message}`);
            });

        /*let d = localStorage.getItem('deltaprotocamera');

        if( d != null && d !== "null" ) {
            let val = JSON.parse(d);
            this.setState({
                ...val,
                isMoving: false,
                isResizing: false,
                setCrosshair: false
            })

            //this.props.dispatch(updateCameraSettings(val))
        }*/

        /*if (!navigator.mediaDevices?.enumerateDevices) {
            console.log("enumerateDevices() not supported.");
        } else {
            // List cameras and microphones.
            navigator.mediaDevices.enumerateDevices()
                .then((devices) => {
                    this.setState({devices: devices})
                    devices.forEach((device) => {
                        console.log(device);
                    });
                })
                .catch((err) => {
                    console.error(`${err.name}: ${err.message}`);
                });
        }*/


        /*this.myTimer = setInterval((ths) => {
            //if( this.props.currentUser != null ) {
            ths.takePicture()
            //}
        }, 1000, this);*/
    }

    /*updateSettings(value) {
        let newSettings = {...this.state, ...value}

        localStorage.setItem('deltaprotocamera', JSON.stringify(newSettings));

        this.setState(value)

        //this.props.dispatch(updateCameraSettings(value))
    }*/

    onMouseMove = (e) => {
        //console.log("Resizer.onMouseMove " + e.clientX);
        if( this.state.isMoving ) {

            const newLeft =  this.state.startLeft + (e.clientX - this.state.startX);
            const newTop =  this.state.startTop + (e.clientY - this.state.startY);

            //console.log('handleMouseMove ' + newWidth)

            this.updateSettings({
                left: newLeft,
                top: newTop
            });

            //this.props.funcResizing( this.props.id, e.clientX, e.clientY);
        } else if( this.state.isResizing ) {

            const newWidth =  this.state.startWidth + (e.clientX - this.state.startX);
            const newHeight =  this.state.startHeight + (e.clientY - this.state.startY);

            this.updateSettings({
                width: newWidth,
                height: newHeight
            });
        }
    }
    onMouseUp = (e) => {
        this.setState({
            isMoving: false,
            isResizing: false
        });
    }

    handleMouseDown = (event, t) => {
        this.setState({
            startX: event.clientX,
            startY: event.clientY,
            startLeft: this.state.left,
            startTop: this.state.top,
            isMoving: true,
        });
    };

    handleMouseDownResize(event, t) {
        this.setState({
            startX: event.clientX,
            startY: event.clientY,
            startWidth: this.state.width,
            startHeight: this.state.height,
            isResizing: true,
        });
    }

    capture = () => {
        //const imageSrc = this.webcam.getScreenshot();
    };

    takePicture() {

        const playerRef = this.webcam;
        const canvasRef = this.canvasRef;
        const imageWidth = playerRef.video.offsetWidth;
        const imageHeight = playerRef.video.offsetHeight;
        [canvasRef.width, canvasRef.height] = [imageWidth, imageHeight];
        const context = canvasRef.getContext('2d');
        context.drawImage(playerRef.video, 0, 0, imageWidth, imageHeight);
        // Trigger the callback function
        //if (this.props.onCapture) {
        //const webPData = canvasRef.toDataURL('image/webp');
        canvasRef.toBlob((blob) => {
            //console.log(blob)
            //console.log(webPData)
            //this.props.onCapture({ blob, webP: webPData, file: new File([webPData], `${new Date().getTime()}.png`) });


            var imageData = context.getImageData(0, 0, imageWidth, imageHeight);
            var code = jsQR(imageData.data, imageData.width, imageData.height, {
                inversionAttempts: "dontInvert",
            });


            if (code) {
                console.log("Found QR code", code);
            }

        });

    }

    handleClose() {
        if( this.props.onClose ) {
            this.props.onClose()
        }
    }

    setRef = webcam => {
        this.webcam = webcam;
    };

    renderCamera() {

        const mohicanPlankjePosition = this.props.mohicanPlankjePosition;
        const mohicanSettings = this.props.mohicanSettings
        const {crosshair} = mohicanSettings

        const videoConstraints = {
            width: mohicanSettings.resolution.width,
            height: mohicanSettings.resolution.height,
            deviceId: mohicanSettings.cameraId
        };

        //if( mohicanSettings.lens && mohicanSettings.lens === '25mm' ) {
        //    videoConstraints.width = 1280
        //    videoConstraints.height = 720
        //}

        let viewWidth = mohicanSettings.viewWidth;
        let viewHeight = (mohicanSettings.viewWidth/16)*9;

        let scale = mohicanSettings.cameraZoom;

        if( mohicanSettings.viewWidth && mohicanSettings.viewWidth !== 50 ) {
            scale = scale * (1 / (mohicanSettings.viewWidth/50))
        }

        let mohicanPosition = getAveragePositionFromList(this.props.mohicanPositionList);

        return <div style={{width: '100%', height: '100%', position: 'relative'}} onClick={() => {
            this.takePicture()
        }}>

            <div style={{overflow: 'hidden'}}>

                {this.props.setCrosshair ? null : this.renderCrossHairOverlay(crosshair)}

                {mohicanPlankjePosition ? this.renderPlankjeOverlay(mohicanPlankjePosition, crosshair, scale, viewWidth, viewHeight, mohicanPosition) : null}

                <div id='centroid' className=''
                     style={{position: 'absolute', zIndex: 3, width: '100%'}}>
                    <MaintainAspectRatio>
                        <div style={{width: '100%', height: '100%'}}>
                            <CentroidSvg
                                scale={scale}
                                fontSize={this.props.mohicanSettings.fontSize}
                                viewHeight={viewHeight} viewWidth={viewWidth}
                                mohicanPosition={mohicanPosition}
                                crosshairPosition={{
                                    y: (crosshair.y / WEBCAM_CAMERA_HEIGHT) * viewHeight,
                                    x: (crosshair.x / WEBCAM_CAMERA_WIDTH) * viewWidth
                                }}
                                onClick={(d) => {
                                    this.props.dispatch(selectDesignatorBomProduction(d.d))
                                }}
                                onDoubleClick={(d) => {
                                    console.log('onDoubleClick ' + JSON.stringify(d.d))

                                    this.props.dispatch(selectDesignatorBomProduction(d.d))

                                    const printId = this.props.print.id;

                                    //let {bomProductionSelected} = this.props;

                                    let bomProductionName = this.props.currentUser.firstName;

                                    gotoPlace(printId, d.d, this.props.mohicanPcbPosition, null, bomProductionName, this.props.dispatch)

                                    //moveTo(d.x, d.y)
                                }}
                                pcbPosition={this.props.mohicanPcbPosition}
                                items={this.props.print.bom.map(r => {
                                    return r.designators.filter(d => {
                                        return filterBom(r, d, this.props.bomFilter, this.props.bomSearch)
                                    }).map(d => {
                                        const bomProduction = this.props.bomProductionList.find(pl => pl.id === this.props.bomProductionSelected)
                                        const color = getDesignatorColor(r, d, this.props.selectedDesignator, null, bomProduction)
                                        return {...d, d: {...d, r: r}, color}
                                    })
                                }).flat()}
                            />
                        </div>
                    </MaintainAspectRatio>
                </div>

                {this.props.setCrosshair ? this.renderCrossHairOverlay(crosshair) : null}

                <Camera
                    imageSmoothing={false}
                    audio={false}
                    ref={(ref) => this.webcam = ref}
                    videoConstraints={videoConstraints}
                    className='videoInsert'
                    style={{
                        filter: `brightness(${this.props.mohicanSettings.brightness})`,
                        transform: this.props.mohicanSettings.cameraFlip ? 'scaleY(-1) scaleX(-1)' : null,
                        width: '100%'
                    }}
                    onUserMediaError={(e) => {
                        //window.alert(JSON.stringify(e))
                    }}
                />

                <canvas style={{display: 'none'}} ref={(ref) => this.canvasRef = ref} />

            </div>
        </div>
    }

    renderPlankjeOverlay(plankjePosition, crosshair, scale, viewWidth, viewHeight, mohicanPosition) {
        return <div id='plankje' className=''
                    style={{position: 'absolute', zIndex: 3, width: '100%'}}>
            <MaintainAspectRatio>
                <div style={{width: '100%', height: '100%'}}>
                    <CentroidSvg
                        scale={scale}
                        fontSize={this.props.mohicanSettings.fontSize}
                        viewHeight={viewHeight} viewWidth={viewWidth}
                        mohicanPosition={mohicanPosition}
                        crosshairPosition={{
                            y: (crosshair.y / WEBCAM_CAMERA_HEIGHT) * viewHeight,
                            x: (crosshair.x / WEBCAM_CAMERA_WIDTH) * viewWidth
                        }}
                        //pcbPosition={this.props.mohicanPcbPosition}
                        items={plankjeArray.map(i => {
                            let x = plankjePosition.x;

                            if( i === 1 ) {
                                x = x - 8.5
                            } else if ( i > 2 ) {
                                x = x + (8.5 * (i-2))
                            }

                            let tray =  this.props.selectedPlankje + String(i).padStart(2, '0')

                            let value = tray;
                            let values = [];

                            try {

                                let parts = getOrderItemsByTray(tray, this.props.bomOrderList)

                                if (parts && parts.length) {
                                    values = parts.map(p => {
                                        let description = p.part.orderpart.shortDescription;
                                        if (p.part.orderpart.specification && p.part.orderpart.specification.displayValueOnly) {
                                            description = p.part.orderpart.specification.displayValueOnly
                                        }

                                        return [p.supplier + ' ' + p.part.sku, description]
                                    }).flat()
                                }
                            } catch (e) {
                                console.log(e)
                            }

                            return {x: x, y: plankjePosition.y, topBottom: 'TOP', rotate: 0, value, values}
                        })}
                    />
                </div>
            </MaintainAspectRatio>
        </div>
    }

    renderCrossHairOverlay(crosshair) {
        return <>
            <div style={{position: 'absolute', zIndex: 3, width: '100%', height: '100%'}}>
                <MaintainAspectRatio>
                    <div style={{width: '100%', height: '100%'}}>
                        <CrosshairSvg
                            width={this.state.width} height={this.state.height}
                            crosshair={crosshair}
                            onChange={this.props.setCrosshair ? (crosshair) => {
                                //this.updateSettings({crosshair})
                                this.props.dispatch(updateMohicanSetting('crosshair', crosshair))
                            } : null}
                            cameraHeight={WEBCAM_CAMERA_HEIGHT}
                            cameraWidth={WEBCAM_CAMERA_WIDTH}
                        />
                    </div>
                </MaintainAspectRatio>
            </div>
        </>;
    }

    render() {

        if( this.props.modal ) {
            return <div style={{position: 'fixed', left: this.state.left, top: this.state.top, zIndex: 99}}>

                <div>
                    <Segment inverted attached='top' style={{cursor: 'move'}}
                             onMouseDown={(e, t) => this.handleMouseDown(e, t)}>

                        <Dropdown
                            text='Camera'
                            simple
                        >
                            <Dropdown.Menu>
                                <Dropdown.Item disabled={true}>Camerabeeld formaat in mm's</Dropdown.Item>
                                <Dropdown.Item disabled={true}>
                                    {`crosshair X: ${this.state.crosshair.x} Y: ${this.state.crosshair.y}`}
                                    {/*{`view size Width: ${viewWidth} Height: ${viewHeight}`}*/}
                                </Dropdown.Item>

                                <Input icon='resize horizontal' iconPosition='left' value={this.state.viewWidth}
                                       type={"number"} step={0.1}
                                       label={{basic: true, content: 'mm'}}
                                       labelPosition='right'
                                       onChange={(e) => this.updateSettings({viewWidth: e.target.value})}
                                />
                                <Input icon='resize vertical' iconPosition='left' value={this.state.viewHeight}
                                       type={"number"} step={0.1}
                                       label={{basic: true, content: 'mm'}}
                                       labelPosition='right'
                                       onChange={(e) => this.updateSettings({viewHeight: e.target.value})}
                                />
                                <Dropdown.Divider/>
                                <Dropdown.Item icon='crosshairs' text='Plaats crosshair' onClick={() => {
                                    this.setState({setCrosshair: !this.state.setCrosshair})
                                }} style={this.state.setCrosshair ? {color: 'blue'} : {}}/>
                                <Dropdown.Item icon='back' text='Reset terug naar default instellingen' onClick={() => {
                                    this.updateSettings(defaultValues)
                                }}/>
                                <Dropdown.Item icon='braille' text='Show overlay' onClick={() => {
                                    this.setState({showOverlay: !this.state.showOverlay})
                                }} style={this.state.showOverlay ? {color: 'blue'} : {}}/>
                            </Dropdown.Menu>
                        </Dropdown>

                        <div style={{position: 'absolute', right: 16, top: 14, cursor: 'pointer'}}
                             onClick={() => this.handleClose()}>
                            <Icon name='close'/>
                        </div>
                    </Segment>
                    <Segment attached style={{
                        width: this.state.width,
                        height: this.state.height,
                        padding: this.state.type ? 0 : ''
                    }}>

                        {this.renderCamera()}

                    </Segment>

                    <div style={{
                        display: 'inline-block',
                        position: 'absolute',
                        width: '36px',
                        bottom: '-18px',
                        height: '36px',
                        right: '-18px',
                        cursor: 'nwse-resize',
                        zIndex: 10
                    }}
                         onMouseDown={(e, t) => this.handleMouseDownResize(e, t)}
                    />
                </div>

            </div>
        }

        return this.renderCamera()
    }

}

BomCamera.propTypes = {
    onClose: PropTypes.func,
    modal: PropTypes.bool,
    setCrosshair: PropTypes.bool,
    flip: PropTypes.bool,
};

BomCamera.defaultProps = {
    onClose: null,
    modal: true,
    setCrosshair: false,
    flip: false
};

const mapStateToProps = (state) => {
    return {
        currentUser: state.main.currentUser,
        print: state.bom.print,
        mohicanPosition: state.bom.mohicanPosition,
        mohicanPcbPosition: state.bom.mohicanPcbPosition,
        mohicanPositionList: state.bom.mohicanPositionList,
        bomFilter: state.bom.bomFilter,
        bomSearch: state.bom.bomSearch,
        mohicanSettings: state.bom.mohicanSettings,
        bomProductionList: state.bom.bomProductionList,
        selectedDesignator: state.bom.selectedDesignator,
        bomProductionSelected: state.bom.bomProductionSelected,
        mohicanPlankjePosition: state.bom.mohicanPlankjePosition,
        selectedPlankje: state.bom.selectedPlankje,
        bomOrderList: state.bom.bomOrderList,
    }
}

export default connect(mapStateToProps)(BomCamera)