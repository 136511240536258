import React from 'react'
import {connect} from "react-redux";
import {Dropdown, Input, Portal, Search} from "semantic-ui-react";
import {changeSiteSearchValue, openSiteSearch, siteSearch} from "./actions";
import {Link, withRouter} from "react-router-dom";

class SiteSearch extends React.Component {

    state = {
        isFetching: false,
        multiple: true,
        search: true,
        searchQuery: null,
        value: [],
        options: [],
    }

    handleClose = () => {
        this.props.dispatch(openSiteSearch(false))
    }

    componentDidMount() {
        if( this.searchInput ) {
            setTimeout(() => {
                this.searchInput.focus()
            }, 100);
        }
    }

    handleChange = (e, { value }) => this.setState({ value })
    handleSearchChange = (e, { searchQuery }) => this.setState({ searchQuery })

    render() {
        const { multiple, isFetching, search, value } = this.state
        const s = this.props.siteSearch.list

        const options = s.map(s => ({
            ...s
        })).sort((a,b) => b.title.localeCompare(a.title))

        return <div>
            <Portal
                closeOnTriggerClick
                openOnTriggerClick
                onClose={this.handleClose}
                open={true}
            >
                <div
                    style={{
                        left: 'calc(50% - 200px)',
                        position: 'fixed',
                        top: '96px',
                        zIndex: 1000,
                        width: 400
                    }}
                >

                    {/*<Dropdown
                        ref={(searchInput) => { this.searchInput = searchInput; }}
                        fluid
                        selection
                        search={search}
                        options={options}
                        value={value}
                        placeholder='Search...' icon='search'
                        onChange={(e,d) => {
                            console.log(d.value)
                            //this.props.dispatch(changeSiteSearchValue(e.target.value))
                            //this.props.dispatch(siteSearch(e.target.value))
                        }}
                        onSearchChange={(e,d) => {
                            this.props.dispatch(changeSiteSearchValue(e.target.value))
                            this.props.dispatch(siteSearch(e.target.value))
                        }}
                        disabled={isFetching}
                        loading={isFetching}
                        onLabelClick={(e,d) => {
                            console.log(JSON.stringify(d))
                        }}
                    />*/}

                    <Search
                        fluid style={{width: 400}}
                        input={{ icon: 'search', iconPosition: 'left', placeholder: 'Search...', fluid: true, ref: (searchInput) => { this.searchInput = searchInput; }} }
                        onSearchChange={(e) => {
                            this.props.dispatch(changeSiteSearchValue(e.target.value))
                            this.props.dispatch(siteSearch(e.target.value))
                        }}
                        results={options}
                        onResultSelect={(e,d) => {
                            console.log(JSON.stringify(d.result))
                            this.props.history.push(d.result.url);
                            this.props.dispatch(openSiteSearch(false))
                        }}
                    />

                    {/*<Dropdown icon={null} fluid trigger={
                        <Input
                            placeholder='Search...' icon='search'
                            size="big" fluid value={this.props.siteSearchValue}
                            onChange={(e) => {
                                this.props.dispatch(changeSiteSearchValue(e.target.value))
                                this.props.dispatch(siteSearch(e.target.value))
                            }}
                        />
                    }>
                        <Dropdown.Menu style={{width: 400}}>
                            {s.map(s =>
                                <Dropdown.Item onClick={() => {
                                    this.props.history.push(s.url);
                                }}>{s.value}</Dropdown.Item>
                            )}
                        </Dropdown.Menu>
                    </Dropdown>*/}
                </div>
            </Portal>
        </div>
    }
}

const mapStateToProps = (state) => {
    return {
        currentUser: state.main.currentUser,
        lang: state.main.lang,
        siteSearchOpen: state.main.siteSearchOpen,
        siteSearchValue: state.main.siteSearchValue,
        siteSearch: state.main.siteSearch
    }
}

export default connect(mapStateToProps)(withRouter(SiteSearch))