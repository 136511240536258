import React, {Component} from 'react';
import {
    Button, Confirm, Dropdown,
    Form, Icon, Label, Modal, Segment, Select,
} from "semantic-ui-react";

import PropTypes from "prop-types";
import Translate, {getText} from "../Translate";
import connect from "react-redux/es/connect/connect";
import {getAddress} from "../actions";

const optionsGender = [
    { key: 'NA', text: 'n.v.t.', value: 'NA' },
    { key: 'MEVROUW', text: 'Mevr.', value: 'MEVROUW' },
    { key: 'HEER', text: 'Dhr.', value: 'HEER' },
]

const optionsCountry = [
    { key: 'Nederland', text: 'Nederland', value: 'Nederland' },
    { key: 'België', text: 'België', value: 'België' },
    { key: 'Frankrijk', text: 'Frankrijk', value: 'Frankrijk' },
    { key: 'Duitsland', text: 'Duitsland', value: 'Duitsland' },
]

function fixPostcode(value, setValue) {
    if( value ) {
        if (value.length === 6) {
            let newValue= value.substring(0, 4) + ' ' + value.substring(4, 6).toUpperCase();
            if( newValue !== value) {
                if( setValue ) setValue(newValue);
                return newValue
            }

        } else if (value.trim().length === 7) {
            let newValue= value.trim().toUpperCase()
            if( newValue !== value) {
                if( setValue ) setValue(newValue);
                return newValue
            }
        }
    }
    return value
}

class AccountAddress extends Component {

    constructor(props) {
        super(props);
        this.state = {
            open: !!props.address.open,
            additionOptions: []
        };
    }

    handleChange = (e, { name, value }) => {

        console.log('handleChange ' + name + ' ' + value)

        let address= this.props.address;
        address[name]= value;

        this.setState({address: address});

        if( this.props.onChange ) {
            this.props.onChange(address)
        }
    }

    renderField = (label, parent, name, disabled, onBlur ) => {
        return (
            <Form.Input
                label={getText(this.props, label)}
                name={name}
                placeholder={getText(this.props, label)}
                value={parent[name]}
                disabled={disabled}
                onChange={this.handleChange}
                onBlur={onBlur ? () => onBlur(parent[name]) : null}
            />
        )
    }

    openDialog = () => {
        this.setState({open: true})
    }

    closeDialog = () => {
        this.setState({open: false})
    }

    handleSave = () => {

        let address= this.props.address;

        if(this.props.onSave) {
            this.props.onSave(address);
        }
        this.setState({open: false})
    }

    handleDelete = () => {
        if( this.props.onDelete ) {
            this.props.onDelete(this.props.address.id)
        }
    }

    handleSetDefault = () => {
        if( this.props.onSetDefault ) {
            this.props.onSetDefault(this.props.address.id)
        }
    }

    handleSelect = () => {
        if( this.props.onSelect ) {
            this.props.onSelect(this.props.address.id)
        }
    }

    onBlurZip = (value) => {

        fixPostcode(value, (newValue) => {
            value= newValue;
            this.handleChange(null, {name: 'zip', value: newValue})
        })

        if( value && this.props.address.number ) {
            this.props.dispatch(getAddress(value, this.props.address.number, (value) => {
                this.setFromGetAddressService(value)
            }))
        }
    }

    onBlurNumber = (value) => {
        if( value && this.props.address.zip ) {
            this.props.dispatch(getAddress(this.props.address.zip, value, (value) => {
                this.setFromGetAddressService(value)
            }))
        }
    }

    setFromGetAddressService = (value) => {
        if( !this.props.address.street && !this.props.address.city ) {
            this.handleChange(null, {name: 'street', value: value.street})
            this.handleChange(null, {name: 'city', value: value.city})
            this.setState({additionOptions: value.toevoeging})
        }
    }

    mouseEnter = () => {
        this.setState({hover: true})
    }

    mouseLeave = () => {
        this.setState({hover: false})
    }

    render() {

        let address= this.props.address;
        let canHasDefault= this.props.canHasDefault;
        let disabled= this.props.disabled;
        let isDefault= address.isDefault;
        let displayOnly= this.props.displayOnly;

        let canSelect= this.props.onSelect && disabled;

        if( !this.state.open ) {
            return [
                <Segment key={'a'} disabled={disabled && !this.state.hover} attached='top' style={{minHeight: 64, maxWidth: 500, cursor: canSelect ? 'pointer' : null}} color={this.props.color ? this.props.color : (isDefault) ? 'blue' : null}
                         onMouseEnter={this.mouseEnter}
                         onMouseLeave={this.mouseLeave}
                         onClick={() => {
                             if(canSelect) {
                                 this.handleSelect()
                             }
                         }}
                >
                    <div style={{float: 'right'}}>
                        {(isDefault && !displayOnly && canHasDefault) ? <Label color='blue'><Translate value='default'/></Label> : ''}
                    </div>
                    <div style={{whiteSpace: 'pre-line'}}>
                        {address.address ? address.address : 'Geen adres'}
                    </div>

                </Segment>,
                <Segment key={'b'} disabled={disabled && !this.state.hover} attached='bottom' style={{textAlign: 'right', maxWidth: 500, cursor: canSelect ? 'pointer' : null, display: displayOnly ? 'none' : null}}
                         onMouseEnter={this.mouseEnter}
                         onMouseLeave={this.mouseLeave}
                         onClick={() => {
                             if(canSelect) {
                                 this.handleSelect()
                             }
                         }}
                >

                    <div style={{marginTop: -8}}>
                    {(!isDefault && canHasDefault && this.props.onSetDefault) ? <Button style={{marginTop: 8}} labelPosition='left' icon onClick={this.handleSetDefault} disabled={disabled}>
                        <Icon name='check'/>
                        <Translate value='setdefault'/>
                    </Button> : ''}

                    {this.props.onDelete && <Button style={{marginTop: 8}} labelPosition='left' icon onClick={() => this.setState({confirmDelete: true})} disabled={disabled}>
                        <Icon name='trash'/>
                        <Translate value='delete'/>
                    </Button>}

                    <Confirm
                        open={this.state.confirmDelete}
                        onCancel={() => this.setState({confirmDelete: false})}
                        onConfirm={() => {
                            this.handleDelete();
                            this.setState({confirmDelete: false});
                        }}
                        content={getText(this.props, "address_delete")}
                        cancelButton={getText(this.props, "no")}
                        confirmButton={getText(this.props, "yes")}
                    />

                    <Button style={{marginTop: 8}} labelPosition='left' icon onClick={this.openDialog} disabled={disabled}>
                        <Icon name='edit'/>
                        <Translate value='change'/>
                    </Button>
                    </div>
                </Segment>

            ]
        }

        return (
            <Modal open={this.state.open} closeOnDimmerClick={false} closeIcon={true} onClose={this.closeDialog}>
                <Modal.Header>{this.props.modalText}</Modal.Header>
                <Modal.Content scrolling>
                    <Form>
                        <Form.Field>
                            {this.renderField('address_company_name', address, 'companyName', this.props.loading)}
                        </Form.Field>
                        <Form.Field>
                            <Form.Input control={Select} label={getText(this.props, "address_gender")} options={optionsGender} name='gender' placeholder={getText(this.props, "address_gender")} value={address.gender} onChange={this.handleChange} disabled={this.props.loading}/>
                        </Form.Field>
                        <Form.Field>
                            {this.renderField('address_name', address, 'name', this.props.loading)}
                        </Form.Field>
                        <Form.Field>
                            {this.renderField('address_zip', address, 'zip', this.props.loading, this.onBlurZip)}
                        </Form.Field>
                        <Form.Field>
                            {this.renderField('address_number', address, 'number', this.props.loading, this.onBlurNumber)}
                        </Form.Field>
                        <Form.Field>
                            <Dropdown
                                inline
                                icon={null}
                                fluid
                                value={address['addition']}
                                options={this.state.additionOptions.map(v => ({key: v, text: v, value: v}))}
                                onChange={(e,d) => {
                                    if( d.value ) {
                                        this.handleChange(null, {name: 'addition', value: d.value})
                                    }
                                }}
                                trigger={this.renderField('address_addition', address, 'addition', this.props.loading)}
                            />
                        </Form.Field>
                        <Form.Field>
                            {this.renderField('address_street', address, 'street', this.props.loading)}
                        </Form.Field>
                        <Form.Field>
                            {this.renderField('address_city', address, 'city', this.props.loading)}
                        </Form.Field>
                        <Form.Field>
                            <Form.Input control={Select} label={getText(this.props, "address_country")} options={optionsCountry} name='country' placeholder={getText(this.props, "address_country")} value={address.country} onChange={this.handleChange} disabled={this.props.loading}/>
                        </Form.Field>
                    </Form>
                </Modal.Content>
                <Modal.Actions>
                    <Button onClick={this.closeDialog}>
                        <Translate value='cancel'/>
                    </Button>
                    <Button primary onClick={this.handleSave}>
                        <Translate value='save'/>
                    </Button>
                </Modal.Actions>
            </Modal>
        );
    }
}

AccountAddress.propTypes = {
    address: PropTypes.any,
    loading: PropTypes.bool,
    disabled: PropTypes.bool,
    color: PropTypes.string,
    modalText: PropTypes.any,
};

AccountAddress.defaultProps = {
    address: {},
    loading: false,
    disabled: false,
    color: null,
    modalText: '',
};

const mapStateToProps = (state) => {
    return {
        lang: state.main.lang
    }
}

export default connect(mapStateToProps)(AccountAddress)