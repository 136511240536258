import React, {Component} from 'react';

import {
    Button,
    Checkbox,
    Confirm,
    Dropdown,
    Form,
    Header,
    Icon,
    Input,
    Loader, Message,
    Modal, Search,
    Table,
    TextArea
} from "semantic-ui-react";
import connect from "react-redux/es/connect/connect";
import {
    addBomOrder, createBomOrders,
    deleteBomOrders, findProjectWithOrders,
    getSuppliers, moveBomOrders,
    openBomOrder, openBomOrderMove, saveBomOrder,
    saveBomOrderItem, selectBomOrderToMove,
    setBomOrderValue,
} from "./../actions";
import {copyToClipboard, isPcbOrStencil, renderSupplier} from "./functions";
import {formatDateTime} from "../actions/functions";
import MailBuilder from "../tools/MailBuilder";
import PropTypes from "prop-types";
import {downloadFile} from "../tools/functions";
import Translate from "../Translate";

export const orderStatus = [
    {key: 'TO_BE_ORDERED', text: 'To be ordered', value: 'TO_BE_ORDERED'},
    {key: 'RESERVED', text: 'Requested', value: 'RESERVED'},
    {key: 'APPROVED', text: 'Approved', value: 'APPROVED'},
    {key: 'ORDERED', text: 'Ordered', value: 'ORDERED'},
    {key: 'RECEIVED', text: 'Received', value: 'RECEIVED'},
    {key: 'PROCESSED', text: 'Processed', value: 'PROCESSED'}
]

class BomOrder extends Component {

    state= {

    }

    componentDidMount() {
        this.props.dispatch(getSuppliers())
    }

    orderTextToClipboard(o, navigate = true) {

        let string= "";

        let projectName= this.props.print.name.replaceAll('.xls', '').replaceAll('.xls', '');

        o.items.forEach( v => {
            if( o.supplier === "DIGIKEY" ) {
                string = string + v.orderAmount + ", " + v.part.sku + "\n";
            }
            else if( o.supplier === "MOUSER" ) {
                string = string + v.part.sku + ", " + v.orderAmount + "\n";
            }
            else if( o.supplier === "RSCOMPONENTS" ) {
                string = string + v.part.sku + "," + v.orderAmount + ",X," + projectName + "\n";
            }
            else if( o.supplier === "TME" ) {
                string = string + v.part.sku + ", " + v.orderAmount + "\n";
            }
            else if( o.supplier === "WURTH" ) {
                string = string + v.orderAmount + ' keer ' + v.part.sku + "\n"; //todo: make e-mail for wurth
            }
            else if( o.supplier === "CUSTOMER" ) {
                string = string + v.kla.name + (v.kla.mpn ? (` MPN: ${v.kla.mpn}`) : '') +
                    (v.kla.sku ? (` SKU: ${v.kla.sku}`) : '') +
                    ", aantal: " + v.orderAmount + "\n";
            }
            else if( o.supplier === "FARNELL" ) {
                string = string + v.part.sku + ", " + v.orderAmount + ", " + projectName.substring(0, 30) + "\n";
            }
            else if( o.supplier === "ARROW" ) {
                string = string + v.part.sku  + "\n";
            }
            else if( o.supplier === "LCSC" ) {
                string = string + v.orderAmount + ", " + v.part.orderpart.mpn + ", " + v.part.sku + ", " + projectName  + "\n";
            }
            else {
                //also the default for farnell
                string = string + v.part.sku + ", " + v.orderAmount + ", " + projectName + "\n";
            }
        });

        copyToClipboard(string);

        let supplierUrl= "#";

        if( o.supplier === "FARNELL" ) {
            supplierUrl= "https://nl.farnell.com/snelle-bestelling";
        }
        else if( o.supplier === "DELTAPROTO" ) {
            supplierUrl= "#";
        }
        else if( o.supplier === "DIGIKEY" ) {
            supplierUrl= "https://www.digikey.nl/ordering/shoppingcart?lang=nl";
        }
        else if( o.supplier === "MOUSER" ) {
            supplierUrl= "http://nl.mouser.com/Tools/part-list-import.aspx";
        }
        else if( o.supplier === "RSCOMPONENTS" ) {
            supplierUrl= "https://nl.rs-online.com/web/ca/overzichtwinkelwagen/";
        }
        else if( o.supplier === "TME" ) {
            supplierUrl= "http://www.tme.eu/en/Profile/QuickBuy/load.html";
        }
        else if( o.supplier === "ARROW" ) {
            supplierUrl= "https://www.arrow.com/en/bom-tool/";
        }
        else if( o.supplier === "LCSC" ) {
            supplierUrl= "https://www.lcsc.com/bom";
        }

        if( navigate && supplierUrl !== "#" ) {
            window.open(supplierUrl, '_blank');

            if( o.supplier === "LCSC" ) {
                downloadFile(`lcsc_bom_${projectName}.csv`, 'Quantity,Manufacture Part Number,LCSC Part Number,Customer Part Number\n' + string);
            }

            this.setState({inputSupplierOrderCode: true, inputSupplierOrderCodeValue: '', inputSupplierOrder: o})
        }
    }

    completeOrder(mailCustomer: false, comment) {

        let value= Object.assign({}, this.state.inputSupplierOrder, {
            supplierOrderCode: this.state.inputSupplierOrderCodeValue
        })

        this.props.dispatch(saveBomOrder(value, mailCustomer, comment))

        this.setState({inputSupplierOrderCode: false, inputCustomerOrderCode: false})

    }

    receivedOrder(o) {

        let value= Object.assign({}, o, {
            status: "RECEIVED"
        })

        this.props.dispatch(saveBomOrder(value))
    }

    renderActions = (o) => {

        let allCheckedId = o.items.filter(li => !li.boardLocation).length === 0

        const props = this.props;

        switch (o.status) {
            case "TO_BE_ORDERED":

                return <div style={{display: 'flex'}}>
                    {( o.supplier === 'CUSTOMER' && props.print.projectOrder && props.print.projectOrder.owner ) ?
                        <MailBuilder
                            style={{marginTop: 8}}
                            to={props.print.projectOrder.owner.email}
                            mailKey='mail.customerOrder'
                            args={[
                                props.print.projectOrder.owner.firstName,
                                `https://www.deltaproto.com/project/${props.print.projectOrder.id}/buy`,
                                props.print.projectOrder.name + ' | ' + (props.print.projectOrder.reference ? (props.print.projectOrder.reference) : ''),
                                props.print.projectOrder.internalName,
                                o.items.length, //componentCount
                                o.items.map(i => {
                                    return i.orderAmount + " * " + i.kla.name + " " + i.kla.mpn + i.kla.sku
                                }).join("\r\n"), //componentList
                                o.supplierOrderCode ? o.supplierOrderCode : ''
                            ]}
                            button={true}
                        >
                            Klant mailen
                        </MailBuilder>

                        : <Button icon="check" onClick={() => {
                        this.setState({inputSupplierOrderCode: true, inputSupplierOrderCodeValue: '', inputSupplierOrder: o})
                    }}/>}
                </div>
            case "ORDERED":
                return <Button icon="box" onClick={() => this.receivedOrder(o)}>I received a package!</Button>
            case "RECEIVED":
                if( allCheckedId ) {
                    return <div>All parts are checked-in</div>
                }
                return <div>You should check-in</div>
            default:
                return <div></div>
        }
    }

    renderStatus = (o) => {
        switch (o.supplierStatus) {
            case "UNKNOWN_STATUS":
                return <div></div>
            default:
                return <div><a target="_blank" rel="noreferrer" href={o.supplierStatus}>STATUS</a></div>
        }
    }

    renderPcbStencilOrders(list) {

        const loading = this.props.openeningBom || this.props.bomOrderListBusy;

        return <div>
            <div>
                <Header>PCB's and Stencils</Header>
                {this.props.bomOrderList.length === 0 && <p>There are no PCB or Stencil orders yet</p>}

                {this.props.bomOrderList.length ? <p>To add the PCB or Stencil order click add</p> : ''}
                <p>For PCBWay, the supplier and status is automatically recognised by the Order code</p>

                {list && <Table compact='very' size='small' style={{width: '100%', lineHeight: '12px'}}>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>Supplier</Table.HeaderCell>
                            <Table.HeaderCell>Date</Table.HeaderCell>
                            <Table.HeaderCell>Status</Table.HeaderCell>
                            <Table.HeaderCell></Table.HeaderCell>
                            <Table.HeaderCell>Order code</Table.HeaderCell>
                            <Table.HeaderCell>Amount</Table.HeaderCell>
                            <Table.HeaderCell collapsing>Process days</Table.HeaderCell>
                            <Table.HeaderCell></Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>

                    <Table.Body>

                        {list.map(o => (
                            <Table.Row>
                                <Table.Cell>
                                    <Dropdown
                                        selection
                                        options={this.props.suppliers.filter(s => isPcbOrStencil({supplier: s})).map(s => ({
                                            key: s,
                                            text: renderSupplier({supplier: s, sku: ''}),
                                            value: s
                                        }))}
                                        value={o.supplier}
                                        onChange={(e,d) => this.props.dispatch(setBomOrderValue(o.id, 'supplier', d.value))}
                                    />
                                </Table.Cell>
                                <Table.Cell style={{width: 140}}>{formatDateTime(o.created)}</Table.Cell>
                                <Table.Cell>
                                    <Dropdown
                                        selection
                                        options={orderStatus}
                                        value={o.status}
                                        onChange={(e,d) => this.props.dispatch(setBomOrderValue(o.id, 'status', d.value))}
                                    />

                                </Table.Cell>
                                <Table.Cell></Table.Cell>
                                <Table.Cell>
                                    <Input
                                        value={o.supplierOrderCode} autoFocus={true} onKeyDown={(e) => {
                                            if (e.key === 'Enter') {
                                                this.props.dispatch(saveBomOrder(o))
                                            }
                                        }}
                                        onChange={(e) => this.props.dispatch(setBomOrderValue(o.id, 'supplierOrderCode', e.target.value))}
                                    />
                                </Table.Cell>
                                <Table.Cell collapsing>
                                    <Input
                                        value={o.amount} fluid
                                        onChange={(e) => this.props.dispatch(setBomOrderValue(o.id, 'amount', e.target.value))}
                                    />
                                </Table.Cell>
                                <Table.Cell collapsing>
                                    <Input
                                        value={o.deliverytime} fluid
                                        onChange={(e) => this.props.dispatch(setBomOrderValue(o.id, 'deliverytime', e.target.value))}
                                    />
                                </Table.Cell>
                                <Table.Cell style={{display: 'flex'}}>
                                    <Button icon="save" primary={o.dirty} onClick={() => {
                                        this.props.dispatch(saveBomOrder(o))
                                    }}/>
                                    <Button icon="trash" onClick={() => {
                                        this.props.dispatch(deleteBomOrders(this.props.print.id, o.id))
                                    }}/>
                                </Table.Cell>
                            </Table.Row>
                        ))}

                    </Table.Body>
                    <Table.Footer>
                        <Table.Row>
                            <Table.HeaderCell colSpan={8} style={{textAlign: 'right'}}>
                                <Button
                                    disabled={loading}
                                    onClick={() => this.props.dispatch(addBomOrder(this.props.print.id))}
                                ><Translate value="add"/></Button>
                            </Table.HeaderCell>
                        </Table.Row>
                    </Table.Footer>
                </Table>}

                {loading && <div style={{textAlign: 'center'}}>
                    <Loader active inline className='fixcolorloader' />
                </div>}

            </div>
        </div>
    }

    renderPartOrders(list) {

        const loading = this.props.openeningBom || this.props.bomOrderListBusy;

        return <div style={{marginTop: 24}}>

            <Header>Parts</Header>

            <div>
                {this.props.bomOrderList.length === 0 && <p>There are no orders yet</p>}

                {this.props.bomOrderList.length ? <p>To order the parts, click the link under the "Order code", paste the text in the quick order field on the supplier webpage. After you have placed the order, copy the order code and close the page</p> : ''}

                {list && <Table compact>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>Supplier</Table.HeaderCell>
                            <Table.HeaderCell>Date</Table.HeaderCell>
                            <Table.HeaderCell style={{width: 150}}>Status</Table.HeaderCell>
                            <Table.HeaderCell>Parts</Table.HeaderCell>
                            <Table.HeaderCell>Order code</Table.HeaderCell>
                            <Table.HeaderCell style={{width: 150}}>Supplier status</Table.HeaderCell>
                            <Table.HeaderCell></Table.HeaderCell>
                            <Table.HeaderCell></Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>

                    <Table.Body>

                        {list.map(o => (
                            <Table.Row key={o.id}>
                                <Table.Cell>
                                    {renderSupplier({supplier: o.supplier})}
                                </Table.Cell>
                                <Table.Cell style={{width: 140}}>{formatDateTime(o.created)}</Table.Cell>
                                <Table.Cell>
                                    <Dropdown value={o.status} options={orderStatus} disabled={true} icon={null}/>
                                </Table.Cell>
                                <Table.Cell>{o.items.length}</Table.Cell>
                                <Table.Cell>
                                    {this.renderOrderCode(o)}
                                </Table.Cell>
                                <Table.Cell>
                                    {this.renderStatus(o)}
                                </Table.Cell>
                                <Table.Cell>
                                    {this.renderActions(o)}
                                </Table.Cell>
                                <Table.Cell style={{display: 'flex'}}>
                                    <Button icon="edit" onClick={() => {
                                        this.setState({
                                            edit: o
                                        })
                                    }}/>
                                    <Button icon="list" onClick={() => {
                                        this.setState({
                                            listView: o.id
                                        })
                                    }}/>
                                    <Button icon="trash" disabled={o.id === this.props.removingBomOrders} loading={o.id === this.props.removingBomOrders} onClick={() => {
                                        this.setState({removingBomOrder: o})
                                    }}/>

                                    <Confirm
                                        dimmer={'blurring'}
                                        open={this.state.removingBomOrder != null}
                                        onCancel={() => this.setState({removingBomOrder: null})}
                                        content={<Modal.Content>
                                            <p>
                                                Weet je zeker dat je deze bestelling wilt verwijderen?
                                            </p>

                                            {(this.state.removingBomOrder && this.state.removingBomOrder.supplierOrderCode) ? <Message error>
                                                <Message.Header>Let op!</Message.Header>
                                                <Message.Content>
                                                    De bestelling is al geplaatst, weet je zeker dat je deze wilt
                                                    verwijderen?
                                                </Message.Content>
                                            </Message> : null}

                                        </Modal.Content>}
                                        cancelButton='Nee'
                                        confirmButton={<Button icon="trash" disabled={this.state.removingBomOrder && this.state.removingBomOrder.id === this.props.removingBomOrders}
                                                               loading={this.state.removingBomOrder && this.state.removingBomOrder.id === this.props.removingBomOrders} onClick={() => {
                                            this.props.dispatch(deleteBomOrders(this.props.print.id, this.state.removingBomOrder.id, () => {
                                                this.setState({removingBomOrder: null})
                                            }))
                                        }}>Verwijderen</Button>}
                                    />
                                </Table.Cell>
                            </Table.Row>
                        ))}

                    </Table.Body>
                </Table>}

                {loading && <div style={{textAlign: 'center'}}>
                    <Loader active inline className='fixcolorloader' />
                </div>}

            </div>
            <div style={{textAlign: 'center', marginTop: 16}}>
                <Button primary disabled={loading} loading={this.props.creatingBomOrders} onClick={() => this.props.dispatch(createBomOrders(this.props.print.id))}>Create supplier order(s)</Button>
            </div>
            <div style={{textAlign: 'center', marginTop: 8}}>
                <Button disabled={loading} onClick={() =>  this.props.dispatch(openBomOrderMove(true))}>
                    Move stock from existing project
                </Button>
            </div>
        </div>
    }

    renderOrderCode(o) {
        if( o.supplier === 'CUSTOMER' ) {
            return <>
                {o.orderPlaced ? 'Mail verzonden op ' + formatDateTime(o.orderPlaced) : 'n.v.t.'}
            </>
        } else {
            return <>
                {
                    o.supplierOrderCode ?
                        <Input value={o.supplierOrderCode}/> :

                        o.supplier === "WURTH" ? <div>
                                <MailBuilder
                                    to={'Robert.Schuil@we-online.com'}
                                    cc={'Naomi.Yip@we-online.com'}
                                    mailKey='mail.wurthOrder'
                                    args={[
                                        this.props.print.projectOrder ? this.props.print.projectOrder.internalName : this.props.print.name,
                                        o.items.map(v => v.orderAmount + ' keer ' + v.part.sku + "\n"),
                                        this.props.print.projectOrder ? this.props.print.projectOrder.internalName : this.props.print.name,
                                    ]}
                                >Create mail for Würth</MailBuilder>

                                <MailBuilder
                                    to={'Robert.Schuil@we-online.com'}
                                    cc={'Naomi.Yip@we-online.com'}
                                    mailKey='mail.wurthOrder.spoed'
                                    args={[
                                        this.props.print.projectOrder ? this.props.print.projectOrder.internalName : this.props.print.name,
                                        o.items.map(v => v.orderAmount + ' keer ' + v.part.sku + "\n"),
                                        this.props.print.projectOrder ? this.props.print.projectOrder.internalName : this.props.print.name,
                                    ]}
                                >Create mail for Würth met SPOED</MailBuilder>
                            </div>:

                        <a href={"#/"} style={{paddingLeft: 8}} onClick={() => this.orderTextToClipboard(o)}>
                            {(o.supplier !== "CUSTOMER" && o.supplier !== "WURTH") ? `Copy order text to clipboard, and open the ${o.supplier} orderpage` :
                                `Copy order text to clipboard`}
                        </a>
                }

                {o.supplierOrderCode ? <a href={"#/"} style={{paddingLeft: 8}} onClick={() => this.orderTextToClipboard(o, false)}>
                    Copy order text
                </a> : ''}
            </>;
        }
    }

    renderList(o) {
        return <Modal open={true} onClose={() => this.setState({listView: null})} key={o.id}>
            <Modal.Header>Order details</Modal.Header>
            <Modal.Content>

                {renderSupplier({
                    supplier: o.supplier,
                    sku: formatDateTime(o.created)
                })}

                <Table compact>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>Supplier</Table.HeaderCell>
                            <Table.HeaderCell>SKU</Table.HeaderCell>
                            <Table.HeaderCell>boardLocation</Table.HeaderCell>
                            <Table.HeaderCell>orderAmount</Table.HeaderCell>
                            <Table.HeaderCell>stock</Table.HeaderCell>
                            <Table.HeaderCell>vervallen</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>

                    <Table.Body>
                        {o.items.map(i => {
                            return <Table.Row disabled={i.busy} key={i.id}>
                                <Table.Cell>
                                    {i.part ? renderSupplier({supplier: i.part.supplier, sku: ''}) : ''}
                                    {i.orderpart ? renderSupplier({supplier: i.orderpart.manufacturer, sku: ''}) : ''}
                                    {i.kla ? renderSupplier({supplier: 'KLANT', sku: ''}) : ''}
                                </Table.Cell>
                                <Table.Cell>
                                    {i.part ? i.part.sku : ''}
                                    {i.orderpart ? i.orderpart.mpn : ''}
                                    {i.kla ? i.kla.name + ' ' + i.kla.sku : ''}
                                </Table.Cell>
                                <Table.Cell>{i.boardLocation}</Table.Cell>
                                <Table.Cell>{i.orderAmount}</Table.Cell>
                                <Table.Cell>{i.stock}</Table.Cell>
                                <Table.Cell>
                                    <Checkbox checked={i.expired} onChange={(e) => this.props.dispatch(saveBomOrderItem({...i, expired: (!i.expired)}, o.id))} />
                                </Table.Cell>
                            </Table.Row>
                        })}
                    </Table.Body>
                </Table>

            </Modal.Content>
            <Modal.Actions>
                <Button onClick={() => this.setState({listView: false})}>
                    <Icon name='close' /> Close
                </Button>
            </Modal.Actions>
        </Modal>
    }

    handleClose = () => {
        this.props.dispatch(openBomOrder(false))

        if( this.props.onClose != null ) {
            this.props.onClose()
        }
    }

    render() {

        if( !this.props.showBomOrder ) {
            return <></>
        }

        return (
            <div>

                {this.state.listView ? this.renderList(this.props.bomOrderList.find(b => b.id === this.state.listView)) : ''}

                <Modal key={'moveorders'} open={this.props.showBomOrderMove} onClose={() => this.props.dispatch(openBomOrderMove(false))}>
                    <Modal.Header>Move stock from existing project</Modal.Header>
                    <Modal.Content>
                        <p>Select an existing project from where you want to move the stock to the current project:</p>

                        <Search
                            fluid
                            input={{ icon: 'search', iconPosition: 'left', placeholder: 'Search...', fluid: true, }}
                            onSearchChange={(e) => {
                                this.props.dispatch(findProjectWithOrders(e.target.value))
                            }}
                            loading={this.props.searchBomOrderMoveBusy}
                            results={this.props.searchBomOrderMove.map(b => {
                                return {
                                    id: b.id,
                                    title: b.title,
                                    description: `${b.bomOrders.length} bestellingen`,
                                    b: b
                                }
                            })}
                            onResultSelect={(e,d) => {
                                this.props.dispatch(selectBomOrderToMove(d.result.b))
                            }}
                        />

                        {this.props.selectedBomOrderMove ? <div style={{marginTop: 16}}>
                            <p>
                                Wil je de volgende bestellingen uit project {this.props.selectedBomOrderMove.title} verhuizen naar het huidige project?
                            </p>
                            <Table compact>
                                <Table.Body>
                                    {this.props.selectedBomOrderMove.bomOrders.map(o => {
                                        return <Table.Row>
                                            <Table.Cell>{renderSupplier({supplier: o.supplier})}</Table.Cell>
                                            <Table.Cell>{formatDateTime(o.created)}</Table.Cell>
                                            <Table.Cell>{o.status}</Table.Cell>
                                            <Table.Cell>{o.items.length}</Table.Cell>
                                        </Table.Row>
                                    })}
                                </Table.Body>
                            </Table>

                        </div> : null}

                    </Modal.Content>
                    <Modal.Actions>
                        <Button onClick={() => this.props.dispatch(openBomOrderMove(false))}>
                            <Icon name='close' /> Close
                        </Button>
                        <Button primary loading={this.props.bomOrderListBusy} disabled={!this.props.selectedBomOrderMove} onClick={() => this.props.dispatch(moveBomOrders(this.props.selectedBomOrderMove.id, this.props.print.id))}>
                            Overzetten
                        </Button>
                    </Modal.Actions>
                </Modal>

                <Modal key={'completeorder'} size={'mini'} open={this.state.inputSupplierOrderCode} onClose={() => this.setState({inputSupplierOrderCode: false})}>
                    <Modal.Header>Did you complete the order?</Modal.Header>
                    <Modal.Content>
                        {this.state.inputSupplierOrder ? JSON.stringify(this.state.inputSupplierOrder.supplier) : ''}
                        <Input label="Ordercode" autoFocus={true} value={this.state.inputSupplierOrderCodeValue} onChange={(e) => this.setState({inputSupplierOrderCodeValue: e.target.value})}
                            onKeyPress={(e) => {if( e.key === 'Enter' ) {this.completeOrder()}}}
                        />
                    </Modal.Content>
                    <Modal.Actions>
                        <Button onClick={() => this.setState({inputSupplierOrderCode: false})}>No</Button>
                        <Button primary icon='checkmark' labelPosition='right' content='Yes' disabled={!this.state.inputSupplierOrderCodeValue} onClick={() => this.completeOrder()}/>
                    </Modal.Actions>
                </Modal>

                <Modal key={'sendmail'} open={this.state.inputCustomerOrderCode} onClose={() => this.setState({inputCustomerOrderCode: false})}>
                    <Modal.Header>Would you like to add a comment to the customer e-mail?</Modal.Header>
                    <Modal.Content>
                        <Form>
                            <TextArea fluid autoFocus={true} placeholder="Customer comment" value={this.state.inputSupplierOrderCodeValue} onChange={(e) => this.setState({inputSupplierOrderCodeValue: e.target.value})}/>
                        </Form>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button onClick={() => this.setState({inputCustomerOrderCode: false})}>Close</Button>
                        <Button primary icon='send' labelPosition='right' content='Klant mailen' onClick={() => this.completeOrder(true, this.state.inputSupplierOrderCodeValue)}/>
                    </Modal.Actions>
                </Modal>

                {this.props.modal ? <Modal key={'edit'} size={'large'} open={this.props.showBomOrder} closeIcon
                        onClose={this.handleClose}>
                    <Header icon='shipping fast'
                            content={'Supplier orders (' + (this.props.print ? this.props.print.name : '') + ')'}/>
                    <Modal.Content scrolling>

                        {this.renderPcbStencilOrders(this.props.bomOrderList.filter((o) => isPcbOrStencil(o)))}

                        {this.renderPartOrders(this.props.bomOrderList.filter((o) => !isPcbOrStencil(o)))}

                    </Modal.Content>
                    <Modal.Actions>
                        <Button onClick={this.handleClose}>
                            <Icon name='close'/> Close
                        </Button>
                    </Modal.Actions>
                </Modal> : <div>

                    {this.renderPcbStencilOrders(this.props.bomOrderList.filter((o) => isPcbOrStencil(o)))}

                    {this.renderPartOrders(this.props.bomOrderList.filter((o) => !isPcbOrStencil(o)))}

                </div>}

                {this.state.edit ? <Modal key={'edit'} size={'mini'} open={this.state.edit} onClose={() => this.setState({edit: null})}>
                    <Modal.Header>Aanpassen</Modal.Header>
                    <Modal.Content>
                        <div>{renderSupplier({
                            supplier: this.state.edit.supplier,
                            sku: ''
                        })}</div>
                        <div>{formatDateTime(this.state.edit.created)}</div>
                        <div>{this.state.edit.items.length}</div>
                        <Form>
                            <Form.Dropdown selection label="Status" value={this.state.edit.status}
                                           onChange={(e, d) => this.setState({edit: {...this.state.edit, status: d.value}})}
                                           options={orderStatus}
                            />
                            {(this.state.edit.supplier === 'CUSTOMER') ?
                                <Form.TextArea label="Customer comment" value={this.state.edit.supplierOrderCode}
                                               onChange={(e) => this.setState({
                                                   edit: {
                                                       ...this.state.edit,
                                                       supplierOrderCode: e.target.value
                                                   }
                                               })}/> :
                                <Form.Input label="Order code" value={this.state.edit.supplierOrderCode}
                                            onChange={(e) => this.setState({edit: {...this.state.edit, supplierOrderCode: e.target.value}})}/>
                            }
                        </Form>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button onClick={() => this.setState({edit: null})}><Translate value="cancel"/></Button>
                        <Button primary icon='save' labelPosition='right' content={<Translate value="save"/>}
                                onClick={() => this.props.dispatch(saveBomOrder(this.state.edit, false, () => {
                                    this.setState({edit: null})
                                }))}/>
                    </Modal.Actions>
                </Modal> : ''}
            </div>
        );
    }
}


BomOrder.propTypes = {
    modal: PropTypes.bool,
    onClose: PropTypes.func
}

BomOrder.default = {
    modal: true,
    onClose: null
}

const mapStateToProps = (state) => {
    return {
        lang: state.main.lang,
        print: state.bom.print,
        showBomOrder: state.bom.showBomOrder,
        bomOrderList: state.bom.bomOrderList,
        openeningBom: state.bom.openeningBom,
        bomOrderListBusy: state.bom.bomOrderListBusy,
        creatingBomOrders: state.bom.creatingBomOrders,
        removingBomOrders: state.bom.removingBomOrders,
        suppliers: state.bom.suppliers,
        showBomOrderMove: state.bom.showBomOrderMove,
        searchBomOrderMove: state.bom.searchBomOrderMove,
        searchBomOrderMoveBusy: state.bom.searchBomOrderMoveBusy,
        selectedBomOrderMove: state.bom.selectedBomOrderMove,
    }
}

export default connect(mapStateToProps)(BomOrder)