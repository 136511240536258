import React from 'react';
import connect from "react-redux/es/connect/connect";
import {GlobalHotKeys} from "react-hotkeys";
import _ from "lodash";
import {getRowByDesignator, gotoPickup, gotoPlace} from "./BomProduction";
import {enableMohicanFeedbackControl, selectDesignatorBomProduction, updateMohicanSetting} from "../actions";
import {getOrderItemsByRow} from "./functions";

class BomProductionHotkeyHandler extends React.Component {

    goToNextAndPickupDebounceCaller = (e) => {
        e.preventDefault();
        this.goToNextAndPickupDebounce(e);
    }

    goToNextAndPickupDebounce= _.debounce((e) => {
        this.goToNextAndPickup();
    }, 300,{leading: true, trailing: false});

    goToPlaceDebounceCaller = (e) => {
        e.preventDefault();
        this.goToPlaceDebounce(e);
    }

    goToPlaceDebounce= _.debounce((e) => {
        this.goToPlace();
    }, 300,{leading: true, trailing: false});

    goToPickup = (reset) => {
        const printId = this.props.print.id;
        const r= this.props.selectedDesignator.r;

        let orderItems = getOrderItemsByRow(r, this.props.bomOrderList);
        orderItems = orderItems.map(o => {
            let lastUsedBoardLocation = this.props.boardLocations.find(bl => bl.boardLocation === o.boardLocation)
            return {...o, lastUsedBoardLocation}
        })

        if (orderItems && orderItems.length) {
            gotoPickup(printId, orderItems[0], this.props.mohicanPlankjePosition, this.props.dispatch, reset);
        }
    }

    goToPlace = () => {
        //if( !this.isDisabled() ) {
            const printId = this.props.print.id;
            const d = this.props.selectedDesignator;

            let {bomProductionSelected} = this.props;

            let bomProductionName = this.props.currentUser.firstName;

            gotoPlace(printId, d, this.props.mohicanPcbPosition, bomProductionSelected, bomProductionName, this.props.dispatch)
        //}
    }

    selectNextD = () => {
        //if( !this.isDisabled() ) {
            console.log('pickupNext')
            const printId = this.props.print.id;

            if (this.props.nextDesignator) {

                let r = getRowByDesignator(this.props.nextDesignator, this.props.print.bom);
                //console.log(JSON.stringify(r))
                let orderItems = getOrderItemsByRow(r, this.props.bomOrderList);
                orderItems = orderItems.map(o => {
                    let lastUsedBoardLocation = this.props.boardLocations.find(bl => bl.boardLocation === o.boardLocation)
                    return {...o, lastUsedBoardLocation}
                })

                //console.log(JSON.stringify(orderItems))
                if (orderItems && orderItems.length) {
                    gotoPickup(printId, orderItems[0], this.props.mohicanPlankjePosition, this.props.dispatch);
                }

                this.props.dispatch(selectDesignatorBomProduction(this.props.nextDesignator, this.props.nextNextDesignator))
            }
        //}
    }

    goToNextAndPickup = () => {
        const d = this.props.selectedDesignator;

        if (this.props.lastGoToDesignator && this.props.lastGoToDesignator.id === d.id) {
            this.selectNextD()
        } else {
            this.goToPickup()
        }
    }

    render() {
        const d= this.props.selectedDesignator;

        const noPickup = false;

        return (
            <div>
                <GlobalHotKeys
                    innerRef={ (c) => this._container = c }
                    keyMap={{
                        ESCAPE: "escape",
                        DOWN: "down",
                        UP: "up",
                        LEFT: "left",
                        RIGHT: "right",
                        SPACE: "space",

                        CTRL: "ctrl",

                        HOME: "home",
                        R: "r",

                        ZERO: "0",
                        COMMA: ",",
                        ONE: "1",
                        TWO: "2",
                        THREE: "3",
                        ENTER: "Enter",

                        FOUR: "4",
                        FIVE: "5",
                        SIX: "6",
                        SEVEN: "7",
                        EIGHT: "8",
                        NINE: "9",
                        PLUS: "+"
                    }} handlers={{
                    UP: (e) => {
                        e.preventDefault();
                        let newValue = {...this.props.mohicanSettings.pan};
                        newValue.y -= 1;
                        this.props.dispatch(updateMohicanSetting('pan', newValue))
                    },
                    DOWN: (e) => {
                        e.preventDefault();
                        let newValue = {...this.props.mohicanSettings.pan};
                        newValue.y += 1;
                        this.props.dispatch(updateMohicanSetting('pan', newValue))
                    },
                    LEFT: (e) => {
                        e.preventDefault();
                        let newValue = {...this.props.mohicanSettings.pan};
                        newValue.x -= 1;
                        this.props.dispatch(updateMohicanSetting('pan', newValue))
                    },
                    RIGHT: (e) => {
                        e.preventDefault();
                        let newValue = {...this.props.mohicanSettings.pan};
                        newValue.x += 1;
                        this.props.dispatch(updateMohicanSetting('pan', newValue))
                    },
                    SPACE: (e) => {
                        this.goToNextAndPickupDebounceCaller(e)
                        //this.props.dispatch(enableMohicanFeedbackControl(true))
                    },
                    ESCAPE: (e) => {
                        e.preventDefault();
                        this.props.dispatch(enableMohicanFeedbackControl(false))
                    },
                    ZERO: this.goToNextAndPickupDebounceCaller,
                    COMMA: (e) => {
                        e.preventDefault();
                        e.stopPropagation();
                    },
                    ONE: this.goToNextAndPickupDebounceCaller,
                    TWO: this.goToNextAndPickupDebounceCaller,
                    THREE: this.goToNextAndPickupDebounceCaller,
                    ENTER: (e) => {
                        e.preventDefault();
                        e.stopPropagation();
                    },

                    CTRL: (e) => this.goToPlaceDebounceCaller(e),
                    FOUR: (e) => this.goToPlaceDebounceCaller(e),
                    FIVE: (e) => this.goToPlaceDebounceCaller(e),
                    SIX: (e) => this.goToPlaceDebounceCaller(e),
                    SEVEN: (e) => this.goToPlaceDebounceCaller(e),
                    EIGHT: (e) => this.goToPlaceDebounceCaller(e),
                    NINE: (e) => this.goToPlaceDebounceCaller(e),
                    PLUS: (e) => this.goToPlaceDebounceCaller(e),
                    HOME: () => {
                        console.log(d.value + ' keydown home')
                        if( !noPickup ) {
                            this.goToPickup(999)
                        }
                    },
                    R: () => {
                        console.log(d.value + ' keydown r')
                        this.resetProduction()
                    }
                }}></GlobalHotKeys>
            </div>
        );
    }
}


const mapStateToProps = (state) => {
    return {
        print: state.bom.print,
        selectedDesignator: state.bom.selectedDesignator,
        nextDesignator: state.bom.nextDesignator,
        lastGoToDesignator: state.bom.lastGoToDesignator,
        bomProductionSelected: state.bom.bomProductionSelected,
        bomProductionSelectedNext: state.bom.bomProductionSelectedNext,
        currentUser: state.main.currentUser,
        mohicanPcbPosition: state.bom.mohicanPcbPosition,
        bomOrderList: state.bom.bomOrderList,
        mohicanPlankjePosition: state.bom.mohicanPlankjePosition,
        boardLocations: state.bom.boardLocations,
        mohicanSettings: state.bom.mohicanSettings,
    }
}

export default connect(mapStateToProps)(BomProductionHotkeyHandler)