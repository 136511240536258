
const initialState = {
    resize: {isResizing: false},
    colwidth: getStoredColWidth(),
    sort: {}
}

function getStoredColWidth() {
    let d = localStorage.getItem('deltaprototable');
    if( d != null && d !== "null" ) {
        return JSON.parse(d);
    }
    return {}
}

function updateStoredColWidth(value) {
    localStorage.setItem('deltaprototable', JSON.stringify(value))
}

const deltatable = (state = initialState, action) => {
    switch( action.type ) {
        case 'DELTA_TABLE_RESIZE':
            return {
                ...state,
                resize: {
                    ...state.resize,
                    [action.name]: action.value
                }
            }
        case 'DELTA_TABLE_SET_COL_WIDTH':
            const res = {
                ...state,
                colwidth: {
                    ...state.colwidth,
                    [action.name]: {
                        ...state.colwidth[action.name],
                        [action.id]: {width: action.width}
                    }
                }
            }
            updateStoredColWidth(state.colwidth);
            return res;
        case 'DELTA_TABLE_RESET_WIDTH':
            const reset = {
                ...state,
                colwidth: {
                    ...state.colwidth,
                    [action.name]: {
                    }
                }
            }
            updateStoredColWidth(state.colwidth);
            return reset;
        case 'DELTA_TABLE_ADD_SORT':
            let newSort1 = []
            if( state.sort[action.name] && state.sort[action.name].find(s => s.column === action.column) ) {
                newSort1 = state.sort[action.name].map(s => (
                    s.column === action.column ? {...s, sort: s.sort === 'descending' ? 'ascending' : 'descending'} : s
                ))
            } else {
                let sort = state.sort[action.name];
                newSort1= [...(sort ? sort: []), {
                    column: action.column,
                    header: action.header,
                    sort: 'ascending',
                    id: sort ? sort.length + 1 : 1
                }]
            }
            return {
                ...state,
                sort: {...state.sort, [action.name]: newSort1}
            }
        case 'DELTA_TABLE_REMOVE_SORT':
            if( state.sort[action.name] && state.sort[action.name].find(s => s.id === action.id) ) {
                return {
                    ...state,
                    sort: {...state.sort, [action.name]: state.sort[action.name].filter(s => s.id !== action.id)}
                }
            }
            return state
        case 'DELTA_TABLE_ADD_FILTER':
            let newSort2 = []
            if( state.sort[action.name] && state.sort[action.name].find(s => s.column === action.column) ) {
                newSort2 = state.sort[action.name].map(s => (
                    s.column === action.column ? {...s, filter: action.value} : s
                ))
            } else {
                let sort = state.sort[action.name];
                newSort2= [...(sort ? sort: []), {
                    column: action.column,
                    header: action.header,
                    filter: action.value,
                    id: sort ? sort.length + 1 : 1
                }]
            }
            return {
                ...state,
                sort: {...state.sort, [action.name]: newSort2}
            }
        case 'DELTA_TABLE_REMOVE_FILTER':
            if( state.sort[action.name] && state.sort[action.name].find(s => s.id === action.id) ) {
                return {
                    ...state,
                    sort: {...state.sort, [action.name]: state.sort[action.name].filter(s => s.id !== action.id)}
                }
            }
            return state
        default:
            return state;
    }
}

export default deltatable