import React from "react";
import {
    addDeltaTableFilter,
    addDeltaTableSort,
    addSharketingSort,
    deltaTableResetWidth,
    deltaTableSetResize
} from "../actions";
import PropTypes from "prop-types";
import {withRouter} from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import {getColumnName} from "./DeltaTable";
import {Dropdown, Icon} from "semantic-ui-react";

function renderColResizer(handleMouseDown, handleDoubleClick) {
    return ( <div style={{
            backgroundColor: 'blue',
            width: 0
        }}>
        <div style={{display: 'inline-block',
            marginLeft: '-6px',
            width: '16px',
            marginTop: '-4px',
            height: 'calc(100% + 8px)',
            right: '-18px',
            cursor: 'col-resize',
            zIndex: 7}}
             onMouseDown={(e, t) => handleMouseDown(e, t)}
             onDoubleClick={() => {
                 if(handleDoubleClick != null ) {
                     handleDoubleClick()
                 }
             }}
        />
        </div>
    )
}

export function getStyle(name, c, colwidth) {
    let backgroundColor = 'white'
    const style = c.headerStyle ? c.headerStyle : {}

    if( colwidth[name] && colwidth[name][c.id] ) {
        return {
            width: colwidth[name][c.id].width,
            backgroundColor: backgroundColor,
            position: 'sticky',
            top: 0,
            ...style
        }
    }
    return {
        width: c.defaultWidth ? c.defaultWidth: 100,
        backgroundColor: backgroundColor,
        position: 'sticky',
        top: 0,
        ...style
    }
}

export function getCellStyle(name, c, r, i, colwidth) {
    let backgroundColor = 'white'
    let style = {}
    if( c.getCellStyle ) {
        style = c.getCellStyle(r, i)
    }

    if( colwidth[name] && colwidth[name][c.id] ) {
        return {
            width: colwidth[name][c.id].width,
            backgroundColor: backgroundColor,
            ...style
        }
    }
    return {
        width: c.defaultWidth ? c.defaultWidth: 100,
        backgroundColor: backgroundColor,
        ...style
    }
}

class DeltaTableHeader extends React.Component {


    getSortIcon(name) {
        const sort = this.props.sort

        if( name ) {
            const s = sort.find(s => s.sort && s.column === name);

            if( s ) {
                if (s.sort === 'ascending') {
                    return <Icon name='sort up' size='' color='blue'/>
                } else if (s.sort === 'descending') {
                    return <Icon name='sort down' size='' color='blue'/>
                }

                return <Icon name='sort' size='small' color='blue'/>
            }
        }

        return <Icon name='sort'/>
    }

    getFilterIcon(name) {
        const sort = this.props.sort

        if( name && sort.some(s => s.filter && s.column === name) ) {
            return <Icon name='filter' size='small' color='blue'/>
        }

        return <Icon name='filter' size='small'/>
    }

    render() {
        const {name, c, colwidth} = this.props;

        const filterOptions = c.filterOptions;
        const style = getStyle(name, c, colwidth);

        return (
            <td key={c.id} style={style}>
                <div style={{display: 'flex'}}>
                    <div style={{flex: 1, overflow: 'hidden'}}>
                        {c.header}
                    </div>
                    <div>
                        {c.sort ? <Dropdown icon={this.getSortIcon(c.sort)} onClick={() => this.props.dispatch(addDeltaTableSort(name, c.sort, c.header))}/> : null}
                        {c.filter ?
                            <div style={{pointer: 'cursor'}}>
                            <Dropdown text=' ' search icon={this.getFilterIcon(c.filter)} options={filterOptions}
                                onChange={(e,d) => {
                                    this.props.dispatch(addDeltaTableFilter(name, c.filter, d.value, c.header))
                                }}
                            /></div> : null}
                    </div>
                    {renderColResizer((e,t) => {
                        let startWidth= style.width;

                        this.props.dispatch(deltaTableSetResize('isResizing', {name: name, id: c.id}))
                        this.props.dispatch(deltaTableSetResize('startX', e.clientX))
                        this.props.dispatch(deltaTableSetResize('startWidth', startWidth))
                    }, () => {
                        this.props.dispatch(deltaTableResetWidth(name))
                    })}


                </div>
            </td>
        );
    }
}

DeltaTableHeader.propTypes = {
    c: PropTypes.object.isRequired,
    name: PropTypes.string.isRequired,
    sort: PropTypes.array
}

DeltaTableHeader.defaultProps = {

}

const mapStateToProps = (state) => {
    return {
        colwidth: state.deltatable.colwidth,
        resize: state.deltatable.resize,
    }
}

export default connect(mapStateToProps)(DeltaTableHeader)