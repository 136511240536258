import React from 'react'
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {Header, Search} from "semantic-ui-react";
import {changeSiteSearchValue, openSiteSearch, searchPrintOrCompany, siteSearch} from "../actions";
import {Link} from "react-router-dom";

class BomStockOverview extends React.Component {

    state = {
        searchValue: ''
    }

    componentDidMount() {
        if( this.searchInput ) {
            setTimeout(() => {
                this.searchInput.focus()
            }, 100);
        }
    }

    handleKeyPress = (event, value) => {
        if( event.charCode === 13 ) {
            //this.fetchProjects(false, 'searchValue', this.state.searchValue);
        }
    }

    render() {
        const s= this.props.searchPrintOrCompany.list;

        const options = s.map(s => ({
            ...s
        })).sort((a,b) => b.title.localeCompare(a.title))

        return <section className="pageContainer" style={{textAlign: 'left'}}>
            <Header>Stock</Header>

            <Link to='/mystock'>Open my stock overview</Link>

            <div style={{textAlign: 'center', marginTop: '1em'}}>
                Select a project or company:

                <Search
                    fluid style={{width: 400, margin: 'auto'}}
                    input={{ icon: 'search', iconPosition: 'left', placeholder: 'Search...', fluid: true, ref: (searchInput) => { this.searchInput = searchInput; }} }
                    onSearchChange={(e) => {
                        //this.props.dispatch(changeSiteSearchValue(e.target.value))
                        this.props.dispatch(searchPrintOrCompany(e.target.value))
                    }}
                    results={options}
                    onResultSelect={(e,d) => {
                        //console.log(JSON.stringify(d.result))
                        this.props.history.push('/stock/'+d.result.id);
                        //this.props.dispatch(openSiteSearch(false))
                    }}
                />
            </div>
        </section>
    }
}


BomStockOverview.propTypes = {
    r: PropTypes.object
}

const mapStateToProps = (state) => {
    return {
        currentUser: state.main.currentUser,
        print: state.bom.print,
        searchPrintOrCompany: state.main.searchPrintOrCompany,
    }
}

export default connect(mapStateToProps)(BomStockOverview)