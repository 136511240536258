import React from 'react'
import {connect} from "react-redux";
import {Icon, Popup} from "semantic-ui-react";
import {getMohicanUrl, receiveStomp} from "../actions";
import {Stomp} from "@stomp/stompjs/esm6/compatibility/stomp";
import SockJS from 'sockjs-client'
import PropTypes from "prop-types";

class MohicanSocket extends React.Component {

    state = {
        connected: false
    }

    componentDidMount() {
        let chatId = this.props.chatId;

        //if( this.props.bomId ) {
            this.connect(chatId)
        //}
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if( (prevProps.chatId == null && this.props.chatId) ||
            (this.props.chatId && this.props.chatId !== prevProps.chatId) ) {
            let chatId = this.props.chatId;
            this.connect(chatId)
        }
    }

    componentWillUnmount() {
        if (this.stompClient) {
            this.stompClient.disconnect(() => {
                this.stompClient = null;
            });
        }

        if (this.socket) {
            this.socket.close(1000, "Normal Closure")
        }
    }

    connect(chatId) {

        console.log('connect to ' + chatId)

        let stompClient = this.state.stompClient;
        let socket;

        //if( chatId == null ) {
        //    if( stompClient ) {
        //        stompClient.unsubscribe('1');
        //    }
        //    return;
        //}

        let route = getMohicanUrl('/topic/mohican') ;

        if( !stompClient ) {
            socket = SockJS('/sock');
            socket.debug = () => {}
            stompClient = Stomp.over(socket);
            stompClient.debug = () => {}
        }

        let handleMessage = this.handleMessage;
        let setIsConnected = this.setIsConnected;

        if( stompClient && stompClient.connected ) {
            stompClient.unsubscribe('1');

            stompClient.subscribe(route, (value) => {
                handleMessage(value)
            }, {id: '1'})
        } else {

            stompClient.connect({chatId}, function () {

                stompClient.subscribe(route, (value) => {
                    handleMessage(value)
                }, {id: '1'})

                setIsConnected()
            });

            stompClient.disconnect = () => {
                stompClient.unsubscribe('1');
                stompClient.ws.close(1000, "Normal Closure")
            }

        }

        this.setState({stompClient: stompClient, socket: socket})

        this.stompClient = stompClient;
        this.socket = socket;
    }

    handleMessage = (value) => {
        this.props.dispatch( receiveStomp(JSON.parse(value.body)) );
    }

    setIsConnected = () => {
        this.setState({connected: true})
    }

    render() {

        let connected = this.state.stompClient && this.state.stompClient.connected;

        if( this.props.hidden ) {
            return <span style={{opacity: .5, color: connected ? 'green' : 'red'}}>.</span>
        }

        return <>
            {connected ? <Popup position='bottom right' trigger={<Icon name='lightning' color='green'/>} content='Server socket is connected' /> :
                <Popup position='bottom right' trigger={<Icon name='lightning' color='red'/>} content='Server socket is disconnected' />}
        </>
    }
}

MohicanSocket.propTypes = {
    //chatId: PropTypes.string,
    hidden: PropTypes.bool
};

MohicanSocket.defaultProps = {
    //chatId: null,
    hidden: false
};

const mapStateToProps = (state) => {
    return {
    }
}

export default connect(mapStateToProps)(MohicanSocket)