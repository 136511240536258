import React, {Component} from 'react';
import connect from "react-redux/es/connect/connect";
import {Button, Input, Table} from "semantic-ui-react";
import {changePackingType, getAllPackingTypes, savePackingType} from "../actions";
import Translate from "../Translate";

export const renderSvg = (value) => {

    let xmin= (value.width/2)
    let ymin= (value.height/2)
    let r= Math.min(value.width, value.height)

    return <svg viewBox={"-" + xmin + " -" + ymin + " "+value.width+" "+value.height} xmlns="http://www.w3.org/2000/svg">
        {value.width && value.height &&
            <rect x={-(value.width/2)} y={-(value.height/2)} width={value.width} height={value.height} fill="#000000" rx={r/20}/>

        }

        {value.width && value.height && value.mrk !== 'line' &&
            <ellipse cx={r/6 - xmin} cy={r/6 - ymin} rx={r/10} ry={r/10} fill="#FFFFFF"/>

        }

        {value.width && value.height && value.mrk === 'line' &&
            <line x1={r/6 - xmin} y1={(r/6)-(r/20)-ymin} x2={r/6 - xmin} y2={r - (r/6) + (r/20)-ymin} stroke="#FFFFFF" strokeWidth={r/10}/>

        }

        {value.symbol && <text x={0} y={0} fontSize={r/2} fill="#FFFFFF" textAnchor="middle" alignmentBaseline="central">{value.symbol}</text>}
    </svg>
}

export const getSvgFromList= (list, name) => {

    let res= null;

    if( list ) {
        let resList = list.filter(v => v.name === name);

        if (resList.length === 1) {
            res = renderSvg(resList[0])
        }
    }

    return res;
}

class PackingTypes extends Component {

    state= {

    }

    componentDidMount() {
        this.props.dispatch(getAllPackingTypes())
    }

    handleChange = (e, id) => {

        this.props.dispatch(changePackingType(id, e.target.name, e.target.value))

    }

    handleSave = (value) => {
        this.props.dispatch(savePackingType(value))
    }

    render() {

        let list= this.props.packingTypeList;

        return <div style={{width: '100%'}}>

            <Table celled>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>name</Table.HeaderCell>
                        <Table.HeaderCell>width</Table.HeaderCell>
                        <Table.HeaderCell>height</Table.HeaderCell>
                        <Table.HeaderCell>mark</Table.HeaderCell>
                        <Table.HeaderCell>symbol</Table.HeaderCell>
                        <Table.HeaderCell></Table.HeaderCell>
                        <Table.HeaderCell></Table.HeaderCell>
                    </Table.Row>
                </Table.Header>

                <Table.Body>

                    {list.map((l) => (
                        <Table.Row key={l.id}>
                            <Table.Cell>
                                <Input name='pname' value={l.pname} onChange={(e) => this.handleChange(e,l.id)} style={{width: '100%'}}/>
                            </Table.Cell>
                            <Table.Cell>
                                <Input name='width' type='number' value={l.width} onChange={(e) => this.handleChange(e,l.id)} style={{width: '100%'}}/>
                            </Table.Cell>
                            <Table.Cell>
                                <Input name='height' type='number' value={l.height} onChange={(e) => this.handleChange(e,l.id)} style={{width: '100%'}}/>
                            </Table.Cell>
                            <Table.Cell>
                                <Input name='mrk' value={l.mrk} onChange={(e) => this.handleChange(e,l.id)} style={{width: '100%'}}/>
                            </Table.Cell>
                            <Table.Cell>
                                <Input name='symbol' value={l.symbol} onChange={(e) => this.handleChange(e,l.id)} style={{width: '100%'}}/>
                            </Table.Cell>
                            <Table.Cell>
                                <div style={{width: 100}}>
                                    {l.width && l.height && renderSvg(l)}
                                </div>
                            </Table.Cell>
                            <Table.Cell>
                                <Button primary={l.dirty} onClick={() => this.handleSave(l)}><Translate value="save"/></Button>
                            </Table.Cell>
                        </Table.Row>
                    ))}

                </Table.Body>


            </Table>

            <Button onClick={() => this.handleSave({name: ''})}>add</Button>
        </div>
    }
}

PackingTypes.propTypes = {
};

PackingTypes.defaultProps = {
};

const mapStateToProps = (state) => {
    return {
        lang: state.main.lang,
        packingTypeList: state.bom.packingTypeList,
    }
}

export default connect(mapStateToProps)(PackingTypes)