import React from 'react'
import {Segment, Transition} from "semantic-ui-react";
import PropTypes from "prop-types";

class Toast extends React.Component {

    state = {
        visible: true
    }

    componentDidMount() {
        if( this.props.timeout ) {
            setTimeout(() => this.hide(), this.props.timeout);
        }
    }

    hide = () => {
        this.setState({visible: false})

        setTimeout(() => this.handleClose(), this.props.duration);
    }

    handleClose = () => {
        if( this.props.onClose ) {
            this.props.onClose()
        }
    }

    render() {
        return (
            <div
                style={{
                    right: '16px',
                    position: 'fixed',
                    bottom: '0px',
                    zIndex: 1000,
                    textAlign: 'center',
                    width: '100vw',
                }}
            >
                <Transition
                    animation={this.props.animation}
                    duration={this.props.duration}
                    //visible={this.state.mouseOver ? false : this.state.bounce}
                    visible={this.state.visible}
                    transitionOnMount={true}
                >
                        <Segment color={this.props.color} inverted={this.props.inverted} style={{width: this.props.width, margin: 'auto', borderBottomRightRadius: 'unset', borderBottomLeftRadius: 'unset'}}
                                 onMouseEnter={() => this.setState({mouseOver: true})}
                                 //onMouseLeave={() => this.setState({mouseOver: false})}
                        >
                            <>{this.props.message}</>
                        </Segment>
                </Transition>
            </div>
        );
    }

}

Toast.propTypes = {
    message: PropTypes.any,
    timeout: PropTypes.number,
    duration: PropTypes.number,
    onClose: PropTypes.func,
    width: PropTypes.number,
    color: PropTypes.string,
    animation: PropTypes.string,
};

Toast.defaultProps = {
    message: '',
    timeout: 4000,
    duration: 1000,
    onClose: null,
    width: 240,
    color: 'blue',
    animation: 'fly up'
}

export default Toast