import React from 'react'
import PropTypes from 'prop-types'

class TabMenu extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            height: 0
        }
    }

    componentDidMount() {
        const height = this.divElement.clientHeight;
        this.setState({ height });
    }

    render() {
        const {useFullPageWidth} = this.props;
        return <div>
            <div
                className='paper-menu'
                ref={ (divElement) => { this.divElement = divElement } }
                style={useFullPageWidth ? {position: 'absolute', left: 8, width: 'calc(100vw - 16px)'} : {}}
            >
                {this.props.children}
            </div>
            <div style={useFullPageWidth ? {height: this.state.height} : {}}/>
        </div>
    }
}

TabMenu.propTypes = {
    useFullPageWidth: PropTypes.bool
};

TabMenu.defaultProps = {
    useFullPageWidth: false
};

export default TabMenu;