import React from 'react'
import connect from "react-redux/es/connect/connect";
import {Form, Popup, Table} from "semantic-ui-react";
import {addComponentData, getComponentDataList, getOrderPartList} from "../actions";
import Translate from "../Translate";

const json = {
    whiteSpace: 'pre',
    display: 'inline-block'
}

class ComponentDataView extends React.Component {

    state = {
        mpn: ''
    }

    componentDidMount() {
        this.props.dispatch(getComponentDataList())
        this.props.dispatch(getOrderPartList())
    }

    render() {

        const {componentDataList, orderPartList} = this.props;

        return <div style={{textAlign: 'left'}}>

            <Form>
                <Form.Input label='MPN' value={this.state.mpn} onChange={(e) => this.setState({mpn: e.target.value})}/>
                <Form.Button
                    disabled={this.props.busyAddComponent || !this.state.mpn}
                    loading={this.props.busyAddComponent}
                    onClick={() => this.props.dispatch(addComponentData(this.state.mpn, () => {
                        this.setState({mpn: ''})
                    }))}
                ><Translate value="add"/></Form.Button>
            </Form>

            <div style={{overflow: 'auto', paddingTop: 32}}>
                <Table celled>
                    <Table.Header>
                        <Table.HeaderCell>Manufacturer</Table.HeaderCell>
                        <Table.HeaderCell>MPN</Table.HeaderCell>
                        <Table.HeaderCell>Spec</Table.HeaderCell>
                        <Table.HeaderCell>Suppliers</Table.HeaderCell>
                    </Table.Header>
                    <Table.Body>
                        {orderPartList && orderPartList.length && orderPartList.map((c) => (
                            <Table.Row>
                                <Table.Cell>{c.manufacturer}</Table.Cell>
                                <Table.Cell>{c.mpn}</Table.Cell>
                                <Table.Cell>{c.specification.displayValue}</Table.Cell>
                                <Table.Cell>{c.suppliers.length}</Table.Cell>
                            </Table.Row>
                        ))}
                    </Table.Body>
                </Table>
            </div>

            <div style={{overflow: 'auto', paddingTop: 32}}>
                <Table celled>
                    <Table.Header>
                        <Table.HeaderCell>MPN</Table.HeaderCell>
                        <Table.HeaderCell>MarkPosition</Table.HeaderCell>
                        <Table.HeaderCell>Spec</Table.HeaderCell>
                        <Table.HeaderCell>Suppliers</Table.HeaderCell>
                    </Table.Header>
                    <Table.Body>
                        {componentDataList.map((c) => (
                            <Table.Row>
                                <Table.Cell>{c.mpn}</Table.Cell>
                                <Table.Cell>{c.markPosition}</Table.Cell>
                                <Table.Cell>
                                    {c.specification && <Popup position='bottom center' hoverable={true} content={<p style={json}>{JSON.stringify(c.specification, null, 4)}</p>} trigger={<div>{c.specification.displayValue}</div>} /> }
                                </Table.Cell>
                                <Table.Cell>
                                    <ul>{c.suppliers.map(s => (
                                        <li>
                                            <Popup position='bottom center' hoverable={true} content={<p style={json}>{JSON.stringify(s, null, 4)}</p>} trigger={<div>{s.supplier}</div>} />
                                        </li>
                                    ))}</ul>
                                </Table.Cell>
                            </Table.Row>
                        ))}
                    </Table.Body>
                </Table>
            </div>
        </div>
    }
}

const mapStateToProps = (state) => {
    return {
        componentDataList: state.bom.componentDataList,
        orderPartList: state.bom.orderPartList,
        busyAddComponent: state.bom.busyAddComponent
    }
}

export default connect(mapStateToProps)(ComponentDataView)