import React, {Component} from "react";
import PropTypes from "prop-types"
import {Button, Icon, Loader, Popup, Table} from "semantic-ui-react";
import {updateStockAndPrice} from "../actions";
import {isInTodos, recentUpdated, renderSupplier, renderSupplierUrl} from "./functions";
import {connect} from "react-redux";
import Highlighter from "react-highlight-words";
import BomMoqDisplay, {MAX_MOQ} from "./BomMoqDisplay";
import OnVisible from "react-on-visible";
import BomOrderpartStock from "./BomOrderpartStock";



export function renderImageForPart(op, sp) {
    if( op && op.imageUrl ) {

        let image = <img src={op.imageUrl} alt="part image" className="bst-image" style={{maxHeight: '32px', maxWidth: '32px', background: 'URL(https://www.deltaproto.com/img/logo.png) center center black'}} />;

        let credit = <div style={{fontSize: '.4em'}}>
            <a href={op.imageCreditUrl}>{op.imageCreditString}</a>
        </div>

        if( sp && sp.supplier ) {
            return <>
                {renderSupplierUrl(sp, image)}
                {credit}
            </>
        } else {
            return <>
                {image}
                {credit}
            </>
        }
    } else {
        return <></>;
    }
}

export function renderDecimalPlaces(value, decimals = 2) {
    if( value != null ) {
        let round = Math.pow(10, decimals);
        return new Number((Math.round(value * round) / round)).toLocaleString('nl-NL', {
            minimumFractionDigits: decimals,
            maximumFractionDigits: decimals
        });
    }
    return value;
}

export function renderEuro(value, decimals = 2) {
    if( value != null ) {
        let round = Math.pow(10, decimals);
        return "€ " + new Number((Math.round(value * round) / round)).toLocaleString('nl-NL', {
            minimumFractionDigits: decimals,
            maximumFractionDigits: decimals
        });
    }
    return value;
}

export function renderPriceSinglePart(sp, need) {

    let priceOnePart= null;

    for (let i = 0; i < 9; i++) {
        let brk = sp['break' + (i+1)];
        let price= sp['price' + (i+1)]

        if( brk <= need ) {
            if( priceOnePart == null || price < priceOnePart) {
                priceOnePart = price;
            }
        }
    }

    if( priceOnePart == null ) {
        priceOnePart= getHighestPrice(sp)
    }

    let decimals = 2;
    if( priceOnePart < 0.01 ) {
        decimals = 3;
    }

    return <div style={{whiteSpace: 'nowrap'}}>
        {renderEuro(priceOnePart, decimals)}
    </div>
}

function getHighestPrice(list) {
    let highest = 0;

    for (let i = 0; i < 9; i++) {
        let price = list['price' + (i + 1)]

        if( price && price > highest ) {
            highest = price
        }
    }
    return highest;
}

export function renderPrice(sp, parts, amount) {

    let need= parts * amount;

    let priceOnePart= null;

    for (let i = 0; i < 9; i++) {
        let brk = sp['break' + (i+1)];
        let price= sp['price' + (i+1)]

        if( brk <= need ) {
            if( priceOnePart == null || price < priceOnePart) {
                priceOnePart = price;
            }
        }
    }

    if( priceOnePart == null ) {
        priceOnePart = getHighestPrice(sp)
    }

    if( sp.moq && need < sp.moq ) {
        need = sp.moq;
    }

    return <div style={{whiteSpace: 'nowrap'}}>
        {renderEuro((need * priceOnePart) / amount)}
    </div>
}

export function getNeed(parts, amount, extra) {
    return (parts * amount) + extra;
}

export function getPriceOnePartMoq(sp, parts, amount, extra = 0) {
    let need= getNeed(parts, amount, extra)

    if( sp.moq && need < sp.moq ) {
        need = sp.moq;
    }

    if( need % sp.moq ) {
        need = need + (sp.moq - (need % sp.moq))
    }

    let priceOnePart= null;

    for (let i = 0; i < 9; i++) {
        let brk = sp['break' + (i+1)];
        let price= sp['price' + (i+1)]

        if( brk != null && price != null ) {
            if (brk <= need) {
                if (priceOnePart == null || price < priceOnePart) {
                    priceOnePart = price;
                }
            }
        }
    }

    if( priceOnePart == null ) {
        priceOnePart = getHighestPrice(sp)
    }

    return {need, price: priceOnePart};
}

export function getPriceWithoutMoq(sp, parts, amount) {

    let need= parts * amount;

    let priceOnePart= null;

    for (let i = 0; i < 9; i++) {
        let brk = sp['break' + (i+1)];
        let price= sp['price' + (i+1)]

        if( brk <= need ) {
            if( priceOnePart == null || price < priceOnePart) {
                priceOnePart = price;
            }
        }
    }

    if( priceOnePart == null ) {
        return getHighestPrice(sp)
    }

    return parts * priceOnePart;
    /*return <div>
        {renderEuro(parts * priceOnePart)}
    </div>*/
}

class BomOrderparts extends Component {

    state = {
        hoverid: null
    }

    getStyle(value, opcode) {
        if( this.props.todos != null && isInTodos(value, opcode, this.props.todos) ) {
            return {backgroundColor: 'lightgrey'}
        }
        return {}
    }

    handleOpClick = (op) => {
        if( this.props.onOrderpart ) {
            this.props.onOrderpart(op)
        }
    }

    handleSpClick = (sp, op) => {
        if( this.props.onSupplierpart ) {
            this.props.onSupplierpart(sp, op)
        }
    }

    handleBuddyClick = (bp, op) => {
        if( this.props.onBuddypart ) {
            this.props.onBuddypart(bp, op)
        }
    }

    renderOp(op) {
        if( this.props.onOrderpart ) {

            return <Popup trigger={<button className='link-button' onClick={() => this.handleOpClick(op)}>
                {op.manufacturer + ' ' + op.mpn}
            </button>}/>

        } else {
            return <div>
                {op.manufacturer + ' ' + op.mpn}
            </div>
        }

    }

    render() {
        const r= this.props.r;
        const todos= this.props.todos;

        const list= this.props.list;
        const buddyList= this.props.buddyList;
        const stockList= this.props.stockList;

        if( list == null ) {
            return <div></div>
        }

        let showStockAndPrice= this.props.showStockAndPrice; //list && list.filter(op => op.suppliers.filter(sp => sp.stock).length).length;
        let allowSelect= this.props.allowSelect;

        return <div>
            <Table striped style={{width: '100%'}}>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Img</Table.HeaderCell>
                        <Table.HeaderCell>Value</Table.HeaderCell>
                        <Table.HeaderCell>MPN</Table.HeaderCell>
                        <Table.HeaderCell>SKU</Table.HeaderCell>
                        {showStockAndPrice ? <>
                            <Table.HeaderCell style={{whiteSpace: 'nowrap'}}>Stock <Icon link name='check' onClick={() => {

                                let keys= []
                                list.forEach(op => {
                                    keys= [...keys, ...(op.suppliers.map(sp => sp.id.toString()))]
                                })

                                console.log( keys )

                                this.props.dispatch(updateStockAndPrice(keys))


                            }}/> </Table.HeaderCell>
                            <Table.HeaderCell>MOQ</Table.HeaderCell>
                            <Table.HeaderCell>Packing</Table.HeaderCell>
                            <Table.HeaderCell>Price</Table.HeaderCell>
                        </> : ''}
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                {stockList && stockList.filter(s => s.orderpart).map(s => (<Table.Row className='dp-part-row' key={s.id}>
                    <Table.Cell style={{}}>
                        {JSON.stringify(s.orderpart)}
                    </Table.Cell>
                </Table.Row>))}
                {buddyList && buddyList.filter(b => b.buddyPart).map(op => (<Table.Row className='dp-part-row' key={op.id}>

                    <Table.Cell style={{}}>
                        {renderImageForPart(op)}
                    </Table.Cell>
                    <Table.Cell style={{}}>
                        {(op.specification) ? op.specification.displayValue : ''}
                    </Table.Cell>
                    <Table.Cell style={{}}>
                        {op.manufacturer + ' ' + op.mpn}
                        <div style={{color: '#7d7d7d'}}>{(op.shortDescription) ? op.shortDescription : ''}</div>
                    </Table.Cell>
                    <Table.Cell>
                        <button className='link-button' onClick={() => this.handleBuddyClick(op.buddyPart, op)}>
                            {renderSupplier({supplier: "DELTAPROTO", sku: op.buddyPart.name})}
                        </button>
                    </Table.Cell>
                    <Table.Cell></Table.Cell>
                    <Table.Cell>1</Table.Cell>
                    <Table.Cell></Table.Cell>
                    <Table.Cell style={{whiteSpace: 'nowrap'}}>{renderEuro(0.01 )}</Table.Cell>

                </Table.Row>))}
                {list && list.map((op) => op && op.suppliers && op.suppliers.map((sp, i) => (<Table.Row key={sp.id}>
                    <Table.Cell style={i === 0 ? {} : {border: 'unset'}}>
                        {i === 0 && renderImageForPart(op)}
                    </Table.Cell>
                    <Table.Cell style={i === 0 ? {} : {border: 'unset'}}>
                        {(i === 0 && op.specification) ? op.specification.displayValue : ''}
                        {(i === 0 && op.type !== 'UNKNOWN') ? <span className='Highlight' style={{marginLeft: '4px'}}>{op.type}</span> : ''}
                    </Table.Cell>
                    <Table.Cell style={i === 0 ? this.getStyle(op.mpn, 'MPN') : {border: 'unset'}}>
                        {i === 0 ? this.renderOp(op) : ''}
                        <div style={{color: '#7d7d7d'}}>{(i === 0 && op.shortDescription) ? op.shortDescription : ''}</div>
                    </Table.Cell>
                    <Table.Cell style={this.getStyle(sp.sku, 'SKU')}>
                        {renderSupplier({...sp, sku: ''})}
                        <Popup trigger={this.props.onSupplierpart == null ? renderSupplier(sp) : <button className='link-button'
                                style={{textAlign: 'left', cursor: this.props.disabled ? 'unset' : 'disabled'}}
                                disabled={
                                    (((sp.stock == null || sp.stock <= 0) && sp.supplier !== 'WURTH' && (sp.stockUS != null && sp.stockUS > 0)) && !this.props.admin)
                                    || ((!recentUpdated(sp) || sp.moq > MAX_MOQ) && !this.props.admin && !this.props.uplacer)
                                    || this.props.disabled
                                }
                                onClick={() => this.handleSpClick(sp, op)}
                                onMouseOver={() => {
                                    if( !recentUpdated(sp) && !sp.loading ) {
                                        this.props.dispatch(updateStockAndPrice([sp.id]))
                                    }
                                    this.setState({hoverid: sp.id})
                                }}
                                onMouseLeave={() => {
                                    this.setState({hoverid: null})
                                }}
                            >{sp.sku}</button>}
                            open={((!recentUpdated(sp) && sp.loading) || sp.moq > MAX_MOQ || sp.stock <= 0) && this.state.hoverid === sp.id}
                            content={
                                sp.loading ? <div><Loader size='tiny' active inline/> Moment.. <br/>We halen de actuele voorraad op bij de leverancier</div> :
                                sp.moq > MAX_MOQ ? `Let op! De 'minimum order quantity' is heel groot: ${sp.moq}` :
                                (sp.stock <= 0 && sp.stockUS != null && sp.stockUS > 0) ? '🇺🇸 Let op! De leverancier heeft dit part alleen in de US op voorraad, dit kost eenmalig 25 euro per bestelling en de levertijd is 3 werkdagen' :
                                sp.stock <= 0 ? 'De leverancier heeft dit part niet op voorraad' : ''
                            }
                        />
                        {renderSupplierUrl(sp)}
                    </Table.Cell>
                    {showStockAndPrice ? <>
                        <Table.Cell
                            style={{display: 'flex', gap: 4}}
                            onMouseOver={() => {
                                if( !recentUpdated(sp) && !sp.loading ) {
                                    this.props.dispatch(updateStockAndPrice([sp.id]))
                                }
                            }}
                        >
                            <BomOrderpartStock sp={sp} refreshWhenVisible={true} requiredAmount={this.props.requiredAmount}/>
                        </Table.Cell>
                        <Table.Cell>
                            <BomMoqDisplay moq={sp.moq}/>
                        </Table.Cell>
                        <Table.Cell>{sp.supplierPackage ? sp.supplierPackage : ''}</Table.Cell>
                        <Table.Cell>{renderPriceSinglePart(sp, this.props.requiredAmount)}</Table.Cell>
                    </> : ''}
                </Table.Row>)))}
                </Table.Body>
            </Table>
        </div>
    }
}

BomOrderparts.propTypes= {
    showStockAndPrice: PropTypes.bool,
    onSupplierpart: PropTypes.func,
    onOrderpart: PropTypes.func,
    onBuddypart: PropTypes.func,
    allowSelect: PropTypes.bool,
    amount: PropTypes.number,
    parts: PropTypes.number,
    admin: PropTypes.bool,
    disabled: PropTypes.bool,
    hasSelection: PropTypes.bool,
    uplacer: PropTypes.bool,
    requiredAmount: PropTypes.number,
}

BomOrderparts.defaultProps = {
    allowSelect: true,
    showStockAndPrice: true,
    admin: false,
    disabled: false,
    hasSelection: false,
    uplacer: false,
    onSupplierpart: null,
    onOrderpart: null,
    onBuddypart: null,
    requiredAmount: 1,
}

const mapStateToProps = (state) => {
    return {

    }
}

export default connect(mapStateToProps)(BomOrderparts);