import fetch from 'isomorphic-fetch'
import SockJS from "sockjs-client";
import {Stomp} from "@stomp/stompjs";
//import ReactGA from 'react-ga';
import {emptyPoints} from "../reducers/project";
import { v4 as uuidv4 } from 'uuid';

export function encodeUrlParameters(obj) {
    var str = "";
    for (var key in obj) {
        if( obj[key] ) {
            if (str !== "") {
                str += "&";
            } else {
                str += "?";
            }
            if (typeof(variable) === typeof(true)) {
                str += key + "=" + (obj[key] ? 'true' : 'false');
            } else {
                str += key + "=" + encodeURIComponent(obj[key]);
            }
        }
    }
    return str;
}

export function fetchFormData(url, content) {

    let form = new FormData();
    //form.append('a', 1);
    Object.keys(content).forEach(c => {
        form.append(c, content[c]);
    })

    let config = {
        method: 'POST',
        credentials: 'same-origin',
        headers: new Headers({
            'Accept-Language': 'nl'
        }),
        body: form

    }

    return fetch(url, config)
}

export function fetchData(url, content, params) {

    //url= '/api' + url;

    var config

    if( content != null ) {

        let body= JSON.stringify(
            content
        );

        config = {
            method: 'POST',
            credentials: 'same-origin',
            params: params,
            headers: new Headers({
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }),
            body: body
        }
    } else {
        config = {
            method: 'GET',
            credentials: 'same-origin',
            params: params,
            headers: new Headers({
                'Accept': 'application/json',
            })
        }
    }

    return fetch(url, config)
}

function handleErrors(response) {
    if (!response.ok) throw Error(response.statusText);
    return response;
}

function async(type, startArgs, url, post, args, asForm, onSuccess) {

    return function (dispatch) {
        dispatch( start(type, startArgs) );

        if( asForm ) {
            return fetchFormData( url + (args ? encodeUrlParameters(args) : ''), post, asForm )
                .then(response => handleErrors(response).ok)
                .then(response => {
                    dispatch(receive(type, response.ok, startArgs))
                    if( onSuccess && onSuccess instanceof Function ) {
                        onSuccess(response)
                    }
                })
                .catch(error => dispatch(handleError(type, startArgs, error)))
        }
        return fetchData( url + (args ? encodeUrlParameters(args) : ''), post, asForm )
            .then(response => handleErrors(response).json())
            .then(json => {
                dispatch(receive(type, json, startArgs))
                if( onSuccess ) {
                    onSuccess(json)
                }
            })
            .catch(error => dispatch(handleError(type, startArgs, error)))
    }
}

function start(type, start) {
    return {
        type: 'START_' + type, start
    }
}


function receive(type, value, start) {
    return {
        type: 'RECEIVE_' + type,
        value, start
    }
}

function handleError(type, startArgs, error) {
    return {
        type: 'ERROR_' + type,
        error,
        start: startArgs
    }
}

export function getLanguageBundle() {

    let url= '/i18n/bundle.json';

    if (process.env.NODE_ENV !== "production") {
        url= '/api/i18n/bundle'
    }

    return async('I18N', {}, url, null, {})
}

export function validate() {
    return async('VALIDATE', {}, '/api/user/validate', null, {})
}

export function login(username, password, onSuccess) {
    return async('LOGIN', {}, '/login', {username, password, submit: 'Submit', 'remember-me': true}, {}, true, onSuccess)
}

export function logout(onSuccess) {
    return async('LOGOUT', {}, '/logout', {}, {}, true, onSuccess)
}

export function validateLogin(onSuccess) {
    return async('VALIDATE_LOGIN', {}, '/api/user/validate', null, {}, null, (value) => {
        //ReactGA.set({ userId: value.email });
        if( onSuccess ) {
            onSuccess()
        }
    })
}

export function activeUsers() {
    return async('ACTIVE_USERS', {}, '/api/user/active', null, {})
}

export function setUserLanguage(lang) {
    return async('SET_USER_LANG', {}, '/api/user/setLang', null, {lang: lang})
}

export function checkEmail(value) {
    return async('CHECK_EMAIL', {}, '/api/user/check', null, {value})
}

export function changePasswordNew(id, token) {
    return async('CHANGE_PASSWORD', {}, '/api/user/changePasswordNew', null, {id, token})
}

export function savePassword(newPassword, token, onSuccess) {
    return async('SAVE_PASSWORD', {}, '/api/user/savePassword', {newPassword, token}, {}, null, onSuccess)
}

export function updatePassword(oldPassword, newPassword) {
    return async('SET_PASSWORD', {}, '/api/user/updatePassword', {oldPassword, newPassword}, {})
}

export function signup(value, onSuccess) {
    return async('SIGNUP', {}, '/api/user/register', value, {}, true, () => {
        if( onSuccess ) {
            onSuccess();
        }
        //ReactGA.event({category: 'User', action: 'Signup'});
    })
}

export function getDropboxStatus() {
    return async('GET_DROPBOX_API_STATUS', {}, '/api/dropbox/test', null, {})
}
export function getDropboxAuthUrl(onSuccess) {
    return async('GET_DROPBOX_AUTH_URL', {}, '/api/dropbox/getAuthUrl', null, {}, null, onSuccess)
}
export function setDropboxAuthResponse(code, onSuccess) {
    return async('SET_DROPBOX_AUTH_RES', {}, '/api/dropbox/authResponse', null, {code}, null, onSuccess)
}
export function dropboxUpdateCompany(createIfNotExists, onSuccess) {
    return async('DROPBOX_UPDATE_COMPANY', {}, '/api/dropbox/updateCompany', null, {createIfNotExists}, null, onSuccess)
}

export function getDropboxProjectFolders(update) {
    return async('GET_DROPBOX_PROJECT_FOLDERS', {}, '/api/dropbox/getProjectFolders', null, {update})
}

export function getKvkApiStatus(query) {
    return async('GET_KVK_API_STATUS', {}, '/api/kvk/getKvkApiStatus', null, {query})
}

export function updateKvkKey(key) {
    return async('UPDATE_KVK_KEY', {}, '/api/kvk/updateApiKey', null, {key})
}

export function zoekKvkNummer(query) {
    return async('GET_KVK_BY_NUMBER', {}, '/api/kvk/zoekKvkNummer', null, {query})
}

export function zoekKvkHandelsnaam(query, onSuccess) {
    return async('GET_KVK_BY_NAME', {}, '/api/kvk/zoekKvkHandelsnaam', null, {query}, null, onSuccess)
}

export function fetchPcbPrice(orderId) {
    return async('FETCH_PCB_PRICE', {orderId}, '/api/calculator/calculatePcbPrice', null, {orderId}, false)
}

export function fetchPrice(value, orderId, onSuccess) {

    console.log(isNaN(value.estComponentCost) ? 'estComponentCost NaN ' : 'estComponentCost OK ' + value.estComponentCost)

    value = Object.assign({}, value, {
        amounts: [value.numPcbs - 1, value.numPcbs, value.numPcbs + 1],
        estComponentCost: value.estComponentCost ? value.estComponentCost : 0,
    })

    if( typeof value.estComponentCost === 'string' || value.estComponentCost instanceof String ) {
        value = Object.assign({}, value, {
            estComponentCost: parseFloat(value.estComponentCost.replace(',', '.'))
        })
    }



    return async('FETCH_PRICE', {value, orderId}, '/api/calculator/calculate', value, {orderId}, false, (e) => {
        if( onSuccess ) {
            onSuccess(e);
        }
        //ReactGA.event({category: 'Calculator', action: 'Fetch price', value: value ? value.numPcbs : ''});
    })
}


export function fetchPriceByOrderId(orderId, numPcbs, selectedDays, onSuccess) {
    return async('FETCH_PRICE_BY_ORDER_ID', {orderId, numPcbs, selectedDays}, '/api/calculator/calculate', null, {orderId, numPcbs, selectedDays}, null, onSuccess)
}

export function calculateExpectedDeliveryDate(selectedDays, onSuccess) {
    return async('FETCH_EXPECTED_DELIVERY_DATE', {}, '/api/calculator/calculateExpectedDeliveryDate', null, {selectedDays}, false, onSuccess)
}

export function confirmRegistration(token, onSuccess) {
    return async('CONFIRM_REGISTRATION', {}, '/api/user/confirm', null, {token}, false, onSuccess)
}

export function requestNewConfirm(token) {
    return async('REQUEST_NEW_CONFIRM_REGISTRATION', {}, '/api/user/resendconfirm', null, {token}, false)
}

export const openLoginSignup = (value, index) => {
    return {
        type: 'OPEN_LOGIN_SIGNUP',
        value, index
    }
}

export const setLangauge = (value) => {
    return {
        type: 'SET_LANG',
        value
    }
}

export const setImages = (value) => {
    return {
        type: 'SET_IMAGES',
        value
    }
}

export const addImage = (value) => {
    return {
        type: 'ADD_IMAGE',
        value
    }
}

export const setImageChecked = (value, index) => {
    return {
        type: 'SET_IMAGE_CHECKED',
        value, index
    }
}

export const setImportColType = (column, value) => {
    return {
        type: 'IMPORT_SET_COL_TYPE',
        column, value
    }
}

export const setImportHeaderRow = (row) => {
    return {
        type: 'IMPORT_SET_HEADER_ROW',
        row
    }
}

export const setImportExcelData = (table, sheetName) => {
    return {
        type: 'IMPORT_SET_EXCEL_DATA',
        sheetName, table
    }
}

export const setImportSheet = (sheetName) => {
    return {
        type: 'IMPORT_SELECT_SHEET',
        sheetName
    }
}

export const cancelImport = () => {
    return {
        type: 'IMPORT_CANCEL'
    }
}

export function updateProjectStatus(id, status, onSuccess) {
    return async('UPDATE_PROJECT_STATUS', {id}, '/api/project/updateProjectStatus', {id, ...status}, null, null, onSuccess)
}

export function fetchProject(id, onSuccess) {
    return async('GET_PROJECT', {id}, '/api/project/getById', null, {id}, null, onSuccess)
}

export const toggleAggregateUpload = () => {
    return {
        type: 'TOGGLE_AGGREGATE_UPLOAD'
    }
}

export const importSetDecimalSeparator = (value) => {
    return {
        type: 'SET_DECIMAL_SEPARATOR',
        value
    }
}

export const importSetUnitOfMeasurement = (value) => {
    return {
        type: 'SET_UNIT_OF_MEASUREMENT',
        value
    }
}

export function fetchProjectStats(id, onSuccess) {
    return async('GET_PROJECT_STATS', {id}, '/api/project/getStatsById', null, {id}, null, onSuccess)
}

export function saveProject(value, args, onSuccess) {
    return async('SAVE_PROJECT', {}, '/api/project/save', value, args, null, onSuccess)
}

export function getUser(onSuccess) {
    return async('GET_USER', {}, '/api/project/getUser', null, null, null, onSuccess)
}

export function changeUserValue(id, name, value) {
    return {
        type: 'CHANGE_USER_VALUE',
        id, name, value
    }
}

export function saveUser(value, updateProjectOwner, onSuccess) {
    return async('SAVE_USER', {updateProjectOwner}, '/api/user/saveUser', value, null, null, onSuccess)
}

export const setInvoiceId = (id) => {
    return {
        type: 'SET_SELECTED_INVOICE_ID',
        id
    }
}

export const setDeliveryId = (id) => {
    return {
        type: 'SET_SELECTED_DELIVERY_ID',
        id
    }
}

export function fetchUsers() {
    return async('GET_USERS', {}, '/api/project/getUsers', null, null)
}

export function fetchUserRoles() {
    return async('GET_USER_ROLES', {}, '/api/project/getUserRoles', null, null)
}

export function deleteUser(id, onSuccess) {
    return async('DELETE_USER', {}, '/api/project/deleteUser', null, {id}, null, onSuccess)
}

export function changeOwner(projectId, userId, onSuccess) {
    return async('CHANGE_PROJECT_OWNER', {}, '/api/project/changeOwner', null, {projectId, userId}, null, onSuccess)
}

export function getProjectList(all, direction, sort, searchValue, pageNumber, paymentStatusFilter, hasOrderDate, series, foto, shippedNoInvoice) {

    let id = uuidv4();

    return async('GET_PROJECTS', {
        id, all, direction, sort, searchValue, pageNumber, paymentStatusFilter, hasOrderDate, series, foto, shippedNoInvoice
    }, '/api/project/getProjectList', null, {paymentStatusFilter, all: all ? 'true' : 'false', direction, sort, searchValue: searchValue ? searchValue : '', pageNumber, hasOrderDate: hasOrderDate !== null ? hasOrderDate ? 'true' : 'false' : '', series, foto, shippedNoInvoice})
}

export function toggleProjectShow(id, filterValue) {
    return async('TOGGLE_PROJECT_SHOW', {id, filterValue}, '/api/project/setProjectListFilter', null, {id, filterValue: filterValue ? 'true' : 'false'})
}

export function setProjectPaymentStatus(id, value) {
    return async('TOGGLE_PROJECT_PAYMENT_STATUS', {id, value}, '/api/project/setProjectPaymentStatus', null, {id, value})
}

export function setProjectParkedStatus(id, park, onSuccess) {
    return async('TOGGLE_PROJECT_PARKED_STATUS', {id, park}, '/api/project/setProjectParkedStatus', null, {id, park}, null, onSuccess)
}

export function getProjectFilesFromZip(id, fileId) {
    return async('GET_FILES_IN_ZIP', {}, '/api/project/getProjectFilesFromZip', null, {id, fileId})
}

export function getOrderPartList() {
    return async('GET_ORDERPART_LIST', {}, '/api/bom/orderPartList', null, {}, null)
}

export function updateOrderPart(id) {
    return async('UPDATE_ORDERPART', {}, '/api/bom/updateOrderPart', null, {id}, null)
}

export function saveOrderpart(value) {
    return async('SAVE_ORDERPART', {}, '/api/bom/saveOrderPart', value, {}, null)
}

export function changeOrderPartValue(id, name, value) {
    return {
        type: 'CHANGE_ORDERPART_VALUE',
        id, name, value
    }
}

export function revertOrderPartValue(id) {
    return {
        type: 'REVERT_ORDERPART_VALUE',
        id
    }
}

export function changeImportPartValue(id, name, value) {
    return {
        type: 'CHANGE_IMPORTPART_VALUE',
        id, name, value
    }
}

export function revertImportPartValue(id) {
    return {
        type: 'REVERT_IMPORTPART_VALUE',
        id
    }
}

export function addComponentData(mpn, onSuccess) {
    return async('ADD_COMPONENT_DATA', {}, '/api/bom/addComponentData', null, {mpn}, null, onSuccess)
}

export function getComponentDataList() {
    return async('GET_COMPONENT_DATA', {}, '/api/bom/componentDataList', null, null)
}

export function saveComponentData(value) {
    return async('SAVE_COMPONENT_DATA', {}, '/api/bom/saveComponentData', value, null)
}

export function parseComponentMpn(mpn, id) {
    return async('PARSE_COMPONENT_MPN', {id}, '/api/bom/parseComponentMpn', null, {mpn})
}

export function bomSetSearchQuery(query) {
    return {
        type: 'BOM_SET_SEARCH_QUERY',
        query
    }
}

const bomViewColumns = {
    'production': [2, 27, 29],
    'valideren': [0, 8, 31, 30, 9, 32],
    'centroid': [0,2,3,4,5,6,7],
    'bom': [0,1,30,8,9,17,23,24,25,26,34]
}

export function setBomView(deprecated, viewId) {
    const columns = bomViewColumns[viewId]
    return {
        type: 'BOM_SET_VIEW',
        selectedColumns: columns ? columns : bomViewColumns.bom,
        viewId
    }
}

export function resetMohicanSettingsDefault() {
    return {
        type: 'BOM_RESET_MOHICAN_SETTING'
    }
}

export function updateMohicanSetting(name, value) {
    return {
        type: 'BOM_UPDATE_MOHICAN_SETTING',
        name,
        value
    }
}

export function enableMohicanFeedbackControl(value) {
    return {
        type: 'MOHICAN_ENABLE_FEEDBACK_CONTROL',
        value
    }
}

export function setCameraDevices(devices) {
    return {
        type: 'SET_CAMERA_DEVICES',
        value: devices
    }
}

export function updateMohicanSettings(value) {
    return {
        type: 'BOM_UPDATE_MOHICAN_SETTINGS',
        value
    }
}

export function searchPart(query, usebuddy, designators, useCompanyStock, printId, onSuccess) {
    return async('BOM_SEARCH_PART', {}, '/api/bom/searchPart', null, {query, usebuddy, designators, useCompanyStock, printId}, null, onSuccess)
}

export function searchPartByMpn(mpn, onSuccess) {
    return async('BOM_SEARCH_BY_MPN', {}, '/api/bom/searchDatabase', null, {mpn}, null, onSuccess)
}

export function getSuppliers(onSuccess) {
    return async('BOM_SUPPLIERS', {}, '/api/bom/suppliers', null, {}, null, onSuccess)
}

export function getManufacturers(onSuccess) {
    return async('BOM_MANUFACTURERS', {}, '/api/bom/manufacturers', null, {}, null, onSuccess)
}

export function getImportStatus() {
    return async('BOM_IMPORT_STATUS', {}, '/api/bom/getImportStatus', null, {}, null)
}

export function importFromBomId(bomId, persist, all) {
    return async('BOM_IMPORT_FROM_BOM_ID', {}, '/api/bom/getPartsFromBomId', null, {bomId, persist: persist ? 'true' : 'false', all: all ? 'true' : false}, null)
}

export function clearImportFromMpn() {
    return {
        type: 'CLEAR_UPDATE_ORDERPART'
    }
}

export function openOrderPartAdd(open, o) {
    return {
        type: 'OPEN_ORDERPART_ADD',
        open: open,
        value: o
    }
}
export function setOrderPartAdd(o) {
    return {
        type: 'SET_ORDERPART_ADD',
        value: o
    }
}

export function importFromMpn(mpn, persist, onSuccess) {
    return async('BOM_IMPORT_FROM_MPN', {}, '/api/bom/getPartsFromMpn', null, {mpn, persist: persist ? 'true' : 'false'}, null, onSuccess)
}

export function addMpn(op) {
    return async('BOM_ADD_MPN', {}, '/api/bom/addMpn', op, {}, null)
}

export function addSku(sp, orderpartId) {
    return async('BOM_ADD_SUPPLIER', {}, '/api/bom/addSku', sp, {orderpartId}, null)
}

export function searchDatabaseTest(args) {
    return async('BOM_SEARCH_TEST', {}, '/api/bom/searchDatabaseTest', null, args, null)
}

export function searchProjectUsedPart(mpn, sku) {
    return async('BOM_SEARCH_PROJECT_USED_PART', {}, '/api/bom/searchProjectUsedPart', null, {mpn, sku}, null)
}

export function searchProjectUsedPartBySpec(resistor, value, packageOutline) {
    return async('BOM_SEARCH_PROJECT_USED_PART', {}, '/api/bom/searchProjectUsedPartBySpec', null, {resistor, value, packageOutline}, null)
}

export function searchDatabaseTestMpn(args) {
    return async('BOM_SEARCH_TEST', {}, '/api/bom/searchDatabaseTestMpn', null, args, null)
}

export function searchDatabaseTestSelect(args) {
    return async('BOM_SEARCH_TEST_SELECT', {}, '/api/bom/searchDatabaseTestSelect', null, args, null)
}

export function testParseBomValue(designators, value, onSuccess) {
    return async('BOM_TEST_PARSE_VALUE', {}, '/api/bom/testParseBomValue', null, {designators, value}, null, onSuccess)
}

export function updateStockAndPrice(id) {
    return async('BOM_UPDATE_STOCK', {ids: id}, '/api/bom/updateStockAndPrice', null, {id})
}

export function fetchAllOrderparts(page) {
    return async('BOM_ORDERPART_LIST', {}, '/api/bom/orderPartList', null, {page})
}

export function getBomList(uplacer = false) {
    return async('BOM_LIST', {}, '/api/bom/list', null, {uplacer: uplacer})
}

export function deleteBom(bomId, uplacer, onSuccess) {
    return async('DELETE_BOM', {}, '/api/bom/delete', null, {id: bomId, uplacer}, null, onSuccess)
}

export function openBom(bomId, projectId, viewId, onSuccess) {
    return async('OPEN_BOM', {viewId}, '/api/bom/getById', null, {id: bomId, projectId}, null, onSuccess)
}

export function clearBomData() {
    return {
        type: 'CLEAR_BOM'
    }
}

export function openBomByProjectOrderId(id, onSuccess) {
    return async('OPEN_BOM', {}, '/api/bom/getById', null, {id: id}, null, onSuccess)
}

export function saveBom(id, print, onSuccess) {
    return async('SAVE_BOM_PRINT', {}, '/api/bom/save', print, {id}, null, onSuccess)
}

export function importBom(table, sheetName, headerRow, colType) {
    return async('IMPORT_BOM', {}, '/api/bom/importBOM', {fileData: table[sheetName], headers: colType, headerRow }, null)
}

export function importBOM2(grid, projectOrderId, aggregate, uplacer, onSuccess) {
    return async('IMPORT_BOM_V2', {}, '/api/bom/importBOM/v2', grid, {projectOrderId, aggregate, uplacer}, null, onSuccess)
}

export function importNewBOM2(grid, printId, aggregate, uplacer, onSuccess) {
    return async('IMPORT_BOM_V2', {}, '/api/bom/importNewBOM/v2', grid, {printId, aggregate, uplacer}, null, onSuccess)
}

export function getCentroidByPrint(printId, onSuccess) {
    return async('GET_CENTROID_BY_PRINT', {}, '/api/bom/getCentroidByPrint', null, {id: printId}, null, onSuccess)
}

export function loadCentroid(printId, centroidId, onSuccess) {
    return async('LOAD_CENTROID', {}, '/api/bom/loadCentroid', null, {printId, centroidId}, null, onSuccess)
}

export function importCentroid2(data, id, decimalSeparator, unitOfMeasurement, onSuccess) {
    return async('IMPORT_CENTROID_V2', {}, '/api/bom/importCentroid2', data, {id, decimalSeparator, unitOfMeasurement,}, null, onSuccess)
}

export function getImportHeaders() {
    return async('IMPORT_HEADERS', {}, '/api/bom/importHeaders', null, {})
}

export function getImportHeadersBom() {
    return async('IMPORT_HEADERS_BOM', {}, '/api/bom/importHeadersBom', null, {})
}

export function getImportHeadersCentroid() {
    return async('IMPORT_HEADERS_CENTROID', {}, '/api/bom/importHeadersCentroid', null, {})
}

export function removeCentroid(bomId, onSuccess) {
    return async('REMOVE_CENTROID', {}, '/api/bom/removeCentroid', null, {id: bomId}, null, onSuccess)
}

export function convertCentroid(bomId, toMM, onSuccess) {
    return async('CONVERT_CENTROID', {}, '/api/bom/convertCentroid', null, {id: bomId, toMM}, null, onSuccess)
}

export function checkBomLine(printId, bomLineId) {
    return async('CHECK_BOM_LINE', {printId}, '/api/bom/processTodo', null, {id: printId, bomLineId: bomLineId})
}

export function checkBom(printId) {
    return async('CHECK_BOM', {printId}, '/api/bom/processAllTodos', null, {id: printId})
}

export function setDoNotPlace(id, place) {
    return async('BOM_SET_DO_NOT_PLACE', {id, place}, '/api/bom/setDoNotPlace2', null, {id, place})
}

export function setDesignatorRemoved(id, removed) {
    return async('BOM_SET_DESIGNATOR_REMOVED', {id, removed}, '/api/bom/setDesignatorRemoved', null, {id, removed})
}

export function addNewBomLine(id, bomLineId, row, designators, description, value, pack, manufacturer, mpn, supplier, sku, cpn, onSuccess) {
    return async('BOM_ADD_BOM_LINE', {id}, '/api/bom/addNewBomLine', null, {id, bomLineId, row, designators, description, value, package: pack, manufacturer, mpn, supplier, sku, cpn}, null, onSuccess)
}

export function removeAllBomLineInfo(bomLineId, onSuccess) {
    return async('BOM_REMOVE_ALL_BOM_LINE_INFO', {bomLineId}, '/api/bom/removeAllBomLineInfo', null, {bomLineId}, null, onSuccess)
}

export function updateBomLineInfo(bomLine, description, value, packge, onSuccess) {
    return async('BOM_UPDATE_BOM_LINE_INFO', {}, '/api/bom/removeAllBomLineInfo', bomLine, {description, value, package: packge}, null, onSuccess)
}

export function addDesignator(designator, bomLineId, onSuccess) {
    return async('BOM_ADD_DESIGNATOR', {designator, bomLineId}, '/api/bom/addDesignator', null, {designator, bomLineId}, null, onSuccess)
}

export function setBomFilter(filter) {
    return {
        type: 'SET_BOM_FILTER',
        filter
    }
}

export function setBomSort(value) {
    return {
        type: 'SET_BOM_SORT',
        value
    }
}

export function setBomSearch(value) {
    return {
        type: 'SET_BOM_SEARCH',
        value
    }
}

export function setBomSearchRef(value) {
    return {
        type: 'SET_BOM_SEARCH_REF',
        value
    }
}

export function setBomRotation(value) {
    return {
        type: 'SET_BOM_ROTATION',
        value
    }
}

export function autoSelectBomLine(printId, bomLineId, autoSelectSettingsId, onlyFromExistingStock) {
    return async('AUTO_SELECT_BOM_LINE', {id: bomLineId}, '/api/bom/autoSelect', null, {id: printId, bomLineId, autoSelectSettingsId, onlyFromExistingStock})
}

export function autoSelectBom(printId, autoSelectSettingsId, onlyFromExistingStock) {
    return async('AUTO_SELECT_BOM', {printId}, '/api/bom/autoSelectAll', null, {id: printId, autoSelectSettingsId, onlyFromExistingStock})
}

export function autoUpdateStockAndPrice(printId, autoSelectSettingsId) {
    return async('AUTO_UPDATE_STOCK', {printId}, '/api/bom/autoUpdateStockAndPrice', null, {id: printId, autoSelectSettingsId})
}

export function saveAutoSelectSettings(bomAutoSelectSettings, onSuccess) {
    return async('UPDATE_BOM_AUTO_SELECT_SETTINGS', {}, '/api/bom/saveAutoSelectSettings', bomAutoSelectSettings, {}, null, onSuccess)
}

export function getDefaultAutoselectSettings(onSuccess) {
    return async('GET_DEFAULT_BOM_AUTO_SELECT_SETTINGS', {}, '/api/bom/getDefaultAutoselectSettings', null, {}, null, onSuccess)
}

export function updateDefaultAutoselectSettings(bomAutoSelectSettings, onSuccess) {
    return async('UPDATE_DEFAULT_BOM_AUTO_SELECT_SETTINGS', {}, '/api/bom/updateDefaultAutoselectSettings', bomAutoSelectSettings, {}, null, onSuccess)
}

export function changeAutoSelectSettings(name, value, id) {
    return {
        type: 'CHANGE_BOM_AUTO_SELECT_SETTINGS',
        name, value, id
    }
}

export function changeDefaultAutoSelectSettings(name, value, id) {
    return {
        type: 'CHANGE_BOM_DEFAULT_AUTO_SELECT_SETTINGS',
        name, value, id
    }
}

export function revertAutoSelectSettings() {
    return {
        type: 'REVERT_BOM_AUTO_SELECT_SETTINGS'
    }
}

export function revertDefaultAutoSelectSettings() {
    return {
        type: 'REVERT_BOM_DEFAULT_AUTO_SELECT_SETTINGS'
    }
}

export function setKlantLevertAan(printId, bomLineId, manualselection) {
    return async('SELECT_BOM_LINE_KLA', {id: bomLineId}, '/api/bom/setKlantLevertAan', null, {id: printId, bomLineId, manualselection})
}

export function setRemark(printId, bomLineId, remark, onSuccess) {
    return async('SET_BOM_LINE_REMARK', {id: bomLineId, remark}, '/api/bom/setRemark', null, {id: printId, bomLineId, remark}, null, onSuccess)
}

export function setMarked(printId, bomLineId, marked, onSuccess) {
    return async('SET_BOM_LINE_MARKED', {id: bomLineId, marked}, '/api/bom/setMarked', null, {id: printId, bomLineId, marked}, null, onSuccess)
}

export function customerConfirmCheckedOpen(open) {
   return {
         type: 'OPEN_BOM_CONFIRM_CHECKED',
         open
   }
}

export function customerConfirmChecked(printId, message, onSuccess) {
    return async('SEND_BOM_CONFIRM_CHECKED', {printId}, '/api/bom/customerConfirmChecked', null, {printId, message}, null, onSuccess)
}

export function setValueForBomLine(printId, bomLineId, value, outline, desciption, customerPartNumber, onSuccess) {
    return async('SET_VALUE_FOR_BOM_LINE', {id: bomLineId}, '/api/bom/setValueForBomLine', null, {id: printId, bomLineId, value, outline, desciption, customerPartNumber}, null, onSuccess)
}

export function setSelectedSupplierPart(bomLineId, supplierPartId, query, onSuccess) {
    return async('SET_BOM_LINE_PART', {}, '/api/bom/setSelectedSupplierPart', null, {bomLineId, supplierPartId, query}, null, onSuccess)
}

export function setSelectedBuddyPart(bomLineId, buddyPartId, query, onSuccess) {
    return async('SET_BOM_LINE_BUDDY', {}, '/api/bom/setSelectedBuddyPart', null, {bomLineId, buddyPartId, query}, null, onSuccess)
}

export function reSelectedSupplierPart(bomLineId, id, onSuccess) {
    return async('RESELECT_BOM_LINE_HISTORY', {bomLineId}, '/api/bom/reSelectedSupplierPart', null, {bomLineId, id}, null, onSuccess)
}

export function getSelectedSupplierPartHistory(bomLineId, onSuccess) {
    return async('GET_BOM_LINE_HISTORY', {bomLineId}, '/api/bom/getSelectedSupplierPartHistory', null, {bomLineId}, null, onSuccess)
}

export function showBomLineHistory(bomLineId, show) {
    return {
        type: 'SHOW_BOM_LINE_HISTORY',
        bomLineId, show
    }
}
export function unsetSelectedSupplierPart(bomLineId, query, onSuccess) {
    return async('UNSET_BOM_LINE_PART', {}, '/api/bom/unsetSelectedSupplierPart', null, {bomLineId, query}, null, onSuccess)
}

export function setBomLineExtraAmount(bomLineId, add, onSuccess) {
    return async('SET_BOM_LINE_EXTRA_AMOUNT', {bomLineId}, '/api/bom/setExtraAmount', null, {bomLineId, add}, null, onSuccess)
}

export function getBomOrders(printId, onSuccess) {
    return async('GET_BOM_ORDERS', {}, '/api/bom/getBomOrders', null, {id: printId}, null, onSuccess)
}

export function getAllBomOrders() {
    return async('GET_ALL_BOM_ORDERS', {}, '/api/bom/getAllBomOrders', null, {})
}

export function getAllBomOrdersNeedCheckin() {
    return async('GET_ALL_BOM_ORDERS_NEED_CHECKIN', {}, '/api/bom/getAllBomOrdersNeedCheckin', null, {})
}

export function deleteBomOrders(printId, bomOrderId, onSuccess) {
    return async('REMOVE_BOM_ORDER', {id: bomOrderId}, '/api/bom/removeBomOrder', null, {id: printId, bomOrderId}, null, onSuccess)
}

export function createBomOrders(printId) {
    return async('CREATE_BOM_ORDERS', {}, '/api/bom/createBomOrders', null, {id: printId})
}

export function getBomStock(printId) {
    return async('GET_BOM_STOCK', {}, '/api/bom/getBomStock', null, {id: printId})
}

export function getAllMyStock() {
    return async('GET_MY_BOM_STOCK', {}, '/api/bom/getAllMyStock', null, {})
}

export function addBomStockItem(bstId, id, item) {
    return {
        type: 'ADD_BOM_STOCK_ITEM',
        bstId, id, item
    }
}

export function changeBomStockItemValue(bstId, id, name, value) {
    return {
        type: 'CHANGE_BOM_STOCK_ITEM_VALUE',
        bstId, id, name, value
    }
}

export function saveBomStockItem(item, stockId, onSuccess) {
    return async('SAVE_BOM_STOCK_ITEM', {bstId: stockId, id: item.id}, '/api/bom/saveBomStockItem', item, {stockId}, null, onSuccess)
}

export function saveBomStockTransaction(bstId, transaction, stockItemId, onSuccess) {
    return async('SAVE_BOM_STOCK_ITEM_TRANSACTION', {bstId, stockItemId}, '/api/bom/saveBomStockTransaction', transaction, {stockItemId}, null, onSuccess)
}

export function getOrderpart(id) {
    return async('GET_ORDERPART', {id}, '/api/bom/getOrderpart', null, {id})
}

export function setOrderpartField(id, field, value) {
    return async('SET_ORDERPART_FIELD', {id}, '/api/bom/setOrderpartField', null, {id, field, value})
}

export function getOrderpartInfo(id) {
    return async('GET_ORDERPART_PROJECT_INFO', {id}, '/api/bom/getOrderpartInfo', null, {id})
}

export function showOrderpartInfo(id) {
    return {
        type: 'SHOW_GET_ORDERPART_INFO',
        id
    }
}

export function closeOrderpartInfo() {
    return {
        type: 'CLOSE_GET_ORDERPART_PROJECT_INFO'
    }
}

export function bomShowImportError(errorList) {
    return {
        type: 'BOM_SHOW_IMPORT_ERROR',
        errorList
    }
}

export function setBomOrderValue(id, name, value) {
    return {
        type: 'SET_BOM_ORDER_VALUE',
        id, name, value
    }
}

export function addBomOrder(printId) {
    return async('ADD_BOM_ORDER', {}, '/api/bom/addBomOrder', null, {id: printId})
}

export function saveBomOrder({created, id, orderPlaced, status, supplier, supplierOrderCode, amount, stock, deliverytime}, mailCustomer, comment, onSuccess) {
    return async('SAVE_BOM_ORDER', {}, '/api/bom/saveBomOrder', {created, id, orderPlaced, status, supplier, supplierOrderCode, amount, stock, deliverytime}, {mailCustomer}, null, onSuccess)
}

export function saveBomOrderItem(value, bomOrderId, onSuccess) {
    return async('SAVE_BOM_ORDER_ITEM', {id: value.id}, '/api/bom/saveBomOrderItem', value, {bomOrderId}, null, onSuccess)
}

export function expireBomOrderItem(id, onSuccess) {
    return async('EXPIRE_BOM_ORDER_ITEM', {id}, '/api/bom/expireBomOrderItem', null, {id}, null, onSuccess)
}

export function saveProjectBestelling({created, id, orderPlaced, status, supplier, supplierOrderNumber}) {
    return async('SAVE_BOM_ORDER', {}, '/api/bom/saveProjectBestelling', {created, id, orderPlaced, status, supplier, supplierOrderNumber}, null)
}

export function fetchProjectEvents(projectId) {
    return async('GET_PROJECT_EVENTS', {}, '/api/project/packingSlipList', null, {projectId})
}

export function deleteProjectEvent(projectId, id) {
    return async('DELETE_PROJECT_EVENT', {}, '/api/project/deletePackingSlip', null, {projectId, id})
}

export function createPackingSlip(projectId, createPackingSlip, pcbType, onSuccess) {
    return async('CREATE_PROJECT_PACKING_SLIP', {}, '/api/project/createPackingSlip', createPackingSlip, {projectId, pcbType}, null, onSuccess)
}

export function bomCheckin(value, printId, orderpartId, klantLevertAanId, onSuccess) {
    return async('BOM_CHECKIN', {id: value.id}, '/api/bom/checkin', value, {printId, orderpartId, klantLevertAanId}, null, onSuccess)
}

export function focusCheckin(name) {
    return {
        type: 'BOM_CHECKIN_FOCUS',
        name
    }
}

export function createInvoice(projectId, createPackingSlip, onSuccess) {
    return async('CREATE_PROJECT_INVOICE', {}, '/api/project/createInvoice', createPackingSlip, {projectId}, null, onSuccess)
}

export function selectDesignatorBomProduction(value, next) {
    return {
        type: 'BOM_PRODUCTION_SELECT_DESIGNATOR',
        value, next
    }
}

export function selectDesignatorBomProductionLastGoTo(value) {
    return {
        type: 'BOM_PRODUCTION_SELECT_DESIGNATOR_LAST_GO_TO',
        value
    }
}

export function getBomProduction(printId) {
    return async('GET_BOM_PRODUCTION', {}, '/api/bom/getProduction', null, {printId})
}

export function setBomProductionSelected(id) {
    return {
        type: 'BOM_PRODUCTION_SELECT',
        id
    }
}

export function setBomProductionSetPlankje(value) {
    return {
        type: 'BOM_PRODUCTION_SET_PLANKJE',
        value
    }
}

export function setSelectedBomRow(id, fade) {
    return {
        type: 'BOM_ROW_SELECT',
        id, fade
    }
}

export function openEditBomRow(id) {
    return {
        type: 'BOM_EDIT_ROW',
        id
    }
}

export function openBomCentroidView(open, searchValue) {
    return {
        type: 'BOM_CENTROID_VIEW',
        open, searchValue
    }
}

export function setBomCentroidViewSearch(value) {
    return {
        type: 'SET_BOM_CENTROID_VIEW_SEARCH',
        value
    }
}

export function addBomProduction(printId, pcbNumber, onSuccess) {
    return async('ADD_BOM_PRODUCTION', {}, '/api/bom/newProduction', null, {printId, pcbNumber}, null, onSuccess)
}

export function addBomProductionLine(productionId, designator, engineer) {
    return async('ADD_BOM_PRODUCTION_LINE', {productionId}, '/api/bom/saveProductionLine', null, {productionId, designator, engineer})
}

export function deleteAllProductionLines(productionId) {
    return async('DELETE_ALL_BOM_PRODUCTION_LINES', {productionId}, '/api/bom/deleteAllProductionLines', null, {productionId})
}

export function setBomDemoMode(value) {
    return {
        type: 'SET_BOM_DEMO_MODE',
        value
    }
}

export function deleteBomProductionLine(productionLineId) {
    return async('DELETE_BOM_PRODUCTION_LINE', {}, '/api/bom/deleteProductionLine', null, {productionLineId})
}

export function getLastUsedBoardLocations(id) {
    return async('GET_BOM_BOARD_LOCATIONS', {}, '/api/bom/getLastUsedBoardLocations', null, {id})
}

export function updateLastUsedBoardLocation(id, boardLocation, usedLocation) {
    return async('UPDATE_BOM_BOARD_LOCATION', {}, '/api/bom/updateLastUsedBoardLocation', null, {id, boardLocation, usedLocation})
}

export function testGetBuddyFile(id, top) {
    return async('GET_BUDDY_FILE', {}, '/api/bom/buddy/productionFile', null, {id, top: top ? 'true' : 'false'})
}

export function getBuddyParts() {
    return async('FETCH_BUDDY_PARTS', {}, '/api/bom/buddy/all', null, {})
}

export function getKnownDielectric() {
    return async('FETCH_KNOWN_DIELECTRIC', {}, '/api/bom/getKnownDielectric', null, {})
}

export function getKnownPackages() {
    return async('FETCH_KNOWN_PACKAGES', {}, '/api/bom/getKnownPackages', null, {})
}

export function saveKnownDielectric(value, onSuccess) {
    return async('SAVE_KNOWN_DIELECTRIC', {}, '/api/bom/saveKnownDielectric', value, {}, null, onSuccess)
}

export function saveKnownPackages(value, onSuccess) {
    return async('SAVE_KNOWN_PACKAGES', {}, '/api/bom/saveKnownPackages', value, {}, null, onSuccess)
}

export function changeKnownDielectric(id, name, value) {
    return {
        type: 'CHANGE_KNOWN_DIELECTRIC',
        id, name, value
    }
}

export function changeKnownPackage(id, name, value) {
    return {
        type: 'CHANGE_KNOWN_PACKAGE',
        id, name, value
    }
}

export function updateOrderpart(id, onSuccess) {
    return async('UPDATE_ORDER_PART', {}, '/api/bom/updateOrderpart', null, {id}, null, onSuccess)
}

export function updateOrderPartSpecification(id, spec, onSuccess) {
    return async('UPDATE_ORDER_PART_SPECIFICATION', {}, '/api/bom/updateOrderPartSpecification', null, {id, spec}, null, onSuccess)
}

export function changeBuddyPart(id, name, value) {
    return {
        type: 'CHANGE_BUDDY_PART',
        id, name, value
    }
}

export function addNewBuddyPart(defaultValues) {
    return {
        type: 'ADD_BUDDY_PART',
        defaultValues
    }
}

export function setPartForBuddyPart(id, partId) {
    return async('SAVE_PART_BUDDY_PART', {}, '/api/bom/buddy/setPart', null, {id, partId})
}

export function saveBuddyPart(value) {
    delete value.dirty;
    delete value.orderpart.buddyPart;
    return async('SAVE_BUDDY_PART', {}, '/api/bom/buddy/save', value, {})
}

export function openConfirmDeletePackingSlip(value ) {
    return {
        type: 'OPEN_DELETE_PACKING_SLIP_MODAL',
        value
    }
}

export function deletePackingSlip(id, projectId) {
    return async('DELETE_PROJECT_PACKING_SLIP', {}, '/api/project/deletePackingSlip', null, {projectId, id}, null)
}

export function mouseOverSelectedSupplierPart(id, bomLineId, supplierPartId) {
    return {
        type: 'MOUSE_OVER_SUPPLIER_PART',
        id, bomLineId, supplierPartId, mouseOver: true
    }
}

export function mouseLeaveSelectedSupplierPart(id, bomLineId, supplierPartId) {
    return {
        type: 'MOUSE_OVER_SUPPLIER_PART',
        id, bomLineId, supplierPartId, mouseOver: false
    }
}

export function receiveStomp(value) {
    return {
        ...value
    }
}

export function startUploadImportData() {
    return {
        type: 'START_UPLOAD_IMPORT_DATA'
    }
}

export function receiveUploadImportData(body) {
    return {
        type: 'RECEIVE_UPLOAD_IMPORT_DATA',
        body
    }
}

export function receiveUploadImportDataRecognisedFormat(body) {
    return {
        type: 'RECEIVE_UPLOAD_IMPORT_DATA_RECOGNISED_FORMAT',
        body
    }
}

export function importDataSelectSheet(sheetName) {
    return {
        type: 'IMPORT_DATA_SELECT_SHEET',
        sheetName
    }
}

export function closeImportData() {
    return {
        type: 'CLOSE_IMPORT_DATA'
    }
}

export function setImportValue(name, value) {
    return {
        type: 'SET_IMPORT_VALUE',
        name, value
    }
}

export function changeImportValue(sheetName, name, value) {
    return {
        type: 'CHANGE_IMPORT_VALUE',
        sheetName, name, value
    }
}

export function deleteImportRow(sheetName, i) {
    return {
        type: 'DELETE_IMPORT_ROW',
        sheetName, i
    }
}
export function changeImportCellValue(sheetName, i, j, name, value) {
    return {
        type: 'CHANGE_IMPORT_CELL_VALUE',
        sheetName, i, j, name, value
    }
}

export function revertImportCellValue(sheetName, i, j, name, value) {
    return {
        type: 'REVERT_IMPORT_CELL_VALUE',
        sheetName, i, j
    }
}

export function startReceivePrint() {
    return {
        type: 'START_OPEN_BOM'
    }
}

export function startUploadCentroid() {
    return {
        type: 'START_UPLOAD_CENTROID'
    }
}

export function receivePrint(value) {
    return {
        type: 'RECEIVE_OPEN_BOM',
        value
    }
}

export function fetchCalculatorSettings() {
    return async('CALCULATOR_SETTINGS', {}, '/api/calculator/filelist', null, null)
}

export function closeCalculatorSettings() {
    return {
        type: 'CLOSE_CALCULATOR_SETTINGS'
    }
}

export function fetchApplicationSettings() {
    return async('APPLICATION_SETTINGS', {}, '/api/calculator/getsettings', null, null)
}

export function saveApplicationSettings(value) {
    return async('SAVE_APPLICATION_SETTINGS', {saving: true}, '/api/calculator/savesettings', value, null)
}

export function getProjectCentroid(projectId) {
    return async('GET_PROJECT_CENTROID', {}, '/api/project/getCentroid', null, {projectId})
}

export function getProjectOrderList(projectId, onSuccess) {
    return async('GET_PROJECT_ORDERLIST', {}, '/api/project/getOrderList', null, {projectId}, onSuccess)
}

export function getProjectAttachments(projectOrderId) {
    return async('GET_PROJECT_ATTACHMENTS', {}, '/api/project/getProjectAttachments', null, {projectOrderId})
}

export function showUploadPcbsModal(value) {
    return {
        type: 'SHOW_UPLOAD_PCBS_MODAL',
        value
    }
}

export function wapperProjectOrderPcbInfo() {
    return {
        type: 'WAPPER_PROJECT_PCBS'
    }
}

export function wapperProjectOrderPcbMode(mode) {
    return {
        type: 'WAPPER_PROJECT_PCBS_MODE',
        mode
    }
}

export function wapperProjectOrderPcbReset(id) {
    return {
        type: 'WAPPER_PROJECT_PCBS_RESET',
        id
    }
}

export function wapperProjectOrderSetReference(id, value) {
    return {
        type: 'WAPPER_PROJECT_PCBS_SET_REFERENCE',
        id, value
    }
}

export function getProjectOrderPcbInfo(projectOrderId) {
    return async('GET_PROJECT_PCBS', {}, '/api/project/getProjectOrderPcbInfo', null, {projectOrderId})
}

export function saveProjectOrderPcbInfo(value, projectOrderId) {
    return async('SAVE_PROJECT_PCB', {}, '/api/project/saveProjectOrderPcbInfo', value, {projectOrderId})
}

export function deleteProjectOrderPcbInfo(projectOrderPcbInfoId) {
    return async('DELETE_PROJECT_PCB', {projectOrderPcbInfoId}, '/api/project/deleteProjectOrderPcbInfo', null, {projectOrderPcbInfoId})
}

export function saveProjectPcbPictireFid(saveFidDto, projectOrderPcbInfoId, back) {
    return async('SAVE_PROJECT_PCB_FID', {projectOrderPcbInfoId}, '/api/project/saveProjectPcbPictireFid', saveFidDto, {projectOrderPcbInfoId, front: !back, back: back})
}

export function resetProjectPcbPictireFid(projectOrderPcbInfoId, back) {
    return async('SAVE_PROJECT_PCB_FID', {projectOrderPcbInfoId}, '/api/project/saveProjectPcbPictireFid', emptyPoints, {projectOrderPcbInfoId, front: !back, back: back})
}

export function updateProjectPcbPictireFid(points, projectOrderPcbInfoId, back) {
    return {
        type: 'UPDATE_PROJECT_PCB_FID',
        points, projectOrderPcbInfoId, back, front: !back
    }
}

export function projectOrderPcbDownloadProgress(pcbId, progress) {
    return {
        type: 'WAPPER_PROJECT_PCB_DOWNLOAD_PROGRESS',
        id: pcbId,
        progress
    }
}

export function changeProjectOrderPcbInfo(id, name, value) {
    return {
        type: 'CHANGE_PROJECT_PCB', id, name, value
    }
}

export function toggleProjectOrderPcbTopBottom() {
    return {
        type: 'TOGGLE_PROJECT_PCB_TOP_BOTTOM'
    }
}

export function toggleProjectOrderPcbInfoMenu() {
    return {
        type: 'TOGGLE_PROJECT_PCB_MENU'
    }
}

export function receiveProjectOrderPcbInfo(value) {
    return {
        type: 'RECEIVE_SAVE_PROJECT_PCB', value
    }
}

export function receiveProjectOrderPcbInfoFileUploaded(value) {
    return {
        type: 'RECEIVE_SAVE_PROJECT_PCB_FILE_UPLOADED', value
    }
}

export function addProjectAttachment(value) {
    return {
        type: 'ADD_PROJECT_ATTACHMENT', value
    }
}

export function changeApplicationSettings(key, value) {
    return {
        type: 'CHANGE_APPLICATION_SETTINGS', key, value
    }
}

export function openBomOrder(open) {
    return {
        type: 'BOM_ORDER_OPEN',
        value: open
    }
}

export function openBomOrderMove(open) {
    return {
        type: 'BOM_ORDER_MOVE_OPEN',
        value: open
    }
}

export function selectBomOrderToMove(value) {
    return {
        type: 'SELECT_BOM_ORDER_TO_MOVE',
        value
    }
}

export function findProjectWithOrders(query, onSuccess) {
    let id = uuidv4();
    return async('BOM_ORDER_SEARCH', {id}, '/api/bom/findBomOrders', null, {query}, null, onSuccess)
}

export function moveBomOrders(from, to, onSuccess) {
    return async('BOM_ORDER_MOVE', {}, '/api/bom/moveBomOrders', null, {from, to}, null, onSuccess)
}

export function openCheckinOrders(open) {
    return {
        type: 'BOM_CHECKIN_ORDERS_OPEN',
        value: open
    }
}

export function changePrintValue(name, value) {
    return {
        type: 'BOM_CHANGE_PRINT_VALUE',
        name, value
    }
}

export function openEditBom(open) {
    return {
        type: 'BOM_EDIT_OPEN',
        value: open
    }
}

export function openBomSearch(id, query) {
    return {
        type: 'BOM_SEARCH',
        id, query
    }
}

export function closeImportErrorDialog() {
    return {
        type: 'BOM_CLOSE_IMPORT_ERROR'
    }
}
export function closeBomSearch() {
    return {
        type: 'BOM_SEARCH_CLOSE'
    }
}

export function openBomCamera(open) {
    return {
        type: 'BOM_OPEN_CAMERA',
        value: open
    }
}

export function openUploadBom(open) {
    return {
        type: 'BOM_UPLOAD_OPEN',
        open
    }
}

export function openBugReport(open) {
    return {
        type: 'BOM_BUGREPORT_OPEN',
        open
    }
}

export function bomdialogStartValidate(value) {
    return {
        type: 'BOM_DIALOG_START_VALIDATE',
        value
    }
}

export function openProjectEventView(open) {
    return {
        type: 'EVENT_VIEW_OPEN',
        value: open
    }
}

export function setStompClient(client) {
    return {
        type: 'SET_STOMP_CLIENT',
        client
    }
}

export function addStompClientSubscription(subscription) {
    return {
        type: 'ADD_STOMP_SUBSCRIPTION',
        subscription
    }
}

export function stompClientUnsubsribeAll() {
    return {
        type: 'STOMP_CLIENT_UNSUBSCRIBE_ALL'
    }
}

function showStompNotification() {
    return {
        type: 'SHOW_STOMP_NOTIFICATION'
    }
}

function hideStompNotification() {
    return {
        type: 'HIDE_STOMP_NOTIFICATION'
    }
}

export function openMohicanSelectionSettingsModal(open) {
    return {
        type: 'OPEN_MOHICAN_SELECTION_SETTINGS_MODAL',
        open
    }
}

export function openMohicanConnectionModal(open) {
    return {
        type: 'OPEN_MOHICAN_CONNECTION_MODAL',
        open
    }
}

export function openMohicanHomeModal(open) {
    return {
        type: 'OPEN_MOHICAN_HOME_MODAL',
        open
    }
}

export function openMohicanTrayModal(open) {
    return {
        type: 'OPEN_MOHICAN_TRAY_MODAL',
        open
    }
}

export function openMohicanPcbModal(open) {
    return {
        type: 'OPEN_MOHICAN_PCB_MODAL',
        open
    }
}

export function mohicanGetPosition() {
    return function (dispatch) {
        let type = 'MOHICAN_POS'
        let startArgs = null;
        let config = {
            method: 'GET',
            credentials: 'same-origin',
            headers: new Headers({
                'Accept': 'application/json',
            })
        }
        fetch(getMohicanUrl('mohicanpos'), config)
            .then(response => handleErrors(response).json())
            .then(json => {
                dispatch(receive(type, json, startArgs))
            })
            .catch(error => dispatch(handleError(type, startArgs, error)))
    }
}

export function connectMohican(onConnect, mohicanSettings) {

    return function (dispatch) {
        var socket = SockJS(getMohicanUrl('mohican'));
        var stompClient = Stomp.over(socket);
        stompClient.debug = function (){};//do nothing

        stompClient.heartbeatIncoming = 60000;
        stompClient.heartbeatOutgoing = 60000;

        stompClient.connect({}, () => {
            var subscription = stompClient.subscribe('/topic/mohican/', (value) => {
                dispatch(receiveStomp(JSON.parse(value.body)))

                dispatch(showStompNotification())
                setTimeout(() => {
                    dispatch(hideStompNotification())
                }, 100)

            });

            if(onConnect) {
                onConnect()
            }

            if (subscription) {
                dispatch( {
                    type: 'SET_MOHICAN_CLIENT',
                    client: stompClient
                })

            }
        }, (error) => {
            console.log(error.headers.message)
        }, (/*closeEvent*/) => {
            dispatch( {
                type: 'SET_MOHICAN_CLIENT',
                client: null
            })
        })
    }

}

export function getMohicanUrl(path, port) {

    let d = null;//localStorage.getItem('deltaprotomohican');
    let url = 'localhost'

    if( d != null && d !== "null" ) {
        url = d;
    }

    if( port ) {
        if( window.location.protocol === 'https:' && port === 80 ) {
            port = 443
        }
        return `${(port === 443 ? 'https' : 'http')}://${url}:${port}/${path}`;
    }
    else if( window.location.protocol === 'https:' ) {
        return `https://${url}:8008/${path}`;
    } else {
        return `http://${url}:8007/${path}`;
    }
}

export function setMohicanUrl(url) {
    localStorage.setItem('deltaprotomohican', url);
}

export function mohicanSendMessage(type, value) {

    let url= getMohicanUrl('mohicanreq');

    let content= {
        type,
        value
    }

    let body= JSON.stringify(
        content
    );

    fetch(url, {
        method: 'POST',
        headers: new Headers({
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }),
        body: body
    })
}

export function changePackingType(id, name, value) {
    return {
        type: 'CHANGE_PACKING_TYPES',
        id, name, value
    }
}

export function getAllPackingTypes() {
    return async('GET_ALL_PACKING_TYPES', {}, '/api/bom/packing/all', null, {})
}

export function savePackingType(value) {
    return async('SAVE_PACKING_TYPES', {}, '/api/bom/packing/save', value, {})
}

export function sendProtoFotoMail(projectId, page, onSuccess) {
    return async('SEND_PROTO_FOTO', {}, '/api/project/informprotofoto', null, {projectId, page}, null, onSuccess)
}

export function changeProjectValue(name, value) {
    return {
        type: 'CHANGE_PROJECT_VALUE',
        name, value
    }
}

export function changeProjectCalculatorValue(name, value) {
    return {
        type: 'CHANGE_PROJECT_CALCULATOR_VALUE',
        name, value
    }
}

export function changeProjectPage (value){
    return {
        type: 'CHANGE_PROJECT_PAGE',
        value
    }
}

export function toggleRenderAsUser() {
    return {
        type: 'TOGGLE_USER_RENDER'
    }
}

export function changeUserModalOpen(value) {
    return {
        type: 'OPEN_CHANGE_USER_MODAL',
        value
    }
}

export function setUserIdleTimeout(value) {
    return {
        type: 'SET_USER_IDLE_TIMEOUT',
        value
    }
}

export function getUserChangeOptions() {
    return async('USER_OPTIONS', {}, '/api/user/changeoptions', null, {})
}

export function changeUser(email, onSuccess) {
    return async('CHANGE_USER', {}, '/api/user/change', null, {email}, null, onSuccess)
}

export function getBlogItem(index) {
    return async('BLOG_ITEM', {index}, '/api/updates', null, {index})
}

export function addNewBlogItem() {
    return {
        type: 'ADD_BLOG_ITEM',
    }
}

export function changeBlogItemValue(id,name, value) {
    return {
        type: 'CHANGE_BLOG_ITEM_VALUE',
        id, name, value
    }
}

export function saveBlogItem(value) {
    return async('SAVE_BLOG_ITEM', {id: value.id}, '/api/saveupdate', value, null)
}

export function submitContactForm(firstname, lastname, email, phone, message, onSuccess) {
    return async('SUBMIT_CONTACT_FORM', {}, '/api/contact', null, {firstname, lastname, email, phone, message}, null, onSuccess)
}

export function regsiterForNewsletter(email, onSuccess) {
    return async('REGISTER_NEWSLETTER', {}, '/api/newsletter/register', null, {email}, null, onSuccess)
}

export function getNewsletterList() {
    return async('GET_NEWSLETTER_LIST', {}, '/api/newsletter/list', null, {}, null, null)
}

export function getQuestionByProject(id) {
    return async('GET_QUESTIONS', {id}, '/api/project/questions/get', null, {id})
}

/**
 * value has:
 * {
 *     id,
 *     projectid,
 *     question
 *     answer,
 *     stage,
 *     questionDate,
 *     answerDate
 * }
 */
export function saveQuestion(value) {
    return async('SAVE_QUESTIONS', {}, '/api/project/questions/save', value, {})
}

export function answerQuestion(id, answer) {
    return async('ANSWER_QUESTION', {id}, '/api/project/questions/answer', null, {id, answer})
}

export function getProjectPlanning(onSuccess) {
    return async('GET_PROJECT_PLANNING', {}, '/api/project/getProjectPlanning', null, {}, null, onSuccess)
}

export function updateProjectPlanning(value) {
    return async('UPDATE_PROJECT_PLANNING', {}, '/api/project/updateProjectPlanning', value, {})
}

export function highlightProjectPlanning(id) {
    return {
        type: 'HIGHLIGHT_PROJECT_PLANNING',
        id
    }
}

export function getChatMentionable() {
    return async('GET_CHAT_MENTIONABLE', {}, '/api/user/mentionable', null, {})
}

export function setChatHandled(id, handled, onSuccess) {
    return async('SET_CHAT_HANDLED', {}, '/api/chat/handled', null, {id, handled}, undefined, onSuccess)
}

export function getChatRefInfo(ref, chatId, onSuccess) {
    return async('GET_CHAT_REF_INFO', {chatId}, '/api/chat/getRefInfo', null, {ref}, undefined, onSuccess)
}

export function openChatMentionable(value) {
    return {
        type: 'OPEN_CHAT_MENTIONABLE',
        value
    }
}

export function openChatModal(value) {
    return {
        type: 'OPEN_NEW_CHAT_MODAL',
        value
    }
}

export function unhandledCount() {
    return async('GET_CHAT_UNHANDLED_COUNT', {}, '/api/chat/unhandledCount', null, {})
}

export function getChatForProjectOrder(orderId) {
    return async('GET_CHAT_FOR_PROJECT_ORDER', {}, '/api/chat/getChatForProjectOrder', null, {orderId})
}

export function getChatForPrint(id) {
    return async('GET_CHAT_FOR_PRINT', {}, '/api/chat/getChatForPrint', null, {id})
}

export function getChatOverviewByPage(page, filter, onSuccess) {
    return async('GET_CHAT_OVERVIEW_BY_PAGE', {}, '/api/chat/getPage', null, {page, filter}, null, onSuccess)
}

export function getChatOverview(chatId, ascending, onSuccess) {
    return async('GET_CHAT_OVERVIEW', {}, '/api/chat/getChatOverview', null, {chatId, ascending: true}, null, onSuccess)
}

export function getUserForChat(id) {
    return async('GET_CHAT_USER', {}, '/api/chat/getUser', null, {id})
}

export function postChatMessage(chatId, text, ref, toUserId, attachments, onSuccess, planning) {
    return async('POST_CHAT_MESSAGE', {chatId}, '/api/chat/post', null, {text, chatId, ref, toUserId, attachments, planning}, null, onSuccess)
}

export function updateChatMessage(chatId, text, ref, toUserId, important, importantHigh, planning) {
    return async('UPDATE_CHAT_MESSAGE', {chatId}, '/api/chat/update', null, {text, id: chatId, ref, toUserId, important, importantHigh, planning})
}

export function deleteChatMessage(chatId) {
    return async('DELETE_CHAT_MESSAGE', {id: chatId}, '/api/chat/delete', null, {id: chatId})
}

export function changeChatValue(id, name, value) {
    return {
        type: 'CHANGE_CHAT_VALUE',
        id, name, value
    }
}

export function changeChatAddListItem(id, name, value) {
    return {
        type: 'CHANGE_CHAT_ADD_LIST_VALUE',
        id, name, value
    }
}

export function addLineProductionRelease(id, line, file) {
    return {
        type: 'ADD_LINE_PRODUCTION_RELEASE',
        id, line, file
    }
}

export function removeLineProductionRelease(id, i) {
    return {
        type: 'REMOVE_LINE_PRODUCTION_RELEASE',
        id, i
    }
}

export function setValueProductionRelease(id, i, name, value) {
    return {
        type: 'SET_VALUE_PRODUCTION_RELEASE',
        id, i, name, value
    }
}

export function getProductionRelease(projectOrderId) {
    return async('GET_PRODUCTION_RELEASE', {}, '/api/project/releaseProduction', null, {projectOrderId})
}

export function submitProductionRelease(value, startId, projectOrderId, action, onSuccess) {
    return async('POST_PRODUCTION_RELEASE', {id: value.id, startId}, '/api/project/releaseProduction', value, {projectOrderId, action}, null, onSuccess)
}

export function cancelProductionRelease(id, released, onSuccess) {
    return async('CANCEL_PRODUCTION_RELEASE', {id}, '/api/project/cancelReleaseProduction', null, {id, released: parseInt(released)}, null, onSuccess)
}

export function addLineProjectModification(line, fileId) {
    return {
        type: 'ADD_LINE_PROJECT_MODIFICATION', line, fileId
    }
}

export function removeLineProjectModification(i) {
    return {
        type: 'REMOVE_LINE_PROJECT_MODIFICATION',
        i
    }
}

export function setValueProjectModification(i, name, value) {
    return {
        type: 'SET_VALUE_PROJECT_MODIFICATION',
        i, name, value
    }
}

export function getProjectModification(projectOrderId) {
    return async('GET_PROJECT_MODIFICATION', {}, '/api/project/modification', null, {projectOrderId})
}

export function submitProjectModification(value, projectOrderId, action, onSuccess) {
    return async('POST_PROJECT_MODIFICATION', {}, '/api/project/modification', value, {projectOrderId, action}, null, onSuccess)
}


export function getAddress(zip, number, onSuccess) {
    return async('FETCH_ADDRESS', {}, '/api/user/postcode', null, {zip, number}, null, onSuccess)
}

export function getDigiKeyClientIdAndSecret() {
    return async('GET_DIGIKEY_SETTINGS', {}, '/api/digikey/getClientIdAndSecret', null, {}, null)
}

export function setDigiKeyClientIdAndSecret(body) {
    return async('SET_DIGIKEY_SETTINGS', {}, '/api/digikey/setClientIdAndSecret', body, {}, null)
}

export function testDigiKey(onSuccess) {
    return async('TEST_DIGIKEY', {}, '/api/digikey/test', null, {}, null, onSuccess)
}

export function changeDigiKeySetting(name, value) {
    return {
        type: 'CHANGE_DIGIKEY_SETTINGS',
        name, value
    }
}

export function getNexarClientIdAndSecret() {
    return async('GET_NEXAR_SETTINGS', {}, '/api/nexar/getClientIdAndSecret', null, {}, null)
}

export function setNexarClientIdAndSecret(body) {
    return async('SET_NEXAR_SETTINGS', {}, '/api/nexar/setClientIdAndSecret', body, {}, null)
}

export function testNexar(onSuccess) {
    return async('TEST_NEXAR', {}, '/api/nexar/test', null, {}, null, onSuccess)
}

export function changeNexarSetting(name, value) {
    return {
        type: 'CHANGE_NEXAR_SETTINGS',
        name, value
    }
}


export function getEmployeeHours(from, to) {
    return async('GET_EMPLOYEE_HOURS', {}, '/api/employee/uren', null, {from, to})
}

export function saveEmployeeHours(value) {
    return async('SAVE_EMPLOYEE_HOURS', {}, '/api/employee/save', value, {})
}

export function setEmployeeHoursStartDate(value) {
    return {
        type: 'SET_EMPLOYEE_HOURS_START_DATE', value
    }
}

export function openSideBar(open) {
    return {
        type: 'OPEN_SIDE_BAR',
        open
    }
}

export function getSharketingPersonList() {
    return async('GET_SHARKETING_PERSON_LIST', {}, '/api/sharketing/list', null, {})
}
export function revertSharketingPerson(id) {
    return {
        type: 'REVERT_SHARKETING_PERSON',
        id
    }
}
export function updateSharketingPerson(value, onSuccess) {
    return async('UPDATE_SHARKETING_PERSON', value, '/api/sharketing/update', value, {}, null, onSuccess)
}
export function updateSharketing(value, personId) {
    return async('UPDATE_SHARKETING', {}, '/api/sharketing/updateSharketing', value, {personId})
}

export function addSharketingPerson() {
    return {
        type: 'ADD_SHARKETING_PERSON'
    }
}

export function addSharketing(personId, type) {
    return {
        type: 'ADD_SHARKETING',
        personId, typeValue: type
    }
}

export function removeSharketing(personId, id) {
    return {
        type: 'REMOVE_SHARKETING',
        personId, id
    }
}


export function changeSharketingPersonValue(id, name, value) {
    return {
        type: 'CHANGE_SHARKETING_PERSON_VALUE',
        id, name, value
    }
}

export function changeSharketingValue(personId, id, name, value) {
    return {
        type: 'CHANGE_SHARKETING_VALUE',
        personId, id, name, value
    }
}

export function addSharketingSort(name) {
    return {
        type: 'ADD_SORT_SHARKETING',
        name
    }
}

export function addSharketingFilter(name, filter) {
    return {
        type: 'ADD_FILTER_SHARKETING',
        name, filter
    }
}

export function removeSharketingSort(id) {
    return {
        type: 'REMOVE_SORT_SHARKETING',
        id
    }
}

export function addCompanySort(name) {
    return {
        type: 'ADD_SORT_COMPANYS',
        name
    }
}
export function addCompanyFilter(name, filter) {
    return {
        type: 'ADD_FILTER_COMPANYS',
        name, filter
    }
}

export function removeCompanySort(id) {
    return {
        type: 'REMOVE_SORT_COMPANYS',
        id
    }
}

export function getCompanyList() {
    return async('GET_COMPANY_LIST', {}, '/api/sharketing/companyList', null, {})
}
export function updateCompany(value, onSuccess) {
    return async('UPDATE_COMPANY', {}, '/api/sharketing/updateCompany', value, {}, null, onSuccess)
}

export function addCompany() {
    return {
        type: 'ADD_COMPANY'
    }
}

export function changeCompanyValue(id, name, value) {
    return {
        type: 'CHANGE_COMPANY_VALUE',
        id, name, value
    }
}

export function openSiteSearch(open) {
    return {
        type: 'OPEN_SITE_SEARCH',
        open
    }
}

export function changeSiteSearchValue(value) {
    return {
        type: 'CHANGE_SITE_SEARCH_VALUE',
        value
    }
}


export function siteSearch(query, onSuccess) {
    return async('SITE_SEARCH', {}, '/api/search', null, {query}, null, onSuccess)
}

export function searchPrintOrCompany(query, onlyCompanies, onSuccess) {
    return async('SEARCH_PRINT_OR_COMPANY', {}, '/api/searchPrintOrCompany', null, {query, onlyCompanies}, null, onSuccess)
}

export function deltaTableSetResize(name, value) {
    return {
        type: 'DELTA_TABLE_RESIZE',
        name, value
    }
}

export function deltaTableResetWidth(name) {
    return {
        type: 'DELTA_TABLE_RESET_WIDTH',
        name
    }
}

export function addDeltaTableSort(name, column, header) {
    return {
        type: 'DELTA_TABLE_ADD_SORT',
        name, column, header
    }
}

export function removeDeltaTableSort(name, id) {
    return {
        type: 'DELTA_TABLE_REMOVE_SORT',
        name, id
    }
}

export function addDeltaTableFilter(name, column, value, header) {
    return {
        type: 'DELTA_TABLE_ADD_FILTER',
        name, column, value, header
    }
}

export function removeDeltaTableFilter(name, id) {
    return {
        type: 'DELTA_TABLE_REMOVE_FILTER',
        name, id
    }
}

export function deltaTableSetColWidth(name, id, width) {
    return {
        type: 'DELTA_TABLE_SET_COL_WIDTH',
        name, id, width
    }
}

export function getAllFloatingPcbPictures() {
    return async('GET_FLOATING_PCB_PICTURES', {}, '/api/project/getAllFloatingPcbPictures', null, {}, null)
}

export function getEmailOverviewByPage(pageNumber) {
    return async('GET_EMAIL_OVERVIEW', {}, '/api/getMailMessages', null, {pageNumber})
}

export function fetchIbiza() {
    return async('FETCH_IBIZA', {}, '/api/intake/ibiza', null, {})
}