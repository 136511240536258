import React from 'react'
import {connect} from "react-redux";
import {Icon, Popup} from "semantic-ui-react";
import PropTypes from "prop-types";
import Translate from "./Translate";

class InformationPopup extends React.Component {
    render() {
        return (
            <Popup trigger={
                <Icon {...this.props.iconStyle} name={this.props.icon} size={this.props.iconSize}/>
                    }>

                <Translate value={this.props.value}/>

            </Popup>
        );
    }
}


InformationPopup.propTypes = {
    value: PropTypes.string.isRequired
};

InformationPopup.defaultProps = {
    icon: 'info',
    iconStyle: {circular: true, bordered: true},
    iconSize: 'small',
};

const mapStateToProps = (state) => {
    return {
        lang: state.main.lang,
        currentLang: state.main.currentLang,
    }
}

export default connect(mapStateToProps)(InformationPopup)