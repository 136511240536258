import React from 'react'
import connect from "react-redux/es/connect/connect";
import {withRouter} from "react-router-dom";
import DeltaTable from "./DeltaTable";
import {DeltaTableCell} from "./DeltaTableCell";
import DeltaTableMenu from "./DeltaTableMenu";
import BomImportAndEdit from "../bom/BomImportAndEdit";

class DeltaTableTestPage extends React.Component {

    state = {}

    render() {
        const admin = true;

        return <main id={'test'} className={'pageContainer'} style={{textAlign: 'left'}}>

            <button onClick={() => {
                let url = 'https://www.pcbway.com/QuickOrderOnline.aspx'
                var fenetrePopup = window.open(url);
                let l = fenetrePopup.document.getElementsById("hidLength");
                console.log(JSON.stringify(l))
                l.value = "999";
                //fenetrePopup.getElementsByName("NameForm").submit();
            }}>Test fill form</button>

            Test for BOM import:
            <BomImportAndEdit onImport={(d) => {
                console.log(d)
            }}/>

            <div style={{marginTop: 16}}>
                Test for Centroid import:
                <BomImportAndEdit type='centroid' onImport={(d) => {
                    console.log(d)
                }}/>
            </div>

            {JSON.stringify(this.state.sortAndFilter)}

            <DeltaTableMenu >

                <DeltaTable
                    name={'testpage'}
                    columns={[
                        {id: 1, header: 'a', sort: 'a', defaultWidth: 80, render: (r) => <DeltaTableCell
                                l1={r.a}
                                l2={r.a}
                                l3={r.a}
                                r1={r.a}
                                r2={r.a}
                                r3={r.a}
                            />},
                        {id: 2, header: 'b', defaultWidth: 80, render: (r) => <div>
                                {JSON.stringify(r)}
                            </div>},
                        {id: 3, header: 'c', defaultWidth: 80, render: (r) => r.c},
                    ]}
                    data={[
                        {a: 1, b: 2, c: 3, x: 1},
                        {a: 2, b: 4, c: 3, x: 1},
                        {a: 1, b: 2, c: 3, x: 1},
                        {a: 2, b: 4, c: 3, x: 1},
                        {a: 1, b: 2, c: 3, x: 1},
                        {a: 2, b: 4, c: 3, x: 1},
                        {a: 1, b: 2, c: 3, x: 1},
                        {a: 2, b: 4, c: 3, x: 1},
                        {a: 1, b: 2, c: 3, x: 1},
                        {a: 2, b: 4, c: 3, x: 1},
                        {a: 1, b: 2, c: 3, x: 1},
                        {a: 2, b: 4, c: 3, x: 1},
                        {a: 1, b: 2, c: 3, x: 1},
                        {a: 2, b: 4, c: 3, x: 1},
                        {a: 1, b: 2, c: 3, x: 1},
                        {a: 2, b: 4, c: 3, x: 1}
                    ]}
                    onSortAndFilter={(value) => {
                        console.log(value)
                        this.setState({sortAndFilter: value})
                    }}

                />
            </DeltaTableMenu>


        </main>
    }
}


const mapStateToProps = (state) => {
    return {
        lang: state.main.lang,
        currentUser: state.main.currentUser
    }
}

export default connect(mapStateToProps)(withRouter(DeltaTableTestPage))