import React from 'react';
import mapStateToProps from "react-redux/lib/connect/mapStateToProps";
import {connect} from "react-redux";
import Toast from "../basic/Toast";
import {Button, Message, Modal, Popup, Segment, TextArea} from "semantic-ui-react";
import Translate from "../Translate";
import {customerConfirmChecked, customerConfirmCheckedOpen} from "../actions";
import PropTypes from "prop-types";

class BomCustomerConfirm extends React.Component {

    state = {
        message: '',
        loading: false
    }

    handleConfirm = () => {
        const m = this.state.message ? this.state.message : '';
        this.props.dispatch(customerConfirmChecked(this.props.print.id, m, () => {
            this.setState({loading: false, confirm: false, done: true, message: ''});
        }));
    };

    render() {
        const open = this.props.bomConfirmCheckedOpen;
        const loading = this.props.bomConfirmCheckedBusy;
        const hasMarkedLines = this.props.hasMarkedLines;

        return (
            <div>
                <Modal open={open} onClose={() => this.setState({confirm: false})} closeIcon>
                    <Modal.Header><Translate value={'bom.marked.checked.button'}/></Modal.Header>
                    <Modal.Content>
                        <Translate value={'bom.marked.checked'}/>
                        <br/>
                        <br/>
                        <div style={{width: '100%'}}>
                            <TextArea style={{width: '100%'}} autoHeight transparent fluid placeholder='Comments' onChange={(e) => this.setState({message: e.target.value})} rows={5}/>
                        </div>

                        {this.props.bomConfirmCheckedError ?  <Message error>
                            <Message.Content>Er is iets mis gegaan</Message.Content>
                        </Message> : null}

                    </Modal.Content>
                    <Modal.Actions>
                        <Button onClick={() => this.props.dispatch(customerConfirmCheckedOpen(false))}>
                            <Translate value={'cancel'}/>
                        </Button>
                        <Button primary onClick={this.handleConfirm} loading={loading}>
                            <Translate value={'bom.marked.checked.button'}/>
                        </Button>
                    </Modal.Actions>
                </Modal>

                {this.props.bomConfirmChecked ?
                    <div id='thanks'>
                        <Toast color='green' inverted={true} message={<div>
                            <Translate value={'bom.marked.thanks'}/>
                        </div>}/>
                    </div>
                    :
                    <div id='mark'>
                        <Toast animation={hasMarkedLines ? 'fly up' : ''} color='white' inverted={false} timeout={0} width={500} message={<div style={{margin: -8, display: 'flex'}}>

                            <div style={{lineHeight: 3.3, paddingRight: 16, paddingLeft: 16}}>
                                <Translate value={'bom.marked.checked'}/>
                            </div>

                            <Popup trigger={
                                <Button color='blue' style={{marginTop: 8}} onClick={() => {
                                    this.setState({confirm: true, message: ''})
                                    this.props.dispatch(customerConfirmCheckedOpen(true))
                                }}>
                                    <Translate value={'bom.marked.checked.button'}/>
                                </Button>
                            }
                               content={<div style={{textAlign: 'left'}}>
                                   <Translate value={'bom.marked.checked'}/>
                               </div>}
                               position='top center'
                            />
                        </div>}/>
                    </div>
                }

            </div>
        );
    }
}

BomCustomerConfirm.propTypes = {
    hasMarkedLines: PropTypes.bool,
};

BomCustomerConfirm.defaultProps = {
    hasMarkedLines: false,
};

mapStateToProps = (state) => {
    return {
        print: state.bom.print,
        bomConfirmCheckedOpen: state.bom.bomConfirmCheckedOpen,
        bomConfirmChecked: state.bom.bomConfirmChecked,
        bomConfirmCheckedBusy: state.bom.bomConfirmCheckedBusy,
        bomConfirmCheckedError: state.bom.bomConfirmCheckedError,

    }
}

export default connect(mapStateToProps)(BomCustomerConfirm)