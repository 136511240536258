import React from 'react';
import PropTypes from "prop-types";
import connect from "react-redux/es/connect/connect";
import {Button, Form, Header, Icon, Modal, Progress, Segment} from "semantic-ui-react";
import Dropzone from "react-dropzone";
import {showUploadPcbsModal} from "./actions";
var request = require('superagent');

class ProjectStepFotoUpload extends React.Component {

    state = {
        data: {files: null},
        progress: {perc: 100, name: '', done: true},
        dropzoneActive: false,
        uploadError: null,
    }

    onDrop = (acceptedFiles, rejectedFiles) => {
        this.setState({
            message: '',
            dropzoneActive: false,
            uploadError: null,
            errorFile: null
        });

            var req = request.post('/api/project/addProjectOrderPcbPictures?projectOrderId=' + this.props.projectOrderId );

            const name= acceptedFiles.length + ' bestanden';

            this.setState({progress: {perc: 0, name: name}})

            acceptedFiles.forEach((file)=> {
                req.attach('files', file, file.name);
            });

            req.on('progress', (e) => {

                if( e.percent != null ) {
                    this.setState({progress: {perc: e.percent, name: name, done: false}});
                }


            })
            req.end((err,res) => {
                if( !err ) {

                    console.log(res)

                    let current= this.state.data.files;
                    if( current == null ) {
                        current= [];
                    }

                    if( res.body && res.body.message ) {
                        current.push({name: res.body.message})
                    }

                    let newData= Object.assign({}, this.state.data, {
                        files: current
                    })

                    this.setState({data: newData, progress: {perc: 100, name: this.state.progress.name, done: true}})

                } else {
                    this.setState({uploadError: err.message, progress: {perc: 100, name: ''}})
                }
            });


    }

    handleClose = () => {
        this.props.dispatch(showUploadPcbsModal(false))
        if( this.props.onClose ) {
            this.props.onClose();
        }
    }

    render() {

        const data= this.state.data;

        return <Modal
            open={this.props.showUploadModal}
            closeIcon
            closeOnEscape={true}
            closeOnDimmerClick={false}
            onClose={this.handleClose}
        >
            <Modal.Header>Uploaden ProtoFoto's</Modal.Header>
            <Modal.Content>

                <Segment.Group style={{backgroundColor: this.state.errorFile ? '#fff6f6' : '', borderColor: this.state.errorFile ? '#e2babb' : '', boxShadow: 'none', marginTop: '30px'}}>

                    <Segment placeholder style={{marginTop: 2, backgroundColor: this.state.errorFile ? '#fff6f6' : ''}} inverted={this.state.dropzoneActive} color={this.state.dropzoneActive ? 'green' : ''}
                                         loading={this.state.progress.perc < 100}>

                        <Dropzone onDrop={this.onDrop}
                                  ref={(node) => {
                                      this.dropzoneRef = node;
                                  }}
                                  multiple={true}
                                  disableClick={true}
                                  disablePreview={true}
                                  style={{}}
                                  onDragEnter={() => this.setState({dropzoneActive: true})}
                                  onDragLeave={() => this.setState({dropzoneActive: false})}
                        >


                            <Form.Group widths={1}>

                                <div style={{textAlign: 'center', width: '100%', marginTop: '14px'}}>

                                    <Header as='h2' data-langkey='calc_drag_files'>Sleep hier de foto's heen (alle bestanden in één keer)</Header>
                                    <p data-langkey='calc_file_types'>(Bestand type: foto<b>.jpg/.jpeg/.png</b>)</p>
                                    <p data-langkey='calc_file_types'>Geef de bestanden de juiste naam: [nummer][t/b].png<br/><b>t</b> geeft aan dat het om de top gaat, en <b>b</b> geeft aan dat het om de foto van de bottom (onderkant) gaat.</p>
                                    <p data-langkey='calc_file_types'>Bijvoorbeeld: 1230t.jpg, 1230b.jpg, 1231t.jpg, 1231b.jpg</p>
                                    <p data-langkey='calc_file_types'>Als er al foto's zijn met het zelfde nummer, dan worden deze overschreven</p>
                                    <p data-langkey='calc_file_types'>De foto's die je in een keer kan uploaden mogen bij elkaar niet meer dan <b>maximaal 1000MB</b> zijn</p>
                                    <Header as='h3'>
                                        <div style={{display: 'inline'}} data-langkey='calc_choose_your_files_or_space'>of </div>
                                        <button className="link-button" type="button" onClick={() => {this.dropzoneRef.open()}} data-langkey='calc_choose_your_files'>
                                            kies je bestanden
                                        </button>
                                    </Header>


                                </div>


                            </Form.Group>


                        </Dropzone>

                    </Segment>

                    {(this.state.progress.perc < 100 || !this.state.progress.done) && <Segment>
                        <Progress active indicating percent={this.state.progress.perc}>{this.state.progress.name}</Progress>
                    </Segment>}

                    {this.state.uploadError != null && <Segment>
                        {this.state.uploadError}
                    </Segment>}

                    {data.files && data.files.map(f => <Segment>
                        <Icon name='file outline'/>
                        {f.name}
                    </Segment>)}

                </Segment.Group>


            </Modal.Content>
            <Modal.Actions>
                <Button primary onClick={this.handleClose}>
                    Sluiten
                </Button>
            </Modal.Actions>
        </Modal>
    }
}

ProjectStepFotoUpload.propTypes = {
    projectOrderId: PropTypes.number.isRequired
};

ProjectStepFotoUpload.defaultProps = {
};

const mapStateToProps = (state) => {
    return {
        lang: state.main.lang,
        showUploadModal: state.project.showUploadModal,
    }
}

export default connect(mapStateToProps)(ProjectStepFotoUpload)
