import React, {Component} from "react";
import {BomTodo} from "./BomTodo";
import {Button, Divider, Form, Icon, Modal, Popup} from "semantic-ui-react";
import BomCustomerView from "./BomCustomerView";
import {removeAllBomLineInfo, setSelectedBomRow, setValueForBomLine} from "../actions";
import connect from "react-redux/es/connect/connect";
import {renderSupplier} from "./functions";
import PropTypes from "prop-types";
import BomAddRow from "./BomAddRow";
import BomOrderparts from "./BomOrderparts";
import {getHighlightedSpecs} from "./BomValidationNew";
import Highlighter from "react-highlight-words";
import Translate from "../Translate";

class BomCustomerData extends Component {

    state = {
        open: false
    }

    handleClose = () => {
        this.setState({editSpec: false})
        this.props.dispatch(setSelectedBomRow(this.props.r.id, true))
    }

    handleClear = () => {
        const r = this.props.r;

        this.props.dispatch(removeAllBomLineInfo(r.id))
    }

    handleChange = (value, todo) => {
        if( todo.isNew ) {

        } else {
            this.props.dispatch(setValueForBomLine(this.props.r.id, todo.id, value))
        }
    }

    render() {

        const r = this.props.r;
        const isAdmin = this.props.isAdmin;

        let isPcbOrStencil = r.designators.some(d => d.pcbOrder) || r.designators.some(d => d.stencilOrder);

        const designators = r.designators.map(d => d.value).join(', ')

        const mpn = (r && r.todos) ? r.todos.filter(td => td.opcode === 'MPN') : [];
        const sku = (r && r.todos) ? r.todos.filter(td => td.opcode === 'SKU') : [];
        const cpn = (r && r.todos) ? r.todos.filter(td => td.opcode === 'CPN') : [];

        const max = Math.max(mpn.length, sku.length, cpn.length);

        if( mpn.length === 0 ) {
            mpn.push({opcode: 'MPN', value: '', isNew: true})
        }
        if( mpn.length < max ) {
            for(let i = 0; i < max - mpn.length; i++) {
                mpn.push({emptyDiv: true})
            }
        }

        if( sku.length === 0 ) {
            sku.push({opcode: 'SKU', value: '', isNew: true})
        }
        if( sku.length < max ) {
            for(let i = 0; i < max - sku.length; i++) {
                sku.push({emptyDiv: true})
            }
        }

        if( cpn.length === 0 ) {
            cpn.push({opcode: 'CPN', value: '', isNew: true})
        }

        if( this.props.isKlaView ) {
            if( r.klantLevertAan ) {
                return <div>
                    <div className='btitem'></div>
                    <div className='btitem'>
                        <div>{r.klantLevertAan.mpn}</div>
                        {this.renderCustomerPartNumber(r)}
                    </div>
                    <div className='btitem'>
                        {r.klantLevertAan.manualselection ? renderSupplier({
                            supplier: 'MANUAL',
                            sku: ''
                        }) : renderSupplier({supplier: 'KLANT', sku: ''})}
                    </div>
                </div>
            } else {
                return <div>
                    <div className='btitem'></div>
                    <div className='btitem'></div>
                    <div className='btitem'></div>
                </div>
            }
        }

        const highlightWords = r.todos ? getHighlightedSpecs(r) : []


        return <div onMouseEnter={() => this.setState({enter: true})} onMouseLeave={() => this.setState({enter: false})}>
            <div className='btitem'>
                {(r.specification && r.specification.displayValue) ? <>
                    <Highlighter highlightClassName="Highlight" searchWords={highlightWords}
                                 textToHighlight={r.specification.displayValue}
                    />
                    {r.specification.warning ? <span className="Highlight " style={{marginLeft: 4}}>{r.specification.warning}</span> : null}
                </>: <div style={{color: 'darkgray'}}>{r.desciption}</div>}
            </div>
            <div className='btitem'>
                <div style={{display: 'flex', gap: 4}}>
                    {(r.todos && r.todos.some(t => t.opcode === 'MPN')) ?
                        <BomTodo key={r.id + '-MPN'} todos={r.todos} opcode={['MPN']} orderparts={r.orderparts} highlightWords={highlightWords}/> : null}
                    {this.renderCustomerPartNumber(r)}
                </div>
            </div>
            <div className='btitem' style={this.props.isKlaView ? {display: 'flex'} : {display: 'flex'}}>
                <BomTodo key={r.id + '-SKU'} todos={r.todos} opcode={['SKU']} orderparts={r.orderparts} highlightWords={highlightWords}/>
                {(!isPcbOrStencil && isAdmin) && <div className='customerdataedit'>
                    <Popup trigger={
                        <Icon style={{paddingLeft: 4}} fitted name='edit'
                              link onClick={() => {
                            this.props.dispatch(setSelectedBomRow(this.props.r.id))
                            this.setState({
                                enter: false,
                                editSpec: true,
                                newSpec: r.specification ? r.specification.displayValueOnly : '',
                                newPackageOutline: r.specification ? r.specification.packageOutline : '',
                                newDesciption: r.desciption
                            })
                        }}/>
                    } content='Bewerken' />
                </div>}
            </div>

            {(this.state.editSpec) && <Modal size={'large'} open={true} onClose={() => this.handleClose()} closeIcon dimmer={false}>
                <Modal.Header>Edit value ({this.props.print ? this.props.print.name : ''})</Modal.Header>
                <Modal.Content>

                    <BomCustomerView r={r}/>

                    <Divider/>

                    <div>
                        Row: {r.row}
                    </div>
                    <div>
                        Designators: {designators}
                    </div>

                    <Divider/>

                    <Form style={{marginTop: 16}}>
                        <Form.Group widths='equal'>
                            {(r.isResistor || r.isCapacitor) ? <Form.Input label={"Value (Only values!)"} value={this.state.newSpec} onChange={e => {
                                this.setState({newSpec: e.target.value})
                            }}/> : <Form.Input label={"Description"} value={this.state.newDesciption} onChange={e => {
                                this.setState({newDesciption: e.target.value})
                            }}/>}
                            <Form.Input label={"Package"} value={this.state.newPackageOutline} onChange={e => {
                                this.setState({newPackageOutline: e.target.value})
                            }}/>
                        </Form.Group>

                        {false ?  <Form>
                            <div style={{display: 'flex', gap: 8, paddingTop: 8}}>

                                {mpn.map((td, i) => {
                                    if( td.emptyDiv ) {
                                        return <>
                                            <div></div>
                                            <div></div>
                                        </>
                                    }
                                    const label = i === 0;
                                    return <>
                                        <div style={{flex: 1}}>
                                            <Form.Input
                                                onChange={(e) => this.handleChange(e.target.value)} value={td.key} disabled
                                                label={label ? 'Manufacturer' : null} name='manufacturer' fluid
                                                placeholder='Manufacturer'
                                            />
                                        </div>
                                        <div style={{flex: 1}}>
                                            <Form.Input
                                                onChange={this.handleChange} value={td.value} disabled
                                                label={label ? 'MPN' : null} name='mpn' fluid
                                                placeholder='Manufacturer Part Number'
                                            />
                                        </div>
                                    </>
                                })}

                                {sku.map((td, i) => {
                                    if( td.emptyDiv ) {
                                        return <>
                                            <div></div>
                                            <div></div>
                                        </>
                                    }
                                    const label = i === 0;
                                    return <>
                                        <div style={{flex: 1}}>
                                            <Form.Input
                                                onChange={this.handleChange} value={td.key} disabled
                                                label={label ? 'Supplier' : null} name='supplier' fluid
                                                placeholder='Supplier'
                                            />
                                        </div>
                                        <div style={{flex: 1}}>
                                            <Form.Input
                                                onChange={this.handleChange} value={td.value} disabled
                                                label={label ? 'SKU' : null} name='sku' fluid
                                                placeholder='Stock Keeping Unit'
                                            />
                                        </div>
                                    </>
                                })}

                                {cpn.map((td, i) => {
                                    const label = i === 0;
                                    return <div style={{flex: 1}}>
                                        <Form.Input
                                            onChange={this.handleChange} value={td.value} disabled
                                            label={label ? 'Art. No.' : null} name='cpn' fluid
                                            placeholder='Your own internal article number'
                                        />
                                    </div>
                                })}
                            </div>

                        </Form> : null}

                    </Form>

                    {this.state.modifyModal ? <BomAddRow bomLineId={this.props.r.id} designators={designators} onClose={() => this.setState({modifyModal: false, editSpec: false, saving: false})} /> : null}

                </Modal.Content>
                <Modal.Actions>
                    <Button
                        content='Annuleren'
                        onClick={() => this.handleClose()}
                    />
                    <Button
                        content='Alle aanpassen'
                        onClick={() => this.setState({modifyModal: true})}
                    />
                    <Button
                        content={<Translate value="save"/>}
                        primary
                        loading={this.state.saving}
                        disabled={this.state.saving}
                        onClick={() => {
                            this.setState({saving: true})
                            this.props.dispatch(setValueForBomLine(this.props.printId,
                                r.id, this.state.newSpec, this.state.newPackageOutline, this.state.newDesciption,
                                this.state.newCustomerPartNumber, () => {
                                    this.setState({editSpec: false, saving: false})
                            }))
                        }}
                    />
                </Modal.Actions>
            </Modal>}

            {this.state.analysisModal ? <Modal closeIcon onClose={() => this.setState({analysisModal: false})}>
                <Modal.Header>Analysis</Modal.Header>
                <Modal.Content>
                    {r.orderparts.length ?
                        <div>
                            <BomOrderparts parts={r.amount} amount={this.props.print.amount}
                                           showStockAndPrice={false} list={r.orderparts} todos={r.todos}
                            />
                        </div> : <div>No orderparts found</div>}
                </Modal.Content>
                <Modal.Actions>
                    <Button
                        content='Sluiten'
                        onClick={() => this.setState({analysisModal: false})}
                    />
                </Modal.Actions>
            </Modal> : null}

        </div>
    }

    renderCustomerPartNumber(r) {
        return (r.todos && r.todos.some(t => t.opcode === 'CPN')) ?
            <BomTodo key={r.id + '-CPN'} todos={r.todos} opcode={['CPN']}/>
            : null;
    }
}

BomCustomerData.propTypes = {
    r: PropTypes.object.isRequired,
    printId: PropTypes.string,
    isKlaView: PropTypes.bool,
    isAdmin: PropTypes.bool
};

BomCustomerData.defaultProps = {
    r: null,
    printId: '',
    isKlaView: false,
    isAdmin: false
};

const mapStateToProps = (state) => {
    return {
        currentUser: state.main.currentUser,
        print: state.bom.print,
    }
}

export default connect(mapStateToProps)(BomCustomerData)