import React, {Component} from "react";
import {
    changeAutoSelectSettings, changeDefaultAutoSelectSettings,
    openMohicanSelectionSettingsModal,
    revertAutoSelectSettings, revertDefaultAutoSelectSettings,
    saveAutoSelectSettings, updateDefaultAutoselectSettings
} from "../actions";
import {Button, Checkbox, Form, Icon, Modal, Segment} from "semantic-ui-react";
import connect from "react-redux/es/connect/connect";
import {SortableContainer, SortableElement} from 'react-sortable-hoc';
import {arrayMoveImmutable} from 'array-move';

// const SortableItem = SortableElement(({value, onRemove, index}) => <Segment className='sortableHelper'>
//     <div style={{display: 'flex'}}>
//         <Icon name='grid layout'/>
//         <div style={{flex: 1}}>
//             {index} {value}
//         </div>
//         <Icon link name='trash' color='red' onClick={() => {
//             if( onRemove ) {
//                 onRemove(value)
//             }
//         }}/>
//     </div>
//
// </Segment>);

// const SortableList = SortableContainer(({items, onRemove}) => {
//     return (
//         <Segment.Group>
//             {items.map((value, index) => (
//                 <SortableItem key={`item-${value}`} index={index} value={value} onRemove={onRemove}/>
//             ))}
//         </Segment.Group>
//     );
// });

class BomMicroPlacerPartSelectionSettings extends Component {

    handleClose = () => {
        this.props.dispatch(revertAutoSelectSettings());
        this.props.dispatch(openMohicanSelectionSettingsModal(false))
    }

    handleChange(name, value, id) {
        if( this.props.isDefaultView ) {
            this.props.dispatch(changeDefaultAutoSelectSettings(name, value, id))
        } else {
            this.props.dispatch(changeAutoSelectSettings(name, value, id))
        }
    }

    renderContent(s) {

        return (
            <div>
                <Form>
                    <Form.Dropdown
                        selection
                        label={'Buddy part selection'}
                        options={[
                            {key: 1, text: 'Buddy parts enabled', value: true},
                            {key: 2, text: 'Buddy parts disabled', value: false}
                        ]}
                        onChange={(e,d) => {
                            this.handleChange('usebuddy', d.value)
                        }}
                        value={s.usebuddy}
                    />

                    <Form.Dropdown
                        selection
                        label={'Selecteer ook alternatieve C\'s en R-en (indien beschikbaar) met een beter specificatie'}
                        options={[
                            {key: 1, text: 'Ja: selecteer ook alternatieven', value: true},
                            {key: 2, text: 'Nee: selecteer alleen parts met gelijke specificaties', value: false}
                        ]}
                        onChange={(e,d) => {
                            this.handleChange('includeBetterSpecedParts', d.value)
                        }}
                        value={s.includeBetterSpecedParts}
                    />

                    <Form.Input
                        label={'Negeer C\'s voor autoselectie met een waarde groter dan'}
                        onChange={(e,d) => {
                            this.handleChange('ignoreAutoselectCapacitorsGreaterThan', d.value)
                        }}
                        value={s.ignoreAutoselectCapacitorsGreaterThan}
                    />

                    <Form.Dropdown
                        selection
                        label={'Gebruik bedrijfsvoorraad'}
                        options={[
                            {key: 1, text: 'Ja', value: true},
                            {key: 2, text: 'Nee', value: false}
                        ]}
                        onChange={(e,d) => {
                            this.handleChange('useCompanyStock', d.value)
                        }}
                        value={s.useCompanyStock}
                    />
                </Form>


                <div style={{display: 'flex', gap: 16, marginBottom: 8, marginTop: 16}}>
                    <div style={{flex: 1}}>
                        <h3>
                            Automatch supplier order
                        </h3>
                        Click and drag to change the order

                        {/*<SortableList helperClass='sortableHelper' hideSortableGhost={false} items={s.enabledSuppliersList} onSortEnd={({oldIndex, newIndex}) => {*/}
                        {/*    let newValue = arrayMoveImmutable(s.enabledSuppliersList, oldIndex, newIndex)*/}
                        {/*    this.handleChange('enabledSuppliersList', newValue)*/}
                        {/*}} onRemove={(r) => {*/}
                        {/*    this.handleChange('enabledSuppliersList', s.enabledSuppliersList.filter(e => e !== r))*/}
                        {/*}} />*/}
                    </div>
                    <div style={{flex: 1}}>
                        <h3>
                            Automatch disabled suppliers
                        </h3>
                        Pres the + to move a supplier to the enabled list.

                        <Segment.Group>
                            {s.allSuppliers.filter(u => !s.enabledSuppliersList.some(e => e === u)).sort().map(u => {
                                return <Segment key={u}>
                                    <div style={{display: 'flex'}}>
                                        <div style={{flex: 1}}>
                                            {u}
                                        </div>
                                        <Icon link name='add' color='blue' onClick={() => {
                                            this.handleChange('enabledSuppliersList', [...s.enabledSuppliersList, u])
                                        }}/>
                                    </div>
                                </Segment>
                            })}
                        </Segment.Group>

                        {/*{s.allSuppliers.sort().map(u => {
                            const enabled = s.enabledSuppliersList.some(e => e === u);
                            const preferred = s.prefferedSuppliersList.some(e => e === u);
                            return <div style={{height: 28}} key={u}>
                                    <Checkbox label={u} checked={enabled} onChange={() => {
                                        if( enabled ) {
                                            this.handleChange('enabledSuppliersList', s.enabledSuppliersList.filter(e => e !== u))

                                            if( preferred ) {
                                                this.handleChange('prefferedSuppliersList', s.prefferedSuppliersList.filter(e => e !== u))
                                            }

                                        } else {
                                            this.handleChange('enabledSuppliersList', [...s.enabledSuppliersList, u])
                                        }
                                    }}/>
                                </div>
                        })}*/}
                    </div>

                    {/*<div style={{flex: 1, textAlign: 'left', marginLeft: 16}}>
                        <div style={{height: 28}}>
                            Preferred
                        </div>

                        {s.allSuppliers.sort().map(u => {
                            const enabled = s.enabledSuppliersList.some(e => e === u);
                            const preferred = s.prefferedSuppliersList.some(e => e === u);
                            return <div style={{height: 28}} key={u}>
                                    <Checkbox toggle checked={preferred} onChange={() => {
                                        if( preferred ) {
                                            this.handleChange('prefferedSuppliersList', s.prefferedSuppliersList.filter(e => e !== u))
                                        } else {
                                            this.handleChange('prefferedSuppliersList', [...s.prefferedSuppliersList, u])
                                        }
                                    }}/>
                                </div>
                        })}
                    </div>*/}
                </div>

                {/*{s.allSuppliers.sort().map(u => {
                    const enabled = s.enabledSuppliersList.some(e => e === u);
                    const preferred = s.prefferedSuppliersList.some(e => e === u);
                    return <div style={{display: 'flex', marginBottom: 4}}>

                        <div>
                            <Checkbox label={u} checked={enabled} onChange={() => {
                                if( enabled ) {
                                    this.handleChange('enabledSuppliersList', s.enabledSuppliersList.filter(e => e !== u))
                                } else {
                                    this.handleChange('enabledSuppliersList', [...s.enabledSuppliersList, u])
                                }
                            }}/>
                        </div>

                        <div style={{flex: 1, textAlign: 'left', marginLeft: 16}}>
                            <Checkbox toggle checked={preferred} onChange={() => {
                                if( preferred ) {
                                    this.handleChange('prefferedSuppliersList', s.prefferedSuppliersList.filter(e => e !== u))
                                } else {
                                    this.handleChange('prefferedSuppliersList', [...s.prefferedSuppliersList, u])
                                }
                            }}/>
                        </div>
                    </div>
                })}*/}
            </div>
        );
    }

    renderActions(s) {
        return <>
            <Button disabled={!s.dirty} onClick={() => {
                if( this.props.isDefaultView ) {
                    this.props.dispatch(revertDefaultAutoSelectSettings());
                } else {
                    this.props.dispatch(revertAutoSelectSettings());
                }
            }}>Revert</Button>
            {this.props.asModal ? <Button onClick={this.handleClose}>Close</Button> : null}
            <Button primary disabled={!s.dirty} onClick={() => {
                if( this.props.isDefaultView ) {
                    this.props.dispatch(updateDefaultAutoselectSettings(s));
                } else {
                    this.props.dispatch(saveAutoSelectSettings(s, () => {
                        this.props.dispatch(openMohicanSelectionSettingsModal(false))
                    }))
                }
            }}>Save</Button>
        </>
    }

    render() {
        if(this.props.asModal && !this.props.mohicanSelectionSettingsModal) {
            return <></>
        }

        const s = this.props.isDefaultView ? this.props.defaultAutoselectSettings : this.props.print.bomAutoSelectSettings

        if( !s ) {
            return <></>
        }

        if( this.props.asModal ) {
            return <Modal size={'small'} open={this.props.mohicanSelectionSettingsModal} closeIcon onClose={this.handleClose}>
                    <Modal.Header>Supplier settings</Modal.Header>
                    <Modal.Content>
                        {this.renderContent(s)}
                    </Modal.Content>
                    <Modal.Actions>
                        {this.renderActions(s)}
                    </Modal.Actions>
                </Modal>
        }
        return <div>

            {this.renderContent(s)}
            {this.renderActions(s)}
        </div>
    }

}

BomMicroPlacerPartSelectionSettings.defaultProps = {
    asModal: true,
    isDefaultView: false
}

const mapStateToProps = (state) => {
    return {
        currentUser: state.main.currentUser,
        lang: state.main.lang,
        print: state.bom.print,
        mohicanSelectionSettingsModal: state.bom.mohicanSelectionSettingsModal,
        defaultAutoselectSettings: state.bom.defaultAutoselectSettings,
    }
}

export default connect(mapStateToProps)(BomMicroPlacerPartSelectionSettings)