import React, {Component} from 'react';
import './App.css';
import connect from "react-redux/es/connect/connect";
import {Button, Icon, Modal, Popup, Table} from "semantic-ui-react";
import {formatDate} from "./actions/functions";
import DatePicker from "react-datepicker";
import {saveProject} from "./actions";
import Translate from "./Translate";

var moment = require('moment');
moment.locale('nl');

class ProjectPlanning extends Component{

    state = {
        edit: {}
    }

    componentDidMount() {
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
    }

    renderEditItem(name, value, allowSetNull = true) {

        let m_value = value;
        let color = null;

        if( this.state.edit[name] ) {
            m_value = this.state.edit[name] !== 'null' ? this.state.edit[name] : null;
            color = 'blue'
        }

        return <div style={{display: 'inline'}}>
            <Popup content={<div style={{textAlign: 'center'}}>
                {name} {JSON.stringify(m_value)}
                <DatePicker inline selected={m_value} onChange={(v) => {
                    this.setState({edit: {
                            ...this.state.edit, [name]: moment(v).unix() * 1000
                        }
                    })
                }}
                todayButton={"Vandaag"} dateFormat={"d-M-yyyy"}/>

                {allowSetNull ? <Button style={{marginTop: 8}} onClick={() => {
                    this.setState({
                        edit: {
                            ...this.state.edit, [name]: 'null'
                        }
                    })
                }}>Datum verwijderen</Button> : null}

            </div>} hoverable trigger={<Icon color={color} name='calendar alternate outline' style={{display: 'inline', cursor: 'pointer', padding: 3}}/>} />

            {color ? <Icon
                name='close'
                color={color}
                style={{display: 'inline', cursor: 'pointer', padding: 3}}
                onClick={() => {
                    this.setState({edit: {
                        ...this.state.edit, [name]: null
                    }
                })}}
            /> : ''}

            {formatDate(m_value)}
        </div>
    }

    handleClose = () => {

        if( this.props.onClose ) {
            this.props.onClose()
        }

    }

    handleSave = () => {

        this.setState({saving: true})
        this.props.dispatch(saveProject({...this.props.project, ...this.state.edit}, null, () => {
            this.handleClose()
            this.setState({saving: false})
        }))
    }

    render() {

        const project = this.props.project;

        return <div>

            <Modal open={true} onClose={this.handleClose}>
                <Modal.Header>{project.internalName}</Modal.Header>
                <Modal.Content>

                    <Table celled>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell></Table.HeaderCell>
                                <Table.HeaderCell>Sample 1</Table.HeaderCell>
                                <Table.HeaderCell>Sample 2</Table.HeaderCell>
                                <Table.HeaderCell>Sample 3</Table.HeaderCell>
                                <Table.HeaderCell>Proto Serie</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            <Table.Row>
                                <Table.Cell>Buying</Table.Cell>
                                <Table.Cell>{this.renderEditItem('buyDate', project.buyDate)}</Table.Cell>
                                <Table.Cell>{this.renderEditItem('buy2Date', project.buy2Date)}</Table.Cell>
                                <Table.Cell>{this.renderEditItem('buy3Date', project.buy3Date)}</Table.Cell>
                                <Table.Cell>{this.renderEditItem('buySerieDate', project.buySerieDate)}</Table.Cell>
                            </Table.Row>

                            <Table.Row>
                                <Table.Cell>Check-In</Table.Cell>
                                <Table.Cell>{this.renderEditItem('checkinDate', project.checkinDate)}</Table.Cell>
                                <Table.Cell>{this.renderEditItem('checkin2Date', project.checkin2Date)}</Table.Cell>
                                <Table.Cell>{this.renderEditItem('checkin3Date', project.checkin3Date)}</Table.Cell>
                                <Table.Cell>{this.renderEditItem('checkinSerieDate', project.checkinSerieDate)}</Table.Cell>
                            </Table.Row>

                            <Table.Row style={{fontWeight: 'bold'}}>
                                <Table.Cell>Make & Ship Sample/Serie</Table.Cell>
                                <Table.Cell>{this.renderEditItem('sampleDate', project.sampleDate)}</Table.Cell>
                                <Table.Cell>{this.renderEditItem('sample2Date', project.sample2Date)}</Table.Cell>
                                <Table.Cell>{this.renderEditItem('sample3Date', project.sample3Date)}</Table.Cell>
                                <Table.Cell>{this.renderEditItem('seriesDate', project.seriesDate)}</Table.Cell>
                            </Table.Row>

                            <Table.Row>
                                <Table.Cell>Checked by Customer</Table.Cell>
                                <Table.Cell>{this.renderEditItem('sampleCheckedDate', project.sampleCheckedDate)}</Table.Cell>
                                <Table.Cell>{this.renderEditItem('sample2CheckedDate', project.sample2CheckedDate)}</Table.Cell>
                                <Table.Cell>{this.renderEditItem('sample3CheckedDate', project.sample3CheckedDate)}</Table.Cell>
                                <Table.Cell>n.v.t.</Table.Cell>
                            </Table.Row>

                        </Table.Body>
                    </Table>

                    <div style={{marginTop: 8}}>
                        Original delivery date: {project.deliveryDate ?
                        this.renderEditItem('deliveryDate', project.deliveryDate, false)
                        : '-'} (sample 1)
                    </div>
                </Modal.Content>
                <Modal.Actions>
                    <Button disabled={this.state.saving} onClick={this.handleClose}>Annuleren</Button>
                    <Button disabled={this.state.saving} loading={this.state.saving} primary
                            onClick={this.handleSave}><Translate value="save"/></Button>
                </Modal.Actions>
            </Modal>
        </div>
    }
}

const mapStateToProps = (state) => {
    return {
        lang: state.main.lang,
        currentLang: state.main.currentLang,
        currentUser: state.main.currentUser,
        project: state.project.project
    }
}

export default connect(mapStateToProps)(ProjectPlanning)
