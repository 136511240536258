import React from 'react'
import {encodeUrlParameters, fetchData} from "./actions";
import {Icon} from "semantic-ui-react";
import {formatDateTime} from "./actions/functions";
import Translate from "./Translate";
//import {formateDateTime, isDevEnvironment} from "../static/statics";

const errorUrl = '/api/logfrontenderror'

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { error: null, errorInfo: null };
    }

    componentDidCatch(error, errorInfo) {
        // Catch errors in any components below and re-render with error message
        this.setState({
            error: error,
            errorInfo: errorInfo,
            posting: false,
            success: false,
            failedToSend: false
        })
        // You can also log error messages to an error reporting service here

        //let dev= isDevEnvironment();

        let url = window.location.href;

        //if( !dev ) {
            this.logErrorToMyService(error, errorInfo, url);
        //}
    }

    logErrorToMyService = (error, errorInfo, url) => {

        this.setState({posting: true})

        fetchData(errorUrl + encodeUrlParameters({error, url}), errorInfo.componentStack)
            .then(response => {
                if (!response.ok) throw Error(response.statusText);
                return response;
            })
            .then(json => {
                this.setState({posting: false, success: true})
            })
            .catch(error => {
                this.setState({posting: false, success: false, failedToSend: true})
            })
    }

    handleClose = () => {
        window.location.reload();
    }

    render() {

        let dev= false;//isDevEnvironment();

        let url = window.location.href;

        if (this.state.errorInfo) {
            // Error path
            return (
                <div style={{margin: 'auto', padding: 16, maxWidth: 600, paddingTop: 128, marginBottom: 32, textAlign: 'left'}}>

                    <h2 style={{float: 'right'}}>
                        <Icon link name='close' color='black' onClick={this.handleClose}/>
                    </h2>

                    <img alt="down" src={Math.random() < 0.5 ? "/images/atatdown1.gif" : "/images/yoda-dies.gif"} style={{}}/>

                    <h2 style={{color: 'rgb(206, 17, 38)'}}><Translate value="er_isiets_mis_gegaan"/></h2>
                    <p>Er is een fout opgetreden in de frontend</p>
                    <p>{url}</p>

                    {dev ? <button onClick={() => this.logErrorToMyService(this.state.error, this.state.errorInfo, url)}>log error</button> : ''}

                    <p>
                        {this.state.posting ? <Icon loading name='spinner' color='blue'/> : <div>

                            {this.state.success ? <div><Icon name='check' color='green'/> Deze foutmelding is verzonden naar een beheerder</div> : ''}

                        </div>}

                        {this.state.failedToSend ? <div>
                            <Icon name='close' color='red'/> Het is niet gelukt om de foutmelding te verzenden naar een beheerder
                            <div>
                                {formatDateTime(new Date())}
                            </div>
                        </div> : ''}

                    </p>

                    <details style={{ whiteSpace: 'pre-wrap' }}>
                        {this.state.error && this.state.error.toString()}
                        <br />
                        {this.state.errorInfo.componentStack}
                    </details>
                </div>
            );
        }
        // Normally, just render children
        return this.props.children;
    }
}

export default ErrorBoundary