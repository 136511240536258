import React from 'react'
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip'
import {Icon} from "semantic-ui-react";

class MyInfo extends React.Component {

    render() {
        return [
            <Icon name='info circle' data-tip={this.props.message}/>,
            <ReactTooltip type="light" border={true} delayHide={1000} effect='solid'>
                <div style={{maxWidth: 200}}>
                    {this.props.message}
                </div>
            </ReactTooltip>
        ]

    }
}

MyInfo.propTypes = {
    message: PropTypes.any
};

MyInfo.defaultProps = {
    message: ''
};

export default MyInfo;