import React from 'react'
import {Button, Checkbox, Form, Header, Icon, Image, Menu, Modal, Segment} from "semantic-ui-react";
import connect from "react-redux/es/connect/connect";
import {
    changeProjectValue,
    fetchProject,
    fetchProjectEvents,
    getProductionRelease,
    getProjectModification, getUser, openLoginSignup,
    saveProject, saveUser, setDeliveryId, setInvoiceId
} from "./actions";
import Translate, {getText, getTextSubheader} from "./Translate";
import {withRouter} from "react-router-dom";
import AccountAddress from "./oldui/AccountAddress";
import {renderInputHorizontal} from "./oldui/Calculator";

class ProjectOrderModal extends React.Component {

    state = {
        factuurAddressIsTheSame: null
    }

    static contextTypes = {
        router: () => true, // replace with PropTypes.object if you use them
    }

    componentDidMount() {
        let id= this.props.match.params.projectId;

        if( this.props.project != null && this.props.project.id !== id ) {
            this.fetchAll(id);
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if( prevProps.currentUser == null && this.props.currentUser != null ) {
            let id= this.props.match.params.projectId;
            this.fetchAll(id)
        }
    }

    fetchAll = (id) => {
        this.props.dispatch(fetchProject(id));
        this.props.dispatch(fetchProjectEvents(id));
        this.props.dispatch(getProductionRelease(id));
        this.props.dispatch(getProjectModification(id));
        this.props.dispatch(getUser());
    }

    order = (deliveryId, invoiceId) => {

        let project= this.props.project;
        let args= {action: "ORDER", deliveryId, invoiceId};

        this.props.dispatch(saveProject(project, args, () => {
            if( args && args.action === "ORDER" ) {
                this.setState({thanks: true, ordered: true})
            }
            this.setState({cancelConfirm: false})
        }))
    }

    sortDeliveryAddress = (a,b) => {
        return b.isDefault - a.isDefault;
    }

    handleSelectDeliveryAddress = (id) => {
        this.props.dispatch(setDeliveryId(id))
    }

    handleChangeDeliveryAddress = (value) => {
        let usr= this.props.project.owner;
        usr.deliveryAddress= usr.deliveryAddress.map(a => {
            if( a.id === value.id ) {
                return value;
            }
            return a;
        });
        this.props.dispatch(saveUser(usr, true))
    }

    handleAddDeliveryAddress = () => {
        let usr= this.props.project.owner;
        usr.deliveryAddress= [...usr.deliveryAddress, {open: true}];
        this.props.dispatch(saveUser(usr, true))
    }

    handleChangeInvoiceAddress = (value) => {
        let usr= this.props.project.owner;
        usr.invoiceAddress= [value];
        this.props.dispatch(saveUser(usr, true))
    }

    handleChangeCheckbox = (e, { name, value }) => {
        let usr= this.props.project.owner;
        usr[name]= !usr[name];
        this.props.dispatch(saveUser(usr, true))
    }

    handleDeleteDeliveryAddress = (id) => {
        let usr= this.props.project.owner;
        usr.deliveryAddress= usr.deliveryAddress.filter(a => {
            return a.id !== id
        });
        this.props.dispatch(saveUser(usr, true))
    }

    render() {

        let project= this.props.project;
        let currentUser= project ? project.owner : null;

        if( !this.props.currentUser ) {
            return <div>
                <p><Translate value='project_need_to_login'/></p>
                <Button color='red' size='large' type="button" onClick={() => this.props.dispatch(openLoginSignup(true))}><p data-langkey="login_login">Login</p></Button>
            </div>
        }

        if( !project ) {
            return <div></div>
        }

        let factuurAddressIsTheSame= this.state.factuurAddressIsTheSame;

        if( factuurAddressIsTheSame === null ) {
            factuurAddressIsTheSame= currentUser.factuurAddressIsTheSame;
        }

        let invoiceAddress= currentUser && currentUser.invoiceAddress && currentUser.invoiceAddress[0] ? currentUser.invoiceAddress[0] : {country: 'Nederland'};

        let deliveryId= this.props.deliveryId;
        if( deliveryId === null ) {

            currentUser.deliveryAddress.forEach(d => {
                if( d.isDefault ) {
                    deliveryId= d.id;
                }
            })
        }

        let invoiceId= this.props.invoiceId;
        if( invoiceId === null ) {

            if( factuurAddressIsTheSame ) {
                invoiceId= deliveryId;
            } else {

                currentUser.invoiceAddress.forEach(i => {
                    invoiceId = i.id;
                })
            }
        }

        let hasAddress = false;

        return <main style={{textAlign: 'left'}} className={'pageContainer'} id={'projectPage'}>
            <div >
                <button className='link-button link-button-blue' onClick={() => {
                    this.props.history.goBack();
                }}><Translate value='back'/></button>
            </div>

            <Header><Translate value='bestellen_header'/> {project.name}</Header>

            <Form style={{maxWidth: 500}}>
                <Menu secondary attached='top' inverted color='blue'>
                    <Menu.Item header>{getText(this.props, "calc_aanvraag")}</Menu.Item>
                </Menu>
                <Segment attached='bottom'>
                    <div>
                        {renderInputHorizontal(getTextSubheader(this.props, "calc_amount"),
                            <p>{project.calculatorDetails.numPcbs}</p>,
                            100, false
                        )}
                    </div>
                    <div>
                        {renderInputHorizontal(getTextSubheader(this.props, "calc_deliverytime"),
                            <p>{project.calculatorDetails.selectedDays}</p>,
                            100, false
                        )}
                    </div>
                </Segment>
            </Form>

            <Header><Translate value="user_invoice_address"/></Header>
            <div>
                <Checkbox toggle label={getText(this.props, "user_address_same_delivery")} checked={factuurAddressIsTheSame} onChange={() => {
                    this.setState({factuurAddressIsTheSame: !factuurAddressIsTheSame})
                }}/>
            </div>
            {(invoiceAddress && !factuurAddressIsTheSame) ? <AccountAddress canHasDefault={false} address={invoiceAddress} onChange={this.handleChangeInvoiceAddress}
                                                                            onSave={this.handleSubmitUser}
            /> : ''}

                <Header><Translate value="user_delivery_address"/></Header>
            {currentUser && currentUser.deliveryAddress.sort(this.sortDeliveryAddress).map((a) => {

                let selected= a.id === deliveryId;
                if( a.address ) {
                    hasAddress = true;
                }

                return <AccountAddress address={a} loading={this.state.loading}
                        onChange={this.handleChangeDeliveryAddress} onSave={this.handleSubmitUser}
                        onSelect={selected ? null : this.handleSelectDeliveryAddress} selected={selected}
                        showEditButton={true}
                                       onDelete={this.handleDeleteDeliveryAddress}
                        disabled={!selected}
                />
            })}

            <div style={{overflow: 'auto'}}>
            <div style={{float: 'left', overflow: 'auto'}}>
                <Button icon labelPosition='left' onClick={this.handleAddDeliveryAddress} >
                    <Icon name='add'/>
                    <Translate value='add'/>
                </Button>
            </div>
            </div>

            <div style={{textAlign: 'center'}}>
                <Button type="button" size='large' color='red' onClick={() => this.order(deliveryId, invoiceId)}
                        disabled={!hasAddress || this.state.ordered || deliveryId == null || invoiceId == null || !currentUser.deliveryAddress.some(d => d.id === deliveryId)}>
                    {this.state.ordered ? <Translate value='ordered'/> : <Translate value='order'/>}
                </Button>
            </div>

            <Modal size='tiny' closeIcon={true} open={this.state.thanks} onClose={() => {
                this.props.history.push('/project/'+project.id)
            }}>
                <Modal.Header style={{textAlign: 'center', fontSize: '3em'}}><Translate value="project_thanks_order"/></Modal.Header>
                <Modal.Content style={{textAlign: 'center'}} >
                    <Header><Translate value="project_thanks_we_start_immediatly"/></Header>
                    <Image centered style={{aligned: 'center'}} wrapped size='medium' src='/images/undraw_To_the_stars_qhyy.svg' />
                </Modal.Content>
            </Modal>
        </main>
    }
}

const mapStateToProps = (state) => {
    return {
        currentUser: state.main.currentUser,
        lang: state.main.lang,
        project: state.project.project,
        deliveryId: state.project.deliveryId,
        invoiceId: state.project.invoiceId,
    }
}

export default connect(mapStateToProps)(withRouter(ProjectOrderModal));