import React, {Component} from 'react';
import './App.css';
import {Button, Icon, Input, Label, Loader, Table} from "semantic-ui-react";
import connect from "react-redux/es/connect/connect";
import {getEmployeeHours, saveEmployeeHours} from "./actions";
import DatePicker from "react-datepicker";
import { registerLocale, setDefaultLocale } from  "react-datepicker";
import nl from 'date-fns/locale/nl';
import PropTypes from "prop-types";
registerLocale('nl', nl);
setDefaultLocale('nl');

var moment = require('moment');
moment.locale('nl');

class EmployeeHoursDay extends Component {

    state={
        saved: false,
        timestampStart: '',
        timestampStop: '',
        commuteCost: null
    }

    componentDidMount() {
        this.setState({saved: false, timestampStart: '', timestampStop: ''})
        let existinghours = this.props.existinghours;
        if(existinghours.length){
            this.setState({saved: true, timestampStart: new Date(existinghours[0].startDate), timestampStop: new Date(existinghours[0].endDate)});
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.datum !== prevProps.datum){
            this.setState({saved: false, timestampStart: '', timestampStop: ''})
            let existinghours = this.props.existinghours;
            if(existinghours.length){
                this.setState({saved: true, timestampStart: new Date(existinghours[0].startDate), timestampStop: new Date(existinghours[0].endDate)});
            }
        }
    }

    render() {
        let existinghours = this.props.existinghours;

        let commuteCost = this.state.commuteCost !== null ? this.state.commuteCost : this.props.commuteCost

        return (<>
            <Table.Cell style={{padding: 0}}>
                <div style={{display: 'flex', verticalAlign: 'middle'}}>
                    <Label style={{width: 38, position: 'relative', top: 0, left: 0, display: 'block', borderRadius: 0}}
                           content={this.props.datum.getDate()}/>

                    <div>
                        <DatePicker
                            title={'Starttijd'}
                            onKeyDown={(e) => e.preventDefault()}
                            selected={this.state ? this.state.timestampStart : ''}
                            onChange={date => {
                                let helpdate = new Date(this.props.datum.getFullYear(), this.props.datum.getMonth(), this.props.datum.getDate(),date.getHours(),date.getMinutes())
                                this.setState({timestampStart: helpdate, saved: false, dirty: true})
                                if( this.endDateInput ) {
                                    this.endDateInput.setOpen(true)
                                }
                            } }
                            showTimeSelect
                            showTimeSelectOnly
                            timeFormat="p"
                            locale="nl"
                            timeIntervals={15}
                            timeCaption="Time"
                            dateFormat="HH:mm"
                        />
                        -
                        <DatePicker
                            title={'Eindtijd'}
                            onKeyDown={(e) => e.preventDefault()}
                            selected={this.state ? this.state.timestampStop : ''}
                            onChange={date => {
                                let helpdate = new Date(this.props.datum.getFullYear(), this.props.datum.getMonth(), this.props.datum.getDate(),date.getHours(),date.getMinutes())
                                this.setState({timestampStop: helpdate, saved: false, dirty: true})
                                if(this.state.timestampStart !== ''){

                                }
                            }}
                            showTimeSelect
                            showTimeSelectOnly
                            timeFormat="p"
                            locale="nl"
                            timeIntervals={15}
                            timeCaption="Time"
                            dateFormat="HH:mm"
                            ref={(input) => { this.endDateInput = input; }}
                        />

                        <div style={{width: '100%', paddingLeft: 4, paddingRight: 4, marginBottom: 4}} title={'Reiskosten'}>
                            <Input fluid iconPosition='left' placeholder='Reiskstn' type='number' step={0.01} value={commuteCost ? commuteCost : ''}
                                   pattern="^\d+(?:\.\d{1,2})?$"
                                onChange={(e) => this.setState({
                                    commuteCost: e.target.value, saved: false, dirty: true
                                })}
                            >
                                <Icon name='euro' />
                                <input/>
                            </Input>
                        </div>

                    </div>
                <Button icon= {this.state.saved ? 'trash' : 'save'}
                        disabled={this.state.saved ? !this.props.deletebuttons || this.props.frozen : this.props.frozen}
                        color={this.state.saved ? 'red' : ''}
                        primary={this.state.dirty}
                        onClick={() => {
                            if(this.state.timestampStart !== '' && this.state.timestampStop !== ''){
                                if(existinghours.length){
                                    if(this.state.saved){
                                        this.props.dispatch(saveEmployeeHours({
                                            id: existinghours[0].id,
                                            commuteCost: 0
                                        }))

                                        this.setState({commuteCost: 0})
                                    }
                                    else {
                                        this.props.dispatch(saveEmployeeHours({
                                            id: existinghours[0].id,
                                            startDate: moment(this.state.timestampStart).format('YYYY-MM-DD HH:mm:ss'),
                                            endDate: moment(this.state.timestampStop).format('YYYY-MM-DD HH:mm:ss'),
                                            commuteCost
                                        }))
                                        this.setState({saved: true})
                                    }
                                }
                                else{
                                    this.props.dispatch(saveEmployeeHours({
                                        startDate: moment(this.state.timestampStart).format('YYYY-MM-DD HH:mm:ss'),
                                        endDate: moment(this.state.timestampStop).format('YYYY-MM-DD HH:mm:ss'),
                                        commuteCost
                                    }))
                                }
                                this.setState({saved: true, dirty: false})
                        }
                    }}
                />
                </div>

            </Table.Cell>
            </>
        );
    }
}

EmployeeHoursDay.propTypes = {
    datum: PropTypes.instanceOf(Date),
    existinghours: PropTypes.array,
    frozen: PropTypes.bool,
    deletebuttons: PropTypes.bool,
    commuteCost: PropTypes.number
};

EmployeeHoursDay.defaultProps = {
    datum: new Date(),
    existinghours: null,
    frozen : false,
    deletebuttons: false,
    commuteCost: null
};

const mapStateToProps = (state) => {
    return {
        lang: state.main.lang,
        currentUser: state.main.currentUser
    }
}

export default connect(mapStateToProps)(EmployeeHoursDay)