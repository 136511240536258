import React from 'react'
import PropTypes from "prop-types";
import {connect} from "react-redux";
import ChatUserView from "./ChatUserView";
import {Dropdown, Icon, Input, Popup, TextArea} from "semantic-ui-react";
import {
    changeChatAddListItem,
    changeChatValue,
    deleteChatMessage, getChatMentionable,
    openChatMentionable,
    updateChatMessage
} from "../actions";
import {isEmployee} from "../actions/functions";
import Translate, {getText} from "../Translate";
import {renderSupplier} from "../bom/functions";
import UploadFile from "../oldui/UploadFile";
import TextareaAutosize from "react-textarea-autosize";

function removeTypedMentionFromText(text) {
    if( text.startsWith('@') && !text.includes(' ') ) {
        return '';
    } else if( text.startsWith('@') && text.includes(' ') ) {
        const from = text.indexOf(' ');
        return text.substr(from);
    }
    return text;
}

class ChatInput extends React.Component {

    state = {
        focus: false,
    }

    constructor(props) {
        super(props);

        this.wrapperRef = React.createRef();
        //this.setWrapperRef = this.setWrapperRef.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    /**
     * Alert if clicked on outside of element
     */
    handleClickOutside(event) {
        if (this.state.focus && this.wrapperRef && (event != null && !this.wrapperRef.current.contains(event.target))) {
            const c = this.props.value

            if( c.dirty ) {
                this.props.dispatch(updateChatMessage(c.id, c.text, c.ref, c.to ? c.to.id : null, c.important, c.importantHigh, c.planning))
            }

            setTimeout(() => {
                this.setState({focus: false})
            }, 99)
        }

    }

    getDefaultReplies(key, text) {

        let list = getText(this.props, key)

        let res = list.split('\n').filter(item => !!item).map((item, index) => {
            return {key: index, text: item, value: item}
        })

        if( text && text !== '' ) {
            res = res.filter(item => item.text.toLowerCase().includes(text.toLowerCase()))
        }

        return res;
    }

    getMentionable(admin) {
        if( admin ) {
            if (this.props.mention) { //todo: WIP message customer
                return [...this.props.mentionable, {...this.props.mention, isKlant: true}];
            }
            return this.props.mentionable;
        }
        return []
    }

    render() {

        if( !this.props.currentUser ) {
            return <></>
        }

        const employee = isEmployee(this.props.currentUser);
        const fromCurrentUser = this.props.value.from ? this.props.currentUser.id === this.props.value.from.id : false;
        const c = this.props.value
        const compact = this.props.compact

        const old = c.date < (new Date().getTime() - (1000 * 60 * 1));

        const disabled = !employee && (!fromCurrentUser || old);

        const firstSpaceOrEnd = (0 < c.text.indexOf(' ') && c.text.indexOf(' ') < c.text.length) ? c.text.indexOf(' ') - 1 : c.text.length;
        const filterValue = c.text.substr( c.text.indexOf('@') + 1, firstSpaceOrEnd );

        const actions = [
            <>
                {(employee && fromCurrentUser) ? <Popup position='top right' wide='very' trigger={
                    <UploadFile url={'/api/chat/upload?id=' + c.id}
                                multiple={true}
                                onResult={(body) => {
                                    console.log(JSON.stringify(body))
                                    this.setState({uploading: false})
                                    if( !body.error && body && body[0] ) {
                                        this.props.dispatch(changeChatAddListItem(c.id, 'attachments', body[0]))
                                    }
                                }}
                                onFileHover={() => this.setState({hover: true})}
                                onFileHoverEnd={() => this.setState({hover: false})}
                                onStart={() => this.setState({uploading: true})}
                    >
                        <Popup position='top right' wide='very' trigger={
                            <Icon name='attach' link circular size='small' loading={this.state.uploading} inverted={this.state.hover} color={this.state.hover ? 'green' : null}/>
                        }>
                            Bijlage toevoegen
                        </Popup>
                    </UploadFile>
                }>
                    Attachments
                </Popup> : null}
            </>
,
            <>
                {employee ? <Popup position='top right' wide='very' trigger={
                    <Icon name='exclamation triangle' link circular size='small' color={c.importantHigh ? 'red' : ''}
                          onClick={() => {
                              this.props.dispatch(changeChatValue(c.id, 'importantHigh', !c.importantHigh))
                              if (this.inputRef) {
                                  setTimeout(() => {
                                      this.inputRef.focus()
                                  }, 100)
                              }
                          }}
                    />
                }>
                    Markeren als HEEL belangrijk
                    {c.importantHighRemovedBy ? <div>
                        Markering verwijderd door: {c.importantHighRemovedBy.fullName}
                    </div> : null}
                </Popup> : null}
            </>

,
            <>
                {employee ? <Popup position='top right' trigger={
                    <Icon name='exclamation' link circular size='small' color={c.important ? 'red' : ''}
                          onClick={() => {
                              this.props.dispatch(changeChatValue(c.id, 'important', !c.important))
                              if (this.inputRef) {
                                  setTimeout(() => {
                                      this.inputRef.focus()
                                  }, 100)
                              }
                          }}
                    />
                }>
                    Markeren als belangrijk
                </Popup> : null}
            </>
            ,
            <>
                {employee ? <Popup position='top right' trigger={
                    <Icon name='clock' link circular size='small' color={c.planning ? 'blue' : ''}
                          onClick={() => {
                              this.props.dispatch(changeChatValue(c.id, 'planning', !c.planning))
                              if (this.inputRef) {
                                  setTimeout(() => {
                                      this.inputRef.focus()
                                  }, 100)
                              }
                          }}
                    />
                }>
                    Markeren voor projectplanning
                </Popup> : null}
            </>
,
            <>
                {employee ? <Popup position='top right' trigger={
                        <Icon name='trash' circular size='small' link onClick={() => {
                            this.props.dispatch(deleteChatMessage(c.id))
                            if (this.inputRef) {
                                setTimeout(() => {
                                    this.inputRef.focus()
                                }, 100)
                            }
                        }}/>
                    }>
                        Verwijderen
                    </Popup>
                    : ''}
            </>

,
            <>
                {!disabled ? <Popup position='top right' trigger={
                    <Icon name='save' circular size='small' link
                          onClick={() => this.props.dispatch(updateChatMessage(c.id, c.text, c.ref, c.to ? c.to.id : null, c.important, c.importantHigh, c.planning))}/>
                }>
                    <Translate value="save"/>
                </Popup> : null}
            </>

        ]

        return <div ref={this.wrapperRef} style={{display: 'flex', gap: 4, lineHeight: '17.5px'}}
                    onFocus={() => this.setState({focus: true})}>
            {this.props.renderFrom ? <ChatUserView user={c.from} type={compact ? "icon" : "name"} date={c.date}/> : ''}

            <Dropdown upward={true} item icon={null} open={this.props.mentionableOpen === c.id && !c.to} text={<></>}
                      options={
                          this.getMentionable(employee).filter(u => {
                              if (filterValue) {
                                  return u.firstName.toLowerCase().includes(filterValue.toLowerCase())
                              }
                              return true
                          }).map(u => ({
                              key: u.id, text: <>
                                  {u.firstName} {u.isKlant ? renderSupplier({supplier: 'KLANT'}) : ''}
                              </>, value: u.id, u: u, onClick: (e, d) => {
                                  const text = c.text.replaceAll('@' + filterValue, '').trim()
                                  this.props.dispatch(updateChatMessage(c.id, text, c.ref, d.u ? d.u.id : null, c.important, c.importantHigh, c.planning))
                                  if (this.inputRef) {
                                      setTimeout(() => {
                                          this.inputRef.focus()
                                      }, 100)
                                  }
                              }
                          }))
                      }/>

            {(c.to && employee) ? <div style={{color: '#2e84cc'}}>
                {c.to ? '@' + c.to.firstName : ''}
                {(c.to && employee) ? <Icon name='close' link inline onClick={() => {
                    this.props.dispatch(changeChatValue(c.id, 'to', null))
                }}/> : ''}
            </div> : null}

            <div style={{flex: 1}}>
                {this.renderInput(c, employee, disabled, !old)}

                {(employee && this.props.showDefaultReplies) ? <Dropdown style={{}} open={this.state.focus} openOnFocus={false} trigger={''} text={<></>} icon={null} options={
                    this.getDefaultReplies(this.props.showDefaultReplies, c.text)
                } onChange={(e,d) => {
                    if( d.value ) {
                        const c = this.props.value
                        this.props.dispatch(changeChatValue(c.id, 'text', d.value))
                        this.props.dispatch(updateChatMessage(c.id, d.value, c.ref, c.to ? c.to.id : null, c.important, c.importantHigh, c.planning))
                    }
                }} fluid/> : ''}
            </div>

            {compact ? <Dropdown label={<Icon/>} direction='left'>
                <Dropdown.Menu direction='down'>
                    {actions.map(a => <Dropdown.Item>{a}</Dropdown.Item>)}
                </Dropdown.Menu>
            </Dropdown> : <>
                {actions}
            </>}
        </div>
    }

    renderInput(c, admin, disabled, autoFocus = false) {
        if( !admin && disabled ) {
            return <div style={{whiteSpace: "pre"}}>
                {c.text}
            </div>
        }
        return <TextareaAutosize value={c.text} transparent={true} loading={c.busy} fluid disabled={disabled}
                         style={{width: '100%', border: 'none', marginTop: 2, background: 'transparent'}}
                      autoFocus={this.props.enableAutoFocus && autoFocus}
                      placeholder={admin ? 'Schrijf eerst een ~ voor berichten aan de klant' : 'Schrijf een bericht'}
                      ref={(ref) => this.inputRef = ref}
                      className={c.dirty ? 'dirty noopacity' : 'noopacity'}
                      onChange={(e) => {
                          console.log("\"" + e.target.value + "\"");
                          this.props.dispatch(changeChatValue(c.id, 'text', e.target.value))
                          if (this.inputRef) {
                              this.inputRef.focus()
                          }
                      }}
                      onKeyUp={(e) => {
                          if (e.keyCode === 50 || e.target.value.startsWith('@')) {
                              this.props.dispatch(getChatMentionable())
                              this.props.dispatch(openChatMentionable(c.id))
                          } else if (e.keyCode === 32 || e.keyCode === 8) {
                              this.props.dispatch(openChatMentionable(false))
                          } else if (e.keyCode === 13) {
                              this.props.dispatch(updateChatMessage(c.id, c.text, c.ref, c.to ? c.to.id : null, c.important, c.importantHigh, c.planning))
                          }
                      }}
                      onFocus={() => {
                          this.setState({focus: true})
                      }}
                      onBlur={() => {
                          //console.log('SAVE!')
                          //setTimeout(() => {
                          //    this.props.dispatch(updateChatMessage(c.id, c.text, c.ref, c.to ? c.to.id : null))
                          //}, 200)

                          setTimeout(() => {
                              this.setState({focus: false})
                          }, 100)
                      }}
        />;
    }
}


ChatInput.propTypes = {
    value: PropTypes.object.isRequired,
    chatId: PropTypes.number,
    showInput: PropTypes.bool,
    renderFrom: PropTypes.bool,
    showDefaultReplies: PropTypes.string,
    mention: PropTypes.object,
    compact: PropTypes.bool,
    enableAutoFocus: PropTypes.bool,
};

ChatInput.defaultProps = {
    value: null,
    chatId: null,
    showInput: false,
    renderFrom: true,
    showDefaultReplies: null,
    mention: null,
    compact: false,
    enableAutoFocus: false,
};

const mapStateToProps = (state) => {
    return {
        currentUser: state.main.currentUser,
        chatOverview: state.chat.chatOverview,
        mentionableOpen: state.chat.mentionableOpen,
        mentionable: state.chat.mentionable,
        lang: state.main.lang,
        currentLang: state.main.currentLang
    }
}

export default connect(mapStateToProps)(ChatInput)