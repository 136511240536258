import React, {Component} from 'react';
import './App.css';
import {
    Button,
    Confirm, Divider,
    Header,
    Image,
    Modal, Dropdown
} from "semantic-ui-react";
import connect from "react-redux/es/connect/connect";
import {
    addLineProductionRelease, cancelProductionRelease,
    changeProjectCalculatorValue, fetchProject,
    fetchProjectEvents, getBomOrders,
    getProductionRelease,
    getProjectModification,
    getProjectOrderPcbInfo, openBomOrder, removeLineProductionRelease,
    saveProject, setProjectParkedStatus, setValueProductionRelease, submitProductionRelease
} from "./actions";
import {
    isEmployee
} from "./actions/functions";
import Translate, {getText} from "./Translate";
import Calculator from "./oldui/Calculator";
import {Link, withRouter} from "react-router-dom";
import AccountAddress from "./oldui/AccountAddress";
import SideBar from "./bom/SideBar";
import ChatView from "./chat/ChatView";
import BasicSheet from "./basic/BasicSheet";
import BasicProjectMenu from "./basic/BasicProjectMenu";
import BomMicroPlacer from "./bom/BomMicroPlacer";
import ProjectEventView from "./oldui/ProjectEventView";
import ProtoFotoView from "./ProtoFotoView";
import ProjectChanges from "./ProjectChanges";
import PropTypes from "prop-types";
import ProjectListOfChange from "./ProjectListOfChange";


class ProjectSteps extends Component{

    state= {
        activeName: 'aanvraag'
    }

    componentDidMount() {
        this.renderAction = this.renderAction.bind(this);
        this.handleChange = this.handleChange.bind(this);

        if (this.props.project) {
            this.props.dispatch(fetchProjectEvents(this.props.project.id));
            this.props.dispatch(getProductionRelease(this.props.project.id));
            this.props.dispatch(getProjectOrderPcbInfo(this.props.project.id));
            this.props.dispatch(getProjectModification(this.props.project.id))
        }

        if( this.props.view ) {
            this.setState({activeName: this.props.view})
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.renderAction = this.renderAction.bind(this);
        this.handleChange = this.handleChange.bind(this);

        if ((!prevProps.project && this.props.project) ||
            (prevProps.project && this.props.project && prevProps.project.id !== this.props.project.id)
        ) {
            this.props.dispatch(fetchProjectEvents(this.props.project.id));
            this.props.dispatch(getProductionRelease(this.props.project.id));
            this.props.dispatch(getProjectOrderPcbInfo(this.props.project.id));
            this.props.dispatch(getProjectModification(this.props.project.id))
        }

        if( prevProps.view !== this.props.view ) {
            this.setState({activeName: this.props.view})
        }
    }

    saveProject( value, args ) {

        this.props.dispatch(saveProject(value, args, () => {
            if( args && args.action === "ORDER" ) {
                this.setState({thanks: true})
            }
            this.setState({cancelConfirm: false})
        }))
    }

    renderAction() {

        if(!this.props) {
            return <div></div>
        }

        let project= this.props.project;
        let saving= this.props.savingProject;

        if(!project) {
            return <div></div>
        }

        let hasLegacyAddress= !project.deliveryAddress && !project.invoiceAddress && project.owner.deliveryAddress != null && project.owner.deliveryAddress.length > 0 && project.owner.deliveryAddress[0] !== "";

        let canCancel;
        let canOrder;


        canCancel = project.analysis === null || project.analysis === "notstarted" || project.analysis === "active" || project.analysis === "ready";
        canOrder = project.analysis === "ready";


        return <div>

            {canCancel && <Button type="button" size='large' title='Project aanvraag annuleren' loading={saving} disabled={saving || this.state.busy} positive={this.state.saved} onClick={() => {
                this.setState({cancelConfirm: true})
            }}>{this.state.busy ? getText(this.props, "moment_please") : this.state.saved ? getText(this.props, "canceled") : getText(this.props, "cancel")}</Button>}

            {canOrder && <Link to={'/order/'+project.id}>
                <Button type="button" size='large' color='red'  data-tip data-for='global' loading={saving} disabled={saving || this.state.busy} positive={this.state.saved} style={{marginLeft: 16}}>
                    {this.state.busy ? getText(this.props, "moment_please") : this.state.saved ? getText(this.props, "ordered") : getText(this.props, "order")}
                </Button>
            </Link>}

            <div style={{textAlign: 'left'}}>
                {project.deliveryAddress ? <div>
                    <Header><Translate value="user_delivery_address"/></Header>
                    <AccountAddress address={project.deliveryAddress} displayOnly={false} color={'blue'} canHasDefault={false}
                                    modalText={<Translate value="user_delivery_address"/>}
                                    onSave={(a) => {
                                        console.log('save ' + JSON.stringify(a) )
                                        this.props.dispatch( saveProject({...project, deliveryAddress: a}) );
                                    }}
                                    onChange={(a) => {
                                        console.log(a)
                                    }}
                    />
                </div>: ''}

                {project.invoiceAddress ? <div style={{marginTop: 16}}>
                    <Header><Translate value="user_invoice_address"/></Header>
                    <AccountAddress address={project.invoiceAddress} displayOnly={false} color={'red'} canHasDefault={false}
                                    modalText={<Translate value="user_invoice_address"/>}
                                    onSave={(a) => {
                                        console.log('save ' + JSON.stringify(a) )
                                        this.props.dispatch( saveProject({...project, invoiceAddress: a}) );
                                    }}
                                    onChange={(a) => {
                                        console.log(a)
                                    }}
                    />
                </div>: ''}

                {hasLegacyAddress ? <div><div style={{marginBottom: 14, whiteSpace: 'pre', display: 'inline-block', textAlign: 'left'}}>
                    {
                        project.owner.deliveryAddress.filter(a => a.isDefault).length > 0
                            ?
                        project.owner.deliveryAddress.filter(a => a.isDefault)[0].address
                            :
                        project.owner.deliveryAddress[0].address
                    }
                </div></div> : ''}

            </div>
        </div>
    }

    handleChange = (name, value) => {
        this.props.dispatch(changeProjectCalculatorValue(name, value))
    }


    renderChat() {
        return <BasicSheet header='Chat'>
            <ChatView chatId={this.props.project.chat} showInput={true} isPageView={false}/>
        </BasicSheet>
    }

    renderVrijgave(dispatch, project) {

        return <ProjectListOfChange/>
    }

    renderPane(dispatch, project, admin) {

        switch (this.state.activeName) {
            default:
            case 'aanvraag':
                return <Calculator embed={true} remark={project.calculatorDetails.remark} data={project.calculatorDetails} orderid={project.id}
                            isAdmin={admin} onChange={this.handleChange} locked={!admin} renderAction={() => this.renderAction()}
                            dateAmountLocked={project.orderDate !== null} displayName={false}
                />
           case 'inkoop':
                return <div className={'fullwidthprojectview'} style={{marginTop: -8}} >
                    <div className={'almostfullwidthprojectview'}>
                        <BomMicroPlacer projectOrderId={this.props.project.id} defaultViewId={'Klant'} />
                    </div>
                </div>
            case 'loc':
                return this.renderVrijgave(dispatch, project)
            case 'packingslip':
                return <div>
                    <ProjectEventView header={'Packingslip'} project={project} projectId={project.id} packingslip={true}/>
                </div>;
            case 'invoice':
                return <div>
                    <ProjectEventView header={'Invoice'} project={project} projectId={project.id} invoice={true}/>
                </div>
            case 'chat':
                return <BasicSheet header='Chat'>
                    <ChatView chatId={this.props.project.chat} showInput={true} isPageView={false}/>
                </BasicSheet>
            case 'protofoto':
                return <div style={{marginTop: -16}}>
                    <ProtoFotoView/>
                </div>
            case 'bom':
                return <div className='fullwidthprojectview' style={{marginTop: -8}}>
                    <div className={'almostfullwidthprojectview'}>
                        <BomMicroPlacer projectOrderId={project.id} view={'bom'}/>
                    </div>
                </div>
            case 'bomproductie':
                return <div className='fullwidthprojectview' style={{marginTop: -8}}>
                    <div className={'almostfullwidthprojectview'}>
                        <BomMicroPlacer projectOrderId={project.id} view={'production'}/>
                    </div>
                </div>

        }
    }

    render() {

        let {project}= this.props;
        const admin = isEmployee(this.props.currentUser);

        if( project == null ) {
            return <div/>
        }

        if( !admin ) {
            return <div style={{marginTop: 16}}>

                <BasicProjectMenu
                    menu={[
                        {
                            name: 'aanvraag',
                            item: <>
                                Aanvraag
                                {this.props.project.analysis === 'ready' ? <div className='notification-counter'>1</div> : ''}
                            </>
                        },
                        {
                            name: 'inkoop',
                            item: <>
                                BOM
                                {(this.props.project && this.props.project.bomStats && this.props.project.bomStats.markedLines) ?
                                    <div className='notification-counter'>{this.props.project.bomStats.markedLines}</div> : ''}
                            </>
                        }
                    ]}
                    menuRight={[
                        /*{
                            name: 'chat',
                            item: 'Chat'
                        }*/
                    ]}
                    activeName={this.state.activeName}
                    onTabChange={(name) => this.setState({activeName: name})}
                />

                <Modal size='tiny' closeIcon={true} open={this.state.thanks} onClose={() => this.setState({thanks: false})}>
                    <Modal.Header style={{textAlign: 'center', fontSize: '3em'}}><Translate value="project_thanks_order"/></Modal.Header>
                    <Modal.Content style={{textAlign: 'center'}} >
                        <Header><Translate value="project_thanks_we_start_immediatly"/></Header>
                        <Image centered style={{aligned: 'center'}} wrapped size='medium' src='/images/undraw_To_the_stars_qhyy.svg' />

                    </Modal.Content>
                </Modal>

                <Confirm
                    open={this.state.cancelConfirm}
                    onCancel={() => this.setState({cancelConfirm: false})}
                    onConfirm={() => this.saveProject(project, {action: "CANCEL"})}
                    content={getText(this.props, "project_cancel_order")}
                    cancelButton={getText(this.props, "no")}
                    confirmButton={getText(this.props, "yes")}
                />

                <div style={{marginTop: 16}}>

                    <div className={this.state.activeName === 'inkoop' ? 'fullwidthprojectview' : ''} style={{marginTop: 0, paddingTop: 0}} id='analysis'>
                        {
                            {
                                'aanvraag':
                                    <Calculator embed={true} remark={project.calculatorDetails.remark} data={project.calculatorDetails} orderid={project.id}
                                                isAdmin={false} onChange={this.handleChange} locked={true} renderAction={() => this.renderAction()}
                                                dateAmountLocked={project.orderDate !== null} displayName={false}
                                    />,
                                'inkoop': <div className={'fullwidthprojectview'} style={{marginTop: -8}} >
                                    <div className={'almostfullwidthprojectview'}>
                                        <BomMicroPlacer projectOrderId={this.props.project.id} defaultViewId={'Klant'} />
                                    </div>
                                </div>,
                                'chat': this.renderChat()

                            }[this.state.activeName]
                        }

                    </div>

                </div>
            </div>
        }

        /*if( this.props.view === 'aanvraag' ) {
            return <div style={{marginTop: 16}}>
                <Calculator embed={true} remark={project.calculatorDetails.remark} data={project.calculatorDetails}
                            orderid={project.id}
                            isAdmin={true} onChange={this.handleChange} locked={false}
                            renderAction={() => this.renderAction()}
                            dateAmountLocked={false} displayName={false}
                />
            </div>
        }*/

        return(
            <div style={{marginTop: 16}}>

                {admin && <SideBar/>}

                <Modal open={this.state.parkProjectOpen} closeIcon onClose={() => this.setState({parkProjectOpen: false})}>
                    <Modal.Header>Project parkeren</Modal.Header>
                    <Modal.Content>
                        <p>Weet je zeker dat je dit project wilt parkeren?</p>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button onClick={() => this.setState({parkProjectOpen: false})}>Annuleren</Button>
                        <Button primary onClick={() => {
                            this.props.dispatch(setProjectParkedStatus(this.props.project.id, true, () => {
                                this.setState({parkProjectOpen: false})
                            }))
                        }}>Parkeren</Button>
                    </Modal.Actions>
                </Modal>

                <BasicProjectMenu
                    menu={[
                        { name: '', item: <>
                                <Dropdown icon='bars' simple>
                                    <Dropdown.Menu>
                                        <Dropdown.Item
                                            onClick={() => {
                                                this.setState({parkProjectOpen: true})
                                            }}
                                        >🚗 Project parkeren</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                        </>},
                        { name: 'aanvraag', item: 'Aanvraag'},
                        {name: 'bom', item: 'BOM'},
                        {name: 'orders', item: 'Orders', bstStep: 'Orders'},
                        {name: 'bomproductie', item: 'Productie', bstStep: 'Productie'},
                        {name: 'protofoto', item: 'ProtoFoto'},
                        {name: 'loc', item: <Translate value='production.release'/>},
                        {name: 'packingslip', item: 'Packingslip'},
                        {name: 'invoice', item: 'Invoice'},
                    ]}
                    menuRight={[
                        { name: 'chat', item: 'Chat'}
                    ]}
                    activeName={this.state.activeName}
                    onTabChange={(name) => {
                        if( name === '' ) {
                            //do nothing
                        }
                        else if( name === 'orders' ) {
                            if( this.state.activeName !== 'bom' && this.state.activeName !== 'bomproductie' ) {
                                this.setState({activeName: 'bom'})
                            }
                            this.props.dispatch(openBomOrder(true))
                            if( this.props.print ) {
                                this.props.dispatch(getBomOrders(this.props.print.id))
                            }
                        } else {
                            this.setState({activeName: name})
                        }
                    }}
                />

                <div className={this.state.activeName === 'inkoop' ? 'almostfullwidthprojectview' : ''} style={{marginTop: 16, paddingTop: 0}} id='analysis'>
                    {this.renderPane(this.props.dispatch, this.props.project, admin)}
                </div>

            </div>
        )
    }
}

ProjectSteps.propTypes = {
    sampleNr: PropTypes.string,
    status: PropTypes.string,
    isSerie: PropTypes.bool
};

ProjectSteps.defaultProps = {
    sampleNr: '',
    status: '',
    isSerie: false
};

const mapStateToProps = (state) => {
    return {
        currentUser: state.main.currentUser,
        lang: state.main.lang,
        currentLang: state.main.currentLang,
        project: state.project.project,
        savingProject: state.project.savingProject,
        pcbs: state.project.pcbs,
        releaseProduction: state.project.releaseProduction,
        errorProductionRelease: state.project.errorProductionRelease,
        savingProductionRelease: state.project.savingProductionRelease,
        projectModification: state.project.projectModification,
        errorProjectModification: state.project.errorProjectModification,
        savingProjectModification: state.project.savingProjectModification,
        pcbWapperMode: state.project.pcbWapperMode,
        showTop: state.project.showTop,
    }
}

export default connect(mapStateToProps)(withRouter(ProjectSteps))