import React from 'react';
import PropTypes from 'prop-types';
import connect from "react-redux/es/connect/connect";
import {getLangFromOs} from "./actions/functions";

export const getText = (props, value) => {

    if( props.lang ) {

        let res= props.lang[props.currentLang ? props.currentLang : getLangFromOs()][value];

        return res ? res : value;

    } else {
        return value;
    }

}

export const getTextForLang = (props, value, lang) => {

    if( props.lang ) {

        let res= props.lang[lang ? lang : getLangFromOs()][value];

        return res ? res : value;

    } else {
        return value;
    }

}

export const getTextSubheader = (props, value) => {

    let currentLang= props.currentLang ? props.currentLang : getLangFromOs()

    if( props.lang && props.lang[currentLang] && props.lang[currentLang][value+'_sub'] ) {
        return <div style={{marginBottom: 4, display: 'inline-block'}}>
            <div style={{fontWeight: 'bold'}}>{props.lang[currentLang][value] ? props.lang[currentLang][value] : ""}</div>
            <div style={{fontSize: '.8em', lineHeight: '2em'}}>{props.lang[currentLang][value+'_sub'] ? props.lang[currentLang][value+'_sub'] : ""}</div>
        </div>
    }

    return <div style={{marginBottom: 4, display: 'inline-block', fontWeight: 'bold'}}>
        {(props.lang && props.lang[currentLang] && props.lang[currentLang][value]) ? props.lang[currentLang][value] : ""}
    </div>
}

class Translate extends React.Component {
    static propTypes = {
        tag: PropTypes.oneOfType([
            PropTypes.func,
            PropTypes.string,
        ]),
        value: PropTypes.string.isRequired,
        dangerousHTML: PropTypes.bool,
        className: PropTypes.string,
        style: PropTypes.objectOf(PropTypes.oneOfType([
            PropTypes.number,
            PropTypes.string,
        ])),
    };

    static defaultProps = {
        tag: 'span',
    };

    render() {
        const {
            tag: Tag, value, dangerousHTML, style, className, formatString
        } = this.props;
        let translation = getText(this.props, value);

        if(this.props.formatString) {
            translation = translation.format(...this.props.formatString)
        }

        if (dangerousHTML) {
            return (
                <Tag
                    style={style}
                    className={className}
                    dangerouslySetInnerHTML={{ __html: translation }}
                />
            );
        }
        return <Tag style={style} className={className}>{translation}</Tag>;
    }
}

const mapStateToProps = (state) => {
    return {
        lang: state.main.lang,
        currentLang: state.main.currentLang
    }
}

export default connect(mapStateToProps)(Translate)