import React from 'react';
import connect from "react-redux/es/connect/connect";
import {
    addCompany,
    addCompanyFilter,
    addSharketing,
    addSharketingFilter,
    addSharketingPerson,
    addSharketingSort,
    changeCompanyValue,
    changeSharketingPersonValue,
    changeSharketingValue,
    getCompanyList,
    getSharketingPersonList,
    removeCompanySort,
    removeSharketing,
    removeSharketingSort,
    updateCompany,
    updateSharketingPerson
} from "../actions";
import {
    Button,
    Checkbox,
    Dropdown,
    Form,
    Icon,
    Input,
    Label,
    Menu,
    Modal,
    Popup,
    Table,
    TextArea
} from "semantic-ui-react";
import DatePicker from "react-datepicker";
import {formatDateTime} from "../actions/functions";
import {hideFooter} from "../bom/functions";
import {withRouter} from "react-router-dom";
import moment from "moment";
import { CSVLink, CSVDownload } from "react-csv";
import Bedrijven from "./Bedrijven";
import DeltaTable from "../deltatable/DeltaTable";
import {DeltaTableCell} from "../deltatable/DeltaTableCell";
import CompanyList from "../CompanyList";
import Translate from "../Translate";

const statusList = [
    {key: '1', text: 'Suspect', value: 'SUSPECT'},
    {key: '2', text: 'Prospect', value: 'PROSPECT'},
    {key: '3', text: 'Klant', value: 'KLANT'},
    {key: '4', text: 'Ambassadeur', value: 'AMBASSADEUR'},
    {key: '5', text: 'Afvaller', value: 'AFVALLER'},
]

const renderLabel = (label) => ({
    content: `${label.text}`,
    icon: 'check',
    size: 'mini'
})

class Sharketing extends React.Component {

    state = {
        activeItem: 'sharketing',
        headers: [

            {id: 1,  w: 300, show: true, name: 'user.fullName', text: <Translate value="naam"/>, sortable: true},
            /*{id: 2,  w: 300, show: true, name: 'email', text: 'E-mail', sortable: true},*/
            {id: 3,  w: 240, show: true, name: 'companyList', text: 'Bedrijf', sortable: false},
            {id: 4,  w: 150, show: true, name: 'status', text: 'Status', filterOptions: statusList},

            {id: 5,  w: 240, show: true, name: 'sharketingList.length', text: 'Sharketing', sortable: true},

            {id: 6,  w: 150, show: true, name: 'lastExecuted', text: 'Last 🦈-ting', sortable: true},
            {id: 7,  w: 150, show: true, name: 'nextToBeExecuted', text: 'Next 🦈-ting', sortable: true},


            /*{id: 9,  w: 180, show: true, name: 'user.fullName', text: 'ProtoFlow account', sortable: true},*/
            {id: 10,  w: 60, show: true, name: 'numberOfProjects', text: 'PrQty', sortable: true},
            {id: 11,  w: 150, show: true, name: 'lastAanvraagDate', text: <Translate value="last_aanvraag"/>, sortable: true},
            {id: 12,  w: 150, show: true, name: 'lastWebsiteActivity', text: 'Last 👨🏼‍💻 bezoek', sortable: true},

           /* {id: 13,  w: 180, show: true, name: 'telefoon', text: 'Telefoon', sortable: true},*/
            /*{id: 14,  w: 180, show: true, name: 'mobiel', text: 'Mobiel', sortable: true},
            {id: 15,  w: 180, show: true, name: 'birthday', text: 'Birthday', sortable: true},*/
            /*{id: 16,  w: 250, show: true, name: 'companyListHistory', text: 'Voormalig bedrijf', sortable: false},*/
        ],
        headersSharketing: [

            {id: 1,  w: 280, show: true, name: 'i', text: 'Line', sortable: true},
            {id: 2,  w: 280, show: true, name: 'kvkNumber', text: 'KvK nummer', sortable: false},
            {id: 3,  w: 280, show: true, name: 'name', text: 'Company', sortable: false},
            {id: 4,  w: 280, show: true, name: 'internalName', text: 'ICC', sortable: true},
            {id: 5,  w: 280, show: true, name: 'lastProjectOrderInternalName', text: 'Laatste project', sortable: true},
            {id: 6,  w: 280, show: true, name: 'lastProjectOrderAanvraagDate', text: 'Datum', sortable: true},
            {id: 9,  w: 280, show: true, name: 'companyType', text: 'Type', sortable: true},
            {id: 10, w: 280, show: true, name: 'zip', text: 'Postcode', sortable: true},
            {id: 11, w: 280, show: true, name: 'provincie', text: 'Provincie', sortable: true}
        ]
    }

    getIconFromSharketingType = (name, iconColor, size, sharketing, onClick) => {

        const color = iconColor ? iconColor : 'grey'
        size = size ? size : ''

        let icon;
        switch (name) {
            case 'EMAIL':
                icon = <Icon circular inverted color={color} name='mail outline' link size={size} onClick={onClick}/>
                break;
            case 'CALL':
                icon = <Icon circular inverted color={color} name='phone' link size={size} onClick={onClick}/>
                break;
            case 'LINKEDIN_MESSAGE':
                icon = <Icon.Group>
                    <Icon circular inverted color={color} name='linkedin' link size={size}  onClick={onClick}/>
                    <Icon corner='top right' name='chat' color={color}/>
                </Icon.Group>
                break;
            case 'LINKEDIN_INVITE':
                icon = <Icon.Group>
                    <Icon circular inverted color={color} name='linkedin' link size={size}  onClick={onClick}/>
                    <Icon corner='top right' name='handshake' color={color}/>
                </Icon.Group>
                break;
            case 'BROCHURE':
                icon = <Icon circular inverted color={color} name='newspaper' link size={size} onClick={onClick}/>
                break;
            case 'CAKE':
                icon = <Icon circular inverted color={color} name='birthday cake' link size={size} onClick={onClick}/>
                break;
            case 'UNKNOWN':
                icon = <Icon circular inverted color={color} name='smile' link size={size} onClick={onClick}/>
                break;
            default:
                icon = <Icon circular inverted color={color} name='question' link onClick={onClick}/>
                break;
        }

        const alertWhenAfterDueDate = sharketing != null && !sharketing.executed && sharketing.toBeExecuted;
        const isAfterDueDate = (sharketing && !sharketing.executed) ? moment(sharketing.toBeExecuted).isBefore(moment()) : false;

        return <span style={{position: alertWhenAfterDueDate ? 'relative' : null}}>
            {icon}
            {alertWhenAfterDueDate ? <Icon color={isAfterDueDate ? 'red' : 'grey'} style={{position: 'absolute', bottom: -3, left: 'calc(100% - 15px)'}} name='time'/> : null}
        </span>
    }

    sharketingTypeOptions = [
        {key: '1', text: <>{this.getIconFromSharketingType('EMAIL', null,  'tiny')} E-mail</>, value: 'EMAIL'},
        {key: '2', text: <>{this.getIconFromSharketingType('CALL', null, 'tiny')} Bellen</>, value: 'CALL'},
        {key: '3', text: <>{this.getIconFromSharketingType('LINKEDIN_MESSAGE', null, 'tiny')} LinkedIn message</>, value: 'LINKEDIN_MESSAGE'},
        {key: '4', text: <>{this.getIconFromSharketingType('LINKEDIN_INVITE', null, 'tiny')} LinkedIn invite</>, value: 'LINKEDIN_INVITE'},
        {key: '5', text: <>{this.getIconFromSharketingType('BROCHURE', null, 'tiny')} Brochure</>, value: 'BROCHURE'},
        {key: '6', text: <>{this.getIconFromSharketingType('CAKE', null, 'tiny')} Taart</>, value: 'CAKE'},
        {key: '7', text: <>{this.getIconFromSharketingType('UNKNOWN', null, 'tiny')} Unknown</>, value: 'UNKNOWN'}
    ]

    componentDidMount() {
        this.props.dispatch(getSharketingPersonList())
        this.props.dispatch(getCompanyList())
        hideFooter()

        window.addEventListener('mousemove', this.onMouseMove.bind(this), false);
        window.addEventListener('mouseup', this.onMouseUp.bind(this), false);
    }

    componentWillUnmount() {
        window.removeEventListener('mousemove', this.onMouseMove.bind(this), false);
        window.removeEventListener('mouseup', this.onMouseUp.bind(this), false);
    }

    handleChange = (p, event) => {
        this.props.dispatch(changeSharketingPersonValue(p.id, event.target.name, event.target.value))
    }

    renderSharketingItem = (p) => {

        if( !p.expanded ) {
            return <></>
        }

        return <tr >
            <td colSpan={this.state.headers.length}>

                    <table className="ui celled very compact table sharketingitem" style={{marginBottom: 0, marginTop: 8}}>
                        <thead className="">
                        <tr>
                            <th>type</th>
                            <th style={{width: 180}}>executed</th>
                            <th>content</th>
                            <th>color</th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        {p.sharketingList.map(s => {
                            const isAfterDueDate = (s && !s.executed && s.toBeExecuted) ? moment(s.toBeExecuted).isBefore(moment()) : false;

                            return <tr className={s.isNew ? 'positive' : isAfterDueDate ? 'negative' : ''} key={s.id} initial={{height: 0}} animate={{height: '24px'}} transition={{ delay: 1 }}>
                                <td style={{width: 200}}>
                                    <Dropdown fluid transparent placeholder='' options={this.sharketingTypeOptions} onChange={(event, data) => {
                                        const newValue = data.value
                                        this.props.dispatch(changeSharketingValue(p.id, s.id, 'type', newValue))
                                    }} value={s.type}/>
                                </td>
                                <td style={{width: 180, display: 'flex'}}>
                                    <DatePicker selected={s.executed ? s.executed : s.toBeExecuted} customInput={<div style={{paddingTop: 6}}>{formatDateTime(s.executed ? s.executed : s.toBeExecuted)}</div>}
                                                onChange={v => {
                                                    const isFuture = moment(v).isAfter(moment())

                                                    if( isFuture ) {
                                                        this.props.dispatch(changeSharketingValue(p.id, s.id, 'toBeExecuted', v))
                                                        this.props.dispatch(changeSharketingValue(p.id, s.id, 'executed', null))
                                                    } else {
                                                        this.props.dispatch(changeSharketingValue(p.id, s.id, 'executed', v))
                                                        this.props.dispatch(changeSharketingValue(p.id, s.id, 'toBeExecuted', null))
                                                    }
                                                }}
                                                todayButton={"Vandaag"} dateFormat={"d-M-yyyy HH:mm"} showTimeSelect/>
                                    {(s.toBeExecuted && !s.executed) ? <Popup trigger={
                                        <Icon style={{marginTop: 6, marginLeft: 4}} color={isAfterDueDate ? 'red' : 'grey'} name='time'/>
                                    } content={<div>
                                        Uit voeren in de toekomst!
                                        <Button onClick={() => {
                                            this.props.dispatch(changeSharketingValue(p.id, s.id, 'executed', new Date()))
                                        }}>Markeren als uitgevoerd</Button>
                                    </div>} hoverable/> : null}
                                </td>
                                <td>
                                    <Input fluid transparent name={'content'} value={s.content} onChange={e => {
                                        this.props.dispatch(changeSharketingValue(p.id, s.id, 'content', e.target.value))
                                    }}/>
                                </td>
                                <td style={{width: 200}}>
                                    <div style={{display: 'flex', gap: 4}}>
                                        {['green', 'blue', 'orange', 'red'].map(c => <Icon.Group>
                                            <Icon size='big' name='circle outline' color={s.color === c ? 'black' : 'white'} inverted/>
                                            <Icon style={{marginLeft: -1.5}} name='circle' color={c} link onClick={() => this.props.dispatch(changeSharketingValue(p.id, s.id, 'color', c))}/>
                                        </Icon.Group>)}
                                    </div>
                                </td>
                                <td className="collapsing">
                                    <Button icon size='mini'>
                                        <Icon name='trash' onClick={() => this.props.dispatch(removeSharketing(p.id, s.id))}/>
                                    </Button>
                                </td>
                            </tr>
                        })}
                        </tbody>
                    </table>
                <div style={{textAlign: 'right', width: 'calc(100vw - 80px)'}}>
                    <button className='link-button' style={{fontSize: '12px'}}>verbergen</button>
                </div>

            </td>
        </tr>
    }

    getColStyle( id, header ) {

        const m_col= this.state.headers.filter(c => c.id === id)[0];

        return Object.assign({}, m_col.style ? m_col.style : {}, {
            maxWidth: m_col.w,
            minWidth: m_col.w,
            boxShadow: 'none',
            color: 'black',/*
            padding: 'unset'*/
            cursor: (header.sortable || header.filterable) ? 'pointer' : 'unset',
            borderRadius: 'unset',
        })

    }

    renderColResizer( col, value ) {
        return <>
            <>
                {value}
            </>
            <div style={{display: 'inline-block',
                position: 'absolute',
                width: '36px',
                top: 0,
                marginTop: '24px',
                height: 'calc(100% - 24px)',
                right: '-18px',
                cursor: 'col-resize',
                zIndex: 10}}
                 onMouseDown={(e, t) => {
                     this.handleMouseDown(e, t, col.id)
                 }}
            />

        </>
    }

    onMouseMove = (e) => {
        if( this.state.isResizing ) {

            const newWidth =  this.state.startWidth + (e.clientX - this.state.startX);

            this.setState({
                headers: this.state.headers.map((c, i) => {
                    return (c.id === this.state.colResizing) ? Object.assign({}, c, {w: newWidth}) : c
                })
            });

            //this.props.funcResizing( this.props.id, e.clientX, e.clientY);
        }
    }

    onMouseUp = (e) => {
        setTimeout(() => {
            this.setState({
                isResizing: false,
            });
        }, 100)


    }

    handleMouseDown = (event, t, col) => {

        let startWidth= this.state.headers.filter((c, i) => c.id === parseInt(col))[0].w;

        this.setState({
            startX: event.clientX,
            startWidth,
            isResizing: true,
            colResizing: col
        });

    };



    renderSharkTypeIcon = (p, name) => {

    }

    renderShark = (p) => {
        const hasNewSharketing = p.sharketingList.some(s => s.dirty)
       // const dirty = p.dirty || hasNewSharketing;

        return <div style={{display: 'flex', gap: 4}}>

            {p.sharketingList.map(s => {
                return <Popup
                    trigger={this.getIconFromSharketingType(s.type, s.color, 'small', s, () => {
                        this.props.dispatch(changeSharketingPersonValue(p.id, 'expanded', !p.expanded))
                    })}
                    content={<p>
                        Executed: {formatDateTime(s.executed)}<br/>
                        Click to expand
                    </p>}
                />
            })}

            <div style={{flex: 1, textAlign: 'right'}}>
                <Popup trigger={
                    <Dropdown disabled={hasNewSharketing}
                              icon={
                                  <Icon circular name='add' link size='small'/>
                              }
                              options={this.sharketingTypeOptions} text=' ' value={null}
                              onChange={(e,d) => {
                                  console.log(d.value)
                                  this.props.dispatch(addSharketing(p.id, d.value))
                              }}
                    />
                } content={<p>Add new sharketing moment</p>} />
            </div>


        </div>
    }

    displayName = (p) => {
        return p.firstName + ' ' + (p.tussenvoegsel ? p.tussenvoegsel + ' ' : '') + p.lastName
    }

    renderName = (p,i) => {
        let dirty = false;

        return <div style={{display: 'flex'}}>
            <div>{this.displayName(p)}</div>
            <div style={{flex: 1, textAlign: 'right'}}>
                {dirty ? <Popup trigger={
                    <Icon name='save' color={'blue'} link
                          onClick={() => this.props.dispatch(updateSharketingPerson(p))}/>
                } content={<Translate value='save'/>}
                /> : <Icon name='edit' link onClick={() => this.setState({editName: p.id})}/>}

                <Modal open={this.state.editName === p.id} closeIcon onClose={() => this.setState({editName: null})}>
                    <Modal.Header></Modal.Header>
                    <Modal.Content>
                        <Form>
                            <Form.Input fluid label='Voornaam' name={'firstName'} value={p.firstName} onChange={e => this.handleChange(p, e)}/>
                            <Form.Input fluid label='Tussenvoegsel' name={'tussenvoegsel'} value={p.tussenvoegsel} onChange={e => this.handleChange(p, e)}/>
                            <Form.Input fluid label='Achternaam' name={'lastName'} value={p.lastName} onChange={e => this.handleChange(p, e)}/>
                            <Form.Input fluid label='E-mail' name={'email'} value={p.email} onChange={e => this.handleChange(p, e)}/>
                            <Form.Input fluid label='Telefoon' name={'telefoon'} value={p.telefoon} onChange={e => this.handleChange(p, e)}/>
                            <Form.Input fluid label='Mobiel' name={'mobiel'} value={p.mobiel} onChange={e => this.handleChange(p, e)}/>
                            <DatePicker selected={p.birthday} customInput={<Form.Input label='Birthday'/>}
                                        showMonthDropdown showYearDropdown
                                        onChange={v => this.props.dispatch(changeSharketingPersonValue(p.id, 'birthday', v))}
                                        todayButton={"Vandaag"} dateFormat={"d-M-yyyy"}/>
                            <Form.Dropdown label={'Voormalig bedrijf'} icon={null} selection fluid search placeholder='Heeft gewerkt bij' multiple options={this.props.companyList.map(c => (
                                {key: c.id, text: c.name, value: c}
                            ))} onChange={(event, data) => {
                                const newValue = data.value
                                this.props.dispatch(changeSharketingPersonValue(p.id, 'companyListHistory', newValue))
                            }} value={p.companyListHistory}/>
                        </Form>

                    </Modal.Content>
                    <Modal.Actions>
                        <Button primary={dirty} onClick={() => this.props.dispatch(updateSharketingPerson(p, () => {
                            this.setState({editName: null})
                        }))}><Translate value='save'/></Button>
                    </Modal.Actions>
                </Modal>
            </div>
        </div>
    }

    renderSharketing = () => {
        const personList = this.props.sharketingPersonList;
        const sort = this.props.sharketingSort;
        const headers = this.state.headers

        return <>
            <div style={{position: 'absolute', right: 16, top: 108}}>
                <CSVLink filename={'DeltaProto sharketing personen ' + formatDateTime(new Date())} data={personList.map(c => ({
                    ...c,
                    lastExecuted: c.lastExecuted ? formatDateTime( c.lastExecuted ) : '',
                    nextToBeExecuted: c.nextToBeExecuted ? formatDateTime( c.nextToBeExecuted ) : '',
                    lastAanvraagDate: c.lastAanvraagDate ? formatDateTime( c.lastAanvraagDate ) : '',
                    lastWebsiteActivity: c.lastWebsiteActivity ? formatDateTime( c.lastWebsiteActivity ) : '',
                }))}>
                    <Button icon labelPosition='left' >
                        <Icon name={'file'}/>
                        <Translate value="download_csv"/>
                    </Button>
                </CSVLink>

                <Button disabled={personList.some(p => p.isNew)} onClick={() => {
                    this.props.dispatch(addSharketingPerson())
                    this.setState({editName: ''})
                }}><Translate value="add"/></Button>
            </div>

            <div style={{display: 'flex', gap: 8, paddingBottom: 8, height: 34}}>
                {sort.map(s => {
                    const h = headers.find(x => x.name === s.name);
                    if( s.sort ) {
                        return <>
                            <Label color='blue' key={s.id}>
                                {s.sort === 'descending' ? <Icon name='caret down'/> : <Icon name='caret up'/>}
                                <button className='link-button' style={{color: 'white'}}
                                        onClick={() => this.props.dispatch(addSharketingSort(s.name))}>
                                    {h.text}
                                </button>


                                <Icon name='close' link onClick={() => {
                                    this.props.dispatch(removeSharketingSort(s.id))
                                }}/>
                            </Label>
                        </>
                    } else {
                        return <>
                            <Label color='blue' key={s.id}>

                                <Icon name='filter'/>

                                {h.text + " "}

                                <span color='white' style={{backgroundColor: 'white', color: 'black', paddingLeft: 2, paddingRight: 2, borderRadius: '.14rem'}}>
                                    <Dropdown value={s.filter} options={h.filterOptions} onChange={(e,d) => {
                                        this.props.dispatch(addSharketingFilter(s.name, d.value))
                                    }}/>
                                </span>

                                <Icon name='close' link onClick={() => {
                                    this.props.dispatch(removeSharketingSort(s.id))
                                }}/>
                            </Label>
                        </>
                    }

                })}
            </div>

            {false ? <DeltaTable
                name='sharketing'
                columns={[
                    {id: 1,  defaultWidth: 300, show: true, field: 'user.fullName', header: 'Naam', sortable: true, render: this.renderName},
                    /*{id: 2,  defaultWidth: 300, show: true, field: 'email', header: 'E-mail', sortable: true, render: (r) => ''},*/
                    {id: 3,  defaultWidth: 240, show: true, field: 'companyList', header: 'Bedrijf', sortable: false, render: (r) => ''},
                    {id: 4,  defaultWidth: 150, show: true, field: 'status', header: 'Status', filterOptions: statusList, render: (r) => ''},

                    {id: 5,  defaultWidth: 240, show: true, field: 'sharketingList.length', header: 'Sharketing', sortable: true, render: (r) => ''},

                    {id: 6,  defaultWidth: 150, show: true, field: 'lastExecuted', header: 'Last 🦈-ting', sortable: true, render: (r) => ''},
                    {id: 7,  defaultWidth: 150, show: true, field: 'nextToBeExecuted', header: 'Next 🦈-ting', sortable: true, render: (r) => ''},


                    /*{id: 9,  defaultWidth: 180, show: true, field: 'user.fullName', header: 'ProtoFlow account', sortable: true, render: (r) => ''},*/
                    {id: 10,  defaultWidth: 60, show: true, field: 'numberOfProjects', header: 'PrQty', sortable: true, render: (r) => ''},
                    {id: 11,  defaultWidth: 150, show: true, field: 'lastAanvraagDate', header: 'Last aanvraag', sortable: true, render: (r) => ''},
                    {id: 12,  defaultWidth: 150, show: true, field: 'lastWebsiteActivity', header: 'Last 👨🏼‍💻 bezoek', sortable: true, render: (r) => ''},

                    /* {id: 13,  defaultWidth: 180, show: true, field: 'telefoon', header: 'Telefoon', sortable: true, render: (r) => ''},*/
                    /*{id: 14,  defaultWidth: 180, show: true, field: 'mobiel', header: 'Mobiel', sortable: true, render: (r) => ''},
                    {id: 15,  defaultWidth: 180, show: true, field: 'birthday', header: 'Birthday', sortable: true, render: (r) => ''},*/
                    /*{id: 16,  defaultWidth: 250, show: true, field: 'companyListHistory', header: 'Voormalig bedrijf', sortable: false, render: (r) => ''},*/
                ]}
                data={personList}
            /> : null}


            <div id="scrolling" className="tableFixHead sharketingTable">
                <table className="ui celled very compact sortable table" style={{width: 'initial'}}>
                    <thead>
                    <tr>
                        {/* {headers.map(h => {
                                    let sortorder = sort.find(s => s.name === h.name)
                                    let sorted = sortorder ? sortorder.sort : null;

                                    return <Table.HeaderCell sorted={sorted}
                                                             onClick={() => h.sortable && this.props.dispatch(addSharketingSort(h.name))}>
                                        {h.text}
                                    </Table.HeaderCell>
                                })}*/}

                        {headers.map((h,i) => {

                            let sortorder = sort.find(s => s.name === h.name)
                            let sorted = sortorder ? sortorder.filter ? 'filtered' : sortorder.sort : null;

                            return <Table.HeaderCell sorted={sorted} key={i} style={this.getColStyle(h.id, h)}
                                                     onClick={() => !this.state.isResizing && h.sortable && this.props.dispatch(addSharketingSort(h.name))}
                                                     className={'ui ' + h.color + ' message ' + h.className}>
                                {this.renderColResizer(h, h.filterOptions ? <Dropdown text={h.text} icon={null} options={h.filterOptions} onChange={(e,d) => {
                                    this.props.dispatch(addSharketingFilter(h.name, d.value))
                                }}/> : h.text)}
                                {this.state.isResizing && <div style={{
                                    position: 'absolute',
                                    right: '0px',
                                    bottom: '-4px',
                                    fontSize: 12,
                                    fontWeight: 'normal',
                                    color: 'grey'
                                }}>
                                    {headers.find(tc => tc.id === h.id).w}
                                </div>}
                            </Table.HeaderCell>
                        })}

                    </tr>
                    </thead>
                    <tbody>
                    {personList.filter(c => !c.hidden).map(p => {
                        const hasNewSharketing = p.sharketingList.some(s => s.dirty)
                        const dirty = p.dirty || hasNewSharketing;

                        const nextToBeExecutedIsLate = p.nextToBeExecuted && moment(p.nextToBeExecuted).isBefore(moment())

                        return [<tr key={p.id}>

                            <td>

                                <div style={{display: 'flex'}}>
                                    <div>{this.displayName(p)}</div>
                                    <div style={{flex: 1, textAlign: 'right'}}>
                                        {dirty ? <Popup trigger={
                                            <Icon name='save' color={'blue'} link
                                                  onClick={() => this.props.dispatch(updateSharketingPerson(p))}/>
                                        } content={'Opslaan'}
                                        /> : <Icon name='edit' link onClick={() => this.setState({editName: p.id})}/>}

                                        <Modal open={this.state.editName === p.id} closeIcon onClose={() => this.setState({editName: null})}>
                                            <Modal.Header></Modal.Header>
                                            <Modal.Content>
                                                <Form>
                                                    <Form.Input fluid label='Voornaam' name={'firstName'} value={p.firstName} onChange={e => this.handleChange(p, e)}/>
                                                    <Form.Input fluid label='Tussenvoegsel' name={'tussenvoegsel'} value={p.tussenvoegsel} onChange={e => this.handleChange(p, e)}/>
                                                    <Form.Input fluid label='Achternaam' name={'lastName'} value={p.lastName} onChange={e => this.handleChange(p, e)}/>
                                                    <Form.Input fluid label='E-mail' name={'email'} value={p.email} onChange={e => this.handleChange(p, e)}/>
                                                    <Form.Input fluid label='Telefoon' name={'telefoon'} value={p.telefoon} onChange={e => this.handleChange(p, e)}/>
                                                    <Form.Input fluid label='Mobiel' name={'mobiel'} value={p.mobiel} onChange={e => this.handleChange(p, e)}/>
                                                    <DatePicker selected={p.birthday} customInput={<Form.Input label='Birthday'/>}
                                                                showMonthDropdown showYearDropdown
                                                                onChange={v => this.props.dispatch(changeSharketingPersonValue(p.id, 'birthday', v))}
                                                                todayButton={"Vandaag"} dateFormat={"d-M-yyyy"}/>
                                                    <Form.Dropdown label={'Voormalig bedrijf'} icon={null} selection fluid search placeholder='Heeft gewerkt bij' multiple options={this.props.companyList.map(c => (
                                                        {key: c.id, text: c.name, value: c}
                                                    ))} onChange={(event, data) => {
                                                        const newValue = data.value
                                                        this.props.dispatch(changeSharketingPersonValue(p.id, 'companyListHistory', newValue))
                                                    }} value={p.companyListHistory}/>
                                                </Form>

                                            </Modal.Content>
                                            <Modal.Actions>
                                                <Button primary={dirty} onClick={() => this.props.dispatch(updateSharketingPerson(p, () => {
                                                    this.setState({editName: null})
                                                }))}><Translate value="digikey_opslaan"/></Button>
                                            </Modal.Actions>
                                        </Modal>
                                    </div>
                                </div>
                            </td>
                            {/*<td>
                                <Input fluid transparent name={'email'} value={p.email} onChange={e => this.handleChange(p, e)}/>
                            </td>*/}
                            <td>
                                <Dropdown className={'compactdropdown'} style={{padding: 0}} icon={null} loading={this.props.fetchingCompanyList} fluid placeholder='Huidig bedrijf' search multiple options={this.props.companyList.map(c => (
                                        {key: c.id, text: c.name, value: c.id}
                                    ))} onChange={(event, data) => {
                                        const newValue = data.value.map(d => this.props.companyList.find(c => c.id === d))
                                        this.props.dispatch(changeSharketingPersonValue(p.id, 'companyList', newValue))
                                    }} value={p.companyList.filter(c => !!c).map(c => c.id)}
                                        allowAdditions additionLabel='Toevoegen: ' onAddItem={(event, data) => {
                                        this.props.dispatch(updateCompany({name: data.value}, (response) => {
                                            const newValue = p.companyList ? [...p.companyList, response] : [response]
                                            this.props.dispatch(changeSharketingPersonValue(p.id, 'companyList', newValue))
                                        }))
                                    }}
                                />
                            </td>
                            <td>
                                <Dropdown fluid transparent placeholder='' options={statusList} onChange={(event, data) => {
                                    const newValue = data.value
                                    this.props.dispatch(changeSharketingPersonValue(p.id, 'status', newValue))
                                }} value={p.status}/>
                            </td>
                            <td>

                                {this.renderShark(p)}

                            </td>
                            <td>
                                {formatDateTime(p.lastExecuted)}
                            </td>
                            <td style={{color: nextToBeExecutedIsLate ? 'red' : null}}>
                                {formatDateTime(p.nextToBeExecuted)}
                            </td>
                            {/*<td>
                                {p.user ? <button className='link-button' onClick={() => {
                                    this.props.history.push('/account/' + p.user.id);
                                }}>{p.user.fullName}</button> : ''}
                            </td>*/}
                            <td>
                                {p.numberOfProjects}
                            </td>
                            <td>
                                {formatDateTime(p.lastAanvraagDate)}
                            </td>
                            <td>
                                {formatDateTime(p.lastWebsiteActivity)}
                            </td>

                            {/*<td><Input fluid transparent name={'telefoon'} value={p.telefoon} onChange={e => this.handleChange(p, e)}/></td>*/}
                            {/*<td><Input fluid transparent name={'mobiel'} value={p.mobiel} onChange={e => this.handleChange(p, e)}/></td>
                            <td>
                                <DatePicker selected={p.birthday} customInput={<Input transparent/>}
                                            showMonthDropdown showYearDropdown
                                            onChange={v => this.props.dispatch(changeSharketingPersonValue(p.id, 'birthday', v))}
                                            todayButton={"Vandaag"} dateFormat={"d-M-yyyy"}/>
                            </td>*/}
                            {/*<td>
                                <Dropdown className={'compactdropdown'} style={{padding: 0}} icon={null} fluid transparent search placeholder='Heeft gewerkt bij' multiple options={this.props.companyList.map(c => (
                                    {key: c.id, text: c.name, value: c}
                                ))} onChange={(event, data) => {
                                    const newValue = data.value
                                    this.props.dispatch(changeSharketingPersonValue(p.id, 'companyListHistory', newValue))
                                }} value={p.companyListHistory}/>
                            </td>*/}
                            <div style={{position: 'fixed', marginTop: 8, right: 0, display: (p.isNew || dirty) ? '' : 'none'}}>
                                <Button size={"tiny"} primary={dirty} onClick={() => this.props.dispatch(updateSharketingPerson(p))}><Translate value="digikey_opslaan"/></Button>
                            </div>
                        </tr>, this.renderSharketingItem(p)]
                    })}
                    </tbody>
                </table>
            </div>
        </>
    }

    renderBedrijven = () => {
        return <CompanyList/>
    }

    render() {


        const activeItem = this.state.activeItem


        return (
            <main id={'sharketing'} className={'pageContainerFullWidth sharketing'}>
                <div>
                    <Menu secondary style={{margin: 'auto', width: 210}}>
                        <Menu.Item
                            name='Personen'
                            active={activeItem === 'sharketing'}
                            onClick={() => {
                                this.setState({activeItem: 'sharketing'})
                            }}
                        />
                        <Menu.Item
                            name='Bedrijven'
                            active={activeItem === 'bedrijven'}
                            onClick={() => {
                                this.setState({activeItem: 'bedrijven'})
                                this.props.dispatch(getCompanyList())
                            }}
                        />
                    </Menu>
                </div>

                {activeItem === 'sharketing' ? this.renderSharketing() : <Bedrijven/>}

            </main>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        lang: state.main.lang,
        currentUser: state.main.currentUser,
        sharketingPersonList: state.main.sharketingPersonList,
        companyList: state.main.companyList,
        fetchingCompanyList: state.main.fetchingCompanyList,
        sharketingSort: state.main.sharketingSort,
    }
}

export default connect(mapStateToProps)(withRouter(Sharketing))