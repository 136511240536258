import React, { Component } from 'react';
import connect from "react-redux/es/connect/connect";
import PropTypes from "prop-types";
import {fetchPrice, fetchPriceByOrderId} from "../actions";
import ReactTextTransition, {presets} from "react-text-transition";

class CalculatorPriceView extends Component {

    state = {
        priceOptions: null
    }

    componentDidMount() {
        if( this.props.orderId && this.props.numPcbs && this.props.selectedDays ) {
            console.log('1 fetchPriceByOrderId ' + this.props.selectedDays)
            this.props.dispatch(fetchPriceByOrderId(this.props.orderId, this.props.numPcbs, this.props.selectedDays))
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if ((!prevProps.project && this.props.project) ||
            (prevProps.project && this.props.project && prevProps.project.id !== this.props.project.id) ||
            (this.props.project && (prevProps.numPcbs !== this.props.numPcbs)) ||
            (this.props.project && (prevProps.selectedDays !== this.props.selectedDays))
        ) {
            if( this.props.orderId ) {
                console.log('2 fetchPriceByOrderId ' + this.props.selectedDays)
                this.props.dispatch(fetchPriceByOrderId(this.props.orderId, this.props.numPcbs, this.props.selectedDays))
            } else if( this.props.data ) {
                this.props.dispatch(fetchPrice(this.props.data, null, (data) => {
                    this.setState({priceOptions: data})
                }))
            }
        }
    }

    getPriceFromList(orderId, numPcbs, selectedDays) {

        let price= null;

        var timestamp = new Date().getTime() - (30 * 60 * 1000)

        let current= this.props.priceList.filter(p => {
            if( p.orderId === orderId && p.numPcbs === numPcbs && p.selectedDays === selectedDays ) {
                return true;
            }
            return false;
        })

        if( current.length && !current[0].loading && current[0].date && timestamp < current[0].date  ) {

            price= current[0].value;
        }

        return price;
    }

    render() {

        let price= null;//this.getPriceFromState(this.props.orderId, this.props.numPcbs, this.props.selectedDays);

        if( this.props.orderId ) {
            price= this.getPriceFromList(this.props.orderId, this.props.numPcbs, this.props.selectedDays);
        } else if( this.props.calculatorPrice ) {
            this.props.calculatorPrice.forEach((p) => {
                if( p.days === this.props.selectedDays && p.amount === this.props.numPcbs ) {
                    price= p.price;
                }
            })
        }

        price = price ? price.toLocaleString("nl-NL") : '';

        return <div style={{display: 'inline'}}>
            {/*{'€ '}*/}
            {price.split("").map((c,i) => <ReactTextTransition
                text={c}
                inline key={i}
            />)}
            {(price ? ',-' : '')}
        </div>


    }
}

CalculatorPriceView.propTypes = {
    data: PropTypes.any,
    orderId: PropTypes.string,
    numPcbs: PropTypes.number,
    selectedDays: PropTypes.number,
};

CalculatorPriceView.defaultProps = {
    data: null,
    orderId: null,
    numPcbs: null,
    selectedDays: null
};

const mapStateToProps = (state) => {
    return {
        calculatorPrice: state.project.calculatorPrice,
        priceList: state.project.priceList
    }
}

export default connect(mapStateToProps)(CalculatorPriceView)