import React from 'react'
import PropTypes from "prop-types";
import {PI_CAMERA_HEIGHT, PI_CAMERA_WIDTH, WEBCAM_CAMERA_HEIGHT, WEBCAM_CAMERA_WIDTH} from "./BomCamera";

class CrossHair extends React.Component {
    render() {

        let {x, y, stroke, size, strokeWidth} = this.props

        let w = size / 2;

        return <>
            <line x1={`${x-w}`} y1={`${y-0}`} x2={`${x+w}`} y2={`${y+0}`} strokeWidth={strokeWidth} stroke={stroke}/>
            <line x1={`${x-0}`} y1={`${y-w}`} x2={`${x+0}`} y2={`${y+w}`} strokeWidth={strokeWidth} stroke={stroke}/>
        </>
    }
}

export default class CrosshairSvg extends React.Component{

    state = {
        scale: 1,
    }

    componentDidMount() {

    }

    render() {

        let {x,y} = this.props.crosshair;
        const {cameraWidth, cameraHeight} = this.props;

        return <svg xmlns="http://www.w3.org/2000/svg" viewBox={`0 0 ${cameraWidth} ${cameraHeight}`} onClick={evt => {
            var e = evt.target;
            var dim = e.getBoundingClientRect();
            var x = evt.clientX - dim.left;
            var y = evt.clientY - dim.top;

            x = (x / dim.width) * cameraWidth
            y = (y / dim.height) * cameraHeight

            if( this.props.onChange ) {
                this.props.onChange({x: x, y: y})
            }
        }} onMouseUp={evt => {
            var e = evt.target;
            var dim = e.getBoundingClientRect();
            var x = evt.clientX - dim.left;
            var y = evt.clientY - dim.top;

            //console.log('dim.left ', dim.left)
            //console.log('dim.top ', dim.top)
            //console.log('clientX ', evt.clientX)
            //console.log('clientY ', evt.clientY)

            //console.log('dim.width ', dim.width)
            //console.log('dim.height ', dim.height)

            x = (x / dim.width) * cameraWidth
            y = (y / dim.height) * cameraHeight

            if( this.props.onChange ) {
                this.props.onChange({x: x, y: y})
            }
        }}>
            <g onClick={event => {
                //console.log(event)
            }}>
                <CrossHair x={x} y={y} stroke={'#ff0000'} size={48} strokeWidth={2}/>
            </g>
        </svg>
    }
}

CrosshairSvg.propTypes = {
    width: PropTypes.number,
    height: PropTypes.number,
    cameraWidth: PropTypes.number,
    cameraHeight: PropTypes.number,
    print: PropTypes.object,
    topBottom: PropTypes.string,
    crosshair: PropTypes.object,
    onChange: PropTypes.func
}

CrosshairSvg.defaultProps = {
    topBottom: 'ALL',
    cameraWidth: 0,//WEBCAM_CAMERA_WIDTH,
    cameraHeight: 0//WEBCAM_CAMERA_HEIGHT
}