import {getNextAssemblyDate} from "../oldui/PlanningEntryProject";

const initialState= {projects: [], eventViewOpen: false, eventList: [], priceList: [], priceListError: false, attachmentList: [], centroid: [], pcbs: [],
    orderList: [], projectFilesFromZip: [], projectPage: null, allUsers: [], projectPageFilter: {
        all: false,
        direction: 'ASC',
        sort: "",
        searchValue: "",
        pageNumber: 0,
        paymentStatusFilter: '',
        hasOrderDate: null
    }, pcbMenu: true, showTop: true, pcbWapperMode: '', releaseProduction: [{
        lines: [{designator: '', issue: '', suggested: ''}],
        extra: '',
        released: false
    }], projectModification: {
        lines: [{designator: '', issue: '', suggested: ''}],
        extra: ''
    }, deliveryId: null, invoiceId: null, sideBarOpen: false,
    priceListPcb: [], planning: []
};

export const emptyPoints= {
    tx1: null,
    ty1: null,
    tx2: null,
    ty2: null,
    tx3: null,
    ty3: null,
    sx1: null,
    sy1: null,
    sx2: null,
    sy2: null,
    sx3: null,
    sy3: null
}

//var whatsThatGerber = require('whats-that-gerber')

const project = (state = initialState, action) => {
    switch( action.type ) {
        case 'RECEIVE_SAVE_USER':
            if( action.start.updateProjectOwner && state.project ) {
                return {
                    ...state, project: {
                        ...state.project, owner:
                            (action.start.updateProjectOwner && action.value.id === state.project.owner.id) ? action.value : state.project.owner
                    }
                }
            }
            return state;
        case 'TOGGLE_PROJECT_PCB_MENU':
            return Object.assign({}, state, {
                pcbMenu: !state.pcbMenu
            })
        case 'TOGGLE_PROJECT_PCB_TOP_BOTTOM':
            return Object.assign({}, state, {
                showTop: !state.showTop
            })
        case 'SHOW_UPLOAD_PCBS_MODAL':
            return {...state, showUploadModal: action.value}
        case 'WAPPER_PROJECT_PCBS_MODE':
            return Object.assign({}, state, {
                pcbWapperMode: state.pcbWapperMode === action.mode ? '' : action.mode
            })
        case 'WAPPER_PROJECT_PCBS_SET':
            return Object.assign({}, state, {
                pcbs: state.pcbs.map(p => p.id === action.id ? Object.assign({}, p, {

                }) : p)
            })
        case 'WAPPER_PROJECT_PCB_DOWNLOAD_PROGRESS':
            return Object.assign({}, state, {
                pcbs: state.pcbs.map(p => p.id === action.id ? Object.assign({}, p, {
                    progress: action.progress
                }) : p)
            })
        case 'WAPPER_PROJECT_PCBS_SET_REFERENCE':
            return Object.assign({}, state, {
                pcbs: state.pcbs.map(p => p.id === action.id ? Object.assign({}, p, {
                    reference: action.value,
                    dirty: true
                }) : Object.assign({}, p, {
                    reference: false
                }))
            })
        case 'START_SAVE_PROJECT_PCB_FID':
            return Object.assign({}, state, {
                pcbs: state.pcbs.map(p => p.id === action.start.projectOrderPcbInfoId ? Object.assign({}, p, {
                    saving: true
                }) : p)
            })
        case 'RECEIVE_SAVE_PROJECT_PCB_FID':
            return Object.assign({}, state, {
                pcbs: state.pcbs.map(p => p.id === action.value.id ? Object.assign({}, p, {
                    saving: false
                }) : p)
            })
        case 'ERROR_SAVE_PROJECT_PCB_FID':
            return Object.assign({}, state, {
                pcbs: state.pcbs.map(p => p.id === action.start.projectOrderPcbInfoId ? Object.assign({}, p, {
                    saving: false,
                    error: true
                }) : p)
            })
        case 'UPDATE_PROJECT_PCB_FID':
            return Object.assign({}, state, {
                pcbs: state.pcbs.map(p => p.id === action.projectOrderPcbInfoId ? Object.assign({}, p, {
                    [action.back ? 'pointsBack' : 'pointsFront']: action.points
                }) : p)
            })
        case 'RESET_PROJECT_PCB_FID':
            return Object.assign({}, state, {
                pcbs: state.pcbs.map(p => p.id === action.projectOrderPcbInfoId ? Object.assign({}, p, {
                    [action.back ? 'pointsBack' : 'pointsFront']: {...emptyPoints}
                }) : p)
            })
        case 'START_UPDATE_PROJECT_STATUS':
            return Object.assign({}, state, {
                projectPage: (state.projectPage && state.projectPage.content) ? Object.assign({}, state.projectPage, {
                    content: state.projectPage.content.map(p => p.id === action.start.id ? Object.assign({}, p, {
                        updateing: true
                    }) : p)
                }) : state.projectPage,
                project: (state.project && state.project.id === action.start.id) ? Object.assign({}, state.project, {
                    updateing: true
                }) : state.project
            })
        case 'RECEIVE_UPDATE_PROJECT_STATUS':
            return Object.assign({}, state, {
                projectPage: (state.projectPage && state.projectPage.content) ? Object.assign({}, state.projectPage, {
                    content: state.projectPage.content.map(p => p.id === action.start.id ? action.value : p)
                }) : state.projectPage,
                project: (state.project && state.project.id === action.start.id) ? action.value : state.project
            })
        case 'ERROR_UPDATE_PROJECT_STATUS':
            return Object.assign({}, state, {
                projectPage: (state.projectPage && state.projectPage.content) ? Object.assign({}, state.projectPage, {
                    content: state.projectPage.content.map(p => p.id === action.start.id ? Object.assign({}, p, {
                        updateing: false
                    }) : p)
                }) : state.projectPage,
                project: (state.project && state.project.id === action.start.id) ? Object.assign({}, state.project, {
                    updateing: false
                }) : state.project
            })
        case 'CHANGE_PROJECT_VALUE':
            return Object.assign({}, state, {
                project: Object.assign({}, state.project, {
                    [action.name]: action.value,
                    dirty: true
                }),
            })
        case 'CHANGE_PROJECT_CALCULATOR_VALUE':
            return Object.assign({}, state, {
                project: Object.assign({}, state.project, {
                    calculatorDetails: Object.assign({}, state.project.calculatorDetails, {
                        [action.name]: action.value,
                    }),
                    dirty: true
                }),
            })
        case 'START_GET_PROJECT':
            return Object.assign({}, state, {
                project: null,
                fetchingProject: true,
                pcbs: []
            })
        case 'RECEIVE_GET_PROJECT':
            return Object.assign({}, state, {
                project: action.value,
                fetchingProject: false
            })
        case 'START_GET_PROJECT_STATS':
            if( state.project && state.project.id === action.start.id ) {
                return {...state, project: {
                    ...state.project,
                    bomStats: {...state.project.bomStats, fetching: true}
                }}
            }
            return state;
        case 'RECEIVE_GET_PROJECT_STATS':
            if( state.project && state.project.id === action.start.id ) {
                return {...state, project: {
                        ...state.project,
                        bomStats: action.value
                    }}
            }
            return {...state, bomStats: action.value};
        case 'ERROR_GET_PROJECT':
            return Object.assign({}, state, {
                fetchingProject: false
            })
        case 'START_GET_PROJECTS':
            return Object.assign({}, state, {
                fetchingProjects: action.start.id,
                pcbs: [],
                project: null
            })
        case 'RECEIVE_GET_PROJECTS':

            if( state.fetchingProjects !== action.start.id ) {
                return state;
            }

            let filter = {}
            if( action.value.sort && action.value.sort.length ) {
                filter = {
                    sort: action.value.sort[0].property,
                    direction: action.value.sort[0].direction
                }
            }

            return Object.assign({}, state, {
                projectPage: action.value.number ? Object.assign({}, action.value, {
                    content: [...state.projectPage.content, ...action.value.content]
                }) : action.value,
                projectPageFilter: {
                    ...action.start,
                    ...filter
                },
                projects: action.value.content,
                fetchingProjects: false,
                pcbs: []
            })
        case 'ERROR_GET_PROJECTS':
            return Object.assign({}, state, {
                fetchingProjects: false
            })
        case 'START_SAVE_PROJECT':
            return Object.assign({}, state, {
                savingProject: true,
                savingProjectError: false
            })
        case 'RECEIVE_SAVE_PROJECT':
            return Object.assign({}, state, {
                savingProject: false,
                project: action.value
            })
        case 'ERROR_SAVE_PROJECT':
            return Object.assign({}, state, {
                savingProject: false,
                savingProjectError: true
            })
        case 'START_TOGGLE_PROJECT_SHOW':
            return Object.assign({}, state, {
            })
        case 'RECEIVE_TOGGLE_PROJECT_SHOW':
            return Object.assign({}, state, {
                projectPage: Object.assign({}, state.projectPage, {content: state.projectPage.content.map((o) =>
                    o.id === action.start.id ? Object.assign({}, o, {filterState: action.start.filterValue}) : o
                )})
            })
        case 'ERROR_TOGGLE_PROJECT_SHOW':
            return Object.assign({}, state, {
            })
        case 'RECEIVE_FETCH_PCB_PRICE':
            let newListPcb= []
            if (state.priceListPcb.some(e => e.orderId === action.start.orderId)) {
                newListPcb= state.priceListPcb.map(p => p.orderId === action.start.orderId ?
                    {value: action.value, date: new Date(), loading: false, ...action.start}
                    : p)
            } else {
                newListPcb= [...state.priceListPcb, {value: action.value, date: new Date(), ...action.start}]
            }
            return Object.assign({}, state, {
                priceListPcb: newListPcb
            })
        case 'START_FETCH_PRICE_BY_ORDER_ID':
            return Object.assign({}, state, {
            })
        case 'RECEIVE_FETCH_PRICE_BY_ORDER_ID':
            let newList1= []
            if (state.priceList.some(e => e.orderId === action.start.orderId)) {
                newList1= state.priceList.map(p => p.orderId === action.start.orderId ?
                        {value: action.value, date: new Date(), loading: false, ...action.start}
                        : p)
            } else {
                newList1= [...state.priceList, {value: action.value, date: new Date(), ...action.start}]
            }
            return Object.assign({}, state, {
                priceList: newList1
            })
        case 'ERROR_FETCH_PRICE_BY_ORDER_ID':
            return Object.assign({}, state, {

            })
        case 'START_FETCH_PRICE':
            return Object.assign({}, state, {

            })
        case 'RECEIVE_FETCH_PRICE':

            let value= null;
            if( action.value && action.value.length > 0 ) {
                let current = action.value.filter(p => {
                    if (p.days === action.start.value.selectedDays && p.amount === action.start.value.numPcbs) {
                        return true;
                    }
                    return false;
                })

                if (current.length && current[0]) {

                    value = current[0].price;
                }
            }

            let newList2= []
            if( value != null && value !== '' ) {
                if (state.priceList.some(e => e.orderId === action.start.orderId)) {
                    newList2 = state.priceList.map(p => p.orderId === action.start.orderId ?
                        {
                            value: value,
                            date: new Date(),
                            loading: false,
                            numPcbs: action.start.value.numPcbs,
                            selectedDays: action.start.value.selectedDays,
                            orderId: action.start.orderId
                        }
                        : p)
                } else {
                    newList2 = [...state.priceList,
                        {
                            value: value,
                            date: new Date(),
                            loading: false,
                            numPcbs: action.start.value.numPcbs,
                            selectedDays: action.start.value.selectedDays,
                            orderId: action.start.orderId
                        }
                    ]
                }
            }
            else {
                newList2 = state.priceList;
            }

            return Object.assign({}, state, {
                calculatorPrice: action.value,
                priceList: newList2
            })
        case 'RECEIVE_FETCH_EXPECTED_DELIVERY_DATE':
            return {...state, expectedDelivery: action.value}
        case 'ERROR_FETCH_PRICE':
            return Object.assign({}, state, {
                calculatorPrice: null
            })
        case 'START_GET_PROJECT_EVENTS':
            return Object.assign({}, state, {
                eventViewOpen: true,
                fetchingEventList: true,
                eventList: [],
            })
        case 'RECEIVE_GET_PROJECT_EVENTS':
            return Object.assign({}, state, {
                eventList: action.value,
                fetchingEventList: false
            })
        case 'ERROR_GET_PROJECT_EVENTS':
            return Object.assign({}, state, {
                eventList: [],
                fetchingEventList: false
            })
        case 'START_DELETE_PROJECT_EVENT':
            return Object.assign({}, state, {
                fetchingEventList: true,
            })
        case 'RECEIVE_DELETE_PROJECT_EVENT':
            return Object.assign({}, state, {
                eventList: action.value,
                fetchingEventList: false,
            })
        case 'ERROR_DELETE_PROJECT_EVENT':
            return Object.assign({}, state, {
                eventList: [],
                fetchingEventList: false,
            })
        case 'START_CREATE_PROJECT_INVOICE':
        case 'START_CREATE_PROJECT_PACKING_SLIP':
            return Object.assign({}, state, {
                creatingPackingSlip: true
            })
        case 'RECEIVE_CREATE_PROJECT_INVOICE':
        case 'RECEIVE_CREATE_PROJECT_PACKING_SLIP':
            return Object.assign({}, state, {
                creatingPackingSlip: false,
                eventList: action.value
            })
        case 'ERROR_CREATE_PROJECT_INVOICE':
        case 'ERROR_CREATE_PROJECT_PACKING_SLIP':
            return Object.assign({}, state, {
                creatingPackingSlip: false
            })
        case 'OPEN_DELETE_PACKING_SLIP_MODAL':
            return {...state, deletePackingSlipModal: action.value}
        case 'START_DELETE_PROJECT_PACKING_SLIP':
            return {...state, deletingEventListItem: true};
        case 'RECEIVE_DELETE_PROJECT_PACKING_SLIP':
            return {...state, eventList: action.value, deletingEventListItem: false, deletePackingSlipModal: null}
        case 'EVENT_VIEW_OPEN':
            return Object.assign({}, state, {
                eventViewOpen: action.value,
            })
        case 'START_GET_PROJECT_ATTACHMENTS':
            return Object.assign({}, state, {
                attachmentList: [],
            })
        case 'RECEIVE_GET_PROJECT_ATTACHMENTS':
            return Object.assign({}, state, {
                attachmentList: action.value,
            })
        case 'ERROR_GET_PROJECT_ATTACHMENTS':
            return state;
        case 'ADD_PROJECT_ATTACHMENT':
            return Object.assign({}, state, {
                attachmentList: action.value
            })
        case 'START_GET_PROJECT_CENTROID':
            return Object.assign({}, state, {
                centroid: null
            })
        case 'RECEIVE_GET_PROJECT_CENTROID':
            return Object.assign({}, state, {
                centroid: action.value
            })
        case 'RECEIVE_GET_PROJECT_PCBS':
            return Object.assign({}, state, {
                pcbs: action.value.map((p,i) => Object.assign({}, p, {
                    updateId: 0,
                    selected: i === 0,
                    skip: !p.reference
                }))
            })
        case 'RECEIVE_SAVE_PROJECT_PCB_FILE_UPLOADED':
            return Object.assign({}, state, {
                pcbMenu: true,
                pcbs: state.pcbs.map(p => p.id === action.value.id ? {
                    ...p, front: action.value.front, back: action.value.back, pictures: action.value.pictures
                } : p)
            })
        case 'RECEIVE_SAVE_PROJECT_PCB':
            if (state.pcbs.some(e => e.id === action.value.id)) {
                return Object.assign({}, state, {
                    pcbMenu: true,
                    pcbs: state.pcbs.map(p => p.id === action.value.id ? {...action.value, skip: p.skip} : p)
                })
            } else {
                return Object.assign({}, state, {
                    pcbMenu: true,
                    pcbs: [...state.pcbs.map(p => Object.assign({}, p, {selected: false})),
                        Object.assign({}, action.value, {selected: true})
                    ]
                })
            }
        case 'RECEIVE_DELETE_PROJECT_PCB':
            return Object.assign({}, state, {
                pcbs: state.pcbs.filter(p => p.id !== action.start.projectOrderPcbInfoId).map((p,i) => {
                    return Object.assign({}, p, {
                        selected: i === 0
                    })
                })
            })
        case 'WAPPER_PROJECT_PCBS':

            let nextWapper= 0;

            state.pcbs.forEach((p,i) => {
                if( p.selected ) {
                    nextWapper= i;
                }
            })

            let allSkip= true;

            state.pcbs.forEach(p => {
                if( !p.skip ) {
                    allSkip= false;
                }
            })

            if( !allSkip ) {
                do {
                    nextWapper++;

                    if (nextWapper > state.pcbs.length - 1) {
                        nextWapper = 0;
                    }
                } while (state.pcbs[nextWapper].skip);
            }

            return Object.assign({}, state, {
                pcbs: state.pcbs.map((p,i) => {
                    if( i === nextWapper ) {
                        return Object.assign({}, p, {
                            selected: true
                        })
                    } else {
                        return Object.assign({}, p, {
                            selected: false
                        })
                    }
                })
            })
        case 'CHANGE_PROJECT_PCB':
            let setDirty= action.name !== 'selected' && action.name !== 'hover' && action.name !== 'hoverBottom' && action.name !== 'bottom'
            return Object.assign({}, state, {
                pcbs: state.pcbs.map(p => p.id === action.id ? Object.assign({}, p, {[action.name]: action.value, dirty: setDirty}) :
                    action.name === 'selected' ? Object.assign({}, p, {selected: false}) : p
                )
            })
        case 'RECEIVE_GET_PROJECT_ORDERLIST':
            return Object.assign({}, state, {
                orderList: action.value
            })
        case 'START_GET_FILES_IN_ZIP':
            return Object.assign({}, state, {
                projectFilesFromZip: []
            })
        case 'RECEIVE_GET_FILES_IN_ZIP':
            return {
                ...state, projectFilesFromZip: action.value
            }

            /*return Object.assign({}, state, {
                projectFilesFromZip: action.value.map(f => {

                    let gerber= null

                    if( whatsThatGerber && f.name ) {
                        let name= f.name;
                        console.log(name)
                        gerber= whatsThatGerber(name);
                        gerber= gerber[name];
                        console.log(gerber)
                    }

                    return Object.assign({}, f, {
                        gerber
                    });

                })
            })*/
        case 'START_SEND_PROTO_FOTO':
            return Object.assign({}, state, {
                sendProtoFotoBusy: true,
                sendProtoFotoResult: ''
            })
        case 'RECEIVE_SEND_PROTO_FOTO':
            return Object.assign({}, state, {
                sendProtoFotoBusy: false,
                sendProtoFotoResult: action.value.message
            })
        case 'ERROR_SEND_PROTO_FOTO':
            return Object.assign({}, state, {
                sendProtoFotoBusy: false,
                sendProtoFotoResult: 'ERROR'
            })
        case 'CHANGE_PROJECT_PAGE':
            return Object.assign({}, state, {
                projectPage: action.value
            })
        case 'RECEIVE_GET_USERS':

            let newList= action.value.map(u => {return {
                key: u.id,
                value: u,
                text: u.lastName + ', ' + u.firstName + (u.tussenvoegsel ? ' ' + u.tussenvoegsel : '') + ' <' + u.email + '>'
            }});

            return Object.assign({}, state, {
                allUsers: newList
            })
        case 'RECEIVE_CHANGE_PROJECT_OWNER':
            return Object.assign({}, state, {
                project: action.value
            })
        case 'RECEIVE_TOGGLE_PROJECT_PARKED_STATUS':
        case 'RECEIVE_TOGGLE_PROJECT_PAYMENT_STATUS':
            return Object.assign({}, state, {
                projectPage: (state.projectPage && state.projectPage.content) ? Object.assign({}, state.projectPage, {
                    content: state.projectPage.content.map(p => p.id === action.start.id ? action.value : p)
                }) : state.projectPage,
                project: (state.project && state.project.id === action.start.id) ? action.value : state.project
            })
        case 'HIGHLIGHT_PROJECT_PLANNING':
            return {
                ...state, planning: state.planning.map(p => (
                    {
                        ...p,
                        highlight: ((p.id === action.id))
                    }
                ))
            }
        case 'RECEIVE_GET_PROJECT_PLANNING':
            return Object.assign({}, state, {
                planning: action.value.map(p => ({...p, nextAssemblyDate: getNextAssemblyDate(p)})).sort((a,b) => {
                    if( a.nextAssemblyDate < b.nextAssemblyDate ) {
                        return -1;
                    }
                    if( a.nextAssemblyDate > b.nextAssemblyDate ) {
                        return 1;
                    }
                    return 0;
                }),
                planningRefreshDate: new Date()
            })
        case 'RECEIVE_POST_CHAT_MESSAGE':
            return {
                ...state, planning: state.planning.map(p => (
                    {
                        ...p,
                        chatLines: p.chat === action.start.chatId ? [...p.chatLines, action.value] : p.chatLines
                    }
                ))
            }
        case 'RECEIVE_UPDATE_CHAT_MESSAGE':
            return {
                ...state, planning: state.planning.map(p => (
                    {
                        ...p,
                        chatLines: p.chatLines.map(c => c.id === action.value.id ? action.value : c)
                    }
                ))
            }
        case 'CHANGE_CHAT_VALUE':
            return {
                ...state, planning: state.planning.map(p => (
                    {
                        ...p,
                        chatLines: p.chatLines.map(c => c.id === action.id ? {...c, [action.name]: action.value, dirty: true} : c)
                    }
                ))
            }

        case 'RECEIVE_UPDATE_PROJECT_PLANNING':
            return Object.assign({}, state, {
                planning: state.planning.map(p => {
                    if( p.id === action.value.id ) {
                        return action.value
                    }
                    return p;
                })
            })

        /**
         * Release production
         */

        case 'ADD_LINE_PRODUCTION_RELEASE':
            return {...state, releaseProduction: state.releaseProduction.map(r => {
                if( r.id === action.id ) {
                    return {
                        ...r, lines: [...r.lines, {
                            line: action.line, designator: '', issue: '', suggested: '', file: action.file
                        }]
                    }
                }
                return r;
            })}
        case 'REMOVE_LINE_PRODUCTION_RELEASE':
            return {...state, releaseProduction: state.releaseProduction.map(r => {
                    if( r.id === action.id ) {
                        return {
                            ...r, lines: r.lines.filter((l,i) => i !== action.i)
                        }
                    }
                    return r;
                })}
        case 'SET_VALUE_PRODUCTION_RELEASE':
            return {...state, releaseProduction: state.releaseProduction.map(r => {
                    if( r.id === action.id ) {
                        if( action.i !== null ) {
                            return {
                                ...r, dirty: true, lines: r.lines.map((l,i) => {
                                    if( i === action.i ) {
                                        return {...l, [action.name]: action.value}
                                    }
                                    return l;
                                })
                            }
                        } else {
                            return {...r,
                                [action.name]: action.value
                            }
                        }
                    }
                    return r;
                })}
        case 'RECEIVE_GET_PRODUCTION_RELEASE':
            if( action.value ) {
                return {...state, releaseProduction: action.value}
            } else {
                return {...state, releaseProduction: initialState.releaseProduction}
            }
        case 'START_POST_PRODUCTION_RELEASE':
            return {...state, savingProductionRelease: action.start.id, errorProductionRelease: false}
        case 'RECEIVE_POST_PRODUCTION_RELEASE':
            if( action.value ) {
                return {...state, savingProductionRelease: false, releaseProduction: state.releaseProduction.map(r => {
                    if( r.id === action.start.startId ) {
                        return action.value
                    }
                    return r;
                })}
            } else {
                return state;
            }
        case 'ERROR_POST_PRODUCTION_RELEASE':
            return {...state, savingProductionRelease: false, errorProductionRelease: action.start.id}

        case 'RECEIVE_CANCEL_PRODUCTION_RELEASE':
            return {
                ...state,
                releaseProduction: state.releaseProduction.map(r => {
                    if (r.id === action.value.id) {
                        return action.value
                    }
                    return r;
                })
            }
        /**
         * Project modification
         */

        case 'ADD_LINE_PROJECT_MODIFICATION':
            return {...state, projectModification: {
                    ...state.projectModification, lines: [...state.projectModification.lines, {
                        line: action.line, designator: '', issue: '', suggested: '', file: action.file
                    }]
                }}
        case 'REMOVE_LINE_PROJECT_MODIFICATION':
            return {...state, projectModification: {
                    ...state.projectModification, lines: state.projectModification.lines.filter((l,i) => i !== action.i)
                }}
        case 'SET_VALUE_PROJECT_MODIFICATION':
            if( action.i !== null ) {
                return {...state, projectModification: {
                        ...state.projectModification, dirty: true, lines: state.projectModification.lines.map((l,i) => {
                            if( i === action.i ) {
                                return {...l, [action.name]: action.value}
                            }
                            return l;
                        })
                    }}
            } else {
                return {
                    ...state, projectModification: {...state.projectModification,
                        [action.name]: action.value
                    }
                }
            }
        case 'RECEIVE_GET_PROJECT_MODIFICATION':
            if( action.value ) {
                return {...state, projectModification: action.value}
            } else {
                return {...state, projectModification: initialState.projectModification}
            }
        case 'START_POST_PROJECT_MODIFICATION':
            return {...state, savingProjectModification: true, errorProjectModification: false}
        case 'RECEIVE_POST_PROJECT_MODIFICATION':
            if( action.value ) {
                return {...state, savingProjectModification: false, projectModification: action.value}
            } else {
                return state;
            }
        case 'ERROR_POST_PROJECT_MODIFICATION':
            return {...state, savingProjectModification: false, errorProjectModification: true}
        case 'SET_SELECTED_INVOICE_ID':
            return {...state, invoiceId: action.id}
        case 'SET_SELECTED_DELIVERY_ID':
            return {...state, deliveryId: action.id}
        case 'OPEN_SIDE_BAR':
            return {...state, sideBarOpen: action.open}
        case 'RECEIVE_GET_FLOATING_PCB_PICTURES':
            return {...state, floatingPcbPictures: action.value}
        default:
            return state
    }
}
export default project
