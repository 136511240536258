import React from 'react'
import * as PropTypes from "prop-types";
import Spinner from "./Spinner";
import BomDesignator from "./BomDesignator";
import {Table} from "semantic-ui-react";

class BomCustomerView extends React.Component {

    handleClick = (v) => {
        if( this.props.onClick ) {
            this.props.onClick(v)
        }
    }

    render() {

        const r= this.props.r;

        let specFromValidation = null

        r.orderparts.forEach(o => {
            if( o && o.specification ) {
                specFromValidation = o.specification.displayValue
            }
        })

        return <div>
            <h3>Data from customer BOM</h3>
            <Table style={{width: '100%'}}>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Row</Table.HeaderCell>
                        <Table.HeaderCell>Designators</Table.HeaderCell>
                        <Table.HeaderCell>Qty</Table.HeaderCell>
                        <Table.HeaderCell>Value</Table.HeaderCell>
                        <Table.HeaderCell>Manufacturer</Table.HeaderCell>
                        <Table.HeaderCell>MPN</Table.HeaderCell>
                        <Table.HeaderCell>Supplier</Table.HeaderCell>
                        <Table.HeaderCell>SKU</Table.HeaderCell>
                        {/*<Table.HeaderCell>Spec</th>*/}
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    <Table.Row>
                        <Table.Cell>{r.row}</Table.Cell>
                        <Table.Cell><BomDesignator values={r.designators}/></Table.Cell>
                        <Table.Cell>{r.amount}</Table.Cell>
                        <Table.Cell>
                            {(r.specification && r.specification.displayValue) ?
                                <button className='link-button' onClick={() => this.handleClick(r.specification.displayValue)}>{r.specification.displayValue}</button>
                                : ''}
                        </Table.Cell>
                        <Table.Cell>{r.todos.filter((t) => t.opcode === 'MPN').map(mpn => <div>
                            <button className='link-button' onClick={() => this.handleClick(mpn.key)}>{mpn.key}</button>
                        </div>)}</Table.Cell>
                        <Table.Cell>{r.todos.filter((t) => t.opcode === 'MPN').map(mpn => <div>
                            <button className='link-button' onClick={() => this.handleClick(mpn.value)}>{mpn.value}</button>
                        </div>)}</Table.Cell>
                        <Table.Cell>{r.todos.filter((t) => t.opcode === 'SKU').map(mpn => <div>
                            <button className='link-button' onClick={() => this.handleClick(mpn.key)}>{mpn.key}</button>
                        </div>)}</Table.Cell>
                        <Table.Cell>{r.todos.filter((t) => t.opcode === 'SKU').map(mpn => <div>
                            <button className='link-button' onClick={() => this.handleClick(mpn.value)}>{mpn.value}</button>
                        </div>)}</Table.Cell>
                    </Table.Row>
                </Table.Body>
            </Table>

            <div style={{display: 'flex'}}>
                <div style={{flex: 1}}>
                    {r.orderparts.map(o => (

                        <div style={{display: ''}}>
                            {o.manufacturer}{" "}{o.mpn}{": "}

                            {o.specification ? <button className='link-button'
                                     onClick={() => this.handleClick(o.specification.supplierPackage)}>{o.specification.supplierPackage}</button> : null}

                            {o.specification ? <button className='link-button'
                                    onClick={() => this.handleClick(o.specification.displayValue)}>{o.specification.displayValue}</button> : null}
                        </div>


                    ))}
                </div>
                <div>
                    {this.props.requiredAmount} required
                </div>
            </div>



        </div>
    }
}

BomCustomerView.propTypes = {
    onClick: PropTypes.func,
    requiredAmount: PropTypes.number,
};

BomCustomerView.defaultProps = {
    onClick: false,
    requiredAmount: 0,
};

export default BomCustomerView;