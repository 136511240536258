import React, {Component} from "react";
import {connect} from "react-redux";
import {
    changeKnownPackage,
    getKnownPackages,
    saveKnownPackages
} from "../../actions";
import {Button, Checkbox, Input, Table} from "semantic-ui-react";

class KnownPackages extends Component {

    state = {}

    componentDidMount() {
        this.props.dispatch(getKnownPackages())
    }

    render() {

        const d = this.props.knownPackages;

        return (
            <div>
                <Table compact='very' size='small' style={{width: '100%', lineHeight: '16px'}}>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>Package</Table.HeaderCell>
                            <Table.HeaderCell>Komt voor als weerstand</Table.HeaderCell>
                            <Table.HeaderCell>Komt voor als capacitor</Table.HeaderCell>
                            <Table.HeaderCell collapsing></Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {d.map(d => <Table.Row key={d.id}>
                            <Table.Cell>
                                <Input value={d.value} fluid onChange={e => {
                                    this.props.dispatch(changeKnownPackage(d.id, 'value', e.target.value))
                                }}/>
                            </Table.Cell>
                            <Table.Cell>
                                <Checkbox checked={d.resistor} onChange={e => {
                                    this.props.dispatch(changeKnownPackage(d.id, 'resistor', !d.resistor))
                                }}/>
                            </Table.Cell>
                            <Table.Cell>
                                <Checkbox checked={d.capacitor} onChange={e => {
                                    this.props.dispatch(changeKnownPackage(d.id, 'capacitor', !d.capacitor))
                                }}/>
                            </Table.Cell>
                            <Table.Cell style={{textAlign: 'right'}}>
                                <Button onClick={() => {
                                    this.props.dispatch(saveKnownPackages(d))
                                }} primary={d.dirty}>
                                    Save
                                </Button>
                            </Table.Cell>
                        </Table.Row>)}

                        <Table.Row key={-1}>
                            <Table.Cell colSpan={3}>
                                <Input value={this.state.newValue} fluid onChange={e => this.setState({newValue: e.target.value})}/>
                            </Table.Cell>
                            <Table.Cell style={{textAlign: 'right'}}>
                                <Button onClick={() => {
                                    this.props.dispatch(saveKnownPackages(this.state.newValue, () => {
                                        this.setState({newValue: ''})
                                    }))
                                }}>
                                    Add new
                                </Button>
                            </Table.Cell>
                        </Table.Row>
                    </Table.Body>
                </Table>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        knownPackages: state.bom.knownPackages
    }
}

export default connect(mapStateToProps)(KnownPackages)