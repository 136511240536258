import React from 'react'
import PropTypes from "prop-types";
import {connect} from "react-redux";
import FloatingWindow from "../FloatingWindow";
import {Popup, Table} from "semantic-ui-react";
import {getCostForPrint, renderSupplier} from "./functions";
import {renderEuro} from "./BomOrderparts";

class BomOrderCostTable extends React.Component {

    state = {}

    render() {
        if( !this.props.print ) {
            return <>...</>
        }

        let {cost, costPerSupplier, sendCostTotal} = getCostForPrint(this.props.print);

        return (
            <>
                <Popup wide='very' position='bottom center' trigger={
                    <button className={'link-button'} style={{color: 'white'}} onClick={() => this.setState({costModal: true})}>
                        {renderEuro((cost + sendCostTotal) / this.props.print.amount)}
                    </button>
                } content={
                    <div>
                        <b>Kosten per PCB (klik voor specificatie)</b>
                        <p>Deze kosten zijn voor de huidige selectie van componenten</p>
                    </div>
                }/>

                {this.state.costModal && <FloatingWindow
                    onClose={() => this.setState({costModal: false})}
                    header={'Bestelkosten'}
                    name={'deltaprotobestelkostenwindow'}
                >
                    <Table compact='very' size='small' style={{width: '100%', lineHeight: '12px'}}>
                        <Table.Row>
                            <Table.HeaderCell>Supplier</Table.HeaderCell>
                            <Table.HeaderCell>Parts</Table.HeaderCell>
                            <Table.HeaderCell>Extra</Table.HeaderCell>
                            <Table.HeaderCell>MOQ</Table.HeaderCell>
                            <Table.HeaderCell>Shipping</Table.HeaderCell>
                            <Table.HeaderCell>Total</Table.HeaderCell>
                        </Table.Row>
                        {Object.entries(costPerSupplier).map(p => {

                            let wantCost = p[1].wantCost;
                            let extraAmountCost = p[1].extraAmountCost;
                            let moqCost = p[1].moqCost;
                            let sendCost = p[1].sendCost;

                            return <Table.Row>
                                <Table.Cell>{renderSupplier({supplier: p[0], sku: ''})}</Table.Cell>
                                <Table.Cell style={{textAlign: 'right'}}>{renderEuro(wantCost)}</Table.Cell>
                                <Table.Cell style={{textAlign: 'right'}}>{renderEuro(extraAmountCost)}</Table.Cell>
                                <Table.Cell style={{textAlign: 'right'}}>{renderEuro(moqCost)}</Table.Cell>
                                <Table.Cell style={{textAlign: 'right'}}>{renderEuro(sendCost)}</Table.Cell>
                                <Table.Cell style={{textAlign: 'right'}}>{renderEuro(wantCost + extraAmountCost + moqCost + sendCost)}</Table.Cell>
                            </Table.Row>
                        })}
                        <Table.Footer>
                            <Table.Row>
                                <Table.HeaderCell/>
                                <Table.HeaderCell/>
                                <Table.HeaderCell/>
                                <Table.HeaderCell/>
                                <Table.HeaderCell style={{textAlign: 'right'}}>{this.props.print.amount} PCBA:</Table.HeaderCell>
                                <Table.HeaderCell style={{fontWeight: 'bold', textAlign: 'right'}}>{renderEuro(cost + sendCostTotal)}</Table.HeaderCell>
                            </Table.Row>
                        </Table.Footer>
                    </Table>
                </FloatingWindow>}
            </>
        );
    }
}


BomOrderCostTable.propTypes = {
    //modal: PropTypes.bool,
}

BomOrderCostTable.default = {
    //modal: true
}

const mapStateToProps = (state) => {
    return {
        lang: state.main.lang,
        print: state.bom.print,
    }
}

export default connect(mapStateToProps)(BomOrderCostTable)