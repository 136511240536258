import React, {Component} from 'react';
import PropTypes from 'prop-types';
import connect from "react-redux/es/connect/connect";
import './App.css';
import {
    changeProjectOrderPcbInfo,
    deleteProjectOrderPcbInfo,
    receiveProjectOrderPcbInfoFileUploaded, resetProjectPcbPictireFid,
    toggleProjectOrderPcbInfoMenu, updateProjectPcbPictireFid,
    wapperProjectOrderPcbMode,
    wapperProjectOrderSetReference
} from "./actions";
import {
    Button,
    Checkbox,
    Confirm, Divider,
    Form,
    Grid, Icon,
    Image, Message,
    Segment,
} from "semantic-ui-react";
import UploadFile from "./oldui/UploadFile";
import { MapInteractionCSS } from 'react-map-interaction';
import {emptyPoints} from "./reducers/project";
import Progressbar from "./Progressbar";

const protoFotoTypeOptions = [
    {key: 'NA', text: 'Not selected', value: 'NA'},
    {key: 'G', text: 'Gerber (G)', value: 'GERBER'},
    {key: 'SAMPLE1', text: 'Sample 1 (S1)', value: 'SAMPLE1'},
    {key: 'SAMPLE2', text: 'Sample 2 (S2)', value: 'SAMPLE2'},
    {key: 'SAMPLE3', text: 'Sample 3 (S3)', value: 'SAMPLE3'},
    {key: 'REPAIR1', text: 'Repair 1 (R1)', value: 'REPAIR1'},
    {key: 'REPAIR2', text: 'Repair 2 (R2)', value: 'REPAIR2'},
    {key: 'REPAIR3', text: 'Repair 3 (R3)', value: 'REPAIR3'},
    {key: 'BATCH1', text: 'Batch 1 (B1)', value: 'BATCH1'},
    {key: 'BATCH2', text: 'Batch 2 (B2)', value: 'BATCH2'},
    {key: 'BATCH3', text: 'Batch 3 (B3)', value: 'BATCH3'},
    {key: 'BATCH4', text: 'Batch 4 (B4)', value: 'BATCH4'},
    {key: 'BATCH5', text: 'Batch 5 (B5)', value: 'BATCH5'},
    {key: 'BATCH6', text: 'Batch 6 (B6)', value: 'BATCH6'},
    {key: 'BATCH7', text: 'Batch 7 (B7)', value: 'BATCH7'},
    {key: 'BATCH8', text: 'Batch 8 (B8)', value: 'BATCH8'},
    {key: 'BATCH9', text: 'Batch 9 (B9)', value: 'BATCH9'},
    {key: 'BATCH10', text: 'Batch 10 (B10)', value: 'BATCH10'}
]

class ProjectStepFotoItem extends Component {

    state= {
        deleteId: null,
        scale: 1,
        translation: {x:0, y:0},
        pan: true
    }

    constructor(props) {
        super(props);
        this.viewport = React.createRef()
    }

    save(value, projectId) {

        if( this.props.onSave ) {
            this.props.onSave()
        }
    }

    delete(id) {

        this.props.dispatch(deleteProjectOrderPcbInfo(id))
        this.setState({deleteId: null})
    }

    onChange(id, name, value) {
        this.props.dispatch(changeProjectOrderPcbInfo(id, name, value))
    }

    toggleMenu() {
        this.props.dispatch(toggleProjectOrderPcbInfoMenu())
    }

    renderMenu(project, m_value, frontImage, backImage) {
        if( !this.props.pcbMenu ) {
            return <div>
                <Button basic color='black' icon='angle right' style={{float: 'left', display: 'block', background: 'white'}}
                        onClick={() => this.toggleMenu()}
                />
            </div>
        }

        let pcbWapperMode= this.props.pcbWapperMode;

        let points= this.props.showTop ? m_value.pointsFront : m_value.pointsBack;

        return <div style={{paddingBottom: 32}} key={this.props.key}>


            {frontImage && <div style={{display: 'none'}}><Image id={frontImage.frontImage.id}
                                                                 src={'/api/project/getAttachment?id=' + frontImage.frontImage.id}/></div>}

            <Confirm
                dimmer={'blurring'}
                open={this.state.deleteId}
                onCancel={() => this.setState({deleteId: null})}
                onConfirm={() => this.delete(this.state.deleteId)}
                content={'Weet je zeker dat je deze pcb wilt verwijderen?'}
                cancelButton='Nee'
                confirmButton="Ja"
            />

            <Segment style={{maxWidth: 300, marginTop: 0, textAlign: 'left'}}>
                <Grid columns={1} divided stackable>
                    <Grid.Row>
                        <Grid.Column>
                            <Button basic icon='angle left' style={{float: 'right', display: 'block'}}
                                    onClick={() => this.toggleMenu()}
                            />
                            <h2 style={{display: 'inline'}}>Tools</h2>
                            {m_value && <Form style={{marginTop: 16}}>
                                <Form.Input type='number' error={!m_value.number} name='Nummer' label='Nummer' fluid placeholder='Nummer' value={m_value.number ? m_value.number : ''}
                                       onChange={(e) => this.onChange(m_value.id, 'number', e.target.value)}/>

                                <Form.Dropdown selection name='type' fluid placeholder='Type' label='Type' value={m_value.type ? m_value.type : 'NA'}
                                            onChange={(e, d) => this.onChange(m_value.id, 'type', d.value)}
                                            options={protoFotoTypeOptions}
                                />

                                {(m_value.type ? m_value.type : 'NA') === 'NA' ? <div>
                                    <Message negative>
                                        <Message.Header>Let op!</Message.Header>
                                        <p>Je hebt nog geen type geselecteerd. Dit is verplicht.</p>
                                    </Message>
                                </div> : null}

                                <Form.Input name='name' fluid placeholder='Name' label='Name' value={m_value.name ? m_value.name : ''}
                                            onChange={(e) => this.onChange(m_value.id, 'name', e.target.value)}/>

                                <Form.TextArea name='remarks' rows={1} autoHeight placeholder='Opmerkingen' label='Opmerkingen'
                                          value={m_value.remarks ? m_value.remarks : ''}
                                          onChange={(e) => this.onChange(m_value.id, 'remarks', e.target.value)}/>
                            </Form>}

                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column>
                            <Checkbox
                                label='Referentie-foto'
                                checked={m_value.reference}
                                onClick={() => this.props.dispatch(wapperProjectOrderSetReference(m_value.id, !m_value.reference))}
                            />
                            <Progressbar color='#f2711c' progress={100}/>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column>
                            <div>
                                Positioneerpunten {this.props.showTop ? 'bovenkant' : 'onderkant'}:
                            </div>

                            <Button color={pcbWapperMode === '1' ? 'red' : 'lightgrey'} onClick={() => this.props.dispatch(wapperProjectOrderPcbMode('1'))}>1</Button>
                            <Button color={pcbWapperMode === '2' ? 'red' : 'lightgrey'} disabled={!points || !points.tx1} onClick={() => this.props.dispatch(wapperProjectOrderPcbMode('2'))}>2</Button>
                            <Button color={pcbWapperMode === '3' ? 'red' : 'lightgrey'} disabled={!points || !points.tx2} nClick={() => this.props.dispatch(wapperProjectOrderPcbMode('3'))}>3</Button>
                            {' '}
                            <Button.Group>
                                <Button onClick={() => {
                                    this.props.dispatch(resetProjectPcbPictireFid(m_value.id, !this.props.showTop))

                                    this.props.dispatch(updateProjectPcbPictireFid(emptyPoints, m_value.id, !this.props.showTop))
                                }}>Reset</Button>
                            </Button.Group>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column>
                            <div style={{visibility: m_value.id ? '' : 'hidden'}}>
                                <UploadFile text={frontImage && <p>Front: <b>{frontImage.frontImage.name}</b></p>}
                                            inputText={'123'}
                                            url={'/api/project/addProjectOrderPcbPicture?projectOrderPcbInfoId=' + m_value.id + '&back=false'}
                                            onResult={(body) => {
                                                this.props.dispatch(receiveProjectOrderPcbInfoFileUploaded(body))
                                            }}/>
                            </div>

                            <div style={{visibility: m_value.id ? '' : 'hidden', textAlign: 'left', marginTop: 24}}>
                                <UploadFile text={backImage && <p>Back: <b>{backImage.backImage.name}</b></p>}
                                            url={'/api/project/addProjectOrderPcbPicture?projectOrderPcbInfoId=' + m_value.id + '&back=true'}
                                            onResult={(body) => {
                                                this.props.dispatch(receiveProjectOrderPcbInfoFileUploaded(body))
                                            }}/>
                            </div>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
                <Divider style={{marginTop: 16, }}/>
                <div style={{textAlign: 'right'}}>
                    <Button loading={m_value.saving} disabled={m_value.saving}  primary={m_value.dirty} onClick={() => this.save(m_value, project.id)}>Save</Button>
                    <Button disabled={m_value.saving} onClick={() => this.setState({deleteId: m_value.id})}>Delete</Button>
                </div>
            </Segment>
        </div>
    }

    render() {

        let project= this.props.project;

        if( !project ) {
            return <div/>
        }

        let m_value= {}

        this.props.pcbs.forEach(p => {
            if( p.id === this.props.id ) {
                m_value= p;
            }
        })

        m_value.pictures.sort((a,b) => a.version - b.version)

        let frontImages= m_value.pictures.filter(p => !!p.frontImage);
        let backImages= m_value.pictures.filter(p => !!p.backImage);

        let frontImage= null;
        let backImage= null;

        if( frontImages.length ) {
            frontImage= frontImages[frontImages.length-1]
        }

        if( backImages.length ) {
            backImage= backImages[backImages.length-1]
        }

        if( this.props.isAdmin ) {

            return <div style={{position: 'absolute', zIndex: 7, left: 16, top: 16}}>
                <div style={{position: 'fixed', left: 0, top: 100, marginLeft: 16}}>
                    {this.renderMenu(project, m_value, frontImage, backImage)}
                </div>
            </div>


        }
        else {

            return <div style={{height: '80vh', margin: -14, backgroundColor: '#504c4c'}} ref={ (e) => this.viewport = e}>

                {(frontImage && !m_value.bottom) ? <MapInteractionCSS
                    style={{cursor: 'auto'}}
                    showControls={true}
                    disablePan={!this.state.pan}
                    disableZoom={!this.state.pan}
                    scale={this.state.scale}
                    translation={this.state.translation}
                    onChange={({scale, translation}) => {
                        this.setState({scale: scale, translation: translation})
                    }}
                >

                    <img style={{maxWidth: 'initial'}} alt='Front' src={'/api/project/getAttachment?id=' + frontImage.frontImage.id}
                         onLoad={(e) => {
                             this.setState({scale: this.viewport.clientWidth / e.target.width, translation: {x:0, y:0},})
                         }}
                    />

                </MapInteractionCSS> : ''}

                {(backImage && m_value.bottom) ? <MapInteractionCSS
                    style={{cursor: 'auto'}}
                    showControls={true}
                    disablePan={!this.state.pan}
                    disableZoom={!this.state.pan}
                    scale={this.state.scale}
                    translation={this.state.translation}
                    onChange={({scale, translation}) => {
                        this.setState({scale: scale, translation: translation})
                    }}
                >

                    <img style={{maxWidth: 'initial'}} alt='Front' src={'/api/project/getAttachment?id=' + backImage.backImage.id}
                         onLoad={(e) => {
                             this.setState({scale: this.viewport.clientWidth / e.target.width, translation: {x:0, y:0},})
                         }}
                    />

                </MapInteractionCSS> : ''}

            </div>


        }

    }
}

ProjectStepFotoItem.propTypes = {
    isAdmin: PropTypes.bool,
    onSave: PropTypes.func
};

ProjectStepFotoItem.defaultProps = {
    isAdmin: true,
    onSave: null
};

const mapStateToProps = (state) => {
    return {
        lang: state.main.lang,
        pcbs: state.project.pcbs,
        project: state.project.project,
        pcbMenu: state.project.pcbMenu,
        pcbWapperMode: state.project.pcbWapperMode,
        showTop: state.project.showTop,
    }
}

export default connect(mapStateToProps)(ProjectStepFotoItem)
