import React from 'react'
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {renderSupplier, renderSupplierUrl} from "./functions";
import {getPriceOnePartMoq, renderEuro} from "./BomOrderparts";
import {Popup} from "semantic-ui-react";
import BomOrderpartStock from "./BomOrderpartStock";
import BomMoqDisplay from "./BomMoqDisplay";

class RenderPartValue extends React.Component {
    render() {
        const {id, orderparts, todos, selectedSupplierPart, selectedBuddyPart, selectedOrderPart, designators, amount, extraAmount, opcode} = this.props;

        const printAmount = this.props.print.amount;

        return renderSelectpartsEx({id, orderparts, todos, selectedSupplierPart, selectedBuddyPart, selectedOrderPart, designators, amount, extraAmount}, opcode, printAmount)
    }
}

let PartValue = connect((state) => {
    return {
        print: state.bom.print,
    }
})(RenderPartValue)

export function renderSelectparts({id, orderparts, todos, selectedSupplierPart, selectedBuddyPart, selectedOrderPart, designators, amount, extraAmount}, opcode) {

    return <PartValue id={id} orderparts={orderparts} todos={todos} selectedSupplierPart={selectedSupplierPart}
                       selectedBuddyPart={selectedBuddyPart} selectedOrderPart={selectedOrderPart}
                       designators={designators} amount={amount} extraAmount={extraAmount} opcode={opcode}/>
}

function renderSelectpartsEx({id, orderparts, todos, selectedSupplierPart, selectedBuddyPart, selectedOrderPart, designators, amount, extraAmount}, opcode, printAmount) {

    if( selectedSupplierPart ) {

        switch(opcode) {
            case 'Manufacturer':
                return <>{selectedOrderPart.manufacturer}</>
            case 'MPN':
                return <>{selectedOrderPart.mpn}</>
            case 'Spec':
                return <>{(selectedOrderPart.specification && selectedOrderPart.specification.displayValue) ? selectedOrderPart.specification.displayValue : selectedOrderPart.shortDescription}</>
            case 'Packing':
                return <>{selectedSupplierPart.supplierPackage ? selectedSupplierPart.supplierPackage : ''}</>
            case 'Supplier':
                return <>
                    {renderSupplier({...selectedSupplierPart, sku: ''})}
                </>
            case 'SKU':
                return <>
                    {selectedSupplierPart.sku}
                </>
            case 'Price':
                return <>
                    {/*Single price*/}
                    {renderEuro( getPriceOnePartMoq(selectedSupplierPart, amount, printAmount).price )}
                </>
            case 'PriceSum':
                return <>
                    {/*Total price, not taking moq into count*/}
                    {JSON.stringify( getPriceOnePartMoq(selectedSupplierPart, amount, printAmount) )}
                </>
            case 'PriceSumWithMoq':

                let sum = getPriceOnePartMoq(selectedSupplierPart, amount, printAmount)

                return <Popup position='top right' wide='very' content={<div>
                    <div>{'Nodig voor 1 print: '}{amount}</div>
                    <div>{'Extra bestellen: '}{extraAmount}</div>
                    <div>{'Aantal te bestellen voor '}{printAmount}{' printen: '}{sum.need}</div>
                    <div>{'Totaal kosten: '}{renderEuro(sum.price * sum.need)}</div>
                </div>} trigger={<div>{renderEuro(sum.price * amount)}</div>}/>
            case 'Sum':
                return <>
                    {amount * printAmount}
                </>
            case 'Stock':
                let requiredAmount = (amount * printAmount)
                return <BomOrderpartStock sp={selectedSupplierPart} requiredAmount={requiredAmount}/>
            case 'MOQ':
                return <>
                    <BomMoqDisplay moq={selectedSupplierPart.moq}/>
                </>
        }

    }

    if( selectedBuddyPart ) {
        switch (opcode) {
            case 'Price':
                return <>
                    {renderEuro(selectedBuddyPart.price1)}
                </>
            case 'PriceSumWithMoq':
                return <>
                    {renderEuro(selectedBuddyPart.price1 * amount)}
                </>
            case 'MOQ':
                return <>
                    1
                </>
        }
    }

    return <div></div>
}
class BomSelectedPartView extends React.Component {

    render() {
        const part = this.props.part;
        const orderpart = this.props.part.orderpart

        if( !part ) {
            return <></>
        }

        if( this.props.isKlaView ) {
            return <>
                <div className='btitem'>{part.name}</div>
                <div className='btitem'>{part.mpn}</div>
                <div className='btitem'>
                    {part.manualselection ? renderSupplier({supplier: 'MANUAL', sku: ''}) :  renderSupplier({supplier: 'KLANT', sku: ''})}
                </div>
            </>
        }

        if( this.props.isBuddyPart ) {
            return <>
                <div className='btitem'>
                    {(orderpart.specification && orderpart.specification.displayValue) ? orderpart.specification.displayValue : orderpart.shortDescription}
                </div>
                <div className='btitem'>
                    {orderpart.manufacturer}{" "}{orderpart.mpn}
                </div>
                <div className='btitem'>{renderSupplier({supplier: 'DELTAPROTO', sku: part.name})}</div>
            </>
        }

        if( !orderpart ) {
            return <></>
        }

        return (
            <>
                <div className='btitem'>
                    {(orderpart.specification && orderpart.specification.displayValue) ? orderpart.specification.displayValue : orderpart.shortDescription}
                </div>
                <div className='btitem'>
                    {orderpart.manufacturer}{" "}{orderpart.mpn}
                </div>
                <div className='btitem'>
                    {renderSupplier({...part})}{" "}{renderSupplierUrl(part)}
                </div>
            </>
        );
    }

}


BomSelectedPartView.propTypes = {
    part: PropTypes.object.isRequired,
    printId: PropTypes.string,
    isKlaView: PropTypes.bool,
    isBuddyPart: PropTypes.bool,
};

BomSelectedPartView.defaultProps = {
    part: null,
    printId: '',
    isKlaView: false,
    isBuddyPart: false,
};

const mapStateToProps = (state) => {
    return {
        currentUser: state.main.currentUser,
        print: state.bom.print,
    }
}

export default connect(mapStateToProps)(BomSelectedPartView)