import { formatDateTime, hasRole, isAdmin, isEmployee } from "../actions/functions";
import { Component } from 'react';
import {
    Button,
    Checkbox,
    Confirm,
    Dropdown, Form,
    Header,
    Icon,
    Loader,
    Menu,
    Modal,
    Popup,
    Segment,
} from "semantic-ui-react";

import connect from "react-redux/es/connect/connect";
import {
    addStompClientSubscription, autoSelectBom,
    autoSelectBomLine, checkBom,
    checkBomLine,
    closeBomSearch,
    closeImportErrorDialog, deleteBomProductionLine, enableMohicanFeedbackControl,
    expireBomOrderItem,
    getBomList,
    getBomOrders,
    getBomProduction, getChatForPrint,
    getChatForProjectOrder,
    getLastUsedBoardLocations,
    getSuppliers, importBOM2, importCentroid2, loadCentroid,
    mohicanSendMessage,
    openBom,
    openBomOrder, openBomOrderMove,
    openBomSearch,
    openBugReport,
    postChatMessage,
    receivePrint, saveBom,
    selectDesignatorBomProduction, setBomFilter,
    setBomLineExtraAmount,
    setBomSearch,
    setBomSearchRef,
    setBomSort,
    setBomView,
    setMarked,
    setSelectedBomRow, setSelectedBuddyPart,
    setSelectedSupplierPart, showOrderpartInfo,
    startReceivePrint,
    toggleAggregateUpload,
    unsetSelectedSupplierPart, updateMohicanSetting
} from "../actions";
import PropTypes from "prop-types";
import Translate from "../Translate";
import ChatInput from "../chat/ChatInput";
import ChatView from "../chat/ChatView";
import UploadFile from "../oldui/UploadFile";
import { mohicanCameraResolutionOptions } from "../reducers/bom";
import MailBuilder from "../tools/MailBuilder";
import BomAddRow from "./BomAddRow";
//import BomCamera from "./BomCamera";
import BomCentroidView from "./BomCentroidView";
import { getChatLinesByRef } from "./BomChat";
import BomCheckin from "./BomCheckin";
import BomCheckinHeader from "./BomCheckinHeader";
import BomCustomerConfirm from "./BomCustomerConfirm";
import BomCustomerData from "./BomCustomerData";
import BomCustomerView from "./BomCustomerView";
import {
    getOrderItemsByRow,
    getOrderItemsByType
} from "./functions";
import BomDesignator from "./BomDesignator";
import BomImportAndEdit from "./BomImportAndEdit";
import BomImportErrorDialog from "./BomImportErrorDialog";
import BomMenu from "./BomMenu";
import BomMicroPlacerPartSelectionSettings from "./BomMicroPlacerPartSelectionSettings";
import BomMohican from "./BomMohican";
import BomMoqDisplay from "./BomMoqDisplay";
import BomOrder from "./BomOrder";
import BomOrderCostTable from "./BomOrderCostTable";
import {
    renderDecimalPlaces
} from "./functions";
import BomOrderparts from "./BomOrderparts";
import BomProduction from "./BomProduction";
import BomProductionHotkeyHandler from "./BomProductionHotkeyHandler";
import BomProductionSegment from "./BomProductionSegment";
import { defaultRemarks } from "./BomRemarkEdit";
import BomSearchDialog from "./BomSearchDialog";
import BomSearchPart from "./BomSearchPart";
import BomSelectExistingCentroid from "./BomSelectExistingCentroid";
import BomSelectedDesignator from "./BomSelectedDesignator";
import BomSelectedHistory from "./BomSelectedHistory";
import BomSelectedPartImage from "./BomSelectedPartImage";
import BomSelectedPartPrice from "./BomSelectedPartPrice";
import { renderSelectparts } from "./BomSelectedPartView";
import BomSelectionTools from "./BomSelectionTools";
import BomStock from "./BomStock";
import { BomTodo } from "./BomTodo";
import BomUpdateStockForProject from "./BomUpdateStockForProject";
import BomValidationButton from "./BomValidationButton";
import BomValidationMessage from "./BomValidationMessage";
import BomValidationNew from "./BomValidationNew";
import CheckinOrders from "./CheckinOrders";
import PartInfo from "./PartInfo";
import {
    checkHasCentroid,
    encodeProjectId, fetchAllBomData, getClassName, getClassNameForRow, getDesignatorColor,
    getOrdersForRow,
    getPriceForAmount, getRows, getSelectedSuppliers,
    hideFooter,
    isInTodos,
    renderSupplier,
    renderSupplierUrl, defaultSelectedColumns
} from "./functions";
import {withRouter} from "react-router-dom";

import { WEBCAM_CAMERA_HEIGHT, WEBCAM_CAMERA_WIDTH } from "../bom/functions";
import BomCamera from "./BomCamera";

class BomMicroPlacer extends Component {

    state= {
        cameraZoom: 1.0,
        showCamera: true,
        activeName: 'bom',
        setCrosshair: false,
        project: null,
        user: null,
        visible: false,
        selectedColumn: null,
        columns: [
            {id: 0,  w: 80, show: true, name: '#', sort: 'row', filter: [
                {id: 'marked', text: <div><Icon name='check square outline'/></div>},
                {id: 'notmarked', text: <div><Icon name='square outline'/></div>},
                {id: 'comment', text: <div><Icon name='pencil alternate'/></div>},
                ], render: (r,i,ri,d) => <div>

                    {(!r.pcbOrder && !r.stencilOrder) && <div style={{float: 'right'}}>
                        <Checkbox
                            checked={r.marked} disabled={!(isEmployee(this.props.currentUser) || hasRole(this.props.currentUser, 'ROLE_MICROPLACER'))} onChange={() => {
                                this.props.dispatch(setMarked(this.props.print.id, r.id, !r.marked))
                            }}
                        />
                    </div>}

                    {d ? <Dropdown className="designator" icon={null} text={<div className='btitem'>{r.row}</div>}>
                        <Dropdown.Menu>
                            <Dropdown.Item disabled={true} icon='trash' text='Regel verwijderen'/>
                        </Dropdown.Menu>
                    </Dropdown> : <div className='btitem'>{r.row}</div>}
                    {!d && <>
                        <div className='btitem'></div>
                        <div className='btitem' style={{textAlign: 'right'}}>
                            <Popup trigger={<Icon.Group>
                                <Icon link name='chat' color='blue'
                                      onClick={() => {
                                          this.props.dispatch(postChatMessage(this.props.chat.id, '', r.id, null))
                                      }}/>
                                <Icon link corner name='add' size='small' color='white' inverted style={{bottom: 9, right: 7.5, textShadow: 'none'}}
                                      onClick={() => {
                                          this.props.dispatch(postChatMessage(this.props.chat.id, '', r.id, null))
                                      }}/>
                            </Icon.Group>} content={<Translate value={'post_new_chat_in_bom'}/>}/>
                        </div>
                    </>}
            </div>},

            {id: 1,  w: 300, show: true, name: 'Designators', render: (r,i) => <BomDesignator values={r.designators} bomLineId={r.id} locked={this.props.locked}/>},
            {id: 31,  w: 100, show: true, name: 'Designators', render: (r,i) => <BomDesignator values={r.designators} collapsed={true} bomLineId={r.id} locked={this.props.locked}/>},

            {id: 8,  w: 80, show: true, name: 'Qty', render: (r,i) => {
                if( r.pcbOrder ) {
                    return <div>{this.props.print.amount}+</div>
                }
                if( r.stencilOrder ) {
                    return <div>{this.props.print.projectOrder ? this.props.print.projectOrder.calculatorDetails.sides : ""}</div>
                }

                let dnpCount = r.designators.filter(d => !d.removed).filter(d => !d.place).length;
                let removedCount = r.designators.filter(d => d.removed).length;

                return <>
                    <div className='btitem'>{r.designators.filter(d => d.place).length}</div>
                    <div className='btitem'>{removedCount ? <p>{removedCount} x REM.</p> : ''}</div>
                    <div className='btitem'>{dnpCount ? <p>{dnpCount} x DNP</p> : ''}</div>
                </>
            }},

            {id: 30,  w: 80, show: true, name: 'Cap/Res', render: (r,i) => <>
                    <div className='btitem'>{r.isResistor ? 'Res' : r.isCapacitor ? 'Cap' : ''}</div>
                    <div className='btitem'/>
                    <div className='btitem'>{/*{r.comment}*/}</div>
                </>},

            {id: 2,  w: 60, show: false, name: 'Des.', isDesignator: true, sort: 'designator', render: (r,i,ri,d) => d.value},
            {id: 3,  w: 60, show: false, name: 'X', center: true, isDesignator: true, render: (r,i,ri,d) => <div style={{textAlign: 'center'}}>
                    {renderDecimalPlaces(d.x)}
                </div>},
            {id: 4,  w: 60, show: false, name: 'Y', center: true, isDesignator: true, render: (r,i,ri,d) => <div style={{textAlign: 'center'}}>
                    {renderDecimalPlaces(d.y)}
                </div>},
            {id: 5,  w: 60, show: false, name: 'Rot.', center: true, isDesignator: true, render: (r,i,ri,d) => <div style={{textAlign: 'center'}}>
                    {d.rotate}
            </div>},
            {id: 6,  w: 60, show: false, name: 'Side', center: true, isDesignator: true, render: (r,i,ri,d) => <div>
                    {d.topBottom ? d.topBottom.toLowerCase() : ''}
                </div>},
            {id: 7,  w: 50, show: false, name: <div><Popup trigger={<div>DNP</div>} content={<div>Do Not Place</div>}/></div>, center: true, isDesignator: true, render: (r,i,ri,d) => <div style={{textAlign: 'center'}}>
                    {d.place ? '' : <div><Popup trigger={<div>DNP</div>} content={<div>Do Not Place</div>}/></div>}
                </div>},
            {id: 33,  w: 220, show: false, name: 'Supplier SKU', render: (r,i,ri) => {
                return <div>
                    {renderSelectparts(r, 'Supplier')} {renderSelectparts(r, 'SKU')}{" "}{r.selectedSupplierPart ? renderSupplierUrl(r.selectedSupplierPart) : ''}
                    {r.klantLevertAan ? renderSupplier({supplier: 'KLANT', sku: r.klantLevertAan.sku ? r.klantLevertAan.sku : r.klantLevertAan.mpn}) : ''}
                </div>}},

            {id: 9,  w: 430, show: true, className: 'customer-divider',
                name: <div><div>Value</div><div>Manuf & MPN <span style={{color: '#2fba51'}}>(Art. No.)</span></div><div>Supplier & SKU</div></div>,
                render: (r,i) => <BomCustomerData r={r} printId={this.props.print.id} isAdmin={isAdmin(this.props.currentUser)} />},

            {id: 10,  w: 200, show: false, name: 'MPN', render: (r,i) => r.orderparts && r.orderparts.length ? this.renderBomparts(r.id, r.specification, r.orderparts, r.todos, 'MPN') : this.renderTodos(r.todos, 'MPN')},
            {id: 11, w: 200, show: false, name: 'MPN Specs', render: (r,i) => r.orderparts && r.orderparts.length ? this.renderBomparts(r.id, r.specification, r.orderparts, r.todos, 'MPN Spec') : ''},
            {id: 12, w: 200, show: false, name: 'Supplier', render: (r,i) => r.orderparts && r.orderparts.length ? this.renderBomparts(r.id, r.specification, r.orderparts, r.todos, 'Supplier') : ''},
            {id: 13, w: 200, show: false, name: 'SKU', render: (r,i) => r.orderparts && r.orderparts.length ? this.renderBomparts(r.id, r.specification, r.orderparts, r.todos, 'SKU') : this.renderTodos(r.todos, 'SKU')},
            {id: 14, w: 200, show: false, name: 'Specs', render: (r,i) => this.renderBomparts(r.id, r.specification, r.orderparts, r.todos, 'Spec')},

            {id: 15, w: 200, show: false, name: '|', render: (r,i) => <div></div>},

            {id: 16, w: 154, show: true, name: 'Search', render: (r,i) => this.renderSearchPart(r)},

            {id: 17, w: 80, show: true, name: 'Image', render: (r,i) => <BomSelectedPartImage orderPart={r}/>},

            {id: 18, w: 200, show: false, name: 'Stock', render: (r,i) => renderSelectparts(r, 'Stock')},
            {id: 19, w: 200, show: false, name: 'MOQ', render: (r,i) => renderSelectparts(r, 'MOQ')},

            {id: 20, w: 200, show: false, name: 'Analysis', render: (r,i) => this.renderValidation(r)},
            {id: 21, w: 550, show: false, name: 'Analysis', render: (r,i) => this.renderSelectFromValidation(r, false)},
            {id: 22, w: 550, show: false, name: 'Analysis data', render: (r,i) => this.renderSelectFromValidation(r, true)},
            {id: 32, w: 1500, show: false, name: <div>
                    <div>Analysis</div>
                    <BomValidationButton/>
            </div>, render: (r,i) => this.renderValidationNew(r)},

            {id: 23, w: 360, show: true,
                filter: [
                    {id: 'nopart', text: 'Geen part geselecteerd'},
                    {id: 'nostock', text: 'Geen voorraad'},
                    {id: 'supplier', text: 'Leverancier: '}
                ],
                name: <div><div>Value</div><div>Manuf & MPN</div>
                    <div style={{float: 'right'}}>
                    <BomSelectionTools/>
                    </div>
                    <div>Selected Supplier & SKU</div>

                </div>,
                render: (r,i) => this.renderSelectedpart(r)},

            {id: 24, w: 180, show: true, name: <div>
                    <div className='cornertopright'><div>Price</div><div>Sum</div></div>
                    <div>Stock <BomUpdateStockForProject/></div>
                    <div>MOQ</div><div>Packing</div>
                </div>, render: (r,i) => <BomSelectedPartPrice r={r}/>},

            {id: 36, w: 180, show: true, name: <div>
                    <div>Ordered</div>
                </div>, render: (r,i) => {
                    if( r.pcbOrder || r.stencilOrder ) {
                        return this.renderBomOrderForPcbOrStencil(r)
                    }
                    return this.renderBomOrderForRow(r)
                }},
            {id: 25, w: 100, show: true, name: <div>
                    <div className='cornertopright'><div>Stock</div></div>
                    <div>Need</div><div></div>
                </div>, render: (r,i) => {
                    return <BomStock r={r}/>
                }},
            {id: 34, w: 555, show: true, userOnly: true, name: <BomCheckinHeader/>, render: (r,i) => <BomCheckin r={r} isAdmin={this.getIsAdmin()}/>},
            {id: 26, w: 555, show: true, adminOnly: true, name: <BomCheckinHeader/>, render: (r,i) => <BomCheckin r={r} isAdmin={this.getIsAdmin()}/>},
            {id: 27, w: 60, show: true, adminOnly: true, name: 'TrLane', center: true, sort: 'boardLocation', render: (r,i) => <BomCheckin r={r} showTrayOnly={true} isAdmin={this.getIsAdmin()}/>},
            {id: 28, w: 100, show: true, adminOnly: true, name: 'Stock', render: (r,i) => <BomCheckin r={r} showStockOnly={true} isAdmin={this.getIsAdmin()}/>},
            {id: 29, w: 120, show: false, name: 'Actie', center: true, isDesignator: true, render: (r,i,ri,d,nextD,firstD,lastD, nextNextD) => <BomProduction r={r} d={d} nextD={nextD} firstD={firstD} lastD={lastD} nextNextD={nextNextD}/>},

        ]
    }

    componentDidMount() {

        hideFooter()

        this.props.dispatch(getBomList(true))

        window.addEventListener('mousemove', this.onMouseMove.bind(this), false);
        window.addEventListener('mouseup', this.onMouseUp.bind(this), false);

        this.handleMouseDown = this.handleMouseDown.bind(this)

        /*let id= this.props.match ? this.props.match.params.bomId : null;

        if( id ) {
            if( id !== 'new' ) {
                fetchAllBomData(id, null, null, this.props.dispatch)
            } else {
                this.props.dispatch(clearBomData())
            }
        }*/

        if( this.props.projectOrderId ) {
            fetchAllBomData(null, this.props.projectOrderId, null, this.props.dispatch)
        }

        this.props.dispatch(getSuppliers())

        if( this.props.view ) {
            this.onViewChange(this.props.view)
        } else {
            this.onViewChange('bom')
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        if( prevProps.view !== this.props.view ) {
            this.onViewChange(this.props.view)
        }
        /*let id = this.props.projectOrderId;

        if( prevProps.projectOrderId !== id && id ) {
            this.props.dispatch(openBomByProjectOrderId(id), (value) => {
                fetchAllBomData(value.key, null, this.props.dispatch)
            })
        }*/

    }

    renderValidationButton(r) {

        let hasTodos= r.todos.filter(t => !t.done).length

        if( !hasTodos ) {
            return <div>
                {r.orderparts.length} options found

                {r.orderparts.length ? <div className='cornertopright'>
                    <Icon name='external alternate' style={{cursor: 'pointer'}} onClick={() => this.setState({open: r.id})}/>
                </div> : ''}

                <Modal size={'small'} open={this.state.open === r.id} onClose={() => this.setState({open: false})} closeIcon dimmer={false}>
                    <Modal.Header>Customer data</Modal.Header>
                    <Modal.Content scrolling>

                        <BomCustomerView r={r}/>

                        <div style={{marginTop: 16}}>
                            <h3>Found parts:</h3>
                            <BomOrderparts parts={r.amount} amount={this.props.print.amount} list={r.orderparts} todos={r.todos}/>
                        </div>


                    </Modal.Content>
                    <Modal.Actions>
                        <Button
                            content='Close'
                            onClick={() => this.setState({open: false})}
                        />
                    </Modal.Actions>
                </Modal>
            </div>
        }

        if( r.busy ) {
            return <p>een moment...</p>
        }
        if( r.selectedSupplierPart !== null ) {
            return <p></p>
        } else if ( r.orderparts && r.orderparts.length ) {
            return <p>Maak een keuze</p>
        } else {
            return <a href="#/" onClick={() => !r.busy && this.props.dispatch(checkBomLine(this.props.print.id, r.id))}>{r.busy ? 'een moment..' : 'valideren'}</a>
        }
    }

    renderValidation(r) {

        if( r.pcbOrder || r.stencilOrder ) {
            return <div></div>
        }

        return <div>

            {this.renderValidationButton(r)}

            <BomValidationMessage value={r} />

        </div>
    }

    renderValidationNew(r) {
        if( r.pcbOrder || r.stencilOrder ) {
            return <div></div>
        }

        return <div>

            <BomValidationNew value={r} printId={this.props.print.id} />

        </div>
    }

    renderSelectFromValidation(r, allowSelect) {
        return <div>

            {r.checking && <div style={{float: 'right'}}><Icon loading name='spinner' color='blue'/></div>}

            {r.orderparts.length ? <BomOrderparts parts={r.amount} amount={this.props.print.amount} showStockAndPrice={allowSelect} list={r.orderparts} todos={r.todos}
                onSupplierpart={allowSelect ? (sp) => this.props.dispatch(setSelectedSupplierPart(r.id, sp.id)) : null}
            /> : <div>
                <button className='link-button' onClick={() => this.props.dispatch(checkBomLine(this.props.print.id, r))}>valideren</button>
            </div>}

        </div>
    }


    componentWillUnmount(){
        window.removeEventListener('mousemove', this.onMouseMove.bind(this), false);
        window.removeEventListener('mouseup', this.onMouseUp.bind(this), false);

        if( this.stompClient ) {
            this.stompClient.disconnect(() => {
                console.log('disconnected')
            });

        }
    }

    addStompSubscription = (subscription) => {
        this.props.dispatch( addStompClientSubscription(subscription) );
    }

    onMouseMove = (e) => {
        if( this.state.isResizing ) {

            const newWidth =  this.state.startWidth + (e.clientX - this.state.startX);

            this.setState({
                columns: this.state.columns.map((c, i) => {
                    return (c.id === this.state.colResizing) ? Object.assign({}, c, {w: newWidth}) : c
                })
            });

            //this.props.funcResizing( this.props.id, e.clientX, e.clientY);
        }
    }
    onMouseUp = (e) => {
        this.setState({
            isResizing: false,
        });

    }

    handleMouseDown = (event, t, col) => {

        let startWidth= this.state.columns.filter((c, i) => c.id === parseInt(col))[0].w;

        this.setState({
            startX: event.clientX,
            startWidth,
            isResizing: true,
            colResizing: col
        });

    };

    renderTodos(todos, opcode) {

        return <BomTodo todos={todos} opcode={[opcode]}/>
    }

    renderSkuOrderPart = (s, p, todos, opcode) => {
        switch(opcode) {
            case 'Price':
                return getPriceForAmount(s, 1);
            case 'Stock':
                return <div>
                    {s.stock ? s.stock : '?'}
                    {(s.stockUS != null && s.stockUS > 0) ? <Popup trigger={<span>{` (${s.stockUS} 🇺🇸)`}</span>} content={<p>US Stock</p>}/>  : ''}
                </div>
            case 'MOQ':
                return <BomMoqDisplay moq={s.moq}/>
            case 'SKU':
                return s.sku ? s.sku : '?';
            default:
                return renderSupplier(s);
        }
    }

    renderBomparts(bomLineId, specification, orderparts, todos, opcode) {

        if( opcode === 'MPN' || opcode === 'MPN Spec' ) {

            return <div>

                {orderparts && orderparts.map((p, i) => (<div style={{borderTop: i ? '1px solid lightgray' : ''}}>

                    {opcode === 'MPN' ?
                        <div style={{
                            visibility: isInTodos(p.mpn, 'MPN', todos) ? '' : 'hidden',
                            //fontWeight: this.isInTodos(p.mpn, 'MPN', todos) ? 'bold' : ''
                        }}>{p.mpn}</div>
                        :
                        <div style={{
                            visibility: isInTodos(p.mpn, 'MPN', todos) ? '' : 'hidden',
                            //color: 'gray'
                        }}>{
                            (p.normalizedComponentSpecification && p.normalizedComponentSpecification.displayValue) ? p.normalizedComponentSpecification.displayValue : '...'
                        }</div>
                    }

                    {/*{p.suppliers.slice(1).map(s => (<div style={{visibility: 'hidden'}}>
                        ...
                    </div>))}*/}

                </div>))}

            </div>

        } else if( opcode === 'Spec') {
            return <div>
                {(specification && specification.displayValue) ? specification.displayValue : ''}
            </div>
        } else {

            return <div>

                {orderparts && orderparts.map((p, i) => (<div style={{borderTop: i ? '1px solid lightgray' : ''}}>

                    <div>
                        {p.suppliers.filter(s => isInTodos(s.sku, 'SKU', todos)).map(s => (<div style={{cursor: 'pointer'}}
                                                                                                     onClick={() => this.props.dispatch(setSelectedSupplierPart(this.props.print.id, bomLineId, s.id))}>

                            {this.renderSkuOrderPart(s, p, todos, opcode)}

                        </div>))}

                        {p.suppliers.filter(s => isInTodos(s.sku, 'SKU', todos)).length === 0 && <div style={{visibility: 'hidden'}}>
                            ...
                        </div>}
                    </div>

                </div>))}

            </div>
        }
    }

    filterForSelectedSupplier(op, selectedSupplierPart) {

        if( selectedSupplierPart === null ) {
            return true;
        }

        for (let i = 0; i < op.suppliers.length; i++) {
            if( op.suppliers[i].id === selectedSupplierPart.id ) {
                return true;
            }
        }

        return false;
    }

    getIsAdmin() {
        return isEmployee(this.props.currentUser) || hasRole(this.props.currentUser, 'ROLE_MICROPLACER')
    }

    renderSearchPart(r) {

        if( r.stencilOrder || r.pcbOrder ) {
            return <div></div>
        }

        return <div>
            <BomSearchPart id={r.id} isAdmin={this.getIsAdmin()} isMicroplacer={true} locked={this.props.locked}/>
        </div>
    }

    renderBuddyPart(r, bp, action = '') {
        return <div>
            <div className='btitem'>{bp.orderpart.specification.displayValue}</div>
            <div className='btitem'>{bp.orderpart.manufacturer} {bp.orderpart.mpn}</div>
            <div className='btitem'>{renderSupplier({supplier: 'DELTAPROTO', sku: `${bp.name} [EB-${bp.machine} ${bp.location}]`})}
                <div style={{flex: 1}}></div>
                <div style={{display: 'flex'}}>
                    {action}
                    {!this.props.locked ? <div>
                    <Icon style={{cursor: 'pointer', paddingTop: 1}} onClick={() => this.props.dispatch(unsetSelectedSupplierPart(r.id, ''))} name='remove circle' color='red'/>
                    </div> : ''}</div>
            </div>
        </div>
    }

    renderSelectedpart(r) {

        const admin = isEmployee(this.props.currentUser) || hasRole(this.props.currentUser, 'ROLE_MICROPLACER');

        if( r.stencilOrder || r.pcbOrder ) {

            let orderItems = getOrderItemsByType(r.pcbOrder ? 'PCB' : 'STENCIL', this.props.bomOrderList)

            if (orderItems.length === 0) {
                if( admin ) {
                    return <button className='link-button' onClick={() => {
                        this.props.dispatch(openBomOrder(true))
                        this.props.dispatch(getBomOrders(this.props.print.id))
                    }}>
                        No order placed
                    </button>
                } else {
                    return 'No order placed'
                }
            }

            return <div>
                {orderItems.map(o => {
                    if( admin ) {
                        return <div>
                            <button key={o.id} className='link-button' onClick={() => {
                                this.props.dispatch(openBomOrder(true))
                                this.props.dispatch(getBomOrders(this.props.print.id))
                            }} disabled={!admin}>
                                <Popup hoverable wide='very' trigger={renderSupplier({supplier: o.supplier, sku: o.supplierOrderCode})}
                                    content={o.orderDetails ? <div>
                                        {Object.entries(o.orderDetails).map(([key, value]) => {
                                            return <div>{key}: {value}</div>
                                        })}
                                    </div> : formatDateTime( o.orderDate )}
                                />
                            </button>
                        </div>
                    } else {
                        return <div>
                            {renderSupplier({supplier: o.supplier, sku: o.supplierOrderCode})}
                        </div>
                    }
                })}
            </div>
        }

        if( !r.place ) {
            return <div>
                DNP
                {(r.selectedSupplierPart || r.selectedBuddyPart) && <div style={{float: 'right', color: '#db2828'}}>
                    Er is nog een part geselecteerd! <Icon style={{cursor: 'pointer'}}
                                                     onClick={() => this.props.dispatch(unsetSelectedSupplierPart(r.id, ''))}
                                                     name='remove circle' color='red'/></div>}
            </div>
        }

        let notSelected = (!r.selectedSupplierPart && !r.selectedBuddyPart && !r.klantLevertAan)

        let noStock = r.orderparts.length > 0;
        r.orderparts.forEach(o => {
            o.suppliers.forEach(s => {
                if( s.stock > 0 ) {
                    noStock = false;
                }
            })
        });

        let alert = '';
        if (notSelected && noStock) {
            alert = defaultRemarks[1]
        }

        let orders = [];
        if( (!notSelected && r.selectedSupplierPart) || r.klantLevertAan) {
            orders = getOrdersForRow(r, this.props.bomOrderList);
        }

        return <div>

            {(this.state.unsetSelectedSupplierPart === r.id) ? <Confirm
                content={<Modal.Content>
                    Er {orders.length > 1 ? 'zijn' : 'is'} {orders.length} {orders.length > 1 ? 'onderdelen' : 'onderdeel'} besteld
                    wilt u deze laten vervallen uit de volgende bestelling(en)?
                    <ul>
                        {orders.map(o => (<li>
                            {renderSupplier({supplier: o.supplier, sku: o.supplierOrderCode})} {o.status}
                        </li>))}
                    </ul>

                </Modal.Content>}
                open={this.state.unsetSelectedSupplierPart === r.id}
                onCancel={(e) => {
                    this.setState({unsetSelectedSupplierPart: null});
                    e.preventDefault();
                }}
                onConfirm={(e) => {
                    this.props.dispatch(unsetSelectedSupplierPart(r.id, '', () => {
                        this.setState({unsetSelectedSupplierPart: null});
                    }))
                    orders.forEach(o => {
                        o.items.forEach(i => {
                            this.props.dispatch(expireBomOrderItem(i.id))
                        })
                    })
                }}
            /> : ''}

            {(!notSelected && r.selectedSupplierPart) ? <>
                <div className='btitem'>{renderSelectparts(r, 'Spec')}{" "}</div>
                <div className='btitem'>{renderSelectparts(r, 'Manufacturer')} {renderSelectparts(r, 'MPN')}{" "} {this.renderShowUsedInProjects(admin, r)}</div>
                <div className='btitem'>
                    {renderSelectparts(r, 'Supplier')}{renderSelectparts(r, 'SKU')}{renderSupplierUrl(r.selectedSupplierPart)}
                    <div style={{flex: 1}}></div>
                    <div style={{float: 'right', display: 'flex'}}>

                        {admin ? <Popup trigger={
                            <Icon style={{cursor: 'pointer', marginLeft: 4}} name='shopping cart' color='grey'/>
                            /*<div className='order-extra' style={{cursor: 'pointer'}}>
                                {r.extraAmount}
                            </div>*/
                        } content={<div>
                            <Header>
                                {r.extraAmount} extra bestellen
                            </Header>

                            <Button.Group>
                                <Button onClick={() => this.props.dispatch(setBomLineExtraAmount(r.id, -10))}>-
                                    10</Button>
                                <Button onClick={() => this.props.dispatch(setBomLineExtraAmount(r.id, -1))}>-
                                    1</Button>
                                <Button.Or text={r.extraAmount} style={{color: 'black'}}/>
                                <Button onClick={() => this.props.dispatch(setBomLineExtraAmount(r.id, 1))}>+ 1</Button>
                                <Button onClick={() => this.props.dispatch(setBomLineExtraAmount(r.id, 10))}>+
                                    10</Button>
                            </Button.Group>

                        </div>} position='top right' hoverable={true} wide='very'/> : ''}

                        {admin ? <BomSelectedHistory r={r}/> : ''}

                        {this.renderSelectOtherPart(admin, r)}

                        {!this.props.locked ? <Popup trigger={
                            <Icon style={{cursor: 'pointer', marginLeft: 4}}
                                  onClick={() => {
                                      if (orders.length > 0) {
                                          this.setState({unsetSelectedSupplierPart: r.id})
                                      } else {
                                          this.props.dispatch(unsetSelectedSupplierPart(r.id, ''))
                                      }
                                  }}
                                  name='remove circle' color='red'/>
                        } content={'Geselecteerd part verwijderen'} position='top right'/> : ''}
                    </div>
                </div>
            </> : ''}

            {(!notSelected && r.selectedBuddyPart) ? this.renderBuddyPart(r, r.selectedBuddyPart, <>
                {admin ? <BomSelectedHistory r={r}/> : ''}
                {this.renderSelectOtherPart(admin, r)}
            </>) : ''}

            {r.klantLevertAan ? <div>
                {!this.props.locked ? <div>
                    <div style={{float: 'right', marginTop: 38}}>
                    {this.renderSelectOtherPart(admin, r)}

                    <Popup trigger={
                        <Icon style={{cursor: 'pointer'}}
                              onClick={() => {
                                  if (orders.length > 0) {
                                      this.setState({unsetSelectedSupplierPart: r.id})
                                  } else {
                                      this.props.dispatch(unsetSelectedSupplierPart(r.id, ''))
                                  }
                              }}
                              name='remove circle' color='red'/>
                    } content={'KLA verwijderen'}/>

                        </div>
                    </div>:''}

                <BomCustomerData r={r} printId={this.props.print.id} isKlaView={true}/>

            </div> : ''}

            {notSelected ? <div style={{display: 'flex'}}>
                <div style={{flex: 1}}>
                    <BomSearchPart id={r.id} alert={alert} busy={r.busy} remark={r.remark} isAdmin={this.getIsAdmin()} isMicroplacer={true} locked={this.props.locked}/>
                </div>
                {admin ? <div style={{
                    marginTop: 38
                }}>
                    <BomSelectedHistory r={r}/>
                </div> : ''}

            </div> : ''}
        </div>

    }

    renderSelectOtherPart(admin, r) {
        return <>
            {admin ? <Popup trigger={
                <Icon style={{cursor: 'pointer', marginLeft: 4}}
                      onClick={() => {
                          this.props.dispatch(setSelectedBomRow(r.id))
                          this.props.dispatch(openBomSearch(r.id, ''))
                      }}
                      name='search' color='blue'/>
            } content={'Zoek een ander part'} position='top right'/> : ''}
        </>;
    }

    renderShowUsedInProjects(admin, r) {
        const hasOrderPart = r.selectedSupplierPart && r.selectedSupplierPart.orderpart

        if( !hasOrderPart ) {
            return <></>
        }

        const washingAllowed = r.selectedSupplierPart.orderpart.washingAllowed;

        return <>
            {admin ? <Popup trigger={
                <Icon style={{cursor: 'pointer', marginLeft: 0}}
                      onClick={() => {
                          this.props.dispatch(showOrderpartInfo(r.selectedSupplierPart.orderpart.id))
                      }}
                      name='info circle' color='blue'/>
            } content={'Toon part informatie' } position='top right' hoverable/> : ''}

            {(admin && !washingAllowed)  ? <Popup trigger={
                <Icon name='shower' color='red'/>
            } content={'Part mag niet in de wasmachine' } position='top right' hoverable/> : ''}
        </>;
    }

    renderBomOrderForPcbOrStencil(r) {

        let orders= [];

        orders= this.props.bomOrderList.filter(bo => {
            return bo.supplier && bo.supplier.endsWith(r.pcbOrder ? "_PCB" : "_STENCIL")
        })

        const admin = isEmployee(this.props.currentUser) || hasRole(this.props.currentUser, 'ROLE_MICROPLACER');

        return <div>
            {(r && orders.length === 0) ? admin ? <button className='link-button' onClick={() => {
                this.props.dispatch(openBomOrder(true))
                this.props.dispatch(getBomOrders(this.props.print.id))
            }}>
                No order placed
            </button> : "No order placed" : ""}

            {(r && orders.length > 0) && orders.map((o,i) => (

                <div style={{borderTop: i ? '1px solid lightgray' : ''}}>
                    <div style={{borderTop: i ? '1px solid lightgray' : ''}}>
                        <div className='btitem'>
                            {this.renderOrderID(o)}
                        </div>
                        <div className='btitem'>
                            {renderSupplier({supplier: o.supplier, sku: o.supplierOrderCode})}
                        </div>
                    </div>
                </div>

            ))}
        </div>
    }
    renderOrderID(o){
        switch (o.supplierOrderCode) {
            case "UNKNOWN_STATUS":
                return <div>UNKNOWN_STATUS</div>
            default:
                return <div>{o.supplierOrderCode}</div>
        }
    }
    renderBomOrderForRow(r) {

        let orders= getOrdersForRow(r, this.props.bomOrderList);

        const admin = isEmployee(this.props.currentUser) || hasRole(this.props.currentUser, 'ROLE_MICROPLACER');

        return <div>

            {(r && orders.length === 0 && r.selectedSupplierPart) ? admin ? <button className='link-button' onClick={() => {
                this.props.dispatch(openBomOrder(true))
                this.props.dispatch(getBomOrders(this.props.print.id))
            }}>
                No supplier order placed
            </button> : "No supplier order placed" : ""}

            {(r && orders.length > 0) && orders.map((o,i) => (

                <div>

                    <Popup wide='very' trigger={
                        <div className='btitem' style={{borderTop: i ? '1px solid lightgray' : ''}}>
                            {o.status}
                        </div>
                    } content={<div>
                        <div className='btitem'>
                            {renderSupplier({supplier: o.supplier})} {o.supplierOrderCode}
                        </div>
                        <div className='btitem'>
                            {o.created ? 'Aangemaakt op ' + formatDateTime(o.created) : ''}
                        </div>
                        <div className='btitem'>
                            {o.orderPlaced ? 'Order geplaatst op ' + formatDateTime(o.orderPlaced) : ''}
                        </div>
                    </div>}/>

                </div>

            ))}
        </div>
    }

    getColStyle( id, header ) {

        const m_col= this.state.columns.filter(c => c.id === id)[0];

        return Object.assign({}, m_col.style ? m_col.style : {}, {
            maxWidth: m_col.w,
            minWidth: m_col.w,
            color: 'black',/*
            padding: 'unset'*/
            cursor: (m_col.sort && header) ? 'pointer' : '',
            textDecoration: (header && m_col.sort === this.props.bomSort) ? "underline" : "",
            borderRadius: 'unset',
            textAlign: m_col.center ? 'center' : '',
            overflow: 'visible'
        })

    }

    renderColResizer( col, value ) {
        return <div>
            <div>
                {value}
            </div>
            <div style={{display: 'inline-block',
                position: 'absolute',
                width: '36px',
                top: 0,
                marginTop: '24px',
                height: 'calc(100% - 24px)',
                right: '-18px',
                cursor: 'col-resize',
                zIndex: 10}}
                 onMouseDown={(e, t) => this.handleMouseDown(e, t, col.id)}
            />

        </div>
    }

    renderColHeader( col ) {

    }

    setShowColumns( g ) {

        let viewId = g.id;
        let columnIds = g.columns

        this.props.dispatch(setBomView(columnIds, viewId))

        //this.setState({columns: newList, selectedColumn: selectedColumn})
    }

    toggleShowColumn ( id ) {

        let newList= this.state.columns.map( c => {
            if( c.id === id ) {
                return Object.assign({}, c, {show: !c.show})
            } else {
                return c;
            }
        })

        //this.setState({columns: newList, selectedColumn: null})
    }



    renderPrice(part) {
        return <div>{part.price1}</div>
    }

    getOwner() {
        if( this.props.print && this.props.print.projectOrder ) {
            return this.props.print.projectOrder.owner;
        }
        return null;
    }

    renderChatLines(lines, colSpan) {

        if( lines && lines.length ) {
            return lines.map(c => (<tr key={c.id}>
                <td colSpan={colSpan}>
                    <ChatInput value={c} showDefaultReplies='list.bom.chat.defaults' mention={this.getOwner()}/>
                </td>
            </tr>))
        }
        return []
    }

    getCurrentCameraName(cameraId, cameraDevices) {
        if( cameraDevices != null ) {
            let device = cameraDevices.find((v) => v.deviceId === cameraId);

            if (device != null) {
                return device.label ? device.label : cameraId;
            }
        }

        return cameraId;
    }

    renderHeaderFilter(c) {
        if( c.filter ) {
            const bomFilter = this.props.bomFilter
            const active = c.filter.some(f => bomFilter && bomFilter.startsWith(f.id))

            return <div style={{float: 'right', height: 0, zIndex: 99}}>
                <Dropdown icon='filter' direction='left' style={{color: active ? '#2185d0' : ''}} simple>
                    <Dropdown.Menu>
                        <Dropdown.Item active={bomFilter == null || bomFilter === ''} onClick={()  => this.props.dispatch(setBomFilter(''))}>
                            Alle
                        </Dropdown.Item>
                        {c.filter.map(f => {
                            if( f.id === 'supplier' ) {

                                const suppliers = getSelectedSuppliers(this.props.print)

                                return <>
                                    {suppliers.map(s => <Dropdown.Item active={('supplier-'+s) === bomFilter} key={('supplier-'+s)}
                                                                       onClick={() => this.props.dispatch(setBomFilter(('supplier-'+s)))}>
                                        {renderSupplier({supplier: s, sku: s})}
                                    </Dropdown.Item>)}
                                </>

                            } else {
                                return <Dropdown.Item active={bomFilter === f.id} key={f.id}
                                                      onClick={() => this.props.dispatch(setBomFilter(f.id))}>
                                    {f.text}
                                </Dropdown.Item>
                            }
                        })}
                    </Dropdown.Menu>
                </Dropdown>
            </div>
        }
        return <div></div>
    }

    renderMenu(tab, admin, uplacer) {

        const hasCentroid = checkHasCentroid(this.props.print);

        const autoSelecting = this.props.print.bom.some(r => r.busy)
        const checking = this.props.print.bom.some(r => r.checking)

        switch (tab) {
            case 'bom':
                return <div className='microplacerMenu' style={{display: 'flex', gap: 8, color: 'white'}}>
                    {admin ? <>

                        <div>
                            {this.props.print.name}
                        </div>

                        {this.renderCentroid(hasCentroid)}

                        {!uplacer ? <Popup trigger={
                            <Button primary onClick={() => {
                                this.props.dispatch(openBomOrder(true))
                                setTimeout(() => {
                                    this.props.dispatch(openBomOrderMove(true))
                                }, 100);
                            }}>
                                Move stock...
                            </Button>
                        } content={<div>Move stock from existing project</div>}/> : null}

                        {uplacer ? <Button
                            primary loading={autoSelecting || checking} disabled={this.props.validatingBom || checking}
                            icon labelPosition='left'
                            onClick={() => {
                                this.props.dispatch(autoSelectBom(this.props.print.id))
                            }}
                        >
                            <Icon name='flag checkered'/>
                            Auto-Select parts
                        </Button>
                            :
                        <Button.Group color='blue' disabled={this.props.validatingBom || checking}>
                            <Button loading={autoSelecting || checking}
                                    onClick={() => {
                                        this.props.print.bom.filter(r => {
                                            return !r.selectedSupplierPart && !r.buddyPart && !r.klantLevertAan;
                                        }).map(r => {
                                            this.props.dispatch(autoSelectBomLine(this.props.print.id, r.id))
                                        })

                                        //this.props.dispatch(autoSelectBom(this.props.print.id))
                                    }}
                            >Auto-Select</Button>
                            <Dropdown
                                className='button icon'
                                floating
                                trigger={<></>}
                                disabled={this.props.validatingBom || checking}
                            >
                                <Dropdown.Menu>
                                    <Dropdown.Item
                                        onClick={() => {
                                            this.props.dispatch(autoSelectBom(this.props.print.id, null, true))
                                        }}
                                    >Auto-Select from existing project stock</Dropdown.Item>

                                    <Dropdown.Item
                                        onClick={() => {
                                            this.props.dispatch(autoSelectBom(this.props.print.id))
                                        }}
                                    >Auto-Select sequential (slower)</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </Button.Group>}

                        {/*<Button loading={autoSelecting} color='blue' disabled={this.props.validatingBom}
                                onClick={() => {
                                    this.props.print.bom.filter(r => {
                                        return !r.selectedSupplierPart && !r.buddyPart && !r.klantLevertAan;
                                    }).map(r => {
                                        this.props.dispatch(autoSelectBomLine(this.props.print.id, r.id))
                                    })
                                }}
                        >
                            Auto-Select
                        </Button>*/}

                        {/*<Button color='blue' onClick={() => this.props.dispatch(autoUpdateStockAndPrice(this.props.print.id))}>Update stock</Button>*/}

                        {(isEmployee(this.props.currentUser) && this.props.print.projectOrder) ? <div>
                            <MailBuilder
                                button={true}
                                to={this.props.print.projectOrder.owner.email}
                                mailKey='mail.readyForBom'
                                args={[
                                    this.props.print.projectOrder.owner.firstName,
                                    this.props.print.projectOrder.name + ' | ' + (this.props.print.projectOrder.reference ? (this.props.print.projectOrder.reference + ' | ') : '') + this.props.print.projectOrder.internalName,
                                    `https://www.deltaproto.com/project/${this.props.print.projectOrder.id}/buy`
                                ]}
                            >Create mail for customer</MailBuilder>
                        </div> : null}
                    </> : null}


                </div>
            case 'production':
                return <div className='microplacerMenu' style={{display: 'flex', gap: 8, color: 'white'}}>

                    <BomProductionHotkeyHandler/>

                    {hasCentroid ? <div>{this.props.print.nameCentroid}</div> : <div>Geen centroid geladen</div>}

                    {this.renderCentroid(hasCentroid)}

                    <BomProductionSegment buddyLink={false} printId={this.props.print.id}/>

                        <Popup trigger={
                            <Icon name='pin' style={{cursor: 'pointer'}}
                                  disabled={!this.props.selectedDesignator}
                                  onClick={() => {
                                      if( this.props.selectedDesignator ) {
                                          mohicanSendMessage('SET_PCB_POSITION', null)
                                      }
                                  }}/>
                        } content={this.props.selectedDesignator ? <div>
                            Position the pcb overlay at the current position of {this.props.selectedDesignator.value} {this.props.selectedDesignator.topBottom === "BOTTOM" ? '(top side)' : '(bottom side)'}
                        </div> : <div>
                            Select a designator to position the pcb overlay
                        </div>}/>

                        <Popup trigger={
                            <Icon name='crosshairs' style={{cursor: 'pointer', color: this.state.setCrosshair ? 'black' : ''}}
                                  onClick={() => {
                                      this.setState({setCrosshair: !this.state.setCrosshair})
                                  }}/>
                        } content={'Move crosshair on overlay (needle position)' + JSON.stringify(this.props.mohicanSettings.crosshair)}/>

                    <Popup trigger={
                        <Icon name='hand pointer' style={{cursor: 'pointer', color: this.props.mohicanEnableFeedbackControl ? 'var(--main-DP2Green)' : ''}} onClick={() => {
                            this.props.dispatch(enableMohicanFeedbackControl(!this.props.mohicanEnableFeedbackControl))
                        }}/>
                    } content='Move placement head using a push and pull action'/>



                </div>
            case 'valideren':
                if( this.props.print ) {
                    let todos = this.props.print.bom.map(r => r.todos.filter(t => !t.done)).flat();
                    let validatingBom = this.props.print.bom.some(r => r.checking)

                    return <></>
                    return <>
                        {todos.length ? <Popup
                                trigger={<Button loading={validatingBom} disabled={validatingBom || !todos.length}
                                                 primary={!!todos.length} onClick={() => {

                                    this.props.dispatch(setBomView([0, 8, 31, 30, 9, 32], 'valideren'))

                                    this.props.dispatch(checkBom(this.props.print.id))
                                }}>
                                    Valideren
                                </Button>} content={<p>Er moeten nog {todos.length} gegevens opgehaald worden</p>}/> :
                            <Button icon labelPosition='left' color={'green'} onClick={() => {
                                this.props.dispatch(setBomView([0, 8, 31, 30, 9, 32], 'valideren'))
                            }}>
                                <Icon name='check'/>
                                Analyse
                            </Button>}
                        {todos.length ? <Translate style={{paddingLeft: 8}} value='microplacer.analyse.info'/> : ''}
                    </>
                }
                return
            default:
                if( this.props.print ) {
                    <div className='microplacerMenu' style={{display: 'flex', gap: 8, color: 'white'}}>

                    </div>
                }
        }

        return <></>
    }

    renderCentroid(hasCentroid) {

        if(!this.props.print) {
            return <></>
        }

        return <>

            <Popup open={this.props.openeningBom ? true : undefined} trigger={<div>

                <Button.Group color='blue'>
                    <BomImportAndEdit key={'uploadcentroid'} type='centroid' printId={this.props.print ? this.props.print.id : null} onImport={(d) => {
                        this.props.dispatch(importCentroid2(d, this.props.print.id, this.props.decimalSeparator, this.props.unitOfMeasurement, (body) => {
                            this.props.dispatch(receivePrint(body))
                        }))
                    }} onReceivePrint={(body) => this.props.dispatch(receivePrint(body))} color={hasCentroid ? null : 'red'}/>
                    <BomSelectExistingCentroid printId={this.props.print ? this.props.print.id : null} onSelectCentroid={(c) => {
                        this.props.dispatch(loadCentroid(this.props.print.id, c.id, (body) => {
                            this.props.dispatch(receivePrint(body))
                        }))
                    }}/>
                </Button.Group>
                    {/*<UploadFile icon={false} text={"Upload Pick&Place file"} url={'/api/bom/importCentroid?id=' + this.props.print.id}
                                    primary={hasCentroid} color={hasCentroid ? null : 'red'} button={true}
                                    onStart={() => this.props.dispatch(startUploadCentroid())} onResult={(body) => {
                            this.props.dispatch(receivePrint(body))
                        }}
                                    onError={(message) => {
                                        this.props.dispatch(bomShowImportError([{
                                            message: message.exception + ' ' + message.message
                                        }]))
                                    }}
                        />*/}


                </div>
            } hoverable content={
                <div>
                    {hasCentroid ? <div>{this.props.print.nameCentroid}</div> : <div>No centroid</div>}
                    <div>
                        <a download href="/images/uplacer/example-centroid.xlsx">Download example Centroid</a>
                    </div>
                    {/*<div>
                        <UploadFile icon={false} text={"Upload Pick&Place [no dialog]"} url={'/api/bom/importCentroid?id=' + this.props.print.id}
                                    primary={hasCentroid} color={hasCentroid ? null : 'red'} button={true}
                                    onStart={() => this.props.dispatch(startUploadCentroid())} onResult={(body) => {
                            this.props.dispatch(receivePrint(body))
                        }}
                                    onError={(message) => {
                                        this.props.dispatch(bomShowImportError([{
                                            message: message.exception + ' ' + message.message
                                        }]))
                                    }}
                        />
                    </div>*/}
                </div>}
            />

            {/*<Confirm
                content={<Modal.Content>
                    Centroid verwijderen?
                </Modal.Content>}
                open={this.state.confirmDeleteCentroid}
                onCancel={(e) => {
                    this.setState({confirmDeleteCentroid: false});
                    e.preventDefault();
                }}
                onConfirm={(e) => {
                    this.props.dispatch(removeCentroid(this.props.print.id, () => {
                        this.setState({confirmDeleteCentroid: false});
                    }))
                }}
            />*/}
        </>
    }

    renderAutoselect() {

        let needSelection = [...this.props.print.bom].filter(r => r.place && r.selectedSupplierPart == null && r.selectedBuddyPart == null && r.klantLevertAan == null).length

        return <>
            <Button.Group>
                <Button as={'a'} onClick={() => {

                    /*this.props.print.bom.filter(r => {
                        return !r.selectedSupplierPart && !r.buddyPart && !r.klantLevertAan;
                    }).map(r => {
                        this.props.dispatch(autoSelectBomLine(this.props.print.id, r.id))
                    })*/

                    this.props.dispatch(autoSelectBom(this.props.print.id))

                    this.props.dispatch(setBomView(defaultSelectedColumns, 'autoselect'))
                }}
                    loading={this.props.print.bom.some(r => r.busy)}
                >
                    Auto-Select
                </Button>

                <Popup trigger={<Button
                    primary={this.props.bomFilter === 'nopart'}
                    onClick={() => this.props.dispatch(setBomFilter(this.props.bomFilter !== 'nopart' ? 'nopart' : ''))}>
                    {needSelection}
                </Button>} content={`Er zijn nog ${needSelection} regels zonder part`} />
            </Button.Group>

        </>
    }

    toggleCameraFlip() {
        let newValue = !this.props.mohicanSettings.cameraFlip;
        this.props.dispatch(updateMohicanSetting('cameraFlip', newValue))
        if( this.props.onSettingsChanged ) {
            this.props.onSettingsChanged()
        }
    }

    render() {

        let expandDesignators= false;
        const admin = isEmployee(this.props.currentUser) || hasRole(this.props.currentUser, 'ROLE_MICROPLACER');
        const uplacer = hasRole(this.props.currentUser, 'ROLE_MICROPLACER');

        let selectedColumns = this.props.selectedColumns ? this.props.selectedColumns : defaultSelectedColumns

        let columns = this.state.columns.filter(c => selectedColumns.includes(c.id))

        columns.forEach(c => {
            if( c.isDesignator ) {
                expandDesignators= true;
            }
        })

        columns = columns.filter(c => {
            if( c.adminOnly ) {
                return admin
            }
            if( c.userOnly ) {
                return !admin
            }
            return true
        })

        if( this.props.currentUser === null ) {
            return <div>

            </div>
        }

        let {rows, nextDesignator} = getRows(this.props.print, expandDesignators, this.props.bomFilterList, this.props.bomFilter, this.props.bomSearch);

        //let {cost, costPerSupplier, sendCostTotal} = getCostForPrint(this.props.print);

        const noProject = this.props.print === null;

        let id= this.props.match ? this.props.match.params.bomId : null;

        const projectView = this.props.projectOrderId !== null;

        const viewId = this.props.view !== null ? this.props.view : this.props.viewId

        const cameraView = this.props.print && (viewId === 'production')

        const hasMarkedLines = rows.some(c => c.r ? c.r.marked : false)

        const scale = this.props.mohicanSettings.cameraZoom;
        const pan = this.props.mohicanSettings.pan;

        let top = 0;
        let left = 0;
        if( pan ) {
            top = pan.y
            left = pan.x
        }

        const cameraTransform = `scale(${scale})`
        const marginTop = `${top}%`
        const marginLeft = `${left}%`
        const marginRight = `${-left}%`

        let cameraDevices = this.props.cameraDevices;

        return (

            <div>

                <PartInfo/>

                <CheckinOrders/>

                <BomCentroidView/>

                {/*{(noProject || admin || !hasMarkedLines) ? null : <BomCustomerConfirm hasMarkedLines={hasMarkedLines}/>}*/}
                {(noProject || admin) ? null : <BomCustomerConfirm hasMarkedLines={hasMarkedLines}/>}

                {this.props.importErrorDialogOpen ? <BomImportErrorDialog open={this.props.importErrorDialogOpen} errors={this.props.errorList} onClose={() => {
                    this.props.dispatch(closeImportErrorDialog())
                }}/> : ''}

                {this.props.searchRow !== null ? <BomSearchDialog
                    header={this.props.print.name}
                    isAdmin={admin}
                    amount={this.props.print.amount}
                    open={true}
                    usebuddy={this.props.print.usebuddy}
                    uplacerOwner={this.props.print.uplacerOwner != null}
                    onClose={() => {
                        this.props.dispatch(setSelectedBomRow(this.props.searchRow.id, true))
                        this.props.dispatch(closeBomSearch())
                    }}
                    onSupplierpart={(sp) => {
                        this.props.dispatch(setSelectedSupplierPart(this.props.searchRow.id, sp.id))
                        this.props.dispatch(setSelectedBomRow(this.props.searchRow.id, true))
                    }}
                    onBuddypart={(bp) => {
                        this.props.dispatch(setSelectedBuddyPart(this.props.searchRow.id, bp.id))
                        this.props.dispatch(setSelectedBomRow(this.props.searchRow.id, true))
                    }}
                /> : ''}


                <div>

                    <BomMicroPlacerPartSelectionSettings/>

                    <Modal size={'large'} open={this.props.bomBugreport} closeIcon onClose={() => this.props.dispatch(openBugReport(false))}>
                        <Modal.Header>Bugs and issues</Modal.Header>
                        <Modal.Content>

                            <Translate value={'microplacer.reportissue.info'}/>

                            <ChatView
                                showInput={true}
                                isPageView={false}
                                chatId={'fa2d6ebb-5f55-4d91-9ee5-179aa4c53cee'}
                            />

                        </Modal.Content>
                        <Modal.Actions>
                            <Button onClick={() => this.props.dispatch(openBugReport(false))}>Close</Button>
                        </Modal.Actions>
                    </Modal>

                    {/*<Link to={'/projects-microplacer'} style={{float: 'left', marginTop: -38, height: 0}}>back</Link>*/}



                    <Modal size='mini' open={this.state.editPrintName != null} closeIcon onClose={() => this.setState({editPrintName: null})}>
                        <Modal.Header><Translate value="uplacerprojectname"/></Modal.Header>
                        <Modal.Content>
                            <Form>
                                <Form.Input label={<Translate value="uplacerprojectname"/>} value={this.state.editPrintName} onChange={e => {
                                    this.setState({editPrintName: e.target.value})
                                }}/>
                            </Form>
                        </Modal.Content>
                        <Modal.Actions>
                            <Button disabled={this.state.savingPrintName} onClick={() => this.setState({editPrintName: null})}
                            ><Translate value="cancel"/></Button>
                            <Button primary loading={this.state.savingPrintName} disabled={!this.state.editPrintName} onClick={() => {
                                this.setState({savingPrintName: true})
                                this.props.dispatch(saveBom(this.props.print.id, {name: this.state.editPrintName}, () => {
                                    this.setState({editPrintName: null, savingPrintName: false})
                                }))
                            }}
                            ><Translate value="save"/></Button>
                        </Modal.Actions>
                    </Modal>

                    <Menu attached='top' style={{marginTop: 8}} color={'blue'} inverted>

                        {(noProject) ? null : <BomMenu viewId={viewId} admin={admin} noProject={noProject} cameraView={cameraView}/>}

                        <div>
                            {noProject ? null : this.renderMenu(viewId, admin, uplacer)}
                        </div>

                        <Menu.Menu position='right'>

                            <div className='microplacerMenu' style={{display: 'flex', gap: 8, color: 'white'}}>

                                {admin ? <Popup trigger={
                                    <Icon name='info circle'></Icon>
                                    } content={this.props.print ? <div>
                                        <div>{this.props.print.bom.filter(b => b.designators.some(d => d.place)).length} Regels</div>
                                        <div>{this.props.print.bom.map(r => r.designators).flat().filter(d => d.place).length} Componenten</div>
                                        <div>{this.props.print.bom.map(r => r.designators).flat().filter(d => d.place && d.topBottom === 'TOP').length} Top side componenten</div>
                                        <div>{this.props.print.bom.map(r => r.designators).flat().filter(d => d.place && d.topBottom === 'BOTTOM').length} Bottom side componenten</div>
                                    </div> : <div>...</div>}
                                /> : null}

                                {(admin && this.props.print) ? <button style={{color: 'white'}} className='link-button' onClick={() => {
                                    this.setState({editNumPcbs: this.props.print.amount})
                                }}>
                                    {this.props.print.amount} {this.props.print.amount > 1 ?
                                        <Translate value="pcbs"/>
                                    :   <Translate value="pcb"/> }
                                </button> : null}

                                <span><div style={{maxHeight: '42.1px'}}>( <BomOrderCostTable/> )</div></span>

                                <Modal size='mini' open={this.state.editNumPcbs} closeIcon onClose={() => this.setState({editNumPcbs: null})}>
                                    <Modal.Header><Translate value="numpcbs"/></Modal.Header>
                                    <Modal.Content>
                                        <Form>
                                            <Form.Input type="number" min="1" step="1" label={<Translate value="numpcbs"/>} value={this.state.editNumPcbs} onChange={e => {
                                                this.setState({editNumPcbs: e.target.value})
                                            }}/>
                                        </Form>
                                    </Modal.Content>
                                    <Modal.Actions>
                                        <Button disabled={this.state.savingNumPcbs} onClick={() => this.setState({editNumPcbs: null})}
                                        ><Translate value="cancel"/></Button>
                                        <Button primary loading={this.state.savingNumPcbs} onClick={() => {
                                            this.setState({savingNumPcbs: true})
                                            this.props.dispatch(saveBom(this.props.print.id, {amount: this.state.editNumPcbs}, () => {
                                                this.setState({editNumPcbs: null, savingNumPcbs: false})
                                            }))
                                        }}
                                        ><Translate value="save"/></Button>
                                    </Modal.Actions>
                                </Modal>

                                {/*{admin ? <div>
                                    <Popup position='bottom center' trigger={
                                        <Button icon color='blue' disabled={noProject} onClick={() => this.props.dispatch(openBugReport((true)))}>
                                            <Icon name='bug' />
                                        </Button>
                                    } content='Report a bug'/>
                                </div> : null}*/}

                                {admin ? <div>
                                    <BomMohican connect={viewId === 'production'}/>
                                </div> : null}
                            </div>

                            <div className='ui right aligned category search item'>
                                <div className='ui icon input'>
                                    <input
                                        disabled={noProject}
                                        className='prompt'
                                        type='text'
                                        placeholder='Search...'
                                        value={this.props.bomSearch}
                                        onChange={(e) => this.props.dispatch(setBomSearch(e.target.value))}
                                        ref={(input) => this.props.dispatch(setBomSearchRef(input))}
                                    />
                                    {this.props.bomSearch ? <i className='close link icon' onClick={() => this.props.dispatch(setBomSearch(''))}/>
                                        : <i className='search link icon' disabled={noProject}/>}
                                </div>
                                <div className='results' />
                            </div>
                        </Menu.Menu>
                    </Menu>

                    <Segment attached='bottom' style={{paddingBottom: 0, height: 'calc(100vh - 220px)'}}>

                        {!this.props.print ? <div className='bommenu'>

                            {(id === 'new' && !this.props.openeningBom) || (this.props.projectOrderId && !this.props.openeningBom) ?
                                <div style={{textAlign: 'center'}}>
                                    {(admin && !uplacer) ? <div>
                                        <UploadFile icon={false} text={"Upload BOM file (.xls/.xlsx) (zonder header selectie)"}
                                                    url={'/api/bom/importBOM' + encodeProjectId(this.props, !this.props.projectOrderId)}
                                                    onStart={() => this.props.dispatch(startReceivePrint())}
                                                    onResult={(body) => {
                                                        this.props.dispatch(receivePrint(body))
                                                        //this.props.dispatch(setBomView(null, 'valideren'))
                                                        this.props.dispatch(checkBom(body.key.id))

                                                        if (window.location.href.includes('projects-microplacer')) {
                                                            this.props.history.push(`/projects-microplacer/${body.key.id}/bom`)
                                                        }
                                                    }}
                                        />
                                        <Checkbox style={{marginTop: 10}}
                                                  label={'Aggregate designators for similar lines (same value, mpn and sku)'}
                                                  checked={this.props.aggregateUpload}
                                                  onClick={() => this.props.dispatch(toggleAggregateUpload())}
                                        />
                                    </div> : ''}

                                    {(admin | uplacer) ? <div style={{marginTop: 32}}>
                                        <BomImportAndEdit
                                            key={'importbom'}
                                            printId={this.props.print ? this.props.print.id : null}
                                            onImport={(d) => {
                                                this.props.dispatch(importBOM2(d, this.props.projectOrderId, this.props.aggregateUpload, !this.props.projectOrderId, (body) => {
                                                    this.props.dispatch(receivePrint(body))
                                                    //this.props.dispatch(setBomView(null, 'valideren'))
                                                    this.props.dispatch(checkBom(body.key.id))

                                                    if (window.location.href.includes('projects-microplacer')) {
                                                        this.props.history.push(`/projects-microplacer/${body.key.id}/bom`)
                                                    }
                                                }))
                                            }}
                                        />
                                        <div style={{paddingTop: 32}}>
                                            <div>
                                                <a download href="/images/uplacer/example-bom.xlsx">Download example
                                                    BOM</a>
                                            </div>
                                            <div style={{paddingTop: 8}}>
                                                <a download href="/images/uplacer/example-centroid.xlsx">Download
                                                    example Centroid</a>
                                            </div>
                                        </div>

                                    </div> : <div style={{marginTop: 32}}>
                                        <Translate value='bom_upload_not_done'/>
                                    </div>}

                                </div>
                                : ''
                            }

                        </div> : ''}

                        {this.props.openeningBom ? <div style={{textAlign: 'center', marginBottom: 16}}>
                            <Loader active inline/>
                        </div>: ''}

                        {this.state.activeName === 'chat' ? <div>
                            <ChatView chatId={this.props.print.chat} showInput={true} isPageView={false}/>
                        </div> : null}

                        {this.props.showBomOrder ? <BomOrder modal={true} onClose={() => {
                            if( this.props.onCloseView ) {
                                this.props.onCloseView()
                            }
                        }}/> : null}

                        <div style={{display: 'flex', gap: 8}}>

                            {cameraView ? <div style={{flex: 1}}>

                                <div style={{width: '100%', height: 'calc(100vh - 248px)', overflow: 'hidden'}}>
                                    <div style={{transform: cameraTransform, marginTop, marginLeft, marginRight}}>
                                        {this.state.showCamera ? <BomCamera modal={false} setCrosshair={this.state.setCrosshair} flip={true}/> : null}
                                    </div>
                                </div>

                                <div style={{position: 'relative'}}>
                                    <div style={{padding: 14, width: '100%', position: 'absolute', zIndex: 4, top: -70}}>
                                        <Segment color={this.props.selectedDesignator ? getDesignatorColor(this.props.selectedDesignator.r, this.props.selectedDesignator, null, null, this.props.bomProductionList.find(pl => pl.id ===this.props.bomProductionSelected)) : null}>
                                            <div style={{display: 'flex'}}>
                                                <div style={{flex: 1}}>
                                                    <BomSelectedDesignator selectedDesignator={this.props.selectedDesignator}/>
                                                </div>


                                                <Dropdown icon='video camera' direction='left'>
                                                    <Dropdown.Menu>
                                                        <Dropdown.Item text='Re-open camera' onClick={() => {
                                                            this.setState({showCamera: false})
                                                            setTimeout((that) => {
                                                                that.setState({showCamera: true})
                                                            }, 1000, this)
                                                        }} />

                                                        <Dropdown.Item onClick={() => this.toggleCameraFlip()}>
                                                            <div>
                                                                <Checkbox style={{paddingTop: 8}} toggle
                                                                          checked={this.props.mohicanSettings.cameraFlip}
                                                                          label={<label onMouseDown={() => this.toggleCameraFlip()}>
                                                                              'Flip image'
                                                                          </label>}
                                                                />
                                                            </div>
                                                        </Dropdown.Item>

                                                        <Dropdown.Item>
                                                            <Dropdown style={{width: '100%'}} simple icon={null} trigger={
                                                                <div style={{display: 'flex'}}>

                                                                    <Button onClick={(e) => {
                                                                        let newValue = Math.round((this.props.mohicanSettings.cameraZoom - 0.10) * 10) / 10
                                                                        this.props.dispatch(updateMohicanSetting('cameraZoom', newValue))
                                                                        e.preventDefault();
                                                                    }}>-</Button>

                                                                    <div style={{flex: 1, textAlign: 'center', lineHeight: '32px'}}>
                                                                        {Math.round(this.props.mohicanSettings.cameraZoom * 100)}{"% "}
                                                                    </div>

                                                                    <Button onClick={(e) => {
                                                                        let newValue = Math.round((this.props.mohicanSettings.cameraZoom + 0.10) * 10) / 10
                                                                        this.props.dispatch(updateMohicanSetting('cameraZoom', newValue))
                                                                        e.preventDefault();
                                                                    }}>+</Button>
                                                                </div>
                                                            }/>
                                                        </Dropdown.Item>

                                                        <Dropdown.Item>
                                                            <Dropdown style={{width: '100%'}} simple icon={null} trigger={
                                                                <div style={{display: 'flex'}}>

                                                                    <Button onClick={(e) => {
                                                                        let newValue = Math.round((this.props.mohicanSettings.viewWidth - 1) )
                                                                        this.props.dispatch(updateMohicanSetting('viewWidth', newValue))
                                                                        e.preventDefault();
                                                                    }}>-</Button>

                                                                    <div style={{flex: 1, textAlign: 'center', lineHeight: '32px'}}>
                                                                        {Math.round(this.props.mohicanSettings.viewWidth)}{"mm "}
                                                                    </div>

                                                                    <Button onClick={(e) => {
                                                                        let newValue = Math.round((this.props.mohicanSettings.viewWidth + 1) )
                                                                        this.props.dispatch(updateMohicanSetting('viewWidth', newValue))
                                                                        e.preventDefault();
                                                                    }}>+</Button>
                                                                </div>
                                                            }/>
                                                        </Dropdown.Item>

                                                        <Dropdown.Item>
                                                            <Dropdown style={{width: '100%'}} simple icon={null} trigger={
                                                                <div style={{display: 'flex'}}>

                                                                    <Button onClick={(e) => {
                                                                        let newValue = Math.round((this.props.mohicanSettings.fontSize - 2) * 100) / 100
                                                                        this.props.dispatch(updateMohicanSetting('fontSize', newValue))
                                                                        e.preventDefault();
                                                                    }}>-</Button>

                                                                    <div style={{flex: 1, textAlign: 'center', lineHeight: '32px'}}>
                                                                        {this.props.mohicanSettings.fontSize}{" "}<Icon name='font' size='small' fitted/><Icon name='font' fitted/>
                                                                    </div>

                                                                    <Button onClick={(e) => {
                                                                        let newValue = Math.round((this.props.mohicanSettings.fontSize + 2) * 100) / 100
                                                                        this.props.dispatch(updateMohicanSetting('fontSize', newValue))
                                                                        e.preventDefault();
                                                                    }}>+</Button>
                                                                </div>
                                                            }/>
                                                        </Dropdown.Item>

                                                        <Dropdown.Item>
                                                            <Dropdown style={{width: '100%'}} simple icon={null} trigger={
                                                                <div style={{display: 'flex'}}>

                                                                    <Button onClick={(e) => {
                                                                        let newValue = Math.round((this.props.mohicanSettings.brightness - 0.1) * 10) / 10
                                                                        this.props.dispatch(updateMohicanSetting('brightness', newValue))
                                                                        e.preventDefault();
                                                                    }}>-</Button>

                                                                    <div style={{flex: 1, textAlign: 'center', lineHeight: '32px'}}>
                                                                        {this.props.mohicanSettings.brightness}<Icon name='sun' />
                                                                    </div>

                                                                    <Button onClick={(e) => {
                                                                        let newValue = Math.round((this.props.mohicanSettings.brightness + 0.1) * 10) / 10
                                                                        this.props.dispatch(updateMohicanSetting('brightness', newValue))
                                                                        e.preventDefault();
                                                                    }}>+</Button>
                                                                </div>
                                                            }/>
                                                        </Dropdown.Item>

                                                        {/*<Dropdown.Item>
                                                            <Dropdown style={{width: '100%'}} simple icon={null} trigger={
                                                                <div>

                                                                    <Button onClick={(e) => {
                                                                        let currentIndex = mohicanLensOptions.indexOf(this.props.mohicanSettings.lens) - 1;
                                                                        if( currentIndex < 0 ) {
                                                                            currentIndex = mohicanLensOptions.length - 1;
                                                                        }
                                                                        let newValue = mohicanLensOptions[currentIndex]
                                                                        e.preventDefault();
                                                                        this.props.dispatch(updateMohicanSetting('lens', newValue))
                                                                    }}>{'<'}</Button>

                                                                    <div style={{flex: 1, textAlign: 'center', lineHeight: '32px'}}>
                                                                        {this.props.mohicanSettings.lens}<Icon name='camera' />
                                                                    </div>

                                                                    <Button onClick={(e) => {
                                                                        let currentIndex = mohicanLensOptions.indexOf(this.props.mohicanSettings.lens) + 1;
                                                                        if( currentIndex >= mohicanLensOptions.length ) {
                                                                            currentIndex = 0;
                                                                        }
                                                                        let newValue = mohicanLensOptions[currentIndex]
                                                                        e.preventDefault();
                                                                        this.props.dispatch(updateMohicanSetting('lens', newValue))
                                                                        e.preventDefault();
                                                                    }}>{'>'}</Button>
                                                                </div>
                                                            }/>
                                                        </Dropdown.Item>*/}

                                                        <Dropdown.Item>
                                                            <Dropdown style={{width: '100%'}} simple icon={null} trigger={
                                                                <div style={{display: 'flex'}}>

                                                                    <Button onClick={(e) => {
                                                                        let currentIndex = mohicanCameraResolutionOptions.indexOf(this.props.mohicanSettings.resolution) - 1;
                                                                        if( currentIndex < 0 ) {
                                                                            currentIndex = mohicanCameraResolutionOptions.length - 1;
                                                                        }
                                                                        let newValue = mohicanCameraResolutionOptions[currentIndex]
                                                                        e.preventDefault();
                                                                        this.props.dispatch(updateMohicanSetting('resolution', newValue))
                                                                    }}>{'<'}</Button>

                                                                    <div style={{flex: 1, textAlign: 'center', lineHeight: '32px'}}>
                                                                        {this.props.mohicanSettings.resolution.width} x {this.props.mohicanSettings.resolution.height}<Icon name='camera' />
                                                                    </div>

                                                                    <Button onClick={(e) => {
                                                                        let currentIndex = mohicanCameraResolutionOptions.indexOf(this.props.mohicanSettings.resolution) + 1;
                                                                        if( currentIndex >= mohicanCameraResolutionOptions.length ) {
                                                                            currentIndex = 0;
                                                                        }
                                                                        let newValue = mohicanCameraResolutionOptions[currentIndex]
                                                                        e.preventDefault();
                                                                        this.props.dispatch(updateMohicanSetting('resolution', newValue))
                                                                        e.preventDefault();
                                                                    }}>{'>'}</Button>
                                                                </div>
                                                            }/>
                                                        </Dropdown.Item>

                                                        <Dropdown.Item>
                                                            <Dropdown style={{width: '100%'}} simple icon={null} trigger={
                                                                <div style={{display: 'flex'}}>

                                                                    <Button onClick={(e) => {
                                                                        let currentIndex = cameraDevices.findIndex((v) => v.deviceId === this.props.mohicanSettings.cameraId) - 1;
                                                                        if( currentIndex < 0 ) {
                                                                            currentIndex = cameraDevices.length - 1;
                                                                        }
                                                                        let newValue = cameraDevices[currentIndex]
                                                                        e.preventDefault();
                                                                        this.props.dispatch(updateMohicanSetting('cameraId', newValue.deviceId))
                                                                    }}>{'<'}</Button>

                                                                    <div style={{flex: 1, textAlign: 'center', lineHeight: '16px'}}>
                                                                        <div>
                                                                            Camera:
                                                                        </div>
                                                                        <Popup trigger={
                                                                            <div style={{width: 128, overflow: 'hidden', textOverflow: 'ellipsis', display: 'inline-block'}}>
                                                                                {this.getCurrentCameraName(this.props.mohicanSettings.cameraId, cameraDevices)}
                                                                            </div>
                                                                        } content={this.getCurrentCameraName(this.props.mohicanSettings.cameraId, cameraDevices)} />
                                                                    </div>

                                                                    <Button onClick={(e) => {
                                                                        let currentIndex = cameraDevices.findIndex((v) => v.deviceId === this.props.mohicanSettings.cameraId) + 1;
                                                                        if( currentIndex >= cameraDevices.length ) {
                                                                            currentIndex = 0;
                                                                        }
                                                                        let newValue = cameraDevices[currentIndex]
                                                                        e.preventDefault();
                                                                        this.props.dispatch(updateMohicanSetting('cameraId', newValue.deviceId))
                                                                        e.preventDefault();
                                                                    }}>{'>'}</Button>
                                                                </div>
                                                            }/>
                                                        </Dropdown.Item>

                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </div>
                                        </Segment>
                                    </div>
                                </div>

                            </div> : null}

                            {(this.props.print && (viewId === 'bom' || viewId === 'production' || viewId === 'valideren')) && <div id="scrolling" className={(projectView && viewId !== 'production') ? "tableFixHead microplacerTable-notscrolling" : "tableFixHead microplacerTable"}
                                                                                                                                   style={{marginLeft: -14, marginRight: cameraView ? 0 : -14, paddingRight: 15}}>
                                <table className="bomtable fixed" style={{marginLeft: 14}}>

                                    <thead>
                                    <tr>

                                        {columns.map((c,i) => (
                                            <th key={c.id} style={this.getColStyle(c.id, true)} className={'ui '+c.color+' message ' + c.className} onClick={() => {
                                                if( c.sort ) {
                                                    this.props.dispatch(setBomSort(c.sort))
                                                }
                                            }}>
                                                {admin ? this.renderHeaderFilter(c) : ''}
                                                {this.renderColResizer(c, c.name)}
                                                {(admin && this.state.isResizing) && <div style={{
                                                    position: 'absolute',
                                                    right: '0px',
                                                    bottom: '-4px',
                                                    fontSize: 12,
                                                    fontWeight: 'normal',
                                                    color: 'grey'
                                                }}>
                                                    {columns.find(tc => tc.id === c.id).w}
                                                </div>}
                                            </th>
                                        ))}

                                    </tr>
                                    </thead>

                                    <tbody>

                                    {((!expandDesignators && !this.props.bomFilter) ? [
                                        {
                                            id: 'pbc',
                                            row: '0',
                                            designators: [{value: "PCB's", place: true, pcbOrder: true}],
                                            orderparts: [],
                                            pcbOrder: true
                                        }, {
                                            id: 'stencil',
                                            row: '00',
                                            designators: [{value: "Stencils", place: true, pcbOrder: true}],
                                            orderparts: [],
                                            stencilOrder: true
                                        }
                                    ]:[]).map((r,ri) => {

                                        const chatLines = getChatLinesByRef(r.id, this.props.chat, this.props.currentUser)

                                        return [<tr key={r.id}>
                                            {columns.map((c, i) => (
                                                <td style={this.getColStyle(c.id)} className={c.className} key={r.id+c.id}> {c.render ? c.render(r, i, ri) : ''} </td>
                                            ))}
                                        </tr>, ...this.renderChatLines(chatLines, columns.length)]
                                    })}

                                    {expandDesignators ? rows.map(({r, ri, d}, d_i) => {

                                        let className = getClassName(r, d, this.props.selectedDesignator, this.props.lastGoToDesignator, this.props.bomProductionList.find(pl => pl.id ===this.props.bomProductionSelected));
                                        let nextD = null;
                                        let nextNextD = null;
                                        const firstD = rows[0].d
                                        const lastD = rows[rows.length-1].d

                                        for (let i = d_i + 1; i < rows.length; i++) {
                                            if( nextD != null && rows[i].d && rows[i].d.place ) {
                                                nextNextD = rows[i].d;
                                                break;
                                            }
                                            if( nextD == null && rows[i].d && rows[i].d.place ) { //todo: has VALID trayLane
                                                nextD = rows[i].d;
                                            }
                                        }

                                        return <tr className={className} key={r.id+'-'+d.value} onClick={() => {
                                            this.props.dispatch(selectDesignatorBomProduction({...d, r: r}, nextD))
                                        }}>
                                            {columns.map((c, i) => (
                                                <td style={this.getColStyle(c.id)} className={c.className} key={r.id+c.id}>
                                                    {c.render ? c.render(r, i, ri, d, nextD, firstD, lastD, nextNextD) : ''}
                                                </td>
                                            ))}
                                        </tr>

                                    }) : rows.map(({r, ri}) => {

                                        let className = getClassNameForRow(r, this.props.selectedRow)

                                        const chatLines = getChatLinesByRef(r.id, this.props.chat, this.props.currentUser)

                                        return [<tr {...className} key={r.id}>
                                            {columns.map((c, i) => (
                                                <td style={this.getColStyle(c.id)} className={c.className} key={r.id+c.id}>
                                                    {c.render ? c.render(r, i, ri) : ''}
                                                </td>
                                            ))}
                                        </tr>,  ...this.renderChatLines(chatLines, columns.length)]

                                    })}

                                    {/* Add this before ...this.renderChatL : <BomLineHistory r={r} columns={columns.map((c,i) => ({
                                        i: i, id: c.id, style: this.getColStyle(c.id), className: c.className
                                    }))}/> ,*/}

                                    </tbody>

                                </table>
                                {(admin || uplacer) ? <div style={{float: 'left', margin: 16}}>
                                    <BomAddRow/>
                                </div> : null}
                                <div style={{height: 64, margin: 'auto', textAlign: 'center', paddingTop: 24}}>
                                    <img src={'/logo.svg'} style={{maxHeight: 16}} alt='DeltaProto'/>
                                </div>
                            </div>}
                        </div>
                        {/*<div style={{textAlign: 'right', marginTop: 16}}>
                            <a style={{color: '#3a6998'}} href="https://octopart.com/">Powered by <img style={{height: '1em', marginBottom: -4}} src="/images/Octopart-Logo_Full-Blue.svg" alt="Octopart"/></a>
                        </div>*/}
                    </Segment>
                </div>

            </div>
        );
    }

    getMarginTop(scale) {
        if( scale === 1 ) {
            return null;
        } else if( scale > 1 ) {
            return '-' + ((scale - 1) * 30) + '%'
        } else if( scale > 1 ) {
            return ((scale - 1) * 30) + '%'
        }
    }

    getTransformOrigin() {
        if( this.props.mohicanSettings ) {

            let crosshair = this.props.mohicanSettings.crosshair;

            let a = Math.round( crosshair.x * 100 / WEBCAM_CAMERA_WIDTH )
            let b = Math.round( crosshair.y * 100 / WEBCAM_CAMERA_HEIGHT )

            return a+'% '+b+'%'

        }
        return '50% 50%';
    }

    onViewChange(name) {
        switch (name) {
            case 'projectname':
                this.setState({editPrintName: this.props.print.name})
                break;
            case 'home':
                this.props.history.push('/projects-microplacer')
                break;
            case 'orders':
                this.props.dispatch(openBomOrder(true))
                if( this.props.print ) {
                    this.props.dispatch(getBomOrders(this.props.print.id))
                }
                break;
            default:
                this.setState({activeName: name})
                this.props.dispatch(setBomView(defaultSelectedColumns, name))
                break;
        }
    }

    deleteProductionLines() {
        if( this.props.bomProductionList ) {
            this.props.bomProductionList.map(p => {
                this.props.dispatch(deleteBomProductionLine(p.id))
            })
        }

    }
}

BomMicroPlacer.propTypes = {
    projectOrderId: PropTypes.string,
    view: PropTypes.string,
    onCloseView: PropTypes.func,
    locked: PropTypes.bool
}

BomMicroPlacer.defaultProps = {
    projectOrderId: null,
    view: null,
    onCloseView: null,
    locked: false
}

const mapStateToProps = (state) => {
    return {
        currentUser: state.main.currentUser,
        lang: state.main.lang,
        print: state.bom.print,
        list: state.bom.list,
        errorList: state.bom.errorList,
        importErrorDialogOpen: state.bom.importErrorDialogOpen,
        dialogStartValidate: state.bom.dialogStartValidate,
        packingList: state.project.eventList,
        searchRow: state.bom.searchRow,
        bomOrderList: state.bom.bomOrderList,
        selectedDesignator: state.bom.selectedDesignator,
        lastGoToDesignator: state.bom.lastGoToDesignator,
        bomFilter: state.bom.bomFilter,
        bomSort: state.bom.bomSort,
        bomSearch: state.bom.bomSearch,
        bomFilterList: state.bom.bomFilterList,
        bomProductionList: state.bom.bomProductionList,
        bomProductionSelected: state.bom.bomProductionSelected,
        viewId: state.bom.viewId,
        checkingFocus: state.bom.checkingFocus,
        mohicanPcbPosition: state.bom.mohicanPcbPosition,
        chat: state.chat.chat,
        selectedRow: state.bom.selectedRow,
        showBomOrder: state.bom.showBomOrder,
        openeningBom: state.bom.openeningBom,
        selectedColumns: state.bom.selectedColumns,
        fetchingOrderpartInfo: state.bom.fetchingOrderpartInfo,
        bomBugreport: state.bom.bomBugreport,
        validatingBom: state.bom.validatingBom,
        bomDemoMode: state.bom.bomDemoMode,
        aggregateUpload: state.bom.aggregateUpload,
        mohicanSettings: state.bom.mohicanSettings,
        cameraDevices: state.bom.cameraDevices,
        decimalSeparator: state.fileimport.decimalSeparator,
        unitOfMeasurement: state.fileimport.unitOfMeasurement,
        mohicanEnableFeedbackControl: state.bom.mohicanEnableFeedbackControl,
    }
}

export default connect(mapStateToProps)(withRouter(BomMicroPlacer))