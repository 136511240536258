import React from "react";
import {Dropdown, Input} from "semantic-ui-react";
import {setRemark} from "../actions";
import PropTypes from "prop-types";
import {connect} from "react-redux";

export const defaultRemarks = [
    "No part found",
    "No stock at active suppliers"
]

class BomRemarkEdit extends React.Component {

    state = {
        value: null
    }

    render() {

        let value = this.state.value ? this.state.value : this.props.value

        return <>
            <Dropdown
                fluid icon={null}
                trigger={
                    <Input className="alert" fluid style={{flex: 1}} value={value} color={this.state.value != null ? 'blue' : ''}
                           onChange={(e) => this.setState({value: e.target.value})}
                           onBlur={() => {
                               if( this.state.value != null ) {
                                   this.setState({loading: true})
                                   this.props.dispatch(setRemark(null, this.props.bomLineId, this.state.value, () => {
                                       this.setState({loading: false})
                                   }))
                               }
                           }}
                           loading={this.state.loading}
                           placeholder={'Status'}
                    />
                }
            >
                <Dropdown.Menu>
                    {defaultRemarks.map(x => (
                        <Dropdown.Item onClick={(e,d) => {
                            this.setState({loading: true, value: x})
                            this.props.dispatch(setRemark(null, this.props.bomLineId, x, () => {
                                this.setState({loading: false})
                            }))

                        }} key={x} value={x}>{x}</Dropdown.Item>
                    ))}
                </Dropdown.Menu>
            </Dropdown>
        </>
    }
}

BomRemarkEdit.propTypes = {
    bomLineId: PropTypes.string,
    value: PropTypes.string,
};

BomRemarkEdit.defaultProps = {
    bomLineId: null,
    value: '',
};

const mapStateToProps = (state) => {
    return {
    }
}

export default connect(mapStateToProps)(BomRemarkEdit)