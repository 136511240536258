import React, {Component} from 'react';
import connect from "react-redux/es/connect/connect";
import {Button, Confirm, Form, Grid, Image, Input, Label, Modal, Segment, TextArea} from "semantic-ui-react";
import UploadFile from "./UploadFile";
import {
    changeProjectOrderPcbInfo, deleteProjectOrderPcbInfo,
    receiveProjectOrderPcbInfo,
    saveProjectOrderPcbInfo
} from "../actions";
import PropTypes from "prop-types";
import PinchZoomPan from "react-responsive-pinch-zoom-pan";

class ProjectViewPcb extends Component {

    state= {
        deleteId: null
    }

    save(value, projectId) {

        this.props.dispatch(saveProjectOrderPcbInfo(value, projectId))

    }

    delete(id) {

        this.props.dispatch(deleteProjectOrderPcbInfo(id))
        this.setState({deleteId: null})
    }

    onChange(id, name, value) {
        this.props.dispatch(changeProjectOrderPcbInfo(id, name, value))
    }

    render() {

        let project= this.props.project;

        let m_value= {}

        this.props.pcbs.forEach(p => {
            if( p.id === this.props.id ) {
                m_value= p;
            }
        })

        m_value.pictures.sort((a,b) => a.version - b.version)

        let frontImages= m_value.pictures.filter(p => !!p.frontImage);
        let backImages= m_value.pictures.filter(p => !!p.backImage);

        let frontImage= null;
        let backImage= null;

        if( frontImages.length ) {
            frontImage= frontImages[frontImages.length-1]
        }

        if( backImages.length ) {
            backImage= backImages[backImages.length-1]
        }

        if( this.props.isAdmin ) {
            return (<div style={{paddingBottom: 32}} key={this.props.key}>


                {frontImage && <div style={{display: 'none'}}><Image id={frontImage.frontImage.id}
                                     src={'/api/project/getAttachment?id=' + frontImage.frontImage.id}/></div>}

                <Confirm
                    dimmer={'blurring'}
                    open={this.state.deleteId}
                    onCancel={() => this.setState({deleteId: null})}
                    onConfirm={() => this.delete(this.state.deleteId)}
                    content={'Weet je zeker dat je deze pcb wilt verwijderen?'}
                    cancelButton='Nee'
                    confirmButton="Ja"
                />

                <Segment>
                    <Grid columns={3} divided>
                        <Grid.Row>
                            <Grid.Column>
                                <h2>PCB</h2>
                                <Form>
                                    <Input type='number' name='number' fluid placeholder='Nummer' value={m_value.number}
                                           onChange={(e) => this.onChange(m_value.id, 'number', e.target.value)}/>
                                    <TextArea name='remarks' autoHeight placeholder='Opmerkingen'
                                              value={m_value.remarks}
                                              onChange={(e) => this.onChange(m_value.id, 'remarks', e.target.value)}/>
                                </Form>
                            </Grid.Column>
                            <Grid.Column>
                                <div style={{visibility: m_value.id ? '' : 'hidden'}}>
                                    <p>Front</p>

                                    {frontImage ? <Image src={'/api/project/getAttachment?id=' + frontImage.frontImage.id}/> :
                                        <Label content='Geen foto'/>}

                                    <UploadFile text={"Foto toevoegen"}
                                                url={'api/project/addProjectOrderPcbPicture?projectOrderPcbInfoId=' + m_value.id + '&back=false'}
                                                onResult={(body) => {
                                                    this.props.dispatch(receiveProjectOrderPcbInfo(body))
                                                }}/>
                                </div>
                            </Grid.Column>
                            <Grid.Column>
                                <div style={{visibility: m_value.id ? '' : 'hidden'}}>
                                    <p>Back</p>

                                    {backImage ? <Image id={backImage.backImage.id}
                                                        src={'/api/project/getAttachment?id=' + backImage.backImage.id}/> :
                                        <Label content='Geen foto'/>}

                                    <UploadFile text={"Foto toevoegen"}
                                                url={'api/project/addProjectOrderPcbPicture?projectOrderPcbInfoId=' + m_value.id + '&back=true'}
                                                onResult={(body) => {
                                                    this.props.dispatch(receiveProjectOrderPcbInfo(body))
                                                }}/>
                                </div>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                    <div style={{textAlign: 'right'}}>
                        <Button primary={m_value.dirty} onClick={() => this.save(m_value, project.id)}>Save</Button>
                        <Button onClick={() => this.setState({deleteId: m_value.id})}>Delete</Button>
                    </div>
                </Segment>
            </div>)
        }
        else {
            return (<div style={{paddingBottom: 32}} key={this.props.key}>
                <Segment>
                    <h3>{m_value.number}</h3>
                    <p>{m_value.remarks}</p>
                    <Grid columns={2} divided>
                        <Grid.Row>
                            <Grid.Column>
                                <div style={{visibility: m_value.id ? '' : 'hidden'}}>
                                    <p>Front</p>

                                    {frontImage ? <Modal size='fullscreen' centered={false} closeIcon={true} trigger={<Image id={frontImage.frontImage.id}
                                                           style={{cursor: 'pointer'}}
                                                           src={'/api/project/getAttachment?id=' + frontImage.frontImage.id + '&scaled=true'}/>}>
                                        <Modal.Header>
                                            <h3>{m_value.number}  (Front)</h3>
                                            <p>{m_value.remarks}</p>
                                        </Modal.Header>
                                        <Modal.Content style={{height: '80vh'}}>
                                            <PinchZoomPan position='center' maxScale={2}>
                                                <img style={{maxWidth: 'initial'}} alt='Front' src={'/api/project/getAttachment?id=' + frontImage.frontImage.id} />
                                            </PinchZoomPan>
                                        </Modal.Content>
                                    </Modal> :
                                    <Label content='Geen foto'/>
                                    }
                                </div>
                            </Grid.Column>
                            <Grid.Column>
                                <div style={{visibility: m_value.id ? '' : 'hidden'}}>
                                    <p>Back</p>

                                    {backImage ? <Modal size='fullscreen' centered={false} closeIcon={true} trigger={<Image id={backImage.backImage.id}
                                                                                                                             style={{cursor: 'pointer'}}
                                                                                                                             src={'/api/project/getAttachment?id=' + backImage.backImage.id + '&scaled=true'}/>}>
                                            <Modal.Header>
                                                <h3>{m_value.number} (Back)</h3>
                                                <p>{m_value.remarks}</p>
                                            </Modal.Header>
                                            <Modal.Content>
                                                <PinchZoomPan position='center' maxScale={2}>
                                                    <img alt='Back' src={'/api/project/getAttachment?id=' + backImage.backImage.id} />
                                                </PinchZoomPan>
                                            </Modal.Content>
                                        </Modal> :
                                        <Label content='Geen foto'/>
                                    }

                                </div>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Segment>
            </div>)
        }

    }
}

ProjectViewPcb.propTypes = {
    isAdmin: PropTypes.bool,
};

ProjectViewPcb.defaultProps = {
    isAdmin: true,
};

const mapStateToProps = (state) => {
    return {
        currentUser: state.main.currentUser,
        lang: state.main.lang,
        pcbs: state.project.pcbs,
        project: state.project.project,
    }
}

export default connect(mapStateToProps)(ProjectViewPcb)
