import React, { Component } from 'react';
import './App.css';
import ReactTextTransition, { presets } from "react-text-transition";

const texts= ["Netherlands", "Solar System", "Galaxy", "Universe", "Multiverse" ];

class HomePrice extends Component{

    state = {
        textIndex: 0,
    };

    componentDidMount() {
        this.interval = setInterval(() => {
            this.setState({
                textIndex: this.state.textIndex + 1,
                show: false
            });
        }, 2000);
    }

    componentWillUnmount() {
        if( this.interval ) {
            clearInterval( this.interval )
        }
    }

    render() {
        return (
            <section id={'homePrice'}>
                <h1 className={'heading1'}>DeltaProto</h1>
                <div className={'heading6 noMobile'}>best proto PCBA service in the <ReactTextTransition
                    text={texts[this.state.textIndex % texts.length]}
                    spring={presets.gentle}
                    inline
                /></div>
            </section>
        );
    }
}

export default HomePrice;