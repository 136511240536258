import React from 'react'
import {Button, Divider, Dropdown, Form, Icon, Input, TextArea} from "semantic-ui-react";
import {connect} from "react-redux";
import {
    changeBuddyPart,
    changeImportPartValue,
    changeOrderPartValue, getBuddyParts, getImportStatus,
    importFromBomId,
    importFromMpn, revertImportPartValue,
    saveBuddyPart,
    saveOrderpart,
    setPartForBuddyPart, updateOrderpart
} from "../actions";
import {formatDateTime} from "../actions/functions";
import {renderSupplier, renderSupplierUrl} from "./functions";
import {renderImageForPart} from "./BomOrderparts";
import TableResize from "../basic/TableResize";
import {statusOptions, typeOptions} from "./BomOrderpartList";
import Translate from "../Translate";


class BomImportParts extends React.Component {

    state = {
        json: ''
    }

    componentDidMount() {
        this.props.dispatch(getBuddyParts())
        this.props.dispatch(getImportStatus())
    }

    handleSubmit = (event) => {
        event.preventDefault();

        console.log( event )

        this.props.dispatch(importFromBomId(this.state.bomId, false));
    }

    handleSubmitAddMpn = (event) => {
        event.preventDefault();

        console.log( event )

        this.props.dispatch(importFromMpn(this.state.mpn, false));
    }

    handleChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    renderBuddyPart = (orderpartId) => {
        if( !orderpartId ) {
            return <></>
        }

        if( this.props.buddy.some(b => b.orderpart.id === orderpartId) ) {

            let b = this.props.buddy.filter(b => b.orderpart.id === orderpartId)[0];

            return <div>

                {['name', 'machine', 'location', 'nozzle'].map(key => (<div style={{display: 'flex'}}>
                    {key}: <Input transparent value={b[key]}
                                    onChange={(e) => this.props.dispatch(changeBuddyPart(b.id, key, e.target.value))}/>
                </div>))}

                <div>
                    <Button size={'mini'} primary={b.dirty}
                            onClick={() => {
                                this.props.dispatch(saveBuddyPart(b))
                            }}
                    ><Translate value="save"/></Button>
                </div>
            </div>
        }

        return <>
            <Button onClick={() => this.props.dispatch(setPartForBuddyPart(null, orderpartId))}>Aanmaken</Button>
        </>
    }

    render() {
        return (
            <div>
                <div style={{float: 'right'}}>

                    {this.props.importing ? 'Een moment...' : ''}
                    {this.props.importError ? 'ERROR' : ''}

                    {this.props.importStatus ? this.props.importStatus : <Button color='red' onClick={() => {
                        this.props.dispatch(importFromBomId('123', true, true));
                    }}>Import all</Button>}
                </div>

                <div style={{display: 'flex'}}>
                    <Form onSubmit={this.handleSubmit}>
                        <Form.Group widths='equal'>
                            <Form.Input name="bomId" label="Bom Id" onChange={this.handleChange}/>

                            <Form.Button type="submit" value="Submit" >Bekijken</Form.Button>
                        </Form.Group>
                    </Form>
                    <div>
                        <Button style={{display: 'inline'}} primary onClick={() => {
                            this.props.dispatch(importFromBomId(this.state.bomId, true));
                        }}>Importeren</Button>
                    </div>
                </div>

                <div style={{display: 'flex'}}>
                    <Form onSubmit={this.handleSubmitAddMpn}>
                        <Form.Group widths='equal'>
                            <Form.Input name="mpn" label="MPN" onChange={this.handleChange}/>

                            <Form.Button type="submit" value="Submit" >Bekijken</Form.Button>
                        </Form.Group>
                    </Form>
                    <div>
                        <Button style={{display: 'inline'}} primary onClick={() => {
                            this.props.dispatch(importFromMpn(this.state.mpn, true));
                        }}>Importeren</Button>
                    </div>
                </div>


                <Divider/>

                <div>
                    <TableResize
                        defaultWidth={200}
                        headers={[
                            {name: 'id', text: 'database'},
                            {name: 'mpn', text: 'mpn'},
                            {name: 'octopartUrl', text: 'octopartUrl', width: 100},
                            {name: 'image', text: 'image', width: 50},
                            {name: 'shortDescription', text: 'shortDescription'},
                            {name: 'dpDescription', text: 'dpDescription'},
                            {name: 'dpChoice', text: 'dpChoice', width: 100},
                            {name: 'dpBuddy', text: 'Buddy part', width: 150},
                            {name: 'type', text: 'type', width: 100},
                            {name: 'specification', text: 'specification', width: 250},
                            {name: 'lastUpdate', text: 'lastUpdate', width: 150},
                            {name: 'suppliers', text: 'suppliers', width: 350},
                            {name: 'pitch', text: 'pit', width: 40},
                            {name: 'width', text: 'wit', width: 40},
                            {name: 'status', text: 'Status', width: 100},
                            {name: 'update', text: '', width: 111}
                        ]}
                        values={this.props.orderpartsImport ? this.props.orderpartsImport : []}
                        render={(name, value) => {

                            switch (name) {
                                case 'dpBuddy':
                                    return <div>
                                        {this.renderBuddyPart(value.id)}
                                    </div>
                                case 'id':
                                    return <div>
                                        {value.id ? <div>
                                            <div>Opgeslagen</div>
                                            <div>
                                                <Button icon onClick={() => this.props.dispatch(updateOrderpart(value.id))}>
                                                    <Icon name='refresh' />
                                                </Button>
                                            </div>
                                        </div> : ''}
                                    </div>
                                case 'lastUpdate':
                                    let lastUpdate = null;
                                    value.suppliers.forEach((s) => {
                                        if( lastUpdate == null || s.lastUpdate < lastUpdate ) {
                                            lastUpdate = s.lastUpdate
                                        }
                                    })

                                    return <div>
                                        <div>{lastUpdate ? formatDateTime(lastUpdate) : ''}</div>

                                    </div>;
                                case 'dpChoice':
                                    return <div style={{textAlign: 'center'}}>
                                        <Icon name={value.dpChoice ? 'heart' : 'heart outline'} link color='red' disabled={!value.id}
                                              onClick={() => this.props.dispatch(changeImportPartValue(value.id, 'dpChoice', !value.dpChoice))}
                                        />
                                    </div>
                                case 'suppliers':
                                    return <div>
                                        {value.suppliers.map(s => (<div>
                                            {renderSupplier(s)}
                                            {renderSupplierUrl(s)}
                                        </div>))}
                                    </div>
                                case 'octopartUrl':
                                    return <div>
                                        <a href={value[name]}>link</a>
                                    </div>
                                case 'image':
                                    return <div>
                                        {renderImageForPart(value)}
                                    </div>
                                case 'specification':
                                    return <div>
                                        {value.specification.displayValue}
                                    </div>
                                case 'update':
                                    return <div>
                                        <Button.Group vertical>
                                            <Button disabled={!value.dirty || !value.id} primary onClick={() => {
                                                this.props.dispatch(saveOrderpart(value))
                                            }}><Translate value="save"/></Button>
                                            <Button disabled={!value.dirty || !value.id} onClick={() => {
                                                this.props.dispatch(revertImportPartValue(value.id))
                                            }}>Annuleren</Button>
                                        </Button.Group>
                                    </div>;
                                case 'shortDescription':
                                    return <div>
                                        {value.shortDescription} <Icon name='copy' link
                                                                       onClick={() => this.props.dispatch(changeImportPartValue(value.id, 'dpDescription', value.shortDescription))}/>
                                    </div>
                                case 'dpDescription':
                                    return <TextArea autoHeight transparent fluid value={value.dpDescription}
                                                     onChange={e => this.props.dispatch(changeImportPartValue(value.id, 'dpDescription', e.target.value))}
                                    />;
                                case 'pitch':
                                    return <Input transparent fluid value={value.pitch} onChange={e => this.props.dispatch(changeImportPartValue(value.id, 'pitch', e.target.value))}/>
                                case 'width':
                                    return <Input transparent fluid value={value.width} onChange={e => this.props.dispatch(changeImportPartValue(value.id, 'width', e.target.value))}/>
                                case 'type':
                                    return <div>
                                        <Dropdown value={value.type} options={typeOptions} onChange={(e,d) => {
                                            this.props.dispatch(changeOrderPartValue(value.id, 'type', d.value))
                                        }}/>
                                    </div>;
                                case 'status':
                                    return <div>
                                        <Dropdown value={value.status} options={statusOptions} onChange={(e,d) => {
                                            this.props.dispatch(changeOrderPartValue(value.id, 'status', d.value))
                                        }}/>
                                    </div>;
                                default:
                                    return <div>
                                        {value[name]}
                                    </div>
                            }
                        }}
                    />
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        orderpartsImport: state.bom.orderpartsImport,
        importing: state.bom.importing,
        importError: state.bom.importError,
        importStatus: state.bom.importStatus,
        buddy: state.bom.buddy,
    }
}

export default connect(mapStateToProps)(BomImportParts)