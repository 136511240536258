import React, {Component} from 'react';
import './App.css';
import connect from "react-redux/es/connect/connect";
import {
    fetchPcbPrice,
    fetchProject,
    fetchProjectEvents,
    getProductionRelease,
    getProjectModification,
    openLoginSignup
} from "./actions";
import ProjectCard from "./ProjectCard";
import ProjectStepBuyParts from "./ProjectStepBuyParts";
import ProjectStepCheckIn from "./ProjectStepCheckIn";
import ProjectStepSamplePCBA from "./ProjectStepSamplePCBA";
import Translate from "./Translate";
import {Button, Loader} from "semantic-ui-react";
import ProjectStepAnalysisAndOrder from "./ProjectStepAnalysisAndOrder";
import {withRouter} from "react-router-dom";
import ProjectSteps from "./ProjectSteps";
import {isEmployee} from "./actions/functions";
import {Helmet} from "react-helmet";

export const projectPhases= [
    {
        name: 'request',
        status: '',
        pageAdmin: <ProjectSteps view={'aanvraag'}/>,
        page: <ProjectStepAnalysisAndOrder view={'aanvraag'}/>,
        statusOptions: ['checked', 'onhold'],
        icon: 'envelope outline',
        fixedStatus: 'checked'
    },
    {
        name: 'analysis',
        status: '',
        pageAdmin: <ProjectSteps view={'aanvraag'}/>,
        page: <ProjectStepAnalysisAndOrder view={'analysis'}/>,
        statusOptions: [
            'notstarted',
            'active',
            'ready',
            'checked',
            'cancelled',
            'onhold'
        ],
        icon: 'star outline'
    },
    /*{
        name: 'order',
        status: '',
        page: <ProjectStepAnalysisAndOrder/>,
        statusOptions: statusOptionsMinus2,
        icon: 'first order'
    },*/
    {
        name: 'buy',
        status: '',
        pageAdmin: <ProjectSteps view={'bom'}/>,
        page: <ProjectStepBuyParts/>,
        statusOptions: [
            'notstarted',
            'active',
            'ready',
            'cancelled',
            'onhold'
        ],
        icon: 'cart'
    },
    {
        name: 'checkin',
        status: '',
        pageAdmin: <ProjectSteps view={'bom'}/>,
        page: <ProjectStepCheckIn/>,
        statusOptions: [
            'notstarted',
            'active',
            'ready',
            'cancelled',
            'onhold'
        ],
        icon: 'clipboard list'
    },
    /*{
        name: 'foto',
        status: '',
        page: <ProjectStepFoto/>,
        statusOptions: [
            'inactive',
            'notstarted',
            'active',
            'ready',
            'checked',
            'cancelled',
        ],
        icon: 'camera'
    },*/
    {
        name: 'sample',
        status: '',
        pageAdmin: <ProjectSteps view={'loc'}/>,
        page: <ProjectStepSamplePCBA/>,
        statusOptions: [
            'inactive',
            'notstarted',
            'active',
            'fotoready',
            'shipped',
            'checked',
            'cancelled',
            'skip',
            'onhold'
        ],
        icon: 'rocket'
    },
    {
        name: 'sample2',
        nameRepair: 'sample2repair',
        status: '',
        pageAdmin: <ProjectSteps view={'loc'} sampleNr={'2'}/>,
        page: <ProjectStepSamplePCBA sampleNr={'2'}/>,
        statusOptions: [
            'inactive',
            'notstarted',
            'purchase',
            'checkin',
            'active',
            'fotoready',
            'shipped',
            'checked',
            'DIVIDER',
            'purchaseR',
            'checkinR',
            'activeR',
            'fotoreadyR',
            'shippedR',
            'checkedR',
            'cancelled',
            'skip',
            'onhold'
        ],
        icon: 'rocket'
    },
    {
        name: 'sample3',
        nameRepair: 'sample3repair',
        status: '',
        pageAdmin: <ProjectSteps view={'loc'} sampleNr={'3'}/>,
        page: <ProjectStepSamplePCBA sampleNr={'3'}/>,
        statusOptions: [
            'inactive',
            'notstarted',
            'purchase',
            'checkin',
            'active',
            'fotoready',
            'shipped',
            'checked',
            'DIVIDER',
            'purchaseR',
            'checkinR',
            'activeR',
            'fotoreadyR',
            'shippedR',
            'checkedR',
            'cancelled',
            'skip',
            'onhold'
        ],
        icon: 'rocket'
    },
    {
        name: 'series',
        status: '',
        pageAdmin: <ProjectSteps view={'loc'} isSerie={true}/>,
        page: <ProjectStepSamplePCBA isSerie={true}/>,
        statusOptions: [
            'inactive',
            'purchase',
            'checkin',
            'active',
            'partready',
            'ready',
            'partshipped',
            'shipped',
            'cancelled',
            'onhold'
        ],
        icon: 'factory'
    },
    /*{
        name: 'modification',
        status: '',
        page: <ProjectStepModification/>,
        statusOptions: [
            'inactive',
            'notstarted',
            'active',
            'shipped',
        ],
        icon: 'wrench'
    },*/
]

class Project extends Component{

    componentDidMount() {
        let id= this.props.match.params.projectId;

        this.fetchAll(id)
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if( prevProps.currentUser == null && this.props.currentUser != null ) {
            let id= this.props.match.params.projectId;
            this.fetchAll(id)
        }

        if( prevProps.match.params.projectId !== this.props.match.params.projectId ) {
            let id= this.props.match.params.projectId;
            this.fetchAll(id)
        }
    }

    fetchAll = (id) => {
        this.props.dispatch(fetchProject(id))
        this.props.dispatch(fetchProjectEvents(id))
        this.props.dispatch(getProductionRelease(id))
        this.props.dispatch(getProjectModification(id))
    }

    renderPage(name, project, admin, subPageName) {

        const pp = projectPhases.find(pp => pp.name === name)

        if( pp && pp.page && pp.pageAdmin ) {
            return <div key={pp.name} style={{textAlign: 'left'}}>
                {
                    React.cloneElement(
                        admin ? pp.pageAdmin : pp.page,
                        {status: project ? project[pp.name] : '', subPageName: subPageName}
                    )
                }
            </div>
        }

        if( project ) {
            let phases = projectPhases.filter(pp => ['active', 'purchase', 'checkin'].includes(project[pp.name]) )

            if( phases.length ) {
                let pp = phases[phases.length-1]

                setTimeout(() => {
                    let id= this.props.match.params.projectId;
                    this.props.history.push(`/project/${id}/${pp.name}/`)
                }, 2000);

                return <div key={'UNKNOWN'} style={{marginTop: 32}}>
                    Projectpagina niet gevonden<br/>
                    <br/>
                    <Loader active inline/><br/>
                    Redirecting to: {pp.name}
                </div>
            }
        }

        return <div key={'UNKNOWN'} style={{marginTop: 32}}>
            Projectpagina niet gevonden
        </div>
    }

    render() {

        let pageName= this.props.match.params.page;
        let subPageName= this.props.match.params.subpage;

        pageName= pageName ? pageName : projectPhases[0].name

        console.log('pageName ' + subPageName)

        const admin = isEmployee(this.props.currentUser)

        let projectName = ''

        if( this.props.project ) {
            projectName = this.props.project.internalName ? this.props.project.internalName : this.props.project.name
        }

        return (
            <main className={'pageContainer'} id={'projectPage'}>

                <Helmet>
                    <title>{projectName} - DeltaProto</title>
                </Helmet>

                {!this.props.currentUser ?
                    <div>
                        <p><Translate value='project_need_to_login'/></p>
                        <Button color='red' size='large' type="button" onClick={() => this.props.dispatch(openLoginSignup(true))}><p data-langkey="login_login">Login</p></Button>
                    </div> : ''
                }

                {this.props.project ?
                    <ProjectCard project={this.props.project} statusBarClickable={true} activePage={pageName} subPage={subPageName}/>
                    : ''}

                {this.renderPage(pageName, this.props.project, admin, subPageName)}

            </main>


        )
    }
}

const mapStateToProps = (state) => {
    return {
        lang: state.main.lang,
        currentLang: state.main.currentLang,
        currentUser: state.main.currentUser,
        project: state.project.project,
        fetchingProject: state.project.fetchingProject,
        eventViewOpen: state.project.eventViewOpen,
        attachmentList: state.project.attachmentList,
        centroid: state.project.centroid,
        orderList: state.project.orderList,
        pcbs: state.project.pcbs,
        sendProtoFotoBusy: state.project.sendProtoFotoBusy,
        sendProtoFotoResult: state.project.sendProtoFotoResult,
    }
}

export default connect(mapStateToProps)(withRouter(Project))
