import React from 'react';
import connect from "react-redux/es/connect/connect";
import {
    connectMohican,
    mohicanSendMessage,
    setBomDemoMode,
    getMohicanUrl,
    setMohicanUrl,
    openMohicanConnectionModal,
    openMohicanHomeModal,
    openMohicanTrayModal,
    openMohicanPcbModal,
    selectDesignatorBomProduction, validateLogin, mohicanGetPosition, updateMohicanSetting, resetMohicanSettingsDefault
} from "../actions";
import {
    Button,
    Checkbox,
    Divider,
    Dropdown,
    Form,
    Header,
    Icon,
    Input,
    List,
    Message,
    Modal,
    Popup, Segment
} from "semantic-ui-react";
import PropTypes from "prop-types";
import FloatingWindow from "../FloatingWindow";
import Translate from "../Translate";
import {getAveragePositionFromList, getDerivative} from "./functions";
import _ from "lodash";
import MohicanSocket from "./MohicanSocket";

import ping from "../sounds/Ping.wav"
import temple from "../sounds/Temple.wav"
//import {CartesianGrid, Line, LineChart, XAxis, YAxis} from "recharts"; "recharts": "^2.6.2",
import {getOrderItemsByRow} from "./functions";
import {getRowByDesignator, gotoPickup, gotoPlace} from "./BomProduction";

const caseSensitiveSearch = (options, query) => {
    return options.filter((opt) => opt.text.toLowerCase().includes(query.toLowerCase()))
}

const setAllMohicanLeds = (leds) => {
    let color = leds.replaceAll('#', '').toUpperCase();
    mohicanSendMessage('MESSAGE', {device: 'MAIN', value: 'L0'+color})
    mohicanSendMessage('MESSAGE', {device: 'MAIN', value: 'L1'+color})
    mohicanSendMessage('MESSAGE', {device: 'MAIN', value: 'L2'+color})
    mohicanSendMessage('MESSAGE', {device: 'MAIN', value: 'L3'+color})
    mohicanSendMessage('MESSAGE', {device: 'MAIN', value: 'L4'+color})
    mohicanSendMessage('MESSAGE', {device: 'MAIN', value: 'L5'+color})
}

class BomMohican extends React.Component {

    state = {
    }

    setAllMohicanLedsDebounce= _.debounce((color) => {
        setAllMohicanLeds(color);
        this.props.dispatch(updateMohicanSetting('color', color))
    }, 300,{leading: true, trailing: false});

    executePlaceDebounce= _.debounce((color) => {
        //this.pingAudio.play();
        //console.log('go to place');
        this.goToPlace();
    }, 300,{leading: true, trailing: false});

    executePickupDebounce= _.debounce((color) => {
        //this.pingAudio.play();
        //console.log('go to pickup');
        this.goToNextAndPickup();
    }, 300,{leading: true, trailing: false});

    componentDidMount() {
        if( this.props.connect ) {
            this.connect();
        }

        let d = localStorage.getItem('deltaprotoplankje');

        if( d != null && d !== "null" ) {
            this.props.dispatch({
                type: 'SET_PLANKJE_POSITION',
                value: JSON.parse(d)
            })
        }

        this.pingAudio = new Audio(ping);
        this.templeAudio = new Audio(temple);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if( prevProps.connect !== this.props.connect ) {
            if( this.props.connect ) {
                this.connect();
            } else {
                this.disconnect();
            }
        }
    }

    componentWillUnmount() {
        if( this.props.inlineView ) {
            //mohicanSendMessage('POSITION_TIMEOUT', 300)
            if( this.props.mohicanClient ) {
                this.props.mohicanClient.disconnect()
            }
        }
    }

    renderControls(connected) {

        let hasPcbPos = this.props.mohicanPcbPosition != null;
        let hasPlankjePos = this.props.mohicanPlankjePosition != null;
        let selectedDesignator = this.props.selectedDesignator;

        return <>

            {/*<Button disabled={!connected} onClick={() => {
                mohicanSendMessage('POSITION_TIMEOUT', 100)
            }}>Get position</Button>
            <Button disabled={!connected} onClick={() => {
                mohicanSendMessage('POSITION_TIMEOUT', 0)
            }}>Stop get position</Button>*/}

            <Button disabled={!connected}
                onClick={() => this.setState({demoDialog: !this.state.demoDialog})}
            >Demo</Button>

            <Popup trigger={
                <Button disabled={!connected} onClick={() => {
                    mohicanSendMessage('SET_PLANKJE_POSITION', null)
                }} color={hasPlankjePos ? 'green' : 'red'}>Set plankje position</Button>
            }>
                {hasPlankjePos ? 'Positie = ' + JSON.stringify(this.props.mohicanPlankjePosition) : 'Plaats de naald exact boven de eerste pin (links bovenin het plankje) en druk dan op deze knop.'}
            </Popup>

            <Popup trigger={
                <Button disabled={!connected} icon labelPosition='left' onClick={() => {
                    if( selectedDesignator ) {
                        mohicanSendMessage('SET_PCB_POSITION', null)
                    }
                }} color={hasPcbPos ? 'green' : 'red'} style={{cursor: !selectedDesignator ? 'no-drop' : 'pointer'}}>
                    <Icon name='crosshairs'/>
                    Set PCB positie
                </Button>
            }>
                {selectedDesignator ? <p>Stel de huidige positie in {selectedDesignator.value}</p> : <p>Selecteer een designator, beweeg de kop naar de positie van deze desgnator, en druk op deze knop.</p>}
            </Popup>

            {/*<div style={{display:"inline-block"}}>
                {JSON.stringify(this.props.mohicanPosition)}
            </div>*/}
        </>
    }

    toggleConnection(connected) {
        if( connected ) {
            if( this.props.mohicanClient ) {
                this.props.mohicanClient.disconnect()
            }
        } else {
            this.connect()
        }
    }

    connect() {
        let storedTimeout = parseInt(localStorage.getItem('deltaprotomohicantimeout'));
        let timeOut = (storedTimeout && storedTimeout > 0) ? storedTimeout : 300;
        if( this.props.mohicanClient ) {
            this.props.mohicanClient.disconnect()
        }
        this.props.dispatch(connectMohican(() => {

            this.connected()
            //this.startMohicanGetPosition(timeOut)

        }), this.props.mohicanSettings)


    }

    connected() {
        console.log('connected');
        const s = this.props.mohicanSettings;
        mohicanSendMessage('SET_SPEED', s.speed)
        mohicanSendMessage('SET_ACCELERATION', s.acceleration)
        mohicanSendMessage('SET_CURRENT', s.current)
        mohicanSendMessage('MESSAGE', {device: 'MAIN', value: 'T'+s.pumpPower})
        mohicanSendMessage('MESSAGE', {device: 'X', value: 'u '+s.updateSpeed})
        mohicanSendMessage('MESSAGE', {device: 'Y', value: 'u '+s.updateSpeed})
        mohicanSendMessage('MESSAGE', {device: 'MAIN', value: 'i'})
        mohicanSendMessage('MESSAGE', {device: 'MAIN', value: 'i'})
        mohicanSendMessage('MESSAGE', {device: 'X', value: 'v'})
        mohicanSendMessage('MESSAGE', {device: 'Y', value: 'v'})
        setAllMohicanLeds(s.color);

        setTimeout(() => {
            mohicanSendMessage('GET_VERSION', null)
        }, 1000)


    }

    startMohicanGetPosition(timeOut) {
        if( this.myTimer ) {
            clearInterval(this.myTimer)
            this.myTimer = null;
        }

        this.myTimer = setInterval(() => {
            //if( this.props.currentUser != null ) {
            this.props.dispatch( mohicanGetPosition() )
            //}
        }, timeOut);
    }

    disconnect() {
        if( this.props.mohicanClient ) {
            this.props.mohicanClient.disconnect()
        }
        if( this.myTimer ) {
            clearInterval(this.myTimer)
            this.myTimer = null;
        }
    }

    renderSetPositionModal() {

        if( !this.props.print || !this.state.setPos ) {
            return <></>
        }

        let options = this.props.print.bom.map(r => r.designators).flat().map(d => ({
            key: d.id, value: {x: d.x, y: d.y}, text: d.value
        }))

        return <Modal closeIcon={true} closeOnDimmerClick={false} open={this.state.setPos}
                      onClose={() => this.setState({setPos: false})}>
            <Modal.Header>Set PCB positie</Modal.Header>
            <Modal.Content >

                Beweeg de kop naar een desgnator en selecteer deze hieronder:

                {JSON.stringify(this.props.mohicanPosition)}

                <Dropdown
                    fluid
                    options={options}
                    placeholder='Selecteer een designator'
                    search={caseSensitiveSearch}
                    selection
                    onChange={(e, d) => {
                        console.log(d.value)
                        this.setState({setPosSelected: d})
                    }}
                />

                {this.state.setPosSelected && <div>
                    <div>Designator: {this.state.setPosSelected.text}</div>
                    <div>Position: {this.state.setPosSelected.value.x} {this.state.setPosSelected.value.y}</div>
                </div>}

            </Modal.Content>
            <Modal.Actions>
                <Button onClick={() => this.setState({setPos: false})}>
                    Sluiten
                </Button>
                <Button primary disabled={!this.state.setPosSelected} onClick={() => {
                    this.setState({setPos: false})
                    mohicanSendMessage('SET_POSITION', this.state.setPosSelected.value)
                }}>
                    Selecteren
                </Button>
            </Modal.Actions>
        </Modal>
    }

    renderActions(connected) {
        return <div style={{display: 'flex', gap: 8}}>

            {this.renderSetPositionModal()}

            <Button disabled={connected} onClick={() => this.toggleConnection(connected)}>Verbinding maken</Button>

            {" => "}

            {this.renderControls(connected)}
        </div>
    }

    getPeakValueAndLocation(d) {
        let max = 0;
        let maxIndex = 0;
        for( let i = 0; i < d.length; i++ ) {
            if( d[i].y > max ) {
                max = d[i].y;
                maxIndex = i;
            }
        }
        return {max, maxIndex};
    }

    getValyValueAndLocation(d) {
        let min = 0;
        let minIndex = 0;
        for( let i = 0; i < d.length; i++ ) {
            if( d[i].y < min ) {
                min = d[i].y;
                minIndex = i;
            }
        }
        return {min, minIndex};
    }

    goToNextAndPickup = () => {
        const d = this.props.selectedDesignator;

        if (this.props.lastGoToDesignator && this.props.lastGoToDesignator.id === d.id) {
            this.selectNextD()
        } else {
            this.goToPickup()
        }
    }

    selectNextD = () => {
        //if( !this.isDisabled() ) {
        console.log('pickupNext')
        const printId = this.props.print.id;

        if (this.props.nextDesignator) {

            let r = getRowByDesignator(this.props.nextDesignator, this.props.print.bom);
            //console.log(JSON.stringify(r))
            let orderItems = getOrderItemsByRow(r, this.props.bomOrderList);
            orderItems = orderItems.map(o => {
                let lastUsedBoardLocation = this.props.boardLocations.find(bl => bl.boardLocation === o.boardLocation)
                return {...o, lastUsedBoardLocation}
            })

            //console.log(JSON.stringify(orderItems))
            if (orderItems && orderItems.length) {
                gotoPickup(printId, orderItems[0], this.props.mohicanPlankjePosition, this.props.dispatch);
            }

            this.props.dispatch(selectDesignatorBomProduction(this.props.nextDesignator, this.props.nextNextDesignator))
        }
        //}
    }

    goToPickup = (reset) => {
        if( this.props.selectedDesignator == null || this.props.print == null ) {
            return;
        }

        const printId = this.props.print.id;
        const r= this.props.selectedDesignator.r;

        let orderItems = getOrderItemsByRow(r, this.props.bomOrderList);
        orderItems = orderItems.map(o => {
            let lastUsedBoardLocation = this.props.boardLocations.find(bl => bl.boardLocation === o.boardLocation)
            return {...o, lastUsedBoardLocation}
        })

        if (orderItems && orderItems.length) {
            gotoPickup(printId, orderItems[0], this.props.mohicanPlankjePosition, this.props.dispatch, reset);
        }
    }

    goToPlace = () => {
        if( this.props.selectedDesignator == null || this.props.print == null ) {
            return;
        }

        const printId = this.props.print.id;
        const d = this.props.selectedDesignator;

        let {bomProductionSelected} = this.props;

        let bomProductionName = this.props.currentUser.firstName;

        gotoPlace(printId, d, this.props.mohicanPcbPosition, bomProductionSelected, bomProductionName, this.props.dispatch)
        //}
    }

    renderCurrentPosition(connected, showHomeControl = false) {
        const debugMessage = false;
        let renderDebug = null;

        if( this.props.mohicanEnableFeedbackControl ) {
            const d1 = getDerivative(this.props.mohicanPositionHistoryList)
            const d2 = getDerivative(d1);

            const max = 0;
            //const max = Math.max(...d.map(v => v));
            //const min = Math.min(...d.map(v => v));

            //if there is a value in the list d that exceeds 0.1, then we are moving
            //const moving = d.some(v => Math.abs(v) > 4);

            //if( moving ) {
            //    this.executeSoundDebounce();
            //}

            const standingStillBefore = d2.slice(0, 3).every(v => Math.abs(v.y) < 0.1);
            const standingStill = d2.slice(-2).every(v => Math.abs(v.y) < 0.1);

            //get the position of the highest peak, and the peak value
            const peak = this.getPeakValueAndLocation(d2);
            const valy = this.getValyValueAndLocation(d2);

            const l = this.props.mohicanPositionHistoryList.length;

            let from = 0;
            let to = 0;

            if ((peak.maxIndex - 1) > 0 && (peak.maxIndex + 1 < l) && (valy.minIndex - 1 > 0) && (valy.minIndex + 1 < l)) {
                if (peak.maxIndex < valy.minIndex) {
                    from = this.props.mohicanPositionHistoryList[peak.maxIndex - 1].y;
                    to = this.props.mohicanPositionHistoryList[valy.minIndex + 1].y;
                } else {
                    from = this.props.mohicanPositionHistoryList[valy.minIndex - 1].y;
                    to = this.props.mohicanPositionHistoryList[peak.maxIndex + 1].y;
                }
            }

            const distance = to - from;

            if (standingStillBefore && standingStill) {
                if (peak.max > .9 && valy.min < -.9) {
                    const dinstanceAbs = Math.abs(distance);
                    if (15 > dinstanceAbs && dinstanceAbs > 4) {
                        if (distance < 0) {
                            //we are moving closer
                            this.executePickupDebounce();
                        } else {
                            //we are moving away
                            this.executePlaceDebounce();
                        }
                    }
                }
            }

            if( debugMessage ) {
                renderDebug = <div>
                        <div>
                            standingStill {standingStill ? 'true' : 'false'}<br/>
                            standingStillBefore {standingStillBefore ? 'true' : 'false'}<br/>
                            peak {peak.max} {peak.maxIndex}<br/>
                            valy {valy.min} {valy.minIndex}<br/>
                            distance {distance}<br/>
                            from {from}<br/>
                            to {to}<br/>
                        </div>

                        {/*{showHomeControl ? <LineChart width={500} height={300} data={d2.map(v => ({amt: v.y, y: v.y}))}>
                            <XAxis dataKey="name" domain={[-5, 5]}/>
                            <YAxis/>
                            <CartesianGrid stroke="#eee" strokeDasharray="5 5"/>
                            <Line type="monotone" dataKey="x" stroke="#8884d8"/>
                            <Line type="step" dataKey="y" stroke="#82ca9d"/>
                        </LineChart> : null}*/}
                    </div>
            }
        }

        return <>
            {/*{!showHomeControl ? <Button
                style={{marginRight: 8}}
                size={'tiny'}
                icon={'power'}
                color={connected ? 'green' : 'red'}
                onClick={() => connected ? this.disconnect() : this.connect()}
            /> : null}*/}

            {(connected && this.props.mohicanPosition) && <>
                {`X: ${this.props.mohicanPosition.x} Y: ${this.props.mohicanPosition.y}`}
            </>}

            <div onClick={() => {
                this.executePlaceDebounce();
            }} >
            </div>

            {renderDebug}

            {/*{(connected && this.props.mohicanPcbPosition) && <div>
                PCB Position: {`X: ${this.props.mohicanPcbPosition.x} Y: ${this.props.mohicanPcbPosition.y}`}
            </div>}*/}

            {showHomeControl ? <div style={{marginTop: 16, display: 'flex', gap: 16}}>
                <Popup trigger={
                    <Button disabled={!connected} icon labelPosition='left' link onClick={() => {
                        mohicanSendMessage('HOME', null)
                    }}>
                        <Icon name='home'/>
                        Home machine
                    </Button>
                }
                       content={<div>
                           Let op! De machine zal langzaam naar de links achter bewegen om het 0-punt te vinden
                       </div>}
                />

                <Popup trigger={
                    <Button icon labelPosition='left' link onClick={() => {
                        this.props.dispatch(resetMohicanSettingsDefault())
                    }}>
                        <Icon name='history'/>
                        Herstel instelling naar default
                    </Button>
                }
                       content={<div>
                           Herstel alle instellingen voor de Microplacer terug naar de standaard waarden
                       </div>}
                />
            </div> : null}
        </>
    }

    /*renderSelectCameraType() {
        return <>
            <Form.Field>
                <Checkbox
                    radio
                    label='USB Camera'
                    name='checkboxRadioGroup'
                    value='webcam'
                    checked={this.state.cameraType === 'webcam'}
                    onChange={(e, data) => {
                        localStorage.setItem('deltaprotocameratype', 'webcam')
                        this.setState({cameraType: 'webcam'})
                    }}
                />
            </Form.Field>
            <Form.Field>
                <Checkbox
                    radio
                    label='Raspberry pi Camera (over ethernet)'
                    name='checkboxRadioGroup'
                    value='pi'
                    checked={this.state.cameraType === 'pi'}
                    onChange={(e, data) => {
                        localStorage.setItem('deltaprotocameratype', 'pi')
                        this.setState({cameraType: 'pi'})
                    }}
                />
            </Form.Field>
        </>
    }*/

    render() {

        let connected = this.props.mohicanClient && this.props.mohicanClient.connected;

        const mohicanSettings = this.props.mohicanSettings

        //if( this.props.mohicanClient && !connected ) {
        //    this.props.mohicanClient.disconnect()
        //}

        let notify = this.props.mohicanClientNotify;

        if( this.props.inlineView ) {
            {this.renderActions(connected)}
        }

        let hasPlankjePos = this.props.mohicanPlankjePosition != null;

        return <>

            <Popup position='bottom left' hoverable content={
                <div>

                    {this.renderCurrentPosition(connected)}

                    {connected ? '' : <>
                        <b>Not connected to uPlacer</b><br/>

                    </>}

                    {/*<MohicanSocket/>*/}

                </div>
            } trigger={
                <div style={{display: 'inline', cursor: 'pointer'}} onClick={() => this.props.dispatch(openMohicanConnectionModal(true))}>

                    <Button icon color={connected ? (notify ? 'green' : 'green') : 'red'}>
                        <Icon name='laptop' />
                    </Button>
                </div>
            } />

            <Modal size={'small'} open={this.props.mohicanConnectionModal} closeIcon onClose={() => this.props.dispatch(openMohicanConnectionModal(false))}>
                <Modal.Header>uPlacer connection</Modal.Header>
                <Modal.Content>

                    <Translate value={'microplacer.connect.info'}/><br/><br/>

                    {/*<div>
                        Refresh speed:<br/>
                        <Input type='number' action={{
                            content: 'Save',
                            primary: !!this.state.uPlacerPositionTimeout,
                            onClick: () => {
                                let newValue = parseInt(this.state.uPlacerPositionTimeout);
                                if( !isNaN(newValue) ) {
                                    localStorage.setItem('deltaprotomohicantimeout', newValue);
                                    //mohicanSendMessage('POSITION_TIMEOUT', newValue)
                                    this.startMohicanGetPosition(newValue)
                                    this.setState({uPlacerPositionTimeout: null})
                                }
                            }
                        }} placeholder='Refresh speed in ms (default is 300ms)' onChange={ (e) => {
                            this.setState({uPlacerPositionTimeout: e.target.value})
                        }}
                        fluid value={this.state.uPlacerPositionTimeout ? this.state.uPlacerPositionTimeout : localStorage.getItem('deltaprotomohicantimeout')}/>
                    </div>*/}

                    <Segment.Group>

                        <Segment>
                            <Header>Stap 1</Header>
                            Download and installeer <a href="https://www.azul.com/downloads/?package=jdk#zulu" target="_blank">Java</a>
                            {/**
                             Finder kan de java sdk niet vinde, mogelijke fix:
                             https://stackoverflow.com/questions/76523553/how-to-make-usr-libexec-java-home-find-jdk-installed-using-sdkman-on-macos
                             **/}
                        </Segment>

                        <Segment>
                            <Header>Stap 2</Header>
                            Download en start de <a download href="/images/uplacer/microPlacer-1.9.jar" target="_blank">microPlacer client</a>.
                        </Segment>

                        <Segment>
                            <Header>Stap 3</Header>
                            Accepteer het unsigned certificate: <a target="_blank" href={getMohicanUrl('')}>accept certificate</a>
                        </Segment>

                    </Segment.Group>

                    <div style={{display: 'flex', gap: 16}}>
                        <Button
                            icon={'power'}
                            onClick={() => connected ? this.disconnect() : this.connect()}
                        >{connected ? 'Disconnect' : 'Connect'}</Button>

                        <Icon name='laptop' style={{marginLeft: 16, color: connected ? (notify ? '#139b33' : '#21ba45') : '#db2828'}}/>
                        {connected ? ' Connected' : ' Not connected'}

                        <div>
                            <div>{this.props.mohicanVersion ? 'Mohican: ' + this.props.mohicanVersion.version : null}</div>
                            <div>{this.props.mohicanVersion ? 'Java: ' + this.props.mohicanVersion.javaVersion : null}</div>
                        </div>

                    </div>
                    <br/>
                    <div>
                        Refresh speed ({mohicanSettings.updateSpeed})
                        <input
                            style={{width: '100%'}}
                            disabled={!connected}
                            id="typeinp"
                            type="range"
                            min="-8000" max="-1000"
                            value={mohicanSettings.updateSpeed * -1}
                            onChange={(event => {
                                this.props.dispatch(updateMohicanSetting('updateSpeed', event.target.value * -1))
                            })}
                            onMouseUp={() => {
                                mohicanSendMessage('MESSAGE', {device: 'X', value: 'u '+mohicanSettings.updateSpeed})
                                mohicanSendMessage('MESSAGE', {device: 'Y', value: 'u '+mohicanSettings.updateSpeed})
                            }}
                            step="1"/>
                    </div>
                    <br/>
                    <div>
                        Speed ({mohicanSettings.speed})
                        <input
                            style={{width: '100%'}}
                            disabled={!connected}
                            id="typeinp"
                            type="range"
                            min="10" max="300"
                            value={mohicanSettings.speed}
                            onChange={(event => {
                                this.props.dispatch(updateMohicanSetting('speed', event.target.value))
                            })}
                            onMouseUp={() => {
                                mohicanSendMessage('SET_SPEED', mohicanSettings.speed)
                            }}
                            step="1"/>
                    </div>
                    <br/>
                    <div>
                        Acceleration ({mohicanSettings.acceleration})
                        <input
                            style={{width: '100%'}}
                            disabled={!connected}
                            id="typeinp"
                            type="range"
                            min="10" max="300"
                            value={mohicanSettings.acceleration}
                            onChange={(event => {
                                this.props.dispatch(updateMohicanSetting('acceleration', event.target.value))
                            })}
                            onMouseUp={() => {
                                mohicanSendMessage('SET_ACCELERATION', mohicanSettings.acceleration)
                            }}
                            step="1"/>
                    </div>
                    <br/>
                    <div>
                        Current ({mohicanSettings.current} mA)
                        <input
                            style={{width: '100%'}}
                            disabled={!connected}
                            id="typeinp"
                            type="range"
                            min="10" max="1000"
                            value={mohicanSettings.current}
                            onChange={(event => {
                                this.props.dispatch(updateMohicanSetting('current', event.target.value))
                            })}
                            onMouseUp={() => {
                                mohicanSendMessage('SET_CURRENT', mohicanSettings.current)
                            }}
                            step="1"/>
                    </div>
                    <br/>
                    <div>
                        Pump power ({700 - mohicanSettings.pumpPower})
                        <input
                            style={{width: '100%'}}
                            disabled={!connected}
                            id="typeinp"
                            type="range"
                            min="-700" max="0"
                            value={mohicanSettings.pumpPower * -1}
                            onChange={(event => {
                                this.props.dispatch(updateMohicanSetting('pumpPower', parseInt(event.target.value) * -1))
                            })}
                            onMouseUp={() => {
                                mohicanSendMessage('MESSAGE', {device: 'MAIN', value: 'T'+mohicanSettings.pumpPower})
                                setTimeout(() => mohicanSendMessage('MESSAGE', {device: 'MAIN', value: 'P1ON'}), 100)
                                setTimeout(() => mohicanSendMessage('MESSAGE', {device: 'MAIN', value: 'V1ON'}), 100)
                                setTimeout(() => mohicanSendMessage('MESSAGE', {device: 'MAIN', value: 'P1OFF'}), 2000)
                                setTimeout(() => mohicanSendMessage('MESSAGE', {device: 'MAIN', value: 'V1OFF'}), 2000)
                                //mohicanSendMessage('SET_CURRENT', mohicanSettings.current)
                            }}
                            step="1"/>
                    </div>

                    <div style={{ marginTop: 12}}>
                        Light color<br/>
                        <input style={{ marginTop: 4}} type="color" id="favcolor" name="favcolor" value={mohicanSettings.color} onChange={(event) => {
                            //this.props.dispatch(updateMohicanSetting('color', event.target.value))
                            this.setAllMohicanLedsDebounce(event.target.value)
                        }} onBlur={(event) => {

                        }}/>
                    </div>

                    <Divider/>

                    {this.renderCurrentPosition(connected, true)}

                    <Divider/>

                    <div>
                        <div>
                            {hasPlankjePos ? `Current tray position = X: ${this.props.mohicanPlankjePosition.x} Y: ${this.props.mohicanPlankjePosition.y}` : 'There is no tray position set.'}
                        </div>

                        <div style={{display: 'flex', gap: 8, marginTop: 4}}>
                            <Popup trigger={
                                <Button onClick={() => mohicanSendMessage('SET_PLANKJE_POSITION', null)}>Set tray position</Button>
                            } content={<div>
                                Move the nozzle to the <b>SECOND</b> pin on the tray. Start counting from the most top left pin on the tray.
                            </div>}/>
                            <Button onClick={() => mohicanSendMessage('GOTO', this.props.mohicanPlankjePosition)} disabled={!hasPlankjePos}>Move nozzle to the tray position</Button>
                        </div>
                    </div>


                </Modal.Content>
                <Modal.Actions>
                    <Button onClick={() => this.props.dispatch(openMohicanConnectionModal(false))}>Close</Button>
                </Modal.Actions>
            </Modal>

            <Modal size={'mini'} open={this.props.mohicanPcbModal} closeIcon onClose={() => this.props.dispatch(openMohicanPcbModal(false))}>
                <Modal.Header>PCB position</Modal.Header>
                <Modal.Content>

                    <List ordered>
                        <List.Item>Choose a visible designator on the PCB.</List.Item>
                        <List.Item>Move the needle exaclty in the middle of the part.</List.Item>
                        <List.Item>Select this corresponding designator in the dropdown</List.Item>
                        <List.Item>And press 'Set PCB position</List.Item>
                    </List>

                    {this.props.print ? <Form>
                        <Form.Dropdown
                            label='Designator'
                            selection search
                            options={
                                this.props.print.bom.map(r => r.designators).flat().map(d => ({
                                    key: d.id, text: d.value, value: d.value, data: d
                                }))
                            }
                            value={this.props.selectedDesignator ? this.props.selectedDesignator.value : ''}
                            onChange={(e,d) => {
                                this.props.dispatch(selectDesignatorBomProduction(this.props.print.bom.map(r => r.designators).flat().find(m => m.value === d.value)))
                            }}
                        />

                        <Button disabled={!connected} icon labelPosition='left' onClick={() => {
                            if( this.props.selectedDesignator ) {
                                mohicanSendMessage('SET_PCB_POSITION', null)
                            }
                        }} style={{cursor: !this.props.selectedDesignator ? 'no-drop' : 'pointer'}}>
                            <Icon name='crosshairs'/>
                            Set PCB position
                        </Button>

                        {/*<Button disabled={true || !connected || !this.props.selectedDesignator} onClick={() => {
                            if( this.props.selectedDesignator ) {
                            }
                        }} style={{cursor: !this.props.selectedDesignator ? 'no-drop' : 'pointer'}}>
                            Move to {this.props.selectedDesignator ? this.props.selectedDesignator.value : ''}
                        </Button>*/}
                    </Form> : ''}

                </Modal.Content>
                <Modal.Actions>
                    <Button onClick={() => this.props.dispatch(openMohicanPcbModal(false))}>Close</Button>
                </Modal.Actions>
            </Modal>

            {/*{this.renderControls(connected)}*/}

            <div style={{textAlign: 'center'}}>
                {(this.state.demoDialog || this.props.demoMode) ? <FloatingWindow
                    onClose={() => {
                        this.setState({demoDialog: false})
                        this.props.dispatch(setBomDemoMode(false))
                    }}
                    header={'Demo mode'}
                    name={'deltaprotodemomodewindow'}
                >
                    <div style={{padding: '1em'}}>
                        <div>

                            <Button
                                color='blue'
                                onClick={() => {
                                    this.props.dispatch(setBomDemoMode(!this.props.bomDemoMode))
                                }}
                            >
                                {this.props.bomDemoMode ? 'Stop demo mode' : 'Start demo mode'}
                            </Button>

                            <br/>

                            <div>
                            X <Input value={this.state.x} onChange={(e) => this.setState({x: e.target.value})}/>
                            </div>

                            <div>
                            Y <Input value={this.state.y} onChange={(e) => this.setState({y: e.target.value})}/>
                            </div>

                            <Button
                                onClick={() => {
                                    let x = parseFloat( this.state.x );
                                    x = x.toFixed(1)

                                    let y = parseFloat( this.state.y );
                                    y = y.toFixed(1)

                                    mohicanSendMessage("GOTO", {x, y})
                                }}
                            >
                                Move
                            </Button>

                            <br/>
                            <br/>

                            <Button
                                onClick={() => {
                                    mohicanSendMessage("ZERO", null)
                                }}
                            >
                                ZERO
                            </Button>

                            <Divider/>

                            {this.props.mohicanPosition ? <div>
                                {`X: ${this.props.mohicanPosition.x} Y: ${this.props.mohicanPosition.y}`}
                            </div> : ''}


                            {this.props.bomDemoMode ? <Icon loading name='asterisk' color='blue' size='big'/> : ''}

                        </div>

                    </div>
                </FloatingWindow>: ''}
            </div>
        </>

    }
}

BomMohican.propTypes = {
    connect: PropTypes.bool,
    inlineView: PropTypes.bool,
    onSettingsChanged: PropTypes.bool,
};

BomMohican.defaultProps = {
    connect: false,
    inlineView: false,
    onSettingsChanged: null
};

const mapStateToProps = (state) => {
    return {
        mohicanClient: state.bom.mohicanClient,
        mohicanClientNotify: state.bom.mohicanClientNotify,
        mohicanPosition: state.bom.mohicanPosition,
        print: state.bom.print,
        mohicanPcbPosition: state.bom.mohicanPcbPosition,
        mohicanPositionList: state.bom.mohicanPositionList,
        mohicanPositionHistoryList: state.bom.mohicanPositionHistoryList,
        mohicanPlankjePosition: state.bom.mohicanPlankjePosition,
        selectedDesignator: state.bom.selectedDesignator,
        bomDemoMode: state.bom.bomDemoMode,
        mohicanConnectionModal: state.bom.mohicanConnectionModal,
        mohicanHomeModal: state.bom.mohicanHomeModal,
        mohicanTrayModal: state.bom.mohicanTrayModal,
        mohicanPcbModal: state.bom.mohicanPcbModal,
        mohicanSettings: state.bom.mohicanSettings,
        mohicanVersion: state.bom.mohicanVersion,
        bomOrderList: state.bom.bomOrderList,
        boardLocations: state.bom.boardLocations,
        currentUser: state.main.currentUser,
        lastGoToDesignator: state.bom.lastGoToDesignator,
        nextDesignator: state.bom.nextDesignator,
        mohicanEnableFeedbackControl: state.bom.mohicanEnableFeedbackControl,
    }
}

export default connect(mapStateToProps)(BomMohican)