import React from 'react'

class Link extends React.Component {
    render() {

        return <a
            href={this.props.href}
            rel="noopener"
            onClick={(e) => {
                e.preventDefault();
                const otherWindow = window.open();
                otherWindow.opener = null;
                otherWindow.location = this.props.href;
                return false;
            }}
            {...this.props}
        >
            {this.props.children}
        </a>
    }
}

export default Link;