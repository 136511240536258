import React from 'react';
import {Icon} from "semantic-ui-react";
import * as PropTypes from "prop-types";

class Spinner extends React.Component {

    componentDidMount() {
        /*if( this.props.autoFocus && this.myRef ) {
            //this.myRef.scrollIntoView({behavior: "smooth"});
            var target = this.myRef;

            var parent = document.getElementById("scrolling");

            parent.scrollTop = target.offsetTop;
        }*/
    }

    render() {
        return <>
            <Icon loading name='spinner' color='blue'/>
            <div
                ref={(c) => {
                    if( this.props.autoFocus ) {
                        this.myRef = c;
                    }
                }}
            />
        </>
    }
}

Spinner.propTypes = {
    autoFocus: PropTypes.bool,
};

Spinner.defaultProps = {
    autoFocus: false,
};

export default Spinner;