import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {getChatOverviewByPage, getEmailOverviewByPage} from "../actions";
import DeltaTable from "../deltatable/DeltaTable";
import {formatDateTime} from "../actions/functions";

class MailView extends React.Component {

  state = {}

  componentDidMount() {
    this.props.dispatch(getEmailOverviewByPage(0))
  }

  render() {
    const { page, fetchingPage } = this.props;


    const emails = page.content;

    const cellStyle = {overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap"}

    return (
      <div>
        E-mail berichten aangemaakt en verzonden door de website

        <DeltaTable
          data={emails}
          loading={fetchingPage}
          onLoadMore={() => {
            if( page.last ) return;
            this.props.dispatch(getEmailOverviewByPage(page.number + 1))
          }}
          columns={[
            {
              id: 1,
              name: "createdDate",
              header: "Datum aangemaakt",
              defaultWidth: 140,
              render: (row) => <div style={cellStyle}>{formatDateTime(row.createdDate)}</div>
            },
            {
              id: 2,
              name: "from",
              header: "From",
              defaultWidth: 184,
              render: (row) => <div style={cellStyle}>{row.from}</div>
            },
            {
              id: 3,
              name: "to",
              header: "To:",
              defaultWidth: 184,
              render: (row) => <div style={cellStyle}>{row.to.join("; ")}</div>
            },
            {
              id: 31,
              name: "cc",
              header: "Cc:",
              defaultWidth: 184,
              render: (row) => <div style={cellStyle}>{row.cc.join("; ")}</div>
            },
            {
              id: 32,
              name: "bcc",
              header: "Bcc:",
              defaultWidth: 184,
              render: (row) => <div style={cellStyle}>{row.bcc.join("; ")}</div>
            },
            {
              id: 4,
              name: "subject",
              header: "Subject",
              defaultWidth: 184,
              render: (row) => <div style={cellStyle}>{row.subject}</div>
            },
            {
              id: 5,
              name: "body",
              header: "Bericht",
              defaultWidth: 184,
              render: (row) => {
                let expanded = this.state.expandId === row.id;
                return <div style={{display: 'flex'}}>
                  <div style={(expanded) ? {height: 300, overflowX: "auto", whiteSpace: "pre", flex: 1} : {...cellStyle, flex: 1, marginRight: 60}} >
                    {row.text}
                  </div>
                <div style={{width: 0}}>
                  <button className='link-button' style={{marginLeft: -60, background: 'white'}} onClick={() => {
                    this.setState({expandId: expanded ? null : row.id})
                  }}>
                    {expanded ? 'minder...' : 'meer...'}
                  </button>
                </div>
              </div>}
            },
            {
              id: 6,
              name: "date",
              header: "Verzonden",
              defaultWidth: 184,
              render: (row) => <div>{formatDateTime(row.createdDate)}</div>
            },
            {
              id: 7,
              name: "sentError",
              header: "Foutmelding",
              defaultWidth: 184,
              render: (row) => <div style={cellStyle}>{row.sentError}</div>
            }
          ]}
        />
      </div>
    );
  }
}


MailView.propTypes = {
};

MailView.defaultProps = {
};

const mapStateToProps = (state) => {
  return {
    lang: state.main.lang,
    fetchingPage: state.mail.fetchingPage,
    page: state.mail.page,
  }
}

export default connect(mapStateToProps)(MailView)