import React from 'react'
import FloatingWindow from "../FloatingWindow";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {openBomCentroidView, setBomCentroidViewSearch, setBomFilter} from "../actions";
import CentroidSvg from "./CentroidSvg";
import {filterBom} from "./functions";
import {UncontrolledReactSVGPanZoom} from 'react-svg-pan-zoom';
import {Dropdown, Form, Input, Segment} from "semantic-ui-react";
import {sideOptions} from "./BomProductionSegment";
import {MapInteractionCSS} from "react-map-interaction";

class BomCentroidView extends React.Component {

    state = {
        side: 'top',
        viewWidth: 75,
        viewHeight: 49
    }

    handleChangeSearch = (value) => {
        this.props.dispatch(setBomCentroidViewSearch(value))
    }

    render() {
        if(!this.props.print || !this.props.bomCentroidView) {
            return <></>
        }

        let viewHeight = this.state.viewHeight;
        let viewWidth = this.state.viewWidth;

        const search = this.props.bomCentroidViewSearch ? this.props.bomCentroidViewSearch : ''

        return <FloatingWindow
            onClose={() => {
                this.props.dispatch(openBomCentroidView(false))
                this.props.dispatch(setBomFilter(null))
            }}
            header={'DeltaProto Centroid viewer'}
            name={'deltaprotocentroidwindow'}
        >
            <div className="svgbackground" style={{width: '100%', height: '100%'}}>
                <div style={{padding: 16, position: 'absolute', width: '100%'}}>

                    <Segment style={{zIndex: 9, display: 'flex', gap: 16}}>
                        <Form.Dropdown inline
                                       onChange={(e,d) => {
                                           this.props.dispatch(setBomFilter(d.value === 'alle' ? null : d.value))
                                       }}
                                       options={sideOptions}
                                       value={this.props.bomFilter ? this.props.bomFilter : 'alle'}
                        />
                        <div style={{flex: 1, textAlign: 'right'}}>
                            <Input placeholder='Search...' value={search} onChange={e => this.handleChangeSearch(e.target.value)}
                                   icon={search ? { name: 'close', link: true, onClick: () => this.handleChangeSearch('') } : {}}
                            />
                        </div>

                    </Segment>
                </div>
                <MapInteractionCSS maxScale={100000} minScale={0.0005} >
                    <CentroidSvg
                        fontSize={0.1}
                        style={{width: '100%', height: '100%'}}
                        print={this.props.print} bottom={this.state.side === 'bottom'}
                        items={this.props.print.bom.map(r => {
                            return r.designators.filter(d => {
                                return filterBom(r, d, this.props.bomFilter, this.props.bomSearch)
                            }).map(d => {
                                let found = false;

                                if( search && search.includes(',') ) {

                                    found = search.split(',').some(s => {
                                        s = s.toLowerCase().trim()
                                        return s && d.value.toLowerCase().includes(s)
                                    });

                                } else if( search ) {
                                    found = d.value.toLowerCase().includes(search.toLowerCase().trim());
                                }

                                if( d.topBottom === 'BOTTOM' ) {
                                    d = {...d, x: -d.x}
                                }

                                if( found ) {
                                    return {...r, ...d, highlight: true}
                                } else {
                                    return {...r, ...d}
                                }
                            })
                        }).flat()}
                    />
                </MapInteractionCSS>
            </div>

        </FloatingWindow>
    }
}

BomCentroidView.propTypes = {
};

BomCentroidView.defaultProps = {
};

const mapStateToProps = (state) => {
    return {
        currentUser: state.main.currentUser,
        print: state.bom.print,
        bomCentroidView: state.bom.bomCentroidView,
        bomCentroidViewSearch: state.bom.bomCentroidViewSearch,
        selectedDesignator: state.bom.selectedDesignator,
        bomFilter: state.bom.bomFilter,
        bomSearch: state.bom.bomSearch,
    }
}

export default connect(mapStateToProps)(BomCentroidView)