import React, { Component } from 'react';
import './App.css';
import AccountView from "./oldui/AccountView";
import {withRouter} from "react-router-dom";

class AccountViewId extends Component {
    render() {

        let id= this.props.match.params.id;

        return <main>
            <section>

                <AccountView id={id}/>

            </section>
        </main>
    }
}

export default withRouter(AccountViewId)