import React from 'react'
import connect from "react-redux/es/connect/connect";
import TabHeader from "./basic/TabHeader";
import {Button, Checkbox, Dropdown, Icon, Modal, Popup} from "semantic-ui-react";
import {
    changeProjectOrderPcbInfo,
    getProjectOrderPcbInfo,
    saveProjectOrderPcbInfo,
    saveProjectPcbPictireFid,
    sendProtoFotoMail, showUploadPcbsModal,
    toggleProjectOrderPcbTopBottom,
    updateProjectPcbPictireFid,
    wapperProjectOrderPcbInfo,
    wapperProjectOrderPcbMode
} from "./actions";
import Progressbar from "./Progressbar";
import TabMenu from "./basic/TabMenu";
import TabPane from "./basic/TabPane";
import Translate from "./Translate";
import {MapInteractionCSS} from "react-map-interaction";
import TranslatedImage from "./TranslatedImage";
import ProjectStepFotoUpload from "./ProjectStepFotoUpload";
import {fullscreen, fullscreenstyle, isEmployee} from "./actions/functions";
import ButtonTabMenuFullWidth from "./basic/ButtonTabMenuFullWidth";
import ProjectStepFotoItem from "./ProjectStepFotoItem";
import Hotkeys from "react-hot-keys";
import {Link} from "react-router-dom";
import PropTypes from "prop-types";
import MailBuilder from "./tools/MailBuilder";

class ProtoFotoView extends React.Component {

    state= {
        showWapper: false,
        hintFlip: true,
        scale: .3,
        translation: {x:0, y:0},
        wapperSpeed: 50,
        fullscreen: false,
        activeName: 'protofoto'
    }

    componentDidMount() {

        this.currentImage= new Image();
        this.currentImage.src = "/api/project/getAttachment?id=cf92c95c-9852-4b91-a206-c0985fb22417";
        this.referenceImage= new Image();
        this.referenceImage.src = "/api/project/getAttachment?id=45734352-9ee6-4ab2-aca6-348f1e0f8854";

        if( this.props.project ) {
            console.log('projectstepfoto componentDidMount ' + this.props.project.id)
            this.props.dispatch(getProjectOrderPcbInfo(this.props.project.id))
        }

        document.addEventListener('fullscreenchange', this.fullScreenHandler, false);
        document.addEventListener('mozfullscreenchange', this.fullScreenHandler, false);
        document.addEventListener('MSFullscreenChange', this.fullScreenHandler, false);
        document.addEventListener('webkitfullscreenchange', this.fullScreenHandler, false);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if ((!prevProps.project && this.props.project) ||
            (prevProps.project && this.props.project && prevProps.project.id !== this.props.project.id)
        ) {
            this.props.dispatch(getProjectOrderPcbInfo(this.props.project.id))
        }
    }

    componentWillUnmount() {
        if( this.wapperTimer ) {
            clearInterval( this.wapperTimer );
        }
    }

    changeWapperSpeed = (e) => {
        this.setState({wapperSpeed: e.target.value});

        if( this.wapperTimer ) {
            clearInterval( this.wapperTimer );
        }

        this.wapperTimer = setInterval((dispatch) => {

            dispatch(wapperProjectOrderPcbInfo())

        }, (2000 / 300) * (100 - this.state.wapperSpeed), this.props.dispatch)
    }

    toggleWapper() {

        if( !this.state.wapper ) {
            this.wapperTimer = setInterval((dispatch) => {

                dispatch(wapperProjectOrderPcbInfo())

            }, (2000 / 300) * (100 - this.state.wapperSpeed), this.props.dispatch)
        }
        else {
            clearInterval( this.wapperTimer );
        }

        this.setState({wapper: !this.state.wapper})
    }

    onSpaceBar(key, event, handle) {
        this.props.dispatch(wapperProjectOrderPcbInfo())
        event.preventDefault();
    }

    onSetMode(key, event, handle) {

        switch (key) {
            case '1':
                this.props.dispatch(wapperProjectOrderPcbMode('1'));
                break;
            case '2':
                this.props.dispatch(wapperProjectOrderPcbMode('2'));
                break;
            case '3':
                this.props.dispatch(wapperProjectOrderPcbMode('3'));
                break;
            default:
                break;
        }

        event.preventDefault();
    }

    onClearMode() {
        this.props.dispatch(wapperProjectOrderPcbMode(''))
    }

    setFullScreen(value) {
        fullscreen(value);
    }

    fullScreenHandler = () => {

        var isInFullScreen = (document.fullscreenElement && document.fullscreenElement !== null) ||
            (document.webkitFullscreenElement && document.webkitFullscreenElement !== null) ||
            (document.mozFullScreenElement && document.mozFullScreenElement !== null) ||
            (document.msFullscreenElement && document.msFullscreenElement !== null);

        this.setState({fullscreen: isInFullScreen})
    }

    renderTopBottomButton() {
        const style= {
            width: 12,
            heigth: 12,
            marginTop: -2,
            marginBottom: -2,
            opacity: .7,
            marginLeft: -8,
            marginRight: 6
        }
        return <Popup trigger={ <Button
            title='flip' size='tiny'
            onClick={() => this.props.dispatch(toggleProjectOrderPcbTopBottom())}
        >
            {this.props.showTop ? <img alt="front" src={"/images/pigfront.png"} style={style}/> :
                <img alt="back" src={"/images/pigbehind.png"} style={style}/>}
            {this.props.showTop ? "Top" : "Bottom"}
        </Button>} content={<Translate value='protofoto.flip.hint'/>} position='bottom center' />
    }

    renderFullscreenButton() {

        return <ButtonTabMenuFullWidth primary={this.state.fullscreen} onClick={() => this.setFullScreen(!this.state.fullscreen)}/>

    }

    handlePointChange = (id, value, back) => {
        this.props.dispatch(updateProjectPcbPictireFid(value, id, back))
        this.props.dispatch(saveProjectPcbPictireFid(value, id, back))
    }

    renderMenu = (isAdmin, project) => {
        if( isAdmin ) {

            let activeIndex= this.props.pcbs.findIndex(p => p.selected);
            activeIndex= activeIndex !== -1 ? activeIndex : 0;

            let currentPcb= this.props.pcbs[activeIndex];

            return <div>

                {currentPcb && <ProjectStepFotoItem id={currentPcb.id} project={project} isAdmin={true} onSave={() => {

                    this.props.pcbs.forEach((pcb) => {
                        this.props.dispatch(saveProjectOrderPcbInfo(pcb, project.id))
                    })

                }}/>}

                <Hotkeys keyName="space" onKeyDown={this.onSpaceBar.bind(this)}/>
                <Hotkeys keyName="1" onKeyDown={this.onSetMode.bind(this)} onKeyUp={this.onClearMode.bind(this)}/>
                <Hotkeys keyName="2" onKeyDown={this.onSetMode.bind(this)} onKeyUp={this.onClearMode.bind(this)}/>
                <Hotkeys keyName="3" onKeyDown={this.onSetMode.bind(this)} onKeyUp={this.onClearMode.bind(this)}/>

                <Button size='tiny' icon labelPosition='left'
                        onClick={() => this.props.dispatch(showUploadPcbsModal(true))} >
                    <Icon name='upload'/>
                    Uploaden
                </Button>

                {this.renderTopBottomButton()}

                <Popup trigger={<Button size='tiny' onClick={() => this.setState({confirmMail: true})} icon labelPosition='left'>
                    <Icon name='send'/>
                    Klant mailen
                </Button>}
                       content={<Translate value='protofoto.sendmail.hint'/>} position='bottom center' />

                <Modal
                    open={this.state.confirmMail}
                    header='Bericht naar de klant versturen'
                    content={<Modal.Content>Versturen?<br/>Link naar pagina: <Link to={this.props.pageName}>{this.props.pageName}</Link></Modal.Content> }
                    actions={[
                        {content: 'Sluiten', onClick: () => this.setState({confirmMail: false})},
                        { key: 'send', content: 'Versturen', loading: this.props.sendProtoFotoBusy,
                            positive: true, disabled: this.props.sendProtoFotoBusy, onClick: () => {
                            let pageName= this.props.pageName;
                            this.props.dispatch(sendProtoFotoMail(project.id, pageName, () => this.setState({confirmMail: false})))
                        }}
                    ]}
                />

                {/*<Button icon size='tiny' onClick={() => this.props.dispatch(saveProjectOrderPcbInfo({number: "", remarks: "", selected: true}, project.id))}>
                    <Icon name='add' />
                </Button>*/}

                <Popup hoverable trigger={
                    <Button icon labelPosition='left' size='tiny' onClick={() => this.toggleWapper()} primary={this.state.wapper}>
                        <Icon name='sign language' />
                        Wapperen
                    </Button>
                } content={
                    this.state.wapper ?
                        <input
                            type='range'
                            min='0'
                            max='96'
                            value={this.state.wapperSpeed}
                            onChange={this.changeWapperSpeed}
                        />
                        : 'Start wapper test'
                } position='bottom center'/>
                {this.state.fullscreen ?
                    <input
                        type='range'
                        min='0'
                        max='96'
                        value={this.state.wapperSpeed}
                        onChange={this.changeWapperSpeed}
                    /> :
                    null}


                {this.renderFullscreenButton()}

                {/*<Popup trigger={ <Button
                    title="hide lolly's" icon size='tiny'
                    onClick={(e) => {
                        this.setState({hidelollys: !this.state.hidelollys})
                    }}
                >
                    <Icon name={'map pin'} color={this.state.hidelollys ? '' : 'red'}/>
                </Button>} content={<Translate value='protofoto.hidelollys.hint'/>} position='bottom center' />*/}


            </div>
        } else {
            return <div>
                <Hotkeys keyName="space" onKeyDown={this.onSpaceBar.bind(this)}/>
                {this.renderTopBottomButton()}
                {this.renderFullscreenButton()}
            </div>
        }
    }

    render() {
        let {project}= this.props;

        if( !project ) {
            return <div/>
        }

        let mode= this.props.pcbWapperMode;

        const m_isAdmin= isEmployee(this.props.currentUser);

        return <div style={this.state.fullscreen ? fullscreenstyle : {}}>
            <TabHeader
                panes={[...this.props.pcbs.map((pcb, i) => ({
                    text: <div>
                        {m_isAdmin && <Checkbox label='' checked={!pcb.skip} onClick={() => this.props.dispatch(changeProjectOrderPcbInfo(pcb.id, 'skip', !pcb.skip))}/>}
                        {!pcb.number && !pcb.name && 'PCB zonder nummer'}
                        {pcb.number}
                        {pcb.type !== 'NA' ? ' (' + pcb.type + ')' : ''}
                        <Progressbar progress={pcb.progress ? pcb.progress : 100} color={pcb.reference ? '#f2711c' : null}/>
                    </div>,
                    active: pcb.selected,
                    ...pcb
                }))]}
                onChange={(value) => this.props.dispatch(changeProjectOrderPcbInfo(value.id, 'selected', true))}
                onAdd={m_isAdmin ? () => this.props.dispatch(saveProjectOrderPcbInfo({number: "", remarks: "", selected: true}, project.id)) : null}
                additionalItems={m_isAdmin ? [<Dropdown style={{marginTop: 4}} icon={null} trigger={<Button basic circular icon='caret down' size='tiny' style={{boxShadow: 'unset', backGround: '#f5f5f5'}}/>}>
                    <Dropdown.Menu>
                        <Dropdown.Item text='Alles selecteren' onClick={() => {
                            this.props.pcbs.forEach(pcb => this.props.dispatch(changeProjectOrderPcbInfo(pcb.id, 'skip', false)))
                        }}/>
                        <Dropdown.Item text='Alles deselecteren' onClick={() => {
                            this.props.pcbs.forEach(pcb => this.props.dispatch(changeProjectOrderPcbInfo(pcb.id, 'skip', true)))
                        }}/>
                    </Dropdown.Menu>
                </Dropdown>] : null}
            />
            <TabMenu>
                {this.renderMenu(m_isAdmin, project)}
            </TabMenu>
            <TabPane style={{padding: 0}}>
                <div className="projectstepfoto" style={{
                    position: 'relative',
                    width: '100%',
                    height: this.state.fullscreen ? 'calc(100vh - 140px)' : '60vh',
                    cursor: mode ? 'crosshair' : this.state.mouseDown ? 'grabbing' : 'grab',
                }} onMouseDown={() => {
                    this.setState({mouseDown: true})
                }} onMouseUp={() => {
                    this.setState({mouseDown: false})
                }}>

                    <div style={{
                        marginTop: 100,
                        position: 'absolute',
                        textAlign: 'center',
                        width: '100%'
                    }}>

                    {this.props.pcbs.length === 0 ? <Translate value={'project_nophotos'}/> :
                    !this.props.pcbs.filter(p => !p.skip).length ? <Translate value={'project_selectphoto'}/> :
                    this.props.pcbs.filter(p => p.progress < 100).length ?
                    <Translate value={'moment_please'}/> : ''}

                    </div>

                    <MapInteractionCSS
                        showControls={true}
                        defaultScale={.3}
                        disablePan={mode !== ''}
                        scale={this.state.scale}
                        translation={this.state.translation}
                        onChange={({ scale, translation }) => {
                            this.setState({scale: scale, translation})
                        }}
                        scaleRate={0.006}
                       /* onChange={({scale, translation}) => {
                            this.setState({scale: scale, translation: translation});
                        }}*/
                    >

                        {this.props.pcbs.sort((a,b) => {

                            if( a.reference ) {
                                return -1;
                            } else if( b.reference ) {
                                return 1;
                            } else {
                                return 0;
                            }

                        }).filter(pcb => !m_isAdmin || !pcb.skip).map(pcb => {

                            if( this.props.showTop && pcb.front && pcb.frontFileName && pcb.frontFileName.toLowerCase().endsWith('.svg') ) {
                                return <img alt="" width={6000} src={"/api/project/getAttachment?id=" + (pcb.front)}/>
                            }
                            else if( this.props.showTop && pcb.back && pcb.backFileName && pcb.backFileName.toLowerCase().endsWith('.svg') ) {
                                return <img alt="" width={6000} src={"/api/project/getAttachment?id=" + (pcb.back)}/>
                            }

                            return <TranslatedImage key={this.props.showTop ? pcb.front : pcb.back} src={this.props.showTop ? pcb.front : pcb.back}
                                                    zIndex={pcb.selected ? 2 : pcb.reference ? -1 : -2} scale={this.state.scale}
                                                    translation={this.state.translation} modify={mode} opacity={mode ? .6 : 1}
                                                    points={this.props.showTop ? pcb.pointsFront : pcb.pointsBack} hidePoints={!m_isAdmin || this.state.hidelollys} pcbId={pcb.id}
                                                    onPointChange={(value) => this.handlePointChange(pcb.id, value, !this.props.showTop)}
                            />
                        })}

                    </MapInteractionCSS>
                </div>
            </TabPane>

            <ProjectStepFotoUpload projectOrderId={project.id} onClose={() => {
                this.props.dispatch(getProjectOrderPcbInfo(this.props.project.id))
            }}/>
        </div>;
    }
}

ProtoFotoView.propTypes = {
    pageName: PropTypes.string
};

ProtoFotoView.defaultProps = {
    pageName: ''
};

const mapStateToProps = (state) => {
    return {
        currentUser: state.main.currentUser,
        lang: state.main.lang,
        pcbs: state.project.pcbs,
        project: state.project.project,
        pcbWapperMode: state.project.pcbWapperMode,
        showTop: state.project.showTop,
    }
}

export default connect(mapStateToProps)(ProtoFotoView)