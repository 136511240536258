import React, { Component } from 'react';
import './App.css';
import PropTypes from "prop-types";
import {Link, withRouter} from "react-router-dom";
import {formatDateTime, isEmployee, ordinal_suffix_of} from "./actions/functions";
import {projectPhases} from "./Project";
import {getText} from "./Translate";
import ProjectCardStatusBarItem from "./ProjectCardStatusBarItem";
import connect from "react-redux/es/connect/connect";
import {Icon} from "semantic-ui-react";
import {fetchProjectStats} from "./actions";

var moment = require('moment');
moment.locale('nl');

class StatusBarTab extends Component {
    render() {
        let activeClass = this.props.active ? 'activeTab' : '';
        let loadingClass = this.props.loading ? 'statusBarTabLoading' : '';
        return(
            <div className={'statusBarTab ' + this.props.statusColor + ' ' + activeClass + ' ' + loadingClass}>
                <div className={'body3'}>{this.props.statusHeader}</div>
                <div className = {'captionContainer'}>
                    <Icon name={this.props.icon} style={{marginRight: 5}}/>
                    <p className={'body4'}>{this.props.statusCaption}</p>
                </div>
            </div>
        )
    }
}

StatusBarTab.propTypes = {
    statusColor: PropTypes.string,
    statusCaption: PropTypes.string,
    active: PropTypes.bool,
    loading: PropTypes.bool
};

StatusBarTab.defaultProps = {
    statusColor: '',
    statusCaption: '',
    active: false,
    loading: false
}

class ProjectCardStatusBar extends Component{

    componentDidMount() {
        this.handleStatusClick = this.handleStatusClick.bind(this);
    }

    handleStatusClick= (projectId, name) => {
        this.props.history.push('/project/' + projectId + '/' + name)
    }

    updateStats = () => {
        this.props.dispatch(fetchProjectStats(this.props.project.id))
    }

    render() {
        let projectId= this.props.project.id;
        let project= this.props.project;
        let statusBarClass = (this.props.statusBarClickable) ? ' tabbedClass' : '';
        let activePage = this.props.activePage;

        let admin= isEmployee(this.props.currentUser)

        return(
            <section className={'statusBar' + statusBarClass}>
                {projectPhases.map((pp) => {

                    let status= project[pp.name];

                    if( pp.fixedStatus ) {
                        status = pp.fixedStatus;
                    }

                    let caption= pp.name === 'order' && project.orderDate ? formatDateTime( project.orderDate ) : '';
                    let color= getText(this.props, 'pp.' + pp.name + '.' + status + getValueFromProject(this.props.project, pp.nameRepair) + '.color');
                    let counter = 0;
                    let counterInternal = 0;
                    let sampleCounter = '1st';
                    let sampleCounterWithoutSuffix = '1';

                    if( pp.name === 'request' ) {
                        caption = formatDateTime(project.aanvraagDate)
                    }

                    if( project[pp.name+'Date'] ) {
                        caption= formatDateTime( project[pp.name+'Date'] )
                        if( caption.endsWith('@00:00') ) {
                            caption = caption.replace('@00:00', '')
                        }
                    }

                    if( status === 'onhold' && project.onHold !== null) {
                        caption = formatDateTime(project.onHold);
                        status= 'inactive';
                        color = 'red';
                    }

                    if( pp.name === 'analysis' && project.orderDate && status === 'checked') {
                        caption = formatDateTime(project.orderDate)
                    }

                    if(pp.name === 'foto' && project.pcbs && project.pcbs.length) {
                        caption= project.pcbs.filter(p => !p.deleted).length
                    }

                    if( status === 'cancelled' ) {
                        caption = ''
                    }

                    if( !admin ) {
                        if( pp.name === 'buy' ) {
                            caption = '';
                        }
                        if( pp.name === 'checkin' ) {
                            caption = '';
                        }
                    }

                    /*pcb stencil componenten centroid*/

                    if(admin && pp.name === 'order' && status !== 'checked' && project && project.bomStats) {
                        caption = <p className='stats-summary' onMouseEnter={() => this.updateStats()}>
                            <span className='stats-summary-done' style={{marginLeft: 4}}>{project.bomStats.pcbApproved || '0'}</span>
                            <span>{project.bomStats.pcbNeed || '0'}</span>
                            {" | "}
                            <span className='stats-summary-done'>{project.bomStats.stencilApproved || '0'}</span>
                            <span>{project.bomStats.stencilNeed || '0'}</span>
                            {" | "}
                            <span className='stats-summary-done'>{project.bomStats.bomLinesDone || '0'}</span>
                            <span>{project.bomStats.bomLines || '0'}</span>
                            {" | "}
                            <span className='stats-summary-done'>{project.bomStats.hasCentroid ? 'C' : '-'}</span>
                            {project.bomStats.fetching ? <Icon loading name='spinner'/> : ''}
                        </p>
                    }

                    if(admin && pp.name === 'buy' && project && project.bomStats) {

                        //and after the buyDate!
                        if( project.buyDate && (project.bomStats.stencilOrdered === null || project.bomStats.stencilOrdered === 0) && moment(project.buyDate).isAfter(new moment()) ) {
                            color = 'yellow';
                        }

                        caption = <p className='stats-summary' onMouseEnter={() => this.updateStats()}>
                            <span className='stats-summary-done' style={{marginLeft: 4}}>{project.bomStats.pcbOrdered || '0'}</span>
                            <span>{project.bomStats.pcbNeed || '0'}</span>
                            {" | "}
                            <span className='stats-summary-done'>{project.bomStats.stencilOrdered || '0'}</span>
                            <span>{project.bomStats.stencilNeed || '0'}</span>
                            {" | "}
                            <span className='stats-summary-done'>{project.bomStats.checkinOrdered || '0'}</span>
                            <span>{project.bomStats.bomLines || '0'}</span>
                            {" | "}
                            <span className='stats-summary-done'>{project.bomStats.hasCentroid ? 'C' : '-'}</span>
                            {project.bomStats.fetching ? <Icon loading name='spinner'/> : ''}
                        </p>
                    }

                    if(admin && pp.name === 'checkin' && project && project.bomStats) {
                        caption = <p className='stats-summary' onMouseEnter={() => this.updateStats()}>
                            <span className='stats-summary-done' style={{marginLeft: 4}}>{project.bomStats.pcbReceived || '0'}</span>
                            <span>{project.bomStats.pcbNeed || '0'}</span>
                            {" | "}
                            <span className='stats-summary-done'>{project.bomStats.stencilReceived || '0'}</span>
                            <span>{project.bomStats.stencilNeed || '0'}</span>
                            {" | "}
                            <span className='stats-summary-done'>{project.bomStats.checkinDone || '0'}</span>
                            <span>{project.bomStats.bomLines || '0'}</span>
                            {" | "}
                            <span className='stats-summary-done'>{project.bomStats.hasCentroid ? 'C' : '-'}</span>
                            {project.bomStats.fetching ? <Icon loading name='spinner'/> : ''}
                        </p>
                    }

                    if( project.releaseProductions ) {
                        let releasedDates = project.releaseProductions.map(rp => rp.lines).flat()
                            .filter(l => l.released != null && !l.releasedForProduction).map(l => l.released)

                        let unique = [...new Set(releasedDates)];

                        if( unique.length > 0 ) {
                            sampleCounter = ordinal_suffix_of(unique.length + 1)
                            sampleCounterWithoutSuffix = unique.length + 1
                        }
                    }

                    /*if (pp.name === 'analysis' && status === 'active') {
                        if (moment().diff(moment(project.aanvraagDate), 'hours') > 23) {
                            color = 'yellow'
                        }
                    }*/
                    if (pp.name === 'analysis' ) {
                        if( project.bomStats && project.bomStats.markedLines) {
                            counter = project.bomStats.markedLines;
                        }

                        if( !admin && status === 'ready' ) {
                            counter = counter + 1;
                        }
                    }

                    /*if (pp.name === 'order' && status === 'ready') {
                        counter = 1
                    }*/

                    if (!admin && (pp.name === 'sample' || pp.name === 'sample2' || pp.name === 'sample3') && (status === 'received' || status === 'shipped' )) {
                        counter = 1
                    }

                    if( admin ) {

                        //if (pp.name === 'sample' && project && project.releaseProductions && project.releaseProductions.length > 0 && project.releaseProductions[0].countNotDone ) {
                        //    counter = project.releaseProductions[0].countNotDone;
                        //}
                        if (pp.name === 'sample' && project && project.releaseProductions && project.releaseProductions.length > 0 ) {
                            caption = caption + ' | LoC ' + project.releaseProductions[0].count;
                        } else if (pp.name === 'sample') {
                            caption = caption + ' | LoC 0';
                        }

                        /*if (pp.name === 'sample' && project && project.releaseProductions ) {
                            counterInternal = project.releaseProductions.filter(rp => rp.checkedDate == null && rp.id && rp.date).length;
                        }

                        if (pp.name === 'sample' && project && project.releaseProductions) {

                            project.releaseProductions.forEach(rp => {
                                if( rp.checkedDate == null && rp.id ) {
                                    if (moment().diff(moment(rp.date), 'hours') > 23) {
                                        color = 'yellow'
                                    }
                                }
                            })


                        }*/
                    }

                    if( admin ) {
                        if (pp.name === 'analysis' && status === 'ready') {
                            if (moment().diff(moment(project.analysisDate), 'hours') > 23) {
                                color = 'yellow'
                            }
                        }
                    }

                    return <Link to={this.props.dummy ? '/' : '/project/' + projectId + '/' + pp.name} key={pp.name}>
                        <StatusBarTab
                            active={pp.name === activePage}
                            statusColor={'statusBarTab-' + color}
                            statusHeader={<ProjectCardStatusBarItem
                                project={this.props.project}
                                phaseName={pp.name}
                                phaseName2={pp.nameRepair}
                                counter={counter}
                                counterInternal={counterInternal}
                                sampleCounter={sampleCounter}
                                sampleCounterWithoutSuffix={sampleCounterWithoutSuffix}
                                selectedOption={'pp.' + pp.name + '.' + status + getValueFromProject(this.props.project, pp.nameRepair)}
                                onClick={() => this.handleStatusClick(projectId, pp.name)}
                                options={pp.statusOptions}
                            />}
                            statusCaption={caption}
                            name={pp.name}
                            loading={this.props.loading}
                            icon = {pp.icon}
                        />
                    </Link>
                })}
            </section>
        )
    }
}

export function getValueFromProject(project, name) {
    if( project && name ) {
        const val = project[name]
        if( val && val !== 'null' ) {
            return val
        }
        return ''
    }
    return ''
}

ProjectCardStatusBar.propTypes = {
    project: PropTypes.object,
    statusBarClickable: PropTypes.bool,
    status: PropTypes.string,
    statusName: PropTypes.string,
    activePage: PropTypes.string,
    loading: PropTypes.bool,
    dummy: PropTypes.bool,
};

ProjectCardStatusBar.defaultProps = {
    project: {},
    statusBarClickable: false,
    status: '',
    statusName: '',
    activePage: null,
    loading: false,
    dummy: false,
};

const mapStateToProps = (state) => {
    return {
        lang: state.main.lang,
        currentLang: state.main.currentLang,
        currentUser: state.main.currentUser,
    }
}

export default connect(mapStateToProps)(withRouter(ProjectCardStatusBar))