import React from 'react';
import PropTypes from 'prop-types';
import {checkBomLine} from "../actions";
import {connect} from "react-redux";
import Spinner from "./Spinner";
import {Popup} from "semantic-ui-react";
import Highlighter from "react-highlight-words";

const width = 240

function equalsIgnoreCase(text, other) {
    return text.localeCompare(other, undefined, { sensitivity: 'base' }) === 0;
}

function getSpecsForRow(value, matches, notExactMatches, name) {
    let values = [
        ...matches.filter(m => m.op && m.op.specification && m.op.specification[name]).map(m => m.op.specification[name]),
        ...notExactMatches.filter(m => m.op && m.op.specification && m.op.specification[name]).map(m => m.op.specification[name])
    ]

    if( value && value.specification && value.specification[name] ) {
        values = [value.specification[name], ...values]
    }

    //remove empty values from list
    values = values.filter(v => v != null && v !== '' && v !== 'null' && v !== 'nullW' && v !== 'nullV')

    return values;
}
export function getHighlightedSpecs(r) {

    const allEqual = arr => arr.every( v => v === arr[0] )

    let matches = r.todos.sort((a,b) => {
        if( a.opcode === b.opcode ) {
            return 0;
        } else if (a.opcode === "MPN") {
            return -1;
        }
        return 1;
    }).map(t => {
        let op;

        switch (t.opcode ) {
            case "MPN":
                op = r.orderparts.find(v => equalsIgnoreCase(v.mpn,t.value))
                break;
            case "SKU":
                op = r.orderparts.find(v => {
                    return v.suppliers.find(s => equalsIgnoreCase(s.sku,t.value))
                })
                break;
        }
        return {op, t}
    })

    let notExactMatches = r.orderparts.filter(op => {
        return !matches.find(m => m.op && m.op.id === op.id)
    })

    let highlight1 = []
    let highlight2 = []
    let highlight3 = []
    let highlight4 = []
    let highlight5 = []
    let highlight6 = []
    let searchWords = []

    if (r.isCapacitor || r.isResistor) {
        highlight1 = getSpecsForRow(r, matches, notExactMatches, 'displayValueOnlyValue');
        highlight2 = getSpecsForRow(r, matches, notExactMatches, 'packageOutline');
        highlight3 = getSpecsForRow(r, matches, notExactMatches, 'voltageRatingDisplayValue');
        highlight4 = getSpecsForRow(r, matches, notExactMatches, 'dielectric');
        highlight5 = getSpecsForRow(r, matches, notExactMatches, 'toleranceDisplayValue');
        highlight6 = getSpecsForRow(r, matches, notExactMatches, 'powerRatingDisplayValue');
    }

    if( !allEqual(highlight1) ) {
        searchWords = highlight1
    }
    if( !allEqual(highlight2) ) {
        searchWords = [...searchWords, ...highlight2]
    }
    if( !allEqual(highlight3) ) {
        searchWords = [...searchWords, ...highlight3]
    }
    if( !allEqual(highlight4) ) {
        searchWords = [...searchWords, ...highlight4]
    }
    if( !allEqual(highlight5) ) {
        searchWords = [...searchWords, ...highlight5]
    }
    if( !allEqual(highlight6) ) {
        searchWords = [...searchWords, ...highlight6]
    }

    return searchWords
}

class BomValidationNew extends React.Component {

    renderMpn(t, op, searchWords) {

        let spec = ''

        if( (this.props.value.isCapacitor || this.props.value.isResistor) && op && op.specification ) {
            spec = op.specification.displayValue;
        } else if (op) {
            spec = op.shortDescription
        }

        spec = <Highlighter highlightClassName="Highlight" searchWords={searchWords}
                            textToHighlight={spec}/>

        return <div style={{width: width, display: "inline-block"}}>

            <Popup content={spec} trigger={<div className="btitem">{spec}</div>}/>
            {op ? <div className={"btitem " + (t ? '' : 'err_blue')} style={{fontWeight: "bold", color: op ? null : 'red'}}>{op.mpn }</div> :
                  <Popup content='Niet gevonden' trigger={
                    <div className="btitem err" style={{fontWeight: "bold", color: op ? null : 'red'}}>{t.value}</div>
                  } />}
            <div className="btitem"></div>

        </div>
    }

    renderSku(t, op, searchWords) {
        let spec = ''

        if( (this.props.value.isCapacitor || this.props.value.isResistor) && op && op.specification ) {
            spec = op.specification.displayValue;
        } else if (op) {
            spec = op.shortDescription
        }

        spec = <Highlighter highlightClassName="Highlight" searchWords={searchWords}
                            textToHighlight={spec}/>

        return <div style={{width: width, display: "inline-block"}}>

            <Popup content={spec} trigger={<div className="btitem">{spec}</div>}/>
            <div className="btitem">{op ? op.mpn : ''}</div>
            {op ? <div className="btitem" style={{fontWeight: "bold"}}>{t.value}</div> :
                <Popup content='Niet gevonden' trigger={
                    <div className="btitem err" style={{fontWeight: "bold", color: op ? null : 'red'}}>{t.value}</div>
                } />}

        </div>
    }

    getValues(value, matches, notExactMatches, name) {
        let values = [
            ...matches.filter(m => m.op && m.op.specification && m.op.specification[name]).map(m => m.op.specification[name]),
            ...notExactMatches.filter(m => m.op && m.op.specification && m.op.specification[name]).map(m => m.op.specification[name])
        ]

        if( value && value.specification && value.specification[name] ) {
            values = [value.specification[name], ...values]
        }

        return values;
    }

    render() {

        const allEqual = arr => arr.every( v => v === arr[0] )
        const {value} = this.props;

        let hasTodos= value.todos.filter(t => !t.done).length

        let hasFailedTodos= value.todos.filter(t => t.failed).length



        //This is the list we found:
        //value.orderparts

        //This the list with todos:
        //value.todos
        //  {id: "7c472e31-927f-4440-865b-41b006ebb552", opcode: "MPN", done: true, failed: false, key: "",…}

        let renderSpec = (this.props.value.isCapacitor || this.props.value.isResistor) && value.specification && value.specification.displayValue

        let matches = value.todos.sort((a,b) => {
            if( a.opcode === b.opcode ) {
                return 0;
            } else if (a.opcode === "MPN") {
                return -1;
            }
            return 1;
        }).map(t => {
            let op;

            switch (t.opcode ) {
                case "MPN":
                    op = this.props.value.orderparts.find(v => equalsIgnoreCase(v.mpn,t.value))
                    break;
                case "SKU":
                    op = this.props.value.orderparts.find(v => {
                        return v.suppliers.find(s => equalsIgnoreCase(s.sku,t.value))
                    })
                    break;
            }
            return {op, t}
        })

        let notExactMatches = this.props.value.orderparts.filter(op => {
            return !matches.find(m => m.op && m.op.id === op.id)
        })

        let highlight1 = []
        let highlight2 = []
        let highlight3 = []
        let highlight4 = []
        let highlight5 = []
        let highlight6 = []
        let searchWords = []

        if (this.props.value.isCapacitor || this.props.value.isResistor) {
            highlight1 = this.getValues(value, matches, notExactMatches, 'displayValueOnlyValue');
            highlight2 = this.getValues(value, matches, notExactMatches, 'packageOutline');
            highlight3 = this.getValues(value, matches, notExactMatches, 'voltageRatingDisplayValue');
            highlight4 = this.getValues(value, matches, notExactMatches, 'dielectric');
            highlight5 = this.getValues(value, matches, notExactMatches, 'toleranceDisplayValue');
            highlight6 = this.getValues(value, matches, notExactMatches, 'powerRatingDisplayValue');
        }

        if( !allEqual(highlight1) ) {
            searchWords = highlight1
        }
        if( !allEqual(highlight2) ) {
            searchWords = [...searchWords, ...highlight2]
        }
        if( !allEqual(highlight3) ) {
            searchWords = [...searchWords, ...highlight3]
        }
        if( !allEqual(highlight4) ) {
            searchWords = [...searchWords, ...highlight4]
        }
        if( !allEqual(highlight5) ) {
            searchWords = [...searchWords, ...highlight5]
        }
        if( !allEqual(highlight6) ) {
            searchWords = [...searchWords, ...highlight6]
        }

        return <div>

            <div style={{width: 40, display: "inline-block"}}>
                <div className="btitem">Val</div>
                <div className="btitem">MPN</div>
                <div className="btitem">SKU</div>
            </div>

            {renderSpec && <div style={{width: width, display: "inline-block"}}>

                <div className="btitem" style={{fontWeight: "bold"}}>
                    <Highlighter highlightClassName="Highlight" searchWords={searchWords}
                                 textToHighlight={value.specification.displayValue}
                    />
                </div>
                <div className="btitem"/>
                <div className="btitem"/>

            </div>}

            {matches.map(v => {

                switch (v.t.opcode ) {
                    case "MPN":
                        return this.renderMpn(v.t, v.op, searchWords)
                    case "SKU":
                        return this.renderSku(v.t, v.op, searchWords)
                }
            })}

            {notExactMatches.map(op => {
                return this.renderMpn(null, op, searchWords)
            })}

            {(hasTodos || hasFailedTodos) ?
                <div style={{width: 80, display: "inline-block"}}>
                    <a href="#/" onClick={() => !value.checking && this.props.dispatch(checkBomLine(this.props.printId, value.id))}>
                        {value.checking ? 'een moment..' : hasFailedTodos ? 'opnieuw proberen' : 'valideren'}
                    </a>

                    {value.checking && <div style={{float: 'right'}}><Spinner autoFocus={true}/></div>}
                </div> : ''
            }

        </div>
    }
}

BomValidationNew.propTypes = {
    value: PropTypes.object,
}

BomValidationNew.defaultProps = {
    value: null
}

const mapStateToProps = (state) => {
    return {
    }
}

export default connect(mapStateToProps)(BomValidationNew)