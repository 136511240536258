import 'react-app-polyfill/ie11';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux'
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import myApp from './reducers';
import {applyMiddleware, createStore} from "redux";
import thunkMiddleware from 'redux-thunk'

import 'semantic-ui-css/semantic.min.css'
import ErrorBoundary from "./ErrorBoundary";
import Frontpage from "./frontpage/Frontpage";

let middleware = [thunkMiddleware]; // lets us dispatch() functions

if (process.env.NODE_ENV !== 'production') {
    let logger = require('redux-logger').createLogger({
        predicate: (getState, action) => {
            return !action.type.includes('WAPPER_PROJECT_PCB_DOWNLOAD_PROGRESS') && //filter redux form log to clean up log
                !action.type.includes('SHOW_STOMP_NOTIFICATION') &&
                !action.type.includes('MOHICAN_POSITION') &&
                !action.type.includes('RECEIVE_MOHICAN_POS') &&
                !action.type.includes('START_VALIDATE_LOGIN') &&
                !action.type.includes('RECEIVE_VALIDATE_LOGIN') &&
                !action.type.includes('ERROR_VALIDATE_LOGIN') &&
                !action.type.includes('HIDE_STOMP_NOTIFICATION') &&
                !action.type.includes('SET_BOM_SEARCH_REF');

        }
    }); // neat middleware that logs actions
    middleware = [...middleware, logger];
}

let store = createStore(
    myApp,
    applyMiddleware(...middleware)
);

ReactDOM.render(
    <ErrorBoundary>
        <Provider store={store}>
            <App />
        </Provider>
    </ErrorBoundary>,
    document.getElementById('root')
);

if (process.env.NODE_ENV !== "production") {
    if (module.hot) {
        module.hot.accept("./reducers", () => {
            var nextReducer = require('./reducers/index').default
            store.replaceReducer(nextReducer)
        })
    }
}

if (module.hot) {
    module.hot.accept('./App', function () {
        var NextApp = require('./App').default
        ReactDOM.render(
            <ErrorBoundary>
                <Provider store={store}>
                    <NextApp />
                </Provider>
            </ErrorBoundary>,
            document.getElementById('root'))
    })
}

//ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
