import React, { Component } from 'react';
import './App.css';
import {Button} from "semantic-ui-react";
import Translate from "./Translate";
import {Link} from "react-router-dom";
import ProjectCard from './ProjectCard';
import OnVisible from 'react-on-visible';

class Steps extends Component{

    state= {
        dummyProject: {
            bomStats: {},
            companyName: 'Company Name',
            owner: {
                firstName: 'First Name',
                tussenvoegsel: 'Infixes',
                lastName: 'Last Name'
            },
            ownerName: 'Customer Name',
            name: 'Project Name',
            reference: 'Project Reference',
            numPcbs: '',
            selectedDays: 'Days',
            deliveryDate: 'Delivery Date',
            internalName: 'Internal Name',
            price: 'Project Price',

            analysis: 'active',
            order: 'notstarted',
            buy: 'notstarted',
            checkin: 'notstarted',
            sample: 'notstarted',
            sample2: 'notstarted',
            sample3: 'notstarted',
            series: 'notstarted',
            onHold: null
        }
    }

    componentDidMount() {
        setInterval(() => {

            let p= this.state.dummyProject

            if( this.state.visible ) {

                if( p.analysis === 'active' ) {
                    p= Object.assign({}, p, {analysis: 'ready', order: 'active'})
                } else if( p.analysis === 'ready' ) {
                    p= Object.assign({}, p, {analysis: 'checked', buy: 'active'})
                } else if( p.buy === 'active' ) {
                    p= Object.assign({}, p, {buy: 'ready', checkin: 'active'})
                } else if( p.checkin === 'active' ) {
                    p= Object.assign({}, p, {checkin: 'ready', sample: 'active'})
                } else if( p.sample === 'active' ) {
                    p= Object.assign({}, p, {sample: 'checked', series: 'active', sample2: 'skip', sample3: 'skip'})
                } else if( p.series === 'active' ) {
                    p= Object.assign({}, p, {series: 'ready'})
                } else if( p.series === 'ready' ) {
                    p= Object.assign({}, p, {series: 'shipped'})
                } else if( p.series === 'shipped' ) {
                    p= Object.assign({}, p, {series: 'received'})
                } else if( p.series === 'shipped' ) {
                    p= Object.assign({}, p, {series: 'checked'})
                }

                if( p ) {
                    //let newP = Object.assign({}, p, {[name]: value})
                    this.setState({dummyProject: p})
                }
            }

        }, 1000)
    }

    render() {

        return(
            <div>
                <div className={'getPriceButton'}>
                    <Link to='/calculator' className = {'subtitle1 linkWhite'}>
                        <Button color='red' className={'subtitle1 ' + this.props.className ? this.props.className : ''}><Translate value='calculateprice'/></Button>
                    </Link>
                </div>

                <section id={'stepsContainer'} className={'noMobile'}>
                    <h2 className={'heading2'}>Steps</h2>
                    <h3 className={'heading3'}><Translate value={'steps.title'}/></h3>
                    <section id={'dummyCard'}>
                            <ProjectCard project={this.state.dummyProject} listView={false} dummy={true}/>
                        <OnVisible bounce={true} onChange={(e) => {
                            this.setState({visible: e})
                        }}/>
                    </section>
                </section>
            </div>
        )
    }
}

export default Steps