import connect from "react-redux/es/connect/connect";
import React from "react";
import {openChatModal, postChatMessage} from "../actions";
import {Button, Form, Icon, List, Modal, TextArea} from "semantic-ui-react";
import Translate from "../Translate";
import TextareaAutosize from "react-textarea-autosize";
import UploadFile from "../oldui/UploadFile";
import PropTypes from "prop-types";

class ChatNewMessageModal extends React.Component {

    state = {
        message: '',
        upload: null,
        attachments: [],
        busy: false,
        messageReceived: false
    }

    close = () => {
        if( !this.state.busy ) {
            this.props.dispatch(openChatModal(false))
            this.setState({messageReceived: false, attachments: [], message: ''})
        }
    }

    post = () => {
        const attachments = this.state.attachments.map(a => a.id)
        this.setState({busy: true})
        this.props.dispatch(postChatMessage(this.props.chatId, this.state.message, null, null, attachments, () => {
            if( this.props.showConfirm ) {
                this.setState({busy: false, messageReceived: true})
            } else {
                this.props.dispatch(openChatModal(false))
                this.setState({busy: false, attachments: [], message: ''})
            }
        }))
    }

    render() {
        const {newChatModalOpen} = this.props;
        const busy = this.state.busy;
        const empty = !this.state.message;

        if( newChatModalOpen ) {
            return <>
                {this.state.messageReceived ? <Modal open={true} closeIcon onClose={this.close}>
                    <Modal.Header><Translate value={'post_new_chat'}/></Modal.Header>
                    <Modal.Content>
                        <Modal.Description>
                            <Translate value={'post_new_chat_received'}/>
                        </Modal.Description>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button disabled={busy} onClick={this.close}><Translate value='close'/></Button>
                    </Modal.Actions>
                </Modal> : null}

                <Modal open={true} closeIcon onClose={this.close} dimmer='blurring'>
                    <Modal.Header><Translate value={'post_new_chat'}/></Modal.Header>
                    <Modal.Content>
                        <Modal.Description>
                            <Form>
                                <Form.TextArea name='message' rows={4} autoHeight label={<Translate value='chat_message'/>}
                                               value={this.state.message} autoFocus
                                               onChange={(e) => this.setState({message: e.target.value})}/>
                            </Form>

                            <List>
                                {this.state.attachments.map(a => <List.Item>
                                    <List.Icon name='file'/>
                                    <List.Content>
                                        <List.Header>{a.name}</List.Header>
                                        <List.Description>{a.size}</List.Description>
                                    </List.Content>
                                </List.Item>)}
                            </List>

                            <UploadFile icon={false} text={<Translate value='bestand_uploaden'/>}
                                        style={{marginTop: '8px'}}
                                        url={'/api/chat/upload?id='+this.props.chatId} color='blue'
                                        onResult={(body) => {
                                            this.setState({attachments: [...this.state.attachments, ...body]})
                                        }}
                            />

                        </Modal.Description>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button disabled={busy} onClick={this.close}><Translate value='close'/></Button>
                        <Button loading={busy} disabled={empty || busy} onClick={this.post} primary><Translate value={'post_new_chat'}/></Button>
                    </Modal.Actions>
                </Modal>
            </>
        } else {
            return null;
        }
    }
}


ChatNewMessageModal.propTypes = {
    chatId: PropTypes.number.isRequired,
    reverseOrder: PropTypes.bool,
    showConfirm: PropTypes.bool,
};

ChatNewMessageModal.defaultProps = {
    chatId: null,
    reverseOrder: false,
    showConfirm: false,
};

const mapStateToProps = (state) => {
    return {
        newChatModalOpen: state.chat.newChatModalOpen
    }
}

export default connect(mapStateToProps)(ChatNewMessageModal)