import React, {Component} from "react";
import {changeProjectValue, getChatForProjectOrder, saveProject} from "./actions";
import connect from "react-redux/es/connect/connect";
import {formatDateTime} from "./actions/functions";
import TextareaAutosize from "react-textarea-autosize";
import {Button, Icon, Popup, Segment} from "semantic-ui-react";
import ChatView from "./chat/ChatView";
import Translate from "./Translate";

class ProjectAdminMessages extends Component {

    componentDidMount() {
        this.props.dispatch(getChatForProjectOrder(this.props.project.id))
    }

    handleFocus = () => {

        let project= this.props.project;

        if( !project.keepIsDirty ) {
            let dateAndUser= formatDateTime(new Date()) + ' ' + this.props.currentUser.firstName + ': ';

            let newKeep= project.keep ? (project.keep + '\n' + dateAndUser) : dateAndUser;

            this.props.dispatch(changeProjectValue('keep', newKeep))

            if( !project.keepIsDirty ) {
                this.props.dispatch(changeProjectValue('keepInitialValue', project.keep))
                this.props.dispatch(changeProjectValue('keepIsDirty', true))
            }
        }
    }

    handleChangeKeep = (e) => {

        let project= this.props.project;

        this.props.dispatch(changeProjectValue('keep', e.target.value))

        if( !project.keepIsDirty ) {
            this.props.dispatch(changeProjectValue('keepInitialValue', project.keep))
            this.props.dispatch(changeProjectValue('keepIsDirty', true))
        }
    }

    handleKeyDownKeep = (e) => {
        if(e.keyCode === 27) { //escape
            this.props.dispatch(changeProjectValue('keep', this.props.project.keepInitialValue))
        }
    }

    handelSave = () => {
        this.props.dispatch(saveProject(this.props.project))
    }

    onItemClick = (name) => {
        if( this.props.onOpenChangeModal != null ) {
            this.props.onOpenChangeModal(name);
        }
    }

    render() {

        let {project} = this.props;

        return (
            <Segment>
                <div id='adminMessages' style={{overflow: 'auto'}}>

                    <div style={{position: 'inline', float: 'right'}}>

                        <div style={{display: 'inline', marginTop: 16}}>
                            <button className='link-button' onClick={() => this.onItemClick('name')} >{project.name ? project.name : '???'}</button>
                            {" | "}
                            <button className='link-button' onClick={() => this.onItemClick('reference')} >{project.reference ? project.reference : '...'}</button>
                            {" | "}
                            <button className='link-button' onClick={() => this.onItemClick('internalName')} >{project.internalName ? project.internalName : 'Interne naam'}</button>
                            {"  "}
                            <input style={{position: 'fixed', top: -100}} id="input" type="text"
                                   value={(project.name ? project.name : '???') + " | " + (project.reference ? project.reference : '...') + " | " + (project.internalName ? project.internalName : 'Interne naam')}/>
                            <Popup trigger={
                                <Icon link name='clipboard outline' onClick={() => {
                                    const copyText = document.querySelector("#input");
                                    copyText.select();
                                    document.execCommand("Copy");
                                }} />
                            } position='bottom center' content='Kopiëren naar clipboard'/>
                        </div>

                        <Button primary={project.dirty} disabled={this.props.savingProject} onClick={this.handelSave}><Translate value='save_project'/></Button>

                    </div>

                    <b>Toelichtingen klant</b>
                    <div style={{whiteSpace: 'pre-wrap', minHeight: '1em'}}>{this.props.project.calculatorDetails.remark}</div>

                    <b><br/></b>
                    <div style={{whiteSpace: 'pre-wrap'}}>{project.keep}</div>

                    <ChatView showInput={true} isPageView={false} chatId={this.props.project.chat} reverseOrder={true} scroll={true} mention={this.props.project.owner}/>


                </div>
            </Segment>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        lang: state.main.lang,
        currentLang: state.main.currentLang,
        currentUser: state.main.currentUser,
        project: state.project.project,
        savingProject: state.project.savingProject,
    }
}

export default connect(mapStateToProps)(ProjectAdminMessages)