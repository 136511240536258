import React, {Component} from 'react';
import PropTypes from "prop-types";
import {
    Button, Confirm, Icon, List,
    Table
} from "semantic-ui-react";

import {
    formatDate
} from "../actions/functions";

import 'moment/locale/nl.js'
import connect from "react-redux/es/connect/connect";
import {deletePackingSlip, openConfirmDeletePackingSlip} from "../actions";
import Translate from "../Translate";

var moment = require('moment');
moment.locale('nl');

class ProjectEventTable extends Component {

    constructor(props) {
        super(props);

        this.state= {
        }
    }

    filterList(list, onlyInvoice, onlyPackingSlip) {
        return list.filter((e) => {

            let isInvoice= !!e.cost;

            if( onlyInvoice ) {
                return isInvoice;
            }

            if( onlyPackingSlip ) {
                return !isInvoice;
            }

            return false;

        });
    }

    render() {

        let list= this.props.list;

        if( this.props.onlyInvoice || this.props.onlyPackingSlip ) {
            list= this.filterList(list, this.props.onlyInvoice, this.props.onlyPackingSlip)
        }

        return <div>
            <Table compact basic>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell><Translate value={"dashboard_project"}/></Table.HeaderCell>
                        <Table.HeaderCell><Translate value="dashboard_aangemaakt_door"/> </Table.HeaderCell>
                        <Table.HeaderCell><Translate value="dashboard_type"/></Table.HeaderCell>
                        <Table.HeaderCell><Translate value="dashboard_invoice_nummer"/> </Table.HeaderCell>
                        <Table.HeaderCell>PCB #</Table.HeaderCell>
                        <Table.HeaderCell><Translate value='dashboard_datum'/></Table.HeaderCell>
                        <Table.HeaderCell><Translate value='dashboard_aantal'/></Table.HeaderCell>
                        <Table.HeaderCell><Translate value="dashboard_bedrag"/></Table.HeaderCell>
                        <Table.HeaderCell collapsing></Table.HeaderCell>
                        <Table.HeaderCell collapsing></Table.HeaderCell>
                    </Table.Row>
                </Table.Header>

                <Table.Body>
                    {list.map(e => (
                        <Table.Row key={e.id}>
                            <Table.Cell><a href={"/project.html?id="+e.projectOrder.id}>{e.projectOrder.name}</a></Table.Cell>
                            <Table.Cell>{e.createdBy}</Table.Cell>
                            <Table.Cell>{e.cost ? "Invoice" : "Packing Slip"}</Table.Cell>
                            <Table.Cell>{e.invoiceNumber}</Table.Cell>
                            <Table.Cell>
                                {e.lines ? <List bulleted items={e.lines.filter(p => p.pcbNumber).map(p => p.pcbNumber + ' (' + p.pcbType + ')')}/> : ''}
                            </Table.Cell>
                            <Table.Cell>{formatDate( e.date )}</Table.Cell>
                            <Table.Cell>{e.amount}</Table.Cell>
                            <Table.Cell>{e.cost}</Table.Cell>
                            <Table.Cell collapsing>
                                {!e.cost ?
                                    <Button download href={'/api/project/getPackingSlipPdf?id=' + e.id} icon='download'/>
                                    :
                                    <Button download href={'/api/project/getInvoicePdf?id=' + e.id} icon='download'/>
                                }
                            </Table.Cell>
                            <Table.Cell collapsing>
                                <Button
                                    color='red'
                                    icon
                                    onClick={() => this.props.dispatch(openConfirmDeletePackingSlip(e.id))}
                                >
                                    <Icon name='trash' />
                                </Button>

                                <Confirm
                                    dimmer={'blurring'}
                                    open={e.id === this.props.deletePackingSlipModal}
                                    onCancel={() => this.props.dispatch(openConfirmDeletePackingSlip(null))}
                                    onConfirm={() => {
                                        this.props.dispatch(deletePackingSlip(e.id, this.props.projectId))
                                    }}
                                    content={'Weet je zeker dat je deze regel wilt verwijderen?'}
                                    cancelButton='Nee'
                                    confirmButton="Ja"
                                />
                            </Table.Cell>
                        </Table.Row>
                    ))}
                </Table.Body>

            </Table>
        </div>
    }
}

ProjectEventTable.propTypes = {
    list: PropTypes.array,
    onlyInvoice: PropTypes.bool,
    onlyPackingSlip: PropTypes.bool,
};

ProjectEventTable.defaultProps = {
    list: [],
    onlyInvoice: false,
    onlyPackingSlip: false
};

const mapStateToProps = (state) => {
    return {
        lang: state.main.lang,
        deletePackingSlipModal: state.project.deletePackingSlipModal,
    }
}

export default connect(mapStateToProps)(ProjectEventTable)
