import React from 'react';
import {checkBom} from "../actions";
import {connect} from "react-redux";
import {Button} from "semantic-ui-react";

class BomValidationButton extends React.Component {
    render() {

        let validatingBom = this.props.print.bom.some(r => r.checking)

        return (
            <Button loading={validatingBom} onClick={() => this.props.dispatch(checkBom(this.props.print.id))}>
                Analyseer BOM
            </Button>
        );
    }
}

BomValidationButton.propTypes = {
}

BomValidationButton.defaultProps = {
}

const mapStateToProps = (state) => {
    return {
        print: state.bom.print
    }
}

export default connect(mapStateToProps)(BomValidationButton)