import React, { Component } from 'react';
import connect from "react-redux/es/connect/connect";
import {Button, Divider, Dropdown, Icon, Input, Segment} from "semantic-ui-react";
import PropTypes from "prop-types";

const style = {
    preview: {
        position: 'relative',
    },
    captureContainer: {
        display: 'flex',
        position: 'absolute',
        justifyContent: 'center',
        zIndex: 1,
        bottom: 0,
        width: '100%'
    },
    captureButton: {
        backgroundColor: '#fff',
        borderRadius: '50%',
        height: 56,
        width: 56,
        color: '#000',
        margin: 20
    },
    captureImage: {
        width: '100%',
    }
};

const defaultValues = {
    top: 100,
    left: 10,
    imageSrc: null,
    type: 'webcam',
    width: 640, height: 480,
    crosshair: {
        x: 50, y: 50
    },
    viewWidth: 40,
    viewHeight: 30,
}

class FloatingWindow extends Component {

    state= defaultValues;

    constructor(props) {
        super(props);

        window.addEventListener('mousemove', this.onMouseMove.bind(this), false);
        window.addEventListener('mouseup', this.onMouseUp.bind(this), false);

        this.handleMouseDown = this.handleMouseDown.bind(this)
    }

    componentDidMount() {
        let d = localStorage.getItem(this.props.name);

        if( d != null && d !== "null" ) {
            let val = JSON.parse(d);

            if( val.top && val.left ) {
                //fix, if outside of current window
                val.left = Math.min( Math.max(val.left, 0), window.innerWidth - 30);
                val.top =  Math.min( Math.max(val.top, 0), window.innerHeight - 30);
            }

            this.setState({
                ...val,
                isMoving: false,
                isResizing: false,
                setCrosshair: false
            })
        }
    }

    updateSettings(value) {
        let newSettings = {...this.state, ...value}

        if( this.props.name ) {
            localStorage.setItem(this.props.name, JSON.stringify(newSettings));
        }
        this.setState(value)
    }

    onMouseMove = (e) => {
        //console.log("Resizer.onMouseMove " + e.clientX);
        if( this.state.isMoving ) {

            const newLeft = Math.min( Math.max(this.state.startLeft + (e.clientX - this.state.startX), 0), window.innerWidth - 30);
            const newTop =  Math.min( Math.max(this.state.startTop + (e.clientY - this.state.startY), 0), window.innerHeight - 30);

            //console.log('handleMouseMove ' + newWidth)

            this.updateSettings({
                left: newLeft,
                top: newTop
            });

            //this.props.funcResizing( this.props.id, e.clientX, e.clientY);
        } else if( this.state.isResizing ) {

            const newWidth =  this.state.startWidth + (e.clientX - this.state.startX);
            const newHeight =  this.state.startHeight + (e.clientY - this.state.startY);

            this.updateSettings({
                width: newWidth,
                height: newHeight
            });
        }
    }
    onMouseUp = (e) => {
        //console.log("Resizer.onMouseUp");

        this.setState({
            isMoving: false,
            isResizing: false
        });

    }

    handleMouseDown = (event, t) => {

        //console.log('handleMouseDown ' + event.clientX)


        this.setState({
            startX: event.clientX,
            startY: event.clientY,
            startLeft: this.state.left,
            startTop: this.state.top,
            isMoving: true,
        });

    };

    handleMouseDownResize(event, t) {
        this.setState({
            startX: event.clientX,
            startY: event.clientY,
            startWidth: this.state.width,
            startHeight: this.state.height,
            isResizing: true,
        });
    }

    handleClose() {
        if( this.props.onClose ) {
            this.props.onClose()
        }
    }

    render() {

        return <div style={{position: 'fixed', left: this.state.left, top: this.state.top, zIndex: 99}}>

            <div>
                <Segment inverted attached='top' style={{cursor: 'move', lineHeight: '12px', padding: '8px'}} onMouseDown={(e, t) => this.handleMouseDown(e, t)}>

                    {this.props.header}

                    <div style={{position: 'absolute', right: 8, top: 8, cursor: 'pointer'}} onClick={() => this.handleClose()}>
                        <Icon name='close'/>
                    </div>
                </Segment>
                <Segment attached style={{width: this.state.width, height: this.state.height, padding: this.state.type ? 0 : ''}}>

                    {this.props.children}

                </Segment>

                <div style={{display: 'inline-block',
                    position: 'absolute',
                    width: '36px',
                    bottom: '-18px',
                    height: '36px',
                    right: '-18px',
                    cursor: 'nwse-resize',
                    zIndex: 10}}
                     onMouseDown={(e, t) => this.handleMouseDownResize(e, t)}
                />
            </div>


        </div>
    }

}

FloatingWindow.propTypes = {
    name: PropTypes.string.isRequired,
    header: PropTypes.any.isRequired,
    onClose: PropTypes.func,
};

FloatingWindow.defaultProps = {
    name: null,
    header: '',
    onClose: null,
};

const mapStateToProps = (state) => {
    return {

    }
}

export default connect(mapStateToProps)(FloatingWindow)