import React, {Component} from 'react';
import {Button, Confirm, Header, Icon, Modal, Table} from "semantic-ui-react";
import AccountView from "./AccountView";
import {deleteUser, fetchUsers} from "../actions";
import connect from "react-redux/es/connect/connect";
import AccountListItem from "./AccountListItem";
import DeltaTableMenu from "../deltatable/DeltaTableMenu";
import DeltaTable from "../deltatable/DeltaTable";
import Translate from "../Translate";

class AccountList extends Component {

    state= {
        error: null,
        fetching: false,
        list: [],
        sort: {}
    }

    constructor(props) {
        super(props);

        this.handleChangeUser.bind(this);
    }

    componentDidMount() {

        this.props.dispatch(fetchUsers())

    }

    setSortValue( value, type, child ) {

        this.setState({sort: {
                value: value,
                type: type,
                child: child,
                desc: this.state.sort.value === value ? !this.state.sort.desc : true
            }});

    }

    renderSortableHeader( title, value, type, child ) {
        return <div>
            <button className="link-button" type="button" onClick={() => this.setSortValue(value, type, child)}>
                {title}{this.state.sort.value === value ? this.state.sort.desc ? ' ▲' : ' ▼' : ''}
            </button>
        </div>
    }

    handleChangeUser = (user) => {

        this.props.dispatch(fetchUsers())

    }

    render() {

        let list= this.props.users;

        if( this.state.sort.value != null ) {

            list= list.sort((a, b) => {

                if( this.state.sort.type === 'number' ) {
                    return parseInt(a[this.state.sort.value])  - parseInt( b[this.state.sort.value] )
                }
                else if( this.state.sort.type === 'string' ) {

                    if( this.state.sort.child === 'deliveryAddress' ) {
                        a= a.deliveryAddress && a.deliveryAddress[0] ? a.deliveryAddress[0] : {};
                        b= b.deliveryAddress && b.deliveryAddress[0] ? b.deliveryAddress[0] : {};
                    }

                    let nameA=a[this.state.sort.value] ? a[this.state.sort.value].toUpperCase() : '';
                    let nameB=b[this.state.sort.value] ? b[this.state.sort.value].toUpperCase() : '';

                    if (this.state.sort.desc) {
                        if (nameA < nameB) {
                            return -1;
                        }
                        if (nameA > nameB) {
                            return 1;
                        }
                    }
                    else {
                        if (nameA < nameB) {
                            return 1;
                        }
                        if (nameA > nameB) {
                            return -1;
                        }
                    }
                }
                return 0;
            });

        }

        return (
            <main id={'accountList'} className={'pageContainerFullWidth'}>

                {list.length === 0 && this.state.fetching && <div style={{textAlign: 'center'}}>
                    <Header as='h2'>Een moment..</Header>
                </div>}

                {this.state.editUser && <Modal closeIcon={true} open={true} onClose={() => this.setState({editUser: null})}>
                    <Modal.Header>Bewerken</Modal.Header>
                    <Modal.Content >
                        <AccountView id={this.state.editUser.id} onSaved={(user) => this.handleChangeUser(user)} asModal={true}/>
                    </Modal.Content>
                </Modal>}

                <DeltaTableMenu
                    header={<Translate value='menu_users_overview'/>}
                >
                    <DeltaTable
                        name={'accountlist'}
                        data={list}
                        columns={[
                            {id: 1, header: 'Voornaam', defaultWidth: 150, render: (p) => <div>
                                    <button className='link-button' onClick={() => this.setState({editUser: p})}>{p.firstName}</button>
                                </div>},
                            {id: 2, header: 'Achternaam', defaultWidth: 150, render: (p) => <div>
                                    <button className='link-button' onClick={() => this.setState({editUser: p})}>{p.lastName}</button>
                                </div>},
                            {id: 3, header: 'Telefoon', defaultWidth: 150, render: (p) => p.telefoon},
                            {id: 4, header: 'Mobiel', defaultWidth: 150, render: (p) => p.mobiel},
                            {id: 5, header: 'E-mail', defaultWidth: 150, render: (p) => p.email},
                            {id: 6, header: 'factuur e-mail', defaultWidth: 150, render: (p) => p.emailinvoice},
                            {id: 7, header: 'BTW nummer', defaultWidth: 150, render: (p) => p.btwnummer},
                            {id: 8, header: 'KvK nummer', defaultWidth: 150, render: (p) => p.kvkNumber},
                            {id: 11, header: <Translate value='user_company_name'/>, defaultWidth: 150, render: (p) => p.companyName},
                            {id: 12, header: <Translate value='user_internal_company_name'/>, defaultWidth: 150, render: (p) => p.internalCompanyName},
                            {id: 10, header: 'domainAdmin', defaultWidth: 150, render: (p) => p.domainAdmin ? 'Ja' : 'Nee'},
                            {id: 9, header: '', defaultWidth: 30, render: (p) => <div style={{textAlign: 'center'}}>
                                <Icon name='trash' link color={'red'} onClick={() => this.setState({confirm: p.id})}/>
                                <Confirm
                                    open={this.state.confirm === p.id}
                                    onCancel={() => this.setState({confirm: false})}
                                    onConfirm={() => this.props.dispatch(deleteUser(p.id, () => {
                                        this.setState({confirm: false})
                                    }))}
                                />
                            </div>},
                        ]}
                    />
                </DeltaTableMenu>

                {/*{list.length > 0 && <Table celled padded compact={'very'} className="tableRowFixedHeader nobrtable" style={{padding: '2em'}}>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell></Table.HeaderCell>
                            <Table.HeaderCell>
                                <div>{this.renderSortableHeader('Voornaam', 'firstName', 'string')}</div>
                            </Table.HeaderCell>
                            <Table.HeaderCell>
                                <div>{this.renderSortableHeader('Achternaam', 'lastName', 'string')}</div>
                            </Table.HeaderCell>
                            <Table.HeaderCell>
                                <div>{this.renderSortableHeader('Telefoon', 'telefoon', 'string')}</div>
                            </Table.HeaderCell>
                            <Table.HeaderCell>
                                <div>{this.renderSortableHeader('Mobiel', 'mobiel', 'string')}</div>
                            </Table.HeaderCell>
                            <Table.HeaderCell>
                                <div>{this.renderSortableHeader('E-mail', 'email', 'string')}</div>
                            </Table.HeaderCell>
                            <Table.HeaderCell>
                                <div>{this.renderSortableHeader('factuur e-mail', 'emailinvoice', 'string')}</div>
                            </Table.HeaderCell>
                            <Table.HeaderCell>
                                <div>{this.renderSortableHeader('BTW nummer', 'btwnummer', 'string')}</div>
                            </Table.HeaderCell>
                            <Table.HeaderCell>
                                <div>{this.renderSortableHeader('KVK nummer', 'kvknummer', 'string')}</div>
                            </Table.HeaderCell>
                            <Table.HeaderCell>
                                <div>{this.renderSortableHeader('Laatste bestelling voor bedrijf', 'internalCompanyName', 'string')}</div>
                            </Table.HeaderCell>
                            <Table.HeaderCell>
                                <div>{this.renderSortableHeader('Laatste projectnaam', 'lastProjectOrderInternalName', 'string')}</div>
                            </Table.HeaderCell>
                            <Table.HeaderCell>
                                <div>{this.renderSortableHeader('Laatste projectaanvraag', 'lastProjectOrderAanvraagDate', 'number')}</div>
                            </Table.HeaderCell>
                            <Table.HeaderCell>
                                <div>{this.renderSortableHeader('Bedrijfsnaam', 'companyName', 'string', 'deliveryAddress')}</div>
                            </Table.HeaderCell>
                            <Table.HeaderCell>
                                <div>{this.renderSortableHeader('Postcode', 'zip', 'string', 'deliveryAddress')}</div>
                            </Table.HeaderCell>
                            <Table.HeaderCell>
                                <div>{this.renderSortableHeader('Huisnummer', 'number', 'string', 'deliveryAddress')}</div>
                            </Table.HeaderCell>
                            <Table.HeaderCell>
                                <div>{this.renderSortableHeader('Toevoeging', 'addition', 'string', 'deliveryAddress')}</div>
                            </Table.HeaderCell>
                            <Table.HeaderCell>
                                <div>{this.renderSortableHeader('Straatnaam', 'street', 'string', 'deliveryAddress')}</div>
                            </Table.HeaderCell>
                            <Table.HeaderCell>
                                <div>{this.renderSortableHeader('Stad', 'city', 'string', 'deliveryAddress')}</div>
                            </Table.HeaderCell>
                            <Table.HeaderCell>
                                <div>{this.renderSortableHeader('Provincie', 'provincie', 'string', 'deliveryAddress')}</div>
                            </Table.HeaderCell>
                            <Table.HeaderCell>
                                <div>{this.renderSortableHeader('Land', 'country', 'string', 'deliveryAddress')}</div>
                            </Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>

                    <Table.Body>
                        {list.map((p,i) =>
                            <AccountListItem p={p} i={i} onSelect={(p) => this.setState({editUser: p})}/>
                        )}
                    </Table.Body>
                </Table>}*/}

            </main>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        lang: state.main.lang,
        currentLang: state.main.currentLang,
        currentUser: state.main.currentUser,
        users: state.main.users,

    }
}

export default connect(mapStateToProps)(AccountList)