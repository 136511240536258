import React from 'react'
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {setBomFilter, setBomSort} from "../actions";
import {Dropdown} from "semantic-ui-react";

const checkinFilterOptions = ['nocheckin']

class BomCheckinHeader extends React.Component {
    render() {

        let f = this.props.checkingFocus;

        const sort = this.props.bomSort === 'boardLocation';

        const nocheckin = this.props.bomFilter === 'nocheckin'

        return <div style={{marginLeft: 0, marginRight: 10}}>
            <Dropdown text={this.props.bomFilter ? this.props.bomFilter : 'Filter'} style={{textDecoration: nocheckin ? 'underline' : null}}>
                <Dropdown.Menu>
                    {this.props.bomFilter ? <Dropdown.Item value='' onClick={() => {
                        this.props.dispatch(setBomFilter(''))
                    }}>
                        Clear filter
                    </Dropdown.Item> : null}
                    <Dropdown.Item value='noCheckin' active={this.props.bomFilter === 'nocheckin'} onClick={() => {
                        this.props.dispatch(setBomFilter('nocheckin'))
                    }}>
                        Not checked-in
                    </Dropdown.Item>
                    <Dropdown.Item value='noorder' active={this.props.bomFilter === 'noorder'} onClick={() => {
                        this.props.dispatch(setBomFilter('noorder'))
                    }}>
                        No order
                    </Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
            <div style={{height: "22px"}}></div>
            <div style={{display: 'flex', gap: 4}}>
                <div style={{fontWeight: (f === '') ? 700 : 500, width: 100, textAlign: 'center', marginRight: 5, marginLeft: 58}}>Stock</div>
                <div style={{fontWeight: (f === 'receivedStock') ? 700 : 500, width: 72, textAlign: 'center', marginRight: 5}}>Qty</div>
                <div style={{fontWeight: (f === 'boardLocation') ? 700 : 500, width: 72, textAlign: 'center', marginRight: 5, cursor: 'pointer', textDecoration: sort ? 'underline' : null}}
                     onClick={() => this.props.dispatch(setBomSort('boardLocation'))}>TrayLane</div>
                <div style={{fontWeight: (f === 'pitch') ? 700 : 500, width: 60, textAlign: 'center', marginRight: 5}}>Width</div>
                <div style={{fontWeight: (f === 'width') ? 700 : 500, width: 60, textAlign: 'center', marginRight: 5}}>Pitch</div>
            </div>
        </div>
    }
}


BomCheckinHeader.propTypes = {
};

BomCheckinHeader.defaultProps = {
};

const mapStateToProps = (state) => {
    return {
        checkingFocus: state.bom.checkingFocus,
        bomSort: state.bom.bomSort,
        bomFilter: state.bom.bomFilter,
    }
}

export default connect(mapStateToProps)(BomCheckinHeader)