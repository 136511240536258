import React, {Component} from 'react';
import {fetchData, handleErrors} from "../actions/functions";
import {Button, Divider, Header, Input, Modal, Table, TextArea} from "semantic-ui-react";
import ReactMarkdown from "react-markdown";

import {languageOptions} from "../actions/functions";

class LanguageEditor extends Component {

    constructor(props) {
        super(props);

        this.state = {
            list: [],
            newItem: {
                key: "",
                nl: "",
                fr: "",
                de: "",
                en: ""
            },
            preview: null
        }
    }

    componentDidMount = () => {

        fetchData( '/api/i18n/get' )
            .then(response => handleErrors(response).json())
            .then(json => {
                console.log(json)
                this.setState({list: json});
            })
            .catch(error => {
                console.log(error)
            })

    }

    save = (obj) => {

        fetchData( '/api/i18n/save', obj )
            .then(response => handleErrors(response).json())
            .then(json => {
                this.setState({list: json});
            })
            .catch(error => {
                console.log(error)
            })

    }

    newkey = (value) => {

        fetchData( '/api/i18n/newkey?value=' + value )
            .then(response => handleErrors(response).json())
            .then(json => {
                this.setState({list: [...this.state.list, json]});
            })
            .catch(error => {
                console.log(error)
            })

    }

    handleChange = (e, i) => {

        let newList= this.state.list.map((l, index) => {
            if( index === i ) {
                return Object.assign({}, l, {[e.target.name]: e.target.value, dirty: true})
            } else {
                return l;
            }
        })

        this.setState({list: newList})
    }

    handleChangeNewItem = (e) => {

        let newItem= Object.assign({}, this.state.newItem, {[e.target.name]: e.target.value})

        this.setState({newItem: newItem})
    }

    preview = (show, i) => {
        this.setState({preview: show, previewI: i, previewValue: ''})
    }

    render() {

        return <section className="pageContainer" style={{width: '100%'}}>

            {this.state.preview && <Modal open={this.state.preview} onClose={() => this.preview(null)} closeIcon>
                <Modal.Content>

                    {languageOptions.map((lo, i) => (<div>
                        <div>{lo.text}</div>
                        <TextArea autofocus={i===0} label={lo.text} autoHeight style={{width: '100%'}} name={lo.key} value={this.state.list[this.state.previewI][lo.key]} onChange={(e) => {
                            this.handleChange(e,this.state.previewI)
                            this.setState({previewValue: e.target.value})
                        }} onClick={() => {
                            this.setState({previewValue: this.state.list[this.state.previewI][lo.key]})
                        }}/>
                    </div>))}

                    <p>You can user HTML! See here for info: <a href="https://github.com/rexxars/react-markdown">https://github.com/rexxars/react-markdown</a></p>

                    <Divider horizontal>
                        <Header as='h4'>
                            Voorbeeld
                        </Header>
                    </Divider>
                    {/*<ReactMarkdown>
                        {this.state.previewValue ? this.state.previewValue : ''} //TODO: Move to new version of react-markdown!
                    </ReactMarkdown>*/}

                    <ReactMarkdown source={this.state.previewValue ? this.state.previewValue : ''} escapeHtml={false} />

                </Modal.Content>
            </Modal>}

            <Table celled>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell collapsing>key</Table.HeaderCell>
                        {languageOptions.map((lo) => (
                            <Table.HeaderCell>{lo.text}</Table.HeaderCell>
                        ))}
                        <Table.HeaderCell collapsing>Save</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>

                <Table.Body>

                    {this.state.list.map((l,i) => (
                        <Table.Row key={l.key}>
                            <Table.Cell collapsing>
                                {l.key}
                            </Table.Cell>

                            {languageOptions.map((lo) => (
                                <Table.Cell>
                                    <TextArea autoHeight style={{width: '100%'}} name={lo.key} value={l[lo.key]} onChange={(e) => this.handleChange(e,i)}/>
                                </Table.Cell>
                            ))}

                            <Table.Cell collapsing>
                                <Button primary={l.dirty} content='Save' onClick={() => this.save(l)} />
                                <Button content='Preview' onClick={() => this.preview(l, i)} />
                            </Table.Cell>
                        </Table.Row>
                    ))}

                    <Table.Row>
                        <Table.Cell colspan={4} >
                            <Input style={{display: 'inline'}} label={'Key'} value={this.state.newkeyvalue} onChange={(e) => this.setState({newkeyvalue: e.target.value})}/>
                            <Button style={{display: 'inline', marginLeft: 16}} onClick={() => {
                                this.newkey(this.state.newkeyvalue)
                            }}>Add</Button>
                        </Table.Cell>
                    </Table.Row>

                </Table.Body>


            </Table>
        </section>
    }
}

export default LanguageEditor;
